import React, { useState, useEffect, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./EventsForm.module.scss";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import addBtn from "../../../assets/add-btn-black.png";
import checkedBtn from "../../../assets/checked-green-btn.png";
import notAvailable from "../../../assets/empty.png";
import cancel from "../../../assets/cancel-btn.svg";
import modalHandle from "../../../assets/modal-handle.png";
import dayjs from "dayjs";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toggle, setUser, toast, closeEvents } from "../../../state";
import { DateTimePicker } from "../../../components/DateTimePicker/DateTimePicker";
import {
  addUserEventsFood,
  getUserData,
  getFruits,
} from "../../../services/firebase";
import {
  CircularProgress,
  Fab,
  InputBase,
  TextareaAutosize,
} from "@material-ui/core";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import barcode from "../../../assets/icons/barcode.svg";
import Div100vh from "react-div-100vh";
import inputOption, { MEAL_PREP } from "../../../constant";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CustomTextArea from "../../../components/CustomTextArea/CustomTextArea";
import NutrientsBar from "../../../components/NutrientsBar/NutrientsBar";
import MultipleNutrientsBar from "../../../components/NutrientsBar/MultipleNutrientsBar";
import AddIcon from "@material-ui/icons/Add";
import { addRecipe } from "./../../../state";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useAddRecentMeal } from "../../../services/mutations";
import FoodUpload from "../FoodUpload/FoodUpload";
import { convertKiloJoulestoKiloCal } from "../../../utilities";

import searchIcon from "../../../assets/icons/search.png";
import cancelDark from "../../../assets/icons/cancelDark.png";
import _debounce from "lodash/debounce";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import * as toObject from "dayjs/plugin/toObject";
import axios from "axios";
import { format } from "date-fns";
import { useHistory } from "react-router";
import {
  getAllRecommendRecipesQuery,
  getAllUserRecipesQuery,
  useGetRecentMeal,
} from "../../../services/queries";
import { truncate } from "lodash";

dayjs.extend(toObject);

const tabs = {
  // History: "History",
  Recipes: "Recipes",
  Recent: "Recent",
  Food: "Food",
  Fruits: "Fruits/Veg",
  Dietitian: "Dietitian",
};

export const EventsForm = ({
  show,
  selectedTime,
  setSelectedTime,
  selectedDate,
  setSelectedDate,
  date,
}) => {
  const recipeState = useSelector((state) => state.recipes, shallowEqual);
  const calendarState = useSelector((state) => state.calendar, shallowEqual);
  const userState = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const [currentTab, setcurrentTab] = useState("Food");
  const [message, setMessage] = useState("");
  const [data, setData] = useState();
  const [fruits, setFruits] = useState([]);
  const [count, setCount] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [openForm2, setOpenForm2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showScanner, setShowScanner] = useState(false);
  const [currentEvent, setCurrentEvent] = useState("Breakfast")
  const [stopStream, setStopStream] = useState(false);
  const [dataCode, setDataCode] = React.useState("");
  const [searchResult, setSearchResult] = React.useState(null);
  const [isSeraching, setIsSeraching] = React.useState(false);
  const [noScan, setNoScan] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const [quantityValues, setQuantityValues] = useState({});
  const [cookingMethod, setCookingMethod] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedKey, setSelectedKey] = useState(0);
  const [showFoodUpload, setShowFoodUpload] = useState(false);
  const [showOptions, setShowOptions] = useState({ 0: true });

  const activeList = calendarState.activeLists;
  const user = userState.user;
  const history = useHistory();

  const { isLoading: isLoadingRecommendRecipes, data: recommendRecipes } =
    getAllRecommendRecipesQuery(user.uid);
  // const { isLoading: isLoadingUserRecipes, data: userRecipes } = getAllUserRecipesQuery(user.uid);
  const { addRecentMeal } = useAddRecentMeal();
  const { recentMeals, recentMealsIsLoading, recentMealIsFetching } =
    useGetRecentMeal(user.uid);

  let timer;

  const onTabClick = (tab) => {
    setcurrentTab(tab);
  };

  const handleClick = (id, product) => {
    dispatch(toggle({ id: id }));
    let item = product;
    if (!item.recipeId) {
      item.unitType = product?.quantity?.match(/\d+/g)
        ? item.quantity.match(/[a-zA-Z]+/g)[0]
        : "percent";
      item.typeArray = product?.quantity?.match(/\d+/g)
        ? [item.quantity.match(/[a-zA-Z]+/g)[0], "percent"]
        : ["percent"];
      item.served = product?.quantity?.match(/\d+/g)
        ? +product.quantity.match(/\d+/g).map(Number)[0]
        : 0;
      if (!product?.quantity?.match(/\d+/g)) {
        item.quantity = "100 %";
        item.served = 100;
      }
    }
    if (item._id) {
      selected.includes(item)
        ? setSelected(selected.filter((e) => e._id !== item._id))
        : setSelected((prev) => [...prev, item]);
    }
    if (item.recipeId) {
      selected.includes(item)
        ? setSelected(selected.filter((e) => e.recipeId !== item.recipeId))
        : setSelected((prev) => [...prev, item]);
    }
    if (item.mid) {
      selected.includes(item)
        ? setSelected(selected.filter((e) => e.mid !== item.mid))
        : setSelected((prev) => [...prev, item]);
    }
    const updatedItems = selected.map((item) => {
      return { ...item, served: 70 };
    });
    // setSelected(updatedItems);
  };

  const isWhatPercentOf = (x, y) => {
    if (x && y) {
      return Math.round((x / y) * 100);
    } else {
      return 0;
    }
  };

  const percentOfNumber = (number, percentage) => {
    return Math.trunc((percentage / 100) * number);
  };

  const onClose = () => {
    if (!isSeraching) {
      dispatch(closeEvents());
      setCount(0);
      setStopStream(true);
      setShowScanner(false);
      setSelected([]);
    } else {
      setIsSeraching(false);
      setStopStream(true);
      setShowScanner(false);
      setSelected([]);
    }
  };

  

  const submit = async () => {
    setLoading(true);
    let selectedData = [];
    const recentData = [];
    for (let i = 0; i < selected.length; i++) {
      const quantity = selected[i]?.quantity?.match(/\d+/g)
        ? selected[i]?.quantity?.match(/\d+/g).map(Number)[0]
        : null;
      const calc = isWhatPercentOf(selected[i].served, quantity);
      const energyValue = selected[i].nutriments["energy-kcal"]
        ? Number(selected[i].nutriments["energy-kcal"])
        : convertKiloJoulestoKiloCal(
            Number(selected[i].nutriments["energy_value"])
          );
      let nutrients = {
        carbohydrates: percentOfNumber(
          +calc,
          +selected[i].nutriments.carbohydrates
        ),
        "energy-kcal": quantity
          ? percentOfNumber(calc, energyValue)
          : energyValue,
        fat: percentOfNumber(+calc, +selected[i].nutriments.fat),
        proteins: percentOfNumber(+calc, +selected[i].nutriments.proteins),
      };
      if (selected[i].recipeId) {
        selectedData.push({
          Item: {
            id: selected[i].recipeId,
            name: selected[i].title,
            quantity: selected[i].quantity ? selected[i].quantity : 0,
            nutriments: nutrients,
            thumbnail: selected[i].thumbnail ? selected[i].thumbnail : '',
            cooking_method: selected[i].cookingMethod,
          },
          id: i,
        });
        recentData.push({
          mid: selected[i].recipeId,
          name: selected[i].title,
          quantity: selected[i].quantity ? selected[i].quantity : 0,
          nutriments: nutrients,
          data: selected[i],
          type: "recipe",
          cooking_method: selected[i].cookingMethod,
          thumbnail: selected[i].thumbnail ? selected[i].thumbnail : '',
          dateCreated: new Date(),
        });
      }
      if (selected[i]._id) {
        const quantity = selected[i]?.quantity?.match(/\d+/g)
          ? selected[i]?.quantity?.match(/\d+/g).map(Number)[0]
          : null;
        const calc = isWhatPercentOf(selected[i].served, quantity);
        const energyValue = selected[i].nutriments["energy-kcal"]
          ? Number(selected[i].nutriments["energy-kcal"])
          : convertKiloJoulestoKiloCal(
              Number(selected[i].nutriments["energy_value"])
            );
        let nutrients = {
          carbohydrates: percentOfNumber(
            +calc,
            +selected[i].nutriments.carbohydrates
          ),
          "energy-kcal": quantity
            ? percentOfNumber(calc, energyValue)
            : energyValue,
          fat: percentOfNumber(+calc, +selected[i].nutriments.fat),
          proteins: percentOfNumber(+calc, +selected[i].nutriments.proteins),
        };
        selectedData.push({
          Item: {
            id: selected[i]._id,
            name: selected[i].product_name
              ? selected[i].product_name
              : selected[i].product_name_fr,
            quantity: selected[i].quantity ? selected[i].quantity : 0,
            nutriments: nutrients,
            served: selected[i].served,
            thumbnail: selected[i].image_front_thumb_url ? selected[i].image_front_thumb_url : '',
          },
          id: i,
        });
        recentData.push({
          mid: selected[i]._id,
          name: selected[i].product_name
            ? selected[i].product_name
            : selected[i].product_name_fr,
          quantity: selected[i].quantity ? selected[i].quantity : 0,
          nutriments: nutrients,
          thumbnail: selected[i].image_front_thumb_url ? selected[i].image_front_thumb_url : '',
          type: "food",
          dateCreated: new Date(),
        });
      }
      if (selected[i].mid) {
        const quantity = selected[i]?.quantity?.match(/\d+/g)
          ? selected[i]?.quantity?.match(/\d+/g).map(Number)[0]
          : null;
        const calc = isWhatPercentOf(selected[i].served, quantity);
        const energyValue = selected[i].nutriments["energy-kcal"]
          ? Number(selected[i].nutriments["energy-kcal"])
          : convertKiloJoulestoKiloCal(
              Number(selected[i].nutriments["energy_value"])
            );
        let nutrients = {
          carbohydrates: percentOfNumber(
            +calc,
            +selected[i].nutriments.carbohydrates
          ),
          "energy-kcal": quantity
            ? percentOfNumber(calc, energyValue)
            : energyValue,
          fat: percentOfNumber(+calc, +selected[i].nutriments.fat),
          proteins: percentOfNumber(+calc, +selected[i].nutriments.proteins),
        };
        selectedData.push({
          Item: {
            id: selected[i].mid,
            name: selected[i].name,
            quantity: selected[i].quantity ? selected[i].quantity : 0,
            nutriments: nutrients,
            thumbnail: selected[i].thumbnail ? selected[i].thumbnail : '',
            cooking_method: selected[i].cookingMethod
              ? selected[i].cookingMethod
              : null,
          },
          id: i,
        });
      }
    }

    const payload = {
      id: selectedDate.format("ddd D MMM"),
      time: selectedTime.format("hh:mm a"),
      hour: selectedTime.format("H"),
      minute: selectedTime.format("mm"),
      items: selectedData,
      description: message,
      tag: currentEvent,
      date: format(selectedDate.$d, "yyyy-MM-dd"),
    };
    const recentMealPayload = {
      userId: user.uid,
      data: recentData,
    };

    try {
      await addUserEventsFood(user.uid, payload);
      const userData = await getUserData(user.uid);
      if (recentData.length > 0) {
        await addRecentMeal(recentMealPayload);
      }
      dispatch(toast({ message: `${payload.tag} added successfully` }));
      setCount(0);
      setMessage("");
      setSelected([]);
      setLoading(false);
      setOpenForm(false);
      dispatch(closeEvents());
      dispatch(setUser(userData));
    } catch (error) {
      dispatch(toast({ message: "an error occurred adding event" }));
      setLoading(false);
    }
  };

  // const handleSave = () => {
  //    setLoading(true);
  //    try {
  //       if (selectedItem && selected.length > 0) {
  //          const data = selected.map((item) => {
  //             if (item._id === selectedItem._id) {
  //                return { ...item, quantity: quantityValues.number + " " + quantityValues.unit };
  //             } else if (item.recipeId === selectedItem.recipeId) {
  //                return { ...item, quantity: quantityValues.number + " " + quantityValues.unit };
  //             }
  //             return item;
  //          });
  //          setSelected(data);
  //       }
  //       dispatch(toast({ message: "saved successfully" }));
  //       setOpenForm2(false);
  //       setLoading(false);
  //    } catch (error) {
  //       dispatch(toast({ message: "an error occurred while saving" }));
  //       setLoading(false);
  //    }
  // };

  const getData = async (pageNum) => {
    setLoading(true);
    const config = {
      headers: {
        page: 2,
      },
    };
    try {
      const { data } = await axios.get(
        `https://us.openfoodfacts.org/cgi/search.pl?action=process&json=true&page=${pageNum}`
      );
      setData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getAllFruits = async () => {
    setLoading(true);
    try {
      const data = await getFruits();
      setFruits(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const searchData = async (code) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://world.openfoodfacts.org/api/v2/search?code=${code}`
      );
      setSearchResult(data);
      if (data.products.length === 0) {
        dispatch(toast({ message: "No food found for your search" }));
      } else {
        window.clearInterval(timer);
      }
      setLoading(false);
      setIsSeraching(true);
      setStopStream(true);
      setShowScanner(false);
      setNoScan(false);
    } catch (err) {
      setLoading(false);
      dispatch(toast({ message: "An error occured please try again" }));
    }
  };

  const handleDebounceFn = async (code, pageNum) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.get(
        `https://us.openfoodfacts.org/cgi/search.pl?tagtype_1=nutrition_grades&tag_1=A&json=true&action=process&search_terms2=${code}&page=${pageNum}`,
        config
      );
      setSearchResult(data);
      if (data.products.length === 0) {
        dispatch(toast({ message: "No food found for your search" }));
      }
      setLoading(false);
      setIsSeraching(true);
      setStopStream(true);
      setShowScanner(false);
    } catch (err) {
      setLoading(false);
      dispatch(toast({ message: "An error occured please try again" }));
    }
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const paginate = async (mode, pageNum) => {
    if (isSeraching) {
      try {
        await handleDebounceFn(searchValue, pageNum);
      } catch (err) {}
    } else {
      try {
        await getData(pageNum);
      } catch (err) {}
    }
  };

  const checkResult = () => {
    timer = window.setTimeout(() => {
      if (searchResult?.products.length === 0 || searchResult === null) {
        setNoScan(true);
      }
    }, 10000);
  };

  const handleShowMore = (id) => {
    setShowOptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const checkIfQuantityIsAdded = (items) => {
    if (items.length > 0) {
      const isQuantityAddedForEverySelectedItem = items.every((item) => {
        if (
          item._id &&
          quantityValues[item._id]?.number &&
          quantityValues[item._id]?.unit
        ) {
          return true;
        } else if (
          item.recipeId &&
          quantityValues[item.recipeId]?.number &&
          quantityValues[item.recipeId]?.unit
        ) {
          return true;
        } else if (
          item.mid &&
          quantityValues[item.mid]?.number &&
          quantityValues[item.mid]?.unit
        ) {
          return true;
        } else {
          return false;
        }
      });

      return isQuantityAddedForEverySelectedItem;
    } else return false;
  };

  useEffect(() => {
    try {
      getData(page);
      getAllFruits();
      setCount(activeList.length);
    } catch (err) {}
  }, []);

  useEffect(() => {
    show
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [show]);

  useEffect(() => {
    const data = selected.map((item) => {
      if (
        item._id &&
        quantityValues[item._id]?.number &&
        quantityValues[item._id]?.unit
      ) {
        return {
          ...item,
          quantity:
            quantityValues[item._id]?.number +
            " " +
            quantityValues[item._id]?.unit,
        };
      } else if (
        item.recipeId &&
        quantityValues[item.recipeId]?.number &&
        quantityValues[item.recipeId]?.unit
      ) {
        return {
          ...item,
          quantity:
            quantityValues[item.recipeId]?.number +
            " " +
            quantityValues[item.recipeId]?.unit,
        };
      } else if (
        item.mid &&
        quantityValues[item.mid]?.number &&
        quantityValues[item.mid]?.unit
      ) {
        return {
          ...item,
          quantity:
            quantityValues[item.mid]?.number +
            " " +
            quantityValues[item.mid]?.unit,
        };
      }
      return item;
    });
    setSelected(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityValues]);

  useEffect(() => {
    const data = selected.map((item) => {
      if (item.recipeId && cookingMethod[item.recipeId]) {
        return { ...item, cookingMethod: cookingMethod[item.recipeId] };
      } else if (
        item.mid &&
        item.type === "recipe" &&
        cookingMethod[item.mid]
      ) {
        return { ...item, cookingMethod: cookingMethod[item.mid] };
      }
      return item;
    });
    setSelected(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookingMethod]);

  useEffect(() => {
     const getMealTime = () => {
      if(selectedTime){
        const currentHour = selectedTime.hour();
        if (currentHour >= 7 && currentHour < 12) {
          setCurrentEvent('Breakfast')
        } else if (currentHour >= 12 && currentHour < 18) {
          setCurrentEvent('Lunch')
        } else {
          setCurrentEvent('Dinner')
        }
      }  
    }
    getMealTime()
  }, [selectedTime])

  return (
    <>
      {/* Add Food page  */}
      <div className={`${show ? styles.addFoodContainer : styles.hide}`}>
        <div className={styles.sticky}>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <CloseOutlinedIcon onClick={onClose} />
              <p className={styles.text}>Add {currentEvent}</p>
            </div>
            <MoreVertIcon fontSize="small" />
          </div>
          <div className={styles.bat}>
            <div className={styles.actions}>
              <div
                className={styles.items}
                onClick={() => [
                  setShowScanner(!showScanner),
                  setIsSeraching(true),
                  checkResult(),
                  setNoScan(false),
                ]}
              >
                <img
                  className={styles.bar}
                  src={barcode}
                  alt="barcode icon"
                  style={{ margin: "auto" }}
                />
                <div
                  style={{
                    fontSize: "12px",
                    color: "#00866E",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  scan <span className={styles.text}>+</span>
                </div>
              </div>

              <div
                onClick={() => {
                  dispatch(addRecipe());
                  history.push("/recipeupload");
                }}
                className={styles.items}
              >
                <AddIcon className={styles.fab} fontSize="small" />

                <div className={styles.text}>recipe</div>
              </div>

              <div
                className={styles.items}
                onClick={() => setShowFoodUpload(true)}
              >
                <AddIcon className={styles.fab} fontSize="small" />
                <p className={styles.text}>food item</p>
              </div>
            </div>
          </div>
        </div>

        {/* tablists */}
        <div className={styles.tabsContainer}>
          {!isSeraching && (
            <div className={styles.tabs}>
              {Object.values(tabs).map((tab, i) => (
                <p
                  key={i}
                  className={`${styles.tablists} ${
                    currentTab === tab ? styles.active : ""
                  }`}
                  onClick={() => onTabClick(tab)}
                >
                  {tab}
                </p>
              ))}
            </div>
          )}

          {showScanner && (
            <>
              <div style={{ marginTop: "30px" }}></div>
              <div className={styles.holdScanner}>
                <BarcodeScannerComponent
                  width={"100%"}
                  height={400}
                  onUpdate={(err, result) => {
                    if (result?.text) {
                      setDataCode(result.text);
                      searchData(result?.text);
                    }
                  }}
                />
                <div className={styles.indicator}></div>
                <div className={styles.warn}>
                  Align barcode inside the green box
                </div>
                {noScan && (
                  <div className={styles.warna}>
                    Unable to scan or detect barcode, please adjust your camera
                    for better lighting
                  </div>
                )}
              </div>
            </>
          )}

          {!showScanner && !isSeraching && (
            <>
              {currentTab === tabs.Food && (
                <div
                  className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                >
                  {currentTab === "Food" && (
                    <div className={styles.input}>
                      <img
                        src={searchIcon}
                        alt="search icon"
                        className={styles.imh}
                      />
                      <img
                        src={cancelDark}
                        alt="search icon"
                        className={styles.canc}
                        onClick={() => setSearchValue("")}
                      />
                      <InputBase
                        // autoFocus
                        className={styles.searchInput}
                        value={searchValue}
                        onChange={(e) => [
                          setSearchValue(e.target.value),
                          debounceFn(e.target.value),
                        ]}
                        placeholder="Food, recipes or brand"
                        inputProps={{ "aria-label": "search" }}
                        onFocus={() => [
                          //   setIsSeraching(true),
                          setStopStream(true),
                          setShowScanner(false),
                        ]}
                      />
                    </div>
                  )}
                  {data && !loading ? (
                    <>
                      {data.products.map((item, i) => (
                        <div key={i}>
                          {item.product_name_fr || item.product_name ? (
                            <div
                              className={`${styles.dataBox} ${
                                selected.includes(item) && styles.add
                              }`}
                            >
                              <div className={styles.dataContent}>
                                <div
                                  style={{
                                    backgroundImage: `url(${item.image_front_thumb_url})`,
                                  }}
                                  className={styles.listImage}
                                />
                                <div className={styles.dataText}>
                                  <h6 className={styles.dataTextHeading}>
                                    {item.product_name
                                      ? item.product_name
                                      : item.product_name_fr}
                                  </h6>
                                  <div>
                                    <p className={styles.text}>{`${
                                      item.desc ? item.desc + "." : ""
                                    } ${
                                      item.quantity ? item.quantity + "." : ""
                                    }`}</p>
                                  </div>
                                </div>
                                <div
                                  onClick={() => {
                                    handleClick(i, item);
                                  }}
                                >
                                  <img
                                    src={`${
                                      selected.includes(item)
                                        ? checkedBtn
                                        : addBtn
                                    }`}
                                    alt="add-button"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={styles.loader}>
                      <CircularProgress />
                    </div>
                  )}

                  {data?.products?.length > 0 && !loading && (
                    <div className={styles.pagination}>
                      <button
                        disabled={page <= 1}
                        className={`${styles.pag} ${styles.left}`}
                        onClick={() => [
                          paginate("prev", page - 1),
                          setPage((prev) => prev - 1),
                        ]}
                      >
                        <KeyboardArrowLeftIcon style={{ color: "white" }} />
                      </button>
                      <div className={styles.num}>{page}</div>
                      <button
                        disabled={page >= data?.page_count}
                        className={`${styles.pag} ${styles.right}`}
                        onClick={() => [
                          paginate("next", page + 1),
                          setPage((prev) => prev + 1),
                        ]}
                      >
                        <KeyboardArrowRightIcon style={{ color: "white" }} />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {isSeraching && !showScanner ? (
            <>
              {currentTab === tabs.Food && (
                <div
                  className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                >
                  <div className={styles.input}>
                    <img
                      src={searchIcon}
                      alt="search icon"
                      className={styles.imh}
                    />
                    <img
                      src={cancelDark}
                      alt="search icon"
                      className={styles.canc}
                      onClick={() => setSearchValue("")}
                    />
                    <InputBase
                      // autoFocus
                      className={styles.searchInput}
                      value={searchValue}
                      onChange={(e) => [
                        setSearchValue(e.target.value),
                        debounceFn(e.target.value),
                      ]}
                      placeholder="Food, recipes or brand"
                      inputProps={{ "aria-label": "search" }}
                      onFocus={() => [
                        //   setIsSeraching(true),
                        setStopStream(true),
                        setShowScanner(false),
                      ]}
                    />
                  </div>
                  {loading && (
                    <div className={styles.loader}>
                      <CircularProgress />{" "}
                    </div>
                  )}
                  {searchResult && !loading && (
                    <>
                      {searchResult?.products.map((item, i) => (
                        <div key={i}>
                          {item.product_name_fr || item.product_name ? (
                            <div
                              className={`${styles.dataBox} ${
                                selected.includes(item) && styles.add
                              }`}
                              key={i}
                            >
                              {searchResult?.products && (
                                <div className={styles.dataContent}>
                                  <div
                                    style={{
                                      backgroundImage: `url(${item.image_front_thumb_url})`,
                                    }}
                                    className={styles.listImage}
                                  />
                                  <div className={styles.dataText}>
                                    <h6 className={styles.dataTextHeading}>
                                      {item.product_name
                                        ? item.product_name
                                        : item.product_name_fr}
                                    </h6>
                                    <div>
                                      <p className={styles.text}>{`${
                                        item.desc ? item.desc + "." : ""
                                      } ${
                                        item.quantity ? item.quantity + "." : ""
                                      }`}</p>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleClick(i, item);
                                    }}
                                  >
                                    <img
                                      src={`${
                                        selected.includes(item)
                                          ? checkedBtn
                                          : addBtn
                                      }`}
                                      alt="add-button"
                                    />
                                  </div>
                                </div>
                              )}{" "}
                              {searchResult?.products?.length === 0 && (
                                <div>No record found</div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </>
                  )}{" "}
                  {searchResult?.products?.length === 0 || !searchResult ? (
                    <div>
                      {!loading && !showScanner ? (
                        <div className={styles.hold}>
                          <img
                            src={notAvailable}
                            className={styles.noResult}
                            alt="no result"
                          />
                          <div className={styles.texter}>
                            No products available
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {searchResult?.products?.length > 0 && !loading && (
                    <div className={styles.pagination}>
                      <button
                        disabled={pageSearch <= 1}
                        className={`${styles.pag} ${styles.left}`}
                        onClick={() => [
                          paginate("prev", pageSearch - 1),
                          setPageSearch((prev) => prev - 1),
                        ]}
                      >
                        <KeyboardArrowLeftIcon style={{ color: "white" }} />
                      </button>
                      <div className={styles.num}>{pageSearch}</div>
                      <button
                        disabled={pageSearch >= searchResult?.page_count}
                        className={`${styles.pag} ${styles.right}`}
                        onClick={() => [
                          paginate("next", pageSearch + 1),
                          setPageSearch((prev) => prev + 1),
                        ]}
                      >
                        <KeyboardArrowRightIcon style={{ color: "white" }} />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : null}

          {/* {currentTab === tabs.History && <div>hi i am History</div>} */}
          {currentTab === tabs.Fruits && (
            <>
              <div
                className={`${styles.dataContainer} ${styles.disableScrollbars}`}
              >
                {fruits.map((item, i) => (
                  <div key={i}>
                    {item.product_name_fr || item.product_name ? (
                      <div
                        className={`${styles.dataBox} ${
                          selected.includes(item) && styles.add
                        }`}
                      >
                        <div className={styles.dataContent}>
                          <div
                            style={{
                              backgroundImage: `url(${item.image_front_thumb_url})`,
                            }}
                            className={styles.listImage}
                          />
                          <div className={styles.dataText}>
                            <h6 className={styles.dataTextHeading}>
                              {item.product_name
                                ? item.product_name
                                : item.product_name_fr}
                            </h6>
                            <div>
                              <p className={styles.text}>{`${
                                item.desc ? item.desc + "." : ""
                              } ${
                                item.quantity ? item.quantity + "." : ""
                              }`}</p>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              handleClick(i, item);
                            }}
                          >
                            <img
                              src={`${
                                selected.includes(item) ? checkedBtn : addBtn
                              }`}
                              alt="add-button"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </>
          )}

          {currentTab === tabs.Recipes && (
            <>
              {recipeState.recipes.recipesIsLoading && (
                <div className={styles.loader}>
                  <CircularProgress />
                </div>
              )}
              {!recipeState.recipes.recipesIsLoading && (
                <div
                  className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                >
                  {recipeState.recipes.map((recipe, i) => (
                    <div
                      className={`${styles.dataBox} ${
                        selected.includes(recipe) && styles.add
                      }`}
                      key={recipe.recipeId}
                    >
                      <div className={styles.dataContent}>
                        <div
                          style={{
                            backgroundImage: `url(${
                              recipe.thumbnail
                                ? recipe.thumbnail
                                : "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262"
                            })`,
                          }}
                          className={styles.listImage}
                        />
                        <div className={styles.dataText}>
                          <h6 className={styles.dataTextHeading}>
                            {recipe.title}
                          </h6>
                          <div>
                            <p
                              className={styles.text}
                            >{`${recipe.difficulty}・${recipe.dietary}`}</p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            handleClick(i, recipe);
                          }}
                        >
                          <img
                            src={`${
                              selected.includes(recipe) ? checkedBtn : addBtn
                            }`}
                            alt="add-button"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {currentTab === tabs.Recent && (
            <>
              {(recentMealsIsLoading || recentMealIsFetching) && (
                <div className={styles.loader}>
                  <CircularProgress />
                </div>
              )}
              {(!recentMealsIsLoading || !recentMealIsFetching) &&
                recentMeals && (
                  <div
                    className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                  >
                    {recentMeals?.mealData?.map((meal, i) => {
                      if (meal.type === "food") {
                        return (
                          <div
                            className={`${styles.dataBox} ${
                              selected.includes(meal) && styles.add
                            }`}
                            key={meal.mid}
                          >
                            <div className={styles.dataContent}>
                              <div
                                style={{
                                  backgroundImage: `url(${meal.thumbnail})`,
                                }}
                                className={styles.listImage}
                              />
                              <div className={styles.dataText}>
                                <h6 className={styles.dataTextHeading}>
                                  {meal.name}
                                </h6>
                                <div>
                                  <p className={styles.text}>
                                    {`${meal.desc ? meal.desc + "." : ""} ${
                                      meal.quantity ? meal.quantity + "." : ""
                                    }`}
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  handleClick(i, meal);
                                }}
                              >
                                <img
                                  src={`${
                                    selected.includes(meal)
                                      ? checkedBtn
                                      : addBtn
                                  }`}
                                  alt="add-button"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (meal.type === "recipe") {
                        return (
                          <div
                            className={`${styles.dataBox} ${
                              selected.includes(meal) && styles.add
                            }`}
                            key={meal.mid}
                          >
                            <div className={styles.dataContent}>
                              <div
                                style={{
                                  backgroundImage: `url(${meal.thumbnail})`,
                                }}
                                className={styles.listImage}
                              />
                              <div className={styles.dataText}>
                                <h6 className={styles.dataTextHeading}>
                                  {meal.name}
                                </h6>
                                <div>
                                  <p
                                    className={styles.text}
                                  >{`${meal.data.difficulty}・${meal.data.dietary}`}</p>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  handleClick(i, meal);
                                }}
                              >
                                <img
                                  src={`${
                                    selected.includes(meal)
                                      ? checkedBtn
                                      : addBtn
                                  }`}
                                  alt="add-button"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              {(!recentMealsIsLoading || !recentMealIsFetching) &&
                recentMeals &&
                recentMeals.length === 0 && (
                  <div
                    className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                  >
                    <div className={styles.hold}>
                      <img
                        src={notAvailable}
                        className={styles.noResult}
                        alt="no result"
                      />
                      <div className={styles.texter}>No products available</div>
                    </div>
                  </div>
                )}
            </>
          )}

          {currentTab === tabs.Dietitian && (
            <>
              {isLoadingRecommendRecipes && (
                <div className={styles.loader}>
                  <CircularProgress />
                </div>
              )}
              {!isLoadingRecommendRecipes && (
                <div
                  className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                >
                  {recommendRecipes &&
                    recommendRecipes.map((recipe, i) => (
                      <div
                        className={`${styles.dataBox} ${
                          selected.includes(recipe) && styles.add
                        }`}
                        key={recipe.recipeId}
                      >
                        <div className={styles.dataContent}>
                          <div
                            style={{
                              backgroundImage: `url(${recipe.thumbnail})`,
                            }}
                            className={styles.listImage}
                          />
                          <div className={styles.dataText}>
                            <h6 className={styles.dataTextHeading}>
                              {recipe.title}
                            </h6>
                            <div>
                              <p
                                className={styles.text}
                              >{`${recipe.difficulty}・${recipe.dietary}`}</p>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              handleClick(i, recipe);
                            }}
                          >
                            <img
                              src={`${
                                selected.includes(recipe) ? checkedBtn : addBtn
                              }`}
                              alt="add-button"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* button for add food page */}
      {selected.length > 0 && show && (
        <div className={styles.buttonBase}>
          <button
            className={styles.searchButton}
            onClick={() => setOpenForm(true)}
          >
            Next
          </button>
          <div className={styles.count}>
            <img src={modalHandle} alt="" />
            <div className={styles.textCtn}>
              <p className={styles.text}>{currentEvent} Details</p>
              <p className={styles.counter}>{selected.length}</p>
            </div>
          </div>
        </div>
      )}

      {/* Page 3 ====> Form for breakfast/Lunch/Dinner form */}
      {openForm && (
        <div
          className={`${styles.modal} ${openForm ? styles.open : ""}`}
          onClick={() => setOpenForm(false)}
        >
          <div className={styles.modalContainer}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={styles.modalHeader}>
                <img src={modalHandle} alt="" />
              </div>

              <div className={styles.modalBody}>
                <p className={styles.text}>{currentEvent} Details</p>
                <DateTimePicker
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  selectedDate={date}
                  setSelectedDate={setSelectedDate}
                />
                {/* items */}
                <div className={styles.items}>
                  <p>Items</p>
                  {/* user total Nutrients */}
                  <>
                    <MultipleNutrientsBar
                      key={selectedKey}
                      selectedMeals={selected}
                      nutrients={
                        userState?.user?.nutrients?.show
                          ? userState?.user?.nutrients
                          : {
                              show: false,
                              carbs: 0,
                              fat: 0,
                              proteins: 0,
                              calories: 0,
                            }
                      }
                    />
                  </>
                  {selected.length > 0 && (
                    <div
                      className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                    >
                      {selected.map((item, i) => {
                        const thumbnail = item.image_front_thumb_url
                          ? item.image_front_thumb_url
                          : item.thumbnail
                          ? item.thumbnail
                          : null;
                        const productId = item.recipeId
                          ? item.recipeId
                          : item._id
                          ? item._id
                          : item.mid;
                        return (
                          <div
                            className={`${styles.dataBox} ${styles.add}`}
                            key={i}
                          >
                            <div className={styles.dataContent}>
                              <div className={styles.dataCtn}>
                                <div
                                  className={styles.showMore}
                                  onClick={() => handleShowMore(i)}
                                >
                                  {showOptions[i] ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </div>
                                {thumbnail && (
                                  <div className={styles.listImage}>
                                    <img
                                      src={thumbnail}
                                      alt=""
                                      className={styles.thumbnail}
                                    />
                                  </div>
                                )}
                                <div className={styles.dataText}>
                                  <h6 className={styles.dataTextHeading}>
                                    {item.product_name
                                      ? item.product_name
                                      : item.product_name_fr
                                      ? item.product_name_fr
                                      : item.name}
                                    {item.mid && item.name}
                                    {item.recipeId && item.title}
                                  </h6>
                                  <div>
                                    <p className={styles.text}>
                                      {`${
                                        item.quantity
                                          ? item.quantity + "・"
                                          : ""
                                      } ${item.size ? item.size + "・" : ""}`}
                                      {`${
                                        item.recipeId && item.difficulty
                                          ? item.difficulty + "・"
                                          : ""
                                      } ${
                                        item.recipeId && item.dietary
                                          ? item.dietary
                                          : ""
                                      }`}
                                      {`${
                                        item.mid && item.type === "recipe"
                                          ? item.data.difficulty + "・"
                                          : ""
                                      } ${
                                        item.mid && item.type === "recipe"
                                          ? item.data.dietary
                                          : ""
                                      }`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (selected.length === 1) {
                                    setOpenForm(false);
                                  }
                                  if (item._id) {
                                    setSelected(
                                      selected.filter((e) => e._id !== item._id)
                                    );
                                  }
                                  if (item.recipeId) {
                                    setSelected(
                                      selected.filter(
                                        (e) => e.recipeId !== item.recipeId
                                      )
                                    );
                                  }
                                  if (item.mid) {
                                    setSelected(
                                      selected.filter((e) => e.mid !== item.mid)
                                    );
                                  }
                                }}
                              >
                                <img src={cancel} alt="add-button" />
                              </div>
                            </div>

                            {showOptions[i] && (
                              <>
                                <div className={styles.dropDown}>
                                  <div className={styles.dropDownContent}>
                                    {/* user select quantity */}
                                    <div className={styles.quantity}>
                                      <p className={styles.subHeading}>
                                        Serving size
                                      </p>
                                      {/* <p className={styles.required}>
                                        {" "}
                                        {item?.quantity?.match(/\d+/g) ? (
                                          <>
                                            * Please add the actual quantity
                                            taken*
                                          </>
                                        ) : (
                                          <>
                                            * quantity not available for product
                                            *
                                          </>
                                        )}
                                      </p> */}
                                      {/* item?.quantity?.match(/\d+/g) && */}
                                      {!item.recipeId && (
                                        <div className={styles.quantityCtn}>
                                          <Grid xs={5} item>
                                            <CustomSelect
                                              select
                                              placeholder="Unit"
                                              name={"unit"}
                                              value={item.unitType}
                                              onChange={(e) => {
                                                const { id, value } = e.target;
                                                const updatedItems = [
                                                  ...selected,
                                                ];
                                                updatedItems[i] = {
                                                  ...updatedItems[i],
                                                  unitType: e.target.value,
                                                };
                                                setSelected(updatedItems);
                                                setSelectedKey(
                                                  (prev) => prev++
                                                );
                                                item.unitType = e.target.value;
                                              }}
                                            >
                                              {item.typeArray.map((value) => {
                                                return (
                                                  <MenuItem
                                                    key={value}
                                                    value={value}
                                                  >
                                                    {value}
                                                  </MenuItem>
                                                );
                                              })}
                                            </CustomSelect>
                                          </Grid>
                                          {item.unitType === "percent" ? (
                                            <Grid xs={6} item>
                                              <CustomSelect
                                                select
                                                placeholder="Percent"
                                                name={"percent"}
                                                value={isWhatPercentOf(
                                                  item.served,
                                                  item?.quantity
                                                    ?.match(/\d+/g)
                                                    .map(Number)[0]
                                                )}
                                                onChange={(e) => {
                                                  const { id, value } =
                                                    e.target;
                                                  const updatedItems = [
                                                    ...selected,
                                                  ];
                                                  updatedItems[i] = {
                                                    ...updatedItems[i],
                                                    served: percentOfNumber(
                                                      item?.quantity
                                                        ?.match(/\d+/g)
                                                        .map(Number)[0],
                                                      +e.target.value
                                                    ),
                                                  };
                                                  setSelected(updatedItems);
                                                  setSelectedKey(
                                                    (prev) => prev++
                                                  );
                                                  item.served = percentOfNumber(
                                                    item?.quantity
                                                      ?.match(/\d+/g)
                                                      .map(Number)[0],
                                                    +e.target.value
                                                  );
                                                }}
                                              >
                                                {Array.from(
                                                  { length: 101 },
                                                  (_, i) => i
                                                ).map((value) => {
                                                  return (
                                                    <MenuItem
                                                      key={value}
                                                      value={value}
                                                    >
                                                      {value}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </CustomSelect>
                                            </Grid>
                                          ) : (
                                            <Grid xs={6} item>
                                              <CustomInput
                                                type="number"
                                                placeholder="Number"
                                                id={productId}
                                                value={selected[i]?.served}
                                                InputProps={{
                                                  className: styles.input,
                                                }}
                                                onChange={(e) => {
                                                  const { id, value } =
                                                    e.target;
                                                  const updatedItems = [
                                                    ...selected,
                                                  ];
                                                  updatedItems[i] = {
                                                    ...updatedItems[i],
                                                    served: +value,
                                                  };
                                                  setSelected(updatedItems);
                                                  setSelectedKey(
                                                    (prev) => prev++
                                                  );
                                                  item.served = +value;
                                                }}
                                              />
                                            </Grid>
                                          )}
                                        </div>
                                      )}
                                    </div>

                                    {/* show user nutrients */}
                                    {userState.user?.nutrients && (
                                      <>
                                        {userState.user?.nutrients?.show && (
                                          <div className={styles.nutrientsBar}>
                                            <NutrientsBar
                                              key={item.served}
                                              selectedItem={item}
                                              nutrients={
                                                userState.user?.nutrients?.show
                                                  ? userState.user.nutrients
                                                  : {
                                                      show: false,
                                                      carbs: 0,
                                                      fat: 0,
                                                      proteins: 0,
                                                      calories: 0,
                                                    }
                                              }
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {/* add picture */}
                                    {/* <div className={styles.addPicture}>
                                                      <p className={styles.subHeading}>Pictures</p>
                                                      <div className={styles.addPictureBtn}>
                                                         <PhotoCameraOutlinedIcon className={styles.editIcon} />
                                                         <p className={styles.text}>Add Picture</p>
                                                      </div>
                                                   </div> */}
                                  </div>
                                </div>

                                {/* user select cooking method */}
                                {((item.mid && item.type === "recipe") ||
                                  item.recipeId) && (
                                  <div className={styles.dropDown}>
                                    <div className={styles.dropDownContent}>
                                      <p className={styles.subHeading}>
                                        Cooking method
                                      </p>
                                      <Grid xs={8} item>
                                        <CustomSelect
                                          select
                                          placeholder="select a cooking method"
                                          name={productId}
                                          value={
                                            cookingMethod[productId]
                                              ? cookingMethod[productId]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const { name, value } = e.target;
                                            setCookingMethod((prevState) => ({
                                              ...prevState,
                                              [name]: value,
                                            }));
                                          }}
                                        >
                                          {MEAL_PREP.map((value) => {
                                            return (
                                              <MenuItem
                                                key={value}
                                                value={value.toLocaleLowerCase()}
                                              >
                                                {value}
                                              </MenuItem>
                                            );
                                          })}
                                        </CustomSelect>
                                      </Grid>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                      {
                        <div
                          className={styles.addNewItem}
                          onClick={() => setOpenForm(false)}
                        >
                          <span>Add Item</span>
                          <img src={addBtn} alt="add-button" />
                        </div>
                      }
                    </div>
                  )}
                </div>

                {/* Notes */}
                <div className={styles.notesCtn}>
                  <p>Notes</p>
                  <CustomTextArea
                    maxrows={8}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="You can add notes for a more detailed description of your meal."
                  />
                </div>
                {/* button */}
                <div className={styles.doneBtn}>
                  <div className={styles.btnCtn}>
                    <button
                      className={`${styles.btn} `}
                      onClick={submit}
                      //  disabled={!checkIfQuantityIsAdded(selected)}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "white",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        "Done"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showFoodUpload && (
        <FoodUpload
          setShowFoodUpload={setShowFoodUpload}
          showFoodUpload={showFoodUpload}
          setSelected={setSelected}
          setOpenForm={setOpenForm}
        />
      )}
    </>
  );
};
