import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useCalendlyEventListener, InlineWidget, PopupModal } from "react-calendly";
import styles from "./styles.module.scss";
import { useHistory } from "react-router";
import back from "../../assets/icons/back.png";
import { getUserData } from "../../services/firebase/index";

import { toast } from "../../state";

const CalenderlyEmbed = () => {
  const [calendlyIsOpen, setCalendlyIsOpen] = useState(true);
  const [dietitian, setDietitian] = useState(null);
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;
  const history = useHistory();
  const dispatch = useDispatch();
  const moveTo = (path) => history.push(path);


    const getUser = async (id) => {
      const user = await getUserData(id);
      if (user) {
        setDietitian(user);
      }
    };

    useCalendlyEventListener({
      onEventScheduled: (event) => {
        dispatch(toast({ message: "Session booked successfully" }));
        setCalendlyIsOpen(false);
        moveTo('/bookingConfirmed')
      },
    });

   useEffect(() => {
      if (userState?.user?.assignedDietitian?.uid) {
        getUser(userState?.user?.assignedDietitian?.uid);
      }
    }, []);

  return (
    // <div className={styles.calenderly}>
    //      <img
    //         onClick={() => {
    //           history.goBack();
    //         }}
    //         className={styles.back}
    //         src={back}
    //         alt="back"
    //       />

    //   <InlineWidget
    //     styles={{
    //       height: "100vh",
    //       position: 'relative',
    //     }}
    //     url='https://calendly.com/stove/stove-monthly-meeting'
    //     // url="https://calendly.com/mcbobbymadu"
    //     // pageSettings={{
    //     //   backgroundColor: "ffffff",
    //     //   hideEventTypeDetails: false,
    //     //   hideLandingPageDetails: false,
    //     //   primaryColor: "00866E",
    //     //   // textColor: '4d5055'
    //     // }}
    //     // prefill={{
    //     //     email: 'test@test.com',
    //     //     firstName: 'Jon',
    //     //     lastName: 'Snow',
    //     //     name: 'Jon Snow',
    //     //     guests: [
    //     //       'janedoe@example.com',
    //     //     ],
    //     //     customAnswers: {
    //     //       a1: 'a1',
    //     //       a2: 'a2',
    //     //       a3: 'a3',
    //     //       a4: 'a4',
    //     //       a5: 'a5'
    //     //     },
    //     //     date: new Date(Date.now() + 86400000)
    //     //   }}
    //   />
    // </div>
    <div className={styles.calenderly}>
      <PopupModal
        rootElement={document.body}
        url={dietitian?.calendlyUrl ? `${dietitian?.calendlyUrl}?hide_event_type_details=1&hide_gdpr_banner=1` : "https://calendly.com/stove/stove-monthly-call-emma?hide_event_type_details=1&hide_gdpr_banner=1"}
        open={calendlyIsOpen}
        onModalClose={() => setCalendlyIsOpen(false)}
        prefill={{
          email: userState?.user?.email,
          name: userState?.user?.displayName,
          guests: dietitian?.email ? [dietitian?.email] : [],
          }}
          />
    </div>
  );
};

export default CalenderlyEmbed;
