import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonBase from "@material-ui/core/ButtonBase";
import formatISO from "date-fns/formatISO";
import { format } from "date-fns";

import MessageBox from "../../../components/MessageBox/MessageBox";
import { GroceryWidget } from "../../../components/GroceryWidgetList/GroceryWidgetList";
import emptyBg from "./../../../assets/empty-bg.png";
import timeIcon from "./../../../assets/icons/time.png";
import ferrisImg from "./../../../assets/icons/user.png";
import chatIcon from "./../../../assets/icons/chat.png";
import plan from "./../../../assets/plan.svg";

import {
  getAllRecommendRecipesQuery,
  getAllRecommendGroceriesQuery,
  getUserSessionsQuery,
} from "../../../services/queries";
import { getUserData } from "../../../services/firebase/index";
import { getDietitianCalendlyQuery } from "../../../services/queries";
import Footer from "../../../layout/Footer/Footer";
import styles from "./Consumer.module.scss";
import Dialog from "../../../components/Dialog/Dialog";
import { setActiveConversation, toast } from "../../../state";
import AnimateElement from "../../../components/AnimateElement";
import PricingDetails from "../PricingDetails/PricingDetails";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import TaskList from "../Task/TaskList";

const date = new Date();
date.setHours(0, 0, 0, 0);

const Consumer = (props) => {
  const { path, url } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [calendlyIsOpen, setCalendlyIsOpen] = useState(false);
  const [dietitian, setDietitian] = useState(null);
  const [nextSteps, setNextSteps] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const userState = useSelector((state) => state.user, shallowEqual);
  const { isLoading: isLoadingRecommendRecipes, data: recommendRecipes } =
    getAllRecommendRecipesQuery(userState.user.uid);
  const { isLoading: isloadingRecommendGroceries, data: recommendGroceries } =
    getAllRecommendGroceriesQuery(userState.user.uid);
  const { isLoading: isLoadingSessions, data: sessionsData } =
    getUserSessionsQuery(userState.user.uid);
  const {
    isLoading,
    isError,
    data: calendlyData,
    refetch,
  } = getDietitianCalendlyQuery(
    formatISO(date),
    userState?.user?.assignedDietitian?.email
  );

  const navigateToGrocery = (groceryId) => {
    history.push(`/shop/${groceryId}`);
  };

  const navigateToPlan = (recipeId) => {
    history.push(`${path}/plan/${recipeId}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMMM h:mmaaaa");
  };

  const getUser = async (id) => {
    const user = await getUserData(id);
    if (user) {
      setDietitian(user);
    }
  };

  const navigateToConversation = () => {
    const privateConversations = props.privateConversations;
    let chat = dietitian;
    dispatch(
      setActiveConversation(
        privateConversations.filter(
          (conversation) => dietitian?.uid in conversation?.attributes
        ).length > 0
          ? privateConversations.filter(
              (conversation) => dietitian?.uid in conversation?.attributes
            )[0]
          : chat
      )
    );
    history.push(`/chat/conversation`);
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const moveTo = (path) => history.push(path);

  useCalendlyEventListener({
    onEventScheduled: (event) => {
      dispatch(toast({ message: "Session booked successfully" }));
      setCalendlyIsOpen(false);
    },
  });

  // userState.user.isPremuimUser
  useEffect(() => {
    if (userState?.user?.assignedDietitian?.uid) {
      getUser(userState?.user?.assignedDietitian?.uid);
    }
    if (userState?.user?.isPremuimUser && !userState?.user?.quizData) {
      setIsOpen(true);
    }
  }, []);

  const navigateToSessions = () => {
    history.push(`${path}/sessions`);
  };

  const navigateToHealthProfile = () => {
    history.push(`${path}/health-profile/${userState?.user?.uid}`);
  };

  const getMostRecentSession = () => {
    if (!sessionsData || !Array.isArray(sessionsData)) return null;
    if (sessionsData) {
      const sortedSessions = sessionsData.sort((a, b) => {
        return new Date(b.createdOn) - new Date(a.createdOn);
      });
      setNextSteps(sortedSessions[0]);
      return sortedSessions[0];
    }
  };

  useEffect(() => {
    getMostRecentSession();
  }, [sessionsData]);

  useEffect(() => {
    if (calendlyData) {
      if (calendlyData.length > 0) {
        const filterUserAppointments = calendlyData.filter((event) => {
          return event?.inviteeUserInfo?.email === userState?.user?.email;
        });
        // sort data by most recent date
        const sortedAppointments = filterUserAppointments.sort((a, b) => {
          return new Date(b.start_time) - new Date(a.start_time);
        });
        setAppointments(sortedAppointments);
      }
    }
  }, [calendlyData]);

  if (!userState.user?.isPremuimUser) {
    return <PricingDetails />;
  }

  return (
    <div className={styles.page}>
      {/* <div className={styles.header}>
        <div className={styles.headertext} onClick={() => {
          handleTabClick(1)
          history.push(`${path}`)}}>For me</div>
        <div className={styles.headertext}  onClick={() =>
            history.push(`dietitian/weeklist/${userState?.user?.uid}`)
          }>Notes</div>
        <div className={styles.headertext} onClick={navigateToHealthProfile}>Diary</div>
      </div> */}

      <div className={styles.dietitianContainer}>
        <AnimateElement duration={"0.5"}>
          <img
            alt="dietitian"
            src={
              dietitian?.photoURL
                ? dietitian?.photoURL + "?tr=w-48,h-48"
                : ferrisImg
            }
            className={styles.dietitianImg}
          />
        </AnimateElement>
        <div className={styles.dietitianTextContentContainer}>
          <div
            className={styles.dietitianNameContainer}
            onClick={() => (dietitian ? navigateToConversation() : null)}
          >
            <span className={styles.dietitianName}>
              <AnimateElement duration={"0.5"}>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.97266 1.19922C5.5767 1.71697 6.55765 3.01752 6.41783 4.58946"
                    stroke="#00866E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.97806 0.583496H8.43832C7.45673 0.583496 6.64136 1.30884 6.5 2.25168C6.62189 2.20682 6.75359 2.18231 6.89087 2.18231H8.05142C9.00883 2.18231 9.80809 1.49231 9.97806 0.583496Z"
                    fill="#00866E"
                    stroke="#00866E"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.7223 11.496C9.94699 12.6807 9.05619 13.4167 8.39758 13.4167C8.10312 13.4167 7.75064 13.2649 7.37686 12.9769C6.86002 12.5813 6.13883 12.5822 5.62202 12.978C5.2494 13.265 4.89684 13.4167 4.60245 13.4167C3.94384 13.4167 3.05304 12.6807 2.27775 11.496C1.50156 10.3099 1 8.904 1 7.91429C1 5.8938 2.61604 4.25 4.60245 4.25C5.18805 4.25 5.76914 4.39622 6.28292 4.67288C6.41869 4.74601 6.58131 4.74601 6.71708 4.67288C7.23086 4.39625 7.81198 4.25 8.39755 4.25C10.384 4.25 12 5.8938 12 7.91429C12 8.904 11.4985 10.3099 10.7223 11.496Z"
                    fill="#00866E"
                    stroke="#00866E"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </AnimateElement>
              <span>{userState?.user?.assignedDietitian?.displayName}</span>
            </span>
            <span className={styles.dietitianOnline}>
              <AnimateElement duration={"0.5"}>
                <img
                  alt="dietitian"
                  src={chatIcon}
                  style={{ width: "20px", height: "20px" }}
                />
              </AnimateElement>
            </span>
          </div>
          <div className={styles.dietitianDesc}>
            {dietitian ? null : "No "}Registered Dietitian
          </div>
          {appointments && appointments.length > 0 && (
            <div className={styles.appointment}>
              <span className={styles.subTitle}>Next appointment:</span>
              <span className={styles.dietitianDesc}>
                {formatDate(appointments[0].start_time)}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.chatContainer}>
        {dietitian ? (
          <Button
            className={styles.sessionButton}
            onClick={() => setCalendlyIsOpen(true)}
          >
            Book a session
          </Button>
        ) : null}

        {userState?.user?.isPremuimUser && !userState?.user?.quizData ? (
          <Button
            className={styles.sessionButton}
            onClick={() => moveTo("/track")}
          >
            Take Quiz
          </Button>
        ) : null}
        {userState?.user?.isPremuimUser && !userState?.user?.quizData ? (
          <Dialog
            open={Boolean(isOpen)}
            message={`You have not filled the quiz form. Do you want to fill now?`}
            onCancel={() => [setIsOpen(false)]}
            onConfirm={() => [moveTo("/track")]}
            isLoading={false}
            isLoadingText="No Loading"
          />
        ) : null}
        {/* <Button className={styles.chatButton}>Chat</Button> */}
      </div>

      {userState?.user?.plan && (
        <div className={styles.all}>
          <div className={styles.plan}>
            <img src={plan} alt="plan div" className={styles.im} />
            <div className={styles.text}>
              <div className={styles.head}>My Plan</div>
              <div>
                {userState?.user?.plan.map((data, index) => (
                  <div className={styles.list} index={index}>
                    {/* <div className={styles.arrow}>{"->"}</div> */}
                    <div className={styles.splan}>
                      {"->"} {"  "} {data}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {userState?.user?.isPremuimUser && <TaskList />}

      <div className={styles.nextSteps}>
        <div className={styles.listHeaderContainer}>
          <AnimateElement duration={"0.5"}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.1499 2V22"
                stroke="#292D32"
                strokeWidth="1.5"
                stroke-miterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.34"
                d="M5.1499 4H16.3499C19.0499 4 19.6499 5.5 17.7499 7.4L16.5499 8.6C15.7499 9.4 15.7499 10.7 16.5499 11.4L17.7499 12.6C19.6499 14.5 18.9499 16 16.3499 16H5.1499"
                stroke="#292D32"
                strokeWidth="1.5"
                stroke-miterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </AnimateElement>
          <span>Next steps</span>
        </div>
        {nextSteps &&
          nextSteps.steps.map((step, index) => (
            <div className={styles.listText} key={index}>
              <span>→</span>
              <span className={styles.listTextValue}>{step}</span>
            </div>
          ))}
      </div>

      <div className={styles.titleContainer}>
        <span className={styles.titleText}>Suggested Recipes</span>
        <span
          className={styles.seeAll}
          onClick={() => moveTo("/suggested-recipes")}
        >
          {"See all >"}
        </span>
      </div>

      {isLoadingRecommendRecipes && (
        <div className="px-4 py-4 text-center">
          <CircularProgress size={15} disableShrink />
        </div>
      )}

      {recommendRecipes && recommendRecipes.length === 0 && (
        <AnimateElement duration={"0.5"}>
          <MessageBox text="No recipe assigned yet" height={0} />
        </AnimateElement>
      )}

      <div className={styles.slider}>
        {recommendRecipes &&
          recommendRecipes.map((recipe) => (
            <AnimateElement duration={"0.5"}>
              <Card
                key={recipe.recipeId}
                component="div"
                className={styles.card}
                onClick={() => history.push(`/recipe/${recipe.recipeId}`)}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      recipe.thumbnail ? recipe.thumbnail : emptyBg
                    })`,
                  }}
                  className={styles.img}
                />
                <div className={styles.recipeTitleContainer}>
                  <AnimateElement duration={"0.5"}>
                    <Avatar
                      src={recipe.chef && recipe.chef.pic + "?tr=w-36,h-36"}
                      className={styles.chefImg}
                      variant="circle"
                    />
                  </AnimateElement>
                  <Typography
                    classes={{ body1: styles.chefName }}
                    variant="body1"
                    display="inline"
                    noWrap
                  >
                    {recipe.chef && recipe.chef.firstName}{" "}
                    {recipe.chef && recipe.chef.lastName}
                  </Typography>
                </div>
                <div className={styles.mealAndDiffText}>{`${
                  typeof recipe.meal === "string" ? recipe.meal : "lunch"
                } . ${recipe.difficulty}`}</div>
                <Typography classes={{ body1: styles.title }} variant="body1">
                  {recipe.title}
                </Typography>
                <div className={styles.footer}>
                  <Button
                    onClick={(event) => [
                      event.stopPropagation(),
                      navigateToPlan(recipe.recipeId),
                    ]}
                    className={styles.planButton}
                  >
                    Plan
                  </Button>
                  <div className="d-flex align-items-center">
                    <Avatar
                      alt="time"
                      sizes="small"
                      variant="square"
                      src={timeIcon}
                      className={styles.img}
                    />{" "}
                    <span className={styles.footerText}>{recipe.readyIn}</span>
                  </div>
                </div>
              </Card>
            </AnimateElement>
          ))}
      </div>

      <div
        className={`${
          !userState?.user?.additionalResources ||
          (userState?.user?.additionalResources &&
            userState?.user?.additionalResources?.length === 0)
            ? styles.spacing
            : ""
        }`}
      >
        {userState?.user &&
          userState?.user?.additionalResources &&
          userState?.user?.additionalResources?.length > 0 && (
            <div className={styles.additionalResourceContainer}>
              <div className={styles.peel}></div>
              <span className={styles.titleText}>Additional Resources</span>

              {userState.user.additionalResources.map((item, i) => (
                <a
                  className={styles.articleContainer}
                  key={item.title + i.toString()}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={styles.articleImage}
                    style={{ backgroundImage: `url(${item.image})` }}
                  />
                  <div className={styles.resourceText}>
                    <div className={styles.articleTitle}>{item.title}</div>
                    <div className={styles.articleText}>{item.url}</div>
                  </div>
                </a>
              ))}
            </div>
          )}
        {((userState?.user?.additionalResources &&
          userState?.user?.additionalResources?.length === 0) ||
          !userState?.user?.additionalResources) && (
          <>
            <div className={styles.titleContainer}>
              <span className={styles.titleText}>Additional Resources</span>
            </div>
            <AnimateElement duration={"0.5"}>
              <MessageBox text="No resources added yet" height={0} />
            </AnimateElement>
          </>
        )}
      </div>
      <div className={styles.titleContainer}>
        <span className={styles.titleText}>Suggested Products</span>
        <span
          className={styles.seeAll}
          onClick={() => moveTo("/suggested-shop")}
        >
          {"See all >"}
        </span>
      </div>

      {isloadingRecommendGroceries && (
        <div className="px-4 py-4 text-center">
          <CircularProgress size={15} disableShrink />
        </div>
      )}

      {recommendGroceries && recommendGroceries.length === 0 && (
        <AnimateElement duration={"0.5"}>
          {" "}
          <MessageBox text="No product assigned yet" height={0} />
        </AnimateElement>
      )}

      {recommendGroceries && recommendGroceries.length > 0 && (
        <div className={styles.slider}>
          {recommendGroceries.map((grocery) => {
            return (
              <AnimateElement duration={"0.5"}>
                <div className={styles.groceryTiles} key={grocery.groceryId}>
                  <GroceryWidget
                    onClick={() => navigateToGrocery(grocery.groceryId)}
                    grocery={grocery}
                    footer="grocery"
                  />
                </div>
              </AnimateElement>
            );
          })}
        </div>
      )}

      {
        <PopupModal
          rootElement={document.body}
          url={
            dietitian?.calendlyUrl
              ? `${dietitian?.calendlyUrl}?hide_event_type_details=1&hide_gdpr_banner=1`
              : "https://calendly.com/stove/stove-monthly-call-emma?hide_event_type_details=1&hide_gdpr_banner=1"
          }
          open={calendlyIsOpen}
          onModalClose={() => setCalendlyIsOpen(false)}
          prefill={{
            email: userState?.user?.email,
            name: userState?.user?.displayName,
            guests: dietitian?.email ? [dietitian?.email] : [],
          }}
        />
      }
      <Footer />
    </div>
  );
};

export default Consumer;

const filterAllCategory = (groceries) => {
  const category = [];
  groceries.forEach((item) => {
    if (typeof item.shopCategory === "string") {
      category.push(item.shopCategory);
    } else {
      item.shopCategory.forEach((value) => category.push(value));
    }
  });
  return [...new Set(category)];
};
