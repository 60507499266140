import React, { useEffect, useState } from "react";
import styles from "./NutrientsBar.module.scss";
import { convertKiloJoulestoKiloCal } from "../../utilities";

const NutrientsBar = ({ selectedItem, nutrients }) => {
  const isWhatPercentOf = (x, y) => {
    if (x && y) {
      return Math.round((x / y) * 100);
    } else {
      return 0;
    }
  };
  
  const percentOfNumber = (number, percentage) => {
    return Math.trunc((percentage / 100) * number);
  };

  // console.log('selested', selectedItem);
  const energyValue = selectedItem.nutriments["energy-kcal"]
    ? Number(selectedItem.nutriments["energy-kcal"])
    : convertKiloJoulestoKiloCal(
        Number(selectedItem.nutriments["energy_value"])
      );
  const [quantity] = useState(
    selectedItem?.quantity?.match(/\d+/g)
      ? selectedItem?.quantity?.match(/\d+/g).map(Number)[0]
      : null
  );
  const [calc] = useState(isWhatPercentOf(selectedItem.served, quantity));
  const [carbs] = useState(
    quantity
      ? isWhatPercentOf(
          percentOfNumber(+calc, +selectedItem.nutriments.carbohydrates),
          nutrients.carbs
        )
      : isWhatPercentOf(+selectedItem.nutriments.carbohydrates, nutrients.carbs)
  );
  const [protein] = useState(
    quantity
      ? isWhatPercentOf(
          percentOfNumber(+calc, +selectedItem.nutriments.proteins),
          nutrients.proteins
        )
      : isWhatPercentOf(+selectedItem.nutriments.proteins, nutrients.proteins)
  );
  const [fat] = useState(
    quantity
      ? isWhatPercentOf(
          percentOfNumber(+calc, +selectedItem.nutriments.fat),
          nutrients.fats
        )
      : isWhatPercentOf(+selectedItem.nutriments.fat, nutrients.fats)
  );
  const [kcal] = useState(
    quantity ? percentOfNumber(calc, energyValue) : energyValue
  );
  // console.log('quantity', percentOfNumber(calc, energyValue));

  return (
    <div className={styles.container}>
      {" "}
      <div className={styles.fles}>
        <div className={`${styles.sin} ${styles.bod}`}>
          <div>Kcal/day</div>
          <div>
            <progress value={kcal} max={nutrients.calories}>
              {nutrients.show ? kcal : selectedItem.nutriments["energy-kcal"]}%
            </progress>
          </div>
          <div>
            <b>
              {nutrients.show ? kcal : selectedItem.nutriments["energy-kcal"]}{" "}
              {nutrients?.calories && <>{`/ ${nutrients?.calories}`}</>}
            </b>
          </div>
        </div>

        <div className={styles.sin}>
          <div>Carbs</div>
          <div>
            <progress
              value={
                nutrients.show ? carbs : selectedItem.nutriments.carbohydrates
              }
              max="100"
            >
              {nutrients.show ? carbs : selectedItem.nutriments.carbohydrates}%
            </progress>
          </div>
          <div>
            <b>
              {nutrients.show ? carbs : selectedItem.nutriments.carbohydrates}
              {nutrients.show ? "%" : "g"}
            </b>
          </div>
        </div>
        <div className={styles.sin}>
          <div>Protein</div>
          <div>
            <progress
              value={
                nutrients.show ? protein : selectedItem.nutriments.proteins
              }
              max="100"
            >
              {nutrients.show ? protein : selectedItem.nutriments.proteins}%
            </progress>
          </div>
          <div>
            <b>
              {nutrients.show ? protein : selectedItem.nutriments.proteins}
              {nutrients.show ? "%" : "g"}
            </b>
          </div>
        </div>

        <div className={styles.sin}>
          <div>Fat</div>
          <div>
            <progress
              value={nutrients.show ? fat : selectedItem.nutriments.fat}
              max="100"
            >
              {nutrients.show ? fat : selectedItem.nutriments.fat}%
            </progress>
          </div>
          <div>
            <b>
              {nutrients.show ? fat : selectedItem.nutriments.fat}
              {nutrients.show ? "%" : "g"}
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutrientsBar;
