import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import sendIMG from "../../../assets/icons/sendBlue.svg";
import reloadICN from "../../../assets/icons/reload.svg";
import Header from "./Components/Header";
import Loader from "./Components/Loader/Loader";
import styles from "./GptAssistant.module.scss";
import AddIcon from "@material-ui/icons/Add";
import {
  addUserEventsFood,
  addUserEventsSymptoms,
  addUserEventsWater,
  addUserEventsWeight,
  createUserTask,
  getUserData,
  uploadRecipe,
} from "../../../services/firebase";
import { setUser, toast } from "../../../state";
import { useHistory, useParams } from "react-router";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea";
import { SSE } from "sse";
// import { macroTags } from "./../../constant";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import {
  format,
  startOfWeek,
  subWeeks,
  addDays,
  isWithinInterval,
  parseISO,
  differenceInDays,
} from "date-fns";
import { DateTimePicker } from "../../../components/DateTimePicker/DateTimePicker";
import AnimateElement from "../../../components/AnimateElement";
import { Link } from "react-router-dom";

const GptAssistant = () => {
  const apiKey = "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";
  const dispatch = useDispatch();

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [activeDate, setActiveDate] = useState("");
  let [result, setResult] = useState("");
  let [dataJson, setDataJson] = useState("");
  let [remove2, setRemove2] = useState(false);
  let [isVisible, setIsVisible] = useState(false);

  const userState = useSelector((state) => state.user, shallowEqual);
  const bottomRef = useRef(null);
  const history = useHistory();
  const textAreaRef = useRef();
  const resultRef = useRef();
  const jsonRef = useRef();
  const [tags, setTags] = useState([]);
  const [foodTags, setFoodTags] = useState([]);
  const [healthGoals, setHealthGoals] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [personalize, setPersonalize] = useState(true);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [messageType, setMessageType] = useState("diary");
  const [reload, setReload] = useState(false);
  const [patient, setPatient] = useState(null);
  const currentWeek = new Date();
  const [filteredData, setFilteredData] = useState([]);
  const [range, setRange] = useState([]);
  // const startDate = useRef(new Date());
  // const endDate = useRef(new Date());
  const foods = useRef();
  const water = useRef();
  const weight = useRef();
  const symptoms = useRef();
  const foodsNum = useRef(0);
  const waterNum = useRef(0);
  const weightNum = useRef(0);
  const symptomsNum = useRef(0);
  const userData = useRef();

  const weeks = Array.from({ length: 6 }, (_, index) =>
    subWeeks(currentWeek, index)
  );

  const user = userState?.user;
  const params = useParams();
  const { type, userID, startDate, endDate } = params;
  const weightRef = useRef();
  const symptomsRef = useRef();
  const waterRef = useRef();
  const numOfDays = useRef(
    differenceInDays(new Date(startDate), new Date(endDate))
  );

  useAutosizeTextArea(textAreaRef.current, input);

  const getPatient = async () => {
    const patients = await getUserData(userID);
    setPatient(patients);
  };

  const updateDate = (week) => {
    let tempFoods = "";
    let tempWater = "";
    let tempWeight = "";
    let tempSymptoms = "";
    setActiveDate(week.date);
    // startDate.current = new Date(week.startDate);
    // endDate.current = new Date(week.endDate);

    const filteredItems = patient?.events?.filter((item) => {
      const itemDate = parseISO(item.date);
      return isWithinInterval(itemDate, {
        start: new Date(startDate),
        end: new Date(endDate),
      });
    });

    setFilteredData(filteredItems);
    if (filteredItems && filteredItems.length > 0) {
      for (let i = 0; i < filteredItems.length; i++) {
        foodsNum.current = foodsNum.current + 1;
        if (filteredItems[i].hasOwnProperty("food")) {
          tempFoods =
            tempFoods +
            `${filteredItems[i].date} :${
              Array.isArray(filteredItems[i].food.items)
                ? filteredItems[i].food.items.map(
                    (innerItem, innerIndex) =>
                      `${innerItem?.Item?.name} qty:${innerItem?.Item?.quantity} {cab:${innerItem?.Item?.nutriments?.carbohydrates}, pro:${innerItem?.Item?.nutriments?.proteins}, fat:${innerItem?.Item?.nutriments?.fat}, cal:${innerItem?.Item?.nutriments?.["energy-kcal"]}}`
                  )
                : ""
            }  \n`;
        }

        if (filteredItems[i].hasOwnProperty("symptoms")) {
          symptomsNum.current = symptomsNum.current + 1;
          tempSymptoms =
            tempSymptoms +
            `${filteredItems[i].date} :${
              Array.isArray(filteredItems[i].symptoms.symptoms)
                ? filteredItems[i].symptoms.symptoms.map(
                    (innerItem, innerIndex) => innerItem
                  )
                : ""
            } \n`;
        }

        if (filteredItems[i].hasOwnProperty("water")) {
          waterNum.current = waterNum.current + 1;
          tempWater =
            tempWater +
            `${filteredItems[i].date}: ${filteredItems[i].water.quantity} \n`;
        }

        if (filteredItems[i].hasOwnProperty("weight")) {
          weightNum.current = weightNum.current + 1;
          tempWeight =
            tempWeight +
            `${filteredItems[i].date}: ${filteredItems[i].weight.size} \n`;
        }
      }
    }
    foods.current = tempFoods;
    water.current = tempWater;
    symptoms.current = tempSymptoms;
    weight.current = tempWeight;
    setReload(!reload);
  };

  useEffect(() => {
    resultRef.current = result;
    jsonRef.current = dataJson;
  }, [result, messages, dataJson]);

  useEffect(() => {
    if (startDate && endDate) {
      updateDate({ startDate, endDate, date: null });
    }
  }, [startDate, endDate, patient]);

  useEffect(() => {
    if (patient) {
      sendMessage(true);
    }
  }, [reload]);

  // useEffect(() => {
  //   // Assuming data is your array of objects
  //   const filteredItems = data.filter((item) => {
  //     const itemDate = parseISO(item.date);
  //     return isWithinInterval(itemDate, { start: startDate, end: endDate });
  //   });

  //   setFilteredData(filteredItems);
  // }, [startDate, endDate]);

  useEffect(() => {
    resultRef.current = result;
    jsonRef.current = dataJson;
  }, [result, messages, dataJson]);

  useEffect(() => {
    getPatient();
  }, []);

  useEffect(() => {
    setMessageType(type);
    if (type === "weight") {
      weightRef.current.scrollIntoView();
    } else if (type === "symptoms") {
      weightRef.current.scrollIntoView();
    } else if (type === "water") {
      symptomsRef.current.scrollIntoView();
    }
    if (!isKeyInLocalStorage("firstVisit")) {
      setIsVisible(true);
      localStorage.setItem("firstVisit", "false");
    } else {
      setIsVisible(false);
    }
  }, [type]);
  useEffect(() => {
    let ranger = [];
    weeks.map((week, index) => {
      const start = startOfWeek(week);
      const days = Array.from({ length: 7 }, (_, dayIndex) =>
        format(addDays(start, dayIndex), "yyyy-MM-dd")
      );
      ranger.push({
        date: `${format(new Date(days[0]), "d MMM")} - ${format(
          new Date(days[6]),
          "d MMM"
        )}`,
        startDate: format(new Date(days[0]), "yyyy-MM-dd"),
        endDate: format(new Date(days[6]), "yyyy-MM-dd"),
      });
      setRange(ranger);

      // return (
      //   <div
      //     key={index}
      //     onClick={() => {
      //       setActiveDate();
      //     }}
      //     className={
      //       activeDate === `${days[0]} - ${days[6]}`
      //         ? `${styles.mode} ${styles.active}`
      //         : styles.mode
      //     }
      //   >
      //     <div className={styles.be}>{`${days[0]} - ${days[6]}`}</div>
      //   </div>
      // );
    });
    ranger.push({
      date: `1 Jan - 30 Dec`,
      startDate: "2021-1-1",
      endDate: "2023-12-30",
    });
  }, []);

  const moveTo = (path) => history.push(path);

  // const fixInvalidJSON = (string) => {
  //   try {
  //     JSON.parse(string);
  //     return string; // Return as is if it's already valid JSON
  //   } catch (error) {
  //     // Check if the string is empty
  //     if (!string.trim()) {
  //       return string;
  //     }

  //     let jsonString = string.replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3');

  //     // Add missing double quotes around keys
  //     const fixedString = jsonString.replace(
  //       /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
  //       '"$2":'
  //     );

  //     // Fix any broken JSON by adding missing double quotes around values
  //     const fixedJSON = fixedString.replace(
  //       /: (["'])?(.*?)\1(?=,|}|$)/g,
  //       ': "$2"'
  //     );

  //     return fixedJSON;
  //   }
  // };

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  const systemMessages = [
    {
      role: "system",
      content: `,
      I will be giving you my patient information below and i am there dietitian, i need analysis based on the information i 
      give to you, so i will be asking you some questions and i need an analyzed answer
      patient name : ${patient?.displayName},
      meal history : ${
        foods.current
          ? foods.current
          : "no meal history on the selected session"
      },
      weight history : ${weight.current},
      water history : ${water.current},
      symptoms history : ${symptoms.current},
        - this chat is between you and the patients dietitian so respond in a way the will understand 
        - make sure you analyze with any data given to you
        - also let the dietitian know of any data discrepancies or missed meals or anything that would affect the patient
        - the data for this session is between ${startDate} and ${endDate}
      `,
    },
    {
      role: "system",
      content: `
                look through patients data and give me analysis exactly in this format below, do not return the same thing, 
                as what i provided is just a template and should be unique based on patient information, also mention any 
                data that is not available for the analysis.

                1. Nutritional Breakdown (Hypothetical Estimates):
                  [ list the total nutrient for carbohydrate, fat, protein and calories separated in new lines starting with -, for each one describe what was taking and break them down into % and add there values too ]
                2. Data Gaps Analysis:
                  [analyze the gaps in data and give advice on what to do to improve data 
                    each point should be in new line starting with -
                  ]
                3. Timing of Meals:
                 [ analyze meal times and give advice on what should be stopped reduced and done more 
                  each point should be in new line starting with -
                ]
                4. Hydration Status: [provide status and advice on the hydration status based on the user history
                  each point should be in new line starting with -
                ]
                5. Behavioral Patterns: [studying the patient history, list there behavioral patterns
                    each point should be in new line starting with -
                  ].
                6. Red Flags and Alerts: [based on patient history list any red flags or alerts each point should be in new line starting with -.]
                7. Evidence-based References: [provide an evidence-based reference or recommendation regarding the 
                  nutritional impact or health benefits of this meals? water intake, weight and symptoms Please consider 
                  reputable sources and scientific studies in your response, i dont need a reference give me the response
                  each point should be in new line starting with -
                  .]
                8. User-Focused Tasks:
                  - Meal Planning: [give a task user should under to improve there meal].
                  - Symptom Tracking: [advice on what user should do to improve health and tracking].
                  - Nutritional Education: [based on users intake and symptoms history give advice on best thing to do, eg best things to eat or avoid etc].
                9. Data Quality: [ number of days between last session and this session was ${differenceInDays(
                  new Date(startDate),
                  new Date(endDate)
                )} days, water tracked for ${
        waterNum.current
      } days, meals tracked for ${foodsNum.current} days, weigh tracked for ${
        weightNum.current
      } days, symptoms tracked for ${symptomsNum.current} days.
                   so compare number of days user tracked meals, water symptoms and weight with number of days session lasted and list analysis on it, 
                  in the analysis state the number of days user tracked and number of days session lasted, 
                  note: always add the number of days tracked and total number of days session lasted]
                  
  `,
    },
  ];
  const taskType = `
    {
      "date" : "should be empty"
      "description" : "[generate a short title for the task]"
      "frequency" : "[select from this options "Daily", "Weekly", "Monthly", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]"
      "status" : "in progress"
      "subDescription" : "[generate a short sub description for the task]"
      "time" : "should be empty"
      "userId" : "${userID}"
    } 
  `;
  const systemTaskMessages = [
    // {
    //   role: "system",
    //   content: `
    //     only talk about user task and adjustments you can make to the tasks
    //   `,
    // },
    {
      role: "user",
      content: `
        as a dietitian generate list of task based on the analysis you provided, the task should be gotten from the user focused task section and should be what user should do,
        it should be in this format array of ${taskType} eg,
        tasks = [${taskType}, ${taskType}]
      `,
      isShow: false,
    },
  ];

  const sendMessage = async (check) => {
    setRemove2(false);
    let searchIndex = input;
    setInput("");
    let startJSON = false;
    const send = async (message) => {
      let mainMessages = JSON.parse(JSON.stringify(message));

      for (let i = 0; i < mainMessages.length; i++) {
        if ("json" in mainMessages[i]) {
          mainMessages[i].content = JSON.stringify(mainMessages[i].json);
          delete mainMessages[i]["json"];
        }
        if ("isRecipe" in mainMessages[i]) {
          delete mainMessages[i]["isRecipe"];
        }
        if ("id" in mainMessages[i]) {
          delete mainMessages[i]["id"];
        }
        if ("isShow" in mainMessages[i]) {
          delete mainMessages[i]["isShow"];
        }
        if ("type" in mainMessages[i]) {
          delete mainMessages[i]["type"];
        }
      }

      if (searchIndex !== "" || check) {
        setLoading(true);
        setResult("");
        setDataJson("");
        let url = "https://api.openai.com/v1/chat/completions";
        let data = {
          model: "gpt-4o",
          // response_format:
          //   messageType !== "recipe" ? { type: "json_object" } : null,

          messages: [...systemMessages, ...mainMessages],
          temperature: 0.8,
          top_p: 1,
          max_tokens: 1500,
          stream: true,
          n: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        let source = new SSE(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
            "X-Content-Type-Options": "nosniff",
          },
          method: "POST",
          payload: JSON.stringify(data),
        });

        source.addEventListener("message", (e) => {
          if (e.data === "[DONE]") {
            // console.log("jsonRef.current", jsonRef.current);

            const jsData = jsonRef.current
              ? convertToJSON(jsonRef.current)
              : "";
            // console.log("jsData", jsData);
            if (jsonRef.current) {
              if (jsData[0]) {
                if (jsData[0].hasOwnProperty("frequency")) {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Here are ${data.length} tasks we’d recommend for the client:
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                    Description: ${data[i].description}
                    Sub Description: ${data[i].subDescription}
                    Frequency: ${data[i].frequency}
                    Status: On Hold
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: false,
                        type: "Task",
                      },
                    ];
                  });
                } else if (jsData[0].hasOwnProperty("symptoms")) {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Symptoms
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                      symptoms:  ${data[i].symptoms.join(" ")}
                      date: ${data[i].date}
                      time: ${data[i].time}
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else if (jsData[0].type === "water") {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Water
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                      Quantity: ${data[i].quantity}ml
      
                      date: ${data[i].date}
                      time: ${data[i].time}
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else if (jsData[0].hasOwnProperty("weight")) {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Weight
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                      weight:  ${data[i].weight}
                      date: ${data[i].date}
                      time: ${data[i].time}
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else {
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: resultRef.current,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                }
              } else {
                setMessages((prevItems) => {
                  return [
                    ...prevItems,
                    {
                      role: "assistant",
                      content: resultRef.current,
                      json: jsData ? jsData : "",
                      isRecipe: true,
                    },
                  ];
                });
              }
            } else {
              setMessages((prevItems) => {
                return [
                  ...prevItems,
                  {
                    role: "assistant",
                    content: resultRef.current,
                    json: "",
                    isRecipe: false,
                  },
                ];
              });
            }

            scrollToBottom("ref", jsonRef, result, resultRef);
          } else {
            let payload = JSON.parse(e.data);
            let text = payload.choices[0].delta.content;
            if (payload.choices[0].finish_reason === "stop") {
              source.close();
            } else {
              if (text !== undefined) {
                if (text.includes("{") || text.includes("[")) {
                  startJSON = true;
                  // setResponseType("recipe");
                  setLoadingText("Finalizing for your cookbook");
                }

                if (startJSON) {
                  jsonRef.current = jsonRef.current + text;
                  setDataJson((prevResult) => prevResult + text);

                  //   dataJson
                  setDataJson(jsonRef.current);
                } else {
                  //   resultRef.current = resultRef.current + text;
                  //   setResult(resultRef.current);
                  setResult((prevResult) => prevResult + text);
                  scrollToBottom();
                }
              }
            }
          }
        });

        source.addEventListener("readystatechange", (e) => {
          if (e.readyState >= 2) {
            setLoading(false);
            setLoadingText("");
          }
        });
        source.stream();
      } else {
        dispatch(toast({ message: `Please write a message` }));
      }
    };
    setMessages((prevValue) => {
      if (!searchIndex) {
        send([...prevValue]);
      } else {
        send([...prevValue, { role: "user", content: searchIndex }]);
      }
      if (!searchIndex) {
        return [...prevValue];
      } else {
        return [...prevValue, { role: "user", content: searchIndex }];
      }
    });
  };

  const addTask = async (data, i) => {
    setLoading(true);
    for (let i = 0; i < data.length; i++) {
      const b = await createUserTask(userID, data[i]);
      scrollToBottom();
      if (i === data.length - 1) {
        setLoading(false);
      }
    }
    setMessages([
      ...messages,
      {
        role: "assistant",
        content: `User tasks created successfully.`,
        id: "added",
      },
    ]);
  };

  const convertFractionsToDecimals = (inputString) => {
    const fractionRegex = /(\d+)\s*\/\s*(\d+)/g;

    const convertedString = inputString.replace(
      fractionRegex,
      (match, numerator, denominator) => {
        const decimalValue = numerator / denominator;
        return decimalValue.toString();
      }
    );

    return convertedString;
  };

  const convertToJSON = (string) => {
    // const splitIndex = message.content.indexOf("{"); // Find the index where the JSON part starts
    // const textPart = message.content.substring(0, splitIndex);
    const jsonStartIndex = string.indexOf("[");
    const jsonEndIndex = string.lastIndexOf("]");
    // console.log("sring", string);

    const jsonString = string.substring(jsonStartIndex, jsonEndIndex + 1);
    // console.log("jsonString", jsonString);

    let mainJson = null;
    if (jsonString.length > 2) {
      try {
        mainJson = JSON.parse(convertFractionsToDecimals(jsonString));
      } catch (err) {
        setMessages([
          ...messages,
          {
            role: "assistant",
            content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
          },
        ]);
        setRemove2(true);
      }
    } else {
      setMessages([
        ...messages,
        {
          role: "assistant",
          content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
        },
      ]);
      setRemove2(true);
    }
    return mainJson;
  };

  const tryAgain = () => {
    setRemove2(true);
    if (remove2) {
      let popMessages = messages.slice(0, -1);
      popMessages = popMessages.slice(0, -1);
      setMessages(popMessages);
      sendMessage(true);
    } else {
      let popMessages = messages.slice(0, -1);
      setMessages(popMessages);
      sendMessage(true);
    }
  };
  const isKeyInLocalStorage = (key) => {
    return localStorage.getItem(key) !== null;
  };

  return (
    <>
      <Header
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        mode={messageType}
      />
      <div>
        <div className={styles.baseContainer} ref={bottomRef}>
          <AnimateElement duration="0.5">
            {isVisible && (
              <div className={styles.fixed}>
                <div className={styles.featureHeading}>
                  <div>
                    <div className={styles.featureTitle}> view chart</div>
                    {/* <div className={styles.conta}>
                      view chart{" "}
                    </div> */}
                  </div>

                  <button
                    className={styles.create3}
                    onClick={() =>
                      moveTo(
                        `/dietitian/report/${startDate}/${endDate}/${userID}`
                      )
                    }
                  >
                    {" "}
                    {/* <AddIcon
                                    className={styles.fab}
                                    fontSize="small"
                                  /> */}
                    {/* <img
                      className={styles.reload}
                      src={reload}
                      alt="reload icon"
                    /> */}
                    View
                  </button>
                </div>
                {/* {personalize && (
                    <>
                      {tags.length > 0 && (
                        <div className={styles.sliderTagContainer}>
                          {tags.map((tag, index) => {
                            return (
                              <Card
                                component="div"
                                key={tag}
                                className={styles.sliderTag}
                                onClick={() =>
                                  moveTo(`/search/tag/groceries/${tag}`)
                                }
                              >
                                <span className={styles.sliderTagDot} />
                                <span>{tag}</span>
                              </Card>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )} */}
              </div>
            )}
          </AnimateElement>
          <AnimateElement>
            <div className={styles.friendTextDiv} style={{ marginTop: "10px" }}>
              {/* <div className={styles.friendTextContainer}>
                <div className={styles.friendText}>
                  <p>Hello! Please select the period you’d like to analyse</p>
                </div>
              </div> */}
            </div>
          </AnimateElement>
          {/* <div className={styles.modes1}>
            {range.map((week, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    updateDate(week);
                    console.log("week", week);
                  }}
                  className={
                    activeDate === week.date
                      ? `${styles.mode} ${styles.active}`
                      : styles.mode
                  }
                >
                  <div className={styles.be}>{week.date}</div>
                </div>
              );
            })}
          </div> */}

          {messages.map((message, i) => {
            if (message.role === "user") {
              return (
                <>
                  {!message.isShow ? null : (
                    <div className={styles.myTextDiv} key={i}>
                      <div className={styles.myTextContainer}>
                        <div className={styles.myText}>{message.content}</div>
                      </div>
                    </div>
                  )}
                </>
              );
            } else if (message.role === "assistant") {
              return (
                <>
                  <div className={styles.friendTextDiv} key={i}>
                    <div className={styles.friendTextContainer} key={i}>
                      <>
                        {" "}
                        <div className={styles.friendText} key={i}>
                          <div className={styles.pre}>
                            {message.content}{" "}
                            {i === messages.length - 1 && (
                              <>
                                <button
                                  className={styles.create}
                                  onClick={() => tryAgain()}
                                >
                                  {" "}
                                  {/* <AddIcon
                                    className={styles.fab}
                                    fontSize="small"
                                  /> */}
                                  <img
                                    className={styles.reload}
                                    src={reloadICN}
                                    alt="reload icon"
                                  />
                                  Try again
                                </button>
                                {message.json && (
                                  <>
                                    <button
                                      className={styles.create1}
                                      onClick={() => {
                                        // message.isRecipe
                                        addTask(message.json, i);
                                        // : saveEvents(message.json, i);
                                        // console.log(message.json, i);
                                        scrollToBottom();
                                      }}
                                    >
                                      {" "}
                                      <AddIcon
                                        className={styles.fab}
                                        fontSize="small"
                                      />
                                      {message.type === "Task"
                                        ? "Add To Record"
                                        : "tracker"}
                                    </button>
                                  </>
                                )}
                                {message.id && (
                                  <button
                                    className={styles.create1}
                                    onClick={() =>
                                      moveTo(
                                        `/dietitian/patient-details/${userID}/tasks?new=yes`
                                      )
                                    }
                                  >
                                    View Tasks
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </>
              );
            } else {
              return "";
            }
          })}
          {loading && (
            <>
              {result && (
                <div className={styles.friendText}>
                  <div className={styles.pre}>{result}</div>
                </div>
              )}
              <div className={styles.loader}>
                <Loader text={loadingText} />
              </div>
            </>
          )}
        </div>
        <AnimateElement duration="0.5">
          <div className={`${styles.modes} ${styles.sliderTagContainer}`}>
            <div
              onClick={() =>
                loading ? null : (setMessageType("diary"), setMessages([]))
              }
              className={`${
                loading && messageType !== "diary" ? styles.disabled : ""
              } ${
                messageType === "diary"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }`}
            >
              {/* <img
                src={messageType === "diary" ? recipe : recipe_dark}
                alt="icon"
              /> */}
              <div className={styles.be}>Diary Analysis</div>
            </div>
            <div
              // onClick={() =>
              //   loading ? null : (setMessageType("recipe"), setMessages([]))
              // }
              className={`${
                loading && messageType !== "recipe" ? styles.disabled : ""
              } ${
                messageType === "recipe"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }`}
            >
              {/* <img src={messageType === "recipe" ? food : food_dark} alt="icon" /> */}
              <div className={styles.be}>Recipe</div>
            </div>

            <div
              // onClick={() =>
              //   loading ? null : (setMessageType("article"), setMessages([]))
              // }
              className={`${
                loading && messageType !== "article" ? styles.disabled : ""
              } ${
                messageType === "article"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }`}
              ref={waterRef}
            >
              {/* <img
                src={messageType === "a" ? water : water_dark}
                alt="icon"
              /> */}
              <div className={styles.be}>Article</div>
            </div>

            <div
              onClick={() =>
                loading
                  ? null
                  : (setMessageType("task"),
                    setMessages((prevItems) => {
                      return [...prevItems, ...systemTaskMessages];
                    }),
                    sendMessage(true))
              }
              className={`${
                loading && messageType !== "task" ? styles.disabled : ""
              } ${
                messageType === "task"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }`}
              ref={symptomsRef}
            >
              {/* <img
                src={messageType === "symptoms" ? symptoms : symptoms_dark}
                alt="icon"
              /> */}
              <div className={styles.be}>Task</div>
            </div>
          </div>
        </AnimateElement>
        <div className={styles.input}>
          <div className={styles.hold}>
            <textarea
              placeholder="Write"
              value={input}
              className={styles.inp}
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     e.preventDefault();
              //     if (!loading) {
              //       sendMessage(false);
              //     }
              //   }
              // }}
              onChange={(e) => [setInput(e.target.value)]}
              ref={textAreaRef}
              rows={1}
            />
            {!loading && (
              <img
                className={styles.send}
                src={sendIMG}
                alt="back"
                onClick={() => sendMessage(false)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GptAssistant;
