import React, { useState } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';


import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';


import { GroceryWidgetPreviewAsyc } from "./../GroceryWidgetUpload/GroceryWidgetUpload";
import RecipeSteps from "./../../../../components/RecipeSteps/RecipeSteps";
import NutrientsBar from "../../../../components/NutrientsBar/NutrientsBar";

import recepeIcon from "./../../../../assets/icons/recipes-red.png";
import clockIcon from "./../../../../assets/icons/clock-red.png";
import potIcon from "./../../../../assets/icons/pot-red.png";
import backIcon from "./../../../../assets/icons/back-light.png";
import editIcon from "./../../../../assets/icons/edit-light.png";
import emptyBg from "./../../../../assets/empty-bg.png";





import styles from "./Preview.module.scss";

const Preview = (props) => {
    const { setActiveIndex, formik, isSubmiting } = props;
    const [stepIsOpen, setStepIsOpen] = useState(false);
    const groceriesState = useSelector(state => state.groceries, shallowEqual);
    const userState = useSelector(state => state.user, shallowEqual);
    const user = userState.user || {};
    const recipe = formik.values;

    const decrease = () => {
        let newNumber = Number(recipe.servings) - 1;
        if (newNumber >= 1) {
            formik.setFieldValue("servings", newNumber)
        }
    }

    const increase = () => {
        let newNumber = Number(recipe.servings) + 1
        if (newNumber <= 15) {
            formik.setFieldValue("servings", newNumber)
        }
    }

    const item = {
        nutriments: {
            "energy-kcal": Number(recipe["energy-kcal"]),
            carbohydrates: Number(recipe.carbohydrates),
            proteins: Number(recipe.proteins),
            fat: Number(recipe.fat),
        }
    }

    return (
       <>
          {stepIsOpen ? (
             <RecipeSteps setStepIsOpen={setStepIsOpen} steps={recipe.steps} recipe={recipe} />
          ) : (
             <div className={styles.page}>
                <div className={styles.header}>
                   <IconButton size='small' disabled={isSubmiting} onClick={() => setActiveIndex(3)}>
                      <Avatar alt='back' sizes='small' variant='square' src={backIcon} className={styles.icon} />
                   </IconButton>
                   <span className={styles.headertext}>Your Recipe Preview</span>
                   <IconButton size='small' onClick={() => setActiveIndex(1)}>
                      <Avatar alt='share' sizes='small' variant='square' src={editIcon} className={styles.icon} />
                   </IconButton>
                </div>

                <div className={styles.headerImgContainer} style={{ backgroundImage: `url(${recipe.thumbnail ? recipe.thumbnail : emptyBg})` }}>
                   <ButtonBase className={styles.letsCook} onClick={() => setStepIsOpen(true)}>
                      Let’s cook!
                   </ButtonBase>
                   <div className={styles.headerBottom} />
                </div>

                <div className='d-flex align-items-center px-4'>
                   <span className={styles.course}>FIRST COURSE</span>
                </div>

                <p className={styles.title}>{recipe.title}</p>

                <div className='d-flex align-items-center px-4'>
                   <IconButton size='small' className='mr-2'>
                      <Avatar alt='like' variant='circle' src={user.photoURL} className={styles.chefIcon} />
                   </IconButton>
                   <span className={styles.chefName}>{user.displayName}</span>
                </div>

                <div className='d-flex justify-content-between align-items-center px-4 py-4'>
                   <div className={styles.levelContain}>
                      <Avatar alt='like' variant='square' src={recepeIcon} className={styles.icon} />
                      <span className={styles.firstText}>Difficulty</span>
                      <span className={styles.secondText}>{recipe.difficulty}</span>
                   </div>
                   <div className={styles.levelContain}>
                      <Avatar alt='like' variant='square' src={clockIcon} className={styles.icon} />
                      <span className={styles.firstText}>Ready in</span>
                      <span className={styles.secondText}>{recipe.readyIn}</span>
                   </div>
                   <div className={styles.levelContain}>
                      <Avatar alt='like' variant='square' src={potIcon} className={styles.icon} />
                      <span className={styles.firstText}>Cost</span>
                      <span className={styles.secondText}>{recipe.cost}</span>
                   </div>
                </div>

                <div className={styles.line} />

                {formik.values.groceriesId.length > 0 && (
                   <>
                      <div className='d-flex justify-content-between align-items-center px-4 mb-4'>
                         <div className={styles.calulatorTextContain}>
                            <span className={styles.firstText}>Ingredients</span>
                            <span className={styles.secondText}>How many servings?</span>
                         </div>
                         <div className={styles.calculator}>
                            <span className={styles.button} onClick={decrease}>
                               <IconButton size='small' color='inherit'>
                                  <RemoveIcon fontSize='small' color='inherit' />
                               </IconButton>
                            </span>
                            <span className={styles.number}>{recipe.servings}</span>
                            <span className={styles.button} onClick={increase}>
                               <IconButton size='small' color='inherit'>
                                  <AddIcon fontSize='small' color='inherit' />
                               </IconButton>
                            </span>
                         </div>
                      </div>

                      <div className='px-4 mb-4'>
                         <GridList className='mt-4' cols={2} spacing={10} cellHeight='auto' component='div'>
                            {formik.values.groceriesId.map((groceryId) => (
                               <GridListTile key={groceryId}>
                                  <GroceryWidgetPreviewAsyc groceryId={groceryId} groceriesState={groceriesState} />
                               </GridListTile>
                            ))}
                         </GridList>
                      </div>

                      <div className='px-4'>
                         <div className={styles.lineHalf} />
                      </div>
                   </>
                )}

                <div className='px-4'>
                   {recipe.ingredients.map((ingredient, index) => (
                      <div key={index}>
                         {index !== 0 && <div className={styles.lineHalf} />}
                         <div className={styles.ingredientListBoldText} variant='body1' display='block'>
                            {ingredient.name}
                         </div>
                         <div className={styles.ingredientListListText} variant='body1' display='block'>
                            {ingredient.quantity}
                            {ingredient.unit}
                         </div>
                      </div>
                   ))}
                   <div className={styles.lineHalf} />
                </div>

                <div className='p-4'>
                   <Typography className={styles.chipHeaderText} variant='body1' display='block'>
                      Recipe’s categories
                   </Typography>
                   <div>
                      <Chip label={recipe.cost} className={styles.chip} />
                      <Chip label={recipe.difficulty} className={styles.chip} />
                      <Chip label={recipe.readyIn} className={styles.chip} />
                      <Chip label={recipe.servings} className={styles.chip} />
                      <Chip label={recipe.type} className={styles.chip} />
                      {(typeof meal === 'string') && <Chip label={recipe.meal} className={styles.chip} />}
                      {
                        (Array.isArray(recipe.meal)) && recipe.meal.map((meal) => {
                           return <Chip label={meal} className={styles.chip} />
                        })
                      }
                      <Chip label={recipe.dietary} className={styles.chip} />
                      <Chip label={recipe.cuisine} className={styles.chip} />
                      <Chip label={recipe.cookingMethod} className={styles.chip} />
                   </div>
                </div>

                <div className='p-4'>
                   <NutrientsBar selectedItem={item} nutrients={
                         userState.user?.nutrients?.show ? userState.user?.nutrients : { show: false, carbs: 0, fat: 0, proteins: 0, calories: 0 }
                      } />
                </div>
             </div>
          )}
          <ButtonBase disabled={isSubmiting} className={styles.confirmButton} onClick={formik.handleSubmit}>
             {isSubmiting ? <CircularProgress color='inherit' size='24px' /> : "Confirm & Publish"}
          </ButtonBase>
       </>
    );
}

export default Preview;
