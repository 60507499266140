import React, { Fragment, useState, useEffect, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DayJsUtils from "@date-io/dayjs";
import CALENDER_ICON from "../../assets/icons/calender-icon.svg";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import timeIcon from "./../../assets/icons/time.png";
import servingsIcon from "./../../assets/icons/servings.png";
import info from "./../../assets/icons/info.svg";
import { SSE } from "sse";
import { jsonrepair } from "jsonrepair";
import {
  addDays,
  endOfWeek,
  format,
  isMonday,
  isWithinInterval,
  parse,
  startOfWeek,
  sub,
} from "date-fns";

import styles from "./MealPlan.module.scss";
import { CustomDatePicker } from "../../components/DatePicker/DatePicker";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import { InputAdornment, InputLabel } from "@material-ui/core";
import AnimateElement from "../../components/AnimateElement";
import MealCard from "./card";
import { setTodaysMeal } from "../../state";
import {
  addUserMealPlan,
  getUserData,
  getUserMeal,
} from "../../services/firebase";
import Head from "./header/header";
import Layout from "../../layout";
import EmptyCard from "./emptyCard";
import { AcceptModal } from "./AcceptModal/AcceptModal";
import { MealModal } from "./MealModal/MealModal";
import Dialog from "../../components/Dialog/Dialog";
import { getUserSessionsQuery } from "../../services/queries";
import NutrientsBar from "../../components/NutrientsBar/NutrientsBar";

const MealPlan = () => {
  const userState = useSelector((state) => state.user, shallowEqual);
  const { search } = useLocation();
  const paramss = new URLSearchParams(search);
  const mode = paramss.get("mode");
  const id = paramss.get("id");
  const user = useRef(null);
  const { isLoading: isLoadingSessions, data: sessionsData } =
    getUserSessionsQuery(id ? id : userState?.user?.uid);

  const planListData = [
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Monday",
    },
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Tuesday",
    },
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Wednesday",
    },
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Thursday",
    },
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Friday",
    },
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Saturday",
    },
    {
      breakfast: null,
      lunch: null,
      dinner: null,
      day: "Sunday",
    },
  ];

  const apiKey = "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";
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const jsonRef = useRef();
  const dateRef = useRef();
  const resultRef = useRef();
  let index = useRef(0);
  let planList = useRef([...planListData]);
  let snackList = useRef([]);
  let foodType = useRef("meal");
  const { date } = params;

  const [loading, setLoading] = useState(true);

  const dietitian = useRef(null);
  const [recentFood, setRecentFood] = useState([]);
  const [snack, setSnack] = useState(false);
  const [nutKey, setNutKey] = useState(false);
  const [open, setOpen] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [reload, setReload] = useState(false);
  const [mealOpen, setMealOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [days] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [disabled, setDisabled] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [old, setOld] = useState(true);
  const [notes, setNotes] = useState("");
  const [ai, setAi] = useState("");
  const history = useHistory();
  const moveTo = (path) => history.push(path);
  const [triggerRerender, setTriggerRerender] = useState(false);
  const [cardLoad, setCardLoad] = useState(false);
  const [allowRemove, setAllowRemove] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const sessions = useRef([]);

  const [userStory] = useState([
    `My name is ${userState?.user?.displayName}`,
    `${
      userState?.user?.assignedDietitian
        ? "my dietitian is " + userState?.user?.assignedDietitian?.displayName
        : ""
    }`,
    `${
      userState?.user?.assignedChatGroup
        ? "my dietitian assigned me to " +
          userState?.user?.assignedChatGroup[0]?.uniqueName
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.allergies.length > 0
        ? "my allergies are " +
          userState?.user?.foodPreference?.allergies.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.children
        ? "my children are " + userState?.user?.foodPreference?.children
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.cookingTime
        ? "my best cooking time is " +
          userState?.user?.foodPreference?.cookingTime
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.cuisine
        ? "my best cuisine is " + userState?.user?.foodPreference?.cuisine
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.dislikes.length > 0
        ? "my dislikes are " +
          userState?.user?.foodPreference?.dislikes.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.foodBudget
        ? "my food budget is " + userState?.user?.foodPreference?.foodBudget
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.foodPractice
        ? "i am a " + userState?.user?.foodPreference?.foodPractice
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.healthCondition.length > 0
        ? "my health conditions are " +
          userState?.user?.foodPreference?.healthCondition.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.toddlers
        ? "my toddlers are " + userState?.user?.foodPreference?.toddlers
        : ""
    }`,

    `${
      userState?.user?.quizData?.age
        ? `my age is ${userState?.user?.quizData.age}`
        : ""
    }`,
    `${
      userState?.user?.quizData
        ? `${userState?.user?.quizData.alcohol}, ${userState?.user?.quizData.smoke} and my weight is ${userState?.user?.quizData.weight}`
        : ""
    }`,
    `${
      userState?.user?.quizData?.gender
        ? `my gender is ${userState?.user?.quizData.gender}`
        : ""
    }`,
    `${
      userState?.user?.quizData?.sleep
        ? `i sleep ${userState?.user?.quizData.sleep}`
        : ""
    }`,
  ]);

  const monday = (day) => isMonday(new Date(day));

  const shouldDisableDate = (day) => {
    if (!date) {
      return false;
    }

    return !monday(day);
  };

  const highlightWeekStyle = (day) => {
    if (monday(date) && day.isSame(date, "week")) {
      return { backgroundColor: "#00866e" }; // Customize the background color for the highlighted week
    }
    return {};
  };

  // "servings": ["number"],
  // "ready_in": ["should not be empty should be in this format "5 mins" and it should be in fives]",

  const recipeType = `{
    "recipe_name": "[Name]",
    "nutrients": should be array in this order, protein which should not be more than ${+user
      ?.current?.nutrients
      ?.proteins}, carbohydrate which should not be more than ${+user?.current
    ?.nutrients?.carbs}, fat which should not be more than ${+user?.current
    ?.nutrients?.fats}, calories which should be more than ${
    user?.current?.nutrients?.calories
  } and quantity in value eg [100, 90, 20, 500, 1000]
}`;

  const mealFormat = `
    {
        "breakfast": return as this object  ${recipeType} and should be a meal that can be taken as breakfast, should be unique and not returned before,
        "lunch": return as this object  ${recipeType} and should be a meal that can be taken as lunch, should be unique and not returned before,
        "dinner": return as this object  ${recipeType} and should be a meal that can be taken as dinner, should be unique and not returned before,
    },
`;

  const snackFormat = `
    {
        "snack": type of ${recipeType},
    },
`;

  const singleMealPlanFormat = `
    {
        "data": type of ${recipeType},
    },
`;

  const singleSnackPlanFormat = `
    {
        "snack": type of ${recipeType},
    },
`;

  const clear = () => {
    planList.current = planListData;
    setTriggerRerender(!triggerRerender);
    save();
  };
  const clearSnack = () => {
    snackList.current = [];
    setTriggerRerender(!triggerRerender);
    save();
  };

  const generatePrompt = (type) => {
    let tempFoods = [
      userState?.user?.quizData?.disliked_foods.join(", "),
      userState?.user?.quizData?.allergies.join(", "),
    ];

    for (let i = 0; i < planList.current.length; i++) {
      if (planList.current[i]?.breakfast?.recipe_name) {
        tempFoods.push(planList.current[i]?.breakfast?.recipe_name);
      }
      if (planList.current[i]?.lunch?.recipe_name) {
        tempFoods.push(planList.current[i]?.lunch?.recipe_name);
      }
      if (planList.current[i]?.dinner?.recipe_name) {
        tempFoods.push(planList.current[i]?.dinner?.recipe_name);
      }
    }

    let time =
      selectedCard === "1"
        ? "breakfast"
        : selectedCard === "2"
        ? "lunch"
        : "dinner";

    let extra = `this are the kind of foods i like "${
      user?.current?.foodPreference?.cuisine
    }", 
      here is my most recent meals \n "${recentFood}", 
      this are my allergies "${user?.current?.foodPreference?.allergies.join(
        ", "
      )}", 
      `;

    // Generate a day's meal plan in JSON format, adhering to these guidelines:
    // - Format: ${mealFormat}
    // - Exclude recipes from "${tempFoods.join(", ")}" to avoid duplicates
    // - Base suggestions on this meal history: [${recentFood}]
    // - Ensure all meals are unique and not repeated from the exclusion list.
    // - Incorporate preferences from this additional data: "${extra}"
    // - Use double quotes only and ensure no empty values in the JSON output.

    if (type === "meal") {
      return `
      Create a day meal plan with breakfast launch and dinner in this json format ${mealFormat}
      - ${ai}
      - exclude recipes or products from "${tempFoods.join(
        ", "
      )}" to avoid duplicates,
      - New meals generated should exclude duplicates
      - utilize my history "${extra}" to generate suggestions
      - Use double quotes only and ensure no empty values in the JSON output
     
      `;
    } else if (type === "singleMeal") {
      return `Generate a single meal ${time} plan in this format ${singleMealPlanFormat}
      - ${ai}
      - avoid this recipes "${tempFoods.join(", ")}
      - learn from and use my most recent meal history
      - learn from and factor in "${extra}"
      - New meals generated should exclude recipes from here "${tempFoods.join(
        ", "
      )}"
      - no single quotations
      - always generate a value`;
    } else if (type === "singleSnack") {
      return `Generate a single snack in this format ${snackFormat}
      - ${ai}
      - avoid this recipes "${tempFoods.join(", ")}
      - learn from and use my most recent meal history
      - learn from and factor in "${extra}"
      - New snacks generated should exclude recipes from here "${tempFoods.join(
        ", "
      )}"
      - no single quotations
      - always generate a value`;
    } else if (type === "snack") {
      return `
      Generate a single snack in this format ${snackFormat}
      - ${ai}
      - avoid this recipes "${tempFoods.join(", ")}
      - learn from and use my most recent meal history
      - learn from and factor in "${extra}"
      - New snacks generated should exclude recipes from here "${tempFoods.join(
        ", "
      )}"
      - no single quotations
      - always generate a value
      `;
    }
  };
  const send = async (i, type) => {
    setLoading(true);
    resultRef.current = "";
    jsonRef.current = "";
    //   setLoading(true);
    //   setResult("");
    //   setDataJson("");
    let url = "https://api.openai.com/v1/chat/completions";
    let data = {
      model: "gpt-4o",
      // response_format: { type: "json_object" },
      messages: [
        {
          role: "system",
          content: `${
            user?.current?.foodPreference?.foodPractice
              ? "note that" + user?.current?.foodPreference?.foodPractice
              : ""
          }
          - if i am vegan or vegetarian only give me vegan or vegetarian meals. do not give me meat or fish recipes .
          - also you can give me fish and other seafood but excludes other types of meat such as poultry, beef, pork, and lamb recipes if i am pescetarian,
          `,
        },

        {
          role: "system",
          content: `return the json starting with { and ending with }, 
                    your response should always be a valid json object, 
                    and each value should always be populated with correct data like example given
                    dont return any empty string value
                    `,
        },
        {
          role: "system",
          content: `take this into consideration it is highly important : ${ai}`,
        },
        {
          role: "user",
          content:
            foodType.current === "meal"
              ? generatePrompt("meal")
              : foodType.current === "single"
              ? generatePrompt("singleMeal")
              : generatePrompt("snack"),
        },
        {
          role: "user",
          content: `this are session notes from my dietitian that i need to fulfil, so its very important you consider it.
          ${sessions.current ? sessions.current : null}
          `,
        },
      ],
      temperature: 0.8,
      top_p: 1,
      max_tokens: 1500,
      stream: true,
      n: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    let source = new SSE(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
        "X-Content-Type-Options": "nosniff",
      },
      method: "POST",
      payload: JSON.stringify(data),
    });

    source.addEventListener("message", (e) => {
      if (e.data === "[DONE]") {
        resultRef.current = convertToJSON(jsonRef.current);
        resultRef.current.day = days[i];
        if (resultRef.current) {
          if (type === null) {
            const data =
              foodType.current === "meal"
                ? planList.current
                : snackList.current;
            const tempPlan = [...data];
            tempPlan[i] = resultRef.current;
            foodType.current === "meal"
              ? (planList.current = tempPlan)
              : (snackList.current = tempPlan);
            setNutKey((prev) => !prev);
          } else {
            if (type === 1) {
              planList.current[i] = {
                ...planList.current[i],
                breakfast: resultRef.current.data,
              };
            } else if (type === 2) {
              planList.current[i] = {
                ...planList.current[i],
                lunch: resultRef.current.data,
              };
            } else if (type === 3) {
              planList.current[i] = {
                ...planList.current[i],
                dinner: resultRef.current.data,
              };
            } else if (type === 4) {
              console.log(
                "snackList",
                snackList.current,
                resultRef.current.snack
              );
              snackList.current[i] = {
                ...snackList.current[i],
                snack: resultRef.current.snack,
              };
            }
            setNutKey((prev) => !prev);
          }
          save();
        }
        if (index.current === 6) {
          setLoading(false);
          setDisabled(false);
          save();
        } else {
          index.current = index.current + 1;
          send(index.current, null);
        }
        setTriggerRerender((prev) => !prev);
        setIsNew(true);
      } else {
        let payload = JSON.parse(e.data);
        let text = payload.choices[0].delta.content;
        let data = JSON.parse(e.data).choices[0];
        // let text = JSON.parse(jsonrepair(e.data)).choices[0].text;
        if (data.finish_reason === "stop") {
          source.close();
        } else {
          jsonRef.current = jsonRef.current + text;
          // console.log("text", text);
        }
      }
    });

    source.addEventListener("readystatechange", (e) => {
      if (e.readyState >= 2) {
      }
    });
    source.stream();
    // } else {
    //   dispatch(toast({ message: `Please write a message` }));
    // }
  };

  const addSnack = () => {
    index.current = 0;
    foodType.current = "snack";
    setSnack(true);
    send(index.current, null);
  };

  const convertFractionsToDecimals = (inputString) => {
    const fractionRegex = /(\d+)\s*\/\s*(\d+)/g;

    const convertedString = inputString.replace(
      fractionRegex,
      (match, numerator, denominator) => {
        const decimalValue = numerator / denominator;
        return decimalValue.toString();
      }
    );

    return convertedString;
  };

  const convertToJSON = (string) => {
    // console.log("string", string);
    const jsonStartIndex = string.indexOf("{");
    const jsonEndIndex = string.lastIndexOf("}");

    const jsonString = string.substring(jsonStartIndex, jsonEndIndex + 1);

    let mainJson = null;
    // if (jsonString.length > 2) {
    // console.log("jsonString", string, jsonStartIndex, jsonString, jsonEndIndex);

    try {
      mainJson = JSON.parse(jsonrepair(jsonString));
    } catch (err) {
      // setMessages([
      //   ...messages,
      //   {
      //     role: "assistant",
      //     content: `Oops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
      //   },
      // ]);
      // setRemove2(true);
      console.log("eer", err);
    }
    // } else {
    //   console.log("error found me");
    // }
    return mainJson;
  };

  const save = async () => {
    setLoading(true);
    const payload = {
      mealPlan: planList.current,
      snackPlan: snackList.current,
      date,
      note: mode === "diet" ? notes : "",
      isDiet: mode === "diet" ? ai : "",
    };

    // console.log("save", payload);

    // console.log("payload", payload, userState);
    try {
      const it = await addUserMealPlan(user?.current?.uid, payload);
      setDisabled(true);
      setIsNew(false);
      setOld(true);
      if (index.current === 6) {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getMealPlan = async () => {
    setLoading(false);
    const currentDay = format(new Date(), "EEEE");
    try {
      const data = await getUserMeal(user?.current?.uid, date);
      if (data) {
        if (data?.mealPlan.length > 0) {
          planList.current = data.mealPlan;
          const mealPlanData = data.mealPlan.filter(
            (item) => item?.day.toLowerCase() === currentDay.toLowerCase()
          );
          if (mealPlanData.length > 0) {
            dispatch(setTodaysMeal(mealPlanData));
          }
        }
        setNotes(data.note ? data.note : "");
        setAi(data.isDiet ? data.isDiet : "");
        if (data?.snackPlan.length > 0) {
          setSnack(true);
          snackList.current = data.snackPlan;
        } else {
          setSnack(false);
          // planList.current = [...planListData];
        }
        setOld(true);
      } else {
        planList.current = [...planListData];
        snackList.current = [];
        setTriggerRerender((prev) => !prev);
        setOld(false);
      }
      setTriggerRerender((prev) => !prev);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getSingleChat = (i, type) => {
    setLoading(true);
    foodType.current = type === 4 ? "singleSnack" : "single";
    index.current = 6;
    send(i, type);
  };

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const daysOfWeek = [];
  //   for (let i = 0; i < 7; i++) {
  //     const nextDate = addDays(currentDate, i);
  //     const dayOfWeek = format(nextDate, "EEEE");

  //     daysOfWeek.push(dayOfWeek);
  //   }
  //   setDays(daysOfWeek);
  // }, [triggerRerender]);
  const isDateBetween = (checkDate, startDate, endDate) => {
    const checkDateObj = parse(checkDate, "yyyy-MM-dd", new Date());
    const startDateObj = parse(startDate, "yyyy-MM-dd", new Date());
    const endDateObj = parse(endDate, "yyyy-MM-dd", new Date());

    return isWithinInterval(checkDateObj, {
      start: startDateObj,
      end: endDateObj,
    });
  };

  useEffect(() => {
    // setUserData();
    // if(snackList.current.length < 1) {
    //   addSnack()
    // }
    const setUserData = async () => {
      if (mode === "diet") {
        const patients = await getUserData(id);
        user.current = patients;
      } else {
        user.current = userState?.user;
        const dietitan = await getUserData(
          userState?.user?.assignedDietitian?.uid
        );
        dietitian.current = dietitan;
      }

      if (user) {
        const newDate = format(new Date(), "yyyy-MM-dd");
        let mainMeal = user?.current?.events
          ? user?.current?.events.slice(-40)
          : [];
        const latestMeal = [];

        for (let i = 0; i < mainMeal.length; i++) {
          if (mainMeal[i].food) {
            if (!latestMeal.includes(mainMeal[i].food.items[0].Item.name)) {
              latestMeal.push(mainMeal[i].food.items[0].Item.name);
            }
          }
        }

        setRecentFood(latestMeal);

        if (date) {
          // fetch data with date
          getMealPlan();
        } else {
          if (isMonday(new Date(newDate))) {
            // moveTo(`/mealplan/${newDate}`);
            if (mode === "diet") {
              moveTo(`/mealplan/${newDate}?mode=diet&id=${id}`);
            } else {
              moveTo(`/mealplan/${newDate}`);
            }
          } else {
            let day = format(
              startOfWeek(new Date(newDate), { weekStartsOn: 1 }),
              "yyyy-MM-dd"
            );

            // moveTo(`/mealplan/${day}`);
            if (mode === "diet") {
              moveTo(`/mealplan/${day}?mode=diet&id=${id}`);
            } else {
              moveTo(`/mealplan/${day}`);
            }
          }
        }
      }
    };
    setUserData();
  }, [date, mode, reload]);

  useEffect(() => {
    if (mode === "diet") {
      if (!isLoadingSessions) {
        const finalSessions = [];
        let tempNotes = "";
        const sortedSessions =
          sessionsData.length > 0
            ? sessionsData.sort(
                (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
              )
            : [];
        for (let i = 0; i < sessionsData.length; i++) {
          finalSessions.push({
            ...sortedSessions[i],
            startDate: format(
              new Date(sortedSessions[i].createdOn),
              "yyyy-MM-dd"
            ),
            endDate: sortedSessions[i + 1]
              ? format(
                  sub(new Date(sortedSessions[i + 1].createdOn), { days: 1 }),
                  "yyyy-MM-dd"
                )
              : format(new Date(), "yyyy-MM-dd"),
          });
        }
        for (let i = 0; i < finalSessions.length; i++) {
          if (
            isDateBetween(
              date,
              finalSessions[i].startDate,
              finalSessions[i].endDate
            )
          ) {
            for (let j = 0; j < finalSessions[i].notes.length; j++) {
              tempNotes = `${tempNotes}
             - ${finalSessions[i].notes[j]}`;
            }
          }
        }
        sessions.current = tempNotes;
      }
    }
  }, [sessionsData]);

  const CustomDisplayComponent = ({ value, onClick }) => {
    // Your custom logic to determine what to display in the DatePicker
    const displayValue = "Select a date";

    return (
      <div onClick={onClick}>
        {date && (
          <span>
            {/* const sevenDaysFromNow = addDays(today, 7); */}
            {format(new Date(date), "d MMM")} -{" "}
            {format(new Date(addDays(new Date(date), 6)), "d MMM")}
            <img className={styles.cal} src={CALENDER_ICON} alt="calendar" />
          </span>
        )}
      </div>
    );
  };

  const refresh = (i, type) => {
    if (type === 1) {
      planList.current[i].breakfast = null;
    } else if (type === 2) {
      planList.current[i].lunch = null;
    } else if (type === 3) {
      planList.current[i].dinner = null;
    } else if (type === 4) {
      snackList.current[i].snack = null;
    }
    setOpen(false);
    index.current = 6;
    save();
  };

  return (
    <Layout header={false}>
      <div style={{ paddingBottom: "100px" }}>
        <Head />

        <div className={styles.page}>
          {loading && (
            <div className={styles.con}>
              <div className={styles.ldsEllipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>{" "}
              <div className={styles.text}></div>
            </div>
          )}
          <div className={styles.date}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <DatePicker
                TextFieldComponent={CustomDisplayComponent}
                format="D MMM"
                value={date}
                shouldDisableDate={shouldDisableDate}
                onChange={(selectedDate) => {
                  if (mode === "diet") {
                    moveTo(
                      `/mealplan/${format(
                        selectedDate.$d,
                        "yyyy-MM-dd"
                      )}?mode=diet&id=${id}`
                    );
                  } else {
                    moveTo(
                      `/mealplan/${format(selectedDate.$d, "yyyy-MM-dd")}`
                    );
                  }

                  getMealPlan();
                  setLoading(true);
                  setTriggerRerender((prev) => !prev);
                }}
                className={styles.clear}
                InputProps={{
                  disableUnderline: true,
                  className: styles.clear,
                  endAdornment: (
                    <InputAdornment position="start">
                      <img
                        className={styles.cal}
                        src={CALENDER_ICON}
                        alt="calendar"
                      />
                    </InputAdornment>
                  ),
                }}
                renderDay={(
                  day,
                  selectedDate,
                  isInCurrentMonth,
                  dayComponent
                ) => {
                  const dayStyles = highlightWeekStyle(day);
                  return React.cloneElement(dayComponent, { style: dayStyles });
                }}
              />
            </MuiPickersUtilsProvider>
            <button
              className={`${styles.btn} ${styles.right} `}
              onClick={() => setOpenC(true)}
              disabled={loading}
            >
              Clear
            </button>
          </div>
          {mode === "diet" && (
            <div className={styles.diet}>
              <div className={styles.note}>
                <div className={styles.notes}>Notes:</div>
                <textarea
                  className={styles.textArea}
                  value={notes}
                  rows="3"
                  onChange={(e) => setNotes(e.target.value)}
                />
                <button
                  className={styles.saveNote}
                  onClick={() => {
                    index.current = 6;
                    save();
                  }}
                  disabled={loading}
                >
                  Save
                </button>
              </div>
              <div className={styles.ai}>
                <div className={styles.notes}>
                  AI generator:{" "}
                  <div
                    className={styles.ilo}
                    onClick={() => moveTo(`/dietitian/health-profile/${id}`)}
                  >
                    <img src={info} alt="preference" />
                  </div>
                </div>
                <textarea
                  className={styles.textArea}
                  value={ai}
                  rows="3"
                  onChange={(e) => setAi(e.target.value)}
                />
                <div className={styles.allBtns}>
                  <button
                    className={`${styles.btn} `}
                    onClick={() => {
                      foodType.current = "meal";
                      index.current = 0;
                      send(index.current, null);
                    }}
                    disabled={loading}
                  >
                    Create meal plans
                  </button>
                  <button
                    className={`${styles.btn} `}
                    onClick={() =>
                      snackList?.current?.length > 0
                        ? setOpenS(true)
                        : addSnack()
                    }
                    disabled={loading}
                  >
                    {snackList?.current?.length > 0
                      ? "Remove snack"
                      : "Add snack"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {mode !== "diet" && (
            <div className={styles.allBtns}>
              {/* <button className={`${styles.btn} `}>Add to shopping list</button> */}
              {!ai && (
                <div>
                  <button
                    className={`${styles.btn} `}
                    onClick={() => {
                      if (ai) {
                        setIsOpen(true);
                      } else {
                        foodType.current = "meal";
                        index.current = 0;
                        send(index.current, null);
                      }
                    }}
                    disabled={loading}
                  >
                    Create meal plans
                  </button>
                </div>
              )}
              {ai && (
                <button className={`${styles.btnBlock} `}>
                  Dietitian plan
                  <span className={styles.block}></span>
                </button>
              )}
              {!ai && (
                <button
                  className={`${styles.btn} `}
                  onClick={() => addSnack()}
                  disabled={loading}
                >
                  Add snack{" "}
                </button>
              )}
            </div>
          )}

          {notes && mode !== "diet" && (
            <div className={styles.vis}>
              <img
                className={styles.dietImg}
                src={dietitian?.current?.photoURL}
                alt="dietitian"
              />
              <div className={styles.val}>
                <div className={styles.tops}>Dietitian plan</div>
                <div className={styles.bots}>{notes}</div>
              </div>
            </div>
          )}

          <div className={styles.meals}>
            <div className={styles.tags}>
              <div className={styles.tag}>Breakfast</div>
              <div className={styles.tag}>Lunch</div>
              <div className={styles.tag}>Dinner</div>
              {<div className={styles.tag}>Snacks</div>}
            </div>

            {planList.current.length > 0 && (
              <>
                {" "}
                {planList.current.map((item, t) => (
                  <div className={styles.days} key={t}>
                    {item && item?.day && (
                      <div className={styles.day}>{item?.day}</div>
                    )}
                    {mode === "diet" && (
                      <>
                        {item?.breakfast?.nutrients && (
                          <div className={styles.nutrientsBar}>
                            <NutrientsBar
                              key={nutKey}
                              selectedItem={{
                                served: 100,
                                quantity: item.breakfast.nutrients[4] + "g",
                                nutriments: {
                                  proteins:
                                    (item?.breakfast?.nutrients[0]
                                      ? item?.breakfast?.nutrients[0]
                                      : 0) +
                                    (item?.lunch?.nutrients[0]
                                      ? item?.lunch?.nutrients[0]
                                      : 0) +
                                    (item?.dinner?.nutrients[0]
                                      ? item?.dinner?.nutrients[0]
                                      : 0),
                                  carbohydrates:
                                    (item?.breakfast?.nutrients[1]
                                      ? item?.breakfast?.nutrients[1]
                                      : 0) +
                                    (item?.lunch?.nutrients[1]
                                      ? item?.lunch?.nutrients[1]
                                      : 0) +
                                    (item?.dinner?.nutrients[1]
                                      ? item?.dinner?.nutrients[1]
                                      : 0),
                                  fat:
                                    (item?.breakfast?.nutrients[2]
                                      ? item?.breakfast?.nutrients[2]
                                      : 0) +
                                    (item?.lunch?.nutrients[2]
                                      ? item?.lunch?.nutrients[2]
                                      : 0) +
                                    (item?.dinner?.nutrients[2]
                                      ? item?.dinner?.nutrients[2]
                                      : 0),
                                  energy_value:
                                    (item?.breakfast?.nutrients[3]
                                      ? item?.breakfast?.nutrients[3]
                                      : 0) +
                                    (item?.lunch?.nutrients[3]
                                      ? item?.lunch?.nutrients[3]
                                      : 0) +
                                    (item?.dinner?.nutrients[3]
                                      ? item?.dinner?.nutrients[3]
                                      : 0),
                                },
                              }}
                              nutrients={
                                user?.current?.nutrients
                                  ? user?.current?.nutrients
                                  : {
                                      show: false,
                                      carbs: 0,
                                      fat: 0,
                                      proteins: 0,
                                      calories: 0,
                                    }
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                    <div className={styles.cards}>
                      {!item || item.breakfast === null ? (
                        <EmptyCard
                          addMeal={() => {
                            setAllowRemove(false);
                            setOpen(true);
                            setSelectedIndex(t);
                            setSelectedCard(1);
                            // getSingleChat(t, 1);
                          }}
                          loading={cardLoad}
                        />
                      ) : (
                        <MealCard
                          plan={item.breakfast}
                          background="#ffd772"
                          refresh={() => {
                            setAllowRemove(true);
                            setOpen(true);
                            setSelectedIndex(t);
                            setSelectedCard(1);
                          }}
                        />
                      )}
                      {!item || item?.lunch === null ? (
                        <EmptyCard
                          addMeal={() => {
                            setAllowRemove(false);
                            setOpen(true);
                            setSelectedIndex(t);
                            setSelectedCard(2);
                            // getSingleChat(t, 2);
                          }}
                          loading={cardLoad}
                        />
                      ) : (
                        <MealCard
                          plan={item.lunch}
                          background="#ffeaa9"
                          refresh={() => {
                            setAllowRemove(true);
                            setOpen(true);
                            setSelectedIndex(t);
                            setSelectedCard(2);
                          }}
                        />
                      )}

                      {!item || item?.dinner === null ? (
                        <EmptyCard
                          addMeal={() => {
                            setAllowRemove(false);
                            setOpen(true);
                            setSelectedIndex(t);
                            setSelectedCard(3);
                            // getSingleChat(t, 3);
                          }}
                          loading={cardLoad}
                        />
                      ) : (
                        <MealCard
                          plan={item.dinner}
                          background="#ffeec2"
                          refresh={() => {
                            setAllowRemove(true);
                            setOpen(true);
                            setSelectedIndex(t);
                            setSelectedCard(3);
                          }}
                        />
                      )}
                      {snackList.current.length - 1 >= t && (
                        <>
                          {!snackList?.current[t]?.snack ||
                          snackList?.current[t]?.snack === null ? (
                            <EmptyCard
                              addMeal={() => {
                                setAllowRemove(false);
                                setOpen(true);
                                setSelectedIndex(t);
                                setSelectedCard(4);
                                // getSingleChat(t, 3);
                              }}
                              loading={cardLoad}
                            />
                          ) : (
                            <MealCard
                              plan={snackList?.current[t]?.snack}
                              background="#ffe0a5"
                              refresh={() => {
                                setAllowRemove(true);
                                setOpen(true);
                                setSelectedIndex(t);
                                setSelectedCard(4);
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {/* {!loading && (
              <button
                className={`${styles.btn} ${styles.save}`}
                onClick={() => save()}
                disabled={loading}
              >
                Save
              </button>
            )} */}
            {/* {planList.current.length <= 0 && (
              <div className={styles.no}>No Data Available</div>
            )} */}
          </div>
        </div>
      </div>
      <AcceptModal
        open={open}
        onClose={() => setOpen(false)}
        allowRemove={allowRemove}
        selectedCard={selectedCard}
        data={(type) =>
          type === "new"
            ? (getSingleChat(selectedIndex, selectedCard), setOpen(false))
            : type === "singleSnack"
            ? (getSingleChat(selectedIndex, selectedCard), setOpen(false))
            : type === "remove"
            ? refresh(selectedIndex, selectedCard)
            : (setMealOpen(true), setOpen(false))
        }
      />

      <MealModal
        open={mealOpen}
        onCloseModal={() => setMealOpen(false)}
        day={selectedIndex}
        type={selectedCard}
        complete={(data) => {
          if (selectedCard === 1) {
            planList.current[selectedIndex] = {
              ...planList.current[selectedIndex],
              breakfast: data.data,
            };
          } else if (selectedCard === 2) {
            planList.current[selectedIndex] = {
              ...planList.current[selectedIndex],
              lunch: data.data,
            };
          } else if (selectedCard === 3) {
            planList.current[selectedIndex] = {
              ...planList.current[selectedIndex],
              dinner: data.data,
            };
          } else if (selectedCard === 4) {
            console.log("snackList", snackList.current);
            snackList.current[selectedIndex] = {
              ...snackList.current[selectedIndex],
              snack: data.data,
            };
          }

          index.current = 6;
          setNutKey((prev) => !prev);
          save();
          setMealOpen(false);
        }}
        // data={(type) =>
        //   type === "new"
        //     ? (getSingleChat(selectedIndex, selectedCard), setOpen(false))
        //     : null
        // }
      />
      <Dialog
        open={Boolean(isOpen)}
        message={`Are you sure you want to change this meal plan? changing it will override your current meal plan which was set by your dietitian`}
        onCancel={() => [setIsOpen(false)]}
        onConfirm={() => {
          foodType.current = "meal";
          index.current = 0;
          setAi("");
          setNotes("");
          send(index.current, null);
          setIsOpen(false);
        }}
        isLoading={false}
        isLoadingText="No Loading"
      />

      <Dialog
        open={Boolean(openC)}
        message={`are you sure you want to clear this meal plan`}
        onCancel={() => [setOpenC(false)]}
        onConfirm={() => {
          clear();
          index.current = 6;
          setOpenC(false);
        }}
        isLoading={false}
        isLoadingText="No Loading"
      />

      <Dialog
        open={Boolean(openS)}
        message={`are you sure you want to clear snacks`}
        onCancel={() => [setOpenS(false)]}
        onConfirm={() => {
          clearSnack();
          index.current = 6;
          setOpenS(false);
        }}
        isLoading={false}
        isLoadingText="No Loading"
      />
    </Layout>
  );
};

export default MealPlan;
