import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import cx from "classnames";
import { FieldArray } from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';


import backIcon from "./../../../../assets/icons/back-light.png";
import infoSquareIcon from "./../../../../assets/icons/info-square-light.png";
import labelIcon from "./../../../../assets/icons/label.png";
import { updateRecipe  } from "../../../../services/firebase";
import { toast, } from "../../../../state";


import CustomInput from "../../../../components/CustomInput/CustomInput";




import styles from "./Directions.module.scss";

const Directions = (props) => {
    const { setActiveIndex, selectedBrands, formik, stage } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleUpdateSteps = async () => {
        try {
            await updateRecipe(formik.values)
            dispatch(toast({message : "Recipe step updated successfully"}))
            history.goBack()
        } catch (error) {
            dispatch(toast({message : "Failed to update recipe step"}));
        }
    }

    return (
       <div className={styles.page}>
          <div className={styles.header}>
             <IconButton size='small' onClick={() => setActiveIndex(1)}>
                <Avatar alt='back' sizes='small' variant='square' src={backIcon} className={styles.icon} />
             </IconButton>
             <div className={styles.stepContainer}>
                <span className={styles.stepIndicator} />
                <span className={styles.stepIndicatorActive} />
                <span className={styles.stepIndicator} />
                <span className={styles.stepIndicator} />
             </div>
             <IconButton size='small'>
                <Avatar alt='share' sizes='small' variant='square' src={infoSquareIcon} className={styles.icon} />
             </IconButton>
          </div>
          <div className={styles.headerBottom} />
          <div className={styles.content}>
             <div className={cx("mb-2", styles.headerText)}>02. Directions</div>
             <div className={cx("mb-4", styles.lightText)}>Describe each direction of your recipe, uploading pictures/videos and descriptions.</div>
             <FieldArray
                name='steps'
                render={(arrayHelpers) => (
                   <>
                      {formik.values.steps &&
                         formik.values.steps.length > 0 &&
                         formik.values.steps.map((step, index) => (
                            <div key={index} className={styles.expandItemContainer}>
                               <IconButton size='small' className={styles.closeButton} onClick={() => arrayHelpers.remove(index)}>
                                  <CancelIcon color='primary' />
                               </IconButton>
                               <Accordion expanded={expanded === index} onChange={handleChange(index)} className={cx(styles.expandItem)}>
                                  <AccordionSummary
                                     expandIcon={<ExpandMoreIcon className={styles.expandItemArrow} size='small' />}
                                     IconButtonProps={{ size: "small" }}
                                  >
                                     <div className='d-flex flex-nowrap align-items-center w-100 '>
                                        <Avatar alt='back' sizes='small' variant='square' src={labelIcon} className={styles.labelIcon} />
                                        <span className={styles.itemHeaderText}>Step {index + 1 < 10 ? `0${index + 1}` : index + 1}.</span>
                                        {formik.values.steps[index].thumbnail &&
                                           formik.values.steps[index].title &&
                                           formik.values.steps[index].ingredientsUsed.length !== 0 &&
                                           formik.values.steps[index].description && <CheckOutlinedIcon className={styles.checkedIcon} />}
                                     </div>
                                  </AccordionSummary>
                                  <AccordionDetails className='p-0'>
                                     <div className='d-block w-100'>
                                        <div className={styles.line} />

                                        <div className='pt-2 pb-3 px-3'>
                                           <div className='mb-3'>
                                              {formik.values.steps[index].thumbnail ? (
                                                 <div
                                                    className={styles.thumbnail}
                                                    style={{ backgroundImage: `url(${formik.values.steps[index].thumbnail})` }}
                                                 >
                                                    <label>
                                                       <PhotoCameraOutlinedIcon color='primary' className={styles.pointer} />
                                                       <input
                                                          hidden
                                                          type='file'
                                                          accept='image/*'
                                                          onChange={(e) => {
                                                             const file = e.target.files[0];
                                                             if (file && file.type.match("image.*")) {
                                                                let reader = new FileReader();
                                                                reader.onload = function (event) {
                                                                   formik.setFieldValue(`steps[${index}].thumbnail`, event.target.result);
                                                                };
                                                                reader.readAsDataURL(e.target.files[0]);
                                                             }
                                                          }}
                                                       />
                                                    </label>
                                                 </div>
                                              ) : (
                                                 <label className={cx(styles.thumbnail, styles.pointer)}>
                                                    <PhotoCameraOutlinedIcon color='primary' />
                                                    <input
                                                       hidden
                                                       type='file'
                                                       accept='image/*'
                                                       onChange={(e) => {
                                                          const file = e.target.files[0];
                                                          if (file && file.type.match("image.*")) {
                                                             let reader = new FileReader();
                                                             reader.onload = function (event) {
                                                                formik.setFieldValue(`steps[${index}].thumbnail`, event.target.result);
                                                             };
                                                             reader.readAsDataURL(e.target.files[0]);
                                                          }
                                                       }}
                                                    />
                                                 </label>
                                              )}
                                           </div>

                                           <div className='mb-3'>
                                              <CustomInput
                                                 label='Title'
                                                 placeholder='e.g. Spaghetti preparation'
                                                 {...formik.getFieldProps(`steps[${index}].title`)}
                                                 error={Boolean(
                                                    formik.touched.steps &&
                                                       formik.touched.steps[index] &&
                                                       formik.touched.steps[index].title &&
                                                       formik.errors.steps &&
                                                       formik.errors.steps[index] &&
                                                       formik.errors.steps[index].title
                                                 )}
                                                 helperText={
                                                    formik.touched.steps &&
                                                    formik.touched.steps[index] &&
                                                    formik.touched.steps[index].title &&
                                                    formik.errors.steps &&
                                                    formik.errors.steps[index] &&
                                                    formik.errors.steps[index].title
                                                 }
                                              />
                                           </div>

                                           <div className='mb-3'>
                                              <div className={styles.titleText}>Ingredients used</div>
                                              <FieldArray
                                                 name={`steps[${index}].ingredientsUsed`}
                                                 render={(arrayHelpers) => (
                                                    <>
                                                       {step.ingredientsUsed.map((ingredientUsed, ingredientUsedIndex) => (
                                                          <div key={ingredientUsedIndex} className={styles.selectedInputContainer}>
                                                             <IconButton
                                                                size='small'
                                                                className={styles.closeButton}
                                                                onClick={() => arrayHelpers.remove(ingredientUsedIndex)}
                                                             >
                                                                <CancelIcon color='primary' />
                                                             </IconButton>
                                                             <Select
                                                                displayEmpty
                                                                className={styles.selectedInput}
                                                                classes={{ select: styles.select, icon: styles.dropDownIcon }}
                                                                input={<InputBase />}
                                                                IconComponent={ArrowBackIosOutlinedIcon}
                                                                {...formik.getFieldProps(`steps[${index}].ingredientsUsed[${ingredientUsedIndex}]`)}
                                                             >
                                                                {[...selectedBrands, ...formik.values.ingredients].map(
                                                                   (ingredientAndBrand, index) => (
                                                                      <MenuItem key={index} value={ingredientAndBrand.name}>
                                                                         {ingredientAndBrand.name}
                                                                      </MenuItem>
                                                                   )
                                                                )}
                                                             </Select>
                                                          </div>
                                                       ))}

                                                       <Select
                                                          displayEmpty
                                                          renderValue={(answer) =>
                                                             answer ? answer : <span className={styles.placeHolder}>Add ingredient</span>
                                                          }
                                                          className={styles.selectInput}
                                                          classes={{ select: styles.select, icon: styles.dropDownIcon }}
                                                          input={<InputBase />}
                                                          IconComponent={ArrowBackIosOutlinedIcon}
                                                          value=''
                                                          onChange={(event) => {
                                                             arrayHelpers.push(event.target.value);
                                                          }}
                                                       >
                                                          {[...selectedBrands, ...formik.values.ingredients].map((ingredientAndBrand, index) => (
                                                             <MenuItem key={index} value={ingredientAndBrand.name}>
                                                                {ingredientAndBrand.name}
                                                             </MenuItem>
                                                          ))}
                                                       </Select>
                                                    </>
                                                 )}
                                              />
                                           </div>

                                           <div className={styles.titleText}>Description</div>
                                           <InputBase
                                              fullWidth
                                              multiline
                                              rows={4}
                                              className={styles.descriptionInput}
                                              placeholder='Describe in a simple and clear way what to do in this step. If there are too many actions, split the step in more ones.'
                                              {...formik.getFieldProps(`steps[${index}].description`)}
                                           />
                                           {formik.touched.steps &&
                                              formik.touched.steps[index] &&
                                              formik.touched.steps[index].description &&
                                              formik.errors.steps &&
                                              formik.errors.steps[index] &&
                                              formik.errors.steps[index].description && (
                                                 <FormHelperText error>{formik.errors.steps[index].description}</FormHelperText>
                                              )}
                                        </div>
                                     </div>
                                  </AccordionDetails>
                               </Accordion>
                            </div>
                         ))}
                      <ButtonBase
                         className={styles.addStepButton}
                         onClick={() => {
                            arrayHelpers.push({
                               thumbnail: null,
                               title: "",
                               ingredientsUsed: [],
                               description: "",
                            });
                         }}
                      >
                         Add step
                      </ButtonBase>
                   </>
                )}
             />
          </div>
          {stage ? (
             <ButtonBase disabled={Boolean(formik.errors.steps)} className={styles.nextButton} onClick={handleUpdateSteps}>
                {"Update"}
             </ButtonBase>
          ) : (
             <ButtonBase disabled={Boolean(formik.errors.steps)} className={styles.nextButton} onClick={() => setActiveIndex(3)}>
                {"Next >"}
             </ButtonBase>
          )}
       </div>
    );
}

export default Directions;
