import React, { useMemo } from "react";
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';


import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import discountIcon from "./../../../../assets/icons/discount.png";

import { updateCart, toast } from "../../../../state";
import styles from "./GroceryRewardWidget.module.scss";


const colors = [
    {
        textColor: "#656462",
        backgroundColor: "rgba(192, 187, 184, 0.2)",
    },
    {
        textColor: "#005DB5",
        backgroundColor: "rgba(1, 114, 190, 0.08",
    },
    {
        textColor: "#656714",
        backgroundColor: "rgba(215, 198, 84, 0.25)",
    },
    {
        textColor: "#656714",
        backgroundColor: "rgba(255, 217, 102, 0.4)",
    },
    {
        textColor: "#CD091D",
        backgroundColor: "rgba(178, 11, 27, 0.1)",
    },
    {
        textColor: "#D5AC7F",
        backgroundColor: "rgba(227, 198, 124, 0.15)",
    },
    {
        textColor: "#DF6701",
        backgroundColor: "rgba(243, 155, 69, 0.15)",
    }
]



const GroceryRewardWidget = (props) => {
    const { reward, userState, cartState } = props;
    const dispatch = useDispatch();
    const color = useMemo(() => colors[Math.floor(Math.random() * colors.length)], []);

    const updateCartItem = (reward, quantity) => {
        dispatch(updateCart({
            ...reward,
            quantity
        }))
    }

    const addToCart = () => {
        const availablePoints = userState.user.points;
        const rewards = cartState.cart.filter(cartItem => Boolean(cartItem.rewardId));
        const totalPoints = rewards.reduce((accumulator, cartItem) => {
            return accumulator + cartItem.points
        }, 0);
        if (availablePoints < (totalPoints + reward.points)) {
            dispatch(toast({ message: "Insufficient points" }))
            return
        }
        updateCartItem(reward, 1);
    }

    const removeFromCart = () => {
        updateCartItem(reward, 0);
    }

    return (
        <Card
            component="div"
            className={styles.productWidgetContainer}
            style={{ backgroundColor: color.backgroundColor }}
        >
            <ButtonBase
                className={cartState.cart.find(item => item.rewardId === reward.rewardId) ? styles.selected : styles.add}
                onClick={(e) => {
                    e.stopPropagation();
                    cartState.cart.find(item => item.rewardId === reward.rewardId) ? removeFromCart() : addToCart();
                }}>
                {cartState.cart.find(item => item.rewardId === reward.rewardId) ? <CheckIcon fontSize="small" /> : <AddIcon fontSize="small" />}
            </ButtonBase>
            <div className={styles.img} style={{ backgroundImage: `url(${reward.thumbnail})` }} />
            <Typography classes={{ body1: styles.desc }} style={{ color: color.textColor }} variant="body1" display="block" noWrap>{reward.description}</Typography>
            <Typography classes={{ body1: styles.name }} variant="body1" display="block" noWrap>{reward.name}</Typography>
            <Typography classes={{ body1: styles.size }} variant="body1" display="block" noWrap>{reward.size}</Typography>
            <div className={styles.pointContainer}>
                <span className={styles.points} style={{ backgroundColor: color.textColor }}>{reward.points} pt</span>
            </div>
        </Card>
    )
}

GroceryRewardWidget.propTypes = {
    reward: PropTypes.object
};

export default GroceryRewardWidget;