import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./Modal.module.scss";
import { toast, setUser } from "../../../state";
import modalHandle from "../../../assets/modal-handle.png";
import Slider from "@material-ui/core/Slider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import OneGlass from "../../../assets/1-water-glass.svg";
import TwoGlasses from "../../../assets/2-water-glass.svg";
import OneBottle from "../../../assets/water-bottle.svg";
import Done from "../../../assets/checked-blue-btn.svg";
import {
  addUserEventsWater,
  getUserData,
} from "../../../services/firebase/index";
import dayjs from "dayjs";
import { format } from 'date-fns'

const PrettoSlider = withStyles({
  root: {
    color: "#130F26",
    height: 20,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -3,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 20,
    borderRadius: 100,
  },
  rail: {
    height: 20,
    borderRadius: 100,
  },
  mark: {
    margin: "9px -4px 9px",
  },
})(Slider);

const marks = [
  {
    value: 0,
  },
  {
    value: 220,
  },
  {
    value: 440,
  },
  {
    value: 500,
  },
];

const today = dayjs();

export const WaterModal = ({ 
  open, 
  onClose,
  currentEvent,
  selectedTime,
  setSelectedTime,
  selectedDate,
  setSelectedDate,
  date

}) => {
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(220);
  const [processing, setProcessing] = useState(false);
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;

  const handleSliderChange = (event, newValue) => {
    if (event.type === "touchstart" && event.cancelable) {
      event.preventDefault();
    }
    setSliderValue(newValue);
  };

  const handleClick = async () => {
    const payload = {
      quantity: sliderValue,
      time: new Date(selectedDate.$d),
      hour: new Date(selectedDate.$d).getHours(),
      id: selectedDate.format("ddd D MMM"),
      date: format(selectedDate.$d, 'yyyy-MM-dd')

    };
    setProcessing(true);
    try {
      await addUserEventsWater(user.uid, payload);
      const userData = await getUserData(user.uid);
      dispatch(setUser(userData));
      setProcessing(false);
      onClose();
      dispatch(toast({ message: "Water added successfully" }));
    } catch (err) {
      setProcessing(false);
      dispatch(toast({ message: "an error occurred adding event" }));
    }
  };

  useEffect(() => {
    open ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
 }, [open])

  return (
    <>
      <div
        className={`${styles.modal} ${open ? styles.open : ""}`}
        onClick={onClose}
      >
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <img src={modalHandle} alt="" />
            <p className={styles.text}>How much water have you drunk?</p>
          </div>
          <div className={styles.modalDesc}>
            <div className={styles.imgContent}>
              <img
                src={
                  sliderValue === 220
                    ? OneGlass
                    : sliderValue === 440
                    ? TwoGlasses
                    : sliderValue === 500
                    ? OneBottle
                    : "No Water"
                }
                alt="glassofwater"
              />
              <div className={styles.textCtn}>
                <p className={styles.text}>
                  {sliderValue === 220
                    ? "A Glass"
                    : sliderValue === 440
                    ? "Two glasses"
                    : sliderValue === 500
                    ? "One Bottle"
                    : ""}
                </p>
                <p className={styles.text2}>{`${sliderValue}ml`}</p>
              </div>
            </div>
            <div
              style={{
                width: "300px",
                margin: "28px auto 0 ",
              }}
            >
              <PrettoSlider
                // key={`slider-${sliderValue}`}
                value={sliderValue}
                step={null}
                marks={marks}
                valueLabelDisplay="on"
                max={500}
                onChange={handleSliderChange}
              />
            </div>

            <div className={styles.checkMark} onClick={handleClick}>
              {processing ? (
                <CircularProgress color="inherit" size="18px" />
              ) : (
                <img src={Done} alt="checkMark" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
