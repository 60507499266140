import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';


import infoSquareIcon from "./../../../../assets/icons/info-square-light.png";


import inputOption from "../../../../constant";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../../components/CustomInput/CustomInput";



import styles from "./Ingredients.module.scss";
const Ingredients = (props) => {
    const { setActiveIndex, formik } = props;
    const scrollItem = useRef();
    const history = useHistory();

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <IconButton size="small"   onClick={() => (history.length ? history.goBack() : history.push("/shop"))}>
                   <CloseOutlinedIcon className={styles.icon} />{" "}
                </IconButton>
                <div className={styles.stepContainer}>
                    <span className={styles.stepIndicatorActive} />
                    <span className={styles.stepIndicator} />
                    <span className={styles.stepIndicator} />
                    <span className={styles.stepIndicator} />
                </div>
                <IconButton size="small">
                    <Avatar alt="share" sizes="small" variant="square" src={infoSquareIcon} className={styles.icon} />
                </IconButton>
            </div>
            <div className={styles.headerBottom} />
            <div className={styles.content}>
                <div className={cx("mb-2", styles.headerText)}>01. Ingredients</div>
                <div className={cx("mb-4", styles.lightText)}>Add all the ingredients the recipe required.</div>
                <FieldArray
                    name="ingredients"
                    render={arrayHelpers => (
                        <>
                            {formik.values.ingredients && formik.values.ingredients.length > 0 && (
                                formik.values.ingredients.map((ingredient, index) => (
                                    <div key={index} className={cx(styles.ingredientItem, "mb-5")}>
                                        <IconButton
                                            size="small"
                                            className={styles.closeButton}
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            <CancelIcon color="primary" />
                                        </IconButton>
                                        <Grid container spacing={4}>
                                            <Grid xs={12} item>
                                                <CustomInput
                                                    placeholder="Name"
                                                    {...formik.getFieldProps(`ingredients[${index}].name`)}
                                                    error={Boolean(
                                                        formik.touched.ingredients &&
                                                        formik.touched.ingredients[index] &&
                                                        formik.touched.ingredients[index].name &&
                                                        formik.errors.ingredients &&
                                                        formik.errors.ingredients[index] &&
                                                        formik.errors.ingredients[index].name)
                                                    }
                                                    helperText={
                                                        formik.touched.ingredients &&
                                                        formik.touched.ingredients[index] &&
                                                        formik.touched.ingredients[index].name &&
                                                        formik.errors.ingredients &&
                                                        formik.errors.ingredients[index] &&
                                                        formik.errors.ingredients[index].name
                                                    }
                                                />
                                            </Grid>


                                            <Grid xs={6} item>
                                                <CustomInput
                                                    type="number"
                                                    placeholder="Quantity"
                                                    {...formik.getFieldProps(`ingredients[${index}].quantity`)}
                                                    error={Boolean(
                                                        formik.touched.ingredients &&
                                                        formik.touched.ingredients[index] &&
                                                        formik.touched.ingredients[index].quantity &&
                                                        formik.errors.ingredients &&
                                                        formik.errors.ingredients[index] &&
                                                        formik.errors.ingredients[index].quantity)
                                                    }
                                                    helperText={
                                                        formik.touched.ingredients &&
                                                        formik.touched.ingredients[index] &&
                                                        formik.touched.ingredients[index].quantity &&
                                                        formik.errors.ingredients &&
                                                        formik.errors.ingredients[index] &&
                                                        formik.errors.ingredients[index].quantity
                                                    }

                                                />
                                            </Grid>

                                            <Grid xs={6} item>
                                                <CustomSelect
                                                    select
                                                    placeholder="Unit"
                                                    {...formik.getFieldProps(`ingredients[${index}].unit`)}
                                                    error={Boolean(
                                                        formik.touched.ingredients &&
                                                        formik.touched.ingredients[index] &&
                                                        formik.touched.ingredients[index].unit &&
                                                        formik.errors.ingredients &&
                                                        formik.errors.ingredients[index] &&
                                                        formik.errors.ingredients[index].unit)
                                                    }
                                                    helperText={
                                                        formik.touched.ingredients &&
                                                        formik.touched.ingredients[index] &&
                                                        formik.touched.ingredients[index].unit &&
                                                        formik.errors.ingredients &&
                                                        formik.errors.ingredients[index] &&
                                                        formik.errors.ingredients[index].unit
                                                    }
                                                >
                                                    {inputOption.units.options.map((value) => {
                                                        return <MenuItem key={value} value={value.toLocaleLowerCase()}>{value}</MenuItem>
                                                    })}
                                                </CustomSelect>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))
                            )}
                            <ButtonBase
                                className={styles.addIngredientButton}
                                onClick={() => {
                                    arrayHelpers.push({
                                        name: "",
                                        quantity: "",
                                        unit: ""
                                    });
                                    setTimeout(() => {
                                        scrollItem.current.scrollIntoView();
                                    }, 500)
                                }
                                }
                            >Add ingredient</ButtonBase>
                        </>
                    )}
                />
            </div>
            <div ref={scrollItem} />
            <ButtonBase
                disabled={Boolean(formik.errors.ingredients)}
                className={styles.nextButton}
                onClick={() => setActiveIndex(2)}
            >{"Next >"}</ButtonBase>
        </div>
    )
}

export default Ingredients;
