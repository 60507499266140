import React, { useEffect, useState, useCallback } from "react";
import Layout from "./../../layout";

import styles from "./Welcome.module.scss";
import strokeGood from "../../assets/icons/strokeGood.svg";
import logo from "../../assets/stovelogo.png";
import slide1 from "../../assets/slide1.png";
import slide2 from "../../assets/slide2.png";
import slide3 from "../../assets/slide3.png";
import slide4 from "../../assets/slide4.png";
import { useHistory } from "react-router";
import { shallowEqual, useSelector } from "react-redux";

const data = [
   { img: slide1, title: "Welcome to Stove", sub: "Say hello to a happier, healthier you!" },
   {
      img: slide2,
      title: "Speak to a Registered Dietitian",
      sub: "Our dietitians are here to provide you with personalised nutrition support. Get actionable content and support from a regulated professional in a safe space.",
   },
   {
      img: slide3,
      title: "Cook tasty, easy to prepare recipes",
      sub: "Use our AI to generate recipes or meals plans using any product for any occasion. We also have 100's of recipes, created by chefs.",
   },
   {
      img: slide4,
      title: "Track your food and symptoms seamlessly",
      sub: "Log what you’ve eaten and how you’re feeling with ease. Between sessions your dietitian can review and track how you’re progressing towards your goals.",
   },
];

const Onboarding = () => {
   const [stage, setStage] = useState(0);
   const history = useHistory();
   const userState = useSelector((state) => state.user, shallowEqual);

   const moveTo = (path) => history.push(path);

   return (
      <Layout header={false} spacer={false} footer={false}>
         <div className={styles.page}>
               <div className={styles.header} style={{ backgroundColor: "white" }}>
                  {/* <div className={styles.space}></div> */}
                  <div className={styles.back}>
                     <div className={styles.logoCon}>
                        <img src={logo} alt='cancel' className={styles.img} />
                     </div>

                     <div className={styles.skip} onClick={() => moveTo("/login")}>
                        Skip
                     </div>
                  </div>
                  {/* <div className={styles.down}></div> */}
               </div>
            <div className={styles.main}>
               <div className={styles.imgBox}>
                  <img src={data[stage].img} alt='slide' className={styles.img} />
                  <div className={styles.content}>
                     <div className={styles.text}> {data[stage].title}</div>
                     <div className={styles.subText}>{data[stage].sub}</div>
                  </div>
               </div>
            </div>
            <div className={styles.dotContainer}>
               <div className={styles.dots}>
                  {data.map((value, i) => {
                     return <div className={stage === i ? `${styles.active}` : `${styles.inActive}`} key={i}></div>;
                  })}
               </div>
            </div>
            <div className={styles.bottomButton}>
               {stage > 0 && (
                  <button className={styles.prev} onClick={() => setStage((prev) => prev - 1)}>
                     {" "}
                     <img style={{ width: "20px", height: "15px" }} src={strokeGood} alt='prev' />{" "}
                  </button>
               )}
               {stage === 0 && (
                  <button className={styles.nextCenter} onClick={() => setStage((prev) => prev + 1)}>
                     {" "}
                     Next
                  </button>
               )}
               {stage > 0 && stage < data.length - 1 && (
                  <button className={styles.next} onClick={() => setStage((prev) => prev + 1)}>
                     Next{" "}
                  </button>
               )}
               {stage === data.length - 1 && (
                  <button className={styles.next} style={{ width: "152px" }} onClick={() => moveTo("/login")}>
                     Get Started{" "}
                  </button>
               )}
            </div>
         </div>
      </Layout>
   );
};

export default Onboarding;
