import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from "./CustomProgressBar.module.scss"

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 29,
      borderRadius: 5,
      maxWidth: 100,
      width: '100%',
    },
    colorPrimary: {
    background: 'repeating-linear-gradient(-45deg, #00866E, #00866E 0.8%, white 0.5%, white 4%)'
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#00866E',
    },
  }))(LinearProgress);


const CustomProgessBar = ({percentage, isSet = true}) => {
  return (
    <div className={styles.customProgessBar}>
        <BorderLinearProgress variant="determinate" value={percentage} className={styles.bar}/>
        <Typography variant="caption" component="div" color="textSecondary" className={`${styles.label} ${percentage < 30 ? styles.labelLow : styles.labelHigh}`}>{`${Math.round(
          percentage,
        )}`} {isSet ? '%' : 'g'}</Typography>
    </div>
  )
}

export default CustomProgessBar