import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getAllChats, getAllProfiles } from "../services/firebase";



export const getProfiles = createAsyncThunk('Chat/getAllProfiles', async (uid) => {
    const response = await getAllProfiles(uid);
    return response;
})

export const getAllPuplicChat = createAsyncThunk('Chat/getAllChats', async () => {
    const response = await getAllChats();
    return response;
})





const initialState = {
    profilesIsLoading: true,
    profilesIsError: false,
    profiles: [],
    puplicConversationIsLoading: true,
    puplicConversationIsError: false,
    puplicConversations: [],
    activeConversation: null
};

export const Chat = createSlice({
    name: 'Chat',
    initialState,
    reducers: {
        setActiveConversation: (state, action) => {
            state.activeConversation = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfiles.pending, (state) => {
                state.profilesIsLoading = true;
                state.profilesIsError = false;
            })
            .addCase(getProfiles.fulfilled, (state, action) => {
                state.profilesIsLoading = false;
                state.profilesIsError = false;
                state.profiles = action.payload;
            })
            .addCase(getProfiles.rejected, (state) => {
                state.profilesIsLoading = false;
                state.profilesIsError = true;
                state.profiles = [];
            })
        builder
            .addCase(getAllPuplicChat.pending, (state) => {
                state.puplicConversationIsLoading = true;
                state.puplicConversationIsError = false;
            })
            .addCase(getAllPuplicChat.fulfilled, (state, action) => {
                state.puplicConversationIsLoading = false;
                state.puplicConversationIsError = false;
                state.puplicConversations = action.payload;
            })
            .addCase(getAllPuplicChat.rejected, (state) => {
                state.puplicConversationIsLoading = false;
                state.puplicConversationIsError = true;
                state.puplicConversations = [];
            })
    }
});

export const { setActiveConversation } = Chat.actions;
export default Chat.reducer;