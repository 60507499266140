import React from "react";
import { useHistory } from "react-router-dom";
import ButtonBase from '@material-ui/core/ButtonBase';


import emptyImg from "./../../../../assets/empty.png";

import styles from "./NoItem.module.scss";

const NoItem = (props) => {
    const { setDisplay } = props;
    const history = useHistory();

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <img alt="Not found" src={emptyImg} className={styles.uploadImage} />
                <div className={styles.congratulations}>Your cart is empty</div>
                <div className={styles.congratulationsLightText}>Fill your cart by adding products from the groceries section. Special discounts are waiting for you!</div>
            </div>

            <ButtonBase
                className={styles.confirmButton}
                onClick={() => history.push("/shop")}
            >Go to Groceries</ButtonBase>
        </div>
    )
}

export default NoItem;