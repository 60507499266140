import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { ArticleWidget } from "../../components/articleWidget/articleWidget";

import styles from "./Article.module.scss";

import IconButton from "@material-ui/core/IconButton";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import Divider from "@material-ui/core/Divider";
import ButtonBase from "@material-ui/core/ButtonBase";
import Avatar from '@material-ui/core/Avatar';

import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import { stateToHTML } from 'draft-js-export-html'

import { getArticle } from "../../state";
import AnimateElement from "../../components/AnimateElement";

const Article = () => {
  const { articleId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const articleState = useSelector((state) => state.articles, shallowEqual);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [contentState, setContentState] = useState("");
  const { article, articles } = articleState;
  const moveTo = (path) => history.push(path);

  useEffect(() => {
    dispatch(getArticle(articleId));
  }, [articleId, dispatch]);

  useEffect(() => {
   //  if (article) {
   //    const contentState = convertFromRaw(JSON.parse(article.content));
   //    setEditorState(EditorState.createWithContent(contentState));
   //  }

    if (article?.content) {
      const isDraftjsContent = article?.content.includes("blocks");
      const textContent = isDraftjsContent ?  stateToHTML(convertFromRaw(JSON.parse(article?.content))) :  JSON.parse(article?.content)
      setContentState(textContent)
   }
  }, [article]);

  return (
     <div className={styles.page}>
        <div className={styles.header}>
           <IconButton size='small' onClick={() => history.goBack()}>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                 <path d='M4.00065 12.3379L20 12.3379' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                 <path
                    d='M11.3691 19.6759L3.99988 12.3379L11.3691 5'
                    stroke='#130F26'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                 />
              </svg>
           </IconButton>

           <IconButton size='small'>
              <ShareOutlinedIcon />
           </IconButton>
        </div>

        {articleState.articleIsLoading === true && articleState.articleIsError === false && (
           <div className='px-3'>
              <Spinner variant='rect' height={64} />
           </div>
        )}

        {articleState.articleIsLoading === false && articleState.articleIsError === true && <MessageBox text='Failed to load Article' />}

        {articleState.articleIsLoading === false && articleState.articleIsError === false && (
           <AnimateElement duration={"0.5"}>
              <div className={styles.content}>
                 {
                    <>
                       <div className={styles.dateReadTime}>
                          <span>
                             {article.category}・{Number(article.readTime) < 1 ? "less than a minute" : article.readTime + " mins"}
                          </span>
                          <span></span>
                       </div>
                       <Divider />
                       <div className={styles.authorInfo}>
                       <AnimateElement duration={"0.5"}>
                          <div className={styles.authorImgCtn}>
                            <Avatar src={article.authorImage} alt='Author' className={styles.img} />
                         </div>
                          </AnimateElement>
                          <div className={styles.authorNameSpecialization}>
                             <span className={styles.authorName}>{article.author}</span>
                             <span className={styles.specialization}>{article.speciality}</span>
                          </div>
                       </div>

                       <div className={styles.articleTitle}>
                          <p>{article.title}</p>
                       </div>

                       <div className={styles.articleCoverImage} style={{ backgroundImage: `url(${article.coverImage})` }}></div>

                       <div className={styles.articleContent}>
                          {/* <Editor
                      editorState={editorState}
                      readOnly={true}
                      toolbarHidden
                    /> */}
                          <ReactQuill value={contentState} readOnly={true} modules={{toolbar: false}}  className={styles.editorContainer}/>
                       </div>
                    </>
                 }
              </div>
           </AnimateElement>
        )}

        {
           <>
              <div className={styles.similarArticles}>
                 <h3 className={styles.headerText}>Similar Articles</h3>
                 <ButtonBase className={styles.seeAllButton} onClick={() => moveTo("/suggested-articles")}>
                    <span className={styles.seeAllText}>See all</span>
                    <svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                       <path d='M1 6.5L4 4L1 1.5' stroke='#00866E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                 </ButtonBase>
              </div>
              <div className={styles.relatedArticlesCtn}>
                 <ArticleWidget articles={articles} limit={3} />
              </div>
           </>
        }
     </div>
  );
};

export default Article;
