import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from "./Spinner.module.scss";


const Spinner = (props) => {
    const { variant, width, height } = props;
    return <Skeleton className={styles.background} variant={variant || "rect"} width={width || "100%"} height={height || 120} />;
}

export default Spinner;