import React from "react";
import cx from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Avatar from "@material-ui/core/Avatar";
import backIcon from "./../../../../assets/icons/back-light.png";

import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";

import infoSquareIcon from "./../../../../assets/icons/info-square-light.png";
import editIcon from "./../../../../assets/icons/edit.png";

import inputOption, { MEAL_PREP } from "../../../../constant";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { useHistory } from "react-router-dom";

import styles from "./Indroduction.module.scss";
const Indroduction = (props) => {
   const { setActiveIndex, formik } = props;
   const history = useHistory();

   return (
      <div className={styles.page}>
         <div className={styles.header}>
            <IconButton size='small' onClick={() => setActiveIndex(2)}>
               <Avatar alt='back' sizes='small' variant='square' src={backIcon} className={styles.icon} />
            </IconButton>
            <div className={styles.stepContainer}>
               <span className={styles.stepIndicator} />
               <span className={styles.stepIndicator} />
               <span className={styles.stepIndicatorActive} />
               <span className={styles.stepIndicator} />
            </div>
            <IconButton size='small'>
               <Avatar alt='share' sizes='small' variant='square' src={infoSquareIcon} className={styles.icon} />
            </IconButton>
         </div>
         <div className={styles.headerBottom} />
         <div className={styles.content}>
            <div className={cx(styles.headerText, "mb-2")}>03. Additional</div>
            {/* <div className={cx(styles.lightText, "mb-2")}>Enter the most important elements which readers will see first.</div> */}
            <div className='mb-3'>
               {formik.values.thumbnail ? (
                  <div className={styles.thumbnail} style={{ backgroundImage: `url(${formik.values.thumbnail})` }}>
                     <label>
                        <PhotoCameraOutlinedIcon color='primary' className={styles.pointer} />
                        <input
                           hidden
                           type='file'
                           accept='image/*'
                           onChange={(e) => {
                              const file = e.target.files[0];
                              if (file && file.type.match("image.*")) {
                                 let reader = new FileReader();
                                 reader.onload = function (event) {
                                    formik.setFieldValue("thumbnail", event.target.result);
                                 };
                                 reader.readAsDataURL(e.target.files[0]);
                              }
                           }}
                        />
                     </label>
                  </div>
               ) : (
                  <label className={cx(styles.thumbnail, styles.pointer)}>
                     <PhotoCameraOutlinedIcon color='primary' />
                     <input
                        hidden
                        type='file'
                        accept='image/*'
                        onChange={(e) => {
                           const file = e.target.files[0];
                           if (file && file.type.match("image.*")) {
                              let reader = new FileReader();
                              reader.onload = function (event) {
                                 formik.setFieldValue("thumbnail", event.target.result);
                              };
                              reader.readAsDataURL(e.target.files[0]);
                           }
                        }}
                     />
                  </label>
               )}
            </div>
            <div className='mb-4 d-flex justify-content-between align-items-center'>
               <Avatar className={styles.editIcon} src={editIcon} />
               <span className={styles.aspectRatioText}>{"Select ratio for thumb preview >"}</span>
            </div>
            <Grid container spacing={4}>
               <Grid xs={12} item>
                  <CustomInput
                     placeholder='Title'
                     {...formik.getFieldProps("title")}
                     error={Boolean(formik.touched.title && formik.errors.title)}
                     helperText={formik.touched.title && formik.errors.title}
                  />
               </Grid>
               <Grid xs={6} item>
                  <CustomSelect
                     select
                     placeholder='Difficulty'
                     {...formik.getFieldProps("difficulty")}
                     error={Boolean(formik.touched.difficulty && formik.errors.difficulty)}
                     helperText={formik.touched.difficulty && formik.errors.difficulty}
                  >
                     {inputOption.difficulty.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={6} item>
                  <CustomSelect
                     select
                     placeholder='Ready in'
                     {...formik.getFieldProps("readyIn")}
                     error={Boolean(formik.touched.readyIn && formik.errors.readyIn)}
                     helperText={formik.touched.readyIn && formik.errors.readyIn}
                  >
                     {inputOption.readyIn.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={6} item>
                  <CustomSelect
                     select
                     placeholder='Cost'
                     {...formik.getFieldProps("cost")}
                     error={Boolean(formik.touched.cost && formik.errors.cost)}
                     helperText={formik.touched.cost && formik.errors.cost}
                  >
                     {inputOption.cost.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={6} item>
                  <CustomSelect
                     select
                     placeholder='Servings'
                     {...formik.getFieldProps("servings")}
                     error={Boolean(formik.touched.servings && formik.errors.servings)}
                     helperText={formik.touched.servings && formik.errors.servings}
                  >
                     {inputOption.servings.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={12} item>
                  <CustomSelect
                     select
                     placeholder='Type'
                     {...formik.getFieldProps("type")}
                     error={Boolean(formik.touched.type && formik.errors.type)}
                     helperText={formik.touched.type && formik.errors.type}
                  >
                     {inputOption.type.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={12} item>
                  <CustomSelect
                     select
                     placeholder='Meal'
                     multiple={true}
                     value={formik.values.meal}
                     onChange={(e) => formik.setFieldValue("meal", e.target.value)}
                     error={Boolean(formik.touched.meal && formik.errors.meal)}
                     helperText={formik.touched.meal && formik.errors.meal}
                  >
                     {inputOption.meal.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={12} item>
                  <CustomSelect
                     select
                     placeholder='Diet'
                     {...formik.getFieldProps("dietary")}
                     error={Boolean(formik.touched.dietary && formik.errors.dietary)}
                     helperText={formik.touched.dietary && formik.errors.dietary}
                  >
                     {inputOption.dietary.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={12} item>
                  <CustomSelect
                     select
                     placeholder='Made in'
                     {...formik.getFieldProps("cuisine")}
                     error={Boolean(formik.touched.cuisine && formik.errors.cuisine)}
                     helperText={formik.touched.cuisine && formik.errors.cuisine}
                  >
                     {inputOption.cuisine.options.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>
               <Grid xs={12} item>
                  <CustomSelect
                     select
                     placeholder='Select a cooking method'
                     {...formik.getFieldProps("cookingMethod")}
                     error={Boolean(formik.touched.cookingMethod && formik.errors.cookingMethod)}
                     helperText={formik.touched.cookingMethod && formik.errors.cookingMethod}
                  >
                     {MEAL_PREP.map((value) => {
                        return (
                           <MenuItem key={value} value={value.toLocaleLowerCase()}>
                              {value}
                           </MenuItem>
                        );
                     })}
                  </CustomSelect>
               </Grid>

               <Grid xs={12} item>
                  <CustomInput
                     type='number'
                     placeholder='Calories (Kcal)'
                     {...formik.getFieldProps("energy-kcal")}
                     error={Boolean(formik.touched["energy-kcal"] && formik.errors["energy-kcal"])}
                     helperText={formik.touched["energy-kcal"] && formik.errors["energy-kcal"]}
                  />
               </Grid>

               <Grid xs={12} item>
                  <CustomInput
                     type='number'
                     placeholder='Carbs (g)'
                     {...formik.getFieldProps("carbohydrates")}
                     error={Boolean(formik.touched.carbohydrates && formik.errors.carbohydrates)}
                     helperText={formik.touched.carbohydrates && formik.errors.carbohydrates}
                  />
               </Grid>
               <Grid xs={12} item>
                  <CustomInput
                     type='number'
                     placeholder='Protein (g)'
                     {...formik.getFieldProps("proteins")}
                     error={Boolean(formik.touched.proteins && formik.errors.proteins)}
                     helperText={formik.touched.proteins && formik.errors.proteins}
                  />
               </Grid>

               <Grid xs={12} item>
                  <CustomInput
                     type='number'
                     placeholder='Fat (g)'
                     {...formik.getFieldProps("fat")}
                     error={Boolean(formik.touched.fat && formik.errors.fat)}
                     helperText={formik.touched.fat && formik.errors.fat}
                  />
               </Grid>
            </Grid>
         </div>
         <ButtonBase
            //  disabled={Boolean(
            //     // formik.errors.thumbnail ||
            //     formik.errors.title ||
            //        formik.errors.difficulty ||
            //        formik.errors.readyIn ||
            //        formik.errors.cost ||
            //        formik.errors.servings ||
            //        formik.errors.type ||
            //        formik.errors.meal ||
            //        formik.errors.cookingMethod
            //     // formik.errors.dietary ||
            //     // formik.errors.cuisine
            //  )}
            className={styles.nextButton}
            onClick={() => setActiveIndex(4)}
         >
            {"Next >"}
         </ButtonBase>
      </div>
   );
};

export default Indroduction;
