import React, { useEffect } from "react";
import styles from "./TaskModal.module.scss";
import modalHandle from "../../../../assets/modal-handle.png";
import progress from "../../../../assets/icons/progress.svg";
import not from "../../../../assets/icons/not.svg";
import completed from "../../../../assets/icons/complete.svg";
import {
  updateUserTask,
} from "../../../../services/firebase/index";


export const TaskModal = ({ open, onClose, selectedTask }) => {
  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  const select = (id, status, frequency) => {
    onClose(id, status, frequency);
  };

  const addData = async (status) => {
    try {
      const payload = {
        ...selectedTask,
        status: status,
      };
      const res = await updateUserTask(payload);

      select(selectedTask.taskId, status, selectedTask.frequency);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        className={`${styles.modal} ${open ? styles.open : ""}`}
        onClick={onClose}
      >
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <img src={modalHandle} alt="" />
            <p className={styles.text}>Status</p>
          </div>

          <div className={styles.icns}>
            <div className={styles.bt} onClick={() => addData("completed")}>
              <div className={styles.icn}>
                <img src={completed} alt="icn" />
              </div>
              <div className={styles.name}>Completed</div>
            </div>

            <div className={styles.bt} onClick={() => addData("in progress")}>
              <div className={styles.icn}>
                <img src={progress} alt="icn" />
              </div>
              <div className={styles.name}>In Progress</div>
            </div>

            <div className={styles.bt} onClick={() => addData("not started")}>
              <div className={styles.icn}>
                <img src={not} alt="icn" />
              </div>
              <div className={styles.name}>Not Started</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
