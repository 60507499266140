import React, { useState, useEffect} from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import styles from './Slider.module.scss';

const PrettoSlider = withStyles({
    root: {
      color: '#00866E',
      height: 24,
      padding: 0,

      '&$disabled': {
        color: '#130F26',
      },
    },
    thumb: {
        width: 24,
        height: 24,
        marginTop: -4,
        marginLeft: -12,
        borderRadius: 23,
        background: '#FFFFFF',
        boxshadow: '0px 0px 8px rgb(0 0 0 / 20%)',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
      '&$disabled': {
        width: 24,
        height: 24,
        marginTop: -4,
        marginLeft: -12,
      },


    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 20px)',
    },
    track: {
      height: 16,
      borderRadius: 100,
      // background: '#00866E',
    },
    rail: {
    height: 16,
    borderRadius: 100,
    background: 'rgba(19, 15, 38, 0.1)'
    },
    disabled: {

    }
  })(Slider);

const CustomSlider = ({defaultValue, min, max, orientation, unit, disabled, onChange, name, value}) => {

  return (
    <div className={orientation === 'vertical' ? `${styles.showValueVertical}` : orientation === 'horizontal' ? `${styles.showValueHorizontal}` : null}>
        <span className={styles.valueText} >{value}{unit}</span>
        <PrettoSlider  aria-label="pretto slider" value={value} min={min} max={max} onChange={onChange} disabled={disabled} name={name}/>
    </div>
  )
}

export default CustomSlider