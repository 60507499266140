import React, { useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CUISINE_COUNTRIES } from "../../../constant";
import styles from "./FoodPreferenceIntakeForm.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MessageBox from "../../../components/MessageBox/MessageBox";
import Divider from "@material-ui/core/Divider";
import { getAppUserQuery } from "../../../services/queries";

const FoodPreferenceIntakeForm = () => {
   const history = useHistory();
   const { userId, healthProfileDetails } = useParams();
   const { data: patient, refetch, isLoading: userDataLoading } = getAppUserQuery(userId);

   const Header = (healthProfileDetails === "foodPreference" && "Food Preferences") || (healthProfileDetails === "intakeForm" && "Intake Form");

   const HOUSEHOLD_SIZE = "Household size";
   const WEEKLY_FOOD_BUDGET = "Weekly Food Budget";
   const COOKING_TIME = "Cooking time";
   const HEALTH_CONDITIONS = "Health Conditions";
   const ALLERGIES = "Allergies";
   const DISLIKES = "Dislikes";
   const FOOD_PRACTICE = "Food practice";
   const FAVORITE_CUISINES = "Favorite Cuisines";

   // intake form variables
   const CONDITIONS = "Suggested Conditions"
   const GENDER = "Gender";
   const AGE = "Age";
   const MEDICAL_HISTORY = "Medical History";
   const MEDICATIONS = "Medication & Supplements";
   const HEIGHT_WEIGHT = "Height and Weight";
   const SLEEP = "Sleep";
   const SMOKE = "Smoke";
   const ALCOHOL = "Alcohol";
   const PREVIOUS_DIET = "Previous Diet";
   const HEALTH_GOALS = "Health Goals";
   const ALLERGIES_INTAKE = "Allergies";
   const DISLIKED_FOODS = "Foods To Avoid";
   const ACTIVITY = "Physical activities";
   const TYPICAL_DIET = "Typical diet"

   const FOOD_PREFERENCES = useMemo(() => {
      const foodPreferences = patient?.foodPreference || [];
      const foodPreferencesData = [
         {
            title: HOUSEHOLD_SIZE,
            data: [{ Adults: foodPreferences.adults }, { Children: foodPreferences.children }, { Toddlers: foodPreferences.toddlers }],
         },
         { title: WEEKLY_FOOD_BUDGET, data: [foodPreferences.foodBudget] },
         { title: COOKING_TIME, data: [foodPreferences.cookingTime] },
         { title: HEALTH_CONDITIONS, data: foodPreferences.healthCondition },
         { title: ALLERGIES, data: foodPreferences.allergies },
         { title: DISLIKES, data: foodPreferences.dislikes },
         { title: FOOD_PRACTICE, data: [foodPreferences.foodPractice] },
         { title: FAVORITE_CUISINES, data: foodPreferences.cuisine },
      ];
      return foodPreferencesData;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [patient?.foodPreference]);

   const INTAKE_FORM = useMemo(() => {
      const intakeForm = patient?.quizData || [];
      const conditions = intakeForm.conditions ? intakeForm.conditions.filter(condition => condition.checked).map(condition => condition.label) : []

      const intakeFormData = [
         { title: CONDITIONS, data: [...conditions]},
         { title: GENDER, data: [intakeForm.gender] },
         { title: AGE, data: [intakeForm.age] },
         { title: MEDICAL_HISTORY, data: intakeForm.medical_history },
         { title: MEDICATIONS, data: [...(intakeForm.medications || ""), ...(intakeForm.supplements || "")] },
         { title: HEIGHT_WEIGHT, data: [intakeForm.height, intakeForm.weight] },
         { title: SLEEP, data: [intakeForm.sleep] },
         { title: SMOKE, data: [intakeForm.smoke] },
         { title: ALCOHOL, data: [intakeForm.alcohol] },
         { title: ALLERGIES_INTAKE, data: intakeForm.allergies },
         { title: PREVIOUS_DIET, data: intakeForm.previous_diet },
         { title: DISLIKED_FOODS, data: intakeForm.disliked_foods },
         { title: HEALTH_GOALS, data: intakeForm.health_goals },
         { title: ACTIVITY, data: [intakeForm?.activity] },
         { title: TYPICAL_DIET, data: [intakeForm?.typicalDiet] }
      ];
      return intakeFormData;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [patient?.quizData]);

   const convertFirstLettertoUpperCase = (text) => {
      if(text && text.length > 0){
         const formattedText = text.charAt(0).toUpperCase() + text.slice(1);
         return formattedText;
      } else return "N/A"  
   };

   const setCuisineData = (text, cuisine_country) => {
      const country = text.toUpperCase();
      const cuisineData = cuisine_country.map((item) => {
         if (country === item.value) {
            return (
               <div className={styles.cuisineContainer} key={item.value}>
                  <img src={item.flag} alt={item.title} />
                  <p className={styles.p}>{item.value}</p>
               </div>
            );
         }
      });
      return cuisineData;
   };

   const setHouseHoldData = (data) => {
      const result = Object.keys(data).map((key, index) => {
         return `${key}: ${data[key]}`;
      });
      return result;
   };

   const formatData = (preference, data) => {
      let mutatedData;
      switch (preference) {
         case HEALTH_CONDITIONS:
         case ALLERGIES:
         case DISLIKES:
         case FOOD_PRACTICE:
         case PREVIOUS_DIET:
         case HEALTH_GOALS:
         case ALLERGIES_INTAKE:
         case MEDICATIONS:
         case MEDICAL_HISTORY:
         case GENDER:
         case DISLIKED_FOODS:
         case ACTIVITY:
         case TYPICAL_DIET:           
            mutatedData = convertFirstLettertoUpperCase(data);
            break;
         case WEEKLY_FOOD_BUDGET:
         case COOKING_TIME:
         case AGE:
         case SLEEP:
         case SMOKE:
         case ALCOHOL:
         case HEIGHT_WEIGHT:
         case CONDITIONS:
            mutatedData = data;
            break;
         case HOUSEHOLD_SIZE:
            mutatedData = setHouseHoldData(data);
            break;
         case FAVORITE_CUISINES:
            mutatedData = setCuisineData(data, CUISINE_COUNTRIES);
            break;
         default:
            mutatedData = "N/A";
      }
      return mutatedData;
   };

   const DATASET =
      healthProfileDetails === "foodPreference" && patient?.foodPreference !== undefined
         ? FOOD_PREFERENCES
         : healthProfileDetails === "intakeForm" && patient?.quizData !== undefined
         ? INTAKE_FORM
         : [];

   const goBack = () => {
      history.goBack();
   };

   return (
      <div className={styles.page}>
         <div className={styles.header}>
            <IconButton className={styles.back} size='small' onClick={goBack}>
               <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4.00065 12.3379L20 12.3379' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                  <path
                     d='M11.3693 19.6759L4.00001 12.338L11.3693 5.00003'
                     stroke='#130F26'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </IconButton>
            <Avatar className={styles.avater} src={patient && patient.photoURL} />
            <div>
               <div className={styles.headerTitle}>{Header}</div>
               <div className={styles.headerSubText}>{patient && patient.displayName}</div>
            </div>
         </div>
         {userDataLoading && (
            <div className='px-4 py-4 text-center'>
               <CircularProgress size={15} disableShrink />
            </div>
         )}
         {!userDataLoading && DATASET.length === 0 && <MessageBox text='No Data added yet' />}

         {userDataLoading === false && (
            <div className={styles.content}>
               {DATASET.map((item, index) => {
                  return (
                     <>
                        <div className={styles.card} key={item.title + index.toString()}>
                           <div className={styles.cardHeader}>
                              <p className={styles.cardNo}>
                                 {index + 1} / {DATASET.length}
                              </p>
                              <span className={styles.cardText}>{item.title}</span>
                           </div>
                           <div className={styles.cardBody}>
                              {item?.data?.length > 0 ? (
                                 item.data.map((data, index) => {
                                    return (
                                       <div className={styles.cardTags} key={index.toString()}>
                                          <span className={styles.cardTagText}>{formatData(item.title, data)}</span>
                                       </div>
                                    );
                                 })
                              ) : (
                                 <div className={styles.cardTags}>
                                    <span className={styles.cardTagText}>N/A</span>
                                 </div>
                              )}
                           </div>
                           <Divider className={styles.divider} light />
                        </div>
                     </>
                  );
               })}
            </div>
         )}
      </div>
   );
};

export default FoodPreferenceIntakeForm;
