import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./GetStartedArticle.module.scss";



const GetStartedArticle = () => {
    const history = useHistory();
    const moveTo = (path) => history.push(path);
   return (
      <div className={styles.page}>
         <div className={styles.header}>
            <div className={styles.authorImgCtn}>
               <img src='https://ik.imagekit.io/stovehealth/authorImages/image_G-Q2oDkLP' alt='author' className={styles.img} />
            </div>
            <div className={styles.authorNameSpecialization}>
               <span className={styles.authorName}>Stove</span>
               <span className={styles.specialization}>Stove app</span>
            </div>
         </div>

         <h1 className={styles.title}>Making the most from Stove: a quick run-through</h1>
         <div className={styles.coverImage} style={{ 
            backgroundImage: `url(https://ik.imagekit.io/stovehealth/coverImages/image_zG_5IBMOo)` }}></div>
         <div className={styles.content}>
            <div className={styles.paragraph}>
               Hello! If you're reading this, you've probably just downloaded the Stove app. Whether you're here to better manage a diet-related
               condition or just level up your dietary game, we've got you covered. In this article, we'll give you a birds-eye view of what Stove is
               all about:
            </div>
            <div className={styles.subTitle}>AI Recipe Generator</div>
            <div className={styles.paragraph}>
               We all have those moments when the fridge seems to be a mishmash of random ingredients, and you're out of ideas. Enter the AI Recipe
               Generator! Whether you're in the mood for Italian, want to stick to a tight budget or need that extra protein punch - toggle your
               preferences and let the magic happen. And guess what? Those generated recipes can be transformed into step-by-step guides. Make it your
               daily kitchen companion.
            </div>
            <div className={styles.subTitle}>The ultimate tracking tool</div>
            <div className={styles.paragraph}>
               Ever wondered why you feel sluggish after lunch? Or maybe you've noticed that particular food leads to bloating? With Stove's tracking
               feature, you can keep an eye on symptoms, food consumption, and even your hydration levels. The best part? No need for manual data
               entry for every single food item; our system calculates all the nutritional values for you
            </div>
            <div className={styles.subTitle}>Dive into our recipe library</div>
            <div className={styles.paragraph}>
               Craving something different? Our vast recipe library is bursting with delicious, healthy options. You can create your own recipes and
               also easily add them to your tracker.
            </div>
            <div className={styles.subTitle}>Enhanced features for our paid Dietitian plans</div>
            <div className={styles.paragraph}>
               If you're one of our special users who've opted for the paid plan, you're in for a treat. The Dietitian tab is your gateway to bespoke
               tips, curated recipes, invaluable session notes, and so much more. Engage with your assigned community group, or simply book your next
               session with your dietitian.
            </div>
            <div className={styles.subTitle}>24/7 assistance with Personal Plus plan</div>
            <div className={styles.paragraph}>
               And for those who've gone the extra mile with our Personal Plus plan, know that you're never alone. Whether it's midmorning or midday,
               you can chat with your dietitian. Questions, doubts, or just need to chat about that chocolate craving? There you have it! An overview
               of what you can expect from the Stove app. Dive in, explore, and remember, managing your diet, understanding your body, and enjoying
               delicious food has never been easier. Welcome to Stove.{" "}
               <span role='img' aria-label='emoji'>
                  🥗📲
               </span>
            </div>
         </div>
         <div className={styles.button}
            onClick={() => {
                moveTo('/')
            }}
         >
           <span className={styles.btnTxt}>Mark as Read</span>
         </div>
      </div>
   );
};

export default GetStartedArticle;
