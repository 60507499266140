import React, { useState, useEffect } from "react";
import styles from "./Modal.module.scss";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import { DateTimePicker } from "../../../components/DateTimePicker/DateTimePicker";
import CustomTextArea from "../../../components/CustomTextArea/CustomTextArea";
import { Chip, CircularProgress, TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ButtonBase from "@material-ui/core/ButtonBase";
import Div100vh from "react-div-100vh";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { toast, setUser } from "../../../state";
import { addUserEventsSymptoms, getUserData, updateUserEventSymptoms, deleteUserEvents } from "../../../services/firebase";
import { format } from "date-fns";
import dayjs from "dayjs";

const today = dayjs();

const isEventEmpty = (obj) => {
   return Object.keys(obj).length === 0;
};

export const Symptoms = ({ showSymptoms, onClose, currentEvent, selectedTime, selectedDate, setSelectedTime, setSelectedDate  }) => {
   const dispatch = useDispatch();
   const userState = useSelector((state) => state.user, shallowEqual);
   const calendarState = useSelector((state) => state.calendar, shallowEqual);
   const [userSymptoms, setUserSymptoms] = useState([
      "Abdominal pain",
      "Rash",
      "Nausea",
      "Vomiting",
      "Bowel open",
      "Flatulance",
      "Headache",
      "Swelling",
      "Itching",
      "Bloating",
   ]);
   const [add, setAdd] = useState(false);
   const [selected, setSelected] = useState([]);
   const [processing, setProcessing] = useState(false);
   const [message, setMessage] = useState("");
   const [addCondition, setAddCondition] = useState("");
   const user = userState.user;
   const selectedSymptomsEvent = calendarState.selectedEvent;

   const addSymptoms = () => {
      if (addCondition !== "") {
         setUserSymptoms([...userSymptoms, addCondition]);
         setSelected([...selected, addCondition]);
      }
      setAddCondition("");
      setAdd(false);
   };

   const handleMessageChange = (event) => {
      setMessage(event.target.value);
   };

   const handleChange = (e) => {
      setAddCondition(e.target.value);
   };

   const handleSubmit = async () => {
      const payload = {
         symptoms: selected,
         hour: selectedTime.format("H"),
         time: selectedTime.format("hh:mm a"),
         id: selectedDate.format("ddd D MMM"),
         description: message,
         date: format(selectedDate.$d, "yyyy-MM-dd"),
      };
      

      try {
         if (selected.length > 0) {
               setProcessing(true);
            if (!isEventEmpty(selectedSymptomsEvent) && showSymptoms) {
               await updateUserEventSymptoms(user.uid, payload, selectedSymptomsEvent.dateCreated);
               const userData = await getUserData(user.uid);
               userData && dispatch(setUser(userData));
               setProcessing(false);
               dispatch(toast({ message: "Changed successfully" }));
               setTimeout(() => onClose(), 500);
            } else {
               await addUserEventsSymptoms(user.uid, payload);
               const userData = await getUserData(user.uid);
               userData && dispatch(setUser(userData));
               setProcessing(false);
               setSelected([]);
               dispatch(toast({ message: "Symptoms added successfully" }));
               setTimeout(() => onClose(), 500);
            }
         } else if(selected.length === 0) {
            dispatch(toast({ message: "Add a symptom" }));
         }
      } catch (error) {
         setProcessing(false);
         dispatch(toast({ message: "an error occurred adding event" }));
      }
   };

   const handleDelete = async () => {
      setProcessing(true)
      try {
         await deleteUserEvents(user.uid, selectedSymptomsEvent.dateCreated);
         const userData = await getUserData(user.uid);
         userData && dispatch(setUser(userData));
         setProcessing(false);
         dispatch(toast({ message: "Symptoms deleted successfully" }));
         setTimeout(() => onClose(), 500);
      } catch (error) {
         setProcessing(false);
         dispatch(toast({ message: "An error occurred deleting event" }));
      }
   };

   useEffect(() => {
      if (!isEventEmpty(selectedSymptomsEvent) && showSymptoms) {
         setSelectedDate(dayjs(selectedSymptomsEvent.date, "YYYY-MM-DD"));
         setSelectedTime(dayjs(selectedSymptomsEvent.time, "hh:mm a"));
         setSelected(selectedSymptomsEvent.symptoms.symptoms);
         setMessage(selectedSymptomsEvent.symptoms.description);
      }
   }, [selectedSymptomsEvent, showSymptoms]);

   useEffect(() => {
      showSymptoms ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
   }, [showSymptoms])

   return (
      <div className={`${showSymptoms ? styles.addWS : styles.hide}`}>
         <div className={styles.sticky}>
            <div className={styles.header}>
               <div className={styles.headerText}>
                  <CloseOutlinedIcon
                     onClick={() => {
                        setAdd(false);
                        onClose();
                     }}
                  />
                  <p className={styles.text}>Add {currentEvent}</p>
               </div>
               {!isEventEmpty(selectedSymptomsEvent) && showSymptoms ? (
                  <DeleteIcon fontSize='small' onClick={handleDelete} disabled={processing} />
               ) : (
                  <MoreVertIcon fontSize='small' />
               )}
            </div>
         </div>
         <DateTimePicker selectedTime={selectedTime} setSelectedTime={setSelectedTime} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

         <div className={`${styles.modalContent} ${styles.disableScrollbars}`}>
            <div className={styles.symptomsCtn}>
               <div className={styles.allSymptoms}>
                  {userSymptoms.map((value) => {
                     return (
                        <Chip
                           clickable
                           key={value}
                           label={value}
                           className={selected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                           onClick={() =>
                              selected.includes(value.toLowerCase())
                                 ? setSelected(selected.filter((e) => e !== value.toLowerCase()))
                                 : setSelected((prev) => [...prev, value.toLowerCase()])
                           }
                        />
                     );
                  })}
                  <Chip clickable label='Add Others...' className={styles.addChip} onClick={() => setAdd(true)} />
               </div>

               {add && (
                  <div className={styles.addCondition}>
                     <TextField
                        id='standard-basic'
                        label='Add symptoms'
                        variant='standard'
                        value={addCondition}
                        onChange={handleChange}
                        style={{ width: "80%" }}
                     />
                     <AddCircleIcon fontSize='large' color='primary' onClick={addSymptoms} />
                  </div>
               )}

               <div className={styles.description}>
                  <p>Description</p>
                  <CustomTextArea
                     maxrows={8}
                     value={message}
                     onChange={handleMessageChange}
                     placeholder='You can add notes for a more detailed description of your weight.'
                  />
               </div>
            </div>

            <div className={styles.doneBtn}>
               <div className={styles.btnCtn}>
                  <button className={`${styles.btn}`} onClick={handleSubmit} >
                     {processing ? <CircularProgress style={{ color: "white", width: "20px", height: "20px" }} /> : "Add symptoms"}
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};
