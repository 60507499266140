import React, { useState, useCallback, useEffect } from "react";
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import styles from "./../Register.module.scss";


const validationPasswordSchema = Yup.object().shape({
    firstName: Yup.string().required("Name  is required"),
    lastName: Yup.string().required("Name  is required"),
    password: Yup.string().min(8, "Minimum password characters is 8").max(20, "Maximum password characters is 20").matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+=\-[\]{}|\\:;'",.<>/?]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number").required("Password is required")
});

const Finish = (props) => {
    const { email, setEmail, setRegisterStage, handleCreateUserWithEmailAndPassword } = props;
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!email || !sessionStorage.getItem("token")) {
            setRegisterStage("email");
        }
    }, [])




    const handleClickShowPassword = () => {
        setShowPassword(showPassword => !showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onChangeEmail = useCallback(() => {
        setEmail("");
        setRegisterStage("email");
        sessionStorage.clear();
    }, [])



    return (
        <Formik
            validationSchema={validationPasswordSchema}
            initialValues={{
                firstName: "",
                lastName: "",
                password: ""
            }}
            onSubmit={async (values, FormikBag) => {
                await handleCreateUserWithEmailAndPassword(email, values.password, values.firstName, values.lastName);
                FormikBag.setSubmitting(false);
            }}

        >
            {formik => (
                <div className={styles.emailPageContent}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        className={styles.textField}
                        value={email}
                        disabled
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        className={styles.textField}
                        placeholder="First name"
                        {...formik.getFieldProps('firstName')}
                        error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                        disabled={formik.isSubmitting}
                    />
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        className={styles.textField}
                        placeholder="Last name"
                        {...formik.getFieldProps('lastName')}
                        error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                        disabled={formik.isSubmitting}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        className={styles.textField}
                        inputProps={{ autoComplete: "new-password" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...formik.getFieldProps('password')}
                        error={Boolean(formik.touched.password && formik.errors.password)}
                        helperText={Boolean(formik.touched.password && formik.errors.password) ? formik.errors.password : "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"}
                        disabled={formik.isSubmitting}
                    />
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={formik.submitForm} disabled={formik.isSubmitting}>Register</Button>
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={onChangeEmail} disabled={formik.isSubmitting} >Restart</Button>
                </div>
            )}
        </Formik>
    )
}

export default Finish;

