import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import Avatar from "@material-ui/core/Avatar";
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getAppUserQuery } from "../../../../services/queries";
import styles from "./singleView.module.scss";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { DateTimePicker } from "../../../../components/DateTimePicker/DateTimePicker";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { Chip, CircularProgress, Grid, MenuItem } from "@material-ui/core";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import CustomTextArea from "../../../../components/CustomTextArea/CustomTextArea";
import { createUserTask, getSingleTask } from "../../../../services/firebase";

const SingleTask = () => {
  const history = useHistory();
  const { patientId, taskId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const {
    data: patient,
    refetch,
    isLoading: userDataLoading,
  } = getAppUserQuery(patientId);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getData();
  }, [patient]);

  const getData = async () => {
    try {
      setLoading(true);

      const data = await getSingleTask(taskId);
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={styles.page}>
      <div className={styles.top}>
        <CloseOutlinedIcon
          onClick={() => {
            goBack();
          }}
        />
        <div className={styles.head}>Task Details</div>
        <div></div>
      </div>

      <div>
        <table>
          <tr>
            <th>Status :</th>
            <td>{data?.status}</td>
          </tr>
          <tr>
            <th>Frequency :</th>
            <td>{data?.frequency}</td>
          </tr>
          <tr>
            <th>Description :</th>
            <td>{data?.description}</td>
          </tr>
          <tr>
            <th>Note :</th>
            <td>{data?.subDescription}</td>
          </tr>
          <tr>
            <th>expiry Date :</th>
            <td>{data?.date ? data?.date : "Not specified"}</td>
          </tr>
          <tr>
            <th>expiry Time :</th>
            <td>{data?.time ? data?.time : "Not specified"}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default SingleTask;
