import React, { useEffect } from "react";
import propTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import MessageBox from "../../../../../components/MessageBox/MessageBox";
import NotFound from "../../NotFound/NotFound";
import RecipeWidgetList from "../../../../../components/RecipeWidgetList/RecipeWidgetList";
import {
  toggleRecipesTagSearch,
  getRecipesTagsSearch,
} from "../../../../../state";
import constant from "../../../../../constant";
import RecipeImage1 from "../../../../../assets/icons/categories/vegan.png";
import RecipeImage2 from "../../../../../assets/icons/categories/vegetarian.png";
import RecipeImage3 from "../../../../../assets/icons/categories/gluten-free.png";
import RecipeImage4 from "../../../../../assets/icons/categories/pescatarian.png";
import RecipeImage5 from "../../../../../assets/icons/categories/sugar-free.png";
import RecipeImage6 from "../../../../../assets/icons/categories/low-sugar.png";
import RecipeImage7 from "../../../../../assets/icons/categories/nut-free.png";

import styles from "./TagSearchRecipes.module.scss";
import AnimateElement from "../../../../../components/AnimateElement";

const recipeCategories = constant.recipeCategory.options;
const sliderImages = [
  RecipeImage1,
  RecipeImage2,
  RecipeImage3,
  RecipeImage4,
  RecipeImage5,
  RecipeImage6,
  RecipeImage7,
];

const TagSearchRecipes = (props) => {
  const { label } = props;
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search, shallowEqual);

  useEffect(() => {
    dispatch(getRecipesTagsSearch([label ? label : ""]));
  }, [label, dispatch]);

  const handleFilterTagClick = (value) => {
    if (label) {
      dispatch(getRecipesTagsSearch([label, value]));
    } else {
      dispatch(getRecipesTagsSearch([value]));
    }
  };

  return (
    <>
      <div className={styles.sliderCategoryContainer}>
        <div className={styles.sliderCategoryContent}>
          <ButtonBase
            className={styles.sliderCategoryContentImageMostPopular}
            onClick={() => handleFilterTagClick("")}
          >
            <div className={styles.mostPopularContent}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.5663 14.6506C19.7938 14.3539 19.8937 13.9882 19.8476 13.6186C19.8005 13.248 19.6124 12.9178 19.3138 12.6883L16.8908 10.8557C16.7785 10.7703 16.7161 10.6359 16.7237 10.4957L16.8898 7.44484C16.9081 7.12996 16.8245 6.83428 16.6354 6.56164C16.1929 5.94244 15.3289 5.79844 14.7106 6.2362L12.1781 8.0026C12.062 8.0842 11.9122 8.10244 11.7769 8.05252L8.91986 6.98692C8.91314 6.98404 8.90546 6.98116 8.89874 6.97924C8.62418 6.89092 8.33906 6.88228 8.05778 6.9562C7.31666 7.1722 6.88178 7.9498 7.09106 8.69476L7.95122 11.6208C7.99058 11.7581 7.96082 11.9098 7.86866 12.0211L5.93618 14.4221C5.7365 14.6717 5.62802 14.9693 5.62418 15.2813C5.61266 16.0387 6.22322 16.6666 6.98642 16.681L10.0556 16.729C10.1919 16.729 10.3196 16.7981 10.3964 16.9143L12.0553 19.4544C12.2261 19.7223 12.4834 19.92 12.757 20.0026C12.9097 20.0583 13.0671 20.0851 13.2255 20.0851C13.4281 20.0851 13.6306 20.04 13.8207 19.9498C14.1596 19.7866 14.4159 19.4995 14.5378 19.1463L15.5689 16.2538C15.6178 16.1146 15.7282 16.0099 15.8713 15.9715L18.8377 15.144C19.141 15.0538 19.3935 14.881 19.5663 14.6506Z"
                  fill="white"
                />
                <path
                  opacity="0.4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.6043 5.78497C12.0017 5.78497 12.3243 5.46241 12.3243 5.06497V3.75937C12.3243 3.36193 12.0017 3.03937 11.6043 3.03937C11.2068 3.03937 10.8843 3.36193 10.8843 3.75937V5.06497C10.8843 5.46241 11.2068 5.78497 11.6043 5.78497Z"
                  fill="white"
                />
                <path
                  opacity="0.4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.378 10.1916C19.4509 10.1916 19.5249 10.18 19.5978 10.157L20.4637 9.87861C20.842 9.75669 21.0503 9.35155 20.9284 8.97235C20.8055 8.59315 20.3985 8.38675 20.0231 8.50771L19.1581 8.78611C18.7789 8.90803 18.5706 9.31315 18.6925 9.69141C18.7914 9.99765 19.0737 10.1916 19.378 10.1916Z"
                  fill="white"
                />
                <path
                  opacity="0.4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.92927 11.0166H3.75711C3.35967 11.0166 3.03711 11.3392 3.03711 11.7366C3.03711 12.134 3.35967 12.4566 3.75711 12.4566H4.92927C5.32671 12.4566 5.64927 12.134 5.64927 11.7366C5.64927 11.3392 5.32671 11.0166 4.92927 11.0166Z"
                  fill="white"
                />
                <path
                  opacity="0.4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.65278 18.7045C9.27743 18.5806 8.86847 18.7861 8.74367 19.1633L8.44031 20.0849C8.41631 20.1598 8.40479 20.2347 8.40479 20.3096C8.40383 20.612 8.59583 20.8942 8.89919 20.9941C8.97407 21.0181 9.04991 21.0296 9.12479 21.0296C9.42719 21.0296 9.70846 20.8376 9.8083 20.5352L10.1117 19.6136C10.1357 19.5387 10.1481 19.4638 10.1481 19.3889C10.1481 19.0856 9.95614 18.8043 9.65278 18.7045Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.5717 18.2546C18.301 17.9618 17.8431 17.9493 17.5532 18.2181C17.2632 18.4898 17.2469 18.9458 17.5176 19.2357L19.6565 21.5301C19.7976 21.6827 19.9916 21.7595 20.1836 21.7595C20.3592 21.7595 20.5359 21.6952 20.6741 21.5666C20.965 21.2949 20.9813 20.8389 20.7106 20.549L18.5717 18.2546Z"
                  fill="white"
                />
              </svg>
            </div>
          </ButtonBase>
          <span className={styles.sliderCategoryContentTextMostPopular}>
            Most Popular
          </span>
        </div>
        {recipeCategories.map((value, index) => (
          <div key={index} className={styles.sliderCategoryContent}>
            <ButtonBase
              className={styles.sliderCategoryContentImage}
              style={{ backgroundImage: `url(${sliderImages[index]})` }}
              onClick={() => handleFilterTagClick(value)}
            />
            <span className={styles.sliderCategoryContentText}>{value}</span>
          </div>
        ))}
      </div>

      {searchState.recipesTagIsLoading === true &&
        searchState.recipesTagIsError === false && (
          <div className="px-4 pt-4 text-center">
            <CircularProgress size={15} disableShrink />
          </div>
        )}

      {searchState.recipesTagIsLoading === false &&
        searchState.recipesTagIsError === true && (
          <MessageBox text="Failed to load your search" />
        )}

      {searchState.recipesTagIsLoading === false &&
        searchState.recipesTagIsError === false &&
        searchState.recipesTagResult.length === 0 && (
          <NotFound tag activeText="recipe" />
        )}

      {searchState.recipesTagIsLoading === false &&
        searchState.recipesTagIsError === false && (
          <AnimateElement duration={"0.5"}>
            <RecipeWidgetList padding recipes={searchState.recipesTagResult} />
          </AnimateElement>
        )}
    </>
  );
};

export default TagSearchRecipes;
