import React, { useState, useEffect, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";

import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";

import RecipeImage1 from "./../../assets/search-page/grocery-1.png";
import RecipeImage2 from "./../../assets/search-page/grocery-2.png";
import RecipeImage3 from "./../../assets/search-page/grocery-3.png";
import RecipeImage4 from "./../../assets/search-page/grocery-4.png";

import RecipeWidgetList from "../../components/RecipeWidgetList/RecipeWidgetList";
import styles from "./SuggestedRecipes.module.scss";
import constant from "./../../constant";
import AnimateElement from "../../components/AnimateElement";

const dietary = constant.dietary.options;
const sliderImages = [RecipeImage1, RecipeImage2, RecipeImage3, RecipeImage4];

const shopCategories = constant.shopCategories.options;
const tabs = ["Popular", ...shopCategories];

const SuggestedRecipes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Popular");
  const [displayedRecipes, setDisplayedRecipes] = useState([]);
  const recipeState = useSelector((state) => state.recipes, shallowEqual);

  useEffect(() => {
    setDisplayedRecipes(recipeState.recipes.slice(0, 6));
  }, [recipeState.recipes]);

  const handleTabChange = useCallback((event, value) => {
    setActiveTab(value);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton size="small" onClick={() => history.goBack()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00065 12.3379L20 12.3379"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3691 19.6759L3.99988 12.3379L11.3691 5"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
        <span className={styles.headerText}>Recipes By Dietitian</span>
        <IconButton size="small">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M17.5 18L21.5423 22"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="11.5"
              cy="11.5"
              r="8.5"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
      </div>

      <AppBar position="sticky" color="default" className={styles.appBar}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          aria-label="Groceries tabs"
          classes={{ indicator: styles.indicator }}
          TabIndicatorProps={{ children: <span /> }}
        >
          {recipeState.recipesIsLoading === false &&
            recipeState.recipesIsError === false &&
            tabs.map((category, idx) => (
              <Tab
                key={idx}
                aria-labelledby={category}
                className={styles.tab}
                classes={{ selected: styles.tabSelected }}
                label={category}
                value={category}
              />
            ))}
        </Tabs>
      </AppBar>

      <div className={styles.sliderCategoryContainer}>
        {recipeState.recipesIsLoading === false &&
          recipeState.recipesIsError === false &&
          dietary.map((value, index) => (
            <AnimateElement duration={"0.5"}>
              <div key={index} className={styles.sliderCategoryContent}>
                <ButtonBase
                  className={styles.sliderCategoryContentImage}
                  style={{ backgroundImage: `url(${sliderImages[index % 4]})` }}
                />
                <span className={styles.sliderCategoryContentText}>
                  {value}
                </span>
              </div>
            </AnimateElement>
          ))}
      </div>

      <div className={styles.containerPopular}>
        {recipeState.recipesIsLoading === false &&
          recipeState.recipesIsError === true && (
            <MessageBox text="Failed to load popular" />
          )}
        {recipeState.recipesIsLoading === true &&
          recipeState.recipesIsError === false && (
            <>
              <Spinner variant="rect" height={140} />
              <Spinner variant="text" height={40} />
              <Spinner variant="text" height={40} />
            </>
          )}
        {recipeState.recipesIsLoading === false &&
          recipeState.recipesIsError === false && (
            <AnimateElement>
              <RecipeWidgetList recipes={displayedRecipes} />
            </AnimateElement>
          )}
      </div>
    </div>
  );
};

export default SuggestedRecipes;
