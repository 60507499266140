import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Login from "./Login/Login";
import Home from "./Home/Home";
import HomeDietitian from "./HomeDietitian/HomeDietitian";
import Account from "./Account/Account";
import Recipes from "./Recipes/Recipes";
import SuggestedRecipes from "./SuggestedRecipes/SuggestedRecipes";
import Recipe from "./Recipe/Recipe";
import RecipeUpload from "./RecipeUpload/RecipeUpload";
import Groceries from "./Groceries/Groceries";
import SuggestedGroceries from "./SuggestedGroceries/SuggestedGroceries";
import Grocery from "./Grocery/Grocery";
import Search from "./Search/Search";
import Chat from "./Chat/Chat";
import Cart from "./Cart/Cart";
import SuggestedArticles from "./SuggestedArticles/SuggestedArticles";
import Redeem from "./Redeem/Redeem";
import Calendar from "./Calendar/Calendar";
import Dietitian from "./Dietitian/Dietitian";
import Article from "./Article/Article";
import UserNotifications from "./UserNotifications/UserNotifications";
import BestDeals from "./BestDeals/BestDeals";
import GettingStarted from "./Welcome/Welcome";
import CalenderlyEmbed from "./CalenderlyEmbed";
import SessionDietitian from "./SessionDietitian/SessionDietitian";
import IncomeDietitian from "./IncomeDietitian/IncomeDietitian";

import {
  auth,
  getUserData,
  createUserData,
  updateUserDeviceToken,
  updateUserData,
  addFoodPreference,
  checkStripePaidUsers,
} from "../services/firebase";
import {
  toast,
  closeToast,
  setUser,
  setUserAccountType,
  logoutUser,
  getRecipes,
  getGroceries,
  revalidateCart,
  getProfiles,
  getAllPuplicChat,
  getPromoCodes,
  getDeliveryAmount,
  getArticles,
  get10Articles,
  getOnly10Recipe,
  get10Grocery,
  getNotifications,
  setLoading,
  getAllUnreadIndividualNotifications,
  getUserUnreadGroupMessages,
} from "../state";
import {
  createTwilioClient,
  updateConversationUser,
  getSubscribedConversations,
  joinPuplicConversation,
} from "./../services/twilio";
import Track from "./Track/Track";
import Onboarding from "./Onboarding/Onboarding";
import WelcomeScreen from "./Welcome/Welcome"
import GptChat from "./GptChat/GptChat";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import GetStartedArticle from "./Onboarding/GetStatedArticle/GetStartedArticle";
import SuccessPage from "./Onboarding/SuccessPage/SuccessPage";
import BookingConfirmed from "./Track/Confirmed/BookingConfirmed";
import HealthProfile from "./Dietitian/HealthProfile/HealthProfile";
import MealPlan from "./MealPlan/MealPlan";
import Edit from "./MealPlan/Edit/edit";
import ShopList from "./MealPlan/Shoplist/ShopList";
import TaskByDate from "./Dietitian/Task/TaskByDate/TaskByDate";
import TaskDetail from "./Dietitian/Task/TaskByDate/TaskDetails/TaskDetail";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

let twilioClient = null;

const Pages = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user, shallowEqual);
  const notifyState = useSelector((state) => state.notify, shallowEqual);
  const [conversationsReady, setConversationsReady] = useState(false);
  const [privateConversationsIsLoading, setPrivateConversationsIsLoading] =
    useState(true);
  const [privateConversations, setPrivateConversations] = useState([]);
  const visited = localStorage.getItem("visited");


  useEffect(() => {
    dispatch(getRecipes());
    dispatch(getOnly10Recipe());
    dispatch(getGroceries());
    dispatch(revalidateCart());
    dispatch(getPromoCodes());
    dispatch(getDeliveryAmount());
    dispatch(getArticles());
    dispatch(get10Articles());
    dispatch(get10Grocery());
  }, [dispatch]);

  const isDietitian =
    userState?.user?.isDietitian && userState?.accountType === "dietitian";

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user && user.uid && user.email) {
        const userData = await getUserData(user.uid);
        const paidUser = await checkStripePaidUsers(user.email);
        // console.log(user, 'check', paidUser)
        if (userData) {
          // check if user has a creation date if not update userdata and update lastloggedin
          if (!userData.created) {
            await updateUserData(user.uid, {
              ...userData,
              created: user.metadata.creationTime,
            });
          }
          await updateUserData(user.uid, {
            ...userData,
            lastLoggedIn: user.metadata.lastSignInTime,
          });
          if (paidUser) {
            if (!userData?.isPremuimUser) {
              await updateUserData(user.uid, {
                ...userData,
                isPremuimUser: true,
                isPremuimDetails: paidUser.isPremuimDetails,
              });
            }
          }
          dispatch(setUser(userData));
          dispatch(
            setUserAccountType(
              localStorage.getItem("accountType")
                ? localStorage.getItem("accountType")
                : userData?.isDietitian
                ? "dietitian"
                : "consumer"
            )
          );
          localStorage.setItem("visited", true);
          dispatch(setLoading(false));
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.getUserId &&
            window.webkit.messageHandlers.getUserId.postMessage
          ) {
            //IOS
            window.webkit.messageHandlers.getUserId.postMessage({
              userId: user.uid,
            });
          } else if (
            window.AndroidSignInFlow &&
            window.AndroidSignInFlow.getUserId
          ) {
            //Android
            window.AndroidSignInFlow.getUserId(user.uid);
          } else {
            //Web
            updateUserDeviceToken(user.uid);
          }
        } else {
          const paidUserData = {
            uid: user.uid,
            photoURL: user.photoURL,
            displayName: user.displayName,
            email: user.email,
            fcmTokenList: [],
            events: [],
            followers: [],
            following: [],
            points: 0,
            flaged: false,
            created: user.metadata.creationTime,
            lastLoggedIn: user.metadata.lastSignInTime,
            isPremuimUser: true,
            isPremuimDetails: paidUser?.isPremuimDetails,
          };

          const unpaidUserData = {
            uid: user.uid,
            photoURL: user.photoURL,
            displayName: user.displayName,
            email: user.email,
            fcmTokenList: [],
            events: [],
            followers: [],
            following: [],
            points: 0,
            flaged: false,
            created: user.metadata.creationTime,
            lastLoggedIn: user.metadata.lastSignInTime,
            isPremuimUser: false,
          };

          const newUserData = paidUser ? paidUserData : unpaidUserData;
          await createUserData(user.uid, newUserData);
          dispatch(setUser(newUserData));
          dispatch(
            setUserAccountType(
              localStorage.getItem("accountType")
                ? localStorage.getItem("accountType")
                : userData?.isDietitian
                ? "dietitian"
                : "consumer"
            )
          );
          dispatch(setLoading(false));
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.getUserId
          ) {
            //IOS
            window.webkit.messageHandlers.getUserId({ userId: user.uid });
          } else if (
            window.AndroidSignInFlow &&
            window.AndroidSignInFlow.getUserId
          ) {
            //Android
            window.AndroidSignInFlow.getUserId(user.uid);
          } else {
            //Web
            updateUserDeviceToken(user.uid);
          }
        }
      } else {
        dispatch(logoutUser());
        dispatch(setLoading(false));
        // localStorage.clear();
      }
    });
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        if (userState.user && userState.user.uid && !twilioClient) {
          //get Profile
          dispatch(getProfiles(userState.user.uid));
          //puplic Chat
          dispatch(getAllPuplicChat());
          //PrivateChat
          twilioClient = await createTwilioClient(userState.user.uid);
          twilioClient.on("connectionStateChanged", (state) => {
            if (state === "connected") {
              setConversationsReady(true);
            }
            if (state === "disconnecting") setConversationsReady(false);
            if (state === "disconnected") setConversationsReady(false);
            if (state === "denied") setConversationsReady(false);
          });
        }
      } catch {
        dispatch(toast({ message: "Failed to initialize chat" }));
      }
    })();
  }, [dispatch, userState.user]);

  useEffect(() => {
    if (userState.user) {
      dispatch(getNotifications(userState.user.uid));
    }
  }, [dispatch, userState.user]);

  useEffect(() => {
    if (userState.user) {
      dispatch(getAllUnreadIndividualNotifications(userState.user.uid));
      dispatch(getUserUnreadGroupMessages(userState.user.uid));
    }
  }, [dispatch, userState.user]);

  useEffect(() => {
    (async () => {
      try {
        if (conversationsReady) {
          const conversations = await getSubscribedConversations(twilioClient);
          setPrivateConversations(conversations.items);
          setPrivateConversationsIsLoading(false);
        }
      } catch {
        dispatch(toast({ message: "Unable to load chat" }));
      }
    })();
  }, [conversationsReady, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        if (conversationsReady) {
          twilioClient.on("conversationJoined", (conversation) => {
            setPrivateConversations((previousPrivateConversations) => {
              if (
                previousPrivateConversations.find(
                  (priviousConversation) =>
                    priviousConversation.sid === conversation.sid
                )
              ) {
                return previousPrivateConversations;
              } else {
                return [conversation, ...previousPrivateConversations];
              }
            });
          });
          twilioClient.on("conversationLeft", (conversation) => {
            setPrivateConversations((previousPrivateConversations) =>
              previousPrivateConversations.filter(
                (priviousConversation) =>
                  priviousConversation.sid !== conversation.sid
              )
            );
          });
        }
      } catch {
        return;
      }
    })();
  }, [conversationsReady, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        if (conversationsReady) {
          await updateConversationUser(twilioClient, userState.user);
        }
      } catch {
        return;
      }
    })();
  }, [conversationsReady, userState.user]);

  if (userState?.isLoading) {
    return <SplashScreen />;
  }

  return (
     <>
        <ScrollToTop />
        <Switch>
           <Route path='/' exact>
              {isDietitian ? (
                 <HomeDietitian conversation={privateConversations} privateConversationsIsLoading={privateConversationsIsLoading} />
              ) : !visited && !userState.user ? (
                 <WelcomeScreen />
              ) : (
                 <Home conversation={privateConversations} privateConversationsIsLoading={privateConversationsIsLoading} />
              )}
           </Route>
           <Route path='/shop' exact>
              <Groceries />
           </Route>
           <Route path='/suggested-shop' exact>
              <SuggestedGroceries />
           </Route>
           <Route path='/shop/:groceryId' exact>
              <Grocery />
           </Route>
           <Route path='/recipes' exact>
              <Recipes />
           </Route>
           <Route path='/suggested-recipes' exact>
              <SuggestedRecipes />
           </Route>
           <Route path='/recipe/:recipeId' exact>
              <Recipe />
           </Route>
           <Route path='/search'>
              <Search />
           </Route>
           <Route path='/cart' exact>
              <Cart />
           </Route>

           <Route path='/suggested-articles' exact>
              <SuggestedArticles />
           </Route>
           <Route path='/article/:articleId' exact>
              <Article />
           </Route>
           <Route path='/redeem/:userId/:groceryId/:quantity' exact>
              <Redeem />
           </Route>
           <Route path='/best-deals'>
              <BestDeals />
           </Route>
           <Route path='/getting-started'>
              <GettingStarted />
           </Route>
           <Route path='/getstarted'>
              <GetStartedArticle />
           </Route>
           {/* Redirect login when a user is already login */}
           <Route path='/login' exact>
              {userState.user && userState.user.uid ? <Redirect to='/' /> : <Login />}
           </Route>
           {/* require auth */}
           <Route path='/recipeupload' exact>
              {userState.user && userState.user.uid ? <RecipeUpload /> : <Login />}
           </Route>
           <Route path='/mealplan' exact>
              {userState.user && userState.user.uid ? <MealPlan /> : <Login />}
           </Route>
           <Route path='/mealplan/:date' exact>
              {userState.user && userState.user.uid ? <MealPlan /> : <Login />}
           </Route>
           <Route path='/success'>{userState.user && userState.user.uid ? <SuccessPage /> : <Login />}</Route>
           <Route path='/bookingConfirmed'>{userState.user && userState.user.uid ? <BookingConfirmed /> : <Login />}</Route>
           <Route path='/list' exact>
              {userState.user && userState.user.uid ? <ShopList /> : <Login />}
           </Route>
           <Route path='/list/:date' exact>
              {userState.user && userState.user.uid ? <ShopList /> : <Login />}
           </Route>
           <Route path='/mealplan/:date/edit' exact>
              {userState.user && userState.user.uid ? <Edit /> : <Login />}
           </Route>
           <Route path='/notifications' exact>
              {userState.user && userState.user.uid ? (
                 <UserNotifications
                    twilioClient={twilioClient}
                    conversationsReady={conversationsReady}
                    privateConversationsIsLoading={privateConversationsIsLoading}
                    privateConversations={privateConversations}
                 />
              ) : (
                 <Login />
              )}
           </Route>
           <Route path='/calendly' exact>
              {userState.user && userState.user.uid ? <CalenderlyEmbed /> : <Login />}
           </Route>
           <Route path='/recipeupload/editing/:stage' exact>
              {userState.user && userState.user.uid ? <RecipeUpload /> : <Login />}
           </Route>
           <Route path='/account'>{userState.user && userState.user.uid ? <Account /> : <Login />}</Route>
           <Route path='/chat'>
              {userState.user && userState.user.uid ? (
                 <Chat
                    twilioClient={twilioClient}
                    conversationsReady={conversationsReady}
                    privateConversationsIsLoading={privateConversationsIsLoading}
                    privateConversations={privateConversations}
                 />
              ) : (
                 <Login />
              )}
           </Route>
           <Route path='/track' exact>
              {userState.user && userState.user.uid ? <Track /> : <Login />}
           </Route>
           <Route path='/track/:editing/:currentStage' exact>
              {userState.user && userState.user.uid ? <Track /> : <Login />}
           </Route>
           <Route path='/onboarding' exact>
              {userState.user && userState.user.uid ? <Onboarding /> : <Login />}
           </Route>
           <Route path='/onboarding/:editing/:currentStage' exact>
              {userState.user && userState.user.uid ? <Onboarding /> : <Login />}
           </Route>
           <Route path='/calendar' exact>
              {userState.user && userState.user.uid ? <Calendar /> : <Login />}
           </Route>
           <Route path='/gpt/:type' exact>
              {userState.user && userState.user.uid ? <GptChat /> : <Login />}
           </Route>
           <Route path='/calendar/:userId' exact>
              {userState.user && userState.user.uid ? <Calendar /> : <Login />}
           </Route>
           <Route path='/health-profile/:userId' exact>
              {userState.user && userState.user.uid ? <HealthProfile /> : <Login />}
           </Route>
           <Route path='/dietitian'>
              {userState.user && userState.user.uid ? <Dietitian privateConversations={privateConversations} isDietitian={isDietitian} /> : <Login />}
           </Route>
           <Route path='/sessions' exact>
              {userState.user && userState.user.uid ? isDietitian ? <SessionDietitian /> : <Redirect to='/' /> : <Login />}
           </Route>
           <Route path='/income' exact>
              {userState.user && userState.user.uid ? isDietitian ? <IncomeDietitian /> : <Redirect to='/' /> : <Login />}
           </Route>
           <Route path='/tasks' exact>
              {userState.user && userState.user.uid ? <TaskByDate /> : <Login />}
           </Route>
           <Route path='/tasks/:startDate/:endDate' exact>
              {userState.user && userState.user.uid ? <TaskDetail /> : <Login />}
           </Route>
           <Route path='/calenderly'>
              <CalenderlyEmbed />
           </Route>


           {/* Redirect all unknow url */}
           <Redirect to='/' />
        </Switch>
        <Snackbar
           anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
           open={notifyState.open}
           autoHideDuration={10000}
           onClose={() => dispatch(closeToast())}
           message={notifyState.message}
        />
        {}
     </>
  );
};

export default Pages;
