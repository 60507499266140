import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import styles from "./SuggestedArticles.module.scss";

import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";

import constant from "./../../constant";

import { ArticleWidget } from "../../components/articleWidget/articleWidget";
import AnimateElement from "../../components/AnimateElement";

const type = constant.type.options;
const tabs = ["For You", ...type];

const SuggestedGrocery = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("For You");
  const articleState = useSelector((state) => state.articles, shallowEqual);
  const { articles } = articleState;

  const handleTabChange = useCallback((event, value) => {
    setActiveTab(value);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton size="small" onClick={() => history.goBack()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00065 12.3379L20 12.3379"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3691 19.6759L3.99988 12.3379L11.3691 5"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
        <span className={styles.headerText}>Learn & Discover</span>
        <IconButton size="small">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M17.5 18L21.5423 22"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="11.5"
              cy="11.5"
              r="8.5"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
      </div>

      <AppBar position="sticky" color="default" className={styles.appBar}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          aria-label="Groceries tabs"
          classes={{ indicator: styles.indicator }}
          TabIndicatorProps={{ children: <span /> }}
        >
          {tabs.map((category, idx) => (
            <Tab
              key={idx}
              aria-labelledby={category}
              className={styles.tab}
              classes={{ selected: styles.tabSelected }}
              label={category}
              value={category}
            />
          ))}
        </Tabs>
      </AppBar>

      <div className={styles.contentContainer}>
        {activeTab === "For You" && (
          <>
            {articleState.articlesIsLoading === true &&
              articleState.articlesIsError === false && (
                <div className="px-3">
                  <Spinner variant="rect" height={120} />
                </div>
              )}
            {articleState.articlesIsLoading === false &&
              articleState.articlesIsError === true && (
                <MessageBox text="Failed to load learn & discover" />
              )}

            {articleState.articlesIsLoading === false &&
              articleState.articlesIsError === false &&
              articleState.articles.length == 0 && (
                <MessageBox text="No articles found" />
              )}

            {articleState.articlesIsLoading === false &&
              articleState.articlesIsError === false &&
              articleState.articles.length > 0 && (
                <AnimateElement duration={"0.5"}>
                  <ArticleWidget articles={articles} limit={articles.length} />
                </AnimateElement>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default SuggestedGrocery;
