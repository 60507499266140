import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./NotificationToggle.module.scss";
import Divider from "@material-ui/core/Divider";
import { updateUserNotificationPreferences } from "../../services/firebase";
import { toggleNotification } from "../../state";

import ToggleButton from "../ToggleButton/ToggleButton";

const NotificationToggle = ({ notification, preference }) => {
   const dispatch = useDispatch();
   const { user } = useSelector((state) => state.user, shallowEqual);

   const handleToggle = async () => {
      dispatch(toggleNotification(notification.slug));
   };

   useEffect(() => {
      const updatePreference = async () => {
         try {
            await updateUserNotificationPreferences(user.uid, preference);
         } catch (error) {
            console.log(error);
         }
      };
      updatePreference();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [preference]);

   return (
      <>
         <div className={styles.container}>
            {notification?.option && <p className={styles.promotionalTitle}>{notification.option}</p>}
            <div className={styles.contentContainer}>
               <div className={styles.textContent}>
                  <h3 className={styles.title}>{notification.title}</h3>
                  <p className={styles.description}>{notification.description}</p>
               </div>
               <div className={styles.toggle}>
                  <ToggleButton toggle={notification.status} handleToggle={handleToggle} />
               </div>
            </div>
         </div>
         <Divider />
      </>
   );
};

export default NotificationToggle;
