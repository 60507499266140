import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import NavigationHeader from '../../components/NavigationHeader/NavigationHeader';
import SearchIcon from '@material-ui/icons/Search';
import GroceryWidgetList from "./../../components/GroceryWidgetList/GroceryWidgetList";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import styles from "./BestDeals.module.scss";


import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";



const tabs = ["Popular", "Fruits", "Vegetables", "Nuts & Seed" ];

const Bestdeals = () => {
  const [activeTab, setActiveTab] = useState("Popular");
  const [groceries, setGroceries] = useState([]);
  const groceriesState = useSelector(state => state.groceries, shallowEqual);

  const handleTabChange = useCallback((event, value) => {
    setActiveTab(value);
 }, []);

  useEffect(() => {
    activeTab === "Popular" ? setGroceries(groceriesState.groceries.filter((grocery) => grocery.specialOffers)) : setGroceries(groceriesState.groceries.filter((grocery) => grocery.specialOffers && grocery.shopCategory.includes(activeTab.toLowerCase())));
  }, [activeTab, groceriesState.groceries])


  return (
    <div className={styles.page}>
      <NavigationHeader icon={<SearchIcon />} text="Best Deals"/>
      <AppBar position="sticky" color="default" className={styles.appBar}>
            <Tabs
               value={activeTab}
               onChange={handleTabChange}
               indicatorColor="primary"
               textColor="primary"
               variant="scrollable"
               scrollButtons="off"
               aria-label="Best Deals tabs"
               classes={{ indicator: styles.indicator }}
               TabIndicatorProps={{ children: <span /> }}
            >
               {tabs.map((category, idx) => (
                  <Tab
                     key={idx}
                     aria-labelledby={category}
                     className={styles.tab}
                     classes={{ selected: styles.tabSelected }}
                     label={category}
                     value={category}
                  />
               ))}
            </Tabs>
         </AppBar>
      {groceriesState.groceriesIsLoading === true && groceriesState.groceriesIsError === false && (
                    <div className="p-4">
                        <Spinner variant="rect" height={140} />
                        <Spinner variant="text" height={40} />
                        <Spinner variant="text" height={40} />
                    </div>
                )}

                {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === true && (
                    <MessageBox text="Failed to load shop items" />
                )}

                {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === false && (
                    <div className={styles.swipeDiv}>
                        {groceries.length === 0 ? <MessageBox text="No shop items matches your selected filter" /> : <GroceryWidgetList groceries={groceries} footer="grocery" />}
                    </div>
                )}
    </div>
  )
}

export default Bestdeals