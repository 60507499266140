import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { revalidateUserCart, getFreeDeliveryAmount } from "../services/firebase";

export const revalidateCart = createAsyncThunk(
  "Cart/revalidateUserCart",
  async () => {
    const response = await revalidateUserCart(
      JSON.parse(localStorage.getItem("cart")) || []
    );
    localStorage.setItem("cart", JSON.stringify(response));
    return response;
  }
);

export const getDeliveryAmount = createAsyncThunk('Cart/getFreeDeliveryAmount', async () => {
    const { amount } = await getFreeDeliveryAmount()
    return amount;
})

const initialState = {
  cart: [],
  freeDeliveryAmount: 0
};

export const Cart = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    updateCart: (state, action) => {
      let item;
      let cart = [];
      if (action.payload.groceryId) {
        item = state.cart.find(
          (item) => item.groceryId === action.payload.groceryId
        );
      }
      if (action.payload.rewardId) {
        item = state.cart.find(
          (item) => item.rewardId === action.payload.rewardId
        );
      }
      if (item) {
        if (action.payload.groceryId) {
          cart = state.cart.map((item) => {
            if (item.groceryId === action.payload.groceryId) {
              return action.payload;
            } else {
              return item;
            }
          });
        }
        if (action.payload.rewardId) {
          cart = state.cart.map((item) => {
            if (item.rewardId === action.payload.rewardId) {
              return action.payload;
            } else {
              return item;
            }
          });
        }
      } else {
        cart = state.cart.concat(action.payload);
      }
      state.cart = cart.filter((item) => item.quantity > 0);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    clearCart: (state) => {
      state.cart = [];
      localStorage.removeItem("cart");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(revalidateCart.pending, (state) => {
        state.cart = [];
      })
      .addCase(revalidateCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(revalidateCart.rejected, (state) => {
        state.cart = [];
      });

    builder
      .addCase(getDeliveryAmount.pending, (state) => {
        state.freeDeliveryAmount = 0;
      })
      .addCase(getDeliveryAmount.fulfilled, (state, action) => {
        state.freeDeliveryAmount = action.payload;
      })
      .addCase(getDeliveryAmount.rejected, (state) => {
        state.freeDeliveryAmount = 0;
      });
  },
});

export const { updateCart, clearCart } = Cart.actions;
export default Cart.reducer;
