import React, { useState, useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import format from "date-fns/format";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import cx from "classnames";

import startOfMonth from 'date-fns/startOfMonth'
import subMonths from 'date-fns/subMonths';
import getUnixTime from 'date-fns/getUnixTime'

import Footer from "../../layout/Footer/Footer";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import { getDietitianIncomeQuery } from "../../services/queries";
import styles from "./IncomeDietitian.module.scss";

const numberFormat = (value, fraction = 2) => {
   return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction
   }).format(value);
}


const IncomeDietitian = () => {

   const [period, setPeriod] = useState("1month");
   const [filter, setFilter] = useState(getUnixTime(startOfMonth(new Date())));
   const { user } = useSelector((state) => state.user, shallowEqual);
   const { isLoading, isError, data } = getDietitianIncomeQuery(user.uid);


   const incomes = useMemo(() => {
      if (data) {
         data.forEach(income => console.log(income.created >= filter, income.created, filter));
         return data.filter(income => income.created >= filter);
      } else {
         return null;
      }
   }, [data, filter])

   useEffect(() => {
      switch (period) {
         case "1month":
            const date1 = startOfMonth(new Date());
            setFilter(getUnixTime(date1));
            break;
         case "3month":
            const date3 = startOfMonth(subMonths(new Date(), 3));
            setFilter(getUnixTime(date3));
            break;
         case "6month":
            const date6 = startOfMonth(subMonths(new Date(), 6));
            setFilter(getUnixTime(date6));
            break;
         case "12month":
            const date12 = startOfMonth(subMonths(new Date(), 12));
            setFilter(getUnixTime(date12));
            break;
         case "all":
            setFilter(0);
            break;
         default:
            break;
      }
   }, [period])




   return (

      <div className={styles.page}>
         <div className={styles.header}>
            <div className={styles.headertext}>Income</div>
            <IconButton size="small">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8067 7.62349L20.1842 6.5434C19.6577 5.62948 18.4907 5.3142 17.5755 5.83859V5.83859C17.1399 6.09522 16.6201 6.16803 16.1307 6.04096C15.6413 5.9139 15.2226 5.5974 14.9668 5.16125C14.8023 4.88403 14.7139 4.56827 14.7105 4.24592V4.24592C14.7254 3.7291 14.5304 3.22828 14.17 2.85755C13.8096 2.48681 13.3145 2.27774 12.7975 2.27795H11.5435C11.037 2.27795 10.5513 2.47979 10.194 2.83882C9.83669 3.19785 9.63717 3.68447 9.63961 4.191V4.191C9.6246 5.2368 8.77248 6.07669 7.72657 6.07658C7.40421 6.07323 7.08846 5.98482 6.81123 5.82029V5.82029C5.89606 5.29589 4.72911 5.61117 4.20254 6.52509L3.53435 7.62349C3.00841 8.53627 3.3194 9.70248 4.23 10.2322V10.2322C4.8219 10.5739 5.18653 11.2055 5.18653 11.8889C5.18653 12.5724 4.8219 13.204 4.23 13.5457V13.5457C3.32056 14.0718 3.00923 15.2352 3.53435 16.1452V16.1452L4.16593 17.2345C4.41265 17.6797 4.8266 18.0082 5.31619 18.1473C5.80578 18.2865 6.33064 18.2248 6.77462 17.9759V17.9759C7.21108 17.7212 7.73119 17.6514 8.21934 17.7821C8.70749 17.9127 9.12324 18.2329 9.37416 18.6715C9.5387 18.9488 9.62711 19.2645 9.63046 19.5869V19.5869C9.63046 20.6434 10.487 21.4999 11.5435 21.4999H12.7975C13.8505 21.4999 14.7055 20.649 14.7105 19.596V19.596C14.7081 19.0879 14.9089 18.5999 15.2682 18.2406C15.6275 17.8813 16.1155 17.6805 16.6236 17.683C16.9452 17.6916 17.2596 17.7796 17.5389 17.9393V17.9393C18.4517 18.4652 19.6179 18.1542 20.1476 17.2436V17.2436L20.8067 16.1452C21.0618 15.7074 21.1318 15.1859 21.0012 14.6963C20.8706 14.2066 20.5502 13.7892 20.111 13.5365V13.5365C19.6718 13.2838 19.3514 12.8665 19.2208 12.3768C19.0902 11.8872 19.1603 11.3657 19.4154 10.9278C19.5812 10.6382 19.8214 10.3981 20.111 10.2322V10.2322C21.0161 9.70277 21.3264 8.54337 20.8067 7.63265V7.63265V7.62349Z" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <circle opacity="0.4" cx="12.1751" cy="11.889" r="2.63616" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
            </IconButton>
         </div>
         <div className={styles.sliderContainer}>
            <ButtonBase className={cx(styles.sliderContent, { [styles.sliderContentActive]: period === "1month" })} onClick={() => setPeriod("1month")}>Month</ButtonBase>
            <ButtonBase className={cx(styles.sliderContent, { [styles.sliderContentActive]: period === "3month" })} onClick={() => setPeriod("3month")}>3-Month</ButtonBase>
            <ButtonBase className={cx(styles.sliderContent, { [styles.sliderContentActive]: period === "6month" })} onClick={() => setPeriod("6month")}>6-Month</ButtonBase>
            <ButtonBase className={cx(styles.sliderContent, { [styles.sliderContentActive]: period === "12month" })} onClick={() => setPeriod("12month")}>Year</ButtonBase>
            <ButtonBase className={cx(styles.sliderContent, { [styles.sliderContentActive]: period === "all" })} onClick={() => setPeriod("all")}>All</ButtonBase>
         </div>
         <div className={styles.content}>
            {isLoading && (
               <div>
                  <Spinner variant="rect" height={64} />
               </div>
            )}
            {isError && (
               <MessageBox text="Failed to load your incomes" />
            )}

            {incomes && (
               <>
                  <div className={styles.totalPrice}>£{numberFormat(incomes.reduce((acc, current) => acc + (current.price * (current.percent ? current.percent / 100 : 70 / 100)), 0) / 100)}</div>
                  <div className={styles.transactionText}><span>{[...incomes.map(value => value.email)].length} Clients</span> ・ <span>{incomes.length} Transactions</span></div>
                  {incomes && incomes.length === 0 && (
                     <MessageBox text="No income earned for this period" />
                  )}
                  {incomes.map(checkoutItem => {
                     return (
                        <div className={styles.checkoutcardContainer}>
                           <Avatar src={checkoutItem?.user?.photoURL} className={styles.checkoutcardProfile} />
                           <div className={styles.checkoutcardTextContainer}>
                              <div className={styles.checkoutcardTop}><span className={styles.cardElli}>{checkoutItem?.user?.displayName}</span> <span>£{numberFormat((checkoutItem?.price * (checkoutItem.percent ? checkoutItem.percent / 100 : 70 / 100)) / 100)}</span></div>
                              <div className={styles.checkoutcardBottom}><span className={styles.cardElli}>Session</span> <span>{format(new Date(checkoutItem?.created * 1000), "dd MMM yyyy")}</span></div>
                           </div>
                        </div>
                     )
                  })}
               </>
            )}
         </div>
         <Footer />
      </div>

   )
};

export default IncomeDietitian;






