import React from "react";
import styles from "./Pricing.module.scss";
import Divider from "@material-ui/core/Divider";
import tick from "../../assets/tick-good.svg";

const Pricing = ({ background, icon, title, description, price, offers, btnBg, link }) => {
   return (
      <div className={styles.page} style={{ background: background }}>
         <div className={styles.heading}>
            <img src={icon} alt='icon' className={styles.headerIcon} />
            <p className={styles.headingText}>{title}</p>
         </div>

         <div className={styles.description}>{description}</div>

         <div className={styles.price}>
            <span className={styles.priceText}>{price}</span>
         </div>

         <div classNmae={styles.offerContainer}>
            {offers.map((offer, index) => {
               return (
                  <>
                     <Divider />
                     <div className={styles.offer} key={index}>
                        <img src={tick} alt={"tick"} className={styles.offerIcon}></img>
                        <p className={styles.offerText}>{offer}</p>
                     </div>
                  </>
               );
            })}
         </div>
         <Divider />
         <div className={styles.btnCtn}>
            <button className={styles.btn} style={{ background: btnBg }}>
               <a href={link} target='_blank' rel='noopener noreferrer'>
                  <span>Book</span>
               </a>
            </button>
         </div>
      </div>
   );
};

export default Pricing;
