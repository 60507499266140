import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import MessageBox from "../../../../../components/MessageBox/MessageBox";
import NotFound from "../../NotFound/NotFound";
import { ArticleWidget } from "../../../../../components/articleWidget/articleWidget";

import styles from "./TagSearchArticles.module.scss";
import Spinner from "../../../../../components/Spinner/Spinner";
import AnimateElement from "../../../../../components/AnimateElement";

const TagSearchArticles = (props) => {
  const { label } = props;
  const [tabLabel, setTabLabel] = useState(label);
  const [articles, setArticles] = useState([]);

  const articleState = useSelector((state) => state.articles, shallowEqual);
  const categories = ["Latest", "Nutrition", "Fitness", "Sleep"];
  articleState.articles.filter((article) =>
    article.category.includes(tabLabel)
  );

  const handleFilterTagClick = (value) => {
    setTabLabel(value);
  };

  useEffect(() => {
    tabLabel === "Latest"
      ? setArticles(articleState.articles)
      : setArticles(
          articleState.articles.filter((article) =>
            article.category.includes(tabLabel)
          )
        );
  }, [articleState.articles, tabLabel]);

  return (
    <>
      <div className={styles.sliderCategoryContainer}>
        {categories.map((category) => {
          return (
            <ButtonBase
              key={category}
              className={styles.sliderCategoryContent}
              onClick={() => handleFilterTagClick(category)}
            >
              <span>{category}</span>
            </ButtonBase>
          );
        })}
      </div>
      <div className={styles.articlesContainer}>
        {articleState.articlesIsLoading === true &&
          articleState.articlesIsError === false && (
            <div className="px-3">
              <Spinner variant="rect" height={120} />
            </div>
          )}
        {articleState.articlesIsLoading === false &&
          articleState.articlesIsError === true && (
            <MessageBox text="Failed to load articles" />
          )}

        {articleState.articlesIsLoading === false &&
          articleState.articlesIsError === false &&
          articles.length === 0 && <NotFound tag activeText="article" />}

        {articleState.articlesIsLoading === false &&
          articleState.articlesIsError === false &&
          articleState.articles.length > 0 && (
            <AnimateElement duration={"0.5"}>
              <ArticleWidget articles={articles} limit={articles.length} />
            </AnimateElement>
          )}
      </div>
    </>
  );
};

export default TagSearchArticles;
