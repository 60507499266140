import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./FoodUpload.module.scss";
import Grid from "@material-ui/core/Grid";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { CircularProgress } from "@material-ui/core";

import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import inputOption from "../../../constant";

import { nanoid } from "nanoid";

import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";

import { Formik } from "formik";
import * as Yup from "yup";

const initialState = {
   id: "",
   name: "",
   quantity: "",
   unit: "",
   thumbnail: "",
   "energy-kcal": "",
   carbohydrates: "",
   proteins: "",
   fat: "",
};

const validationSchema = Yup.object().shape({
   name: Yup.string().required("name is required"),
   quantity: Yup.string().required("quantity is required"),
   unit: Yup.string().required("unit in is required"),
   "energy-kcal": Yup.string().required("value is required"),
   carbohydrates: Yup.string().required("value is required"),
   proteins: Yup.string().required("value is required"),
   fat: Yup.string().required("value is required"),
   thumbnail: Yup.string().nullable(),
});


const Units = {
   'grams': 'g',
   'ounces': 'oz',
   'pounds': 'lb',
   'millilitres': 'ml', 
   'litres': 'l'
}

const FoodUpload = ({ showFoodUpload, setShowFoodUpload, setSelected, setOpenForm }) => {
   const uniqueId = nanoid();
   const [foodData, setFoodData] = useState(initialState);
   const [loading, setLoading] = useState(false);

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFoodData((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   };

   const handleNutrimentChange = (e) => {
      const { name, value } = e.target;
      setFoodData((prevState) => ({
         ...prevState,
         nutriments: {
            ...prevState.nutriments,
            [name]: value,
         },
      }));
   };

   const handleNext = (values) => {
      setLoading(true);
      const payload = {
         _id: uniqueId,
         name: values.name,
         quantity: Number(values.quantity) + " " + Units[values.unit],
         nutriments: {
            "energy-kcal": Number(values["energy-kcal"]),
            carbohydrates: Number(values.carbohydrates),
            proteins: Number(values.proteins),
            fat: Number(values.fat),
         },
         thumbnail: values.thumbnail,
         unitType: "percent",
         typeArray: [Units[values.unit], "percent"],
         served: Number(values.quantity),
      };  
      setSelected([payload]);
      setLoading(false);
      setShowFoodUpload(false);
      setOpenForm(true);
   };

   const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            resolve(reader.result);
         };
         reader.onerror = (error) => reject(error);
      });
   };

   const handleImageChange = async (e, formik) => {
      const file = e.target.files[0];
      const base64Img = await convertFileToBase64(file);
      formik.setFieldValue("thumbnail", base64Img);
   };

   return (
      <div className={`${styles.page} ${showFoodUpload ? styles.open : ""}`}>
         <div className={styles.backIcon} onClick={() => setShowFoodUpload(false)}>
            <CloseOutlinedIcon />
         </div>
         <div className={`${styles.container} ${styles.disableScrollbars}`}>
            <div className={styles.header}>
               <h1 className={styles.title}>Add Item</h1>
               <p className={styles.text}>Simply add below</p>
            </div>
            <Formik
               validateOnMount
               enableReinitialize
               validationSchema={validationSchema}
               initialValues={foodData}
               onSubmit={(values, { resetForm }) => {
                  handleNext(values);
               }}
            >
               {(formik) => {
                  return (
                     <>
                        <div className={styles.products}>
                           <Grid container spacing={4}>
                              <Grid xs={12} item>
                                 <CustomInput
                                    type='text'
                                    placeholder='Product Name'
                                    name='name'
                                    {...formik.getFieldProps('name')}
                                    error={Boolean(formik.touched.name && formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                 />
                              </Grid>

                              <Grid xs={6} item>
                                 <CustomInput
                                    type='number'
                                    placeholder='Quantity'
                                    name='quantity'
                                    {...formik.getFieldProps('quantity')}
                                    error={Boolean(formik.touched.quantity && formik.errors.quantity)}
                                    helperText={formik.touched.quantity && formik.errors.quantity}
                                 />
                              </Grid>

                              <Grid xs={6} item>
                                 <CustomSelect
                                    select
                                    placeholder='Unit'
                                    name='unit'
                                    {...formik.getFieldProps('unit')}
                                    error={Boolean(formik.touched.unit && formik.errors.unit)}
                                    helperText={formik.touched.unit && formik.errors.unit}

                                 >
                                    {inputOption.units.options.map((value) => {
                                       return (
                                          <MenuItem key={value} value={value.toLocaleLowerCase()}>
                                             {value}
                                          </MenuItem>
                                       );
                                    })}
                                 </CustomSelect>
                              </Grid>
                           </Grid>
                        </div>

                        {/* add picture */}
                        <div className={styles.addPicture}>
                           {formik.values.thumbnail && (
                              <div className={styles.listImage}>
                                 <img src={formik.values.thumbnail} alt='' className={styles.thumbnail} />
                              </div>
                           )}
                           <div className={styles.addPictureBtn}>
                              <input
                                 className={styles.input}
                                 id='icon-button-file'
                                 type='file'
                                 name='thumbnail'
                                 accept='image/*'
                                 onChange={(e) => handleImageChange(e, formik)}
                              />
                              <label htmlFor='icon-button-file' className={styles.text}>
                                 <PhotoCameraOutlinedIcon className={styles.editIcon} />
                                 <span> Add Picture</span>
                              </label>
                           </div>
                        </div>

                        <div className={styles.products}>
                           <Grid container spacing={4} alignItems='center'>
                              <Grid xs={8} item>
                                 <CustomInput
                                    type='number'
                                    placeholder='Calories (Kcal)'
                                    name='energy-kcal'
                                    value={foodData["energy-kcal"]}
                                    {...formik.getFieldProps('energy-kcal')}
                                    error={Boolean(formik.touched["energy-kcal"] && formik.errors["energy-kcal"])}
                                    helperText={formik.touched["energy-kcal"] && formik.errors["energy-kcal"]}
                                 
                                 />
                              </Grid>
                              <p className={styles.text}>Kcal</p>
                           </Grid>
                        </div>

                        <div className={styles.products}>
                           <Grid container spacing={4} alignItems='center'>
                              <Grid xs={8} item>
                                 <CustomInput
                                    type='number'
                                    placeholder='Carbs'
                                    name='carbohydrates'
                                    {...formik.getFieldProps('carbohydrates')}
                                    error={Boolean(formik.touched.carbohydrates && formik.errors.carbohydrates)}
                                    helperText={formik.touched.carbohydrates && formik.errors.carbohydrates}
                                 
                                 />
                              </Grid>
                              <p className={styles.text}>g</p>
                           </Grid>
                        </div>

                        <div className={styles.products}>
                           <Grid container spacing={4} alignItems='center'>
                              <Grid xs={8} item>
                                 <CustomInput
                                    type='number'
                                    placeholder='Protein'
                                    name='proteins'
                                    {...formik.getFieldProps('proteins')}
                                    error={Boolean(formik.touched.proteins && formik.errors.proteins)}
                                    helperText={formik.touched.proteins && formik.errors.proteins}
                                
                                />
                              </Grid>
                              <p className={styles.text}>g</p>
                           </Grid>
                        </div>

                        <div className={styles.products}>
                           <Grid container spacing={4} alignItems='center'>
                              <Grid xs={8} item>
                                 <CustomInput
                                    type='number'
                                    placeholder='Fat'
                                    name='fat'
                                    {...formik.getFieldProps('fat')}
                                    error={Boolean(formik.touched.fat && formik.errors.fat)}
                                    helperText={formik.touched.fat && formik.errors.fat}
                                 />
                              </Grid>
                              <p className={styles.text}>g</p>
                           </Grid>
                        </div>

                        <div className={styles.doneBtn}>
                           <div className={styles.btnCtn}>
                              <button type='submit' className={`${styles.btn} `} onClick={formik.handleSubmit} disabled={
                                 loading ||
                                 formik.errors.name ||
                                 formik.errors.quantity ||
                                 formik.errors.unit ||
                                 formik.errors.carbohydrates ||
                                 formik.errors.proteins ||
                                 formik.errors.fat ||
                                 formik.errors['energy-kcal']
                                 }>
                                 {loading ? <CircularProgress style={{ color: "white", width: "20px", height: "20px" }} /> : "Next"}
                              </button>
                           </div>
                        </div>
                     </>
                  );
               }}
            </Formik>
         </div>
      </div>
   );
};

export default FoodUpload;
