import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllPromoCode } from "../services/firebase";


export const getPromoCodes = createAsyncThunk(
    'Promocodes/getAllPromoCode',
    async () => {
        const response = await getAllPromoCode();
        return response;
    }
);
const initialState = {
    promoCodeIsLoading: true,
    IsError: false,
    promoCodes: [],
    promocode: null,
}

export const PromoCode = createSlice({
    name: 'PromoCode',
    initialState,
    reducers: {
        setPromoCode: (state, action) => {
            state.promoCodes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPromoCodes.pending, (state) => {
                state.promoCodeIsLoading = true;
                state.IsError = false;
            })
            .addCase(getPromoCodes.fulfilled, (state, action) => {
                state.promoCodeIsLoading = false;
                state.IsError = false;
                state.promoCodes = action.payload;
            })
            .addCase(getPromoCodes.rejected, (state) => {
                state.promoCodeIsLoading = false;
                state.IsError = true;
                state.promoCodes = [];
            })
    }
});

export const { setPromoCode } = PromoCode.actions;
export default PromoCode.reducer; 