import React, { useEffect, useState, useCallback, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Layout from "./../../layout";
import constant, { PRICING } from "./../../constant";
import Pricing from "../../components/Pricing/Pricing";
import { toast, setUser } from "./../../state";
import styles from "./Track.module.scss";
import CustomMultiLineSelect from "../../components/CustomerMultiLineSelect/CustomMultiLineSelect";
import strokeGood from "../../assets/icons/strokeGood.svg";
import tick from "../../assets/checkgreen.svg";
import { Checkbox, Chip, CircularProgress, TextField, FormGroup, FormControlLabel, FormLabel } from "@material-ui/core";
import startIMG from "../../assets/trackIMGStart.svg";
import paulina from "../../assets/quiz.png";
import paulinaNPU from "../../assets/paulina-npu.png";
import booksession from "../../assets/booksession.svg";
import endIMG from "../../assets/trackIMGEnd.svg";
import cancel from "../../assets/icons/cancelLight.png";
import { addQuizData, getUserData } from "../../services/firebase";
import { useHistory, useLocation, useParams } from "react-router";
import Divider from "@material-ui/core/Divider";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CancelIcon from "@material-ui/icons/Cancel";

const Track = () => {
   const [display, setDisplay] = useState({
      header: true,
   });
   const history = useHistory();
   const location = useLocation();
   const { editing, currentStage } = useParams();
   const quizRef = useRef();

   const userState = useSelector((state) => state.user, shallowEqual);
   const [loading, setLoading] = useState(false);
   const dispatch = useDispatch();
   const [stage, setStage] = useState(0);
   const [weight, setWeight] = useState("");
   const [type, setType] = useState("");
   const [height, setHeight] = useState("");
   const [values, setValues] = useState([]);
   const [condition, setCondition] = useState("");
   const [weightChange, setWeightChange] = useState(false);
   const [add, setAdd] = useState(false);
   const [diet, setDiet] = useState("No");
   const [heightType, setHeightType] = useState("cm");
   const [weightType, setWeightType] = useState("kg");
   const [historySelected, setHistorySelected] = useState([]);
   const [medicationSelected, setMedicationSelected] = useState([]);
   const [supplementsSelected, setSupplementsSelected] = useState([]);
   const [allergySelected, setAllergySelected] = useState([]);
   const [dietSelected, setDietSelected] = useState([]);
   const [foodSelected, setFoodSelected] = useState([]);
   const [goalsSelected, setGoalsSelected] = useState([]);
   const [sleepList] = useState([
      { title: "Less than 6", value: "Less than 6" },
      { title: "6 - 7", value: "6 - 7" },
      { title: "8 - 9", value: "8 - 9" },
      { title: "10 or more", value: "10 or more" },
   ]);
   const [smokeList] = useState([
      { title: "I dont smoke", value: "I dont smoke" },
      { title: "1 - 3 cigarettes", value: "1 - 3 cigarettes" },
      { title: "4 - 7 cigarettes", value: "4 - 7 cigarettes" },
      { title: "8 or more", value: "8 or more" },
   ]);
   const [alcoholList] = useState([
      { title: "I dont drink alcohol", value: "I dont drink alcohol" },
      { title: "1 - 3", value: "1 - 3" },
      { title: "4 - 8", value: "4 - 8" },
      { title: "8 - 11", value: "8 - 11" },
      { title: "12 - 14", value: "12 - 14" },
      { title: "15 or more", value: "15 or more" },
   ]);
   const [genderList] = useState([
      { title: "Female", value: "Female" },
      { title: "Male", value: "Male" },
      { title: "Transgender", value: "Transgender" },
      { title: "Let me type...", value: "Let me type..." },
      { title: "I prefer not to say", value: "I prefer not to say" },
   ]);
   const [activityList] = useState([
      { title: "I walk less than 30 mins per day", value: "I walk less than 30 mins per day" },
      { title: "I walk one hour per day", value: "I walk one hour per day" },
      { title: "I train twice a week", value: "I train twice a week" },
      { title: "I train 3 times a week", value: "I train 3 times a week" },
      { title: "I train more than 3 times a week", value: "I train more than 3 times a week" },
   ]);
   const [ageList] = useState([
      { title: "18 or younger", value: "18 or younger" },
      { title: "19 - 21", value: "19 - 21" },
      { title: "22 - 29", value: "22 - 29" },
      { title: "30 - 39", value: "30 - 39" },
      { title: "40 - 49", value: "40 - 49" },
      { title: "50 - 59", value: "50 - 59" },
      { title: "60 or over", value: "60 or over" },
   ]);
   const [historyList, setHistoryList] = useState([
      "Diabetes",
      "Hypertension",
      "Heart Disease",
      "Blood Clots",
      "Osteoporosis",
      "Lupus",
      "Cholesterol",
      "Lyme disease",
      "Asthma",
      "Hyperthyroidism",
      "Hypothyroidism",
      "Acid Reflux",
   ]);
   const [medicationList, setMedicationList] = useState([
      "Atorvastatin",
      "Lisinopril",
      "Ventolin",
      "Gabapentin",
      "Amlodipine Besylate",
      "Proair",
      "Omeprazole",
      "Ondansetron",
      "Levothyroxine",
      "Basaglar",
      "Metoprolol",
   ]);
   const [supplementsList, setSupplementsList] = useState([
      "Vitamin D (D2 & D3)",
      "Magnesium",
      "Fish Oil/Omega 3",
      "CoQ10",
      "Multivitamin",
      "Probiotic",
      "Zinc",
      "Curcumin/Turmeric",
      "B-12",
      "B-Complex",
      "Calcium",
      "Melatonin",
      "Vitamin K",
      "Vitamin C (including Rose Hips)",
   ]);
   const [allergyList, setAllergyList] = useState(["Wheat", "Milk", "Sesame", "Eggs", "Soybeans", "Tree Nuts", "Peanuts", "Fish", "Shellfish"]);
   const [dietList, setDietList] = useState([
      "Intermittent Fasting",
      "Paleo",
      "Mediterrean",
      "Ketogenic",
      "Veganism",
      "Sirtfood",
      "Dukan",
      "HCG",
      "Zone",
      "Low-Carb",
      "Atkins",
      "Ultra-Low-Fat",
   ]);
   const [foodList, setFoodList] = useState([
      "Beets",
      "Olives",
      "Cilantro",
      "Mushrooms",
      "Tomatoes",
      "Eggplant",
      "Bitter Gourd",
      "Durian",
      "Circus Peanuts",
      "Bologna",
      "Liver",
      "Ginger",
      "Tuna",
      "Cantaloupe",
      "Pickles",
   ]);
   const [goalsList, setGoalsList] = useState([
      "Weight loss",
      "Weight gain",
      "Blood glucose control",
      "Improved nutritional intake",
      "Improved mood",
      "Reduced blood pressure",
      "Symptom management",
   ]);
   const [alcohol, setAlcohol] = useState(alcoholList[0].value);
   const [gender, setGender] = useState(genderList[0].value);
   const [age, setAge] = useState(ageList[0].value);
   const [sleep, setSleep] = useState(sleepList[0].value);
   const [smoke, setSmoke] = useState(smokeList[0].value);
   const [activity, setActivity] = useState(activityList[0].value);
   const [medicationTab, setMedicationTab] = React.useState(true);
   const [typicalDiet, setTypicalDiet] = useState("");

   const [suggestedConditions, setSuggestedConditions] = useState({
      conditions: [
         { type: "Cancer", label: "Cancer", checked: false },
         { type: "Diabetes (Types 2)", label: "Diabetes (Types 2)", checked: false },
         { type: "Gut health", label: "Gut health", checked: false },
         { type: "Gestational Diabetes", label: "Gestational Diabetes", checked: false },
         { type: "Heart disease", label: "Heart disease", checked: false },
         { type: "IBS", label: "IBS", checked: false },
         { type: "Weight management", label: "Weight management", checked: false },
         { type: "Other", label: "Other", checked: false },
      ],
      otherConditions: "",
   });

   const currentUser = userState?.user;
   const moveTo = useCallback((path) => history.push(path));

   const handleConditionTypeChange = (event) => {
      const conditions = [...suggestedConditions.conditions];
      let newState = conditions.map((item) => {
         if (item.type === event.target.name) {
            return { ...item, checked: event.target.checked };
         }
         return item;
      });
      setSuggestedConditions({ ...suggestedConditions, conditions: newState });
   };

   const addCondition = () => {
      if (values.length > 0) {
         // let newAdded = condition.toLowerCase().split(",");
         if (stage === 4) {
            setHistoryList([...historyList, ...values]);
            setHistorySelected([...historySelected, ...values]);
         } else if (stage === 5) {
            if (medicationTab) {
               setMedicationList([...medicationList, ...values]);
               setMedicationSelected([...medicationSelected, ...values]);
            } else {
               setSupplementsList([...supplementsList, ...values]);
               setSupplementsSelected([...supplementsSelected, ...values]);
            }
         } else if (stage === 10) {
            setAllergyList([...allergyList, ...values]);
            setAllergySelected([...allergySelected, ...values]);
         } else if (stage === 11) {
            setDietList([...dietList, ...values]);
            setDietSelected([...dietSelected, ...values]);
         } else if (stage === 12) {
            setFoodList([...foodList, ...values]);
            setFoodSelected([...foodSelected, ...values]);
         } else if (stage === 14) {
            setGoalsSelected([...goalsSelected, ...values]);
            setGoalsList([...goalsList, ...values]);
         }
         // setCondition("");
         setValues([]);
      }
      setAdd(false);
   };
   const handleSaveCondition = () => {
      if (condition) {
         // Trim the input value and split it by commas
         const newValues = condition.toLowerCase().trim().split(",");
         // Filter out empty strings and add new values to the existing list
         setValues((prevValues) => [...prevValues, ...newValues.map((val) => val.trim()).filter((val) => val !== "")]);
         // Clear the input field
         setCondition("");
      }
   };
   const handleDelete = (selected) => {
      setValues((values) => values.filter((value) => value !== selected));
   };

   const submit = async () => {
      setLoading(true);
      let payload = {
         gender,
         age,
         medical_history: historySelected,
         medications: medicationSelected,
         supplements: supplementsSelected,
         height: height + heightType,
         weight: weight + weightType,
         experienced_weight_changes: weightChange,
         sleep,
         smoke,
         alcohol,
         activity,
         typicalDiet,
         allergies: allergySelected,
         previous_diet: dietSelected,
         disliked_foods: foodSelected,
         health_goals: goalsSelected,
         other_conditions: suggestedConditions.otherConditions,
         conditions: suggestedConditions.conditions,
      };
      try {
         await addQuizData(userState?.user?.uid, payload);
         const user = await getUserData(userState?.user?.uid);
         dispatch(setUser(user));
         setLoading(false);
         if (!editing) {
            setStage(16);
         } else {
            history.goBack();
         }
      } catch (error) {
         console.log(error);
         dispatch(toast({ message: error.message }));
         setLoading(false);
      }
   };

   useEffect(() => {
      if (location.pathname === "/track") {
         if (userState?.user?.quizData) {
            moveTo("/calendar");
         }
      } else {
         editing === "isEditing" || "redo" ? setStage(Number(currentStage)) : setStage(1);
         setDisplay((display) => ({ ...display, header: false }));
         let data = userState?.user?.quizData;
         if (editing === "isEditing" && data) {
            data?.age && setAge(data.age);
            data?.alcohol && setAlcohol(data.alcohol);
            data?.experienced_weight_changes && setWeightChange(data.experienced_weight_changes);
            data?.gender && setGender(data.gender);
            data?.height && setHeight(data.height.slice(0, -2));
            data?.height && setHeightType(data.height.slice(-2));
            if (data?.disliked_foods) {
               setFoodSelected(data.disliked_foods);
               const combinedArray = [
                  ...new Set([...foodList.map((str) => str.toLowerCase()), ...data.disliked_foods.map((str) => str.toLowerCase())]),
               ];
               setFoodList(combinedArray);
            }
            if (data?.health_goals) {
               setGoalsSelected(data.health_goals);
               const combinedArray = [
                  ...new Set([...goalsList.map((str) => str.toLowerCase()), ...data.health_goals.map((str) => str.toLowerCase())]),
               ];
               setGoalsList(combinedArray);
            }

            if (data?.allergies) {
               setAllergySelected(data.allergies);
               const combinedArray = [
                  ...new Set([...allergyList.map((str) => str.toLowerCase()), ...data.allergies.map((str) => str.toLowerCase())]),
               ];
               setAllergyList(combinedArray);
            }
            if (data?.medical_history) {
               setHistorySelected(data.medical_history);
               const combinedArray = [
                  ...new Set([...historyList.map((str) => str.toLowerCase()), ...data.medical_history.map((str) => str.toLowerCase())]),
               ];
               setHistoryList(combinedArray);
            }

            if (data?.medications) {
               setMedicationSelected(data.medications);
               const combinedArray = [
                  ...new Set([...medicationList.map((str) => str.toLowerCase()), ...data.medications.map((str) => str.toLowerCase())]),
               ];
               setMedicationList(combinedArray);
            }
            if (data?.supplements) {
               setSupplementsSelected(data.supplements);
               const combinedArray = [
                  ...new Set([...supplementsList.map((str) => str.toLowerCase()), ...data.supplements.map((str) => str.toLowerCase())]),
               ];
               setSupplementsList(combinedArray);
            }
            if (data?.previous_diet) {
               setDietSelected(data.previous_diet);
               const combinedArray = [
                  ...new Set([...dietList.map((str) => str.toLowerCase()), ...data.previous_diet.map((str) => str.toLowerCase())]),
               ];
               setDietList(combinedArray);
            }
            data?.activity && setActivity(data.activity);
            data?.typicalDiet && setTypicalDiet(data.typicalDiet);
            data?.sleep && setSleep(data.sleep);
            data?.smoke && setSmoke(data.smoke);
            data?.weight && setWeightType(data.weight.slice(-2));
            data?.weight && setWeight(data.weight.slice(0, -2));
            if (data.previous_diet.length > 0) {
               setDiet("Yes");
            }
            data?.otherConditions && setSuggestedConditions({ ...suggestedConditions, otherConditions: data.otherConditions });
            data?.conditions && setSuggestedConditions({ ...suggestedConditions, conditions: data.conditions });
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      // Scroll to the top of the quiz component
      quizRef.current && quizRef.current.scrollTo(0, 0);
   }, [stage]);

   console.log(editing, "edit");

   return (
      <Layout header={display.header} spacer={false}>
         <div className={styles.page} ref={quizRef}>
            {!display.header && (
               <div className={styles.header}>
                  <div className={styles.space}></div>
                  <div className={styles.back}>
                     <img
                        src={cancel}
                        alt='cancel'
                        onClick={() =>
                           location.pathname === "/track"
                              ? [setStage(0), setDisplay((display) => ({ ...display, header: true }))]
                              : editing === "isEditing" && stage > 0 && stage < 16
                              ? history.goBack()
                              : moveTo("/")
                        }
                     />
                     <div>{stage < 16 ? "Intake Form" : "Intake form sent!"}</div>
                     {stage < 16 && <div>{stage}/15</div>}
                     {stage >= 16 && <div></div>}
                  </div>
                  <div className={styles.down}></div>
               </div>
            )}
            <div className={styles.main}>
               {add ? (
                  <div className={styles.others}>
                     <div className={styles.title}>Add Other {type}</div>
                     <div className={styles.sub}>Write everything else you want to add, separating each thing by a comma.</div>
                     {values.length > 0 && (
                        <div className={styles.otherConditionsContainer}>
                           {values.map((textValue) => {
                              return (
                                 <Chip
                                    // clickable
                                    key={textValue}
                                    label={textValue}
                                    className={styles.chip}
                                    onDelete={() => handleDelete(textValue)}
                                    deleteIcon={<CancelIcon />}
                                 />
                              );
                           })}
                        </div>
                     )}
                     <TextField
                        fullWidth
                        multiline
                        label='Write your condition'
                        placeholder='Write your condition'
                        variant='standard'
                        InputProps={{
                           disableUnderline: true,
                        }}
                        name='url'
                        className={styles.addInput}
                        value={condition}
                        onChange={(e) => setCondition(e.target.value)}
                     />
                     <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                        {/* <button className={styles.prev} onClick={() => setStage((prev) => (prev - 1))}> <img src={strokeGood} alt="prev" /> </button> */}
                        <button style={{ width: "152px" }} className={styles.nextCenter} onClick={condition ? handleSaveCondition : addCondition}>
                           {condition ? "Save Values" : "Done"}
                        </button>
                     </div>
                  </div>
               ) : (
                  <>
                     {stage === 0 && currentUser && currentUser?.isPremuimUser && (
                        <>
                           <div className={styles.initialStage}>
                              <div className={styles.imgCon}>
                                 <img src={paulina} alt='woman' className={styles.img} />
                              </div>
                              <div className={styles.content}>
                                 <div className={styles.text}>Health Quiz</div>
                                 <div className={styles.subText}>
                                    Your dietitian needs the following information for your first call - it takes just 3 minutes to complete. It helps
                                    us tailor our care just for you and ensures we're all set for our first call.
                                 </div>
                              </div>
                              <button
                                 style={{ width: "232px" }}
                                 className={styles.startButton}
                                 onClick={() => [setStage((prev) => prev + 1), setDisplay((display) => ({ ...display, header: false }))]}
                              >
                                 {" "}
                                 Start the quiz{" "}
                              </button>
                           </div>
                        </>
                     )}

                     {stage === 0 && currentUser && !currentUser?.isPremuimUser && (
                        <>
                           <div className={styles.initialStage}>
                              <div className={styles.imgCon}>
                                 <img src={paulinaNPU} alt='woman' className={styles.img} />
                              </div>

                              <div>
                                 <div className={styles.text}>Food fit for you – easing conditions naturally</div>
                                 <div className={styles.subText}>
                                    Speak to a dietitian, get meal plans, track progress and shop healthy ingredients. Start easing your chronic
                                    symptoms or conditions like pregnancy, diabetes and prediabetes, hypertension, celiac & heart disease, chronic
                                    kidney failure.
                                 </div>
                                 <Divider variant />
                              </div>

                              <div className={styles.priceContainer}>
                                 <p className={styles.premuimText} style={{ textAlign: "start" }}>
                                    Premium Offers
                                 </p>
                                 <div className={styles.pricing}>
                                    {PRICING.map((premium) => (
                                       <Pricing
                                          key={premium.id}
                                          icon={premium.icon}
                                          title={premium.title}
                                          price={premium.price}
                                          description={premium.description}
                                          background={premium.background}
                                          offers={premium.offers}
                                          btnBg={premium.button}
                                          link={premium.link}
                                       />
                                    ))}
                                 </div>
                              </div>
                           </div>
                        </>
                     )}

                     {stage === 1 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Conditions</div>
                           <FormLabel component='legend' className={styles.sub}>
                              What can we help you with?
                           </FormLabel>
                           <FormGroup>
                              {suggestedConditions.conditions.map((type) => {
                                 return (
                                    <FormControlLabel
                                       control={
                                          <Checkbox checked={type.checked} onChange={handleConditionTypeChange} name={type.label} color='primary' />
                                       }
                                       label={type.label}
                                       key={type.type}
                                    />
                                 );
                              })}
                           </FormGroup>
                           {suggestedConditions.conditions[suggestedConditions.conditions.length - 1]["checked"] && (
                              <TextField
                                 id='outlined-basic'
                                 label='If others, list here'
                                 type='text'
                                 value={suggestedConditions?.otherConditions}
                                 onChange={(e) => {
                                    setSuggestedConditions({ ...suggestedConditions, otherConditions: e.target.value });
                                 }}
                                 variant='outlined'
                                 style={{ width: "100%" }}
                                 multiline
                                 maxRows={4}
                              />
                           )}
                        </div>
                     )}

                     {stage === 2 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Biological sex</div>
                           <div className={styles.sub}>
                              We ask this just because there are several aspects that work slight differently between genders. Feel free to not
                              answer.
                           </div>
                           <CustomMultiLineSelect
                              select={(selected) => setGender(selected)}
                              active={gender ? gender : genderList[0].value}
                              data={genderList}
                           />
                        </div>
                     )}

                     {stage === 3 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Age</div>
                           <div className={styles.sub}>
                              We need your age to establish how quick is your metabolism, and other body considerations.
                           </div>
                           <CustomMultiLineSelect select={(selected) => setAge(selected)} active={age ? age : ageList[0].value} data={ageList} />
                        </div>
                     )}

                     {stage === 4 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Medical history</div>
                           <div className={styles.sub}>
                              Highlight your past medical history, including any conditions you are currently being investigated for.
                           </div>
                           {/* <CustomMultiLineSelect select={(selected) => setAge(selected)} active={age ? age : ageList[0].value} data={ageList} /> */}
                           {historyList.map((value) => {
                              return (
                                 <Chip
                                    clickable
                                    key={value}
                                    label={value}
                                    className={historySelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                    onClick={() =>
                                       historySelected.includes(value.toLowerCase())
                                          ? setHistorySelected(historySelected.filter((e) => e !== value.toLowerCase()))
                                          : setHistorySelected((prev) => [...prev, value.toLowerCase()])
                                    }
                                 />
                              );
                           })}
                           <Chip clickable label='Add Others...' className={styles.addChip} onClick={() => [setAdd(true), setType("Conditions")]} />
                        </div>
                     )}

                     {stage === 5 && (
                        <>
                           <div className={styles.pad}>
                              <div className={styles.title}>Medications</div>
                              <div className={styles.sub}>Add current medication, including any over the counter nutritional supplements.</div>
                           </div>

                           {/* <CustomMultiLineSelect select={(selected) => setAge(selected)} active={age ? age : ageList[0].value} data={ageList} /> */}
                           <div className={styles.tabHead}>
                              <div className={medicationTab ? styles.activeTab : styles.nonActiveTab} onClick={() => setMedicationTab(true)}>
                                 Medications
                              </div>
                              <div className={!medicationTab ? styles.activeTab : styles.nonActiveTab} onClick={() => setMedicationTab(false)}>
                                 Supplements
                              </div>
                           </div>

                           <div className={styles.pad}>
                              {medicationTab && (
                                 <>
                                    {medicationList.map((value) => {
                                       return (
                                          <Chip
                                             key={value}
                                             clickable
                                             label={value}
                                             className={medicationSelected?.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                             onClick={() =>
                                                medicationSelected?.includes(value.toLowerCase())
                                                   ? setMedicationSelected(medicationSelected?.filter((e) => e !== value.toLowerCase()))
                                                   : setMedicationSelected((prev) => [...prev, value.toLowerCase()])
                                             }
                                          />
                                       );
                                    })}
                                    <Chip
                                       clickable
                                       label='Add Others...'
                                       className={styles.addChip}
                                       onClick={() => [setAdd(true), setType("Medications")]}
                                    />
                                 </>
                              )}
                              {!medicationTab && (
                                 <>
                                    {supplementsList.map((value) => {
                                       return (
                                          <Chip
                                             key={value}
                                             clickable
                                             label={value}
                                             className={supplementsSelected?.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                             onClick={() =>
                                                supplementsSelected?.includes(value.toLowerCase())
                                                   ? setSupplementsSelected(supplementsSelected?.filter((e) => e !== value.toLowerCase()))
                                                   : setSupplementsSelected((prev) => [...prev, value.toLowerCase()])
                                             }
                                          />
                                       );
                                    })}
                                    <Chip
                                       clickable
                                       label='Add Others...'
                                       className={styles.addChip}
                                       onClick={() => [setAdd(true), setType("Supplements")]}
                                    />
                                 </>
                              )}
                           </div>
                        </>
                     )}

                     {stage === 6 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Height and weight</div>
                           <div className={styles.sub}>Share your current height, weight, and any recent weight changes.</div>
                           <div className={styles.bt}>
                              <TextField
                                 id='outlined-basic'
                                 label='Height'
                                 type='text'
                                 value={height}
                                 onChange={(e) => setHeight(e.target.value)}
                                 variant='outlined'
                                 style={{ width: "100%" }}
                              />
                              <div className={styles.selector}>
                                 <div onClick={() => setHeightType('ft"in')} className={heightType === 'ft"in' ? styles.good : styles.bad}>
                                    ft"in
                                 </div>
                                 <div onClick={() => setHeightType("cm")} className={heightType === "cm" ? styles.good : styles.bad}>
                                    cm
                                 </div>
                              </div>
                           </div>

                           <div className={styles.bt}>
                              <TextField
                                 id='outlined-basic'
                                 label='Weight'
                                 type='text'
                                 value={weight}
                                 onChange={(e) => setWeight(e.target.value)}
                                 variant='outlined'
                                 style={{ width: "100%" }}
                              />
                              <div className={styles.selector}>
                                 <div onClick={() => setWeightType("lb")} className={weightType === "lb" ? styles.good : styles.bad}>
                                    lb
                                 </div>
                                 <div onClick={() => setWeightType("kg")} className={weightType === "kg" ? styles.good : styles.bad}>
                                    kg
                                 </div>
                              </div>
                           </div>

                           <div className={styles.check}>
                              <Checkbox color='default' checked={weightChange} onChange={() => setWeightChange(!weightChange)} />
                              <span>I’ve experienced different weight changes in the last 6 months</span>
                           </div>
                        </div>
                     )}

                     {stage === 7 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Sleep</div>
                           <div className={styles.sub}>Describe the average hours of sleep per night.</div>
                           <CustomMultiLineSelect
                              select={(selected) => setSleep(selected)}
                              active={sleep ? sleep : sleepList[0].value}
                              data={sleepList}
                           />
                        </div>
                     )}

                     {stage === 8 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Smoke</div>
                           <div className={styles.sub}>If you smoke, specify how much per day.</div>
                           <CustomMultiLineSelect
                              select={(selected) => setSmoke(selected)}
                              active={smoke ? smoke : smokeList[0].value}
                              data={smokeList}
                           />
                        </div>
                     )}

                     {stage === 9 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Alcohol</div>
                           <div className={styles.sub}>
                              Do you drink alcohol? If so, how many units per week? Consider that a glass of wine is 1.5 unit, a can of beer is 2
                              units, a pint of beer is 3 units, and a bottle of wine is 9 units.
                              <span className={styles.click}>Check for more examples about unit</span>
                           </div>
                           <CustomMultiLineSelect
                              select={(selected) => setAlcohol(selected)}
                              active={alcohol ? alcohol : alcoholList[0].value}
                              data={alcoholList}
                           />
                        </div>
                     )}

                     {stage === 10 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Allergies</div>
                           <div className={styles.sub}>Add all the allergies you might have.</div>
                           {allergyList.map((value) => {
                              return (
                                 <Chip
                                    key={value}
                                    clickable
                                    label={value}
                                    className={allergySelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                    onClick={() =>
                                       allergySelected.includes(value.toLowerCase())
                                          ? setAllergySelected(allergySelected.filter((e) => e !== value.toLowerCase()))
                                          : setAllergySelected((prev) => [...prev, value.toLowerCase()])
                                    }
                                 />
                              );
                           })}
                           <Chip clickable label='Add Others...' className={styles.addChip} onClick={() => [setAdd(true), setType("Allergies")]} />
                        </div>
                     )}

                     {stage === 11 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Previous diets</div>
                           <div className={styles.sub}>Tell us about your history with diets.</div>
                           <div>
                              <div className={styles.question}>Have you tried diets in the past?</div>
                              {["Yes", "No"].map((value) => {
                                 return (
                                    <Chip
                                       key={value}
                                       clickable
                                       label={value}
                                       className={diet === value ? styles.chipSelected : styles.chip}
                                       onClick={() => setDiet(diet === "Yes" ? "No" : "Yes")}
                                    />
                                 );
                              })}
                           </div>
                           {diet === "Yes" && (
                              <>
                                 <div className={styles.question}>Specify which ones</div>
                                 {dietList.map((value) => {
                                    return (
                                       <Chip
                                          key={value}
                                          clickable
                                          label={value}
                                          className={dietSelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                          onClick={() =>
                                             dietSelected.includes(value.toLowerCase())
                                                ? setDietSelected(dietSelected.filter((e) => e !== value.toLowerCase()))
                                                : setDietSelected((prev) => [...prev, value.toLowerCase()])
                                          }
                                       />
                                    );
                                 })}
                                 <Chip clickable label='Add Others...' className={styles.addChip} onClick={() => [setAdd(true), setType("Diets")]} />
                              </>
                           )}
                        </div>
                     )}

                     {stage === 12 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Dislike food</div>
                           <div className={styles.sub}>What food do you avoid?</div>
                           {foodList.map((value) => {
                              return (
                                 <Chip
                                    key={value}
                                    clickable
                                    label={value}
                                    className={foodSelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                    onClick={() =>
                                       foodSelected.includes(value.toLowerCase())
                                          ? setFoodSelected(foodSelected.filter((e) => e !== value.toLowerCase()))
                                          : setFoodSelected((prev) => [...prev, value.toLowerCase()])
                                    }
                                 />
                              );
                           })}
                           <Chip
                              clickable
                              label='Add Others...'
                              className={styles.addChip}
                              onClick={() => [setAdd(true), setType("Foods to Avoid")]}
                           />
                        </div>
                     )}

                     {stage === 13 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Physical Activities</div>
                           <div className={styles.sub}>How would you describe your weekly movement?</div>
                           <CustomMultiLineSelect
                              select={(selected) => setActivity(selected)}
                              active={activity ? activity : activityList[0].value}
                              data={activityList}
                           />
                        </div>
                     )}

                     {stage === 14 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Health goals</div>
                           <div className={styles.sub}>What is the outcome you are looking for with this diet?</div>
                           {goalsList.map((value) => {
                              return (
                                 <Chip
                                    key={value}
                                    clickable
                                    label={value}
                                    className={goalsSelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                    onClick={() =>
                                       goalsSelected.includes(value.toLowerCase())
                                          ? setGoalsSelected(goalsSelected.filter((e) => e !== value.toLowerCase()))
                                          : setGoalsSelected((prev) => [...prev, value.toLowerCase()])
                                    }
                                 />
                              );
                           })}
                           <Chip clickable label='Add Others...' className={styles.addChip} onClick={() => [setAdd(true), setType("Health Goals")]} />
                        </div>
                     )}

                     {stage === 15 && (
                        <div className={styles.pad}>
                           <div className={styles.title}>Typical diet</div>
                           <div className={styles.sub}>
                              What do you eat in a typical day? List your breakfast, lunch, dinner, snack and drinks. The more information the better!
                           </div>
                           <TextField
                              fullWidth
                              multiline
                              label='Write your typical diet'
                              placeholder='Write your typical diet'
                              variant='standard'
                              InputProps={{
                                 disableUnderline: true,
                              }}
                              name='url'
                              className={styles.addInput}
                              value={typicalDiet}
                              onChange={(e) => setTypicalDiet(e.target.value)}
                           />
                        </div>
                     )}

                     {stage === 16 && (
                        // <div className={styles.sessionContainer}>
                        //    <img src={booksession} alt='woman' className={styles.img} />
                        //    <div className={styles.textBox}>
                        //       <div className={styles.text}>Thank you! Now let's book your first session</div>
                        //       <div className={styles.subText}>You've completed everything that's needed from your Registered Dietitian</div>
                        //       <div className={styles.expect}>What to expect</div>
                        //    </div>
                        //    <Divider variant />
                        //    <div className={styles.offerContainer}>
                        //       <div className={styles.offer}>
                        //          <img src={tick} alt={"tick"} className={styles.offerIcon}></img>
                        //          <p className={styles.offerText}>Personalised nutrition counselling that makes sense to you</p>
                        //       </div>
                        //       <div className={styles.offer}>
                        //          <img src={tick} alt={"tick"} className={styles.offerIcon}></img>
                        //          <p className={styles.offerText}>Safe space to ask any question at any point along your health journey</p>
                        //       </div>
                        //       <div className={styles.offer}>
                        //          <img src={tick} alt={"tick"} className={styles.offerIcon}></img>
                        //          <p className={styles.offerText}>Actionable insights and tips</p>
                        //       </div>

                        //       <div className={styles.btnCtn}>
                        //          <button className={styles.btn} onClick={() => history.push("/dietitian")}>
                        //             <a href='https://calendly.com/stove/stove-monthly-meeting' target='_blank' rel='noopener noreferrer'>
                        //                <span>Book</span>
                        //             </a>
                        //          </button>
                        //       </div>
                        //    </div>
                        // </div>
                        <div className={styles.alldone}>
                           <div className={styles.imgBox}>
                              <img src={endIMG} alt='end' />
                              <div>
                                 <div className={styles.text}>All done!</div>
                                 <div className={styles.subText}>
                                    Thank you! Your dietitian will now review all your information. While you wait, explore all the tools and features
                                    the Stove app has to offer.
                                 </div>
                              </div>

                              <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                                 <button
                                    style={{ width: "232px" }}
                                    className={styles.nextCenter}
                                    onClick={() => {
                                       moveTo("/getStarted");
                                    }}
                                 >
                                    Explore
                                 </button>
                                 <div
                                    className={styles.more}
                                    onClick={() => {
                                       moveTo("/");
                                    }}
                                 >
                                    Skip{" >"}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}

                     {stage > 0 && stage < 16 && editing !== "isEditing" && (
                        <div className={styles.bottomButton}>
                           {stage > 1 && (
                              <button className={styles.prev} onClick={() => setStage((prev) => prev - 1)}>
                                 {" "}
                                 <img src={strokeGood} alt='prev' />{" "}
                              </button>
                           )}
                           {stage === 1 && (
                              <button className={styles.nextCenter} onClick={() => setStage((prev) => prev + 1)}>
                                 {loading ? <CircularProgress size={20} disableShrink color='inherit' /> : <>{stage === 15 ? "Send" : "Next"} </>}
                              </button>
                           )}
                           {stage !== 1 && (
                              <button
                                 className={styles.next}
                                 onClick={() => {
                                    if (stage > 1 && stage < 16) {
                                       if (stage === 15) {
                                          submit();
                                       } else {
                                          setStage((prev) => prev + 1);
                                       }
                                    }
                                 }}
                              >
                                 {loading ? <CircularProgress size={20} disableShrink color='inherit' /> : <>{stage === 15 ? "Done" : "Next"} </>}
                              </button>
                           )}
                        </div>
                     )}

                     {editing === "isEditing" && stage > 0 && stage < 16 && (
                        <div className={styles.bottomButton}>
                           <button className={styles.nextCenter} onClick={() => submit()}>
                              {loading ? <CircularProgress size={20} disableShrink color='inherit' /> : <span>Update</span>}
                           </button>
                        </div>
                     )}
                  </>
               )}
            </div>
            <div style={{ height: "400px" }}></div>
         </div>
      </Layout>
   );
};

export default Track;
