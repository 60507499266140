import React from "react";
import TextField from '@material-ui/core/TextField';
import styles from "./CustomInput.module.scss";


const CustomInput = (props) => {
    const { placeholder, label, ...otherProps } = props;
    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            label={label ? label : placeholder}
            className={styles.input}
            InputLabelProps={{
                className: styles.label
            }}
            InputProps={{
                className: styles.input,
                classes: {
                    underline: styles.underLine
                }
            }}
            variant="standard"
            {...otherProps}
        />
    )
}


export default CustomInput