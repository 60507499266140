import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import dayjs from "dayjs";
import * as isToday from "dayjs/plugin/isToday";
import { CustomDatePicker } from "../../components/DatePicker/DatePicker";
import { Time } from "./Time";
import add from "../../assets/add-btn.svg";
import water from "../../assets/water-btn.svg";
import styles from "./Calendar.module.scss";
import { Modal } from "./CustomModal/Modal";
import { WaterModal } from "./CustomModal/WaterModal";
import { WeightModal } from "./CustomModal/WeightModal";
import { Symptoms } from "./CustomModal/Symptoms";
import { EventsForm } from "./EventsForm/EventsForm";
import BookingModal from "./BookingModal/BookingModal";
import { openEvents, closeEvents, toast, setUser } from "../../state";
import { getUserData } from "../../services/firebase";

import { useFoodDairyStatusMutation } from "../../services/mutations/index";
import { format, lastDayOfMonth, subDays, subMonths } from "date-fns";
import DayJsUtils from "@date-io/dayjs";

import Layout from "../../layout";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { InputAdornment } from "@material-ui/core";
import CALENDER_ICON from "../../assets/icons/calendarAR.png";
import cu from "../../assets/icons/cu.svg";
import cu2 from "../../assets/icons/cu2.svg";
import { lowerFirst } from "lodash";
import { useHistory, useParams } from "react-router";
import Head from "../MealPlan/header/header";

dayjs.extend(isToday);
const today = dayjs();

const Calendar = () => {
  const dispatch = useDispatch();
  const calendarState = useSelector((state) => state.calendar, shallowEqual);
  const { user: currentUser } = useSelector(
    (state) => state.user,
    shallowEqual
  );
  const [selectDate, setSelectDate] = useState(today);
  const [openWaterModal, setWaterModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState(today);
  const [selectedDate, setSelectedDate] = useState(today);
  const [currentDayNumber, setCurrentDayNumber] = useState(1);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [foodDiaryStatus, setFoodDairyStatus] = useState({});
  const [isFoodDiaryCompleted, setIsFoodDiaryCompleted] = useState(false);
  const [showDairyDays, setShowDairyDays] = useState(true);
  const [foodEvents, setFoodEvents] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(+format(new Date(), "d"));

  const currentDate = selectDate.format("YYYY-MM-DD");
  const userEvents = currentUser.events || [];
  const userState = useSelector((state) => state.user, shallowEqual);
  const eventsData = userState?.user?.events ? userState?.user?.events : [];
  const [user, setCurrentUser] = useState(userState?.user);

  const { userId } = useParams();
  const {
    isModalOpen,
    currentEventType,
    showModal,
    showWeightModal,
    showSymptomsModal,
  } = calendarState.eventStates;

  const divRef = useRef(null);
  const history = useHistory();
  // mutations
  const { updateFoodDairyStatusData } = useFoodDairyStatusMutation();

  const handleAdd = () => {
    dispatch(openEvents({ ...calendarState.eventStates, isModalOpen: true }));
  };
  const handleClose = () => {
    dispatch(closeEvents());
  };

  const handleOpenWaterModal = () => {
    setWaterModal(true);
  };

  const checkFoodDairyDailyStatus = () => {
    try {
      if (userEvents) {
        let statusDetails = {};
        for (let index = 2; index >= 0; index--) {
          const date = dayjs().subtract(index, "day").format("YYYY-MM-DD");
          const events =
            userEvents.filter((event) => event.date === date && event.food) ||
            [];
          const status = {
            breakfast: false,
            lunch: false,
            dinner: false,
          };

          events.length > 0 &&
            events.map((event) => {
              if (
                event.tag.toLowerCase() === "breakfast" &&
                event.date === date
              ) {
                status.breakfast = true;
              }
              if (event.tag.toLowerCase() === "lunch" && event.date === date) {
                status.lunch = true;
              }
              if (event.tag.toLowerCase() === "dinner" && event.date === date) {
                status.dinner = true;
              }
            });

          if (status.breakfast && status.lunch && status.dinner) {
            statusDetails[3 - index] = { complete: true, day: date };
          } else {
            statusDetails[3 - index] = { complete: false, day: date };
          }
        }
        setFoodDairyStatus(statusDetails);
      }
    } catch (error) {
      console.log(error, "checkFoodDairyDailyStatus");
    }
  };

  const setFoodDairyDay = () => {
    // all days are complete
    const days = Object.keys(foodDiaryStatus);
    if (days.length > 0) {
      const isAllDaysComplete =
        foodDiaryStatus[1].complete &&
        foodDiaryStatus[2].complete &&
        foodDiaryStatus[3].complete;
      const isBeforeDayOne = dayjs(currentDate).isBefore(
        foodDiaryStatus[1].day,
        "d"
      );
      const isAfterDayThree = dayjs(currentDate).isAfter(
        foodDiaryStatus[3].day,
        "d"
      );
      if (isAllDaysComplete) {
        setIsFoodDiaryCompleted(true);
        setOpenBookingModal(true);
      }
      // if day 1 and day 2 is complete setCurrentdayNumber to 3
      if (
        foodDiaryStatus[1].complete &&
        foodDiaryStatus[2].complete &&
        !foodDiaryStatus[3].complete &&
        currentDate === today.format("YYYY-MM-DD")
      ) {
        setCurrentDayNumber(3);
      }
      // if day 1 is incomplete and day 2 is complete and isToday is true setCurrentdayNumber to day 2
      if (
        !foodDiaryStatus[1].complete &&
        foodDiaryStatus[2].complete &&
        currentDate === today.format("YYYY-MM-DD")
      ) {
        setCurrentDayNumber(2);
      }
      // if day 1 is complete and day 2 is incomplete today === day3 setCurrebtdayNumber to 1
      if (
        foodDiaryStatus[1].complete &&
        !foodDiaryStatus[2].complete &&
        currentDate === today.format("YYYY-MM-DD")
      ) {
        setCurrentDayNumber(1);
      }
      // if day 1 and 2 is complete and current date == today
      if (
        foodDiaryStatus[1].complete &&
        foodDiaryStatus[2].complete &&
        currentDate === foodDiaryStatus[2].day
      ) {
        setCurrentDayNumber(2);
      }
      if (
        foodDiaryStatus[1].complete &&
        foodDiaryStatus[2].complete &&
        currentDate === foodDiaryStatus[1].day
      ) {
        setCurrentDayNumber(1);
      }
      if (
        !foodDiaryStatus[1].complete &&
        foodDiaryStatus[2].complete &&
        currentDate === foodDiaryStatus[2].day
      ) {
        setCurrentDayNumber(1);
      }

      if (isBeforeDayOne || isAfterDayThree) {
        setShowDairyDays(false);
      } else {
        setShowDairyDays(true);
      }
    }
  };

  const updateFoodDairyCompletionStatus = async () => {
    try {
      if (currentUser && isFoodDiaryCompleted) {
        const payload = { uid: currentUser.uid, status: isFoodDiaryCompleted };
        await updateFoodDairyStatusData(payload);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (userId) {
      const getUser = async () => {
        try {
          const getData = await getUserData(userId);
          setCurrentUser(getData);
        } catch (err) {
          console.log(err);
        }
      };
      getUser();
    } else {
      setCurrentUser(userState?.user);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      setEvents();
    }
    if (currentUser && currentUser?.foodDiaryComplete) {
      setShowDairyDays(false);
    } else {
      setFoodDairyDay();
      isFoodDiaryCompleted && updateFoodDairyCompletionStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, isFoodDiaryCompleted, foodDiaryStatus, currentUser]);

  useEffect(() => {
    checkFoodDairyDailyStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, selectedDay, currentUser?.events?.length]);

  useEffect(() => {
    addMonthDays(selectDate);
    if (userId) {
      setEvents();
      const timer = setTimeout(() => {
        scrollToPosition(selectedDay - 1);
      }, 0);
      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const userData = await getUserData(currentUser.uid);
      dispatch(setUser(userData));
      // ...
    }
    // fetchData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const addMonthDays = (date) => {
    let allDays = [];
    for (
      let i = 0;
      i <
      +format(
        lastDayOfMonth(
          new Date(
            +format(date.$d, "yyyy"),
            +format(date.$d, "MM") - 1,
            +format(date.$d, "dd")
          )
        ),
        "d"
      );
      i++
    ) {
      allDays.push({
        day: i + 1,
        year: format(date.$d, "yyyy"),
        month: format(date.$d, "MM"),
        dayWord: format(
          new Date(format(date.$d, "yyyy"), format(date.$d, "M") - 1, i + 1),
          "EEE"
        ),
        date: new Date(
          format(date.$d, "yyyy"),
          format(date.$d, "M") - 1,
          i + 1
        ),
      });
    }
    setMonthDays(allDays);
  };

  const setEvents = () => {
    let tempValue = [];
    for (let i = 0; i < userEvents.length; i++) {
      if (userEvents[i]?.food) {
        foodEvents.push(userEvents[i].food.items);

        if (userEvents[i]?.date === format(selectDate.$d, "yyyy-MM-dd")) {
          for (let j = 0; j < userEvents[i].food.items.length; j++) {
            tempValue.push(
              userEvents[i].food.items[j].Item.nutriments
                ? userEvents[i].food.items[j].Item.nutriments
                : 0
            );
          }
        }
      }
    }
    setFoodEvents(tempValue);
  };

  const isWhatPercentOf = (x, y) => {
    return Math.round((x / y) * 100);
  };

  const reduceFat = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + +object.fat;
    }, 0);
  };
  const reduceProt = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + +object.proteins;
    }, 0);
  };
  const reduceCab = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + +object.carbohydrates;
    }, 0);
  };

  const reduceCal = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + object["energy-kcal"];
    }, 0);
  };

  function scrollToPosition(itemIndex) {
    const div = divRef.current;
    const item = div.childNodes[itemIndex];
    // div.scrollLeft = scrollLeft;
    div.scrollTo({
      left: item.offsetLeft - (div.offsetLeft + 180),
      behavior: "smooth",
    });
  }

  const handleDateChange = (date) => {
    addMonthDays(date);
    setSelectDate(date);
    setSelectedDate(date);
    setSelectedDay(+format(date.$d, "d"));
    scrollToPosition(+format(date.$d, "d") - 1);
    setEvents();
  };

  const runClick = (date) => {
    setSelectedDay(date.day);
    setSelectDate(dayjs(new Date(date.year, date.month - 1, date.day)));
    scrollToPosition(date.day - 1);
    setEvents();
  };

  return (
    <>
      <Head />
      <Layout header={false}>
         {/* <div style={{height: '18px'}} /> */}
        {!userId && (
          <CustomDatePicker
            selectDate={selectDate}
            setSelectDate={(date) => [
              setSelectDate(date),
              setSelectedDate(date),
              setSelectedDay(+format(date.$d, "d")),
            ]}
          />
        )}

        {/* {!userId && (
          <div className={showDairyDays ? styles.day : styles.hide}>
            <span className={styles.span}>{`Day ${currentDayNumber}`}</span>
          </div>
        )} */}

        {/* monthDays */}
        {userId && (
          <div className={styles.data}>
            <div className={styles.bothof}>
              <div className={styles.date}>
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                  <DatePicker
                    orientation="portrait"
                    format="ddd D MMM"
                    value={selectDate}
                    onChange={handleDateChange}
                    InputProps={{
                      disableUnderline: true,
                      className: dayjs(selectDate).isToday()
                        ? `${styles.today}`
                        : `${styles.input}`,
                      classes: {
                        input: styles.inputWidth,
                      },
                      endAdornment: (
                        <InputAdornment position="start">
                          <img src={CALENDER_ICON} alt="calendar" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Modal
                open={isModalOpen}
                date={selectDate.$d}
                onClose={handleClose}
              />

              <div className={styles.icons}>
                <img
                  src={cu}
                  alt="calendar"
                  onClick={() =>
                    history.push(`/dietitian/patient-details/${userId}/session`)
                  }
                />
                <img
                  src={cu2}
                  alt="calendar"
                  onClick={() => history.push(`/dietitian/weeklist/${userId}`)}
                />
              </div>
            </div>
            <div className={styles.con} ref={divRef}>
              {monthDays.map((content, i) => (
                <div className={styles.allBoth}>
                  <div className={styles.tit}>{content.dayWord}</div>
                  <div
                    onClick={() => runClick(content)}
                    className={
                      selectedDay === content.day
                        ? `${styles.item} ${styles.selected}`
                        : `${styles.item}`
                    }
                  >
                    {content.day}
                  </div>
                </div>
              ))}
            </div>
            {foodEvents && (
              <>
                <div className={styles.fles}>
                  <div className={`${styles.sin} ${styles.bod}`}>
                    <div>Kcal/day</div>
                    <div>
                      <progress
                        value={isWhatPercentOf(
                          reduceCal(foodEvents),
                          user?.nutrients?.calories
                        )}
                        max="100"
                      >
                        {isWhatPercentOf(
                          reduceCal(foodEvents),
                          user?.nutrients?.calories
                        )}
                        %
                      </progress>
                    </div>
                    <div>
                      <b>
                        {user?.nutrients
                          ? Math.round(reduceCal(foodEvents))
                          : 0}
                      </b>
                    </div>
                  </div>

                  <div className={styles.sin}>
                    <div>Carbs</div>
                    <div>
                      <progress
                        value={isWhatPercentOf(
                          reduceCab(foodEvents),
                          user?.nutrients?.carbs
                        )}
                        max="100"
                      >
                        {isWhatPercentOf(
                          reduceCab(foodEvents),
                          user?.nutrients?.carbs
                        )}
                        %
                      </progress>
                    </div>
                    <div>
                      <b>
                        {user?.nutrients
                          ? isWhatPercentOf(
                              reduceCab(foodEvents),
                              user?.nutrients?.carbs
                            )
                          : 0}
                        %
                      </b>
                    </div>
                  </div>
                  <div className={styles.sin}>
                    <div>Protein</div>
                    <div>
                      <progress
                        value={isWhatPercentOf(
                          reduceProt(foodEvents),
                          user?.nutrients?.proteins
                        )}
                        max="100"
                      >
                        {isWhatPercentOf(
                          reduceProt(foodEvents),
                          user?.nutrients?.proteins
                        )}
                        %
                      </progress>
                    </div>
                    <div>
                      <b>
                        {user?.nutrients
                          ? isWhatPercentOf(
                              reduceProt(foodEvents),
                              user?.nutrients?.proteins
                            )
                          : 0}
                        %
                      </b>
                    </div>
                  </div>

                  <div className={styles.sin}>
                    <div>Fat</div>
                    <div>
                      <progress
                        value={isWhatPercentOf(
                          reduceFat(foodEvents),
                          user?.nutrients?.fats
                        )}
                        max="100"
                      >
                        {isWhatPercentOf(
                          reduceFat(foodEvents),
                          user?.nutrients?.fats
                        )}
                        %
                      </progress>
                    </div>
                    <div>
                      <b>
                        {user?.nutrients
                          ? isWhatPercentOf(
                              reduceFat(foodEvents),
                              user?.nutrients?.fats
                            )
                          : 0}
                        %
                      </b>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        <Time
          selectDate={selectDate}
          setSelectedTime={setSelectedTime}
          selectedTime={selectedTime}
        />
        {!userId && (
          <div className={styles.waterAddButton}>
            <img src={water} alt="" onClick={handleOpenWaterModal} />
            <img src={add} alt="" onClick={handleAdd} />
          </div>
        )}
      </Layout>

      <Modal
        open={isModalOpen}
        date={selectDate.$d}
        onClose={() => dispatch(closeEvents())}
      />

      <WeightModal
        onClose={handleClose}
        showWeight={showWeightModal}
        currentEvent={currentEventType}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <Symptoms
        onClose={handleClose}
        showSymptoms={showSymptomsModal}
        currentEvent={currentEventType}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <EventsForm
        show={showModal}
        currentEvent={currentEventType}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        date={selectDate.$d}
      />
      <BookingModal
        open={openBookingModal}
        onClose={() => {
          setOpenBookingModal(false);
          setIsFoodDiaryCompleted(false);
        }}
      />

      <WaterModal
        open={openWaterModal}
        onClose={() => setWaterModal(false)}
        date={selectDate.$d}
        selectedDate={selectedDate}
      />
    </>
  );
};

export default Calendar;
