import React from 'react'
import { useHistory, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import styles from './NavigationHeader.module.scss'

const NavigationHeader = ({
    icon, text 
}) => {
    const history = useHistory();
  return (
    <div className={styles.header}>
    <IconButton size="small" onClick={() => history.goBack()}>
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.00065 12.3379L20 12.3379" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
             d="M11.3691 19.6759L3.99988 12.3379L11.3691 5"
             stroke="#130F26"
             strokeWidth="1.5"
             strokeLinecap="round"
             strokeLinejoin="round"
          />
       </svg>
    </IconButton>
    <div className={styles.headerText}>
        {text}
    </div>
    <IconButton size="small">
      {icon}
    </IconButton>
 </div>
  )
}

export default NavigationHeader