import React from 'react';
import styles from "./MessageBox.module.scss";


const MessageBox = (props) => {
    const { text, height } = props;
    return <div className={styles.container} style={{minHeight: height}}>
        <span>{text}</span>
    </div>
}

export default MessageBox;