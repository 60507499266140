import React, { useState } from "react";
import CALENDER_ICON from "../../assets/icons/calender-icon.svg";
import styles from "./DatePicker.module.scss";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import dayjs from "dayjs";
import * as isToday from "dayjs/plugin/isToday";
import DayJsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import TodayIcon from "@material-ui/icons/Today";

dayjs.extend(isToday);

export const CustomDatePicker = ({ selectDate, setSelectDate }) => {
   const [showPicker, setShowPicker] = useState(false);

   const handleNextday = () => {
      setSelectDate(dayjs(selectDate).add(1, "d"));
   };

   const handlePrevday = () => {
      setSelectDate(dayjs(selectDate).subtract(1, "d"));
   };

   const handleDateChange = (date) => {
      setSelectDate(date);
   };

   const handleShowPicker = () => {
      setShowPicker(true);
   };

   return (
      <div className={styles.datePicker}>
         <ArrowBackIcon onClick={handlePrevday} />
         <div className={styles.dateField}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
               <DatePicker
                  orientation='portrait'
                  format='ddd D MMM'
                  value={selectDate}
                  onChange={handleDateChange}
                  InputProps={{
                     disableUnderline: true,
                     className: dayjs(selectDate).isToday() ? `${styles.today}` : `${styles.input}`,
                     classes: {
                        input: styles.inputWidth,
                     },
                     startAdornment: (
                        <InputAdornment position='start'>
                           <img src={CALENDER_ICON} alt='calendar' />
                           <p className={styles.text}>{dayjs(selectDate).isToday() && "Today,"}</p>
                        </InputAdornment>
                     ),
                  }}
               />
            </MuiPickersUtilsProvider>
         </div>
         <ArrowForwardIcon onClick={handleNextday} />
      </div>
   );
};
