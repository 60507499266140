import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Highlighter from "react-highlight-words";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import MessageBox from "../../../../components/MessageBox/MessageBox";
import NotFound from "../NotFound/NotFound";
import backImg from "./../../../../assets/icons/back.png";
import searchImg from "./../../../../assets/icons/search.png";
import TagSearchGroceries from "./TagSearchGroceries/TagSearchGroceries";
import TagSearchRecipes from "./TagSearchRecipes/TagSearchRecipes";
import TagSearchArticles from "./TagSearchArticles/TagSearchArticles";
import styles from "./TagSearch.module.scss";


const TextSearch = (props) => {
    const { tabTexts } = props;
    const searchState = useSelector(state => state.search, shallowEqual);
    const params = useParams()
    const dispatch = useDispatch();
    const history = useHistory();

    // useEffect(() => {
    // if (searchText.length > 0) {
    //     searchPending.current = true;
    //     clearTimeout(searchBounce.current);
    //     searchBounce.current = setTimeout(() => {
    //         searchPending.current = false;
    //         dispatch(getGroceriesTextSearch(searchText));
    //         dispatch(getRecipesTextSearch(searchText));
    //     }, 1500);
    // } else {
    //     clearTimeout(searchBounce.current);
    //     dispatch(resetTypingSearchGroceries());
    //     dispatch(resetTypingSearchRecipes());
    // }
    // }, []);


    const handleGoBack = () => {
        history.push("/");
    };

    const handleTabChange = (event, tab) => {
        history.push(`/search/tag/${tab}/${params.label ? params.label : ""}`)
    };


    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerTop}>
                    <IconButton size="small" className="mr-3" onClick={handleGoBack}>
                        <Avatar alt="back" sizes="" variant="square" src={backImg} className={styles.backIcon} />
                    </IconButton>
                    <div className={styles.searchText}>{params.label && params.label}</div>
                </div>
            </div>

            <AppBar position="sticky" color="default" className={styles.appBar}>
                <Tabs
                    value={params.tab}
                    onChange={handleTabChange}
                    aria-label="Search tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="off"
                    variant="fullWidth"
                >
                    {tabTexts.map((text, idx) => <Tab key={idx} value={text.toLowerCase()} aria-labelledby={text} className={styles.tab} label={text} />)}
                </Tabs>
            </AppBar>

            <Switch>

                <Route path="/search/tag/groceries/:label?" exact>
                    <TagSearchGroceries label={params.label.toLowerCase()} />
                </Route>

                <Route path="/search/tag/recipes/:label?" exact>
                    <TagSearchRecipes label={params.label.toLowerCase()} />
                </Route>

                <Route path="/search/tag/articles/:label?" exact>
                    <TagSearchArticles label={params.label || params.label.toLowerCase()} />
                </Route>

                <Redirect to="/search/tag/groceries" />

            </Switch>





        </>
    )
}

export default TextSearch;
