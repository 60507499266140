import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import sendIMG from "../../assets/icons/sendBlue.svg";
import Header from "./Components/Header";
import Loader from "./Components/Loader/Loader";
import styles from "./GptChat.module.scss";
import AddIcon from "@material-ui/icons/Add";
import {
  addQuizData,
  addRecentMealData,
  addUserEventsFood,
  addUserEventsSymptoms,
  addUserEventsWater,
  addUserEventsWeight,
  getUserData,
  uploadRecipe,
} from "../../services/firebase";
import { addRecipe, setUser, toast } from "../../state";
import { useHistory, useParams } from "react-router";
import useAutosizeTextArea from "../../hooks/useAutosizeTextArea";
import { SSE } from "sse";
import { Card } from "@material-ui/core";
import { macroTags } from "./../../constant";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import { format } from "date-fns";
import { DateTimePicker } from "../../components/DateTimePicker/DateTimePicker";
import bulb from "../../assets/icons/bulb.svg";
import food from "../../assets/icons/food_light.svg";
import food_dark from "../../assets/icons/food_dark.svg";
import symptoms from "../../assets/icons/symptoms_light.svg";
import symptoms_dark from "../../assets/icons/symptoms_dark.svg";
import recipe from "../../assets/icons/Chef_light.svg";
import recipe_dark from "../../assets/icons/Chef_dark.svg";
import weight from "../../assets/icons/weight_light.svg";
import weight_dark from "../../assets/icons/weight_dark.svg";
import water_dark from "../../assets/icons/water.svg";
import water from "../../assets/icons/water_dark.svg";
import galary from "../../assets/icons/gallery.svg";
import AnimateElement from "../../components/AnimateElement";
import { Link } from "react-router-dom";

const GptChat = () => {
  const apiKey = "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";
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [generatingJSON, setGeneratingJSON] = useState(false);
  let [result, setResult] = useState("");
  let [dataJson, setDataJson] = useState("");
  let [remove2, setRemove2] = useState(false);
  let [isVisible, setIsVisible] = useState(false);

  const userState = useSelector((state) => state.user, shallowEqual);
  const bottomRef = useRef(null);
  const history = useHistory();
  const textAreaRef = useRef();
  const resultRef = useRef();
  const jsonRef = useRef();
  const [tags, setTags] = useState([]);
  const [foodTags, setFoodTags] = useState([]);
  const [healthGoals, setHealthGoals] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [personalize, setPersonalize] = useState(true);
  const [isRecipe, setIsRecipe] = useState(true);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [messageType, setMessageType] = useState("recipe");
  const [responseType, setResponseType] = useState("recipe");
  const user = userState?.user;
  const params = useParams();
  const { type } = params;
  const weightRef = useRef();
  const symptomsRef = useRef();
  const waterRef = useRef();

  const [userStory] = useState([
    `My name is ${userState?.user?.displayName}`,
    `${
      userState?.user?.assignedDietitian
        ? "my dietitian is " + userState?.user?.assignedDietitian?.displayName
        : ""
    }`,
    `${
      userState?.user?.assignedChatGroup
        ? "my dietitian assigned me to " +
          userState?.user?.assignedChatGroup[0]?.uniqueName
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.allergies.length > 0
        ? "my allergies are " +
          userState?.user?.foodPreference?.allergies.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.children
        ? "my children are " + userState?.user?.foodPreference?.children
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.cookingTime
        ? "my best cooking time is " +
          userState?.user?.foodPreference?.cookingTime
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.cuisine
        ? "my best cuisine is " + userState?.user?.foodPreference?.cuisine
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.dislikes.length > 0
        ? "my dislikes are " +
          userState?.user?.foodPreference?.dislikes.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.foodBudget
        ? "my food budget is " + userState?.user?.foodPreference?.foodBudget
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.foodPractice
        ? "i am a " + userState?.user?.foodPreference?.foodPractice
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.healthCondition.length > 0
        ? "my health conditions are " +
          userState?.user?.foodPreference?.healthCondition.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.toddlers
        ? "my toddlers are " + userState?.user?.foodPreference?.toddlers
        : ""
    }`,

    `${
      userState?.user?.nutrients
        ? `my dietitian says i am only allowed to consume carbohydrates ${userState?.user?.nutrients.carbs} gram, protein ${userState?.user?.nutrients.proteins} gram, fat ${userState?.user?.nutrients.fats} gram and calories ${userState?.user?.nutrients.fats} a day`
        : ""
    }`,
    `${
      userState?.user?.quizData?.age
        ? `my age is ${userState?.user?.quizData.age}`
        : ""
    }`,
    `${
      userState?.user?.quizData
        ? `${userState?.user?.quizData.alcohol}, ${userState?.user?.quizData.smoke} and my weight is ${userState?.user?.quizData.weight}`
        : ""
    }`,
    `${
      userState?.user?.quizData?.gender
        ? `my gender is ${userState?.user?.quizData.gender}`
        : ""
    }`,
    `${
      userState?.user?.quizData?.sleep
        ? `i sleep ${userState?.user?.quizData.sleep}`
        : ""
    }`,
    `todays date is ${selectedDate}`,
  ]);

  const [weightMessages] = useState([
    {
      role: "system",
      content: `whenever i talk about my weight or i want to log my weight,
      if i did not provide time use the current time and date and
      return the weight hour, time and date in array objects eg, 
      {
        "details": [
          {
            "weight": should be a string in kg format,
            "hour": use hour user provides from time or use the current date to get hour,
            "date": use the current date or date user provides,
            "time": use current time or time user provides,
          }
        ]
      }
     
      `,
    },
    // date in all objects should be in date format yyyy-MM-dd and calculate date using the date i had the symptom or use today,
    // convert hour in all objects to 24 hours format eg [{weight: '23kg', time: 09:48 AM, hour: 23, date: in date format}],
    // the weight should be a string in kg format,
    // convert hour in all objects to 24 hours format
    // make sure array is returned in this format eg  [{weight: '70kg', time: 09:48 AM, hour: 23, date: in date format}]
    // time should be in the specified format in all objects
    // always return array
    {
      role: "system",
      content: `if i did not say the time ask me of the time and return in 24 hours format`,
    },
    {
      role: "system",
      content: `always make sure array of object is returned`,
    },
    {
      role: "system",
      content: `here is information about me use it for all your response \n ${
        personalize ? userStory : ""
      }`,
    },
    {
      role: "system",
      content: `user should only talk about weight or weight the want to log`,
    },
    {
      role: "system",
      content: `always make sure you return array when you are asked to log to calender, or i tell you my weight. i only need the json to help me log it`,
    },
    {
      role: "system",
      content: `make sure string values are wrapped in double quotation, i don't need single quotation`,
    },
    {
      role: "system",
      content: `don't forget to always return array part
      make sure you always return the array in my specified format
      `,
    },
  ]);

  const [symptomMessages] = useState([
    // {
    //   role: "system",
    //   content: `whenever i talk about my symptoms or i want to log my symptoms,
    //   if i did not provide time use the current time and date and
    //   return the symptoms, hour, time and date in json of array objects in this format 🏥 🏥 🏥 :,
    //   date in json should be in date format yyyy-MM-dd and calculate date using the date i had the symptom or use today,
    //   convert hour in json to 24 hours format eg 0, 4, 9,
    //   the symptoms should be an array inside the objects,
    //   time, time should be in 24 hours format eg 09:48 AM, 19:48 PM, use time user specified or use the current time
    //   convert hour in json to 24 hours format
    //   make sure json is returned in this eg array format with icons before the json 🏥 🏥 🏥 : [{symptoms: 'headache','stomach pain', time: 09:48 AM, hour: 23, date: in date format}]
    //   `,
    // },
    {
      role: "system",
      content: `whenever i tell you my symptom or ask you to log my symptom or add to my calender
        use the symptoms  and get me the details in this array format
        {
          "details": [
            {
              "symptoms": in array of strings format of users symptoms eg ['headache', 'fever'],
              "hour": use hour user provides from time or use the current date to get hour,
              "date": use the current date or date user provides,
              "time": use current time or time user provides,
            }
          ]
        }
        
        
        `,
    },
    // name,
    //     symptoms in array of strings format eg ['headache', 'fever'],
    //     hour, hour should be the current hour in 24 hours time eg 2, 5, 11, 24
    //     date, date should be in this format yyyy-MM-dd and calculate date using the date i had the symptom or use today,
    //     time, time should be in 24 hours format eg 09:48 AM, 19:48 PM, use time user specified or use the current time
    //     make sure array is returned in this format eg [{symptoms: 'headache','stomach pain', time: 09:48 AM, hour: 23, date: in date format}]
    //     time should be in the specified format in all objects
    //     always return array
    {
      role: "system",
      content: `you cant give me recipe, only talk about my symptoms`,
    },
    {
      role: "system",
      content: `always make sure one array of objects is returned`,
    },
    {
      role: "system",
      content: `here is information about me use it for all your response \n ${
        personalize ? userStory : ""
      }`,
    },
    {
      role: "system",
      content: `user should only talk about symptoms or symptoms the want to log`,
    },
    {
      role: "system",
      content: `always make sure you return array when you are asked to log to calender, or i tell you my symptoms. i only need the json to help me log it`,
    },
    {
      role: "system",
      content: `make sure string values are wrapped in double quotation, i don't need single quotation`,
    },
    {
      role: "system",
      content: `don't forget to always return json part with the emojis.
      make sure you always return the json in my specified format
      `,
    },
  ]);

  const [logMessages] = useState([
    {
      role: "system",
      content: `whenever i tell you what i ate or ask you to log my meal or add to my calender
        use the ingredients and quantity and get me the details in array format

        {
          "details": [
            {
              "name": "name of meal",
              "tag": "Dinner Breakfast or Launch depends on time",
              "quantity": "use quantity user provides or the smallest unit for one meal eg "200 g", "100 l"",
              "hour": use hour user provides from time or use the current date to get hour hour should always be two numbers instated of 12:30 just use 12,
              "date": use the current date or date user provides,
              "time": use current time or time user provides,
              "nutrients": {
                "carbohydrates": carbohydrates contained in meal in grams eg "302" should all be in number format no fraction,
                "fat": fat contained in meal in grams eg "3.6" should all be in number format no fraction,
                "proteins": proteins contained in meal in grams eg "3.2" should all be in number format no fraction,
                "energy-kcal": calories contained in meal in grams eg "502" should all be in number format no fraction,
              }
            }
          ]
        }
        
        `,
    },

    // name,
    //     tag eg Breakfast, Launch, Dinner
    //     quantity in grams eg 100 g, 300 g, 500 g,
    //     hour, hour should be the current hour in 24 hours time eg 2, 5, 11, 24
    //     date, date should be in this format yyyy-MM-dd and calculate date using the date i had the symptom or use today,
    //     time, time should be in 24 hours format eg 09:48 AM, 19:48 PM, use time user specified or use the current time
    //     and nutrients as an object containing carbohydrates in grams eg 2, 45, 69, fat in grams eg 2, 45, 69, proteins in grams eg 2, 45, 69, energy-kcal eg 2, 45, 69 the should all be in number format,
    //     always return details in array do not return as list

    // {
    //   "details": [
    //     {
    //       "name": "plantain",
    //       "tag": "Dinner",
    //       "quantity": "100 g",
    //       "hour": 16,
    //       "date": "2023-12-21",
    //       "time": "16:00",
    //       "nutrients": {
    //         "carbohydrates": 31,
    //         "fat": 0.4,
    //         "proteins": 1.3,
    //         "energy-kcal": 122
    //       }
    //     }
    //   ]
    // }
    // date,
    //     time,
    //     hour
    //     date in json should be in date format yyyy-MM-dd and calculate date using the date i had the symptom or use today,
    //     convert hour in json to 24 hours format eg 🍲 🍲 🍲 : [{name: 'rice', tag: 'Breakfast', nutrients: {carbohydrate: 6, fat: 2, calories: 6, protein: 1}, quantity: 23 g time: 09:48 AM, hour: 23, date: in date format}],
    //     time, time should be in 24 hours format eg 24, 18, 40
    //     and nutrients as an object containing carbohydrates in grams eg 2, 45, 69, fat in grams eg 2, 45, 69, proteins in grams eg 2, 45, 69, energy-kcal eg 2, 45, 69 the should all be in number format,
    //     make sure the json start with 🍲 🍲 🍲 : [{name: 'rice', tag: 'Breakfast', nutrients: {carbohydrate: 6, fat: 2, calories: 6, protein: 1}, quantity: 23 g time: 09:48 AM, hour: 23, date: in date format}]

    {
      role: "system",
      content: `you cant give me recipe, only talk about the food i ate`,
    },
    {
      role: "system",
      content: `if i did not say the time ask me of the time and return in 24 hours format`,
    },
    {
      role: "system",
      content: `always make sure one object is returned and make sure its inside array`,
    },
    {
      role: "system",
      content: `here is information about me use it for all your response \n ${
        personalize ? userStory : ""
      }`,
    },
    {
      role: "system",
      content: `if user greets you, greet them back`,
    },

    {
      role: "system",
      content: `user should only talk about foods the ate or foods the want to log`,
    },
    {
      role: "system",
      content: `always make sure you return json array when you are asked to log to calender, or i tell you what i ate. i only need the json to help me log it`,
    },
    {
      role: "system",
      content: `make sure string values are wrapped in double quotation, i don't need single quotation`,
    },
    {
      role: "system",
      content: `don't forget to always return json part.
      make sure you always return the json in my specified format
      `,
    },
    {
      role: "system",
      content: `always give me the array of what i ate, don't return in a list format, i need it in array`,
    },
  ]);

  const [waterMessages] = useState([
    {
      role: "system",
      content: `whenever i tell you how much water i drank or ask you to log the water i drank to my calender
        use the  quantity and get me the details in array format

        {
          "details": [
            { 
              "type": "water",
              "quantity": should be converted to ml but return only the number eg 100, 300, 500,
              "hour": use hour user provides from time or use the current date to get hour,
              "date": use the current date or date user provides,
              "time": use current time or time user provides,
            }
          ]
        }
       
        
        `,
    },
    // type should be water
    //     quantity should be converted to ml but return only the number eg 100, 300, 500,
    //     hour, hour should be the current hour in 24 hours time eg 2, 5, 11, 24
    //     date, date should be in this format yyyy-MM-dd and calculate date using the date i had the symptom or use today,
    //     time, time should be in 24 hours format eg 09:48 AM, 19:48 PM, use time user specified or use the current time
    //     always return details in array do not return as list
    {
      role: "system",
      content: `you cant give me recipe, only talk about the water`,
    },
    {
      role: "system",
      content: `if i did not say the time ask me of the time and return in 24 hours format`,
    },
    {
      role: "system",
      content: `always make sure one object is returned and make sure its inside array`,
    },
    {
      role: "system",
      content: `here is information about me use it for all your response \n ${
        personalize ? userStory : ""
      }`,
    },
    {
      role: "system",
      content: `if user greets you, greet them back`,
    },

    {
      role: "system",
      content: `user should only talk about water the drank or water the want to log`,
    },
    {
      role: "system",
      content: `always make sure you return json array when you are asked to log to calender, or i tell you amount of water i drank. i only need the json to help me log it`,
    },
    {
      role: "system",
      content: `make sure string values are wrapped in double quotation, i don't need single quotation`,
    },
    {
      role: "system",
      content: `don't forget to always return json part.
      make sure you always return the json in my specified format
      `,
    },
    {
      role: "system",
      content: `always give me the array of water i drank, don't return in a list format, i need it in array`,
    },
  ]);

  const [recipeMessages] = useState([
    {
      role: "system",
      content: `here is information about me use it for all your response \n ${
        personalize ? userStory : ""
      }`,
    },
    {
      role: "system",
      content: `if user greets you, greet them back`,
    },

    {
      role: "system",
      content: `user should only talk about recipes and symptoms`,
    },
    {
      role: "system",
      content: `always add nutritional value to your description`,
    },

    {
      role: "system",
      content: `response to all recipes should have a list part and a json part, list part should be listed like a word doc while json should be in this format 🍳 🍳: {}`,
    },
    {
      role: "system",
      content: `only return recipe when user is requesting for it or user ask for a recipe`,
    },
    {
      role: "system",
      content: `do not give me recipe if any of my allergies is part of ingredients`,
    },
    {
      role: "system",
      content: `do not give me meat or fish recipes if i am vegan or vegetarian,`,
    },
    {
      role: "system",
      content: `you can give me fish and other seafood but excludes other types of meat such as poultry, beef, pork, and lamb recipes if i am pescetarian,`,
    },
    {
      role: "system",
      content: `make sure string values are wrapped in double quotation, i don't need single quotation`,
    },

    {
      role: "system",
      content: ` this is just an instruction.
            if your response is about a recipe add Stove Recipe  as first word on your response and
            return the recipe in a list format like word document, make sure to add the nutrition and the content is exactly what we have on the json excluding 🍳 🍳.
            add a second response containing only json format of the recipe in this format 🍳 🍳: {} make sure the json does not have text description or a variable holding it, and make sure it is returned last.
            just give me the json object,
            the json should contain servings, difficulty, cost, ready_in, type, meal, diet, made_in, cooking_method, recipe_name, ingredients, nutrition and instructions with the same spelling and casing,
            ingredients should be array of object that contains name, quantity as decimal numbers instead of fractions. For example, use 1.5 instead of 1/2, and 2.3 instead of 2/3 and unit.,
            recipe_name should be the name of food to be prepared
            nutrition should contain carbohydrates, protein , fat in grams and calories in kcal the should all be in decimal numbers instead of fractions. For example, use 1.5 instead of 1/2, and 2.3 instead of 2/3,
            instructions should be array of object containing ingredientsUsed, title, description, 
            ingredientsUsed is ingredients used in the step and should be in string separated by , format,
            cost should be either £, ££, £££ or ££££,
            difficulty should be either Low, Medium, High or Pro,
            made_in should be full name of country it was made in,
            cooking_method should be either baked, boiled, braised, broiled, grilled, poached, roasted, sautéed, simmered, steamed, stir-fry or stewed,
            type should be either Starter, Dessert, Side dish, Sauce, Main, Salad, Bread, Soup, Roast, Antipasti, Sandwich, Stew, Cake, Pie or Drink,
            meal should be string of either Breakfast, Launch, Dinner or Snack
            ready_in should be in this format 5 mins and it should be in fives,
            diet should be a either Vegeterian, Vegan, Pescetarian, No pork, No beef, No nuts, Glueten free, Dairy free or N/A,
            the same details in the json should be in the list part too,
            recipe json should be in this format 🍳 🍳: {}.
            servings should be a number, of how many times it can be taken,
            do not give me recipes that has more nutrient than my daily consumption
            when i ask for plan meals factor in the my information i gave you about my allergies etc.
            make sure you always return the json in my specified format.
            `,
    },
  ]);

  useAutosizeTextArea(textAreaRef.current, input);

  //roles assistant and user

  useEffect(() => {
    resultRef.current = result;
    jsonRef.current = dataJson;
  }, [result, messages, dataJson]);

  useEffect(() => {
    setMessageType(type);
    if (type === "weight") {
      weightRef.current.scrollIntoView();
    } else if (type === "symptoms") {
      weightRef.current.scrollIntoView();
    } else if (type === "water") {
      symptomsRef.current.scrollIntoView();
    }
    if (!isKeyInLocalStorage("firstVisit")) {
      setIsVisible(true);
      localStorage.setItem("firstVisit", "false");
    } else {
      setIsVisible(false);
    }
  }, [type]);

  useEffect(() => {
    if (user) {
      if (user?.foodPreference) {
        const {
          allergies,
          cookingTime,
          dislikes,
          foodBudget,
          healthCondition,
        } = user?.foodPreference;
        const food_tags = [
          ...allergies,
          ...dislikes,
          ...healthCondition,
          cookingTime,
          foodBudget,
        ];
        setFoodTags(food_tags);
      }
      if (user?.quizData?.health_goals) {
        const health_goals = user?.quizData?.health_goals
          ? user?.quizData?.health_goals
          : [];
        setHealthGoals(health_goals);
      }
      if (user?.quizData?.conditions) {
        const checkedConditions = user?.quizData?.conditions
          ? user?.quizData?.conditions
              .filter((condition) => condition.checked === true)
              .map((condition) => condition.label)
          : [];
        setConditions(checkedConditions);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const tags = [...foodTags, ...healthGoals, ...conditions];
      setTags(tags);
    }
  }, [conditions, foodTags, healthGoals, user]);

  const moveTo = (path) => history.push(path);

  const fixInvalidJSON = (string) => {
    try {
      JSON.parse(string);
      return string; // Return as is if it's already valid JSON
    } catch (error) {
      // Check if the string is empty
      if (!string.trim()) {
        return string;
      }

      let jsonString = string.replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3');

      // Add missing double quotes around keys
      const fixedString = jsonString.replace(
        /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
        '"$2":'
      );

      // Fix any broken JSON by adding missing double quotes around values
      const fixedJSON = fixedString.replace(
        /: (["'])?(.*?)\1(?=,|}|$)/g,
        ': "$2"'
      );

      return fixedJSON;
    }
  };

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  const sendMessage = async (check) => {
    setRemove2(false);
    let searchIndex = input;
    setInput("");
    let startJSON = false;
    const send = async (message) => {
      const systemMessage =
        messageType === "recipe"
          ? recipeMessages
          : messageType === "food"
          ? logMessages
          : messageType === "symptoms"
          ? symptomMessages
          : messageType === "water"
          ? waterMessages
          : weightMessages;
      let mainMessages = JSON.parse(JSON.stringify(message));

      for (let i = 0; i < mainMessages.length; i++) {
        if ("json" in mainMessages[i]) {
          mainMessages[i].content = JSON.stringify(mainMessages[i].json);
          delete mainMessages[i]["json"];
        }
        if ("isRecipe" in mainMessages[i]) {
          delete mainMessages[i]["isRecipe"];
        }
        if ("id" in mainMessages[i]) {
          delete mainMessages[i]["id"];
        }
      }

      if (searchIndex !== "" || check) {
        setLoading(true);
        setResult("");
        setDataJson("");
        let url = "https://api.openai.com/v1/chat/completions";
        let data = {
          model: "gpt-4o",
          // response_format:
          //   messageType !== "recipe" ? { type: "json_object" } : null,
          messages: [...systemMessage, ...mainMessages],
          temperature: 0.8,
          top_p: 1,
          max_tokens: 1500,
          stream: true,
          n: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        let source = new SSE(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
            "X-Content-Type-Options": "nosniff",
          },
          method: "POST",
          payload: JSON.stringify(data),
        });

        source.addEventListener("message", (e) => {
          if (e.data === "[DONE]") {
            console.log("jsonRef.current", jsonRef.current);

            const jsData = jsonRef.current
              ? convertToJSON(jsonRef.current)
              : "";
            // myObject.hasOwnProperty(keyToCheck)
            // if (jsData.hasOwnProperty('keyToCheck')) {
            //   setMessages((prevItems) => {
            //     return [
            //       ...prevItems,
            //       {
            //         role: "assistant",
            //         content: resultRef.current,
            //         json: jsonRef.current ? jsonRef.current : null,
            //         isRecipe: jsonRef.current.includes("🍳") ? true : false,
            //       },
            //     ];
            //   });
            // } else
            console.log("jsData", jsData);
            if (jsonRef.current) {
              if (jsData[0]) {
                if (jsData[0].hasOwnProperty("tag")) {
                  const data = convertToJSON(jsonRef.current);
                  let content = "";
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                    Name: ${data[i].name}
                    Quantity: ${data[i].quantity}
    
                    Nutrients
                    carbohydrate: ${data[i].nutrients.carbohydrates}
                    fat: ${data[i].nutrients.fat}
                    calories: ${data[i].nutrients["energy-kcal"]}
                    protein: ${data[i].nutrients.proteins}

                    date: ${data[i].date}
                    time: ${data[i].time}
    
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else if (jsData[0].hasOwnProperty("symptoms")) {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Symptoms
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                      symptoms:  ${data[i].symptoms.join(" ")}
                      date: ${data[i].date}
                      time: ${data[i].time}
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else if (jsData[0].type === "water") {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Water
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                      Quantity: ${data[i].quantity}ml
      
                      date: ${data[i].date}
                      time: ${data[i].time}
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else if (jsData[0].hasOwnProperty("weight")) {
                  const data = convertToJSON(jsonRef.current);
                  let content = `Weight
                  
                  `;
                  for (let i = 0; i < data.length; i++) {
                    content =
                      content +
                      `
                      weight:  ${data[i].weight}
                      date: ${data[i].date}
                      time: ${data[i].time}
                    `;
                  }
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: content,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                } else {
                  setMessages((prevItems) => {
                    return [
                      ...prevItems,
                      {
                        role: "assistant",
                        content: resultRef.current,
                        json: jsData ? jsData : "",
                        isRecipe: jsData[0].hasOwnProperty("meal")
                          ? true
                          : false,
                      },
                    ];
                  });
                }
              } else {
                setMessages((prevItems) => {
                  return [
                    ...prevItems,
                    {
                      role: "assistant",
                      content: resultRef.current,
                      json: jsData ? jsData : "",
                      isRecipe: true,
                    },
                  ];
                });
              }
            } else {
              setMessages((prevItems) => {
                return [
                  ...prevItems,
                  {
                    role: "assistant",
                    content: resultRef.current,
                    json: "",
                    isRecipe: false,
                  },
                ];
              });
            }

            scrollToBottom("ref", jsonRef, result, resultRef);
          } else {
            let payload = JSON.parse(e.data);
            let text = payload.choices[0].delta.content;
            if (payload.choices[0].finish_reason === "stop") {
              source.close();
            } else {
              if (text !== undefined) {
                //text.includes("🏥") ||
                if (text.includes("{") || text.includes("[")) {
                  startJSON = true;
                  // setResponseType("recipe");
                  setLoadingText("Finalizing for your cookbook");
                }
                // else if (text.includes("🍲")) {
                //   startJSON = true;
                //   setLoadingText("Finalizing for your calender");
                //   setResponseType("food");
                // } else if (text.includes("🏥")) {
                //   startJSON = true;
                //   setResponseType("symptoms");
                //   setLoadingText("Finalizing for your calender");
                // } else if (text.includes("🏋")) {
                //   startJSON = true;
                //   setResponseType("weight");
                //   setLoadingText("Finalizing for your calender");
                // }
                if (startJSON) {
                  jsonRef.current = jsonRef.current + text;
                  setDataJson((prevResult) => prevResult + text);

                  //   dataJson
                  setDataJson(jsonRef.current);
                } else {
                  //   resultRef.current = resultRef.current + text;
                  //   setResult(resultRef.current);
                  setResult((prevResult) => prevResult + text);
                  scrollToBottom();
                }
              }
            }
          }
        });

        source.addEventListener("readystatechange", (e) => {
          if (e.readyState >= 2) {
            setLoading(false);
            setLoadingText("");
          }
        });
        source.stream();
      } else {
        dispatch(toast({ message: `Please write a message` }));
      }
    };
    setMessages((prevValue) => {
      if (!searchIndex) {
        send([...prevValue]);
      } else {
        send([...prevValue, { role: "user", content: searchIndex }]);
      }
      if (!searchIndex) {
        return [...prevValue];
      } else {
        return [...prevValue, { role: "user", content: searchIndex }];
      }
    });
  };

  const addData = async (json, id) => {
    setRemove2(true);
    setResult("");
    const data = json;
    if (!data) {
      return;
    }
    try {
      let newData = [];
      setLoading(true);
      scrollToBottom();
      if (data.instructions) {
        for (let i = 0; i < data.instructions.length; i++) {
          newData.push({
            ingredientsUsed: data?.instructions[i]?.ingredientsUsed
              ? data.instructions[i].ingredientsUsed.split(",")
              : [],
            id: i,
            thumbnail:
              "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
            description: data.instructions[i].description,
            title: data.instructions[i].title,
          });
        }
      } else {
        setLoading(false);
        setMessages([
          ...messages,
          {
            role: "assistant",
            content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
          },
        ]);
        console.log("error 1");
        return false;
      }

      const payload = {
        chefId: userState.user.uid,
        cookingMethod: data.cooking_method,
        cost: data.cost,
        cuisine: data.made_in,
        dietary: data.diet,
        difficulty: data.difficulty,
        groceriesId: [],
        ingredients: data.ingredients,
        meal: [data.meal],
        readyIn: data.ready_in,
        servings: data.servings,
        steps: newData,
        thumbnail:
          "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
        title: data.recipe_name,
        type: data.type,
        nutriments: {
          proteins: data.nutrition.protein,
          carbohydrates: data.nutrition.carbohydrates,
          fat: data.nutrition.fat,
          "energy-kcal": data.nutrition.calories,
        },
      };
      scrollToBottom();
      const res = await uploadRecipe(payload);

      setLoading(false);

      setMessages([
        ...messages,
        {
          role: "assistant",
          content: `${data.recipe_name} added to your recipe`,
          id: res,
        },
      ]);
      setMessages((prevItems) => {
        let prev = prevItems;
        prev[id].json = "";

        return [...prev];
      });
      scrollToBottom();
    } catch (err) {
      console.log(err);
      dispatch(toast({ message: `Sorry try again later` }));
      setLoading(false);
      setMessages([
        ...messages,
        {
          role: "assistant",
          content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
        },
      ]);
      setRemove2(true);
    }
  };

  const saveEvents = async (json, id) => {
    setRemove2(true);
    setResult("");
    const data = json;
    setLoading(true);
    const date = new Date();
    date.setHours(1, 0, 0, 0);
    let items = [];
    let tag = "Breakfast";
    let name = null;
    if (+format(selectedTime, "H") > 0 && +format(selectedTime, "H") < 12) {
      tag = "Breakfast";
    } else if (
      +format(selectedTime, "H") > 12 &&
      +format(selectedTime, "H") < 18
    ) {
      tag = "Launch";
    } else if (+format(selectedTime, "H") > 18) {
      tag = "Dinner";
    }

    for (let i = 0; i < data.length; i++) {
      if (data[0].hasOwnProperty("tag")) {
        name = !name ? data[i].name : name + ", " + data[i].name;
        items.push({
          Item: {
            id: format(date, "yyyy/MM/dd:hh:mm:a"),
            name: data[i].name,
            quantity: data[i].quantity,
            nutriments: data[i].nutrients,
          },
          id: i,
        });
      }
    }

    // const payload = {
    //   id: data[0]?.date
    //     ? format(new Date(data[0].date), "eee d MMM")
    //     : format(new Date(), "eee d MMM"),
    //   time: data[0]?.time ? data[0].time : format(new Date(), "hh:mm a"),
    //   hour: data[0]?.hour ? data[0].hour : format(new Date(), "H"),
    //   minute: "00",
    //   items: [...items],
    //   description: "",
    //   tag: tag,
    //   date: data[0].date
    //     ? format(new Date(data[0].date), "yyyy-MM-dd")
    //     : format(new Date(), "eee d MMM"),
    // };
    // const symptomsPayload = {
    //   symptoms: data[0].symptoms,
    //   id: format(new Date(data[0].date), "eee d MMM"),
    //   time: data[0].time,
    //   hour: data[0].hour,
    //   description: "",
    //   date: format(new Date(data[0].date), "yyyy-MM-dd"),
    // };

    // addUserEventsWeight(user.uid, payload);
    try {
      if (data[0].hasOwnProperty("tag")) {
        const id = format(date, "yyyy/MM/dd:hh:mm:a");
        console.log(data);
        const recentData = [];
        for (let i = 0; i < data.length; i++) {
          recentData.push({
            mid: id,
            name: data[i].name,
            quantity: data[i].quantity,
            nutriments: data[i].nutrients,
            thumbnail: "",
            type: "food",
            dateCreated: new Date(),
          });
          const payload = {
            items: [
              {
                Item: {
                  id: id,
                  name: data[i].name,
                  quantity: data[i].quantity,
                  nutriments: data[i].nutrients,
                },
                id: i,
              },
            ],
            tag: tag,
            minute: "00",
            id: data[i]?.date
              ? format(new Date(data[i].date), "eee d MMM")
              : format(new Date(), "eee d MMM"),
            time: data[i]?.time ? data[i].time : format(new Date(), "hh:mm a"),
            hour: data[i]?.hour ? data[i].hour : format(new Date(), "H"),
            description: "",
            date: data[i].date
              ? format(new Date(data[i].date), "yyyy-MM-dd")
              : format(new Date(), "eee d MMM"),
          };
          await addUserEventsFood(userState.user.uid, payload);

          setMessages([
            ...messages,
            {
              role: "assistant",
              content: `${name} added to your calender`,
            },
          ]);
        }
        const recentMealPayload = {
          userId: userState.user.uid,
          data: recentData,
        };

        await addRecentMealData(recentMealPayload);
      } else if (data[0].type === "water") {
        for (let i = 0; i < data.length; i++) {
          const payload = {
            quantity: data[i].quantity,
            minute: "00",
            id: data[i]?.date
              ? format(new Date(data[i].date), "eee d MMM")
              : format(new Date(), "eee d MMM"),
            time: data[i]?.time ? data[i].time : format(new Date(), "hh:mm a"),
            hour: data[i]?.hour ? data[i].hour : format(new Date(), "H"),
            date: data[i].date
              ? format(new Date(data[i].date), "yyyy-MM-dd")
              : format(new Date(), "eee d MMM"),
          };
          await addUserEventsWater(userState.user.uid, payload);
          setMessages([
            ...messages,
            {
              role: "assistant",
              content: `water added to your calender`,
            },
          ]);
        }
      } else if (data[0].hasOwnProperty("symptoms")) {
        for (let i = 0; i < data.length; i++) {
          const symptomsPayload = {
            symptoms: data[i].symptoms,
            id: data[i]?.date
              ? format(new Date(data[i].date), "eee d MMM")
              : format(new Date(), "eee d MMM"),
            time: data[i]?.time ? data[i].time : format(new Date(), "hh:mm a"),
            hour: data[i]?.hour ? data[i].hour : format(new Date(), "H"),
            description: "",
            date: data[i].date
              ? format(new Date(data[i].date), "yyyy-MM-dd")
              : format(new Date(), "eee d MMM"),
          };
          await addUserEventsSymptoms(userState.user.uid, symptomsPayload);
          setMessages([
            ...messages,
            {
              role: "assistant",
              content: `symptom added to your calender`,
            },
          ]);
        }
      } else if (data[0].hasOwnProperty("weight")) {
        for (let i = 0; i < data.length; i++) {
          const weightPayload = {
            size: data[i].weight,
            id: data[i]?.date
              ? format(new Date(data[i].date), "eee d MMM")
              : format(new Date(), "eee d MMM"),
            time: data[i]?.time ? data[i].time : format(new Date(), "hh:mm a"),
            hour: data[i]?.hour ? data[i].hour : format(new Date(), "H"),
            date: data[i].date
              ? format(new Date(data[i].date), "yyyy-MM-dd")
              : format(new Date(), "eee d MMM"),
          };
          await addUserEventsWeight(userState.user.uid, weightPayload);
          setMessages([
            ...messages,
            {
              role: "assistant",
              content: `weight added to your calender`,
            },
          ]);
        }
      }
      const userData = await getUserData(userState.user.uid);
      dispatch(setUser(userData));
      setLoading(false);
      // if (responseType === "food") {

      // } else if (responseType === "symptoms") {

      // } else if (responseType === "weight") {

      // }

      setMessages((prevItems) => {
        let prev = prevItems;
        prev[id].json = "";

        return [...prev];
      });
      scrollToBottom();
    } catch (error) {
      // dispatch(toast({ message: "an error occurred adding event" }));
      setLoading(false);
      console.log("err", error);
      setMessages([
        ...messages,
        {
          role: "assistant",
          content: `Oops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
        },
      ]);
      setRemove2(true);
    }
  };

  const convertFractionsToDecimals = (inputString) => {
    const fractionRegex = /(\d+)\s*\/\s*(\d+)/g;

    const convertedString = inputString.replace(
      fractionRegex,
      (match, numerator, denominator) => {
        const decimalValue = numerator / denominator;
        return decimalValue.toString();
      }
    );

    return convertedString;
  };

  const convertToJSON = (string) => {
    // const splitIndex = message.content.indexOf("{"); // Find the index where the JSON part starts
    // const textPart = message.content.substring(0, splitIndex);
    const jsonStartIndex =
      messageType === "recipe" ? string.indexOf("{") : string.indexOf("[");
    const jsonEndIndex =
      messageType === "recipe"
        ? string.lastIndexOf("}")
        : string.lastIndexOf("]");

    const jsonString = string.substring(jsonStartIndex, jsonEndIndex + 1);

    let mainJson = null;
    if (jsonString.length > 2) {
      try {
        mainJson = JSON.parse(convertFractionsToDecimals(jsonString));
      } catch (err) {
        setMessages([
          ...messages,
          {
            role: "assistant",
            content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
          },
        ]);
        setRemove2(true);
      }
    } else {
      setMessages([
        ...messages,
        {
          role: "assistant",
          content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
        },
      ]);
      setRemove2(true);
    }
    return mainJson;
  };

  const tryAgain = () => {
    setRemove2(true);
    if (remove2) {
      let popMessages = messages.slice(0, -1);
      popMessages = popMessages.slice(0, -1);
      setMessages(popMessages);
      sendMessage(true);
    } else {
      let popMessages = messages.slice(0, -1);
      setMessages(popMessages);
      sendMessage(true);
    }
  };
  const isKeyInLocalStorage = (key) => {
    return localStorage.getItem(key) !== null;
  };

  return (
    <>
      <Header
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        mode={messageType}
      />
      <div>
        <div className={styles.baseContainer} ref={bottomRef}>
          {messageType === "recipe" && (
            <AnimateElement duration="0.5">
              {isVisible && (
                <div className={styles.fixed}>
                  <div className={styles.featureHeading}>
                    <div>
                      <div className={styles.featureTitle}>Personalise</div>
                      <div className={styles.conta}>
                        The AI will consider all your preferences and
                        conditions. View them{" "}
                        <Link to={`/health-profile/${userState.user.uid}`}>
                          here
                        </Link>
                      </div>
                    </div>

                    <ToggleButton
                      toggle={personalize}
                      handleToggle={() => {
                        setPersonalize((prev) => !prev);
                      }}
                    />
                  </div>
                  {/* {personalize && (
                    <>
                      {tags.length > 0 && (
                        <div className={styles.sliderTagContainer}>
                          {tags.map((tag, index) => {
                            return (
                              <Card
                                component="div"
                                key={tag}
                                className={styles.sliderTag}
                                onClick={() =>
                                  moveTo(`/search/tag/groceries/${tag}`)
                                }
                              >
                                <span className={styles.sliderTagDot} />
                                <span>{tag}</span>
                              </Card>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )} */}
                </div>
              )}
            </AnimateElement>
          )}
          <AnimateElement>
            {messageType !== "recipe" && (
              <div
                className={styles.friendTextDiv}
                style={{ marginTop: "10px" }}
              >
                <div className={styles.friendTextContainer}>
                  <div className={styles.friendText}>
                    {messageType === "food" && (
                      <p>
                        Quickly add meals to your Food Diary fast!
                        <br />
                        <br />
                        Remember to include an amount and time, like 'a bowl of
                        porridge at 8am, a cheese sandwich at 1pm, 6 glasses of
                        water throughout the day, and a cottage pie at 7pm.’🥪
                        <br />
                        <br />
                        We'll forward this to your dietitian. 📊 Can't find a
                        meal? Tap here to search or add it yourself! 🥣"
                      </p>
                    )}

                    {messageType === "symptoms" && (
                      <p>
                        <span role="img" aria-label="food icons">
                          🍽️📝{" "}
                        </span>
                        Log & Track Your Symptoms! Simply message how you're
                        feeling and it'll be added to your calendar. Add
                        multiple entries in one go
                        {/* <span role="img" aria-label="food icons">
                      🍏🥙🍚
                    </span> */}
                      </p>
                    )}

                    {messageType === "weight" && (
                      <p>
                        <span role="img" aria-label="food icons">
                          🍽️📝{" "}
                        </span>
                        Log & Track Your Weight! If required by your Dietitian,
                        simply add your weight. Add multiple entries in one go
                        <span role="img" aria-label="food icons">
                          📊
                        </span>
                      </p>
                    )}

                    {messageType === "water" && (
                      <p>
                        <span role="img" aria-label="food icons">
                          🍽️📝{" "}
                        </span>
                        Log & Track Your water intake! If required by your
                        Dietitian, simply add your water intake. Add multiple
                        entries in one go
                        <span role="img" aria-label="food icons">
                          📊
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {messageType === "recipe" && (
              <div
                className={styles.friendTextDiv}
                style={{ marginTop: isVisible ? "100px" : "10px" }}
              >
                <div className={styles.friendTextContainer}>
                  <div className={styles.friendText}>
                    <p>
                      <span role="img" aria-label="food icons">
                        🥕🍆🌽{" "}
                      </span>
                      Not sure what to do with leftover ingredients? Hosting a
                      dinner party? Dealing with picky eaters? Ask for recipe
                      now!{" "}
                      <span role="img" aria-label="food icons">
                        🥑🥔🍄
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </AnimateElement>

          {messages.map((message, i) => {
            if (message.role === "user") {
              return (
                <div className={styles.myTextDiv} key={i}>
                  <div className={styles.myTextContainer}>
                    <div className={styles.myText}>{message.content}</div>
                  </div>
                </div>
              );
            } else if (message.role === "assistant") {
              return (
                <>
                  <div className={styles.friendTextDiv} key={i}>
                    <div className={styles.friendTextContainer} key={i}>
                      <>
                        {" "}
                        <div className={styles.friendText} key={i}>
                          <div className={styles.pre}>
                            {message.content}{" "}
                            {i === messages.length - 1 && (
                              <>
                                <button
                                  className={styles.create}
                                  onClick={() => tryAgain()}
                                >
                                  {" "}
                                  {/* <AddIcon
                                    className={styles.fab}
                                    fontSize="small"
                                  /> */}
                                  Try again
                                </button>
                                {message.json && (
                                  <>
                                    <button
                                      className={styles.create1}
                                      onClick={() => {
                                        message.isRecipe
                                          ? addData(message.json, i)
                                          : saveEvents(message.json, i);
                                        scrollToBottom();
                                      }}
                                    >
                                      {" "}
                                      <AddIcon
                                        className={styles.fab}
                                        fontSize="small"
                                      />
                                      Add to{" "}
                                      {message.isRecipe
                                        ? "my recipes"
                                        : "tracker"}
                                    </button>
                                  </>
                                )}
                                {message.id && (
                                  <button
                                    className={styles.create2}
                                    onClick={() =>
                                      moveTo(`/recipe/${message.id}`)
                                    }
                                  >
                                    View
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {/* {message.json && (
                          <div className={styles.friendText}>
                            <div className={styles.pre}>
                              {message.isRecipe ? (
                                <>
                                  Do you want to add this recipe to your
                                  collection?
                                </>
                              ) : (
                                <>
                                  {responseType === "food"
                                    ? "Do you want to add this food to your tracking"
                                    : responseType === "symptoms"
                                    ? "do you want to add this symptoms to your calender"
                                    : "do you want to add weight to your calender"}
                                </>
                              )}
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  className={styles.create}
                                  onClick={() =>
                                    message.isRecipe
                                      ? addData(message.json, i)
                                      : saveEvents(message.json, i)
                                  }
                                >
                                  {" "}
                                  <AddIcon
                                    className={styles.fab}
                                    fontSize="small"
                                  />
                                  Add to{" "}
                                  {message.isRecipe ? "my recipes" : "tracker"}
                                </button>
                              </div>
                            </div>
                          </div>
                        )} */}
                      </>
                      {/* {message.id && (
                        <div className={styles.friendText} key={i}>
                          {message.id && (
                            <div className={styles.pre}>
                              Do you want to view recipe?
                              <button
                                className={styles.create}
                                onClick={() => moveTo(`/recipe/${message.id}`)}
                              >
                                View
                              </button>
                            </div>
                          )}
                        </div>
                      )} */}
                    </div>
                  </div>
                </>
              );
            } else {
              return "";
            }
          })}
          {loading && (
            <>
              {result && (
                <div className={styles.friendText}>
                  <div className={styles.pre}>{result}</div>
                </div>
              )}
              <div className={styles.loader}>
                <Loader text={loadingText} />
              </div>
            </>
          )}
        </div>
        <AnimateElement duration="0.5">
          <div className={`${styles.modes} ${styles.sliderTagContainer}`}>
            <div
              onClick={() => {
                setMessageType("recipe");
                setMessages([]);
              }}
              className={
                messageType === "recipe"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }
            >
              <img
                src={messageType === "recipe" ? recipe : recipe_dark}
                alt="icon"
              />
              <div className={styles.be}>Create a recipe</div>
            </div>
            <div
              onClick={() => {
                setMessageType("food");
                setMessages([]);
              }}
              className={
                messageType === "food"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }
            >
              <img src={messageType === "food" ? food : food_dark} alt="icon" />
              <div className={styles.be}>Log meals</div>
            </div>

            <div
              onClick={() => {
                setMessageType("water");
                setMessages([]);
              }}
              className={
                messageType === "water"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }
              ref={waterRef}
            >
              <img
                src={messageType === "water" ? water : water_dark}
                alt="icon"
              />
              <div className={styles.be}>Log water</div>
            </div>

            <div
              onClick={() => {
                setMessageType("symptoms");
                setMessages([]);
              }}
              className={
                messageType === "symptoms"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }
              ref={symptomsRef}
            >
              <img
                src={messageType === "symptoms" ? symptoms : symptoms_dark}
                alt="icon"
              />
              <div className={styles.be}>Log symptoms</div>
            </div>

            <div
              onClick={() => {
                setMessageType("weight");
                setMessages([]);
              }}
              className={
                messageType === "weight"
                  ? `${styles.mode} ${styles.active}`
                  : styles.mode
              }
              ref={weightRef}
            >
              <img
                src={messageType === "weight" ? weight : weight_dark}
                alt="icon"
              />
              <div className={styles.be}>Log weight</div>
            </div>
          </div>
        </AnimateElement>
        <div className={styles.input}>
          <div className={styles.hold}>
            <textarea
              placeholder="Write"
              value={input}
              className={styles.inp}
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     e.preventDefault();
              //     if (!loading) {
              //       sendMessage(false);
              //     }
              //   }
              // }}
              onChange={(e) => [setInput(e.target.value)]}
              ref={textAreaRef}
              rows={1}
            />
            {!loading && (
              <img
                className={styles.send}
                src={sendIMG}
                alt="back"
                onClick={() => sendMessage(false)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GptChat;
