import { Client } from "@twilio/conversations";
import axios from "axios";
const adminChatUID = "admin@stovehealth.com";
//Twillo Conversation
const tokenEndPoint = window.location.hostname.includes("app.stovecook") ? "https://us-central1-stove-e851c.cloudfunctions.net/chatTokenProd" : "https://us-central1-stove-e851c.cloudfunctions.net/chatTokenDev";

export const fetchAccessToken = async (uid) => {
    const response = await axios.post(tokenEndPoint, {
        uid
    });
    return response.data;
}

export const refreshToken = async (client, uid) => {
    const response = await fetchAccessToken(uid);
    client.updateToken(response.token);
}

export const createTwilioClient = async (uid) => {
    const response = await fetchAccessToken(uid);
    const client = new Client(response.token, { reachabilityEnabled: true });
    client.on('tokenAboutToExpire', function () {
        refreshToken(client, uid);
    });
    client.on('tokenExpired', function () {
        refreshToken(client, uid);
    });
    return client
}

export const createNewClient = async (uid) => {
    const response = await fetchAccessToken(uid);
    const client = new Client(response.token, { reachabilityEnabled: true });
    await new Promise(resolve => {
        client.on("connectionStateChanged", (state) => {
            if (state === "connected") {
                resolve()
            }
        })
    })
    return client;
}


export const updateConfigurationService = async() => {
    // const response = await fetchAccessToken(adminChatUID);
}

export const getSubscribedConversations = async (client) => {
    const conversations = await client.getSubscribedConversations();
    return conversations;
}


export const updateConversationUser = async (client, user) => {
    try {
        const twilioUser = await client.getUser(user.uid);
        if (twilioUser.friendlyName !== user.displayName || twilioUser.attributes.name !== user.displayName || twilioUser.attributes.thumbnail !== user.photoURL || twilioUser.attributes.email !== user.email) {
            await twilioUser.updateFriendlyName(user.displayName);
            await twilioUser.updateAttributes({
                name: user.displayName,
                thumbnail: user.photoURL,
                email: user.email
            })
        }
    } catch {
        return;
    }
}

export const getPublicConversation = async (client, chat, uid) => {
    try {
        const conversation = await client.getConversationByUniqueName(chat.chatId);
        return conversation
    } catch (error) {
        if (error.message === "Not Found") {
            return "Forbidden"
        } else if (error.message === "Forbidden") {
            return "Forbidden"
        } else {
            throw new Error(error.message);
        }
    }
}


export const joinPuplicConversation = async (client, chat, uid) => {
    try {
        const conversation = await client.getConversationByUniqueName(chat.chatId);
        return conversation
    } catch (error) {
        if (error.message === "Not Found") {
            const adminClient = await createNewClient(adminChatUID);
            const conversation = await adminClient.createConversation({
                uniqueName: chat.chatId,
                friendlyName: chat.uniqueName,
                attributes: {
                    isPrivate: false
                }
            });
            await conversation.add(uid);
            await adminClient.shutdown();
            return joinPuplicConversation(client, chat, uid)
        } else if (error.message === "Forbidden") {
            try {
                const adminClient = await createNewClient(adminChatUID);
                const newConversation = await adminClient.getConversationByUniqueName(chat.chatId);
                await newConversation.add(uid);
                await adminClient.shutdown();
                return joinPuplicConversation(client, chat, uid)
            } catch (error) {
                throw new Error(error.message);
            }
        } else {
            throw new Error(error.message);
        }
    }
}


export const checkProfile = async (client, profile) => {
    try {
        const user = await client.getUser(profile.uid);
        return user;
    } catch (error) {
        if (error.message === "Not Found") {
            const newClient = await createNewClient(profile.uid);
            const user = await newClient.getUser(profile.uid);
            await newClient.shutdown();
            return user;
        } else {
            throw new Error(error.message);
        }
    }
}

export const createPrivateConversationWithProfile = async (client, user, profile) => {
    try {
        const conversation = await client.getConversationByUniqueName([user.uid, profile.uid].sort().join("-"));
        return conversation
    } catch (error) {
        if (error.message === "Not Found") {
            const conversation = await client.createConversation({
                uniqueName: [user.uid, profile.uid].sort().join("-"),
                friendlyName: `${user.displayName}-${profile.displayName}`,
                attributes: {
                    isPrivate: true,
                    [user.uid]: {
                        identity: user.uid,
                        thumbnail: user.photoURL,
                        name: user.displayName,
                        email: user.email
                    },
                    [profile.uid]: {
                        identity: profile.uid,
                        thumbnail: profile.photoURL,
                        name: profile.displayName,
                        email: profile.email
                    }
                }
            });
            await conversation.join();
            await conversation.add(profile.uid);
            return conversation;
        } else if (error.message === "Forbidden") {
            try {
                const newClient = await createNewClient(profile.uid);
                const newConversation = await newClient.getConversationByUniqueName([user.uid, profile.uid].sort().join("-"));
                await newConversation.add(user.uid);
                await newClient.shutdown();
                return createPrivateConversationWithProfile(client, user, profile);
            } catch (error) {
                throw new Error(error.message);
            }
        } else {
            throw new Error(error.message);
        }
    }
}

export const createPrivateConversation = async (client, user, participant) => {
    try {
        const conversation = await client.getConversationByUniqueName([user.uid, participant.identity].sort().join("-"));
        return conversation
    } catch (error) {
        if (error.message === "Not Found") {
            const conversation = await client.createConversation({
                uniqueName: [user.uid, participant.identity].sort().join("-"),
                friendlyName: `${user.displayName}-${participant.friendlyName}`,
                attributes: {
                    isPrivate: true,
                    reachabilityEnabled: true,
                    [user.uid]: {
                        identity: user.uid,
                        thumbnail: user.photoURL,
                        name: user.displayName,
                        email: user.email
                    },
                    [participant.identity]: {
                        identity: participant.identity,
                        thumbnail: participant.attributes.thumbnail,
                        name: participant.friendlyName,
                        email: participant.attributes.email
                    }
                }
            });
            await conversation.join();
            await conversation.add(participant.identity);
            return conversation;
        } else if (error.message === "Forbidden") {
            try {
                const newClient = await createNewClient(participant.identity);
                const newConversation = await newClient.getConversationByUniqueName([user.uid, participant.identity].sort().join("-"));
                await newConversation.add(user.uid);
                await newClient.shutdown();
                return createPrivateConversation(client, user, participant);
            } catch (error) {
                throw new Error(error.message);
            }
        } else {
            throw new Error(error.message);
        }
    }
}



export const getConversationsMessage = async (conversation) => {
    try {
        let messages = await conversation.getMessages();
        let enhancedMessages = await Promise.all(
            messages.items.map(async (message) => {
                if (message.type === "media") {
                    message["mediaUrl"] = await message.media.getContentTemporaryUrl();
                    return message;
                }
                return message;
            })
        );
        return [conversation, enhancedMessages]
    } catch (error) {
        //No access code
        if (error.code === 54007) {
            await conversation.join();
            let messages = await conversation.getMessages();
            let enhancedMessages = await Promise.all(
                messages.items.map(async (message) => {
                    if (message.type === "media") {
                        message["mediaUrl"] = await message.media.getContentTemporaryUrl();
                        return message;
                    }
                    return message;
                })
            );
            return [conversation, enhancedMessages]
        } else {
            throw new Error(error.message);
        }
    }
}



//Twillo sms
export const sendSMS = async (text) => {
    try {
        await axios.post("https://us-central1-stove-e851c.cloudfunctions.net/sendSms", {
            text
        });
    } catch (error) {
        console.log(error)
    }
}


export const sendMail = async (toEmail, subject, html) => {
    try {
        await axios.post("https://us-central1-stove-e851c.cloudfunctions.net/sendMail", {
            toEmail,
            subject,
            html
        });
    } catch (error) {
        console.log(error)
    }
}

export const sendRegisterMail = async (email, html) => {
    const response = await axios.post("https://us-central1-stove-e851c.cloudfunctions.net/sendRegisterMail", {
        email: email.toLowerCase(),
        html
    });
    return response;
}

export const verifyRegisterMail = async (email, pin, token) => {
    const response = await axios.post("https://us-central1-stove-e851c.cloudfunctions.net/verifyRegisterMail", {
        email: email.toLowerCase(),
        pin: Number(pin),
        token
    });
    return response;
}

//Chat sendBroadCastMessage/
const chatBroadCastEndPoint = window.location.hostname.includes("app.stovecook") ? "https://us-central1-stove-e851c.cloudfunctions.net/notificationMessageProd" : "https://us-central1-stove-e851c.cloudfunctions.net/notificationMessageDev";
export const sendBroadCastMessage = async (title, body, membersId) => {
    const response = await axios.post(chatBroadCastEndPoint, {
        title,
        body,
        membersId
    });
    return response;
}

