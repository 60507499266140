import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import cx from "classnames";

import Footer from "./../../layout/Footer/Footer";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import servingsIcon from "./../../assets/icons/servings.png";
import timeIcon from "./../../assets/icons/time.png";
import emptyBg from "./../../assets/empty-bg.png";

import RecipeWidgetList from "./../../components/RecipeWidgetList/RecipeWidgetList";
import { addRecipe } from "./../../state";
import styles from "./Recipes.module.scss";
import constant from "./../../constant";
import RecipeImage1 from "./../../assets/search-page/grocery-1.png";
import RecipeImage2 from "./../../assets/search-page/grocery-2.png";
import RecipeImage3 from "./../../assets/search-page/grocery-3.png";
import RecipeImage4 from "./../../assets/search-page/grocery-4.png";
import RecipeSlide from "./../../assets/recipes/recipe-slide1.png";

import vegan from "./../../assets/icons/categories/vegan.png";
import vegetarian from "./../../assets/icons/categories/vegetarian.png";
import glutenFree from "./../../assets/icons/categories/gluten-free.png";
import sugarFree from "./../../assets/icons/categories/sugar-free.png";
import lowSugar from "./../../assets/icons/categories/low-sugar.png";
import nutFree from "./../../assets/icons/categories/nut-free.png";
import pescetarianImg from "./../../assets/icons/categories/pescatarian.png";
import AnimateElement from "../../components/AnimateElement";

const recipeCategories = constant.recipeCategory.options;
const sliderImages = [
  vegan,
  vegetarian,
  glutenFree,
  pescetarianImg,
  sugarFree,
  lowSugar,
  nutFree,
];

const Recipes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const recipeState = useSelector((state) => state.recipes, shallowEqual);

  const [moreRecipesToShow, setMoreRecipesToShow] = useState(true);
  const [displayedRecipes, setDisplayedRecipes] = useState([]);

  useEffect(() => {
    setDisplayedRecipes(recipeState.recipes.slice(0, 6));
  }, [recipeState.recipes]);

  const fetchMoreData = () => {
    if (displayedRecipes.length >= recipeState.recipes.length) {
      setMoreRecipesToShow(false);
      return;
    }
    setTimeout(() => {
      setDisplayedRecipes((displayedRecipes) =>
        displayedRecipes.concat(
          recipeState.recipes.slice(
            displayedRecipes.length,
            displayedRecipes.length + 6
          )
        )
      );
    }, 1000);
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <div className={styles.inputContainer}>
            <svg
              className={styles.searchIcon}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M17.5 18L21.5423 22"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                cx="11.5"
                cy="11.5"
                r="8.5"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <InputBase
              fullWidth
              placeholder="What are you looking for?"
              inputProps={{ "aria-label": "search" }}
              onFocus={() => history.push("/search/text/recipes")}
            />
          </div>
          <IconButton
            size="small"
            className={styles.headerIcon}
            onClick={() => history.push("/account/recipesliked")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.87187 11.5983C1.79887 8.24832 3.05287 4.41932 6.56987 3.28632C8.41987 2.68932 10.4619 3.04132 11.9999 4.19832C13.4549 3.07332 15.5719 2.69332 17.4199 3.28632C20.9369 4.41932 22.1989 8.24832 21.1269 11.5983C19.4569 16.9083 11.9999 20.9983 11.9999 20.9983C11.9999 20.9983 4.59787 16.9703 2.87187 11.5983Z"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.4"
                d="M16 6.69995C17.07 7.04595 17.826 8.00095 17.917 9.12195"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <IconButton
            size="small"
            className={styles.headerIcon}
            onClick={() => history.push("/account/recipes")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 5.48999V20.49"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.4"
                d="M7.75 8.48999H5.5"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                opacity="0.4"
                d="M8.5 11.49H5.5"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>

        <AnimateElement duration={"0.5"}>
          <div className={styles.advert}>
            <Box
              className={cx(styles.content, styles.dietitian)}
              component="a"
              onClick={() => history.push("/gpt/recipe")}
            >
              <div className={styles.title}>
                Become a chef with AI-powered recipes and more!
              </div>
              {/* <div className={styles.subTitle}>Receive recipes tailored to<br />your personal situation</div> */}
              <img src={RecipeSlide} alt="promo" className={styles.image} />
            </Box>
          </div>
        </AnimateElement>

        <div className={styles.containerForYou}>
          <Typography
            classes={{ body1: styles.typographyHeader }}
            variant="body1"
            gutterBottom
          >
            For You
          </Typography>
        </div>

        <AnimateElement>
          <div className={styles.sliderCategoryContainer}>
            {recipeCategories.map((value, index) => (
              <div key={index} className={styles.sliderCategoryContent}>
                <ButtonBase
                  className={styles.sliderCategoryContentImage}
                  style={{ backgroundImage: `url(${sliderImages[index]})` }}
                />
                <span className={styles.sliderCategoryContentText}>
                  {value}
                </span>
              </div>
            ))}
          </div>
        </AnimateElement>

        <div className={styles.containerHighLight}>
          <Typography
            classes={{ body1: styles.typographyHeader }}
            variant="body1"
            gutterBottom
          >
            Today Highlights
          </Typography>

          {recipeState.recipesIsLoading === false &&
            recipeState.recipesIsError === true && (
              <MessageBox text="Failed to load today highlights" />
            )}

          {recipeState.recipesIsLoading === true &&
            recipeState.recipesIsError === false && (
              <div className="px-3">
                <Spinner variant="rect" height={140} />
                <Spinner variant="text" height={40} />
                <Spinner variant="text" height={40} />
              </div>
            )}

          <div className={styles.slider}>
            {recipeState.recipes.slice(0, 6).map((recipe) => (
              <AnimateElement duration={"0.5"}>
                <Card
                  key={recipe.recipeId}
                  component="div"
                  className={styles.card}
                  onClick={() => history.push(`/recipe/${recipe.recipeId}`)}
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        recipe.thumbnail
                          ? recipe.thumbnail
                          : "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262"
                      })`,
                    }}
                    className={styles.img}
                  />
                  <div className={styles.titleContainer}>
                    <Avatar
                      src={recipe.chef && recipe.chef.pic + "?tr=w-36,h-36"}
                      className={styles.chefImg}
                      variant="circle"
                    />
                    <Typography
                      classes={{ body1: styles.chefName }}
                      variant="body1"
                      display="inline"
                      noWrap
                    >
                      {recipe.chef && recipe.chef.firstName}{" "}
                      {recipe.chef && recipe.chef.lastName}
                    </Typography>
                  </div>
                  <Typography classes={{ body1: styles.title }} variant="body1">
                    {recipe.title}
                  </Typography>
                  <div className={styles.line} />
                  <div className={styles.footer}>
                    <div className="d-flex align-items-center mr-4">
                      <Avatar
                        alt="servings"
                        sizes="small"
                        variant="square"
                        src={servingsIcon}
                        className={styles.img}
                      />
                      <span className={styles.footerText}>
                        {recipe.servings}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <Avatar
                        alt="time"
                        sizes="small"
                        variant="square"
                        src={timeIcon}
                        className={styles.img}
                      />{" "}
                      <span className={styles.footerText}>
                        {recipe.readyIn}
                      </span>
                    </div>
                  </div>
                </Card>
              </AnimateElement>
            ))}
          </div>
        </div>

        <div className={styles.containerPopular}>
          <Typography
            classes={{ body1: styles.typographyHeader }}
            variant="body1"
            gutterBottom
          >
            Most Popular
          </Typography>
          {recipeState.recipesIsLoading === false &&
            recipeState.recipesIsError === true && (
              <MessageBox text="Failed to load popular" />
            )}
          {recipeState.recipesIsLoading === true &&
            recipeState.recipesIsError === false && (
              <>
                <Spinner variant="rect" height={140} />
                <Spinner variant="text" height={40} />
                <Spinner variant="text" height={40} />
              </>
            )}
          {recipeState.recipesIsLoading === false &&
            recipeState.recipesIsError === false && (
              <InfiniteScroll
                hasMore={moreRecipesToShow}
                next={fetchMoreData}
                dataLength={displayedRecipes.length}
                loader={
                  <div className="p-5 text-center">
                    <CircularProgress />
                  </div>
                }
                endMessage={
                  <div className="p-5 text-center">
                    Yay! You have seen it all
                  </div>
                }
              >
                <RecipeWidgetList recipes={displayedRecipes} />
              </InfiniteScroll>
            )}
        </div>
        <Fab
          className={styles.fab}
          aria-label="add"
          onClick={() => {
            dispatch(addRecipe());
            history.push("/recipeupload");
          }}
        >
          <AddIcon />
        </Fab>
      </div>
      <Footer />
    </>
  );
};

export default Recipes;
