import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom"
import axios from "axios";
import {
    CardElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';

import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import CustomInput from "../../components/CustomInput/CustomInput";
import Congratulations from "./components/Congratulations/Congratulations";
import StoveLogo from "./../../assets/stove-green-logo.svg";

import { toast } from "./../../state";
import { getUserData, getSingleGrocery, addReferrerTransactions } from "./../../services/firebase";
import styles from "./Redeem.module.scss";



const REDEEM = "REDEEM";
const CONGRATULATIONS = "CONGRATULATIONS";

const colors = [
    {
        textColor: "#656462",
        backgroundCenter: "rgba(192, 187, 184, 0.6)",
        backgroundColor: "rgb(192, 187, 184)",
    },
    {
        textColor: "#005DB5",
        backgroundCenter: "rgba(1, 114, 190, 0.6)",
        backgroundColor: "rgb(1, 114, 190)",
    },
    {
        textColor: "#656714",
        backgroundCenter: "rgba(215, 198, 84, 0.6)",
        backgroundColor: "rgb(215, 198, 84)",
    },
    {
        textColor: "#656714",
        backgroundCenter: "rgba(255, 217, 102, 0.6)",
        backgroundColor: "rgb(255, 217, 102)",
    },
    {
        textColor: "#CD091D",
        backgroundCenter: "rgba(178, 11, 27, 0.6)",
        backgroundColor: "rgb(178, 11, 27)",
    },
    {
        textColor: "#D5AC7F",
        backgroundCenter: "rgba(227, 198, 124, 0.6)",
        backgroundColor: "rgb(227, 198, 124)",
    },
    {
        textColor: "#DF6701",
        backgroundCenter: "rgba(243, 155, 69)",
        backgroundColor: "rgb(243, 155, 69)",
    }
]


const CARD_OPTIONS = {
    style: {
        base: {
            iconColor: "rgb(30,32,35, 1)",
            color: "#1e2023",
            fontWeight: "normal",
            fontFamily: "Epilogue, Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883",
            },
            "::placeholder": {
                color: "rgb(30,32,35, 0.5)",
            },
            ":focus": {
                fontWeight: 600,
            },
        },
        invalid: {
            iconColor: "#ff0000",
            color: "#ff1919",
        },
    },
};

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

const getTotalDiscount = (cartItem) => {
    let discount
    if (cartItem.quantity >= cartItem.discountQuantity) {
        discount = (cartItem.price - cartItem.discountPrice) * cartItem.quantity;
    } else {
        discount = 0;
    }
    return discount.toFixed(2);
}


const getPrice = (cartItem) => {
    if (cartItem.quantity >= cartItem.discountQuantity) {
        return cartItem.discountPrice.toFixed(2);
    } else {
        return cartItem.price.toFixed(2);
    }
}

const getTotalPrice = (cartItem) => {
    let totalPrice = 0
    if (cartItem.quantity >= cartItem.discountQuantity) {
        totalPrice = cartItem.discountPrice * cartItem.quantity;
    } else {
        totalPrice = cartItem.price * cartItem.quantity;
    }
    return totalPrice.toFixed(2);
}





const Redeem = () => {
    const { userId, groceryId, quantity } = useParams();
    const dispatch = useDispatch();
    const fixedColor = useMemo(() => colors[Math.floor(Math.random() * colors.length)], []);

    const stripe = useStripe();
    const elements = useElements();

    const [active, setActive] = useState(REDEEM);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");


    const [cardComplete, setCardComplete] = useState(false);
    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [processing, setProcessing] = useState(false);

    const [referrer, setReferrer] = useState(null);
    const [grocery, setGrocery] = useState(null);



    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const [user, grocery] = await Promise.all([getUserData(userId), getSingleGrocery(groceryId)]);
                setReferrer(user);
                setGrocery(grocery);
                setIsLoading(false);
            } catch {
                setIsLoading(false);
            }
        })();
    }, []);



    const confirmAndPayButtonClick = async (price) => {
        if (!stripe || !elements) {
            return;
        }

        if (!name) {
            dispatch(toast({ message: "Name fieid is required" }));
            return;
        }

        if (!email) {
            dispatch(toast({ message: "Enter Your email address" }));
            return;
        }

        if (!validateEmail(email)) {
            dispatch(toast({ message: "Enter a valid email address" }));
            return;
        }

        if (!address) {
            dispatch(toast({ message: "Enter shipping address" }));
            return;
        }

        setError(null);
        setProcessing(true);

        try {
            const cardElement = elements.getElement(CardElement);
            const response = await axios.post(`https://us-central1-stove-e851c.cloudfunctions.net/stripePayment${window.location.hostname.includes("app.stovecook") ? "Prod" : "Dev"}`, {
                amount: (Number(price) * 100)
            });
            let paymentIntent = response.data;
            const result = await stripe.confirmCardPayment(paymentIntent.client_secret,
                {
                    receipt_email: email,
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: name,
                            email: email
                        },
                    }
                }
            );
            if (result.error) {
                setError(result.error);
                setProcessing(false);
            } else {
                if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                    await addReferrerTransactions(result.paymentIntent.id, referrer.uid, email, address, price, [{ ...grocery, quantity }], name);
                    setProcessing(false);
                    setActive(CONGRATULATIONS);
                }
            }
        } catch (error) {
            setError(error);
            setProcessing(false);
        }
    }



    return (
        <>
            {active === REDEEM && (
                <div className={styles.homePage}>

                    <img alt="Stove Logo" src={StoveLogo} className={styles.img} />

                    {isLoading === true && (
                        <div className="pt-5 px-4">
                            <div className="pt-3" />
                            <Spinner variant="rect" height={140} />
                            <Spinner variant="text" height={40} />
                            <Spinner variant="text" height={40} />
                        </div>
                    )}

                    {isLoading === false && (!referrer || !grocery || !isNormalInteger(quantity)) && (
                        <MessageBox text="Invalid link" />
                    )}

                    {isLoading === false && referrer && grocery && isNormalInteger(quantity) && (
                        <>
                            <div className={styles.header} style={{ background: `radial-gradient(51.33% 119.29% at 49.87% 50%, ${fixedColor.backgroundCenter} 0%, ${fixedColor.backgroundColor} 100%)` }}>
                                <img className={styles.headerImage} src={grocery.thumbnail} alt="Grocery" />
                            </div>

                            <div className={styles.content}>
                                <div className="d-flex mb-4">
                                    <div className={styles.leftPan}>
                                        <Typography classes={{ body1: styles.desc }} style={{ color: fixedColor.textColor }} variant="body1" display="block">{grocery.description}</Typography>
                                        <Typography classes={{ body1: styles.name }} variant="body1" display="block">{grocery.name}</Typography>
                                        <Typography classes={{ body1: styles.size }} variant="body1" display="block">{grocery.size}</Typography>
                                    </div>
                                    <div className={styles.rightPan}>
                                        <Typography classes={{ body1: styles.rightPanText }} variant="body1" display="block" noWrap>£{getPrice({ ...grocery, quantity })}</Typography>
                                        <Typography classes={{ body1: styles.rightPanText }} variant="body1" display="block" noWrap>Quantity {quantity}</Typography>
                                        <Typography classes={{ body1: styles.rightPanText }} variant="body1" display="block" noWrap>Total £{getTotalPrice({ ...grocery, quantity })}</Typography>
                                    </div>
                                </div>


                                <div className={styles.line} />

                                <Typography className={styles.headerText} variant="body1" display="block">About the product</Typography>
                                <Typography className={styles.aboutProductText} variant="body1" display="block">
                                    {grocery.about}
                                </Typography>
                            </div>


                            <div className="px-4 pt-4 pb-2">
                                <div className={styles.shippingDetailsContainer}>
                                    <div className={styles.shippingDetails}>
                                        Shipping information
                            </div>
                                    <div>
                                        <CustomInput
                                            fullWidth
                                            multiline
                                            rows={2}
                                            label="Enter name"
                                            placeholder="Enter name"
                                            value={name}
                                            onChange={event => setName(event.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <CustomInput
                                            fullWidth
                                            multiline
                                            rows={2}
                                            label="Enter email"
                                            placeholder="Enter email"
                                            value={email}
                                            onChange={event => setEmail(event.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <CustomInput
                                            fullWidth
                                            multiline
                                            rows={2}
                                            label="Enter shipping address"
                                            placeholder="Enter shipping address"
                                            value={address}
                                            onChange={event => setAddress(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="p-4">
                                <div className={styles.paymentContainer}>
                                    <div className={styles.paymentText}>
                                        Payment
                            </div>
                                    <div>
                                        <CardElement
                                            options={CARD_OPTIONS}
                                            onChange={event => {
                                                setError(event.error);
                                                setCardComplete(event.complete);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {error && <div className={styles.errorText}>{error.message}</div>}


                            <div className={styles.subtotalContainer}>
                                <span className={styles.totalText}>Subtotal</span>
                                <span className={styles.totalPriceText}>£{getTotalPrice({ ...grocery, quantity })}</span>
                            </div>
                            <div className={styles.shippingContainer}>
                                <span className={styles.totalText}>Shipping</span>
                                <span className={styles.totalPriceText}>Free</span>
                            </div>
                            <div className={styles.totalContainer}>
                                <span className={styles.totalText}>Total</span>
                                <span className={styles.totalPriceText}>£{getTotalPrice({ ...grocery, quantity })}</span>
                            </div>


                            <ButtonBase
                                onClick={() => confirmAndPayButtonClick(getTotalPrice({ ...grocery, quantity }))}
                                disabled={!stripe || !elements || !cardComplete || processing}
                                className={styles.confirmButton}
                            >{processing ? <CircularProgress color="inherit" size="24px" /> : `Confirm & Pay  £${getTotalPrice({ ...grocery, quantity })}`}</ButtonBase>
                        </>
                    )}
                </div>
            )}


            {active === CONGRATULATIONS && <Congratulations />}
        </>


    )
}

export default Redeem;