import React from "react";
import {
    useHistory
} from "react-router-dom";
import ButtonBase from '@material-ui/core/ButtonBase';

import notMessageImg from "./../../../../assets/no-message.png";
import styles from "./NotFound.module.scss"

const NotFound = (props) => {
    const { url } = props;
    const history = useHistory();
    const handleNavigateToSearch = () => {
        history.push(`${url}/search`)
    };
    return (
        <div className={styles.container} >
            <img alt="Not found" src={notMessageImg} className={styles.img} />
            <p className={styles.header}>Add friends, or discover public groups</p>
            <p className={styles.body}>Invite your best friends, your mum or your partner and share recipes, or the cart.You can also join public groups!</p>
            <ButtonBase onClick={handleNavigateToSearch} className={styles.searchButtonGroup}>{"Discover public groups >"}</ButtonBase>
        </div>
    )
}


export default NotFound;