import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { ImageListItemBar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import backIcon from "./../../../../assets/icons/back.png";
import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";
import Dialog from "../../../../components/Dialog/Dialog";
import {
  addRecipe,
  editRecipe,
  removeRecipeFromRedux,
  toast,
} from "../../../../state";
import { deleteRecipe } from "../../../../services/firebase";

import styles from "./Recipes.module.scss";
import AnimateElement from "../../../../components/AnimateElement";

const Recipes = (props) => {
  const { userState } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeRecipeId, setActiveRecipeId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async (recipeId) => {
    try {
      setIsDeleting(true);
      await deleteRecipe(recipeId);
      dispatch(removeRecipeFromRedux(recipeId));
      dispatch(toast({ message: "Recipe deleted successfully" }));
      setIsDeleting(false);
      setActiveRecipeId(null);
    } catch {
      setIsDeleting(false);
      dispatch(toast({ message: "Failed to delete recipe" }));
    }
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <IconButton
            size="small"
            className="mr-auto"
            onClick={() => history.goBack()}
          >
            <Avatar
              alt="back"
              sizes="small"
              variant="square"
              src={backIcon}
              className={styles.arrowback}
            />
          </IconButton>
        </div>
        <h2 className={styles.title}>Your Recipes</h2>

        <div className="px-4 pt-4">
          {userState.userRecipesIsLoading === true &&
            userState.userRecipesIsError === false && (
              <Spinner variant="rect" height={140} />
            )}
          {userState.userRecipesIsLoading === false &&
            userState.userRecipesIsError === true && (
              <MessageBox text="Failed to load your recipes" />
            )}
          {userState.userRecipesIsLoading === false &&
            userState.userRecipesIsError === false &&
            userState.userRecipes.length === 0 && (
              <MessageBox text="No recipe uploaded yet" />
            )}
          {userState.userRecipesIsLoading === false &&
            userState.userRecipesIsError === false && (
              <GridList cols={2} spacing={10} cellHeight={208} component="div">
                {userState.userRecipes.map((recipe) => (
                  <AnimateElement duration={"0.5"}>
                    <GridListTile
                      key={recipe.recipeId}
                      classes={{ tile: styles.card }}
                      onClick={() => history.push(`/recipe/${recipe.recipeId}`)}
                      className={styles.hover}
                    >
                      <Avatar
                        alt="Chef"
                        variant="circular"
                        src={recipe.chef.pic}
                        className={styles.avater}
                      />

                      <div className={styles.actionContainerEdit}>
                        <div className={styles.iconButton}>
                          <IconButton
                            className={styles.icon}
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              dispatch(editRecipe(recipe));
                              history.push("/recipeupload");
                            }}
                          >
                            <EditIcon
                              className={styles.icon}
                              fontSize="small"
                            />
                          </IconButton>
                        </div>
                        <div className={styles.iconButton}>
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              setActiveRecipeId(recipe.recipeId);
                            }}
                          >
                            <DeleteIcon
                              className={styles.icon}
                              fontSize="small"
                            />
                          </IconButton>
                        </div>
                      </div>
                      <img
                        src={
                          recipe.thumbnail ||
                          "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262"
                        }
                        alt="Recipe"
                      />
                      <GridListTileBar
                        title={recipe.title}
                        subtitle={format(
                          new Date(recipe.createdOn),
                          "MM/dd/yyyy"
                        )}
                      />
                    </GridListTile>
                  </AnimateElement>
                ))}
              </GridList>
            )}
        </div>
      </div>

      <Fab
        className={styles.fab}
        aria-label="add"
        onClick={() => {
          dispatch(addRecipe());
          history.push("/recipeupload");
        }}
      >
        <AddIcon />
      </Fab>

      <Dialog
        open={Boolean(activeRecipeId)}
        message="Recipe will be deleted"
        onCancel={() => setActiveRecipeId(null)}
        onConfirm={() => onDelete(activeRecipeId)}
        isLoading={isDeleting}
        isLoadingText="Deleting..."
      />
    </>
  );
};

export default Recipes;
