import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import cx from "classnames";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import ButtonBase from "@material-ui/core/ButtonBase";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import StarRateIcon from "@material-ui/icons/StarRate";
import FavoriteIcon from "@material-ui/icons/Favorite";
import EditIcon from "@material-ui/icons/Edit";

import RecipeWidgetList from "../../components/RecipeWidgetList/RecipeWidgetList";
import { GroceryWidgetListAsyc } from "../../components/GroceryWidgetList/GroceryWidgetList";
import RecipeSteps from "../../components/RecipeSteps/RecipeSteps";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import NutrientsBar from "../../components/NutrientsBar/NutrientsBar";

import backIcon from "./../../assets/icons/back.png";
import heartIcon from "./../../assets/icons/heart.png";
import shareIcon from "./../../assets/icons/share.png";
import recepeIcon from "./../../assets/icons/recipes-red.png";
import clockIcon from "./../../assets/icons/clock-red.png";
import potIcon from "./../../assets/icons/pot-red.png";
import emptyBg from "./../../assets/empty-bg.png";

import {
  getRecipe,
  likeRecipeAction,
  unlikeRecipeAction,
  toast,
  editRecipe,
} from "../../state";
import { likeRecipe, unlikeRecipe } from "./../../services/firebase";
import styles from "./Recipe.module.scss";
import AnimateElement from "../../components/AnimateElement";

const Recipe = () => {
  const { recipeId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [number, setNumber] = useState(0);
  const [stepIsOpen, setStepIsOpen] = useState(false);
  const [quotient, setQuotient] = useState(1);
  const userState = useSelector((state) => state.user, shallowEqual);
  const recipeState = useSelector((state) => state.recipes, shallowEqual);
  const recipe = recipeState.recipe;

  const [moreRecipesToShow, setMoreRecipesToShow] = useState(true);
  const [displayedRecipes, setDisplayedRecipes] = useState([]);

  useEffect(() => {
    dispatch(getRecipe(recipeId));
  }, [recipeId]);

  useEffect(() => {
    if (recipe) {
      setNumber(Number(recipe.servings));
    }
  }, [recipe]);

  const like = () => {
    if (userState.user) {
      if (!recipe) return;
      dispatch(likeRecipeAction(userState.user.uid));
      likeRecipe(recipe.recipeId, userState.user.uid);
    } else {
      dispatch(toast({ message: "Please login to add recipe to favourite" }));
    }
  };

  const unlike = () => {
    if (userState.user) {
      if (!recipe) return;
      dispatch(unlikeRecipeAction(userState.user.uid));
      unlikeRecipe(recipe.recipeId, userState.user.uid);
    } else {
      dispatch(toast({ message: "Please login to add recipe to favourite" }));
    }
  };

  const shareRecipe = (url) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Recipe",
          url: url,
        })
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const dummyInput = document.createElement("input");
      const text = url;
      document.body.appendChild(dummyInput);
      dummyInput.value = text;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);
      dispatch(toast({ message: "Copied to clipboard" }));
    }
  };

  const decrease = () => {
    let newNumber = number - Number(recipe.servings);
    if (newNumber >= 1) {
      setNumber(newNumber);
    }
  };

  const increase = () => {
    let newNumber = number + Number(recipe.servings);
    setNumber(newNumber);
  };

  useEffect(() => {
    setDisplayedRecipes(recipeState.recipes.slice(0, 6));
  }, [recipeState.recipes]);

  const fetchMoreData = () => {
    if (displayedRecipes.length >= recipeState.recipes.length) {
      setMoreRecipesToShow(false);
      return;
    }
    setTimeout(() => {
      setDisplayedRecipes((displayedRecipes) =>
        displayedRecipes.concat(
          recipeState.recipes.slice(
            displayedRecipes.length,
            displayedRecipes.length + 6
          )
        )
      );
    }, 1000);
  };

  useEffect(() => {
    if (recipe?.servings) {
      setQuotient(Number(number / Number(recipe.servings)));
    }
  }, [number, recipe]);

  return (
    <>
      {stepIsOpen ? (
        <RecipeSteps
          setStepIsOpen={setStepIsOpen}
          steps={recipe.steps}
          recipe={recipe}
        />
      ) : (
        <div className={styles.page}>
          <div className={styles.header}>
            <IconButton
              size="small"
              className="mr-auto"
              onClick={() => history.goBack()}
            >
              <AnimateElement duration={"0.5"}>
                <Avatar
                  alt="back"
                  sizes="small"
                  variant="square"
                  src={backIcon}
                  className={styles.arrowback}
                />
              </AnimateElement>
            </IconButton>
            <IconButton
              size="small"
              className="mr-2"
              onClick={() => {
                if (
                  recipeState.recipeIsLoading === false &&
                  recipe &&
                  recipe.likes &&
                  userState.user &&
                  userState.user.uid &&
                  recipe.likes.includes(userState.user.uid)
                ) {
                  unlike();
                } else {
                  like();
                }
              }}
            >
              {recipeState.recipeIsLoading === false &&
              recipe &&
              recipe.likes &&
              userState.user &&
              userState.user.uid &&
              recipe.likes.includes(userState.user.uid) ? (
                <FavoriteIcon color="primary" className={styles.heartIcon} />
              ) : (
                <Avatar
                  alt="like"
                  variant="square"
                  src={heartIcon}
                  className={styles.heartIcon}
                />
              )}
            </IconButton>
            <IconButton
              size="small"
              onClick={() => shareRecipe(window.location.href)}
            >
              <Avatar
                alt="share"
                variant="square"
                src={shareIcon}
                className={styles.shareIcon}
              />
            </IconButton>
          </div>

          {recipeState.recipeIsLoading === true &&
            recipeState.recipeIsError === false && (
              <div className="px-3">
                <Spinner variant="rect" height={140} />
                <Spinner variant="text" height={40} />
                <Spinner variant="text" height={40} />
              </div>
            )}

          {recipeState.recipeIsLoading === false &&
            recipeState.recipeIsError === true && (
              <MessageBox text="Failed to load today highlights" />
            )}

          {recipeState.recipeIsLoading === false &&
            recipeState.recipeIsError === false &&
            !recipe && <MessageBox text="Recipe not found" />}

          {recipeState.recipeIsLoading === false &&
            recipeState.recipeIsError === false &&
            recipe && (
              <>
                <AnimateElement duration={"0.5"}>
                  <div
                    className={styles.headerImgContainer}
                    style={{
                      backgroundImage: `url(${
                        recipe.thumbnail ? recipe.thumbnail : emptyBg
                      })`,
                    }}
                  >
                    <ButtonBase
                      className={styles.letsCook}
                      onClick={() => setStepIsOpen(true)}
                    >
                      Let’s cook!
                    </ButtonBase>
                  </div>
                  <div className={styles.headerBottom}>
                    {userState.user.uid === recipe.chefId && (
                      <div className={styles.iconButton}>
                        <IconButton
                          className={styles.icon}
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(editRecipe(recipe));
                            history.push("/recipeupload");
                          }}
                        >
                          <EditIcon className={styles.icon} color="primary" />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center px-4">
                    <span className={styles.course}>FIRST COURSE</span>
                    {/* <IconButton className="p-0 mr-2" size="small"><StarRateIcon className={styles.starIcon} /></IconButton>
                                    <span className={styles.rating}>4.2</span>
                                    <span className={styles.vote}>{"Vote >"}</span> */}
                  </div>
                  <p className={styles.title}>{recipe.title}</p>
                  <div className="d-flex align-items-center px-4">
                    <IconButton size="small" className="mr-2">
                      <Avatar
                        alt="like"
                        variant="circle"
                        src={recipe.chef && recipe.chef.pic}
                        className={styles.chefIcon}
                      />
                    </IconButton>
                    <span className={styles.chefName}>
                      {recipe.chef && recipe.chef.firstName}{" "}
                      {recipe.chef && recipe.chef.lastName}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center px-4 py-4">
                    <div className={styles.levelContain}>
                      <Avatar
                        alt="like"
                        variant="square"
                        src={recepeIcon}
                        className={styles.icon}
                      />
                      <span className={styles.firstText}>Difficulty</span>
                      <span className={styles.secondText}>
                        {recipe.difficulty}
                      </span>
                    </div>
                    <div className={styles.levelContain}>
                      <Avatar
                        alt="like"
                        variant="square"
                        src={clockIcon}
                        className={styles.icon}
                      />
                      <span className={styles.firstText}>Ready in</span>
                      <span className={styles.secondText}>
                        {recipe.readyIn}
                      </span>
                    </div>
                    <div className={styles.levelContain}>
                      <Avatar
                        alt="like"
                        variant="square"
                        src={potIcon}
                        className={styles.icon}
                      />
                      <span className={styles.firstText}>Cost</span>
                      <span className={styles.secondText}>{recipe.cost}</span>
                    </div>
                  </div>

                  <div className={styles.line} />
                  {recipe.groceriesId.length > 0 && (
                    <>
                      <div className="d-flex justify-content-between align-items-center px-4 mb-4">
                        <div className={styles.calulatorTextContain}>
                          <span className={styles.firstText}>Ingredients</span>
                          <span className={styles.secondText}>
                            How many servings?
                          </span>
                        </div>
                        <div className={styles.calculator}>
                          <span className={styles.button} onClick={decrease}>
                            <IconButton size="small" color="inherit">
                              <RemoveIcon fontSize="small" color="inherit" />
                            </IconButton>
                          </span>
                          <span className={styles.number}>{number}</span>
                          <span className={styles.button} onClick={increase}>
                            <IconButton size="small" color="inherit">
                              <AddIcon fontSize="small" color="inherit" />
                            </IconButton>
                          </span>
                        </div>
                      </div>

                      <div>
                        <GroceryWidgetListAsyc
                          groceriesId={recipe.groceriesId}
                          footer="recipe"
                        />
                      </div>

                      <div className="px-4">
                        <div className={styles.lineHalf} />
                      </div>
                    </>
                  )}
                  {recipe?.nutriments && (
                    <div className="p-4">
                      <NutrientsBar
                        selectedItem={recipe}
                        nutrients={
                          userState.user?.nutrients?.show
                            ? userState.user?.nutrients
                            : { show: false, carbs: 0, fat: 0, proteins: 0, calories: 0 }
                        }
                      />
                    </div>
                  )}
                  {/* <div className={styles.line} /> */}
                  <div className="px-4">
                    {recipe.ingredients &&
                      recipe.ingredients.map &&
                      recipe.ingredients.map((ingredient, index) => (
                        <div key={index}>
                          {index !== 0 && <div className={styles.lineHalf} />}
                          <div className={styles.ingredientListBoldText}>
                            {ingredient.name}
                          </div>
                          <div className={styles.ingredientListListText}>
                            {quotient * Number(ingredient.quantity)}{" "}
                            {ingredient.unit}
                          </div>
                        </div>
                      ))}
                    <div className={styles.lineHalf} />
                  </div>

                  <div className="p-4">
                    <Typography
                      className={styles.headerText}
                      variant="body1"
                      display="block"
                    >
                      Recipe’s categories
                    </Typography>
                    <div>
                      {recipe.tags &&
                        recipe.tags.map &&
                        recipe.tags
                          .filter((tag) => tag !== "vegan")
                          .map((tag) => (
                            <Chip
                              key={tag}
                              label={tag}
                              className={styles.chip}
                            />
                          ))}
                    </div>
                  </div>
                </AnimateElement>
              </>
            )}

          {/* <div className={cx("px-4 py-5", styles.friendsRecipeContainer)}>
                            <Typography className={styles.headerText} variant="body1" display="block">Made by your friends</Typography>
                            <div className={styles.cardContainer}>
                                {recipeState.recipes.map(recipe => (
                                    <div key={recipe.recipeId} className={styles.card}>
                                        <span className={styles.cardHeader}>Alexander Miller</span>
                                        <div className={styles.image} style={{ backgroundImage: `url(${recipe.thumbnail})` }} />
                                        <span className={styles.date}>Today, 12:41 PM</span>
                                        <span className={styles.description}>Assuming salt and pepper are pantry staples, this recipe can really be pullet of milk</span>
                                    </div>
                                ))}
                            </div>
                            <ButtonBase className={styles.makePictureButton}>Take a picture of your dish</ButtonBase>
                        </div> */}

          <div className={cx("px-4 py-5", styles.moveUp)}>
            <Typography
              className={styles.headerText}
              variant="body1"
              display="block"
            >
              Similar recipes
            </Typography>
            {recipeState.recipesIsLoading === false &&
              recipeState.recipesIsError === true && (
                <MessageBox text="Failed to load similar recipes" />
              )}
            {recipeState.recipesIsLoading === true &&
              recipeState.recipesIsError === false && (
                <>
                  <Spinner variant="rect" height={140} />
                  <Spinner variant="text" height={40} />
                  <Spinner variant="text" height={40} />
                </>
              )}
            {recipeState.recipesIsLoading === false &&
              recipeState.recipesIsError === false && (
                <AnimateElement>
                  <InfiniteScroll
                    hasMore={moreRecipesToShow}
                    next={fetchMoreData}
                    dataLength={displayedRecipes.length}
                    loader={
                      <div className="p-5 text-center">
                        <CircularProgress />
                      </div>
                    }
                    endMessage={
                      <div className="p-5 text-center">
                        Yay! You have seen it all
                      </div>
                    }
                  >
                    <RecipeWidgetList
                      recipes={displayedRecipes.filter(
                        (recipe) => recipe.recipeId !== recipeId
                      )}
                    />
                  </InfiniteScroll>
                </AnimateElement>
              )}
          </div>
          {/* <ButtonBase className={styles.seeAllButton}>See all</ButtonBase> */}
        </div>
      )}
    </>
  );
};

export default Recipe;
