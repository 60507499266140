import React from "react";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import styles from "./CustomSelect.module.scss";


const CustomSelect = (props) => {
    const { placeholder, multiple, ...otherProps } = props;
    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            label={placeholder}
            className={styles.input}
            InputLabelProps={{
                className: styles.label
            }}
            InputProps={{
                classes: {
                    underline: styles.underLine
                }
            }}
            SelectProps={{
                className: styles.select,
                IconComponent: ArrowBackIosOutlinedIcon,
                classes: { select: styles.select, icon: styles.dropDownIcon },
                multiple: multiple
            }}
            variant="standard"
            {...otherProps}
        />
    )
}


export default CustomSelect;