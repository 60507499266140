import React from "react";
import propTypes from 'prop-types';


import notFoundRecipeImg from "./../../../../assets/not-found-recipe.png";
import notFoundGroceryImg from "./../../../../assets/not-found-grocery.png";
import styles from "./NotFound.module.scss";


const NotFound = (props) => {
    const { tag, typing, activeText } = props;
    return (
        <>
            {tag && (
                <div className={styles.container} >
                    <img alt="Not found" src={notFoundRecipeImg} className={styles.img} />
                    <p className={styles.header}>Sorry, no {activeText} found</p>
                </div>
            )}
            {typing && (
                <div className={styles.container} >
                    <img alt="Not found" src={notFoundGroceryImg} className={styles.img} />
                    <p className={styles.header}>Ops, no {activeText} found</p>
                    <p className={styles.body}>Check your spelling or try alternatives to find what you're looking for.</p>
                </div>
            )}
        </>
    )
}

NotFound.propTypes = {
    activeText: propTypes.oneOf(["grocery", "recipe", "article"]).isRequired,
    tag: propTypes.bool,
    typing: propTypes.bool
}

export default NotFound;