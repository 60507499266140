import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import cx from "classnames";
import { format } from "date-fns";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import FavoriteIcon from "@material-ui/icons/Favorite";

import RecipeWidgetList from "../../components/RecipeWidgetList/RecipeWidgetList";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";

import backIcon from "./../../assets/icons/back-light.png";
import heartIcon from "./../../assets/icons/heart-light.png";
import shareIcon from "./../../assets/icons/share-light.png";
import deliveryIcon from "./../../assets/delivery.svg";
import clockIcon from "./../../assets/timeClock.svg";
import stoveIcon from "./../../assets/stoveIcon.svg";

import {
  getGrocery,
  updateCart,
  likeGroceryAction,
  unlikeGroceryAction,
  toast,
} from "../../state";
import { likeGrocery, unlikeGrocery } from "./../../services/firebase";
import styles from "./Grocery.module.scss";

import { calculateDiscount } from "../../utilities";
import AnimateElement from "../../components/AnimateElement";

const colors = [
  {
    textColor: "#656462",
    backgroundCenter: "rgba(192, 187, 184, 0.6)",
    backgroundColor: "rgb(192, 187, 184)",
  },
  {
    textColor: "#005DB5",
    backgroundCenter: "rgba(1, 114, 190, 0.6)",
    backgroundColor: "rgb(1, 114, 190)",
  },
  {
    textColor: "#656714",
    backgroundCenter: "rgba(215, 198, 84, 0.6)",
    backgroundColor: "rgb(215, 198, 84)",
  },
  {
    textColor: "#656714",
    backgroundCenter: "rgba(255, 217, 102, 0.6)",
    backgroundColor: "rgb(255, 217, 102)",
  },
  {
    textColor: "#CD091D",
    backgroundCenter: "rgba(178, 11, 27, 0.6)",
    backgroundColor: "rgb(178, 11, 27)",
  },
  {
    textColor: "#D5AC7F",
    backgroundCenter: "rgba(227, 198, 124, 0.6)",
    backgroundColor: "rgb(227, 198, 124)",
  },
  {
    textColor: "#DF6701",
    backgroundCenter: "rgba(243, 155, 69)",
    backgroundColor: "rgb(243, 155, 69)",
  },
];

const getDiscountPercentage = (discountPrice = 1, price = 1) => {
  const discountAmount = discountPrice - price;
  const discountPercentage = (discountAmount / discountPrice) * 100;
  return discountPercentage.toFixed(0);
};

const getDiscountPrice = (discountPrice = 1, price = 1) => {
  const discountAmount = discountPrice - price;
  return Math.sign(discountAmount) === -1
    ? `-£${Math.abs(discountAmount).toFixed(2).replace(".", ",")}`
    : `£${Math.abs(discountAmount).toFixed(2).replace(".", ",")}`;
};

const Grocery = () => {
  const { groceryId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const fixedColor = useMemo(
    () => colors[Math.floor(Math.random() * colors.length)],
    []
  );
  const userState = useSelector((state) => state.user, shallowEqual);
  const recipeState = useSelector((state) => state.recipes, shallowEqual);
  const groceriesState = useSelector((state) => state.groceries, shallowEqual);
  const cartState = useSelector((state) => state.cart, shallowEqual);
  const [number, setNumber] = useState(0);
  const [viewMore, setViewMore] = useState(false);
  const grocery = groceriesState.grocery;
  const stockQuantity = grocery?.stockQuantity;

  const [moreRecipesToShow, setMoreRecipesToShow] = useState(true);
  const [displayedRecipes, setDisplayedRecipes] = useState([]);

  useEffect(() => {
    dispatch(getGrocery(groceryId));
  }, [groceryId]);

  useEffect(() => {
    let cartQuantity = 0;
    if (cartState.cart.find((item) => item.groceryId === groceryId)) {
      const cartItem = cartState.cart.find(
        (item) => item.groceryId === groceryId
      );
      cartQuantity = cartItem.quantity;
    }
    setNumber(cartQuantity);
  }, []);

  const like = () => {
    if (userState.user) {
      if (!grocery) return;
      dispatch(likeGroceryAction(userState.user.uid));
      likeGrocery(grocery.groceryId, userState.user.uid);
    } else {
      dispatch(toast({ message: "Please login to add product to favourite" }));
    }
  };

  const unlike = () => {
    if (userState.user) {
      if (!grocery) return;
      dispatch(unlikeGroceryAction(userState.user.uid));
      unlikeGrocery(grocery.groceryId, userState.user.uid);
    } else {
      dispatch(toast({ message: "Please login to add product to favourite" }));
    }
  };

  const shareGrocery = (url) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Item",
          url: url,
        })
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const dummyInput = document.createElement("input");
      const text = url;
      document.body.appendChild(dummyInput);
      dummyInput.value = text;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);
      dispatch(toast({ message: "Copied to clipboard" }));
    }
  };

  const updateCartItem = (grocery, quantity) => {
    if (quantity > 0) {
      dispatch(
        updateCart({
          ...grocery,
          quantity,
        })
      );
      dispatch(toast({ message: "Cart updated successfully" }));
    } else {
      dispatch(toast({ message: "Add an item to cart" }));
    }
  };

  const decrease = () => {
    let newNumber = number - 1;
    if (newNumber >= 0) {
      setNumber(newNumber);
    }
  };

  const increase = () => {
    let newNumber = number + 1;
    if (stockQuantity && newNumber <= stockQuantity) {
      setNumber(newNumber);
    } else {
      dispatch(
        toast({
          message: "You can’t order more than what’s available in stock",
        })
      );
    }
  };

  useEffect(() => {
    setDisplayedRecipes(recipeState.recipes.slice(0, 6));
  }, [recipeState.recipes]);

  const fetchMoreData = () => {
    if (displayedRecipes.length >= recipeState.recipes.length) {
      setMoreRecipesToShow(false);
      return;
    }
    setTimeout(() => {
      setDisplayedRecipes((displayedRecipes) =>
        displayedRecipes.concat(
          recipeState.recipes.slice(
            displayedRecipes.length,
            displayedRecipes.length + 6
          )
        )
      );
    }, 1000);
  };

  console.log(grocery);
  return (
    <div className={styles.page}>
      <div
        className={styles.header}
        style={{
          background: `radial-gradient(51.33% 119.29% at 49.87% 50%, ${fixedColor.backgroundColor} 0%, ${fixedColor.backgroundColor} 100%)`,
        }}
      >
        <IconButton
          size="small"
          className="mr-auto"
          onClick={() =>
            history.length ? history.goBack() : history.push("/shop")
          }
        >
          <AnimateElement duration={"0.5"}>
            <Avatar
              alt="back"
              variant="square"
              src={backIcon}
              className={styles.arrowback}
            />
          </AnimateElement>
        </IconButton>
        <IconButton
          size="small"
          className="mr-2"
          onClick={() => {
            if (
              groceriesState.groceryIsLoading === false &&
              grocery &&
              grocery.likes &&
              userState.user &&
              userState.user.uid &&
              grocery.likes.includes(userState.user.uid)
            ) {
              unlike();
            } else {
              like();
            }
          }}
        >
          {groceriesState.groceryIsLoading === false &&
          grocery &&
          grocery.likes &&
          userState.user &&
          userState.user.uid &&
          grocery.likes.includes(userState.user.uid) ? (
            <FavoriteIcon color="primary" className={styles.heartIcon} />
          ) : (
            <Avatar
              alt="like"
              variant="square"
              src={heartIcon}
              className={styles.heartIcon}
            />
          )}
        </IconButton>
        <IconButton
          size="small"
          onClick={() => shareGrocery(window.location.href)}
        >
          <Avatar
            alt="share"
            variant="square"
            src={shareIcon}
            className={styles.shareIcon}
          />
        </IconButton>
      </div>

      {groceriesState.groceryIsLoading === true &&
        groceriesState.groceryIsError === false && (
          <div className="p-4">
            <Spinner variant="rect" height={140} />
            <Spinner variant="text" height={40} />
            <Spinner variant="text" height={40} />
          </div>
        )}

      {groceriesState.groceryIsLoading === false &&
        groceriesState.groceryIsError === true && (
          <MessageBox text="Failed to load item" />
        )}

      {groceriesState.groceryIsLoading === false &&
        groceriesState.groceryIsError === false &&
        !grocery && <MessageBox text="Item not found" />}

      {groceriesState.groceryIsLoading === false &&
        groceriesState.groceryIsError === false &&
        grocery && (
          <>
            <div
              className={styles.headerImgContainer}
              style={{
                background: `radial-gradient(51.33% 119.29% at 49.87% 50%, ${fixedColor.backgroundCenter} 0%, ${fixedColor.backgroundColor} 100%)`,
              }}
            >
              <img
                className={styles.headerImage}
                src={grocery.thumbnail}
                alt="Grocery"
              />
            </div>
            <div className={styles.headerBottom} />
            <AnimateElement duration={"0.5"}>
              <div className={styles.content}>
                <div className={styles.contentHeading}>
                  <Typography
                    classes={{ body1: styles.desc }}
                    style={{ color: fixedColor.textColor }}
                    variant="body1"
                    display="block"
                  >
                    {grocery.description}
                  </Typography>
                  <Typography
                    classes={{ body1: styles.name }}
                    variant="body1"
                    display="block"
                  >
                    {grocery.name}
                  </Typography>
                  <div className={styles.text}>
                    <span className={styles.size}>{grocery.size}</span>
                    <span className={styles.stockItems}>
                      {!stockQuantity
                        ? "No Items left"
                        : stockQuantity === 1
                        ? "1 Item left"
                        : stockQuantity + " Items left"}
                    </span>
                  </div>
                </div>

                {grocery.specialOffers ? (
                  <div className={styles.specialOfferCtn}>
                    <div className={styles.specialPriceContainer}>
                      <span className={styles.specialOfferText}>promo</span>
                      <div className={styles.specialOfferDiscount}>
                        <span>
                          <s>£{Number(grocery.price).toFixed(2)}</s>
                        </span>
                        <span>
                          -
                          {calculateDiscount(
                            grocery.price,
                            grocery.discountPrice
                          )}
                          %
                        </span>
                      </div>
                    </div>
                    <div className={styles.specialPrice}>
                      <span>£{Number(grocery.discountPrice).toFixed(2)}</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={styles.priceContainer}>
                      <span className={styles.number}>{number}</span>
                      <span
                        className={
                          number < grocery.discountQuantity
                            ? styles.activePrice
                            : styles.nonActivePrice
                        }
                      >
                        £{grocery.price.toFixed(2)}
                      </span>
                    </div>
                    <div className={styles.discountPriceContainer}>
                      <span className={styles.number}>
                        {grocery.discountQuantity}+
                      </span>
                      <div className={styles.leftContainer}>
                        <span className={styles.percentage}>
                          <span>
                            {getDiscountPercentage(
                              grocery.discountPrice,
                              grocery.price
                            )}
                            %
                          </span>
                          <span>
                            {getDiscountPrice(
                              grocery.discountPrice,
                              grocery.price
                            )}
                            /pack
                          </span>
                        </span>
                        <span
                          className={
                            number >= grocery.discountQuantity
                              ? styles.activePrice
                              : styles.nonActivePrice
                          }
                        >
                          £{grocery.discountPrice.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                {!(grocery.stockQuantity > 0) ? (
                  <div className={styles.soldOutContainer}>
                    <span className={styles.soldOut}>SOLD OUT</span>
                  </div>
                ) : (
                  <div className={styles.buttonContainer}>
                    <div className={styles.calculator}>
                      <IconButton color="inherit" onClick={decrease}>
                        <RemoveIcon fontSize="small" color="primary" />
                      </IconButton>
                      <span className={styles.number}>{number}</span>
                      <IconButton
                        color="primary"
                        onClick={increase}
                        disabled={grocery.specialOffers && number > 0}
                      >
                        <AddIcon fontSize="small" color="inherit" />
                      </IconButton>
                    </div>
                    <Button
                      className={styles.buy}
                      onClick={() => updateCartItem(grocery, number)}
                    >
                      Add to Cart
                    </Button>
                  </div>
                )}

                <div className={styles.deliveryContainer}>
                  <div className={styles.tag}>
                    <img src={deliveryIcon} alt="" />
                    <span className={styles.leftTag}>Delivered in</span>
                    <span className={styles.rightTag}>
                      {grocery.delivery} days
                    </span>
                  </div>
                  <div className={styles.tag}>
                    <img src={clockIcon} alt="" />
                    <span className={styles.leftTag}>Next day delivery</span>
                    <span className={styles.rightTag}>
                      order by{" "}
                      {grocery.orderBy
                        ? format(new Date(grocery.orderBy), "h:mm aa")
                        : "00:00"}
                    </span>
                  </div>
                  <div className={styles.tag}>
                    <img src={stoveIcon} alt="" />
                    <span className={styles.leftTag}>Delivery by</span>
                    <span className={styles.rightTag}>
                      {grocery.deliveryBy}
                    </span>
                  </div>
                </div>
                <Typography
                  className={styles.headerText}
                  variant="body1"
                  display="block"
                >
                  About the product
                </Typography>
                <Typography
                  className={cx(styles.aboutProductText, {
                    [styles.aboutProductTextLess]: !viewMore,
                  })}
                  variant="body1"
                  display="block"
                >
                  {grocery.about}
                </Typography>
                {!viewMore && (
                  <span
                    onClick={() => setViewMore(true)}
                    className={styles.readMore}
                  >
                    Read more <AddIcon fontSize="small" color="inherit" />
                  </span>
                )}
                <div className={styles.line} />
              </div>
            </AnimateElement>
          </>
        )}
      <AnimateElement duration={"0.5"}>
        <div className="px-4 py-5">
          <Typography
            className={styles.headerText}
            variant="body1"
            display="block"
          >
            Discover recipes
          </Typography>
          {recipeState.recipesIsLoading === false &&
            recipeState.recipesIsError === true && (
              <MessageBox text="Failed to load recipes" />
            )}
          {recipeState.recipesIsLoading === true &&
            recipeState.recipesIsError === false && (
              <>
                <Spinner variant="rect" height={140} />
                <Spinner variant="text" height={40} />
                <Spinner variant="text" height={40} />
              </>
            )}
          {recipeState.recipesIsLoading === false &&
            recipeState.recipesIsError === false && (
              <InfiniteScroll
                hasMore={moreRecipesToShow}
                next={fetchMoreData}
                dataLength={displayedRecipes.length}
                loader={
                  <div className="p-5 text-center">
                    <CircularProgress />
                  </div>
                }
                endMessage={
                  <div className="p-5 text-center">
                    Yay! You have seen it all
                  </div>
                }
              >
                <RecipeWidgetList recipes={displayedRecipes} />
              </InfiniteScroll>
            )}
        </div>
      </AnimateElement>
    </div>
  );
};

export default Grocery;
