import React from "react";

import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import PatientList from "./PatientList/PatientList";
import PatientDetails from "./PatientDetails/PatientDetails";
import PatientRecipes from "./PatientRecipes/PatientRecipes";
import PatientGroceries from "./PatientGroceries/PatientGroceries";
import PatientCalories from "./Calories/Calories";
import Consumer from "./Consumer/Consumer";
import Chart from "./Report/Components/ChartPage/chart";
import Weeklist from "./Report/Components/WeekList/Weeklist";
import Plan from "./Plan/Plan";
import PatientSessions from "./PatientSessions/PatientSessions";
import PatientSessionsDetails from "./PatientSessionsDetails/PatientSessionsDetails";
import Sessions from "./Sessions/Sessions";
import SessionsDetails from "./SessionsDetails/SessionsDetails";
import HealthProfile from "./HealthProfile/HealthProfile";
import FoodPreferenceIntakeForm from "./FoodPreferencesIntakeForm/FoodPreferenceIntakeForm";
import AdditionalResources from "./AdditionalResources/AdditionalResources";
import Task from "./Task/Task";
import NewTask from "./Task/New/New";
import SingleTask from "./Task/SingleView";
import TabHeader from "./TabHeader/TabHeader";
import GptAssistant from "./GPTAssistant/GptAssistant";
import UserPlan from "./UserPlan/UserPlan";
import MealPlan from "../MealPlan/MealPlan";

const Dietitian = (props) => {
  const { isDietitian } = props;
  const { path } = useRouteMatch();

  return (
    <>
      {isDietitian ? (
        <Switch>
          <Route path={`${path}`} exact>
            <PatientList path={path} />
          </Route>
          <Route path={`${path}/patient-details/:patientId`} exact>
            <PatientDetails />
          </Route>
          <Route
            path={`${path}/patient-details/:patientId/suggest-recipe`}
            exact
          >
            <PatientRecipes />
          </Route>
          <Route
            path={`${path}/patient-details/:patientId/suggest-product`}
            exact
          >
            <PatientGroceries />
          </Route>
          <Route path={`${path}/patient-details/:patientId/session`} exact>
            <PatientSessions />
          </Route>
          <Route
            path={`${path}/patient-details/:patientId/session/:sessionId`}
            exact
          >
            <PatientSessionsDetails />
          </Route>
          <Route path={`${path}/patient-details/:patientId/userPlan`} exact>
            <UserPlan />
          </Route>
          <Route path={`${path}/mealplan/:date`} exact>
            <MealPlan />
          </Route>

          <Route
            path={`${path}/patient-details/:patientId/patient-calories`}
            exact
          >
            <PatientCalories />
          </Route>
          <Route path={`${path}/patient-details/:patientId/tasks`} exact>
            <Task />
          </Route>
          <Route path={`${path}/patient-details/:patientId/new-tasks`} exact>
            <NewTask />
          </Route>
          <Route
            path={`${path}/patient-details/:patientId/task-details/:taskId`}
            exact
          >
            <SingleTask />
          </Route>
          <Route path={`${path}/report`} exact>
            <Chart />
          </Route>
          <Route path={`${path}/report/:from/:to/:userId`} exact>
            <Chart />
          </Route>
          <Route path={`${path}/weeklist/:userId`} exact>
            <Weeklist />
          </Route>
          <Route path={`${path}/patient-details/resources/:userId`} exact>
            <AdditionalResources />
          </Route>
          <Route
            path={`${path}/patient-details/:userId/:healthProfileDetails`}
            exact
          >
            <FoodPreferenceIntakeForm />
          </Route>
          <Route path={`${path}/health-profile/:userId`} exact>
            <HealthProfile path={path} />
          </Route>
          <Route path={`${path}/gpt/:type/:userID/:startDate/:endDate`}>
            <GptAssistant />
          </Route>
          <Redirect to="/dietitian" />
        </Switch>
      ) : (
        <Switch>
          <Route path={`${path}`} exact>
            <TabHeader>
              <Consumer
                path={path}
                privateConversations={props.privateConversations}
              />
            </TabHeader>
          </Route>
          <Route path={`${path}/weeklist/:userId`} exact>
            <TabHeader>
              <Weeklist />
            </TabHeader>
          </Route>
          <Route path={`${path}/gpt/:type/:userID/:startDate/:endDate`} exact>
            <GptAssistant />
          </Route>
          <Route path={`${path}/sessions`} exact>
            <TabHeader>
              <Sessions path={path} />
            </TabHeader>
          </Route>
          <Route
            path={`${path}/patient-details/:patientId/task-details/:taskId`}
            exact
          >
            <SingleTask />
          </Route>
          <Route path={`${path}/sessions/:sessionId/:number`} exact>
            <SessionsDetails />
          </Route>
          <Route path={`${path}/report/:from/:to/:userId`} exact>
            <Chart />
          </Route>
          <Route path={`${path}/plan/:recipeId`} exact>
            <Plan path={path} />
          </Route>
          <Route path={`${path}/health-profile/:userId`} exact>
            <HealthProfile path={path} />
          </Route>
          <Redirect to="/dietitian" />
        </Switch>
      )}
    </>
  );
};

export default Dietitian;
