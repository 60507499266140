import React from "react";
import AnimateElement from "../../../components/AnimateElement";
import styles from "./header.module.scss";
import { useHistory, useLocation, useParams } from "react-router";

const Head = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const moveTo = (path) => history.push(path);
  const path = location.pathname;
  const { date } = params;


  return (
    // <AnimateElement duration={"0.5"}>
      <div className={styles.con}>
        <div
          className={`${styles.lin} ${
            path.includes("calendar") ? styles.active : null
          }`}
          onClick={() => moveTo("/calendar")}
        >
          Diary
        </div>
        <div
          className={`${styles.lin} ${
            path.includes("mealplan") ? styles.active : null
          }`}
          onClick={() => moveTo(`/mealplan${date ? "/" + date : '/'}`)}
        >
          MealPlan
        </div>
        <div
          className={`${styles.lin} ${
            path.includes("list") ? styles.active : null
          }`}
          onClick={() => moveTo(`/list${date ? "/" + date : '/'}`)}
        >
          Shopping list
        </div>
      </div>
    // </AnimateElement>
  );
};

export default Head;
