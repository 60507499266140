import React, { Fragment, useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import PhonedIcon from "@material-ui/icons/Phone";

import { toast } from "../../state";
import { useCalendlyEventListener, InlineWidget, PopupModal } from "react-calendly";
import Layout from "../../layout";
import Dialog from "../../components/Dialog/Dialog";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import { getDietitianCalendlyQuery, } from "../../services/queries";
import { cancelCalendlyEvent, getEvents } from "../../services/calendar"
import styles from "./HomeDietitian.module.scss";



const date = new Date();
date.setHours(0, 0, 0, 0)

const HomeDietitian = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const userState = useSelector((state) => state.user, shallowEqual);
   const [selectedEvent, setSelectedEvent] = useState("")
   const [isOpen, setIsOpen] = useState(false);
   const [calendlyIsOpen, setCalendlyIsOpen] = useState(false);
   const [loading, setLoading] = useState(false)
   const { isLoading, isError, data, refetch } = getDietitianCalendlyQuery(formatISO(date), userState?.user?.email);
   const user = userState.user;

   const handleCancelEvent = async (event) => {
      if(event){
         setLoading(true)
         const uuid = event?.uri?.split('/').pop();
         await cancelCalendlyEvent(uuid);
         refetch();
         setLoading(false)
      }   
   }

   const handleNavigateToPatientDetails = (uid) => {
      history.push(`dietitian/patient-details/${uid}`);
    };

    useCalendlyEventListener({
      onEventScheduled: async (event) => {
        dispatch(toast({ message: "Session booked successfully" }));
        await handleCancelEvent(selectedEvent)
        setCalendlyIsOpen(false);
        setSelectedEvent("");
      },
    });


   return (
      <Layout>
         <div className={styles.page}>
            <div className={styles.titleContainer}>
               <span className={styles.titleText}>Incoming Sessions</span>
            </div>

            <div>
               {isLoading && (
                  <>
                     <Spinner variant='rect' height={64} />
                     <br />
                     <Spinner variant='rect' height={64} />
                     <br />
                     <Spinner variant='rect' height={64} />
                     <br />
                  </>
               )}
            </div>
            <div>{!isLoading && data && data.length === 0 && <MessageBox text='No Incoming Session' />}</div>
            <div>
               {/* {!isLoading && data && data.data.collection.map((event) => { */}
               {!isLoading &&
                  data &&
                  data.map((event) => {
                        return (
                        <div className={styles.eventCard} key={event.uri}>
                           <div className={styles.header}>
                              <span className={styles.date}>
                                 {format(new Date(event.start_time), "eee dd MMM")}, {format(new Date(event.start_time), "hh:mm")} -{" "}
                                 {format(new Date(event.end_time), "hh:mm aaa").toLocaleUpperCase()}
                              </span>
                              <IconButton size='small'>
                                 <a href={`tel:${event?.location?.location}`}>
                                    <PhonedIcon className={styles.phone} />
                                 </a>
                              </IconButton>
                           </div>
                           <div className={styles.body} onClick={() => {
                             event?.inviteeUserInfo?.uid && handleNavigateToPatientDetails(event?.inviteeUserInfo?.uid)
                           }}>
                              {event?.invitees?.map((invitee) => {
                                 return (
                                    <div className={styles.invitee} key={invitee.uri}>
                                       <Avatar className={styles.avatar} src={event?.inviteeUserInfo?.photoURL} />
                                       <div>
                                          <div className={styles.name}>{invitee?.name}</div>
                                          <div className={styles.session}>Session</div>
                                       </div>
                                    </div>
                                 );
                              })}
                           </div>
                           <div className={styles.actions}>
                              <div className={styles.reschedule} onClick={() => [setSelectedEvent(event), setCalendlyIsOpen(true)]}>
                                 <span className={styles.span}>Reschedule</span>
                              </div>
                              <div className={styles.cancel} onClick={() => [setSelectedEvent(event), setIsOpen(true)]}>
                                 <span className={styles.text}>Cancel</span>
                              </div>
                           </div>
                        </div>
                     );
                  })}
            </div>
         </div>

         <Dialog
            open={Boolean(isOpen)}
            message={`Are you sure you want to cancel?`}
            onCancel={() => [setSelectedEvent(""), setIsOpen(false)]}
            onConfirm={() => [handleCancelEvent(selectedEvent), setSelectedEvent(""), setIsOpen(false)]}
            isLoading={loading}
            isLoadingText='No Loading'
         />

         <PopupModal
            rootElement={document.body}
            url={
               selectedEvent?.user?.calendlyUrl
                  ? `${selectedEvent?.user?.calendlyUrl}?hide_event_type_details=1&hide_gdpr_banner=1`
                  : "https://calendly.com/stove/stove-monthly-call-emma?hide_event_type_details=1&hide_gdpr_banner=1"
            }
            open={calendlyIsOpen}
            onModalClose={() => [setSelectedEvent(""), setCalendlyIsOpen(false)]}
            prefill={{
               email: selectedEvent?.inviteeUserInfo?.email,
               name: selectedEvent?.inviteeUserInfo?.displayName,
               guests: selectedEvent?.user?.email ? [selectedEvent?.user?.email] : [],
            }}
         />
      </Layout>
   );
};

export default HomeDietitian;


