import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getAllUserRecipes, getAllUserUsedPromoCode, getAllUserCollectedRewards, getAllUserTransactions, getAllUserLikedRecipes, getAllUserLikedGroceries, getAllUserCollectedPoints } from "../services/firebase";



export const removeRecipeFromRedux = createAction('removeRecipeFromRedux');

export const getUserRecipes = createAsyncThunk('User/getAllUserRecipes', async (userId) => {
    const response = await getAllUserRecipes(userId);
    return response;
})

export const getUserTransactions = createAsyncThunk('User/getAllUserTransactions', async (userId) => {
    const response = await getAllUserTransactions(userId);
    return response;
})

export const getUserCollectedRewards = createAsyncThunk('User/getAllUserCollectedRewards', async (userId) => {
    const response = await getAllUserCollectedRewards(userId);
    return response;
})

export const getUserLikedRecipes = createAsyncThunk('User/getAllUserLikedRecipes', async (userId) => {
    const response = await getAllUserLikedRecipes(userId);
    return response;
})

export const getUserLikedGroceries = createAsyncThunk('User/getAllUserLikedGroceries', async (userId) => {
    const response = await getAllUserLikedGroceries(userId);
    return response;
})

export const getUserCollectedPoints = createAsyncThunk('User/getAllUserCollectedPoints', async (userId) => {
    const response = await getAllUserCollectedPoints(userId);
    return response;
})

export const getUserUsedPromoCode = createAsyncThunk('User/getAllUserUsedPromoCode', async (userId) => {
    const response = await getAllUserUsedPromoCode(userId);
    return response;
})


const initialState = {
    user: null,
    accountType: localStorage.getItem("accountType"),
    isLoading: true,
    todaysMeal: null,

    userRecipesIsLoading: true,
    userRecipesIsError: false,
    userRecipes: [],

    userTransactionsIsLoading: true,
    userTransactionsIsError: false,
    userTransactions: [],

    userRewardsIsLoading: true,
    userRewardsIsError: false,
    userRewards: [],

    userLikedRecipesIsLoading: true,
    userLikedRecipesIsError: false,
    userLikedRecipes: [],

    userLikedGroceriesIsLoading: true,
    userLikedGroceriesIsError: false,
    userLikedGroceries: [],

    userCollectedPointsIsLoading: true,
    userCollectedPointsIsError: false,
    userCollectedPoints: [],

    userUsedPromoCodeIsLoading: true,
    userUsedPromoCodeIsError: false,
    userUsedPromoCode: [],
};

export const User = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        updateUserField: (state, action) => {
            state.user[action.payload.field] = action.payload.data;
        },
        setUserAccountType: (state, action) => {
            state.accountType = action.payload;
        },
        logoutUser: () => initialState
        ,
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setTodaysMeal: (state, action) => {
            state.todaysMeal = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(removeRecipeFromRedux, (state, action) => {
                state.userRecipes = state.userRecipes.filter(recipe => recipe.recipeId !== action.payload);
            })

        builder
            .addCase(getUserRecipes.pending, (state) => {
                state.userRecipesIsLoading = true;
                state.userRecipesIsError = false;
            })
            .addCase(getUserRecipes.fulfilled, (state, action) => {
                state.userRecipesIsLoading = false;
                state.userRecipesIsError = false;
                state.userRecipes = action.payload;
            })
            .addCase(getUserRecipes.rejected, (state) => {
                state.userRecipesIsLoading = false;
                state.userRecipesIsError = true;
                state.userRecipes = [];
            })

        builder
            .addCase(getUserTransactions.pending, (state) => {
                state.userTransactionsIsLoading = true;
                state.userTransactionsIsError = false;
            })
            .addCase(getUserTransactions.fulfilled, (state, action) => {
                state.userTransactionsIsLoading = false;
                state.userTransactionsIsError = false;
                state.userTransactions = action.payload;
            })
            .addCase(getUserTransactions.rejected, (state) => {
                state.userTransactionsIsLoading = false;
                state.userTransactionsIsError = true;
                state.userTransactions = [];
            })

        builder
            .addCase(getUserCollectedRewards.pending, (state) => {
                state.userRewardsIsLoading = true;
                state.userRewardsIsError = false;
            })
            .addCase(getUserCollectedRewards.fulfilled, (state, action) => {
                state.userRewardsIsLoading = false;
                state.userRewardsIsError = false;
                state.userRewards = action.payload;
            })
            .addCase(getUserCollectedRewards.rejected, (state) => {
                state.userRewardsIsLoading = false;
                state.userRewardsIsError = true;
                state.userRewards = [];
            })

        builder
            .addCase(getUserLikedRecipes.pending, (state) => {
                state.userLikedRecipesIsLoading = true;
                state.userLikedRecipesIsError = false;
            })
            .addCase(getUserLikedRecipes.fulfilled, (state, action) => {
                state.userLikedRecipesIsLoading = false;
                state.userLikedRecipesIsError = false;
                state.userLikedRecipes = action.payload;
            })
            .addCase(getUserLikedRecipes.rejected, (state) => {
                state.userLikedRecipesIsLoading = false;
                state.userLikedRecipesIsError = true;
                state.userLikedRecipes = [];
            })

        builder
            .addCase(getUserLikedGroceries.pending, (state) => {
                state.userLikedGroceriesIsLoading = true;
                state.userLikedGroceriesIsError = false;
            })
            .addCase(getUserLikedGroceries.fulfilled, (state, action) => {
                state.userLikedGroceriesIsLoading = false;
                state.userLikedGroceriesIsError = false;
                state.userLikedGroceries = action.payload;
            })
            .addCase(getUserLikedGroceries.rejected, (state) => {
                state.userLikedGroceriesIsLoading = false;
                state.userLikedGroceriesIsError = true;
                state.userLikedGroceries = [];
            })

        builder
            .addCase(getUserCollectedPoints.pending, (state) => {
                state.userCollectedPointsIsLoading = true;
                state.userCollectedPointsIsError = false;
            })
            .addCase(getUserCollectedPoints.fulfilled, (state, action) => {
                state.userCollectedPointsIsLoading = false;
                state.userCollectedPointsIsError = false;
                state.userCollectedPoints = action.payload;
            })
            .addCase(getUserCollectedPoints.rejected, (state) => {
                state.userCollectedPointsIsLoading = false;
                state.userCollectedPointsIsError = true;
                state.userCollectedPoints = [];
            })

        builder
            .addCase(getUserUsedPromoCode.pending, (state) => {
                state.userUsedPromoCodeIsLoading = true;
                state.userUsedPromoCodeIsError = false;
            })
            .addCase(getUserUsedPromoCode.fulfilled, (state, action) => {
                state.userUsedPromoCodeIsLoading = false;
                state.userUsedPromoCodeIsError = false;
                state.userUsedPromoCode = action.payload;
            })
            .addCase(getUserUsedPromoCode.rejected, (state) => {
                state.userUsedPromoCodeIsLoading = false;
                state.userUsedPromoCodeIsError = true;
                state.userUsedPromoCode = [];
            })
    }
});

export const { setUser, updateUserField, setUserAccountType, logoutUser, setLoading, setTodaysMeal } = User.actions;
export default User.reducer;