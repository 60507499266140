import React from "react";
import ReactDOMServer from 'react-dom/server';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { checkIfEmailExist } from "./../../../../services/firebase";
import { sendRegisterMail } from "./../../../../services/twilio";
import { toast } from "./../../../../state";

import styles from "./../Register.module.scss";

const validationEmailSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email is required"),
});

const Email = (props) => {
    const { SendRegisterEmail, setEmail, setRegisterStage } = props;
    const dispatch = useDispatch();

    return (
        <Formik
            validationSchema={validationEmailSchema}
            initialValues={{ email: "" }}
            onSubmit={async (values, FormikBag) => {
                try {
                    const emailExist = await checkIfEmailExist(values.email);
                    if (emailExist) {
                        dispatch(toast({ message: "This email is already in use" }));
                        FormikBag.setSubmitting(false);
                        return
                    }
                    const response = await sendRegisterMail(values.email, ReactDOMServer.renderToString(<SendRegisterEmail />))
                    sessionStorage.setItem("token", response.data.token);
                    dispatch(toast({ message: "Verification code has been sent to your email" }));
                    setEmail(values.email);
                    setRegisterStage("verify");
                } catch (e) {
                    dispatch(toast({ message: "Failed to send verification code, try again" }));
                    FormikBag.setSubmitting(false);
                }
            }}
        >
            {formik => (
                <div className={styles.emailPageContent}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        className={styles.textField}
                        inputProps={{ autoComplete: "off" }}
                        {...formik.getFieldProps("email")}
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        disabled={formik.isSubmitting}
                    />
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={formik.submitForm} disabled={formik.isSubmitting}>Next</Button>
                </div>
            )}
        </Formik>
    )
}

export default Email;