import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Home from "./components/Home/Home";
import Recipes from "./components/Recipes/Recipes";
import Points from "./components/Points/Points";
import RecipesLiked from "./components/RecipesLiked/RecipesLiked";
import GroceriesLiked from "./components/GroceriesLiked/GroceriesLiked";
import Transactions from "./components/Transactions/Transactions";
import Transaction from "./components/Transaction/Transaction";
import Settings from "./components/Settings/Settings";
import Notifications from "./components/Notifications/Notifications";
import Track from "../Track/Track";
import ChangePassword from "./components/ChangePassword";
import {
   getRewards,
   getUserRecipes,
   getUserTransactions,
   getUserCollectedRewards,
   getUserLikedRecipes,
   getUserLikedGroceries,
   getUserCollectedPoints,
   setUser,
   setNotificationPreferences,
} from "../../state";
import { getUserData } from "../../services/firebase";

const Account = () => {
   const { path, url } = useRouteMatch();
   const dispatch = useDispatch();
   const userState = useSelector((state) => state.user, shallowEqual);
   const cartState = useSelector((state) => state.cart, shallowEqual);
   const rewardsState = useSelector((state) => state.rewards, shallowEqual);
   const user = userState.user;

   useEffect(() => {
      dispatch(getRewards());
      dispatch(getUserRecipes(userState.user.uid));
      dispatch(getUserTransactions(userState.user.uid));
      dispatch(getUserCollectedRewards(userState.user.uid));
      dispatch(getUserLikedRecipes(userState.user.uid));
      dispatch(getUserLikedGroceries(userState.user.uid));
      dispatch(getUserCollectedPoints(userState.user.uid));
      // dispatch(getNotifications())
   }, [dispatch, userState.user.uid]);

   useEffect(() => {
      const fetchUserInfo = async () => {
         if (user) {
            const userData = await getUserData(user.uid);
            dispatch(setUser(userData));
         }
      };
      fetchUserInfo();
   }, []);

   useEffect(() => {
      if (user && user.notificationPreferences) {
         dispatch(setNotificationPreferences(user.notificationPreferences));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user]);


   return (
      <Switch>
         <Route path={`${path}`} exact>
            <Home url={url} userState={userState} />
         </Route>

         <Route path={`${path}/recipes`} exact>
            <Recipes userState={userState} />
         </Route>

         <Route path={`${path}/recipesliked`} exact>
            <RecipesLiked userState={userState} />
         </Route>

         <Route path={`${path}/groceriesliked`} exact>
            <GroceriesLiked userState={userState} />
         </Route>

         <Route path={`${path}/transactions`} exact>
            <Transactions url={url} userState={userState} />
         </Route>

         <Route path={`${path}/transaction/:transactionId`} exact>
            <Transaction userState={userState} />
         </Route>

         <Route path={`${path}/settings`} exact>
            <Settings userState={userState} />
         </Route>

         <Route path={`${path}/password`} exact>
            <ChangePassword userState={userState} />
         </Route>

         <Route path={`${path}/notification`} exact>
            <Notifications userState={userState} />
         </Route>

         <Route path={`${path}/track`} exact>
            <Track />
         </Route>

         <Route path={`${path}/points`} exact>
            <Points url={url} userState={userState} rewardsState={rewardsState} cartState={cartState} />
         </Route>

         <Redirect to={`${path}`} />
      </Switch>
   );
};

export default Account;
