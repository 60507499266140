import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import styles from "./TabHeader.module.scss";

const TabHeader = (props) => {
   const { children } = props;
   const history = useHistory();
   const { pathname } = useLocation();

   const userState = useSelector((state) => state.user, shallowEqual);

   return (
      <div className={styles.page}>
         <div className={styles.header}>
            {/* <div className={styles.headertext} >Your diet</div> */}
            <div
               className={`${styles.headertext} ${pathname === "/dietitian" ? styles.active : ""}`}
               onClick={() => {
                  history.push(`/dietitian`);
               }}
            >
               For me
            </div>
            <div
               className={`${styles.headertext} ${pathname.includes("/sessions") ? styles.active : ""}`}
               onClick={() => {
                  history.push(`/dietitian/sessions`);
               }}
            >
               Sessions
            </div>
            <div
               className={`${styles.headertext} ${pathname.includes("/weeklist") ? styles.active : ""}`}
               onClick={() => {
                  history.push(`/dietitian/weeklist/${userState?.user?.uid}`);
               }}
            >
               Food Diary
            </div>
         </div>
         {children}
      </div>
   );
};

export default TabHeader;
