import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';


import congratulationsImg from "./../../../../assets/congratulations.png";
import discountIcon from "./../../../../assets/icons/discount-red.png";





import styles from "./Congratulations.module.scss";

const Congratulations = (props) => {
    const { activeRecipeId, points } = props;
    const history = useHistory();

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <IconButton size="small" onClick={() => history.length ? history.goBack() : history.push("/shop")}>
                    <CloseOutlinedIcon className={styles.icon} />
                </IconButton>
                <span className={styles.headertext}>Recipe added!</span>
            </div>
            <div className={styles.headerBottom} />
            <div className={styles.content}>
                <img alt="Congratulations" src={congratulationsImg} className={styles.uploadImage} />
                <div className={styles.congratulations}>Congratulations!</div>
                <div className={styles.congratulationsLightText}>Your new recipe has been uploaded correctly, and tons of friends can be inspired – stay tuned on comments!</div>
                {/* <div className={styles.pointContainer}>
                    <div className={styles.pointEarnedContainer}>
                        <Avatar alt="discount" src={discountIcon} className={styles.discountIcon} />
                        <span className="mr-auto">Points earned</span>
                        <span>{points}</span>
                    </div>
                    <div className={styles.pointCheckContainer}>
                        <span className={styles.curse} onClick={() => history.push("/account/points")}>{"Check all points >"}</span>
                    </div>
                    <div className={styles.cicrleLeft} />
                    <div className={styles.cicrleRight} />
                </div> */}

            </div>
            <ButtonBase className={styles.viewRecipeButton} onClick={() => history.push(`/recipe/${activeRecipeId}`)}>See recipe</ButtonBase>
        </div>
    )
}

export default Congratulations;
