import React, { useState } from "react";
import styles from "./DateTimePicker.module.scss";
import CALENDER_ICON from "./../../assets/icons/calender-icon.svg";
import TIME_ICON from "./../../assets/time.svg";
import "date-fns";
import dayjs from "dayjs";
import * as isToday from "dayjs/plugin/isToday";
import DayJsUtils from "@date-io/dayjs";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

dayjs.extend(isToday);

export const DateTimePicker = ({
  selectedTime,
  setSelectedTime,
  selectedDate,
  setSelectedDate,
  dateLabel = "Date",
  timeLabel = "Time",
  isTimeSelectable = true,
  isDateSelectable = true,
  changeTimeSelectable,
  changeDateSelectable,
}) => {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  return (
    <>
      <div className={styles.dateTimeContainer}>
        <div className={styles.date}>
          <p className={styles.headers}>{dateLabel}</p>
          <div className={styles.dateField}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <DatePicker
                orientation="portrait"
                format="ddd D MMM"
                value={selectedDate}
                onChange={handleDateChange}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={CALENDER_ICON} alt="calendar" />
                    </InputAdornment>
                  ),
                }}
                className={styles.input}
              />
            </MuiPickersUtilsProvider>
            {!isDateSelectable && (
              <div className={styles.filler}>
                <div>No Date</div>{" "}
                <CloseOutlinedIcon
                  onClick={() => {
                    if (
                      changeDateSelectable &&
                      typeof changeDateSelectable === "function"
                    ) {
                      changeDateSelectable();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {/* time */}
        <div className={styles.time}>
          <p className={styles.headers}>{timeLabel}</p>
          <div className={styles.timeField}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <TimePicker
                orientation="portrait"
                value={selectedTime}
                onChange={handleTimeChange}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={TIME_ICON} alt="calendar" />
                    </InputAdornment>
                  ),
                }}
                className={styles.input}
              />
            </MuiPickersUtilsProvider>
            {!isTimeSelectable && (
              <div className={styles.filler}>
                <div>No Time</div>
                <CloseOutlinedIcon
                  onClick={() => {
                    
                      changeTimeSelectable();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
