import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import ButtonBase from "@material-ui/core/ButtonBase";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Footer from "./../../layout/Footer/Footer";
import Spinner from "./../../components/Spinner/Spinner";
import MessageBox from "./../../components/MessageBox/MessageBox";
import GroceryWidgetList from "./../../components/GroceryWidgetList/GroceryWidgetList";
import { setActiveGroceriesTab, setCurrentPage, setTabIndex } from "./../../state";
import styles from "./Groceries.module.scss";
import cx from "classnames";
import advertpromo1Image from "./../../assets/ad-promo-1.png";
import advertpromo2Image from "./../../assets/ad-promo-2.png";
import advertpromo3Image from "./../../assets/ad-promo-3.png";
import adverttracking1Image from "./../../assets/ad-tracking-1.png";
import adverttracking2Image from "./../../assets/ad-tracking-2.png";
import adverttracking3Image from "./../../assets/ad-tracking-3.png";
import advertdietitianImage from "./../../assets/ad-dietitian.png";
import advertcommunity1Image from "./../../assets/ad-community-1.png";
import advertcommunity2Image from "./../../assets/ad-community-2.png";
import advertcommunity3Image from "./../../assets/ad-community-3.png";
import advertcommunity4Image from "./../../assets/ad-community-4.png";
import advertworkImage from "./../../assets/ad-work.png";
import constant from "./../../constant";

import GrocerySlide1 from "./../../assets/groceries/groceries-slide1.png";
import GroceryImage1 from "./../../assets/search-page/grocery-1.png";
import GroceryImage2 from "./../../assets/search-page/grocery-2.png";
import GroceryImage3 from "./../../assets/search-page/grocery-3.png";
import GroceryImage4 from "./../../assets/search-page/grocery-4.png";
import AnimateElement from "../../components/AnimateElement";



const shopCategories = constant.shopCategories.options;
const tabs = ["All", ...shopCategories]
const sliderImages = [GroceryImage1, GroceryImage2, GroceryImage3, GroceryImage4]

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Groceries = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const groceriesState = useSelector(state => state.groceries, shallowEqual);
    const cartState = useSelector(state => state.cart, shallowEqual);
    const [advertIndex, setAdvertIndex] = useState(0);
    const [activeTab, setActiveTab] = useState("All");

    const handleTabChange = useCallback((activeTab) => {
        setActiveTab(activeTab)
    }, [])





    const groceries = activeTab === "All" ? groceriesState.groceries : groceriesState.groceries.filter(grocery => grocery.shopCategory.includes(activeTab.toLowerCase()))

    return (
        <>
            <div className={styles.page}>
                <div className={styles.header}>
                    <div className={styles.inputContainer}>
                        <svg className={styles.searchIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M17.5 18L21.5423 22" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <circle cx="11.5" cy="11.5" r="8.5" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <InputBase
                            fullWidth
                            placeholder="What are you looking for?"
                            inputProps={{ 'aria-label': 'search' }}
                            onFocus={() => history.push("/search/text/groceryies")}
                        />
                    </div>
                    <IconButton size="small" className={styles.headerIcon} onClick={() => history.push("/account/groceriesliked")}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.87187 11.5983C1.79887 8.24832 3.05287 4.41932 6.56987 3.28632C8.41987 2.68932 10.4619 3.04132 11.9999 4.19832C13.4549 3.07332 15.5719 2.69332 17.4199 3.28632C20.9369 4.41932 22.1989 8.24832 21.1269 11.5983C19.4569 16.9083 11.9999 20.9983 11.9999 20.9983C11.9999 20.9983 4.59787 16.9703 2.87187 11.5983Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M16 6.69995C17.07 7.04595 17.826 8.00095 17.917 9.12195" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                    <IconButton size="small" className={styles.headerIcon} onClick={() => history.push("/cart")}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.4222 19.8203C7.8442 19.8203 8.1872 20.1633 8.1872 20.5853C8.1872 21.0073 7.8442 21.3493 7.4222 21.3493C7.0002 21.3493 6.6582 21.0073 6.6582 20.5853C6.6582 20.1633 7.0002 19.8203 7.4222 19.8203Z" fill="#130F26" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M18.6747 19.8203C19.0967 19.8203 19.4397 20.1633 19.4397 20.5853C19.4397 21.0073 19.0967 21.3493 18.6747 21.3493C18.2527 21.3493 17.9097 21.0073 17.9097 20.5853C17.9097 20.1633 18.2527 19.8203 18.6747 19.8203Z" fill="#130F26" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.75 3.25L4.83 3.61L5.793 15.083C5.871 16.018 6.652 16.736 7.59 16.736H18.502C19.398 16.736 20.158 16.078 20.287 15.19L21.236 8.632C21.353 7.823 20.726 7.099 19.909 7.099H5.164" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M14.1255 10.7949H16.8985" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className={styles.cartNotify}>{cartState.cart.length}</span>
                    </IconButton>
                </div>
                <AnimateElement duration={"0.5"}>
                <div className={styles.advert}>
                    <Box className={cx(styles.content, styles.grocery)} component="a" onClick={() => history.push("/dietitian")}>
                        <div className={styles.title}>The right grocery</div>
                        <div className={styles.subTitle}>A product selection by a professional dietitian</div>
                        <img src={GrocerySlide1} alt="promo" className={styles.image} />
                    </Box>
                </div>
                </AnimateElement>
                {/* <AnimateElement>
                <div className={styles.advert}>
                    <Box className={cx(styles.content, styles.order)} component="a" href="https://www.stovehealth.com/business" target="_blank">
                        <div className={styles.title}>Order Your Daily Groceries</div>
                        <div className={styles.subTitle}>Free Delivery</div>
                        <div className={styles.orderButton}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.9585 2.91668L9.04183 7.00001L4.9585 11.0833" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </Box>
                </div>
                </AnimateElement> */}


                <div className={styles.titleContainer}>
                    <span className={styles.titleText}>Categories</span>
                    <ButtonBase className={styles.seeAllButton}>
                        <span className={styles.seeAllText}>See all</span>
                        <svg className={styles.todayCheck} width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6.5L4 4L1 1.5" stroke="#00866E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </ButtonBase>
                </div>

                <div className={styles.sliderCategoryContainer}>
                    {tabs.slice(0, 8).map((value, index) => (
                         <AnimateElement duration={"0.5"}>
                        <div key={index} className={styles.sliderCategoryContent} onClick={() => handleTabChange(value)}>
                            <ButtonBase className={styles.sliderCategoryContentImage} style={{ backgroundImage: `url(${sliderImages[index % 4] + '?tr=w-64,h-64'})` }} />
                            <span className={styles.sliderCategoryContentText}>{value}</span>
                        </div>
                        </AnimateElement>
                    ))}
                </div>

                <div className={styles.sliderTagContainer}>
                    {tabs.slice(8).map((value, index) => (
                         <AnimateElement>
                        <Card key={index} component="div" className={styles.sliderTag} onClick={() => handleTabChange(value)}>
                            <span className={styles.sliderTagDot} />
                            <span>{value}</span>
                        </Card>
                        </AnimateElement>
                    ))}
                </div>

                {groceriesState.groceriesIsLoading === true && groceriesState.groceriesIsError === false && (
                    <div className="p-4">
                        <Spinner variant="rect" height={140} />
                        <Spinner variant="text" height={40} />
                        <Spinner variant="text" height={40} />
                    </div>
                )}

                {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === true && (
                    <MessageBox text="Failed to load shop items" />
                )}

                {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === false && (
                    <div className={styles.swipeDiv}>
                        {groceries.length === 0 ? <MessageBox text="No shop items matches your selected filter" /> : <GroceryWidgetList groceries={groceries} footer="grocery" />}
                    </div>
                )}
            </div>
            <Footer />
        </>
    )
}

export default Groceries;