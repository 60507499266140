import React from "react";


import congratulationsImg from "./../../../../assets/congratulations.png";
import StoveLogo from "./../../../../assets/stove-green-logo.svg";

import styles from "./Congratulations.module.scss";

const Congratulations = () => {
    return (
        <div className={styles.page}>
            <img alt="Stove Logo" src={StoveLogo} className={styles.img} />
            <div className={styles.content}>
                <img alt="Congratulations" src={congratulationsImg} className={styles.uploadImage} />
                <div className={styles.congratulations}>Payment successful</div>
                <div className={styles.congratulationsLightText}>Tomorrow between 14:00–16:00, you’ll receive all the products ordered.</div>
                <div className={styles.reciptText}>{"Check the receipt >"}</div>

            </div>
        </div>
    )
}

export default Congratulations;
