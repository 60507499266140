import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./MealModal.module.scss";
import { toast, setUser, toggle, closeEvents, addRecipe } from "../../../state";
import modalHandle from "../../../assets/modal-handle.png";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import {
  addUserEventsFood,
  addUserEventsWater,
  getFruits,
  getUserData,
  updateUserTask,
  uploadRecipe,
} from "../../../services/firebase/index";
import dayjs from "dayjs";
import { format } from "date-fns";
import {
  getAllRecommendRecipesQuery,
  useGetRecentMeal,
} from "../../../services/queries";
import { useAddRecentMeal } from "../../../services/mutations";
import { convertKiloJoulestoKiloCal } from "../../../utilities";
import axios from "axios";
import _debounce from "lodash/debounce";
import barcode from "../../../assets/icons/barcode.svg";
import AddIcon from "@material-ui/icons/Add";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import searchIcon from "../../../assets/icons/search.png";
import cancelDark from "../../../assets/icons/cancelDark.png";
import { CircularProgress, InputBase } from "@material-ui/core";
import addBtn from "../../../assets/add-btn-black.png";
import checkedBtn from "../../../assets/checked-green-btn.png";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import notAvailable from "../../../assets/empty.png";
import { SSE } from "sse";
import { getRecipes } from "../../../state/Recipes";
const today = dayjs();
const tabs = {
  New: "New",
  Recipes: "Recipes",
  Recent: "Recent",
  Food: "Food",
  Fruits: "Fruits/Veg",
  Dietitian: "Dietitian",
};

export const MealModal = ({ open, onCloseModal, day, type, complete }) => {
  const apiKey = "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";

  const recipeState = useSelector((state) => state.recipes, shallowEqual);
  const calendarState = useSelector((state) => state.calendar, shallowEqual);
  const userState = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const [currentTab, setcurrentTab] = useState("Recipes");
  const [recipes, setRecipes] = useState([]);
  const [message, setMessage] = useState("");
  const [data, setData] = useState();
  const [fruits, setFruits] = useState([]);
  const [count, setCount] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [openForm2, setOpenForm2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showScanner, setShowScanner] = useState(false);
  const [stopStream, setStopStream] = useState(false);
  const [dataCode, setDataCode] = React.useState("");
  const [recipeSearch, setRecipeSearch] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [dietRecipeSearch, setDietRecipeSearch] = React.useState([]);
  const [recentRecipeSearch, setRecentRecipeSearch] = React.useState([]);
  const [searchResult, setSearchResult] = React.useState(null);
  const [isSeraching, setIsSeraching] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [noScan, setNoScan] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const [quantityValues, setQuantityValues] = useState({});
  const [cookingMethod, setCookingMethod] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedKey, setSelectedKey] = useState(0);
  const [showFoodUpload, setShowFoodUpload] = useState(false);
  const [showOptions, setShowOptions] = useState({ 0: true });
  const [loadingText, setLoadingText] = useState("");
  const [description, setDescription] = useState("");
  const [generatingJSON, setGeneratingJSON] = useState(false);
  let [result, setResult] = useState("");
  let [json, setJson] = useState(null);
  let [dataJson, setDataJson] = useState("");
  const resultRef = useRef();
  const jsonRef = useRef();
  const activeList = calendarState.activeLists;

  const user = userState.user;

  const { isLoading: isLoadingRecommendRecipes, data: recommendRecipes } =
    getAllRecommendRecipesQuery(user.uid);
  // const { isLoading: isLoadingUserRecipes, data: userRecipes } = getAllUserRecipesQuery(user.uid);
  const { addRecentMeal } = useAddRecentMeal();
  const { recentMeals, recentMealsIsLoading, recentMealIsFetching } =
    useGetRecentMeal(user.uid);

  const updatedRecipes = recentMeals?.mealData;
  let timer;

  const onTabClick = (tab) => {
    setcurrentTab(tab);
  };

  const getData = async (pageNum) => {
    setLoading(true);
    const config = {
      headers: {
        page: 2,
      },
    };
    try {
      const { data } = await axios.get(
        `https://us.openfoodfacts.org/cgi/search.pl?action=process&json=true&page=${pageNum}`
      );
      setData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const paginate = async (mode, pageNum) => {
    if (isSeraching) {
      try {
        await handleDebounceFn(searchValue, pageNum);
      } catch (err) {}
    } else {
      try {
        await getData(pageNum);
      } catch (err) {}
    }
  };

  const [userStory] = useState([
    `My name is ${userState?.user?.displayName}`,
    `${
      userState?.user?.assignedDietitian
        ? "my dietitian is " + userState?.user?.assignedDietitian?.displayName
        : ""
    }`,
    `${
      userState?.user?.assignedChatGroup
        ? "my dietitian assigned me to " +
          userState?.user?.assignedChatGroup[0]?.uniqueName
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.allergies.length > 0
        ? "my allergies are " +
          userState?.user?.foodPreference?.allergies.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.children
        ? "my children are " + userState?.user?.foodPreference?.children
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.cookingTime
        ? "my best cooking time is " +
          userState?.user?.foodPreference?.cookingTime
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.cuisine
        ? "my best cuisine is " + userState?.user?.foodPreference?.cuisine
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.dislikes.length > 0
        ? "my dislikes are " +
          userState?.user?.foodPreference?.dislikes.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.foodBudget
        ? "my food budget is " + userState?.user?.foodPreference?.foodBudget
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.foodPractice
        ? "i am a " + userState?.user?.foodPreference?.foodPractice
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.healthCondition.length > 0
        ? "my health conditions are " +
          userState?.user?.foodPreference?.healthCondition.join(", ")
        : ""
    }`,
    `${
      userState?.user?.foodPreference?.toddlers
        ? "my toddlers are " + userState?.user?.foodPreference?.toddlers
        : ""
    }`,

    `${
      userState?.user?.nutrients
        ? `my dietitian says i am only allowed to consume carbohydrates ${userState?.user?.nutrients.carbs} gram, protein ${userState?.user?.nutrients.proteins} gram, fat ${userState?.user?.nutrients.fats} gram and calories ${userState?.user?.nutrients.fats} a day`
        : ""
    }`,
    `${
      userState?.user?.quizData?.age
        ? `my age is ${userState?.user?.quizData.age}`
        : ""
    }`,
    `${
      userState?.user?.quizData
        ? `${userState?.user?.quizData.alcohol}, ${userState?.user?.quizData.smoke} and my weight is ${userState?.user?.quizData.weight}`
        : ""
    }`,
    `${
      userState?.user?.quizData?.gender
        ? `my gender is ${userState?.user?.quizData.gender}`
        : ""
    }`,
    `${
      userState?.user?.quizData?.sleep
        ? `i sleep ${userState?.user?.quizData.sleep}`
        : ""
    }`,
  ]);

  const [recipeMessages] = useState([
    {
      role: "system",
      content: `here is information about me use it for all your response \n ${userStory}`,
    },
    {
      role: "system",
      content: `if user greets you, greet them back`,
    },

    {
      role: "system",
      content: `user should only talk about recipes and symptoms`,
    },
    {
      role: "system",
      content: `always add nutritional value to your description`,
    },

    {
      role: "system",
      content: `response to all recipes should have a list part and a json part, list part should be listed like a word doc while json should be in this format 🍳 🍳: {}`,
    },
    {
      role: "system",
      content: `only return recipe when user is requesting for it or user ask for a recipe`,
    },
    {
      role: "system",
      content: `do not give me recipe if any of my allergies is part of ingredients`,
    },
    {
      role: "system",
      content: `do not give me meat or fish recipes if i am vegan or vegetarian,`,
    },
    {
      role: "system",
      content: `you can give me fish and other seafood but excludes other types of meat such as poultry, beef, pork, and lamb recipes if i am pescetarian,`,
    },
    {
      role: "system",
      content: `make sure string values are wrapped in double quotation, i don't need single quotation`,
    },

    {
      role: "system",
      content: ` this is just an instruction.
            if your response is about a recipe add Stove Recipe  as first word on your response and
            return the recipe in a list format like word document,
            add a second response containing only json format of the recipe in this format 🍳 🍳: {} make sure the json does not have text description or a variable holding it, and make sure it is returned last.
            just give me one json object, dont duplicate it,
            the json should contain servings, difficulty, cost, ready_in, type, meal, diet, made_in, cooking_method, recipe_name, ingredients, nutrition and instructions with the same spelling and casing,
            ingredients should be array of object that contains name, quantity as decimal numbers instead of fractions. For example, use 1.5 instead of 1/2, and 2.3 instead of 2/3 and unit.,
            recipe_name should be the name of food to be prepared
            nutrition should contain carbohydrates, protein , fat in grams and calories in kcal the should all be in decimal numbers instead of fractions and rounded off to the highest. For example, use 2 instead of 1.5 or 1/2, and 2 instead of 2.3 or of 2/3,
            instructions should be array of object containing ingredientsUsed, title, description, 
            ingredientsUsed is ingredients used in the step and should be in string separated by , format,
            cost should be either £, ££, £££ or ££££,
            difficulty should be either Low, Medium, High or Pro,
            made_in should be full name of country it was made in,
            cooking_method should be either baked, boiled, braised, broiled, grilled, poached, roasted, sautéed, simmered, steamed, stir-fry or stewed,
            type should be either Starter, Dessert, Side dish, Sauce, Main, Salad, Bread, Soup, Roast, Antipasti, Sandwich, Stew, Cake, Pie or Drink,
            meal should be string of either Breakfast, Launch, Dinner or Snack
            ready_in should be in this format 5 mins and it should be in fives,
            diet should be a either Vegeterian, Vegan, Pescetarian, No pork, No beef, No nuts, Glueten free, Dairy free or N/A,
            the same details in the json should be in the list part too,
            recipe json should be in this format 🍳 🍳: {}.
            servings should be a number, of how many times it can be taken,
            don't add characters like { and } to the description part, only use when it comes to the json part
            do not give me recipes that has more nutrient than my daily consumption
            when i ask for plan meals factor in the my information i gave you about my allergies etc.
            make sure you always return the json in my specified format.
            `,
    },
  ]);

  const addData = async (json) => {
    // setRemove2(true);
    setResult("");
    const data = json;
    if (!data) {
      return;
    }
    try {
      let newData = [];
      setLoading(true);
      // scrollToBottom();
      if (data.instructions) {
        for (let i = 0; i < data.instructions.length; i++) {
          newData.push({
            ingredientsUsed: data?.instructions[i]?.ingredientsUsed
              ? data.instructions[i].ingredientsUsed.split(",")
              : [],
            id: i,
            thumbnail:
              "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
            description: data.instructions[i].description,
            title: data.instructions[i].title,
          });
        }
      } else {
        setLoading(false);
        // setMessages([
        //   ...messages,
        //   {
        //     role: "assistant",
        //     content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
        //   },
        // ]);
        console.log("error 1");
        return false;
      }

      const payload = {
        chefId: userState.user.uid,
        cookingMethod: data.cooking_method,
        cost: data.cost,
        cuisine: data.made_in,
        dietary: data.diet,
        difficulty: data.difficulty,
        groceriesId: [],
        ingredients: data.ingredients,
        meal: [data.meal],
        readyIn: data.ready_in,
        servings: data.servings,
        steps: newData,
        thumbnail:
          "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
        title: data.recipe_name,
        type: data.type,
        nutriments: {
          proteins: data.nutrition.protein,
          carbohydrates: data.nutrition.carbohydrates,
          fat: data.nutrition.fat,
          "energy-kcal": data.nutrition.calories,
        },
      };
      // scrollToBottom();
      const res = await uploadRecipe(payload);

      setLoading(false);
      setJson(null);
      dispatch(getRecipes());
      setRecipeSearch([payload, ...recipeSearch]);

      // setMessages([
      //   ...messages,
      //   {
      //     role: "assistant",
      //     content: `${data.recipe_name} added to your recipe`,
      //     id: res,
      //   },
      // ]);
      // setMessages((prevItems) => {
      //   let prev = prevItems;
      //   prev[id].json = "";

      //   return [...prev];
      // });
      // scrollToBottom();
    } catch (err) {
      console.log(err);
      dispatch(toast({ message: `Sorry try again later` }));
      setLoading(false);
      // setMessages([
      //   ...messages,
      //   {
      //     role: "assistant",
      //     content: `Woops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
      //   },
      // ]);
      // setRemove2(true);
    }
  };

  const handleClick = (id, product) => {
    setSelected([]);
    dispatch(toggle({ id: id }));
    let item = product;
    // if (!item.recipeId) {
    //   item.unitType = product?.quantity?.match(/\d+/g)
    //     ? item.quantity.match(/[a-zA-Z]+/g)[0]
    //     : "percent";
    //   item.typeArray = product?.quantity?.match(/\d+/g)
    //     ? [item.quantity.match(/[a-zA-Z]+/g)[0], "percent"]
    //     : ["percent"];
    //   item.served = product?.quantity?.match(/\d+/g)
    //     ? +product.quantity.match(/\d+/g).map(Number)[0]
    //     : 0;
    //   if (!product?.quantity?.match(/\d+/g)) {
    //     item.quantity = "100 %";
    //     item.served = 100;
    //   }
    // }
    // if (item._id) {
    //   selected.includes(item)
    //     ? setSelected(selected.filter((e) => e._id !== item._id))
    //     : setSelected((prev) => [...prev, item]);
    // }
    // if (item.recipeId) {
    //   selected.includes(item)
    //     ? setSelected(selected.filter((e) => e.recipeId !== item.recipeId))
    //     : setSelected((prev) => [...prev, item]);
    // } else if (item.mid) {
    //   selected.includes(item)
    //     ? setSelected(selected.filter((e) => e.mid !== item.mid))
    //     : setSelected((prev) => [...prev, item]);
    // }
    setSelected([item]);
    // const updatedItems = selected.map((item) => {
    //   return { ...item, served: 70 };
    // });
    // setSelected(updatedItems);
  };

  const isWhatPercentOf = (x, y) => {
    if (x && y) {
      return Math.round((x / y) * 100);
    } else {
      return 0;
    }
  };

  const percentOfNumber = (number, percentage) => {
    return Math.trunc((percentage / 100) * number);
  };

  const onClose = () => {
    if (!isSeraching) {
      dispatch(closeEvents());
      setCount(0);
      setStopStream(true);
      setShowScanner(false);
      setSelected([]);
    } else {
      setIsSeraching(false);
      setStopStream(true);
      setShowScanner(false);
      setSelected([]);
    }
  };

  const handleDebounceFn = async (code, pageNum) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.get(
        `https://us.openfoodfacts.org/cgi/search.pl?tagtype_1=nutrition_grades&tag_1=A&json=true&action=process&search_terms2=${code}&page=${pageNum}`,
        config
      );
      setSearchResult(data);
      if (data.products.length === 0) {
        dispatch(toast({ message: "No food found for your search" }));
      }
      setLoading(false);
      setIsSeraching(true);
      setStopStream(true);
      setShowScanner(false);
    } catch (err) {
      setLoading(false);
      dispatch(toast({ message: "An error occured please try again" }));
    }
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const search = () => {
    // console.log(searchValue, updatedRecipes);

    if (searchValue === "") {
      setRecipeSearch(recipeState.recipes);
      setRecentRecipeSearch(updatedRecipes);
      setDietRecipeSearch(recommendRecipes);
    } else {
      setRecipeSearch(
        recipeState.recipes.filter(
          (item) => item.title && item.title.includes(searchValue)
        )
      );
      setRecentRecipeSearch(
        updatedRecipes.filter(
          (item) => item.name && item.name.includes(searchValue)
        )
      );
      setDietRecipeSearch(
        recommendRecipes.filter(
          (item) => item.title && item.title.includes(searchValue)
        )
      );
      const arr = recipeState.recipes.filter(
        (item) => item.title && item.title.includes(searchValue)
      );
      console.log("arr", arr.length);
      if (arr.length === 0) {
        setcurrentTab(tabs.New);
        setIsError(true);
        setDescription(searchValue);
      }
      // currentTab
    }
    // console.log("up", recentRecipeSearch);
    // console.log("re", dietRecipeSearch);
    // return array.filter(item => item.title.includes(searchText));
  };

  useEffect(() => {
    search();
  }, [searchValue]);

  const checkResult = () => {
    timer = window.setTimeout(() => {
      if (searchResult?.products.length === 0 || searchResult === null) {
        setNoScan(true);
      }
    }, 10000);
  };

  const handleShowMore = (id) => {
    setShowOptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const done = (data) => {
    const title = data?.title
      ? data?.title
        ? data.name
        : data.name
      : data.product_name;
    const payload = {
      data: {
        day:
          day == "0"
            ? "Monday"
            : day == "1"
            ? "Tuesday"
            : day == "2"
            ? "Wednesday"
            : day == "3"
            ? "Thursday"
            : day == "4"
            ? "Friday"
            : day == "5"
            ? "Saturday"
            : day == "6"
            ? "Sunday"
            : "Monday",
        recipe_id: "",
        recipe_name: data?.title || data?.name || data.product_name,
        servings: "",
        ready_in: "",
        image: "",
      },
    };

    // payload.data.recipe_name = ;

    // payload.data.servings = selected[0].servings;
    // payload.data.ready_in = selected[0].readyIn;
    complete(payload);
    setSelected([]);
  };

  useEffect(() => {}, [recipeSearch, reload]);

  // useEffect(() => {
  //   show
  //     ? (document.body.style.overflow = "hidden")
  //     : (document.body.style.overflow = "unset");
  // }, [show]);

  // useEffect(() => {
  //   const data = selected.map((item) => {
  //     if (
  //       item._id &&
  //       quantityValues[item._id]?.number &&
  //       quantityValues[item._id]?.unit
  //     ) {
  //       return {
  //         ...item,
  //         quantity:
  //           quantityValues[item._id]?.number +
  //           " " +
  //           quantityValues[item._id]?.unit,
  //       };
  //     } else if (
  //       item.recipeId &&
  //       quantityValues[item.recipeId]?.number &&
  //       quantityValues[item.recipeId]?.unit
  //     ) {
  //       return {
  //         ...item,
  //         quantity:
  //           quantityValues[item.recipeId]?.number +
  //           " " +
  //           quantityValues[item.recipeId]?.unit,
  //       };
  //     } else if (
  //       item.mid &&
  //       quantityValues[item.mid]?.number &&
  //       quantityValues[item.mid]?.unit
  //     ) {
  //       return {
  //         ...item,
  //         quantity:
  //           quantityValues[item.mid]?.number +
  //           " " +
  //           quantityValues[item.mid]?.unit,
  //       };
  //     }
  //     return item;
  //   });
  //   setSelected(data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [quantityValues]);

  // useEffect(() => {
  //   const data = selected.map((item) => {
  //     if (item.recipeId && cookingMethod[item.recipeId]) {
  //       return { ...item, cookingMethod: cookingMethod[item.recipeId] };
  //     } else if (
  //       item.mid &&
  //       item.type === "recipe" &&
  //       cookingMethod[item.mid]
  //     ) {
  //       return { ...item, cookingMethod: cookingMethod[item.mid] };
  //     }
  //     return item;
  //   });
  //   setSelected(data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cookingMethod]);

  const getAllFruits = async () => {
    setLoading(true);
    try {
      const data = await getFruits();
      setFruits(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
    search();
  }, [open]);

  useEffect(() => {
    try {
      getData(page);
      getAllFruits();
      setCount(activeList.length);
    } catch (err) {}
  }, []);

  return (
    <>
      <div
        className={`${styles.modal} ${open ? styles.open : ""}`}
        onClick={onCloseModal}
      >
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={`${styles.addFoodContainer}`}>
            <div className={styles.modalHeader}>
              <CloseOutlinedIcon
                onClick={() => {
                  if (isSeraching) {
                    setIsSeraching(false);
                    setSearchValue("");
                  } else {
                    onCloseModal();
                  }
                }}
              />

              {/* <div className={styles.headerText}> */}
              <p className={styles.headerText}>
                Add{" "}
                {`${
                  type == "1"
                    ? "Breakfast"
                    : type == "2"
                    ? "Lunch"
                    : type == "3"
                    ? "Dinner"
                    : "Snack"
                }`}
              </p>
              {/* </div> */}
              <div></div>
            </div>
            {/* tablists */}
            {currentTab !== "Food" && (
              <div className={styles.input}>
                <img
                  src={searchIcon}
                  alt="search icon"
                  className={styles.imh}
                />
                <img
                  src={cancelDark}
                  alt="search icon"
                  className={styles.canc}
                  onClick={() => setSearchValue("")}
                />
                <InputBase
                  // autoFocus
                  className={styles.searchInput}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder="recipes"
                  inputProps={{ "aria-label": "search" }}
                  onFocus={() => [
                    // setStopStream(true),
                    // setShowScanner(false),
                  ]}
                />
                {/* <div
                className={styles.items}
                onClick={() => [
                  setShowScanner(!showScanner),
                  setIsSeraching(true),
                  checkResult(),
                  setNoScan(false),
                ]}
              >
                <img
                  className={styles.bar}
                  src={barcode}
                  alt="barcode icon"
                  style={{ margin: "auto" }}
                />
              </div> */}
              </div>
            )}
            {currentTab === "Food" && (
              <div className={styles.input}>
                <img
                  src={searchIcon}
                  alt="search icon"
                  className={styles.imh}
                />
                <img
                  src={cancelDark}
                  alt="search icon"
                  className={styles.canc}
                  onClick={() => setSearchValue("")}
                />
                <InputBase
                  // autoFocus
                  className={styles.searchInput}
                  value={searchValue}
                  onChange={(e) => [
                    setSearchValue(e.target.value),
                    debounceFn(e.target.value),
                  ]}
                  placeholder="Food, recipes or brand"
                  inputProps={{ "aria-label": "search" }}
                  onFocus={() => [
                    //   setIsSeraching(true),
                    setStopStream(true),
                    setShowScanner(false),
                  ]}
                />
                {/* <div
                  className={styles.items}
                  onClick={() => [
                    setShowScanner(!showScanner),
                    setIsSeraching(true),
                    checkResult(),
                    setNoScan(false),
                  ]}
                >
                  <img
                    className={styles.bar}
                    src={barcode}
                    alt="barcode icon"
                    style={{ margin: "auto" }}
                  />
                </div> */}
              </div>
            )}
            <div className={styles.tabsContainer}>
              {!isSeraching && (
                <div className={styles.tabs}>
                  {Object.values(tabs).map((tab, i) => (
                    <p
                      key={i}
                      className={`${styles.tablists} ${
                        currentTab === tab ? styles.active : ""
                      }`}
                      onClick={() => {
                        onTabClick(tab);
                        setIsError(false);
                        setDescription(searchValue);
                      }}
                    >
                      {tab}
                    </p>
                  ))}
                </div>
              )}

              {showScanner && (
                <>
                  <div style={{ marginTop: "30px" }}></div>
                  <div className={styles.holdScanner}>
                    <BarcodeScannerComponent
                      width={"100%"}
                      height={400}
                      onUpdate={(err, result) => {
                        if (result?.text) {
                          setDataCode(result.text);
                        }
                      }}
                    />
                    <div className={styles.indicator}></div>
                    <div className={styles.warn}>
                      Align barcode inside the green box
                    </div>
                    {noScan && (
                      <div className={styles.warna}>
                        Unable to scan or detect barcode, please adjust your
                        camera for better lighting
                      </div>
                    )}
                  </div>
                </>
              )}

              {!showScanner && !isSeraching && (
                <>
                  {currentTab === tabs.Food && (
                    <div
                      className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                    >
                      {data && !loading ? (
                        <>
                          {data.products.map((item, i) => (
                            <div key={i}>
                              {item.product_name_fr || item.product_name ? (
                                <div
                                  className={`${styles.dataBox} ${
                                    selected.includes(item) && styles.add
                                  }`}
                                >
                                  <div className={styles.dataContent}>
                                    <div
                                      style={{
                                        backgroundImage: `url(${item.image_front_thumb_url})`,
                                      }}
                                      className={styles.listImage}
                                    />
                                    <div className={styles.dataText}>
                                      <h6 className={styles.dataTextHeading}>
                                        {item.product_name
                                          ? item.product_name
                                          : item.product_name_fr}
                                      </h6>
                                      <div>
                                        <p className={styles.text}>{`${
                                          item.desc ? item.desc + "." : ""
                                        } ${
                                          item.quantity
                                            ? item.quantity + "."
                                            : ""
                                        }`}</p>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleClick(i, item);
                                      }}
                                    >
                                      <img
                                        src={`${
                                          selected.includes(item)
                                            ? checkedBtn
                                            : addBtn
                                        }`}
                                        alt="add-button"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className={styles.loader}>
                          <CircularProgress />
                        </div>
                      )}

                      {data?.products?.length > 0 && !loading && (
                        <div className={styles.pagination}>
                          <button
                            disabled={page <= 1}
                            className={`${styles.pag} ${styles.left}`}
                            onClick={() => [
                              paginate("prev", page - 1),
                              setPage((prev) => prev - 1),
                            ]}
                          >
                            <KeyboardArrowLeftIcon style={{ color: "white" }} />
                          </button>
                          <div className={styles.num}>{page}</div>
                          <button
                            disabled={page >= data?.page_count}
                            className={`${styles.pag} ${styles.right}`}
                            onClick={() => [
                              paginate("next", page + 1),
                              setPage((prev) => prev + 1),
                            ]}
                          >
                            <KeyboardArrowRightIcon
                              style={{ color: "white" }}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              {isSeraching ? (
                <>
                  {currentTab === tabs.Food && (
                    <div
                      className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                    >
                      {/* <div className={styles.input}>
                        <img
                          src={searchIcon}
                          alt="search icon"
                          className={styles.imh}
                        />
                        <img
                          src={cancelDark}
                          alt="search icon"
                          className={styles.canc}
                          onClick={() => setSearchValue("")}
                        />
                        <InputBase
                          // autoFocus
                          className={styles.searchInput}
                          value={searchValue}
                          onChange={(e) => [
                            setSearchValue(e.target.value),
                            debounceFn(e.target.value),
                          ]}
                          placeholder="Food, recipes or brand"
                          inputProps={{ "aria-label": "search" }}
                          onFocus={() => [
                            //   setIsSeraching(true),
                            setStopStream(true),
                            setShowScanner(false),
                          ]}
                        />
                      </div> */}
                      {loading && (
                        <div className={styles.loader}>
                          <CircularProgress />{" "}
                        </div>
                      )}
                      {searchResult && !loading && (
                        <>
                          {searchResult?.products.map((item, i) => (
                            <div key={i}>
                              {item.product_name_fr || item.product_name ? (
                                <div
                                  className={`${styles.dataBox} ${
                                    selected.includes(item) && styles.add
                                  }`}
                                  key={i}
                                >
                                  {searchResult?.products && (
                                    <div className={styles.dataContent}>
                                      <div
                                        style={{
                                          backgroundImage: `url(${item.image_front_thumb_url})`,
                                        }}
                                        className={styles.listImage}
                                      />
                                      <div className={styles.dataText}>
                                        <h6 className={styles.dataTextHeading}>
                                          {item.product_name
                                            ? item.product_name
                                            : item.product_name_fr}
                                        </h6>
                                        <div>
                                          <p className={styles.text}>{`${
                                            item.desc ? item.desc + "." : ""
                                          } ${
                                            item.quantity
                                              ? item.quantity + "."
                                              : ""
                                          }`}</p>
                                        </div>
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleClick(i, item);
                                        }}
                                      >
                                        <img
                                          src={`${
                                            selected.includes(item)
                                              ? checkedBtn
                                              : addBtn
                                          }`}
                                          alt="add-button"
                                        />
                                      </div>
                                    </div>
                                  )}{" "}
                                  {searchResult?.products?.length === 0 && (
                                    <div>No record found</div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </>
                      )}{" "}
                      {searchResult?.products?.length === 0 || !searchResult ? (
                        <div>
                          {!loading && !showScanner ? (
                            <div className={styles.hold}>
                              <img
                                src={notAvailable}
                                className={styles.noResult}
                                alt="no result"
                              />
                              <div className={styles.texter}>
                                No products available
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {searchResult?.products?.length > 0 && !loading && (
                        <div className={styles.pagination}>
                          <button
                            disabled={pageSearch <= 1}
                            className={`${styles.pag} ${styles.left}`}
                            onClick={() => [
                              paginate("prev", pageSearch - 1),
                              setPageSearch((prev) => prev - 1),
                            ]}
                          >
                            <KeyboardArrowLeftIcon style={{ color: "white" }} />
                          </button>
                          <div className={styles.num}>{pageSearch}</div>
                          <button
                            disabled={pageSearch >= searchResult?.page_count}
                            className={`${styles.pag} ${styles.right}`}
                            onClick={() => [
                              paginate("next", pageSearch + 1),
                              setPageSearch((prev) => prev + 1),
                            ]}
                          >
                            <KeyboardArrowRightIcon
                              style={{ color: "white" }}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : null}

              {/* {currentTab === tabs.History && <div>hi i am History</div>} */}
              {currentTab === tabs.Fruits && (
                <>
                  <div
                    className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                  >
                    {fruits.map((item, i) => (
                      <div key={i}>
                        {item.product_name_fr || item.product_name ? (
                          <div
                            className={`${styles.dataBox} ${
                              selected.includes(item) && styles.add
                            }`}
                          >
                            <div className={styles.dataContent}>
                              <div
                                style={{
                                  backgroundImage: `url(${item.image_front_thumb_url})`,
                                }}
                                className={styles.listImage}
                              />
                              <div className={styles.dataText}>
                                <h6 className={styles.dataTextHeading}>
                                  {item.product_name
                                    ? item.product_name
                                    : item.product_name_fr}
                                </h6>
                                <div>
                                  <p className={styles.text}>{`${
                                    item.desc ? item.desc + "." : ""
                                  } ${
                                    item.quantity ? item.quantity + "." : ""
                                  }`}</p>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  handleClick(i, item);
                                }}
                              >
                                <img
                                  src={`${
                                    selected.includes(item)
                                      ? checkedBtn
                                      : addBtn
                                  }`}
                                  alt="add-button"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {currentTab === tabs.New && (
                <>
                  {isError && (
                    <div>
                      <div className={styles.hr}>
                        Ops! Looks like it’s missing
                      </div>
                      <div className={styles.hm}>
                        Write the name of the dish or product to create one!
                      </div>
                    </div>
                  )}
                  <div className={styles.diet}>
                    <div className={styles.note}>
                      <div className={styles.notes}>Title:</div>
                      <textarea
                        className={styles.textArea}
                        value={description}
                        rows="3"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <button
                        className={styles.btn}
                        style={{ width: "100px" }}
                        onClick={() => {
                          setSelected([{ title: description }]);
                          done({ title: description });
                        }}
                        disabled={loading}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </>
              )}

              {currentTab === tabs.Recipes && (
                <>
                  {recipeState.recipes.recipesIsLoading && (
                    <div className={styles.loader}>
                      <CircularProgress />
                    </div>
                  )}
                  {!recipeState.recipes.recipesIsLoading && (
                    <div
                      className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                    >
                      {recipeSearch.map((recipe, i) => (
                        <div
                          className={`${styles.dataBox} ${
                            selected.includes(recipe) && styles.add
                          }`}
                          key={recipe.recipeId}
                        >
                          <div className={styles.dataContent}>
                            <div
                              style={{
                                backgroundImage: `url(${
                                  recipe.thumbnail
                                    ? recipe.thumbnail
                                    : "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262"
                                })`,
                              }}
                              className={styles.listImage}
                            />
                            <div className={styles.dataText}>
                              <h6 className={styles.dataTextHeading}>
                                {recipe.title}
                              </h6>
                              <div>
                                <p
                                  className={styles.text}
                                >{`${recipe.difficulty}・${recipe.dietary}`}</p>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                handleClick(i, recipe);
                              }}
                            >
                              <img
                                src={`${
                                  selected.includes(recipe)
                                    ? checkedBtn
                                    : addBtn
                                }`}
                                alt="add-button"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              {currentTab === tabs.Recent && (
                <>
                  {(recentMealsIsLoading || recentMealIsFetching) && (
                    <div className={styles.loader}>
                      <CircularProgress />
                    </div>
                  )}
                  {(!recentMealsIsLoading || !recentMealIsFetching) &&
                    updatedRecipes && (
                      <div
                        className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                      >
                        {recentRecipeSearch?.map((meal, i) => {
                          if (meal.type === "food") {
                            return (
                              <div
                                className={`${styles.dataBox} ${
                                  selected.includes(meal) && styles.add
                                }`}
                                key={meal.mid}
                              >
                                <div className={styles.dataContent}>
                                  <div
                                    style={{
                                      backgroundImage: `url(${
                                        meal.thumbnail
                                          ? meal.thumbnail
                                          : "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262"
                                      })`,
                                    }}
                                    className={styles.listImage}
                                  />
                                  <div className={styles.dataText}>
                                    <h6 className={styles.dataTextHeading}>
                                      {meal.name}
                                    </h6>
                                    <div>
                                      <p className={styles.text}>
                                        {`${meal.desc ? meal.desc + "." : ""} ${
                                          meal.quantity
                                            ? meal.quantity + "."
                                            : ""
                                        }`}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleClick(i, meal);
                                    }}
                                  >
                                    <img
                                      src={`${
                                        selected.includes(meal)
                                          ? checkedBtn
                                          : addBtn
                                      }`}
                                      alt="add-button"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (meal.type === "recipe") {
                            return (
                              <div
                                className={`${styles.dataBox} ${
                                  selected.includes(meal) && styles.add
                                }`}
                                key={meal.mid}
                              >
                                <div className={styles.dataContent}>
                                  <div
                                    style={{
                                      backgroundImage: `url(${meal.thumbnail})`,
                                    }}
                                    className={styles.listImage}
                                  />
                                  <div className={styles.dataText}>
                                    <h6 className={styles.dataTextHeading}>
                                      {meal.name}
                                    </h6>
                                    <div>
                                      <p
                                        className={styles.text}
                                      >{`${meal.data.difficulty}・${meal.data.dietary}`}</p>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleClick(i, meal);
                                    }}
                                  >
                                    <img
                                      src={`${
                                        selected.includes(meal)
                                          ? checkedBtn
                                          : addBtn
                                      }`}
                                      alt="add-button"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                  {(!recentMealsIsLoading || !recentMealIsFetching) &&
                    recentMeals &&
                    recentMeals.length === 0 && (
                      <div
                        className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                      >
                        <div className={styles.hold}>
                          <img
                            src={notAvailable}
                            className={styles.noResult}
                            alt="no result"
                          />
                          <div className={styles.texter}>
                            No products available
                          </div>
                        </div>
                      </div>
                    )}
                </>
              )}

              {currentTab === tabs.Dietitian && (
                <>
                  {isLoadingRecommendRecipes && (
                    <div className={styles.loader}>
                      <CircularProgress />
                    </div>
                  )}
                  {!isLoadingRecommendRecipes && (
                    <div
                      className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                    >
                      {recommendRecipes &&
                        dietRecipeSearch &&
                        dietRecipeSearch.map((recipe, i) => (
                          <div
                            className={`${styles.dataBox} ${
                              selected.includes(recipe) && styles.add
                            }`}
                            key={recipe.recipeId}
                          >
                            <div className={styles.dataContent}>
                              <div
                                style={{
                                  backgroundImage: `url(${recipe.thumbnail})`,
                                }}
                                className={styles.listImage}
                              />
                              <div className={styles.dataText}>
                                <h6 className={styles.dataTextHeading}>
                                  {recipe.title}
                                </h6>
                                <div>
                                  <p
                                    className={styles.text}
                                  >{`${recipe.difficulty}・${recipe.dietary}`}</p>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  handleClick(i, recipe);
                                }}
                              >
                                <img
                                  src={`${
                                    selected.includes(recipe)
                                      ? checkedBtn
                                      : addBtn
                                  }`}
                                  alt="add-button"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={selected.length === 0}
          className={styles.searchButton}
          onClick={() => done(selected[0])}
        >
          Add
        </button>
      </div>
    </>
  );
};
