import React from "react";
import styles from "./PricingDetails.module.scss";
import paulinaNPU from "../../../assets/paulina-npu.png";
import Divider from "@material-ui/core/Divider";
import { PRICING } from "./../../../constant";
import Pricing from "../../../components/Pricing/Pricing";
import Layout from "../../../layout";

const PricingDetails = () => {
   return (
    <Layout>
      <div className={styles.page}>
         <div className={styles.initialStage}>
            <div className={styles.imgCon}>
               <img src={paulinaNPU} alt='woman' className={styles.img} />
            </div>

            <div>
               <div className={styles.text}>Food fit for you – easing conditions naturally</div>
               <div className={styles.subText}>
                  Speak to a dietitian, get meal plans, track progress and shop healthy ingredients. Start easing your chronic symptoms or conditions
                  like pregnancy, diabetes and prediabetes, hypertension, celiac & heart disease, chronic kidney failure.
               </div>
               <Divider variant />
            </div>

            <div className={styles.priceContainer}>
               <p className={styles.premuimText} style={{ textAlign: "start" }}>
                  Premium Offers
               </p>
               <div className={styles.pricing}>
                  {PRICING.map((premium) => (
                     <Pricing
                        key={premium.id}
                        icon={premium.icon}
                        title={premium.title}
                        price={premium.price}
                        description={premium.description}
                        background={premium.background}
                        offers={premium.offers}
                        btnBg={premium.button}
                        link={premium.link}
                     />
                  ))}
               </div>
            </div>
         </div>
      </div>
    </Layout>
   );
};

export default PricingDetails;
