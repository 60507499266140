import React from "react";
import styles from './Loader.module.scss'

const Loader = ({text}) => {
  return (
    <div className={styles.con}>
      <div className={styles.ldsEllipsis}><div></div><div></div><div></div><div></div></div> <div className={styles.text}></div>
    </div>
  );
};

export default Loader;
