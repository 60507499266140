import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Layout from "./../../layout";
import NoItem from "./components/NoItem/NoItem";
import Home from "./components/Home/Home";
import Address from "./components/Address/Address";
import Confirm from "./components/Confirm/Confirm";
import Congratulations from "./components/Congratulations/Congratulations";
import { revalidateCart, getUserUsedPromoCode } from "../../state";
import AnimateElement from "../../components/AnimateElement";

//Displays
export const HOME = "HOME";
export const ADDRESS = "ADDRESS";
export const CONFIRM = "CONFIRM";
export const CONGRATULATIONS = "CONGRATULATIONS";

export const getDiscountPrice = (cartItem) => {
  const discountAmount = cartItem.discountPrice - cartItem.price;
  return Math.sign(discountAmount) === -1
    ? `-£${Math.abs(discountAmount).toFixed(2).replace(".", ",")}`
    : `£${Math.abs(discountAmount).toFixed(2).replace(".", ",")}`;
};

export const getPrice = (cartItem) => {
  if (cartItem.quantity >= cartItem.discountQuantity) {
    return cartItem.discountPrice.toFixed(2);
  } else {
    return cartItem.price.toFixed(2);
  }
};

export const getTotalPrice = (cartItem) => {
  let totalPrice = 0;
  if (cartItem.quantity >= cartItem.discountQuantity) {
    totalPrice = cartItem.discountPrice * cartItem.quantity;
  } else {
    totalPrice = cartItem.price * cartItem.quantity;
  }
  return totalPrice.toFixed(2);
};

export const getTotalCartPrice = (accumulator, cartItem) => {
  if (cartItem.groceryId) {
    let price = Number(accumulator) + Number(getTotalPrice(cartItem));
    return price.toFixed(2);
  } else {
    return Number(accumulator);
  }
};

export const getTotalCartDiscount = (accumulator, cartItem) => {
  let discount = 0;
  if (cartItem.groceryId) {
    if (cartItem.quantity >= cartItem.discountQuantity) {
      discount =
        Number(accumulator) +
        (cartItem.price - cartItem.discountPrice) * cartItem.quantity;
    } else {
      discount = Number(accumulator);
    }
  } else {
    discount = Number(accumulator);
  }
  return discount.toFixed(2);
};

const Cart = () => {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState({
    header: true,
    active: HOME,
  });
  const [address, setAddress] = useState("");
  const [whenDate, setWhenDate] = useState(null);

  const cartState = useSelector((state) => state.cart, shallowEqual);
  const userState = useSelector((state) => state.user, shallowEqual);
  const promoCodesState = useSelector(
    (state) => state.promoCodes,
    shallowEqual
  );

  useEffect(() => {
    dispatch(revalidateCart());
    window.scrollTo(0, 0);
  }, [dispatch, display.active]);

  useEffect(() => {
    dispatch(getUserUsedPromoCode(userState?.user?.uid));
  }, [userState?.user?.uid]);

  return (
    <Layout header={display.header}>
      {cartState.cart.length === 0 && display.active === HOME && <NoItem />}

      {cartState.cart.length > 0 && display.active === HOME && (
        <AnimateElement duration={"0.5"}>
          <Home
            setDisplay={setDisplay}
            cartState={cartState}
            userState={userState}
          />
        </AnimateElement>
      )}

      {cartState.cart.length > 0 && display.active === ADDRESS && (
        <AnimateElement duration={"0.5"}>
          {" "}
          <Address
            setDisplay={setDisplay}
            userState={userState}
            address={address}
            setAddress={setAddress}
            whenDate={whenDate}
            setWhenDate={setWhenDate}
          />
        </AnimateElement>
      )}

      {cartState.cart.length > 0 && display.active === CONFIRM && (
        <AnimateElement duration={"0.5"}>
          <Confirm
            setDisplay={setDisplay}
            cartState={cartState}
            userState={userState}
            promoCodesState={promoCodesState}
            address={address}
            whenDate={whenDate}
          />
        </AnimateElement>
      )}

      {display.active === CONGRATULATIONS && (
        <AnimateElement duration={"0.5"}>
          <Congratulations setDisplay={setDisplay} whenDate={whenDate} />
        </AnimateElement>
      )}
    </Layout>
  );
};

export default Cart;
