import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";

import backIcon from "./../../../../assets/icons/back.png";
import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";
import RecipeWidgetList from "../../../../components/RecipeWidgetList/RecipeWidgetList";

import styles from "./RecipesLiked.module.scss";
import AnimateElement from "../../../../components/AnimateElement";

const RecipesLiked = (props) => {
  const { userState } = props;
  const history = useHistory();

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton
          size="small"
          className="mr-auto"
          onClick={() => history.goBack()}
        >
          <Avatar
            alt="back"
            sizes="small"
            variant="square"
            src={backIcon}
            className={styles.arrowback}
          />
        </IconButton>
      </div>
      <h2 className={styles.title}>Favourite Recipes</h2>
      <div className="px-4 pt-4">
        {userState.userLikedRecipesIsLoading === true &&
          userState.userLikedRecipesIsError === false && (
            <Spinner variant="rect" height={140} />
          )}
        {userState.userLikedRecipesIsLoading === false &&
          userState.userLikedRecipesIsError === true && (
            <MessageBox text="Failed to load your favourite recipes" />
          )}
        {userState.userLikedRecipesIsLoading === false &&
          userState.userLikedRecipesIsError === false &&
          userState.userLikedRecipes.length === 0 && (
            <MessageBox text="No favourite recipe added" />
          )}
        {userState.userLikedRecipesIsLoading === false &&
          userState.userLikedRecipesIsError === false && (
            <AnimateElement>
              {" "}
              <RecipeWidgetList recipes={userState.userLikedRecipes} />
            </AnimateElement>
          )}
      </div>
    </div>
  );
};

export default RecipesLiked;
