import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import styles from "./../SignIn.module.scss";
import { Avatar, ButtonBase } from "@material-ui/core";
import helpIcon from "./../../../../assets/icons/help.png";

const validationEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("password is required"),
});

const Main = (props) => {
  const { handleSignInWithEmailAndPassword, setSignInStep } = props;
  return (
    <Formik
      validationSchema={validationEmailSchema}
      initialValues={{ email: "", password: "" }}
      onSubmit={async (values, FormikBag) => {
        await handleSignInWithEmailAndPassword(values.email, values.password);
        FormikBag.setSubmitting(false);
      }}
    >
      {(formik) => (
        <div className={styles.emailPageContent}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            className={styles.textField}
            {...formik.getFieldProps("email")}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={formik.isSubmitting}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            className={styles.textField}
            {...formik.getFieldProps("password")}
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            disabled={formik.isSubmitting}
          />
          <Button
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={formik.submitForm}
            disabled={formik.isSubmitting}
          >
            Login
          </Button>
          <Button
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={() => setSignInStep("reset_email")}
            disabled={formik.isSubmitting}
          >
            Forgotten password
          </Button>
          <a
            href="https://www.stovehealth.com/#help"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonBase className={styles.helpButton}>
              <Avatar className={styles.helpIcon} src={helpIcon} />
              <span>Help</span>
            </ButtonBase>
          </a>
        </div>
      )}
    </Formik>
  );
};

export default Main;
