import React, { useState, useEffect } from "react";
import styles from "./Modal.module.scss";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DateTimePicker } from "../../../components/DateTimePicker/DateTimePicker";
import { TextField } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { toast, setUser } from "../../../state";
import {
  addUserEventsWeight,
  getUserData,
  updateUserEventWeight,
  deleteUserEvents,
} from "../../../services/firebase";
import dayjs from "dayjs";
import * as toObject from "dayjs/plugin/toObject";
import { format } from "date-fns";
dayjs.extend(toObject);

const today = dayjs();

const isEventEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const WeightModal = ({
  showWeight,
  onClose,
  currentEvent,
  selectedTime,
  selectedDate,
  setSelectedTime,
  setSelectedDate,
}) => {
  const calendarState = useSelector((state) => state.calendar, shallowEqual);
  const [weight, setWeight] = useState("");
  const [weightType, setWeightType] = useState("kg");
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;
  const selectedWeightEvent = calendarState.selectedEvent;

  const handleSumbit = async () => {
    const payload = {
      size: weight + weightType,
      hour: selectedTime.format("H"),
      time: selectedTime.format("hh:mm a"),
      id: selectedDate.format("ddd D MMM"),
      date: format(selectedDate.$d, "yyyy-MM-dd"),
    };
    try {
      if (weight) {
        setProcessing(true);
        if (!isEventEmpty(selectedWeightEvent && showWeight)) {
          await updateUserEventWeight(
            user.uid,
            payload,
            selectedWeightEvent.dateCreated
          );
          const userData = await getUserData(user.uid);
          userData && dispatch(setUser(userData));
          setProcessing(false);
          setWeight("");
          dispatch(toast({ message: "Changed successfully" }));
          setTimeout(() => onClose(), 1000);
        } else {
          await addUserEventsWeight(user.uid, payload);
          const userData = await getUserData(user.uid);
          userData && dispatch(setUser(userData));
          setProcessing(false);
          setWeight("");
          dispatch(toast({ message: "Weight added successfully" }));
          setTimeout(() => onClose(), 500);
        }
      } else {
        dispatch(toast({ message: "Add your weight" }));
      }
    } catch (error) {
      setProcessing(false);
      dispatch(toast({ message: "an error occurred adding event" }));
    }
  };

  const handleDelete = async () => {
    setProcessing(true);
    try {
      await deleteUserEvents(user.uid, selectedWeightEvent.dateCreated);
      const userData = await getUserData(user.uid);
      dispatch(setUser(userData));
      setProcessing(false);
      setWeight("");
      dispatch(toast({ message: "Weight deleted successfully" }));
      setTimeout(() => onClose(), 1000);
    } catch (error) {
      setProcessing(false);
      setWeight("");
      dispatch(toast({ message: "an error occurred deleting event" }));
    }
  };

  useEffect(() => {
    if (!isEventEmpty(selectedWeightEvent) && showWeight) {
      setWeight(selectedWeightEvent?.weight?.size.slice(0, -2));
      setSelectedDate(formatDate(selectedWeightEvent.date));
      setSelectedTime(formatTime(selectedWeightEvent.weight.time));
    }
  }, [selectedWeightEvent, showWeight]);

  useEffect(() => {
    showWeight
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [showWeight]);

  return (
    <>
      <div className={`${showWeight ? styles.addWS : styles.hide}`}>
        <div className={styles.sticky}>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <CloseOutlinedIcon onClick={() => {setWeight(""); onClose();}} />
              <p className={styles.text}>Add {currentEvent}</p>
            </div>
            {!isEventEmpty(selectedWeightEvent) ? (
              <DeleteIcon fontSize="small" onClick={handleDelete} />
            ) : (
              <MoreVertIcon fontSize="small" />
            )}
          </div>
        </div>
        <DateTimePicker
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <div className={styles.bt}>
          <TextField
            id="outlined-basic"
            label="Weight"
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
          />
          <div className={styles.selector}>
            <div
              onClick={() => setWeightType("lb")}
              className={weightType === "lb" ? styles.good : styles.bad}
            >
              lb
            </div>
            <div
              onClick={() => setWeightType("kg")}
              className={weightType === "kg" ? styles.good : styles.bad}
            >
              kg
            </div>
          </div>
        </div>

        <ButtonBase
          onClick={handleSumbit}
          disabled={processing}
          className={styles.add}
        >
          {processing ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            `Add Weight`
          )}
        </ButtonBase>
      </div>
    </>
  );
};

const formatTime = (value) => {
  const time = dayjs(value, "hh:mm a");
  return time;
};

const formatDate = (value) => {
  const date = dayjs(value, "YYYY-MM-DD");
  return date;
};
