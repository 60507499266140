import React, { useEffect, useState } from "react";

import { useRouteMatch, useHistory, useParams } from "react-router-dom";
import back from "../../../../../assets/icons/back.png";
import arrowRight from "../../../../../assets/icons/arrowRight.png";
import dayjs from "dayjs";
import styles from "./Weeklist.module.scss";
import { format, lastDayOfMonth, subDays, subMonths } from "date-fns";
import AnimateElement from "../../../../../components/AnimateElement";
import SingleWeekChart from "../SingleWeekChart/SingleWeekChart";
import Footer from "../../../../../layout/Footer/Footer";

const Report = () => {
   const { path } = useRouteMatch();
   const [data, setData] = useState([]);
   const [month, setMonth] = useState(format(new Date(), "MMMM"));
   const [today, setToday] = useState(+format(new Date(), "dd"));
   const [todayShow, setTodayShow] = useState(+format(new Date(), "dd"));
   const history = useHistory();
   const params = useParams();

   const moveTo = (path) => history.push(path);

   useEffect(() => {
      let yesterday = subDays(new Date(), 1);
      let label = [];
      let days = [];
      for (let i = 0; i < 12; i++) {
         let date = subMonths(new Date(), i);
         label.push(format(date, "yyyy-MM"));
         days.push({
            no_of_days: format(lastDayOfMonth(date), "d"),
            monthNo: format(date, "MM"),
            month: format(date, "MMMM"),
            year: format(date, "yyyy"),
            day: format(date, "dd"),
         });
      }
      setData(days);
   }, []);


   return (
      <div>
         {/* <div className={styles.header}>
        <img
          src={back}
          alt="Community"
          className={styles.back}
          onClick={() => history.goBack()}
        />
        <div className={styles.title}>Weekly Reports</div>
        <div></div>
      </div> */}
         <SingleWeekChart />

         <div className={styles.weeks}>
            {data.length > 0 && (
               <>
                  {data.map((value, i) => {
                     return (
                        <AnimateElement duration={"0.5"}>
                           <div className={styles.list} key={i}>
                              <div className={styles.month}>
                                 {value.month} {value.year}
                              </div>
                              {/* <div className={styles.both}>
                                <div className={styles.day}>
                                    <div className={styles.week}>05</div>
                                    <div className={styles.data}>
                                        <div className={styles.title}>14 – 20 Nov</div>
                                        <div className={styles.sub}>data logged:</div>
                                    </div>
                                </div>
                                <div className={styles.enter}>
                                    <img src={arrowRight} alt="Community" className={styles.back} />
                                </div>
                    </div> */}

                              {value.month === month && (
                                 <>
                                    {today <= 21 ? null : (
                                       <div
                                          className={styles.both}
                                          onClick={() =>
                                             moveTo(
                                                `/dietitian/report/${value.year}-${value.monthNo}-22/${
                                                   todayShow === 22
                                                      ? `${value.year}-${value.monthNo}-22`
                                                      : `${value.year}-${value.monthNo}-${todayShow}`
                                                }/${params.userId}`
                                             )
                                          }
                                       >
                                          <div className={styles.day}>
                                             <div className={styles.week}>04</div>
                                             <div className={styles.data}>
                                                {today === 22 ? (
                                                   <div className={styles.title}>22nd {value.month}</div>
                                                ) : (
                                                   <div className={styles.title}>
                                                      22 – {value.no_of_days} {value.month}
                                                   </div>
                                                )}
                                                {/* <div className={styles.sub}>data logged:</div> */}
                                             </div>
                                          </div>
                                          <div className={styles.enter}>
                                             <img src={arrowRight} alt='Community' className={styles.back} />
                                          </div>
                                       </div>
                                    )}
                                 </>
                              )}

                              {value.month !== month && (
                                 <div
                                    className={styles.both}
                                    onClick={() =>
                                       moveTo(
                                          `/dietitian/report/${value.year}-${value.monthNo}-22/${value.year}-${value.monthNo}-${value.no_of_days}/${params.userId}`
                                       )
                                    }
                                 >
                                    <div className={styles.day}>
                                       <div className={styles.week}>04</div>
                                       <div className={styles.data}>
                                          <div className={styles.title}>
                                             22 - {value.no_of_days} {value.month}
                                          </div>
                                          {/* <div className={styles.sub}>data logged:</div> */}
                                       </div>
                                    </div>
                                    <div className={styles.enter}>
                                       <img src={arrowRight} alt='Community' className={styles.back} />
                                    </div>
                                 </div>
                              )}

                              {value.month === month && (
                                 <>
                                    {today <= 14 ? null : (
                                       <div
                                          className={styles.both}
                                          onClick={() =>
                                             moveTo(
                                                `/dietitian/report/${value.year}-${value.monthNo}-15/${
                                                   today === 15
                                                      ? `${value.year}-${value.monthNo}-15`
                                                      : today >= 21
                                                      ? `${value.year}-${value.monthNo}-22`
                                                      : `${value.year}-${value.monthNo}-${todayShow}`
                                                }/${params.userId}`
                                             )
                                          }
                                       >
                                          <div className={styles.day}>
                                             <div className={styles.week}>03</div>
                                             <div className={styles.data}>
                                                {today === 15 ? (
                                                   <div className={styles.title}>15th {value.month}</div>
                                                ) : today >= 21 ? (
                                                   <div className={styles.title}>15 – 21 {value.month}</div>
                                                ) : (
                                                   <div className={styles.title}>
                                                      15 – {todayShow} {value.month}
                                                   </div>
                                                )}
                                                {/* <div className={styles.sub}>data logged:</div> */}
                                             </div>
                                          </div>
                                          <div className={styles.enter}>
                                             <img src={arrowRight} alt='Community' className={styles.back} />
                                          </div>
                                       </div>
                                    )}
                                 </>
                              )}

                              {value.month !== month && (
                                 <div
                                    className={styles.both}
                                    onClick={() =>
                                       moveTo(
                                          `/dietitian/report/${value.year}-${value.monthNo}-15/${value.year}-${value.monthNo}-21/${params.userId}`
                                       )
                                    }
                                 >
                                    <div className={styles.day}>
                                       <div className={styles.week}>03</div>
                                       <div className={styles.data}>
                                          <div className={styles.title}>15 – 21 {value.month}</div>
                                          {/* <div className={styles.sub}>data logged:</div> */}
                                       </div>
                                    </div>
                                    <div className={styles.enter}>
                                       <img src={arrowRight} alt='Community' className={styles.back} />
                                    </div>
                                 </div>
                              )}

                              {value.month === month && (
                                 <>
                                    {today <= 7 ? null : (
                                       <div
                                          className={styles.both}
                                          onClick={() =>
                                             moveTo(
                                                `/dietitian/report/${value.year}-${value.monthNo}-08/${
                                                   today === 8
                                                      ? `${value.year}-${value.monthNo}-08`
                                                      : today >= 14
                                                      ? `${value.year}-${value.monthNo}-14`
                                                      : `${value.year}-${value.monthNo}-${todayShow}`
                                                }/${params.userId}`
                                             )
                                          }
                                       >
                                          <div className={styles.day}>
                                             <div className={styles.week}>02</div>
                                             <div className={styles.data}>
                                                {today === 8 ? (
                                                   <div className={styles.title}>8th {value.month}</div>
                                                ) : today >= 14 ? (
                                                   <div className={styles.title}>08 – 14 {value.month}</div>
                                                ) : (
                                                   <div className={styles.title}>
                                                      08 – {todayShow} {value.month}
                                                   </div>
                                                )}
                                                {/* <div className={styles.sub}>data logged:</div> */}
                                             </div>
                                          </div>
                                          <div className={styles.enter}>
                                             <img src={arrowRight} alt='Community' className={styles.back} />
                                          </div>
                                       </div>
                                    )}
                                 </>
                              )}

                              {value.month !== month && (
                                 <div
                                    className={styles.both}
                                    onClick={() =>
                                       moveTo(
                                          `/dietitian/report/${value.year}-${value.monthNo}-08/${value.year}-${value.monthNo}-14/${params.userId}`
                                       )
                                    }
                                 >
                                    <div className={styles.day}>
                                       <div className={styles.week}>02</div>
                                       <div className={styles.data}>
                                          <div className={styles.title}>08 – 14 {value.month}</div>
                                          {/* <div className={styles.sub}>data logged:</div> */}
                                       </div>
                                    </div>
                                    <div className={styles.enter}>
                                       <img src={arrowRight} alt='Community' className={styles.back} />
                                    </div>
                                 </div>
                              )}

                              {value.month === month && (
                                 <div
                                    className={styles.both}
                                    onClick={() =>
                                       moveTo(
                                          `/dietitian/report/${value.year}-${value.monthNo}-01/${
                                             today === 1
                                                ? `${value.year}-${value.monthNo}-01`
                                                : today >= 7
                                                ? `${value.year}-${value.monthNo}-07`
                                                : `${value.year}-${value.monthNo}-${todayShow}`
                                          }/${params.userId}`
                                       )
                                    }
                                 >
                                    <div className={styles.day}>
                                       <div className={styles.week}>01</div>
                                       <div className={styles.data}>
                                          {value.month === month && (
                                             <>
                                                {today === 1 ? (
                                                   <div className={styles.title}>1st {value.month}</div>
                                                ) : today >= 7 ? (
                                                   <div className={styles.title}>01 – 07 {value.month}</div>
                                                ) : (
                                                   <div className={styles.title}>
                                                      01 – {todayShow} {value.month}
                                                   </div>
                                                )}
                                             </>
                                          )}
                                       </div>
                                    </div>
                                    <div className={styles.enter}>
                                       <img src={arrowRight} alt='Community' className={styles.back} />
                                    </div>
                                 </div>
                              )}

                              {value.month !== month && (
                                 <div
                                    className={styles.both}
                                    onClick={() =>
                                       moveTo(
                                          `/dietitian/report/${value.year}-${value.monthNo}-01/${value.year}-${value.monthNo}-07/${params.userId}`
                                       )
                                    }
                                 >
                                    <div className={styles.day}>
                                       <div className={styles.week}>01</div>
                                       <div className={styles.data}>
                                          {value.month !== month && <div className={styles.title}>01 – 07 {value.month}</div>}
                                       </div>
                                    </div>
                                    <div className={styles.enter}>
                                       <img src={arrowRight} alt='Community' className={styles.back} />
                                    </div>
                                 </div>
                              )}
                           </div>
                        </AnimateElement>
                     );
                  })}
               </>
            )}
            {/* {data.map((value) => {
                    return (
                        <div className={styles.list}>
                            <div className={styles.month}>{value.month}</div>

                            {value.values.map((i) => {
								return (
                                    <div className={styles.both}>
                                    <div className={styles.day}>
                                        <div className={styles.week}>{i.week}</div>
                                        <div className={styles.data}>
                                            <div className={styles.title}>{`${i.days} ${value.month.split(' ')[0]}`}</div>
                                            <div className={styles.sub}>data logged: {i.logged}</div>
                                        </div>
                                    </div>
                                    <div className={styles.enter}>
                                        <img src={arrowRight} alt="Community" className={styles.back} />
                                    </div>
                                </div>
								)
							})}
                        </div>
                    )
                })} */}
         </div>
         <Footer />
      </div>
   );
};

export default Report;
