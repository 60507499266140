import React from "react";
import ReactDOMServer from 'react-dom/server';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { checkIfEmailExistAndPassword } from "../../../../services/firebase";
import { sendRegisterMail } from "../../../../services/twilio";
import { toast } from "../../../../state";

import styles from "./../SignIn.module.scss";

const validationEmailSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email is required"),
});


const ResetEmail = (props) => {
    const { SendResetEmail, setEmail, setSignInStep } = props;
    const dispatch = useDispatch();

    return (
        <Formik
            validationSchema={validationEmailSchema}
            initialValues={{ email: "" }}
            onSubmit={async (values, FormikBag) => {
                try {
                    const methods = await checkIfEmailExistAndPassword(values.email);
                    if (methods.length === 0) {
                        dispatch(toast({ message: "This email does not exist" }));
                        FormikBag.setSubmitting(false);
                        return;
                    } else if (methods.length > 0 && methods.includes("password")) {
                        const response = await sendRegisterMail(values.email, ReactDOMServer.renderToString(<SendResetEmail />))
                        sessionStorage.setItem("resetToken", response.data.token);
                        dispatch(toast({ message: "Verification code has been sent to your email" }));
                        setEmail(values.email);
                        setSignInStep("reset_verify_email");
                    } else {
                        dispatch(toast({ message: "Hey, looks like you're using this email address through another login option. Please change your log in option. Thanks!" }));
                        FormikBag.setSubmitting(false);
                        return;
                    }
                } catch (e) {
                    dispatch(toast({ message: "Failed to send verification code, try again" }));
                    FormikBag.setSubmitting(false);
                }
            }}
        >
            {formik => (
                <div className={styles.emailPageContent}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        className={styles.textField}
                        inputProps={{ autoComplete: "off" }}
                        {...formik.getFieldProps("email")}
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        disabled={formik.isSubmitting}
                    />
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={formik.submitForm} disabled={formik.isSubmitting}>Next</Button>
                </div>
            )}
        </Formik>
    )
}

export default ResetEmail;