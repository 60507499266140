import ITALY from "./assets/icons/italy.png";
import FRANCE from "./assets/icons/france.png";
import UK from "./assets/icons/unitedKingdom.png";
import USA from "./assets/icons/unitedStates.png";
import BRAZIL from "./assets/icons/brazil.png";
import JAPAN from "./assets/icons/japan.png";
import INDIA from "./assets/icons/india.png";
import SWEDEN from "./assets/icons/sweden.png";
import COLOMBIA from "./assets/icons/colombia.png";
import CHINA from "./assets/icons/china.png";
import SA from "./assets/icons/southAfrica.png";
import BANGLEDESH from "./assets/icons/bangladesh.png";
import THAILAND from "./assets/icons/thailand.png";
import GERMANY from "./assets/icons/germany.png";
import IRELAND from "./assets/icons/ireland.png";
import TURKEY from "./assets/icons/turkey.png";
import GREECE from "./assets/icons/greece.png";
import CUBA from "./assets/icons/cuba.png";
import MESSAGING from "./assets/messages-2.png"
import PHONE from './assets/phone-pricing.png'

export default {
  difficulty: {
    label: "Difficulty",
    options: ["Low", "Medium", "High", "Pro"],
  },
  readyIn: {
    label: "Ready in",
    options: [
      "5 mins",
      "10 mins",
      "15 mins",
      "20 mins",
      "30 mins",
      "35 mins",
      "40 mins",
      "45 mins",
      "50 mins",
      "55 mins",
      "60 mins",
      "65 mins",
      "70 mins",
      "75 mins",
      "80 mins",
      "85 mins",
      "90 mins",
    ],
  },
  servings: {
    label: "Servings",
    options: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
    ],
  },
  cost: {
    label: "Cost",
    options: ["£", "££", "£££", "££££"],
  },
  type: {
    label: "Type",
    options: [
      "Starter",
      "Dessert",
      "Side dish",
      "Sauce",
      "Main",
      "Salad",
      "Bread",
      "Soup",
      "Roast",
      "Antipasti",
      "Sandwich",
      "Stew",
      "Cake",
      "Pie",
      "Drink",
    ],
  },
  cuisine: {
    label: "Cuisine",
    options: [
      "American",
      "Cajun",
      "Chinese",
      "Caribbean",
      "English",
      "French",
      "Greek",
      "German",
      "Indian",
      "Iranian",
      "Italian",
      "Japanese",
      "Kenyan",
      "Korean",
      "Lebanese",
      "Mexican",
      "Moroccan",
      "Scottish",
      "Spanish",
      "Thai",
      "Turkish",
      "Vietnamese",
    ],
  },
  date: {
    label: "Date",
    options: ["Today", "This Week", "Upcoming"],
  },
  shopCategories: {
    label: "Shop Categories",
    options: [
      "Herbs and Spices",
      "Oil & Vinegar",
      "Nuts/seeds",
      "Fruits",
      "Dairy Free",
      "Hair and Body",
      "Supplements",
      "Gluten free",
      "Tea & Coffee",
      "High-Protein",
      "Snacks",
      "Breakfast",
      "Low-Sugar",
      "Ready Meals",
      "Chocolate",
      "Kombucha",
      "Meat-free Alternatives",
      "Skincare",
      "Cupboard",
      "Canned",
      "Mens",
      "Organic",
      "Vegan",
      "Vegetarian",
      "Grains",
      "Tofu & Tempeh",
      "Deli",
      "Juice& Smoothies",
      "Yoghurts",
      "Household",
      "Pets",
      "Baby",
      "Pasta & Rice",
      "Soups & Ramen",
      "Health Food",
      "Cooking Sauces",
      "Condiments & Spreads",
      "Bakery",
      "Drinks",
      "Dried Fruits",
      "Baking Mix",
    ],
  },
  dietary: {
    label: "Dietary",
    options: [
      "Vegeterian",
      "Vegan",
      "Pescetarian",
      "No pork",
      "No beef",
      "No nuts",
      "Gluten free",
      "Dairy free",
      "N/A"
    ],
  },
  recipeCategory: {
    label: "Category",
    options: [
      "Vegan",
      "Vegeterian",
      "Gluten free",
      "Pescetarian",
      "Sugar free",
      "Low sugar",
      "Nut free",
    ],
  },
  meal: {
    label: "Meal",
    options: ["Breakfast", "Lunch", "Dinner", "Snack"],
  },
  kitchenTools: {
    label: "kitchen Tools",
    options: [
      "OVEN",
      "MICROWAVE",
      "STOVE",
      "BLENDER",
      "PRESSURE_COOKER",
      "SLOW_COOKER",
      "FRYER",
    ],
  },
  time: {
    label: "Time",
    options: ["MORNING", "AFTERNOON", "EVENING"],
  },
  borough: {
    label: "Borough",
    options: [
      "Barking and Dagenham",
      "Barnet",
      "Bexley",
      "Brent",
      "Bromley",
      "Camden",
      "Croydon",
      "Ealing",
      "Enfield",
      "Greenwich",
      "Hackney",
      "Hammersmith and Fulham",
      "Haringey",
      "Harrow",
      "Havering",
      "Hillingdon",
      "Hounslow",
      "Islington",
      "Kensington and Chelsea",
      "Kingston upon Thames",
      "Lambeth",
      "Lewisham",
      "Merton",
      "Newham",
      "Redbridge",
      "Richmond upon Thames",
      "Southwark",
      "Sutton",
      "Tower Hamlets",
      "Waltham Forest",
      "Wandsworth",
      "Westminster",
    ],
  },
  units: {
    label: "Units",
    options: [
      'Grams',
      'Ounces',
      'Pounds',
      'Millilitres',
      'Litres',
      // "Cup",
      // "Fluid oz.",
      // "Gram",
      // "Kilogram",
      // "Liter",
      // "Millilitre",
      // "Ounce",
      // "Pint",
      // "Pound",
      // "Tablespoon",
      // "Teaspoon",
      // "Bag",
      // "Ball",
      // "Bar",
      // "Block",
      // "Bottle",
      // "Bowl",
      // "Box",
      // "Branch",
      // "Bulb",
      // "Bunch",
      // "Can",
      // "Capsule",
      // "Carton",
      // "Centilitre",
      // "Centimetre",
      // "Cheek",
      // "Chunk",
      // "Clove",
      // "Container",
      // "Cube",
      // "Dash",
      // "Decilitre",
      // "Desert spoon",
      // "Dollop",
      // "Drop",
      // "Ear",
      // "Fillet",
      // "Gallon",
      // "Glass",
      // "Glug",
      // "Grating",
      // "Grind",
      // "Half",
      // "Handful",
      // "Head",
      // "Heart",
      // "Inch",
      // "Jar",
      // "Jug",
      // "Knob",
      // "Loaf",
      // "Log",
      // "Lump",
      // "Millimetre",
      // "Nest",
      // "Package",
      // "Item",
      // "Pinch",
      // "Pouch",
      // "Punnet",
      // "Quart",
      // "Rack",
      // "Rasher",
      // "Rib",
      // "Rind",
      // "Ring",
      // "Sachet",
      // "Scoop",
      // "Segment",
      // "Shaving",
      // "Sheet",
      // "Shooter",
      // "Shot",
      // "Sleeve",
      // "Slice",
      // "Sliver",
      // "Splash",
      // "Sprig",
      // "Sprinkle",
      // "Square",
      // "Stalk",
      // "Stem",
      // "Stick",
      // "Tin",
      // "Tray",
      // "Tub",
      // "Tube",
      // "Wedge",

    ].sort(),
  },

  unitShots: {
    label: "UnitShots",
    options: {
      cm: "centimeters",
      m: "meters",
      mm: "millimeters",
      km: "kilometers",
      ft: "feet",
      in: "inches",
      mi: "miles",
      kg: "kilograms",
      g: "grams",
      mg: "milligrams",
      oz: "ounces",
      lb: "pounds",
      ton: "tons",
      L: "liters",
      l: "liters",
      ml: "milliliters",
      gal: "gallons",
      s: "seconds",
      min: "minutes",
      h: "hours",
      day: "days",
      week: "weeks",
      yr: "years",
      Hz: "hertz",
      kHz: "kilohertz",
      MHz: "megahertz",
      GHz: "gigahertz",
      TB: "terabytes",
      GB: "gigabytes",
      MB: "megabytes",
      kB: "kilobytes",
      B: "bytes",
      "%": "percent",
      "°C": "degrees Celsius",
      "°F": "degrees Fahrenheit",
      K: "kelvin",
    },
  },
};

export const transactionState = ["Getting", "Collect", "Delivery"];

// Hey George, this isn't urgent but when you can, could you change category "Fresh Fruit" to "Fruit, Nuts & Seeds" and "Fresh Vegetables" to "Veg (edited)
export const notifications = [
  {
    title: "Direct Messages",
    description: "From contacts and dietitian",
    slug: "direct_message",
    status: true,
  },
  {
    title: "Community Messages",
    description: "From all the community you joined",
    slug: "community_message",
    status: true,
  },
  {
    title: "General Notifications",
    description: "Get a reminder notification in order to fill your tracking section",
    slug: "general_notification",
    status: true,
  },
  // {
  //   title: "Following and followers",
  //   description: "New followers and accepted Follower request",
  //   label: "on",
  // },
  // {
  //   title: "New member",
  //   description: "Get notified when one of your contact joins Stove Health",
  //   label: "on",
  // },
  // {
  //   title: "New recipes",
  //   description: "From people you follow and related to your health profile",
  //   label: "on",
  // },
  // {
  //   title: "New products",
  //   description: "Based on your tastes and related to your health profile",
  //   label: "on",
  // },
  // {
  //   title: "New articles",
  //   description: "Based on your interests and related to your health profile",
  //   label: "on",
  // },
  // {
  //   title: "Promotional",
  //   option: "Email",
  //   description:
  //     "About new products or discount offers in the Groceries section",
  //   label: "off",
  // },
  // {
  //   title: "News",
  //   description: "About new Stove Health features",
  //   label: "on",
  // },
];

export const macroTags = [
  "Gluten free",
  "Dairy free",
  "Vegan",
  "Vegetarian",
  "Healthy",
  "Pescatarian",
  "Good for Blood Flow",
  "Low-Sugar",
  "Sugar Free",
  "Nut-Free",
  "Sleep",
];

export const trackEvents = ["Breakfast", "Lunch", "Dinner", "Snack"];

export const CUISINE_COUNTRIES = [
  { title: "ITA", value: "ITA", flag: ITALY },
  { title: "FRA", value: "FRA", flag: FRANCE },
  { title: "GBR", value: "GBR", flag: UK },
  { title: "USA", value: "USA", flag: USA },
  { title: "BRA", value: "BRA", flag: BRAZIL },
  { title: "JPN", value: "JPN", flag: JAPAN },
  { title: "IND", value: "IND", flag: INDIA },
  { title: "SWE", value: "SWE", flag: SWEDEN },
  { title: "COL", value: "COL", flag: COLOMBIA },
  { title: "CHN", value: "CHN", flag: CHINA },
  { title: "ZAF", value: "ZAF", flag: SA },
  { title: "BGD", value: "BGD", flag: BANGLEDESH },
  { title: "THA", value: "THA", flag: THAILAND },
  { title: "DEU", value: "DEU", flag: GERMANY },
  { title: "IRL", value: "IRL", flag: IRELAND },
  { title: "TUR", value: "TUR", flag: TURKEY },
  { title: "GRC", value: "GRC", flag: GREECE },
  { title: "CUB", value: "CUB", flag: CUBA },
];

export const MEAL_PREP = [
  "baked",
  "boiled",
  "braised",
  "broiled",
  "grilled",
  "poached",
  "roasted",
  "sautéed",
  "simmered",
  "steamed",
  "stewed",
  "stir-fry",
];

export const PRICING = [
  {
    title: "Community",
    icon: MESSAGING,
    description: 'Chat based support, led by a Registered Dietian',
    price: '£15.00',
    background: 'rgba(19, 15, 38, 0.03)',
    offers: [
      'AItools - new recipe ideas, cooking tips, quickly log your symptoms',
      'Food/Symptom tracker, access to analytics',
      'Learning blogs, writtenby our dietitians',
      'Small chat groups to ask any question, free of misinformation and led by dietitian'
  ],
    button: '#130F26',
    link: 'https://buy.stripe.com/5kA7uSb5vbxdh2g148',
    id: 1
  },
  {
    title: "Personal",
    icon: PHONE,
    description: 'food diary review',
    price: '£25.00',
    background: 'rgba(0, 134, 110, 0.1)',
    offers: [
      'Everything from Community package plus:',
      '1 food review from dietitian, analysing your nutritional intake (40 minute call)',
      'Includes a post review plan, with next steps to reach your goals'
    ],
    button: '#00866E',
    link: 'https://buy.stripe.com/3cs6qO5Lb0Sz5jy3cd',
    id: 2
  },
  {
    title: "Personal Plus",
    icon: PHONE,
    description: '1:1 chat and a monthly online check in of 45 minutes ',
    price: '£50.00',
    background: 'rgba(19, 15, 38, 0.03)',
    offers: [
      'Everything from Personal package plus:',
      '1 food review (every two weeks)',
      '1:1 chat support with your dietitian - 24/7 care.'
    ],
    button: '#2f2e45',
    link: 'https://buy.stripe.com/6oE02qa1r30H4fu28b',
    id: 3
  }
]