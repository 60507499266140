import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import MessageBox from "../../../components/MessageBox/MessageBox";
import Footer from "../../../layout/Footer/Footer";

import { getAllAssignedProfilesQuery } from "../../../services/queries";
import styles from "./PatientList.module.scss";
import AnimateElement from "../../../components/AnimateElement";

const PatientList = (props) => {
  const { path } = props;
  const [showInput, setShowInput] = useState(false);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const userState = useSelector((state) => state.user, shallowEqual);
  const { isLoading, data } = getAllAssignedProfilesQuery(userState.user.uid);

  const handleNavigateToPatientDetails = (uid) => {
    history.push(`${path}/patient-details/${uid}`);
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.headertext}>Patients {data && <span className={styles.tagCount}>{data.length}</span>}</div>
        <IconButton size="small" onClick={() => setShowInput(true)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11.7666"
              cy="11.7666"
              r="8.98856"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.4"
              d="M18.0183 18.4852L21.5423 22.0001"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
      </div>

      {showInput && (
        <div className={styles.inputContainer}>
          <InputBase
            autoFocus
            className={styles.searchInput}
            placeholder="Search patients"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onBlur={() => setShowInput(false)}
          />
        </div>
      )}

      {isLoading && (
        <div className="px-4 pt-4 text-center">
          <CircularProgress size={15} disableShrink />
        </div>
      )}

      {data &&
        data.length === 0 && (
          <MessageBox text="No patient assigned yet" />
        )}

      {data &&
        data.length > 0 &&
        data.filter((user) =>
          user?.displayName?.toLowerCase().includes(searchText.toLowerCase())
        ).length === 0 && (
          <MessageBox text="No patient matches your current search" />
        )}

      <div className={styles.list}>
        {data &&
          data
            .filter((user) =>
              user?.displayName
                ?.toLowerCase()
                .includes(searchText.toLowerCase())
            )
            .map((user) => {
              return (
                <div
                  key={user.uid}
                  className={styles.listItem}
                  onClick={() => handleNavigateToPatientDetails(user.uid)}
                >
                  <AnimateElement duration={"0.5"}>
                    <Avatar
                      alt=""
                      src={user.photoURL}
                      className={styles.listAvater}
                    />
                  </AnimateElement>
                  <div className={styles.listTextContainer}>
                    <div className={styles.patientsText}>
                      {user.displayName}
                    </div>
                    <div></div>
                  </div>
                </div>
              );
            })}
      </div>

      <Footer />
    </div>
  );
};

export default PatientList;
