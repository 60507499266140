import React from "react";
import { format, addDays } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import congratulationsImg from "./../../../../assets/congratulations.png";
import discountIcon from "./../../../../assets/icons/discount-red.png";
import { HOME } from "./../../Cart";

import styles from "./Congratulations.module.scss";

const Congratulations = (props) => {
    const { setDisplay, whenDate } = props;

    const goToHome = () => {
        setDisplay((display) => ({ ...display, header: true, active: HOME }));
    }

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <IconButton size="small" onClick={goToHome}>
                    <CloseOutlinedIcon className={styles.icon} />
                </IconButton>
            </div>
            <div className={styles.content}>
                <img alt="Congratulations" src={congratulationsImg} className={styles.uploadImage} />
                <div className={styles.congratulations}>Payment successful</div>
                <div className={styles.congratulationsLightText}>{whenDate === 1 ? "Tomorrow" : format(addDays(new Date(), whenDate), "EEE, do MMMM yyyy")} between 14:00–16:00, you’ll receive all the products ordered.</div>
                {/* <div className={styles.reciptText}>{"Check the receipt >"}</div>
                <div className={styles.pointContainer}>
                    <div className={styles.pointEarnedContainer}>
                        <Avatar alt="discount" src={discountIcon} className={styles.discountIcon} />
                        <span className="mr-auto">Points earned</span>
                        <span>0</span>
                    </div>
                    <div className={styles.pointCheckContainer}>
                        <span className={styles.curse}>{"Check all points >"}</span>
                    </div>
                    <div className={styles.cicrleLeft} />
                    <div className={styles.cicrleRight} />
                </div> */}
            </div>


        </div>
    )
}

export default Congratulations;
