import React from "react";
import styles from './SplashScreen.module.scss'
import SplashLogo from "./../../assets/stove-logo-transperent.gif";


const SplashScreen = () => {
   return (
      <div className={styles.splashScreen}>
         <img src={SplashLogo} alt='logo' style={{ width: "100%", height: "inherit" }}  />
      </div>
   );
};

export default SplashScreen;
