import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./TaskModal.module.scss";
import { toast, setUser } from "../../../state";
import modalHandle from "../../../assets/modal-handle.png";
import progress from "../../../assets/icons/progress.svg";
import not from "../../../assets/icons/not.svg";
import completed from "../../../assets/icons/complete.svg";
import {
  addUserEventsWater,
  getUserData,
  updateUserTask,
} from "../../../services/firebase/index";
import dayjs from "dayjs";
import { format } from "date-fns";

const today = dayjs();

export const TaskModal = ({ open, onClose, selectedTask }) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;

  useEffect(() => {
    console.log(selectedTask);
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  const select = (id, status) => {
    onClose(id, status);
  };

  const addData = async (status) => {
    try {
      const payload = {
        ...selectedTask,
        status: status,
      };
      const res = await updateUserTask(payload);

      console.log(res);
      select(selectedTask.taskId, status);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        className={`${styles.modal} ${open ? styles.open : ""}`}
        onClick={onClose}
      >
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <img src={modalHandle} alt="" />
            <p className={styles.text}>Status</p>
          </div>

          <div className={styles.icns}>
            <div className={styles.bt} onClick={() => addData("completed")}>
              <div className={styles.icn}>
                <img src={completed} alt="icn" />
              </div>
              <div className={styles.name}>Completed</div>
            </div>

            <div className={styles.bt} onClick={() => addData("in progress")}>
              <div className={styles.icn}>
                <img src={progress} alt="icn" />
              </div>
              <div className={styles.name}>In Progress</div>
            </div>

            <div className={styles.bt} onClick={() => addData("not started")}>
              <div className={styles.icn}>
                <img src={not} alt="icn" />
              </div>
              <div className={styles.name}>Not Started</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
