import React, { Fragment, useState, useEffect, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DayJsUtils from "@date-io/dayjs";
import CALENDER_ICON from "../../../assets/icons/calender-icon.svg";
import reload from "../../../assets/icons/reload.svg";
import { SSE } from "sse";
import { jsonrepair } from "jsonrepair";
import { addDays, endOfWeek, format, isMonday, startOfWeek } from "date-fns";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import styles from "./ShopList.module.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Checkbox, InputAdornment } from "@material-ui/core";
import AnimateElement from "../../../components/AnimateElement";
import {
  addUserMealPlan,
  addUserShoppingList,
  getUserMeal,
  getUserShopList,
} from "../../../services/firebase";
import Head from "../header/header";
import Layout from "../../../layout";

const ShopList = () => {
  const userState = useSelector((state) => state.user, shallowEqual);

  const apiKey = "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";
  const params = useParams();
  const location = useLocation();

  const jsonRef = useRef();
  const dateRef = useRef();
  const resultRef = useRef();
  const serving = useRef(null);

  let index = useRef(0);
  let planList = useRef([]);
  let snackList = useRef([]);
  let foodType = useRef("meal");
  const { date } = params;

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [coming, setComing] = useState(true);
  const [days] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const history = useHistory();
  const moveTo = (path) => history.push(path);
  const [triggerRerender, setTriggerRerender] = useState(false);
  const [allMeals, setAllMeals] = useState("");
  const [cat, setCats] = useState([]);

  const monday = (day) => isMonday(new Date(day));

  const shouldDisableDate = (day) => {
    if (!date) {
      return false;
    }

    return !monday(day);
  };

  const highlightWeekStyle = (day) => {
    if (monday(date) && day.isSame(date, "week")) {
      return { backgroundColor: "#00866e" }; // Customize the background color for the highlighted week
    }
    return {};
  };
  const dataType = `
    {
      data: [
        {
          "title": "[title of category of list to be populated in the array]",
          "value": list of ingredients that falls in the category [
            {
              "item_name": "[name of item]" it should not be a meal, but rather ingredients or products used to prepare the meal,
              "quantity": "[quantity to purchase]",
              "unit": "[unit of measurement in shot hand eg kg, g]",
              "done": [default to false],
            }
          ]
        },
      ]
    }
  `;

  const send = async (meals) => {
    setLoading(true);
    resultRef.current = "";
    jsonRef.current = "";
    let url = "https://api.openai.com/v1/chat/completions";
    let data = {
      model: "gpt-4o",
      // response_format: { type: "json_object" },
      messages: [
        {
          role: "system",
          content: `return the json starting with { and ending with },
                    your response should always be a valid json object,
                    and each value should always be populated with correct data like example given
                    dont return any empty string value
                    `,
        },
        {
          role: "user",
          content: `
          Generate a comprehensive shopping list from the provided meal data. The meal data is formatted as follows: "[${meals}]". 
          The output should be a JSON object adhering to the specified structure ${dataType}.
          Key requirements:
          - Aggregate similar ingredients from all unique meals into a single total quantity, standardizing measurements where necessary.
          - each meal should be broken down to ingredients and spices
          - make sure values return all ingredients used to prepare all the meals on my meal list
          - don't return any meal as item name it should be ingredients and products used to prepare the meal
          - Organize the ingredients into categories as outlined in the provided JSON format. Each category should have a clear title.
          - Use double quotes in the JSON output and ensure there are no empty values.
          - In cases of missing data or ambiguous instructions in recipes, [provide a specific instruction – e.g., skip the ingredient, list it separately, etc.].
          - Please ensure the final output is a well-structured, easy-to-read JSON object that accurately reflects the total ingredients needed for one serving of each recipe provided.
          `,
        },
      ],

      // Generate a comprehensive shopping list from the provided meal data. The meal data is formatted as follows: "[${meals}]".
      // The output should be a JSON object adhering to the specified structure ${dataType}.
      // Key requirements:
      // - For each ingredient in duplicate meals, sum their quantities to reflect the total amount needed.
      // - Aggregate similar ingredients from all unique meals into a single total quantity, standardizing measurements where necessary (e.g., convert all similar measurements to grams or cups).
      // - Organize the aggregated ingredients into categories as outlined in the provided JSON format. Each category should have a clear title.
      // - Use double quotes in the JSON output and ensure there are no empty values.
      // - Please ensure the final output is a well-structured, easy-to-read JSON object that accurately reflects the total ingredients needed for one serving of each recipe provided.

      // - Aggregate similar ingredients from all recipes into a single total quantity, ensuring measurements are standardized (e.g., convert all similar measurements to grams or cups).
      // - Organize the ingredients into categories as outlined in the provided JSON format. Each category should have a clear title.

      // Create an ingredient shopping list for one serving of all recipes listed here [${meals}]
      //               - return in this json format ${dataType}
      //               - make sure all ingredients, food products, and groceries that are used for preparing the all recipes  are accounted for
      //               - separate the list in categories like we have in the json format
      //               - come up with titles for each category
      //               - Use double quotes only and ensure no empty values in the JSON output
      temperature: 0.8,
      top_p: 1,
      max_tokens: 1500,
      stream: true,
      n: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    let source = new SSE(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
        "X-Content-Type-Options": "nosniff",
      },
      method: "POST",
      payload: JSON.stringify(data),
    });

    source.addEventListener("message", (e) => {
      if (e.data === "[DONE]") {
        resultRef.current = convertToJSON(jsonRef.current);

        setCats(resultRef.current.data);
        setTriggerRerender((prev) => !prev);
        save(resultRef.current.data);
      } else {
        let payload = JSON.parse(e.data);
        let text = payload.choices[0].delta.content;
        let data = JSON.parse(e.data).choices[0];
        // let text = JSON.parse(jsonrepair(e.data)).choices[0].text;
        if (data.finish_reason === "stop") {
          source.close();
        } else {
          jsonRef.current = jsonRef.current + text;
          // console.log("text", text);
        }
      }
    });

    source.addEventListener("readystatechange", (e) => {
      if (e.readyState >= 2) {
      }
    });
    source.stream();
    // } else {
    //   dispatch(toast({ message: `Please write a message` }));
    // }
  };

  const convertFractionsToDecimals = (inputString) => {
    const fractionRegex = /(\d+)\s*\/\s*(\d+)/g;

    const convertedString = inputString.replace(
      fractionRegex,
      (match, numerator, denominator) => {
        const decimalValue = numerator / denominator;
        return decimalValue.toString();
      }
    );

    return convertedString;
  };

  const convertToJSON = (string) => {
    // console.log("string", string);
    const jsonStartIndex = string.indexOf("{");
    const jsonEndIndex = string.lastIndexOf("}");

    const jsonString = string.substring(jsonStartIndex, jsonEndIndex + 1);

    let mainJson = null;
    // if (jsonString.length > 2) {
    // console.log("jsonString", string, jsonStartIndex, jsonString, jsonEndIndex);

    try {
      mainJson = JSON.parse(jsonrepair(jsonString));
    } catch (err) {
      // setMessages([
      //   ...messages,
      //   {
      //     role: "assistant",
      //     content: `Oops! We encountered an issue. Please try again or refresh the page. Need further assistance? Contact our support team.`,
      //   },
      // ]);
      // setRemove2(true);
      console.log("eer", err);
    }
    // } else {
    //   console.log("error found me");
    // }
    return mainJson;
  };

  const save = async (data) => {
    setLoading(true);
    setTriggerRerender((prev) => !prev);

    const payload = {
      shoplist: data,
      date,
      serving: serving.current,
    };

    // console.log("payload", payload, userState);
    try {
      await addUserShoppingList(userState.user.uid, payload);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const saveCheck = async (data) => {
    setTriggerRerender((prev) => !prev);

    const payload = {
      shoplist: data,
      date,
      serving: serving.current,
    };

    // console.log("payload", payload, userState);
    try {
      await addUserShoppingList(userState.user.uid, payload);
      setTriggerRerender((prev) => !prev);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getMealPlan = async () => {
    try {
      const data = await getUserMeal(userState.user.uid, date);
      const meals = [];
      if (data) {
        planList.current = data.mealPlan;
        for (let i = 0; i < data.mealPlan.length; i++) {
          if (data.mealPlan[i]?.breakfast?.recipe_name) {
            meals.push(data.mealPlan[i].breakfast.recipe_name);
          }
          if (data.mealPlan[i].lunch) {
            meals.push(data.mealPlan[i]?.lunch?.recipe_name);
          }
          if (data.mealPlan[i].dinner) {
            meals.push(data.mealPlan[i]?.dinner?.recipe_name);
          }
        }
        for (let i = 0; i < data.snackPlan.length; i++) {
          meals.push(data.snackPlan[i]?.snack?.recipe_name)
        }
        setAllMeals(meals.join(", "));
        send(meals.join(", "));
      } else {
        setTriggerRerender((prev) => !prev);
        setLoading(false);
        setMessage("No meal plan associated with this date");
      }
      setTriggerRerender((prev) => !prev);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getShopList = async () => {
   
    setMessage("");
    try {
      const data = await getUserShopList(userState.user.uid, date);
      if (data) {
        setCats(data.shoplist);
        if (data.serving) {
          serving.current = data.serving;
        } else {
          serving.current = 1;
        }
        setLoading(false);
      } else {
        setCats([]);
        serving.current = 1
        getMealPlan();
      }
      setTriggerRerender((prev) => !prev);
      // setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const daysOfWeek = [];
  //   for (let i = 0; i < 7; i++) {
  //     const nextDate = addDays(currentDate, i);
  //     const dayOfWeek = format(nextDate, "EEEE");

  //     daysOfWeek.push(dayOfWeek);
  //   }
  //   setDays(daysOfWeek);
  // }, [triggerRerender]);

  useEffect(() => {
    const newDate = format(new Date(), "yyyy-MM-dd");
    if (date) {
      getShopList();
      // setLoading(false)
    } else {
      if (isMonday(new Date(newDate))) {
        moveTo(`/list/${newDate}`);
      } else {
        let day = format(
          startOfWeek(new Date(newDate), { weekStartsOn: 1 }),
          "yyyy-MM-dd"
        );

        moveTo(`/list/${day}`);
      }
    }
  }, [date]);

  const CustomDisplayComponent = ({ value, onClick }) => {
    // Your custom logic to determine what to display in the DatePicker
    const displayValue = "Select a date";

    return (
      <div onClick={onClick}>
        {date && (
          <span>
            {/* const sevenDaysFromNow = addDays(today, 7); */}
            {format(new Date(date), "d MMM")} -{" "}
            {format(new Date(addDays(new Date(date), 6)), "d MMM")}
            <img className={styles.cal} src={CALENDER_ICON} alt="calendar" />
          </span>
        )}
      </div>
    );
  };

  return (
    <Layout header={false}>
      <div style={{ paddingBottom: "100px" }}>
        <Head />
        {message && <div className={styles.message}>{message}</div>}
        <div className={styles.page}>
          {loading && (
            <div className={styles.con}>
              <div className={styles.ldsEllipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>{" "}
              <div className={styles.text}>{}</div>
            </div>
          )}
          <div className={styles.dats}>
            <div className={styles.date}>
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <DatePicker
                  TextFieldComponent={CustomDisplayComponent}
                  format="D MMM"
                  value={date}
                  shouldDisableDate={shouldDisableDate}
                  onChange={(selectedDate) => {
                    moveTo(
                      `/list/${format(selectedDate.$d, "yyyy-MM-dd")}`
                    );
                    // getMealPlan();
                    setLoading(true);
                    setTriggerRerender((prev) => !prev);
                  }}
                  className={styles.clear}
                  InputProps={{
                    disableUnderline: true,
                    className: styles.clear,
                    endAdornment: (
                      <InputAdornment position="start">
                        <img
                          className={styles.cal}
                          src={CALENDER_ICON}
                          alt="calendar"
                        />
                      </InputAdornment>
                    ),
                  }}
                  renderDay={(
                    day,
                    selectedDate,
                    isInCurrentMonth,
                    dayComponent
                  ) => {
                    const dayStyles = highlightWeekStyle(day);
                    return React.cloneElement(dayComponent, {
                      style: dayStyles,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            {cat.length > 0 && (
              <img
                className={styles.reload}
                src={reload}
                alt="reload icon"
                onClick={() => {
                  setCats([]);
                  getMealPlan();
                }}
              />
            )}
          </div>
          {!loading && (
            <div className={styles.col}>
              <div className={styles.typ}>Select Serving</div>
              <div className={styles.calculator}>
                <span
                  className={styles.button}
                  onClick={() => {
                    if (serving.current >= 2) {
                      serving.current = serving.current - 1;
                    }
                    saveCheck(cat);
                  }}
                >
                  <IconButton size="small" color="inherit">
                    <RemoveIcon fontSize="small" color="inherit" />
                  </IconButton>
                </span>
                <span className={styles.number}>{serving.current}</span>
                <span
                  className={styles.button}
                  onClick={() => {
                    serving.current = serving.current + 1;
                    saveCheck(cat);
                  }}
                >
                  <IconButton size="small" color="inherit">
                    <AddIcon fontSize="small" color="inherit" />
                  </IconButton>
                </span>
              </div>
            </div>
          )}

          {coming ? (
            <div className={styles.list}>
              {cat.map((item, i) => (
                <div key={i}>
                  <div className={styles.head}>{item.title}</div>
                  {item.value.map((data, t) => (
                    <div className={styles.body} key={t}>
                      <div className={styles.sing}>
                        <div className={styles.both}>
                          <div className={styles.title}>{data?.item_name}</div>
                          <div className={styles.unit}>
                            {data?.quantity * serving.current} {data?.unit}
                          </div>
                        </div>

                        <div className={styles.check}>
                          {" "}
                          <Checkbox
                            checked={data?.done}
                            onChange={(e) => {
                              let tempData = cat;
                              tempData[i].value[t].done = !cat[i].value[t].done;
                              setCats(tempData);
                              saveCheck(tempData);
                              setTriggerRerender((prev) => !prev);
                            }}
                            name={"me"}
                            color="primary"
                          />
                        </div>
                      </div>
                      {data?.done && <div className={styles.strike}></div>}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                marginTop: "200px",
                // marginLeft: "-20px",
                color: "#00866e",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ShopList;
