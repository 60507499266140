import { useMutation, useQueryClient } from "react-query";
import { updatePatientNutrients, toggleShowFeatures, updateFoodDairyStatus, addRecentMealData } from "./../firebase";
import { GET_RECENT_MEAL_DATA } from "../queries/queryKeys";

export const useUpdatePatientNutrients = () => {
   const { mutateAsync: updatePatientNutrientData, isLoading } = useMutation(updatePatientNutrients);
   return { updatePatientNutrientData, isLoading };
};

export const useToggleShowFeatures = () => {
   const { mutateAsync: toggleShowFeature } = useMutation(toggleShowFeatures);
   return { toggleShowFeature };
};

export const useFoodDairyStatusMutation = () => {
   const { mutateAsync: updateFoodDairyStatusData } = useMutation(updateFoodDairyStatus);
   return { updateFoodDairyStatusData };
};

export const useAddRecentMeal = () => {
   const queryClient = useQueryClient();
   const { mutateAsync: addRecentMeal, isLoading: isAddingMealData } = useMutation(addRecentMealData, {
      onSuccess: () => {
         queryClient.invalidateQueries(GET_RECENT_MEAL_DATA);
      },
   });
   return { addRecentMeal, isAddingMealData };
};
