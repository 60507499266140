import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import styles from "./Layout.module.scss"

const Layout = (props) => {
    const { header, headerProps, footer, footerProps, children, spacer } = props;
    return (
        <>
            {header && <Header {...headerProps} />}
            {spacer && <div className={styles.spacer}></div>}
            {children}
            {footer && <Footer {...footerProps} />}
        </>
    )
}
Layout.defaultProps = {
    header: true,
    headerProps: {},
    footer: true,
    footerProps: {},
    spacer: true

};

export default Layout;