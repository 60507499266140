import React, { useState, useEffect, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import modalHandle from "../../../assets/modal-handle.png";
import dayjs from "dayjs";


import emptyBg from "./../../../assets/empty-bg.png";
import { setUser, toast, } from "../../../state";
import { getSingleRecipeQuery } from "../../../services/queries";
import { DateTimePicker } from "./../../../components/DateTimePicker/DateTimePicker";
import { getUserData, addUserEventsRecipe } from "../../../services/firebase";
import { CircularProgress, IconButton, ButtonBase, Button } from "@material-ui/core";



import Div100vh from 'react-div-100vh';

import styles from "./Plan.module.scss";
import * as isToday from "dayjs/plugin/isToday";
dayjs.extend(isToday);
const today = dayjs();


const Plan = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { recipeId } = useParams();
    const { isLoading: isLoadingRecipe, data: recipe } = getSingleRecipeQuery(recipeId);
    const userState = useSelector((state) => state.user, shallowEqual);
    const [selectedTime, setSelectedTime] = useState(today);
    const [selectedDate, setSelectedDate] = useState(today);
    const [loading, setLoading] = useState(false);
    const user = userState.user;



    const submit = async () => {
        setLoading(true);
        const payload = {
            id: selectedDate.format("ddd D MMM"),
            time: selectedTime.format("hh:mm a"),
            hour: selectedTime.format("H"),
            minute: selectedTime.format("mm"),
            recipeId: recipe.recipeId,
            thumbnail: recipe.thumbnail,
            title: recipe.title,
        };
        try {
            await addUserEventsRecipe(user.uid, payload);
            const userData = await getUserData(user.uid);
            dispatch(setUser(userData));
            dispatch(toast({ message: "event added successfully" }));
            setLoading(false);
            history.goBack();
        } catch (error) {
            dispatch(toast({ message: "an error occurred adding event" }));
            setLoading(false);
        }
    };





    return (
        <Div100vh className={styles.page} onClick={() => history.goBack()}>
            <div className={styles.header}>
                <IconButton>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.34379 12.9945L12.657 1.68121" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.6573 12.9943L1.34405 1.6811" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </IconButton>
                <span className={styles.headerTitle}>Plan Recipe</span>
            </div>

            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <img src={modalHandle} alt="" />
                </div>
                {isLoadingRecipe && (
                    <div className="px-4 py-4 text-center">
                        <CircularProgress size={15} disableShrink />
                    </div>
                )}
                {recipe && (
                    <div>
                        <div className={styles.dateContainer}>
                            <DateTimePicker
                                selectedTime={selectedTime}
                                setSelectedTime={setSelectedTime}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                            />
                        </div>
                        <div className={styles.recipeTitle}>{recipe.title}</div>
                        <div style={{ backgroundImage: `url(${recipe?.thumbnail ? recipe.thumbnail : emptyBg})` }} className={styles.img} />
                        <div className={styles.ingredient}>
                            <span>Ingredients:</span>
                            {recipe.ingredients.map((ingredient, index) => <span key={ingredient.name}> {ingredient.name}{index + 1 !== recipe.ingredients.length ? "," : ""} </span>)}
                        </div>

                        {/* <div className={styles.energyContainer}>
                            <div>
                                <div className={styles.energyItemTitle}>Kcal/day</div>
                                <div className={styles.energyItemBar} />
                                <div className={styles.energyItemValue}>224</div>
                            </div>
                            <div>
                                <div className={styles.energyItemTitle}>Carbs</div>
                                <div className={styles.energyItemBar} />
                                <div className={styles.energyItemValue}>8%</div>
                            </div>
                            <div>
                                <div className={styles.energyItemTitle}>Protein</div>
                                <div className={styles.energyItemBar} />
                                <div className={styles.energyItemValue}>3%</div>
                            </div>
                            <div>
                                <div className={styles.energyItemTitle}>Fat</div>
                                <div className={styles.energyItemBar} />
                                <div className={styles.energyItemValue}>1.8%</div>
                            </div>
                        </div> */}

                        <ButtonBase className={styles.seeAll} onClick={() => history.push(`/recipe/${recipe.recipeId}`)}>
                            <span>See recipe detail</span>
                            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6.5L4 4L1 1.5" stroke="#00866E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBase>
                    </div>

                )}
            </div>
            {recipe && (
                <>
                    <Button disabled={loading} className={styles.deleteButton} onClick={() => history.goBack()}>Delete</Button>
                    <Button disabled={loading} className={styles.confirmButton} onClick={(e) => [e.stopPropagation(), submit()]}>{loading ? <CircularProgress style={{ color: "white", width: "20px", height: "20px" }} /> : "Confirm"}</Button>
                </>
            )}
        </Div100vh>
    );
};

export default Plan;