import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import { withOrientationChange, isMobileOnly } from "react-device-detect";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core//MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { toast } from "../../../state";
import {
  getAppUserQuery,
  getSessionQuery,
  getUserSessionsQuery,
} from "../../../services/queries";
import {
  addSession,
  updateSession,
  deleteSession,
  addUserPlan,
} from "../../../services/firebase";
import styles from "./UserPlan.module.scss";
import AnimateElement from "../../../components/AnimateElement";
import { format, sub } from "date-fns";

const validationSchema = Yup.object().shape({
  notes: Yup.array().of(Yup.string()).min(1, "Add a note"),
});

const UserPlan = (props) => {
  const [initialValues, setInitialValues] = useState({
    notes: [],
  });
  const [typingNoteIndex, setTypingNoteIndex] = useState(null);
  const [typingPointndex, setTypingPointIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeActive, setTypeActive] = useState(null);
  const [indexActive, setIndexActive] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientId, sessionId } = useParams();
  const { data } = getAppUserQuery(patientId);
  const { data: sessionData } = getSessionQuery(sessionId);

  const moveTo = (path) => history.push(path);

  useEffect(() => {
    if (data?.plan) {
      setInitialValues({notes: data.plan});
    }
  }, [data]);


  const goBack = () => {
    history.goBack();
  };

  const handleMenuClick = (event, type, index) => {
    setAnchorEl(event.currentTarget);
    setTypeActive(type);
    setIndexActive(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTypeActive(null);
    setIndexActive(null);
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <IconButton className={styles.back} size="small" onClick={goBack}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.00065 12.3379L20 12.3379"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <AnimateElement duration={"0.5"}>
            <Avatar className={styles.avater} src={data && data.photoURL} />
          </AnimateElement>
          <div className={styles.headerContentcontainer}>
            <div className={styles.headerTitle}>Plan</div>
            <div className={styles.headerSubText}>
              {data && data.displayName}
            </div>
          </div>
        </div>

        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (values, FormikBag) => {
            FormikBag.setSubmitting(true);
            await addUserPlan(patientId, values.notes)
            console.log('data',values);
          }}
        >
          {(formik) => {
            return (
              <>
                <div className={styles.container}>

                  <div className={styles.listHeaderContainer}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 2V5"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 2V5"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M7 13H15"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M7 17H12"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Plan</span>
                  </div>
                  <FieldArray
                    name="notes"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.notes.map((note, index) => (
                          <Fragment key={index}>
                            {index === typingNoteIndex ? (
                              <InputBase
                                autoFocus
                                fullWidth
                                multiline
                                rows={4}
                                className={styles.descriptionInput}
                                {...formik.getFieldProps(`notes[${index}]`)}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter")
                                    setTypingNoteIndex(null);
                                }}
                              />
                            ) : (
                              <div className={styles.listText}>
                                <span>→</span>
                                <span className={styles.listTextValue}>
                                  {note}
                                </span>
                                <IconButton
                                  className={styles.listMoreIcon}
                                  size="small"
                                  onClick={(event) =>
                                    handleMenuClick(event, "note", index)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {typeActive === "note" &&
                                  indexActive === index && (
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          setTypingNoteIndex(index);
                                          handleMenuClose();
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setTypingNoteIndex(null);
                                          handleMenuClose();
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  )}
                              </div>
                            )}
                          </Fragment>
                        ))}
                        <div
                          onClick={() => {
                            if (
                              typeof typingNoteIndex === "number" &&
                              formik.values.notes[typingNoteIndex].length === 0
                            )
                              return;
                            if (
                              typeof typingNoteIndex === "number" &&
                              formik.values.notes[typingNoteIndex].length > 0
                            ) {
                                console.log('tester', formik);
                              setTypingNoteIndex(null);
                              setTimeout(() => {
                                setTypingNoteIndex(formik.values.notes.length);
                                arrayHelpers.push("");
                              }, 500);
                            } else {
                              setTypingNoteIndex(formik.values.notes.length);
                              arrayHelpers.push("");
                            }
                          }}
                          className={styles.addListText}
                        >
                          → Add another point
                        </div>
                      </>
                    )}
                  />
                </div>
                <ButtonBase
                  className={styles.confirmButton}
                  onClick={formik.submitForm}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Saving..." : "Save"}
                </ButtonBase>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default withOrientationChange(UserPlan);
