import React, { useState } from "react";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import Chip from '@material-ui/core/Chip';

import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';


import styles from "./GroceriesTab.module.scss";
import constant from "../../../../constant";

const cuisine = constant.cuisine.options;



const GroceriesTab = (props) => {
    const { localCuisines, setLocalCuisines, groceriesTagSearch, toggleGroceriesTagSearch } = props;
    const [selectedCuisine, setSelectedCuisine] = useState(cuisine[0].toLowerCase());

    const addToLocalCuisines = (value) => {
        const newLocalCuisines = [...localCuisines, value];
        setLocalCuisines([...new Set(newLocalCuisines)]);
    }

    return (
        <div className={styles.page}>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Home country</span>
                {localCuisines.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={groceriesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleGroceriesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
                <div className={styles.selectAndAddContainer}>
                    <Select
                        className={styles.selectInput}
                        classes={{ select: styles.select, icon: styles.dropDownIcon }}
                        input={<InputBase />}
                        IconComponent={ArrowBackIosOutlinedIcon}
                        value={selectedCuisine}
                        onChange={(event) => setSelectedCuisine(event.target.value)}
                    >
                        {cuisine.map((value) => (
                            <MenuItem key={value} disabled={localCuisines.includes(value.toLowerCase())} value={value.toLowerCase()}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    <ButtonBase
                        disabled={localCuisines.includes(selectedCuisine)}
                        onClick={() => addToLocalCuisines(selectedCuisine)}
                        className={styles.addIcon}
                    >
                        <AddOutlinedIcon />
                    </ButtonBase>
                </div>
            </div>

        </div>
    )
}

export default GroceriesTab;