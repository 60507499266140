import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import * as Sentry from "@sentry/browser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/scss/bootstrap.scss";
import "./styles/variables.css";
import "./styles/style.scss";
import 'draft-js/dist/Draft.css';
import { stripeKeyProduction, stripeKeyDevelopment } from "./env";
import App from "./App";
import store from "./state";


const stripePromise = loadStripe(
  window.location.hostname.includes("app.stovecook")
    ? stripeKeyProduction
    : stripeKeyDevelopment
);

Sentry.init({
  dsn: "https://493558c1f9414acfb7d8708be6ead5a5@o381352.ingest.sentry.io/5208562",
  environment: window.location.hostname.includes("app.stovecook") ? "production" : "development"
});

const theme = createTheme({
  typography: {
    fontFamily: [
      'Epilogue',
      'Roboto',
      'sans-serif',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: "#EE3D3F",
      // rgba(0, 134, 110, 0.06),
      main: "#00866E",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  zIndex: {
    modal: 5000,
    snackbar: 5001
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      },
    },
  },
});

const queryClient = new QueryClient()


ReactDOM.render(

  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <Router>
            <App />
          </Router>
        </Elements>
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
