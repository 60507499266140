import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactDOMServer from 'react-dom/server';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';

import PinInput from "./../../../../components/PinInput/PinInput";

import { sendRegisterMail, verifyRegisterMail } from "./../../../../services/twilio";
import { toast } from "./../../../../state";

import styles from "./../Register.module.scss";

const validationPinSchema = Yup.object().shape({
    pin: Yup.number().required(),
});



const VerifyEmail = (props) => {
    const { SendRegisterEmail, email, setEmail, setRegisterStage } = props;
    const timeId = useRef();
    const dispatch = useDispatch();
    const [time, setTime] = useState("");
    const [timeUp, setTimeUp] = useState(false);
    const [isResending, setIsResending] = useState(false);


    useEffect(() => {
        if (!email || !sessionStorage.getItem("token")) {
            setRegisterStage("email");
        }
    }, [])


    const startTimer = useCallback((setValue) => {
        let timer = 60 * 3;
        let minutes;
        let seconds;
        clearInterval(timeId.current);
        timeId.current = setInterval(function () {
            minutes = parseInt(`${timer / 60}`, 10);
            seconds = parseInt(`${timer % 60}`, 10);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            setValue(`${minutes}:${seconds}`);
            if (--timer < 0) {
                clearInterval(timeId.current);
                setTimeUp(true);
            }
        }, 1000);
    }, [])



    const onResendPinSubmit = useCallback(async (formik) => {
        try {
            setIsResending(true);
            const response = await sendRegisterMail(email, ReactDOMServer.renderToString(<SendRegisterEmail />))
            sessionStorage.setItem("token", response.data.token);
            dispatch(toast({ message: "New verification code has been sent to your email" }));
            startTimer(setTime);
            setTimeUp(false);
            formik.resetForm();
            setIsResending(false);
        } catch (e) {
            dispatch(toast({ message: "Failed to resend verification code, try again" }));
            setIsResending(false);
        }
    }, [])


    const onChangeEmail = useCallback(() => {
        setEmail("");
        setRegisterStage("email");
    }, [])



    useEffect(() => {
        startTimer(setTime);
        return () => {
            clearInterval(timeId.current);
        }
    }, [])

    return (

        <Formik
            validationSchema={validationPinSchema}
            initialValues={{ pin: "" }}
            onSubmit={async (values, FormikBag) => {
                try {
                    await verifyRegisterMail(email, values.pin, sessionStorage.getItem("token") || "");
                    setRegisterStage("finish");
                } catch {
                    dispatch(toast({ message: "Invalid code" }));
                    FormikBag.setSubmitting(false);
                }
            }}

        >
            {formik => (
                <div className={styles.emailPageContent}>
                    <div className={styles.subTitle}>Enter the 6 digit code sent to your email {email}</div>
                    <PinInput className={styles.textField} value={formik.values.pin} onChange={pin => formik.setFieldValue("pin", pin)} disabled={formik.isSubmitting || isResending} />
                    <>
                        {timeUp ? (
                            <span className={styles.helperText}>Your verification code has timed out</span>
                        ) : (
                                <span className={styles.helperText}> This code expires in <span className={styles.timer}>{time}</span> minutes</span>
                            )}
                    </>
                    {timeUp ? (
                        <Button disableElevation fullWidth variant="contained" color="primary" className={styles.resend} onClick={() => onResendPinSubmit(formik)} disabled={!timeUp || formik.isSubmitting || isResending}>
                            <span>{isResending ? "Resending..." : "Resend code"}</span>
                        </Button>
                    ) : (
                            <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={formik.submitForm} disabled={timeUp || formik.isSubmitting || formik.values.pin.length < 6}>Next</Button>
                        )}
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={onChangeEmail} disabled={formik.isSubmitting || isResending} >Restart</Button>
                </div>
            )}
        </Formik>
    )
}

export default VerifyEmail;