import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./HealthProfile.module.scss";
import NavigationHeader from "../../../components/NavigationHeader/NavigationHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Divider from "@material-ui/core/Divider";
import { CardFoodPreferences } from "./Card/Card";

const HealthProfile = () => {
   const history = useHistory();
   const location = useLocation();
   const localState = localStorage.getItem('expandedState');
   const [expanded, setExpanded] = useState( Number(localState) || false);

   const HEALTH_PROFILE = [
      {
         title: "Food preferences",
         subHeading: "Redo the whole questionnaire",
         type: "foodPreference",
      },
      {
         title: "Quiz",
         subHeading: "Redo the whole questionnaire",
         type: "intakeForm",
      },
   ];

   const moveTo = (path) => history.push(path);

   const handleChange = (panel) => (event, newExpanded) => {
      localStorage.setItem("expandedState", newExpanded ? panel : false) 
      setExpanded(newExpanded ? panel : false);
   };

   const handleOpenQuestionaire = (type) => {
      type === "foodPreference" && moveTo('/onboarding')
      type === "intakeForm" && moveTo('/track/redo/0')
      ;
   };

   useEffect(() => {
     if(location.pathname.includes('health-profile') && localState){
         setExpanded(Number(localState))
     }
   }, [localState, location.pathname])

   return (
      <div className={styles.page}>
         {/* <NavigationHeader text='Health Profile' /> */}
         <div className={styles.accordionContainer}>
            {/* <Divider className={styles.divider} light /> */}
            {HEALTH_PROFILE.map((item, index) => {
               return (
                  <Accordion
                     classes={{
                        root: styles.accordion,
                        rounded: styles.rounded,
                        expanded: styles.expanded,
                     }}
                     expanded={expanded === index}
                     onChange={handleChange(index)}
                     key={index.toString()}
                  >
                     <AccordionSummary
                        expandIcon={expanded === index ? <RemoveCircleIcon  /> : <AddCircleIcon  />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                        classes={{
                           root: styles.accordionSummary,
                        }}
                     >
                        <div className={styles.titleContainer}>
                           <Typography className={styles.heading}>{item.title}</Typography>
                        </div>
                     </AccordionSummary>
                     <AccordionDetails className={styles.accordionDetails}>
                        <CardFoodPreferences type={item.type} />
                        <div className={styles.subHeadingCtn} onClick={() => handleOpenQuestionaire(item.type)}>
                              <Typography className={styles.subHeading}>{item.subHeading}</Typography>
                              <ArrowForwardIosIcon className={styles.arrow} />
                           </div>
                     </AccordionDetails>
                  </Accordion>
               );
            })}
            <Divider className={styles.divider} light />
         </div>
      </div>
   );
};

export default HealthProfile;
