import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import { withOrientationChange, isMobileOnly } from "react-device-detect";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core//MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik, FieldArray } from "formik";
import assistant from "../../../assets/icons/assistant.svg";
import weekly from "../../../assets/icons/weekly.svg";
import * as Yup from "yup";

import { toast } from "../../../state";
import {
  getAppUserQuery,
  getSessionQuery,
  getUserSessionsQuery,
} from "../../../services/queries";
import {
  addSession,
  updateSession,
  deleteSession,
} from "../../../services/firebase";
import styles from "./PatientSessionsDetails.module.scss";
import AnimateElement from "../../../components/AnimateElement";
import { format, isMonday, startOfWeek, sub } from "date-fns";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  notes: Yup.array().of(Yup.string()).min(1, "Add a note"),
  steps: Yup.array().of(Yup.string()),
});

const PatientSessionsDetails = (props) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    notes: [],
    steps: [],
  });
  const [typingNoteIndex, setTypingNoteIndex] = useState(null);
  const [typingPointndex, setTypingPointIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeActive, setTypeActive] = useState(null);
  const [indexActive, setIndexActive] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientId, sessionId } = useParams();
  const { data } = getAppUserQuery(patientId);
  const { data: sessionData } = getSessionQuery(sessionId);
  const { isLoading: isLoadingSessions, data: allSessions } =
    getUserSessionsQuery(patientId);

  const moveTo = (path) => history.push(path);

  useEffect(() => {
    if (sessionData) {
      setInitialValues(sessionData);
      console.log(sessionData);
    }
  }, [sessionData]);

  useEffect(() => {
    if (allSessions) {
      const sortedSessions = allSessions
        .slice()
        .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
      console.log("allSessions", allSessions, sortedSessions);
      for (let i = 0; i < sortedSessions.length; i++) {
        if (sessionId === sortedSessions[i].sessionId) {
          setStartDate(
            format(new Date(sortedSessions[i].createdOn), "yyyy-MM-dd")
          );
          if (sortedSessions[i + 1]) {
            setEndDate(
              format(
                sub(new Date(sortedSessions[i + 1].createdOn), { days: 1 }),
                "yyyy-MM-dd"
              )
            );
          } else {
            setEndDate(format(new Date(), "yyyy-MM-dd"));
          }
        }
      }
    }
  }, [allSessions]);

  const goBack = () => {
    history.goBack();
  };

  const handleMenuClick = (event, type, index) => {
    setAnchorEl(event.currentTarget);
    setTypeActive(type);
    setIndexActive(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTypeActive(null);
    setIndexActive(null);
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <IconButton className={styles.back} size="small" onClick={goBack}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.00065 12.3379L20 12.3379"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <AnimateElement duration={"0.5"}>
            <Avatar className={styles.avater} src={data && data.photoURL} />
          </AnimateElement>
          <div className={styles.headerContentcontainer}>
            <div className={styles.headerTitle}>Session Detail</div>
            <div className={styles.headerSubText}>
              {data && data.displayName}
            </div>
          </div>
          {sessionData && (
            <>
              <IconButton
                className={styles.listMoreIcon}
                size="small"
                onClick={(event) => handleMenuClick(event, "header", null)}
              >
                <MoreVertIcon />
              </IconButton>
              {typeActive === "header" && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={async () => {
                      handleMenuClose();
                      try {
                        await deleteSession(sessionData.sessionId);
                        dispatch(
                          toast({ message: "Session deleted successfully" })
                        );
                        goBack();
                      } catch {
                        dispatch(
                          toast({ message: "Failed to delete session" })
                        );
                      }
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              )}
            </>
          )}
        </div>

        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (values, FormikBag) => {
            FormikBag.setSubmitting(true);
            if (data && data.uid && sessionData && sessionData.sessionId) {
              try {
                const payload = {
                  sessionId: sessionData.sessionId,
                  userId: data.uid,
                  title: values.title,
                  notes: values.notes,
                  steps: values.steps,
                };
                await updateSession(payload);
                FormikBag.setSubmitting(false);
                dispatch(toast({ message: "Session updated successfully" }));
                goBack();
              } catch {
                FormikBag.setSubmitting(false);
                dispatch(toast({ message: "Failed to update session" }));
              }
            } else if (data && data.uid && sessionId === "add") {
              try {
                const payload = {
                  userId: data.uid,
                  title: values.title,
                  notes: values.notes,
                  steps: values.steps,
                };
                await addSession(payload);
                FormikBag.setSubmitting(false);
                dispatch(toast({ message: "Session added successfully" }));
                goBack();
              } catch {
                FormikBag.setSubmitting(false);
                dispatch(toast({ message: "Failed to add session" }));
              }
            } else {
              FormikBag.setSubmitting(false);
              dispatch(toast({ message: "Invalid URL" }));
            }
          }}
        >
          {(formik) => {
            return (
              <>
                <div className={styles.container}>
                  <div className={styles.titleContainer}>
                    {/* <div className={styles.titleImage}>1</div> */}
                    <div className={styles.titleHeadertext}>Title</div>
                    <div className={styles.titleTextContainer}>
                      <InputBase
                        autoFocus
                        fullWidth
                        className={styles.descriptionInput}
                        {...formik.getFieldProps("title")}
                      />
                      {/* <div className={styles.titleHeadertext}>First Visit</div> */}
                      {/* <div className={styles.titleHeaderSubText}>Mon 12 Sep 2022</div> */}
                    </div>
                  </div>

                  <div className={styles.listHeaderContainer}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 2V5"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 2V5"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M7 13H15"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M7 17H12"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Notes</span>
                  </div>
                  <FieldArray
                    name="notes"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.notes.map((note, index) => (
                          <Fragment key={index}>
                            {index === typingNoteIndex ? (
                              <InputBase
                                autoFocus
                                fullWidth
                                multiline
                                rows={4}
                                className={styles.descriptionInput}
                                {...formik.getFieldProps(`notes[${index}]`)}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter")
                                    setTypingNoteIndex(null);
                                }}
                              />
                            ) : (
                              <div className={styles.listText}>
                                <span>→</span>
                                <span className={styles.listTextValue}>
                                  {note}
                                </span>
                                <IconButton
                                  className={styles.listMoreIcon}
                                  size="small"
                                  onClick={(event) =>
                                    handleMenuClick(event, "note", index)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {typeActive === "note" &&
                                  indexActive === index && (
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          setTypingNoteIndex(index);
                                          handleMenuClose();
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setTypingNoteIndex(null);
                                          handleMenuClose();
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  )}
                              </div>
                            )}
                          </Fragment>
                        ))}
                        <div
                          onClick={() => {
                            if (
                              typeof typingNoteIndex === "number" &&
                              formik.values.notes[typingNoteIndex].length === 0
                            )
                              return;
                            if (
                              typeof typingNoteIndex === "number" &&
                              formik.values.notes[typingNoteIndex].length > 0
                            ) {
                              setTypingNoteIndex(null);
                              setTimeout(() => {
                                setTypingNoteIndex(formik.values.notes.length);
                                arrayHelpers.push("");
                              }, 500);
                            } else {
                              setTypingNoteIndex(formik.values.notes.length);
                              arrayHelpers.push("");
                            }
                          }}
                          className={styles.addListText}
                        >
                          → Add another point
                        </div>
                      </>
                    )}
                  />
                  <div className={styles.listHeaderContainer}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.1499 2V22"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.34"
                        d="M5.1499 4H16.3499C19.0499 4 19.6499 5.5 17.7499 7.4L16.5499 8.6C15.7499 9.4 15.7499 10.7 16.5499 11.4L17.7499 12.6C19.6499 14.5 18.9499 16 16.3499 16H5.1499"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Next steps</span>
                  </div>
                  <FieldArray
                    name="steps"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.steps.map((step, index) => (
                          <Fragment key={index}>
                            {index === typingPointndex ? (
                              <InputBase
                                autoFocus
                                fullWidth
                                multiline
                                rows={4}
                                className={styles.descriptionInput}
                                {...formik.getFieldProps(`steps[${index}]`)}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter")
                                    setTypingPointIndex(null);
                                }}
                              />
                            ) : (
                              <div className={styles.listText}>
                                <span>→</span>
                                <span className={styles.listTextValue}>
                                  {step}
                                </span>
                                <IconButton
                                  className={styles.listMoreIcon}
                                  size="small"
                                  onClick={(event) =>
                                    handleMenuClick(event, "step", index)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {typeActive === "step" &&
                                  indexActive === index && (
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          setTypingPointIndex(index);
                                          handleMenuClose();
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setTypingPointIndex(null);
                                          handleMenuClose();
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  )}
                              </div>
                            )}
                          </Fragment>
                        ))}
                        <div
                          onClick={() => {
                            if (
                              typeof typingPointndex === "number" &&
                              formik.values.steps[typingPointndex].length === 0
                            )
                              return;
                            if (
                              typeof typingPointndex === "number" &&
                              formik.values.steps[typingPointndex].length > 0
                            ) {
                              setTypingPointIndex(null);
                              setTimeout(() => {
                                setTypingPointIndex(formik.values.steps.length);
                                arrayHelpers.push("");
                              }, 500);
                            } else {
                              setTypingPointIndex(formik.values.steps.length);
                              arrayHelpers.push("");
                            }
                          }}
                          className={styles.addListText}
                        >
                          → Add another point
                        </div>

                        <button
                          className={styles.btn}
                          onClick={() => {
                            if (sessionData) {
                            } else {
                              toast.error("No session available");
                            }
                            if (isMonday(new Date(sessionData.createdOn))) {
                              let date = format(
                                new Date(sessionData.createdOn),
                                "yyyy-MM-dd"
                              );
                              moveTo(`/mealplan/${date}?mode=diet&id=${patientId}`);
                            } else {
                              let day = format(
                                startOfWeek(new Date(sessionData.createdOn), {
                                  weekStartsOn: 1,
                                }),
                                "yyyy-MM-dd"
                              );

                              moveTo(`/mealplan/${day}?mode=diet&id=${patientId}`);
                            }
                          }}
                        >
                          Meal Plan
                        </button>

                        <div className={styles.btms}>
                          <div
                            className={styles.btm}
                            onClick={() => {
                              moveTo(
                                `/dietitian/gpt/diary/${patientId}/${startDate}/${endDate}`
                              );
                            }}
                          >
                            <img src={assistant} alt="ai icon" />
                            <div className={styles.name}>AI Assistant</div>
                            <div className={styles.new}>New</div>
                          </div>
                          <div
                            className={styles.btm}
                            onClick={() => {
                              moveTo(
                                `/dietitian/report/${startDate}/${endDate}/${patientId}`
                              );
                            }}
                          >
                            <img src={weekly} alt="weekly report icon" />
                            <div className={styles.name}>Weekly Report</div>
                            <div className={styles.name}></div>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
                <ButtonBase
                  className={styles.confirmButton}
                  onClick={formik.submitForm}
                  disabled={
                    formik.isSubmitting ||
                    !formik.values.title ||
                    formik.values.steps.length === 0 ||
                    formik.values.notes.length === 0
                  }
                >
                  {formik.isSubmitting ? "Saving..." : "Save"}
                </ButtonBase>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default withOrientationChange(PatientSessionsDetails);
