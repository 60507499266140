import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import axios from "axios";
import { configProduction, configDevelopment } from "../../env";
import ImageKit from "imagekit";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
// import { OAuthProvider, signInWithPopup } from "firebase/auth";

const imageKit = new ImageKit({
  publicKey: "public_fUXeM0+88mm9s/8qOHA36vdeUCQ=",
  privateKey: "private_bu8IKr4Wzatac0WeNaDxBKqgqEc=",
  urlEndpoint: "https://ik.imagekit.io/stovehealth/",
});

const isProduction = window.location.hostname.includes("app.stovecook")
  ? true
  : false;
const config = isProduction ? configProduction : configDevelopment;

firebase.initializeApp(config);
export default firebase;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const db = firebase.firestore();

const searchRecipesUrl = isProduction
  ? "https://us-central1-stove-e851c.cloudfunctions.net/searchRecipesProd"
  : "https://us-central1-stove-e851c.cloudfunctions.net/searchRecipesDev";
const searchGroceryUrl = isProduction
  ? "https://us-central1-stove-e851c.cloudfunctions.net/searchGroceryProd"
  : "https://us-central1-stove-e851c.cloudfunctions.net/searchGroceryDev";
const searchArticleUrl = isProduction
  ? "https://us-central1-stove-e851c.cloudfunctions.net/searchArticleProd"
  : "https://us-central1-stove-e851c.cloudfunctions.net/searchArticleDev";

const createUserWithEmailAndPasswordUrl = isProduction
  ? "https://us-central1-stove-e851c.cloudfunctions.net/createUserProd"
  : "https://us-central1-stove-e851c.cloudfunctions.net/createUserDev";
const updateUserWithEmailAndPasswordUrl = isProduction
  ? "https://us-central1-stove-e851c.cloudfunctions.net/updateUserProd"
  : "https://us-central1-stove-e851c.cloudfunctions.net/updateUserDev";

//Messaging
(async function () {
  if ("Notification" in window && firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    if (Notification.permission === "granted") {
      try {
        const currentToken = await messaging.getToken({
          vapidKey: config.vapidKey,
        });
        const querySnapshot = await db
          .collection("fcmToken")
          .where("fcmToken", "==", currentToken)
          .get();
        if (querySnapshot.empty) {
          const ref = db.collection("fcmToken").doc();
          const fcmTokenData = {
            fcmId: ref.id,
            fcmToken: currentToken,
            createdOn: new Date().getTime(),
          };
          await ref.set(fcmTokenData, { merge: true });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const currentToken = await messaging.getToken({
            vapidKey: config.vapidKey,
          });
          const querySnapshot = await db
            .collection("fcmToken")
            .where("fcmToken", "==", currentToken)
            .get();
          if (querySnapshot.empty) {
            const ref = db.collection("fcmToken").doc();
            const fcmTokenData = {
              fcmId: ref.id,
              fcmToken: currentToken,
              createdOn: new Date().getTime(),
            };
            await ref.set(fcmTokenData, { merge: true });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
})();

export const uploadFileToImageKit = async (file, folderName) => {
  return await imageKit
    .upload({
      file: file,
      fileName: "image",
      folder: "/" + folderName,
      extensions: [
        {
          name: "google-auto-tagging",
          maxTags: 5,
          minConfidence: 95,
        },
      ],
    })
    .then((response) => {
      return response.url;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserDeviceToken = async (userId) => {
  if ("Notification" in window && firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const currentToken = await messaging.getToken({
          vapidKey: config.vapidKey,
        });
        const querySnapshot = await db
          .collection("users")
          .where("fcmTokenList", "array-contains", currentToken)
          .get();
        if (querySnapshot.empty) {
          await updateUserData(userId, {
            fcmTokenList:
              firebase.firestore.FieldValue.arrayUnion(currentToken),
          });
        } else {
          const users = [];
          querySnapshot.forEach((doc) => {
            users.push(doc.data());
          });
          await Promise.all(
            users.map(async (user) => {
              if (user.uid === userId) {
                await updateUserData(userId, {
                  fcmTokenList:
                    firebase.firestore.FieldValue.arrayUnion(currentToken),
                });
              } else {
                await updateUserData(userId, {
                  fcmTokenList:
                    firebase.firestore.FieldValue.arrayRemove(currentToken),
                });
              }
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};

//auth
export const signInWithGoogleCredential = async (idToken) => {
  // Build Firebase credential with the Google ID token.
  const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
  // Sign in with credential from the Google user.
  const userCredential = await auth.signInWithCredential(credential);
  return userCredential;
};

export const signInWithRedirectGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "consent",
  });
  auth.signInWithRedirect(provider);
};

export const signInWithRedirectApple = async () => {
  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");
  // await signInWithRedirect(auth, provider);

  await signInWithRedirect(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      console.log("its user", user);
      // Apple credential
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;

      // IdP data available using getAdditionalUserInfo(result)
      // ...
      console.log("user singed in");
      getRedirectResult(auth)
        .then((result) => {
          const credential = OAuthProvider.credentialFromResult(result);
          if (credential) {
            // You can also get the Apple OAuth Access and ID Tokens.
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
          }
          // The signed-in user info.
          const user = result.user;
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The credential that was used.
      const credential = OAuthProvider.credentialFromError(error);
      console.log("error");

      // ...
    });
};

export const signInWithRedirectFacebook = async () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("public_profile");
  provider.addScope("email");
  auth.signInWithRedirect(provider);
};

export const checkIfEmailExist = async (email) => {
  const methods = await auth.fetchSignInMethodsForEmail(email);
  return methods.length !== 0;
};

export const checkIfEmailExistAndPassword = async (email) => {
  const methods = await auth.fetchSignInMethodsForEmail(email);
  return methods;
};

export const createUserWithEmailAndPassword = async (
  email,
  password,
  firstName,
  lastName
) => {
  await axios.post(createUserWithEmailAndPasswordUrl, {
    displayName: `${firstName} ${lastName}`,
    email,
    password,
  });
  const user = await signInWithEmailAndPassword(email, password);
  return user;
};

export const updateUserWithEmailAndPassword = async (email, password) => {
  await axios.post(updateUserWithEmailAndPasswordUrl, {
    email,
    password,
  });
  await signInWithEmailAndPassword(email, password);
};

export const updateUserWithPassword = async (email, oldPassword, password) => {
  const user = await firebase.auth().currentUser;
  const credentials = await firebase.auth.EmailAuthProvider.credential(
    email,
    oldPassword
  );

  return await user
    .reauthenticateWithCredential(credentials)
    .then(() => {
      axios.post(updateUserWithEmailAndPasswordUrl, {
        email,
        password,
      });
      console.log("correct");
      return "success";
    })
    .catch((error) => {
      console.error("Error confirming password:", error);
      return "failed";
    });
};

export const signInWithEmailAndPassword = async (email, password) => {
  const userCredential = await auth.signInWithEmailAndPassword(email, password);
  return userCredential.user;
};

export const getRedirectResult = async () => {
  const result = await auth.getRedirectResult();
  const user = result.user;
  return user;
};

export const logout = async () => {
  await auth.signOut();
};

export const updateUserProfile = async ({ thumbnail, firstName, lastName }) => {
  const user = auth.currentUser;
  if (user) {
    let url = thumbnail;
    if (thumbnail && thumbnail.startsWith("data:")) {
      // const storageRef = storage.ref(`profilePhoto/${user.uid}`);
      // await storageRef.putString(thumbnail, "data_url");
      // url = await storageRef.getDownloadURL();
      url = await uploadFileToImageKit(thumbnail, "user");
    }
    await Promise.all([
      user.updateProfile({
        photoURL: url,
        displayName: `${firstName} ${lastName}`,
      }),
      updateUserData(user.uid, {
        photoURL: url,
        displayName: `${firstName} ${lastName}`,
      }),
    ]);
    const chef = await getChefById(user.uid);
    if (chef && chef.chefId) {
      await updateChef({
        chefId: chef.chefId,
        pic: url,
        firstName,
        lastName,
      });
    }
    const userRef = await db.collection("users").doc(user.uid).get();
    return userRef.data();
  } else {
    return null;
  }
};

export const checkStripePaidUsers = async (email) => {
  const user = [];
  const querySnapshot = await db
    .collection("unTrackedUsers")
    .where("email", "==", email.toLowerCase())
    .get();
  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      user.push(doc.data());
    });
    return user[0];
  }
  return null;
};

//storage
export const uploadFile = async (ref, file) => {
  // const storageRef = storage.ref(`file/${ref}`);
  // await storageRef.putString(file, "data_url");
  // let fileUrl = await storageRef.getDownloadURL();
  let fileUrl = await uploadFileToImageKit(file, "file");

  return fileUrl;
};

//firestore
export const getUserData = async (userId) => {
  const userRef = db.collection("users").doc(userId);
  const user = await userRef.get();
  return user.data();
};

export const getUserDataByEmail = async (email) => {
  const users = [];
  const querySnapshot = await db
    .collection("users")
    .where("email", "==", email.toLowerCase())
    .get();
  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });
  return users[0];
};

export const createUserData = async (userId, userData) => {
  await db.collection("users").doc(userId).set(userData, { merge: true });
};

export const addQuizData = async (userId, payload) => {
  await db
    .collection("users")
    .doc(userId)
    .set({ quizData: payload }, { merge: true });
};
export const updateNutrient = async (userId, payload) => {
  console.log("payload", payload);
  await db
    .collection("users")
    .doc(userId)
    .set({ nutrients: payload }, { merge: true });
};

export const addFoodPreference = async (userId, payload) => {
  await db
    .collection("users")
    .doc(userId)
    .set({ foodPreference: payload }, { merge: true });
};

export const addUserPlan = async (userId, payload) => {
  console.log("pay", userId, payload);
  await db
    .collection("users")
    .doc(userId)
    .set({ plan: payload }, { merge: true });
};

export const updateUserData = async (userId, userData) => {
  await db.collection("users").doc(userId).set(userData, { merge: true });
};

export const createChef = async (payload) => {
  const ref = db.collection("chefs").doc(payload.chefId);
  await ref.set(
    {
      chefId: payload.chefId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      bio: payload.bio,
      pic: payload.pic,
    },
    { merge: true }
  );
};

export const updateChef = async (payload) => {
  const ref = db.collection("chefs").doc(payload.chefId);
  await ref.set(payload, { merge: true });
};

export const getChefById = async (chefId) => {
  let chefRef = db.collection("chefs").doc(chefId);
  let chefDocs = await chefRef.get();
  if (chefDocs.exists) {
    return chefDocs.data();
  } else {
    return {};
  }
};

//Profile
export const getAllProfiles = async (profileId) => {
  const profile = [];
  const querySnapshot = await db.collection("users").get();
  querySnapshot.forEach((doc) => {
    profile.push(doc.data());
  });
  return profile.filter((user) => user.uid !== profileId);
};

export const getAllAssignedProfiles = async (uid) => {
  const profile = [];
  const querySnapshot = await db
    .collection("users")
    .where("assignedDietitian.uid", "==", uid)
    .get();
  querySnapshot.forEach((doc) => {
    profile.push(doc.data());
  });
  return profile.filter((user) => user.uid !== uid);
};

export const getProfile = async (profileId) => {
  const userRef = db.collection("users").doc(profileId);
  const userDoc = await userRef.get();
  return userDoc.data();
};

export const getUserTasks = async (userId) => {
  // const taskRef = db.collection("tasks").doc(userId);
  // const taskDoc = await taskRef.get();
  // return taskDoc.data();
  const tasks = [];
  const querySnapshot = await db
    .collection("tasks")
    .where("userId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    tasks.push(doc.data());
  });
  return tasks;
};

export const getSingleTask = async (taskId) => {
  const taskRef = db.collection("tasks").doc(taskId);
  const taskDoc = await taskRef.get();
  return taskDoc.data();
};

export const createUserTask = async (userId, payload) => {
  const taskRef = db.collection("tasks").doc();
  console.log(userId, payload);
  const taskId = taskRef.id;
  await taskRef.set(
    { taskId: taskId, createdAT: new Date(), userId, ...payload },
    { merge: true }
  );
  return taskId;
};

export const addUserMealPlan = async (userId, payload) => {
  console.log("rebounce 3", payload, userId);

  const querySnapshot = db
    .collection("meal")
    .where("date", "==", payload.date)
    .where("userId", "==", userId);

  const meal = await querySnapshot.get();

  if (!meal.empty) {
    const doc = meal.docs[0];

    const data = doc.data();

    console.log("data", data);
    data.mealPlan = payload.mealPlan;
    data.snackPlan = payload.snackPlan;
    data.note = payload.note ? payload.note : "";
    data.isDiet = payload.isDiet ? payload.isDiet : "";

    await doc.ref.set(data, { merge: true });
    console.log("1");
  } else {
    const mealRef = db.collection("meal").doc();
    console.log("2");
    await mealRef.set({ mealId: mealRef.id, userId, ...payload });
  }
};

export const getUserMeal = async (userId, date) => {
  console.log("rebounce 2", date, userId);

  const mealRef = db
    .collection("meal")
    .where("userId", "==", userId)
    .where("date", "==", date);
  const mealQuerySnapshot = await mealRef.get();

  if (mealQuerySnapshot.empty) {
    return null;
  }

  const mealDoc = mealQuerySnapshot.docs[0];
  return mealDoc.data();
};

export const getUserShopList = async (userId, date) => {
  console.log("rebounce", date, userId);
  const mealRef = db
    .collection("shoplist")
    .where("userId", "==", userId)
    .where("date", "==", date);
  const shopQuerySnapshot = await mealRef.get();

  if (shopQuerySnapshot.empty) {
    return null;
  }

  const shopDoc = shopQuerySnapshot.docs[0];
  return shopDoc.data();
};

export const addUserShoppingList = async (userId, payload) => {
  console.log("rebounce 4", payload, userId);

  const querySnapshot = db
    .collection("shoplist")
    .where("date", "==", payload.date)
    .where("userId", "==", userId);

  const list = await querySnapshot.get();

  if (!list.empty) {
    const doc = list.docs[0];

    const data = doc.data();

    console.log("data fire", data, doc);
    data.shoplist = payload.shoplist;
    data.serving = payload.serving ? payload.serving : 1;

    await doc.ref.set(data, { merge: true });
    console.log("1");
  } else {
    const shopRef = db.collection("shoplist").doc();
    console.log("2");
    await shopRef.set({ shoplistId: shopRef.id, userId, ...payload });
  }
};

// export const updateUserMealPlan = async (userId, payload) => {
//   const mealRef = db.collection("meal").doc();
//   console.log(userId, payload);
//   await mealRef.set({ mealId: mealRef.id, userId, ...payload }, { merge: true });
// };

export const updateUserTask = async (payload) => {
  const taskRef = db.collection("tasks").doc(payload.taskId);
  console.log("payload yea", payload);
  await taskRef.set(payload, { merge: true });
};

//Recipe Calls
export const uploadRecipe = async (payload) => {
  const ref = db.collection("recipes").doc();
  let thumbnail = payload.thumbnail;
  if (payload.thumbnail) {
    // const storageRef = storage.ref(`recipes/${ref.id}`);
    // await storageRef.putString(payload.thumbnail, "data_url");
    // thumbnail = await storageRef.getDownloadURL();
    thumbnail = await uploadFileToImageKit(payload.thumbnail, "recipes");
  }
  let steps = await Promise.all(
    payload.steps.map(async (step, index) => {
      step.id = index;
      if (step.thumbnail) {
        // const storageRef = storage.ref(`recipes/${ref.id}_steps/${index}`);
        // await storageRef.putString(step.thumbnail, "data_url");
        step.thumbnail = await uploadFileToImageKit(
          step.thumbnail,
          "recipeSteps"
        );
      }
      return step;
    })
  );
  console.log(payload, "payload");
  await ref.set(
    {
      recipeId: ref.id,
      chefId: payload.chefId,
      thumbnail: thumbnail,
      title: payload.title.trim().toLowerCase(),
      cost: payload.cost,
      difficulty: payload.difficulty,
      readyIn: payload.readyIn,
      servings: payload.servings,
      type: payload.type,
      meal: payload.meal,
      dietary: payload.dietary,
      cuisine: payload.cuisine,
      ingredients: payload.ingredients,
      cookingMethod: payload.cookingMethod,
      steps: steps,
      userUploaded: true,
      groceriesId: payload.groceriesId,
      tags: [
        payload.cost,
        payload.difficulty,
        payload.readyIn,
        payload.servings,
        payload.type,
        ...payload.meal,
        payload.dietary,
        payload.cuisine,
      ],
      likes: [],
      createdOn: new Date().getTime(),
      nutriments: payload.nutriments,
    },
    { merge: true }
  );
  return ref.id;
};

export const updateRecipe = async (payload) => {
  const ref = db.collection("recipes").doc(payload.recipeId);
  let thumbnail = payload.thumbnail;
  if (payload.thumbnail && payload.thumbnail.startsWith("data:")) {
    // const storageRef = storage.ref(`recipes/${ref.id}`);
    // await storageRef.putString(payload.thumbnail, "data_url");
    // thumbnail = await storageRef.getDownloadURL();
    thumbnail = await uploadFileToImageKit(payload.thumbnail, "recipes");
  }

  let steps = await Promise.all(
    payload.steps.map(async (step, index) => {
      // step.id = index;
      if (step.thumbnail && step.thumbnail.startsWith("data:")) {
        // const storageRef = storage.ref(`recipes/${ref.id}_steps/${index}`);
        // await storageRef.putString(step.thumbnail, "data_url");
        // step.thumbnail = await storageRef.getDownloadURL();
        step.thumbnail = await uploadFileToImageKit(
          step.thumbnail,
          "recipeSteps"
        );
      }
      return step;
    })
  );

  await ref.set(
    {
      recipeId: ref.id,
      chefId: payload.chefId,
      thumbnail: thumbnail,
      title: payload.title.trim().toLowerCase(),
      cost: payload.cost,
      difficulty: payload.difficulty,
      readyIn: payload.readyIn,
      servings: payload.servings,
      type: payload.type,
      meal: payload.meal,
      dietary: payload.dietary,
      cuisine: payload.cuisine,
      ingredients: payload.ingredients,
      cookingMethod: payload.cookingMethod,
      steps: steps,
      userUploaded: true,
      groceriesId: payload.groceriesId,
      tags: [
        payload.cost,
        payload.difficulty,
        payload.readyIn,
        payload.servings,
        payload.type,
        ...payload.meal,
        payload.dietary,
        payload.cuisine,
      ],
      lastUpdated: new Date().getTime(),
      nutriments: payload.nutriments,
    },
    { merge: true }
  );

  return ref.id;
};

export const deleteRecipe = async (recipeId) => {
  const recipeRef = db.collection("recipes").doc(recipeId);
  const recipeDoc = await recipeRef.get();
  const recipeData = recipeDoc.data();
  await recipeRef.delete();
  if (recipeData && recipeData.thumbnail) {
    await storage.ref(`recipes/${recipeId}`).delete();
  }
  let allStepImage = await storage.ref(`recipes/${recipeId}_steps`).listAll();
  await Promise.all(
    allStepImage.items.map(async (itemRef) => {
      return await itemRef.delete();
    })
  );
  return recipeId;
};

export const getAllRecipesOnly = async () => {
  const recipes = [];
  const querySnapshot = await db.collection("recipes").get();
  querySnapshot.forEach((doc) => {
    recipes.push(doc.data());
  });
  return recipes;
};

export const getOnly10Recipes = async () => {
  const recipes = [];
  const collection = firebase.firestore().collection("recipes");

  const lastTenQuery = collection.limit(10);

  await lastTenQuery.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      recipes.push(doc.data());
    });
  });
  return recipes;
};

export const getRecipesByName = async (recipeName) => {
  try {
    const recipes = [];
    const querySnapshot = await db
      .collection("recipes")
      .where("title", "==", recipeName.trim().toLowerCase())
      .get();

    console.log("Query Snapshot Size:", querySnapshot.size);

    querySnapshot.forEach((doc) => {
      recipes.push(doc.data());
    });

    console.log("Recipes:", recipes);

    return recipes;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw error; // Rethrow the error for further handling, if needed
  }
};

export const getAllRecipes = async () => {
  const recipes = [];
  const querySnapshot = await db.collection("recipes").get();
  querySnapshot.forEach((doc) => {
    recipes.push(doc.data());
  });
  const recipesAndChef = await Promise.all(
    recipes.map(async (recipe) => {
      if (recipe?.chefId) {
        recipe.chef = await getChefById(recipe?.chefId);
        return recipe;
      } else {
        return recipe;
      }
    })
  );
  return recipesAndChef;
};

export const getAllUserRecipes = async (userId) => {
  const recipes = [];
  const querySnapshot = await db
    .collection("recipes")
    .where("chefId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    recipes.push(doc.data());
  });
  const recipesAndChef = await Promise.all(
    recipes.map(async (recipe) => {
      recipe.chef = await getChefById(recipe.chefId);
      return recipe;
    })
  );
  return recipesAndChef;
};

export const getSingleRecipe = async (recipeId) => {
  const recipeRef = db.collection("recipes").doc(recipeId);
  const recipeDoc = await recipeRef.get();
  let recipe;
  if (recipeDoc.exists) {
    recipe = recipeDoc.data();
    if (recipe.chefId) {
      recipe.chef = await getChefById(recipe.chefId);
    }
  }
  return recipe;
};

export const likeRecipe = async (recipeId, userId) => {
  const recipeRef = db.collection("recipes").doc(recipeId);
  await recipeRef.update({
    likes: firebase.firestore.FieldValue.arrayUnion(userId),
  });
};

export const unlikeRecipe = async (recipeId, userId) => {
  const recipeRef = db.collection("recipes").doc(recipeId);
  await recipeRef.update({
    likes: firebase.firestore.FieldValue.arrayRemove(userId),
  });
};

export const getAllUserLikedRecipes = async (userId) => {
  const recipes = [];
  const querySnapshot = await db
    .collection("recipes")
    .where("likes", "array-contains", userId)
    .get();
  querySnapshot.forEach((doc) => {
    recipes.push(doc.data());
  });
  const recipesAndChef = await Promise.all(
    recipes.map(async (recipe) => {
      recipe.chef = await getChefById(recipe.chefId);
      return recipe;
    })
  );
  return recipesAndChef;
};

export const recommendRecipesTo = async (recipeId, userId) => {
  const recipeRef = db.collection("recipes").doc(recipeId);
  await recipeRef.update({
    recommendedUser: firebase.firestore.FieldValue.arrayUnion(userId),
  });
};

export const unrecommendRecipesTo = async (recipeId, userId) => {
  const recipeRef = db.collection("recipes").doc(recipeId);
  await recipeRef.update({
    recommendedUser: firebase.firestore.FieldValue.arrayRemove(userId),
  });
};

export const getAllRecommendRecipes = async (userId) => {
  const recipes = [];
  const querySnapshot = await db
    .collection("recipes")
    .where("recommendedUser", "array-contains", userId)
    .get();
  querySnapshot.forEach((doc) => {
    recipes.push(doc.data());
  });
  const recipesAndChef = await Promise.all(
    recipes.map(async (recipe) => {
      recipe.chef = await getChefById(recipe.chefId);
      return recipe;
    })
  );
  return recipesAndChef;
};

export const getFruits = async () => {
  const fruits = [];
  const querySnapshot = await db.collection("fruits").get();
  querySnapshot.forEach((doc) => {
    fruits.push(doc.data());
  });

  return fruits;
};

//Groceries Calls
export const getAllGroceries = async () => {
  const groceries = [];
  const querySnapshot = await db.collection("groceries").get();
  querySnapshot.forEach((doc) => {
    groceries.push(doc.data());
  });
  return groceries;
};

export const getSingleGrocery = async (groceryId) => {
  const groceryRef = db.collection("groceries").doc(groceryId);
  const groceryDoc = await groceryRef.get();
  const grocery = groceryDoc.data();
  return grocery;
};

export const get10Groceries = async () => {
  const groceries = [];
  const collection = firebase.firestore().collection("groceries");

  const lastTenQuery = collection.limit(10);

  await lastTenQuery.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      groceries.push(doc.data());
    });
  });
  return groceries;
};

export const likeGrocery = async (groceryId, userId) => {
  const groceryRef = db.collection("groceries").doc(groceryId);
  await groceryRef.update({
    likes: firebase.firestore.FieldValue.arrayUnion(userId),
  });
};

export const unlikeGrocery = async (groceryId, userId) => {
  const groceryRef = db.collection("groceries").doc(groceryId);
  await groceryRef.update({
    likes: firebase.firestore.FieldValue.arrayRemove(userId),
  });
};

export const getAllUserLikedGroceries = async (userId) => {
  const groceries = [];
  const querySnapshot = await db
    .collection("groceries")
    .where("likes", "array-contains", userId)
    .get();
  querySnapshot.forEach((doc) => {
    groceries.push(doc.data());
  });
  return groceries;
};

export const recommendGroceriesTo = async (groceryId, userId) => {
  const groceryRef = db.collection("groceries").doc(groceryId);
  await groceryRef.update({
    recommendedUser: firebase.firestore.FieldValue.arrayUnion(userId),
  });
};

export const unrecommendGroceriesTo = async (groceryId, userId) => {
  const groceryRef = db.collection("groceries").doc(groceryId);
  await groceryRef.update({
    recommendedUser: firebase.firestore.FieldValue.arrayRemove(userId),
  });
};
export const getAllRecommendGroceries = async (userId) => {
  const groceries = [];
  const querySnapshot = await db
    .collection("groceries")
    .where("recommendedUser", "array-contains", userId)
    .get();
  querySnapshot.forEach((doc) => {
    groceries.push(doc.data());
  });
  return groceries;
};

//Update Cart
export const revalidateUserCart = async (payload) => {
  const updatedCart = [];
  await Promise.all(
    payload.map(async (cartItem) => {
      if (cartItem.groceryId) {
        const groceryRef = db.collection("groceries").doc(cartItem.groceryId);
        const groceryDoc = await groceryRef.get();
        const grocery = groceryDoc.data();
        if (grocery && grocery.stockQuantity > 0 && cartItem.quantity > 0) {
          updatedCart.push({ ...grocery, quantity: cartItem.quantity });
        }
      } else if (cartItem.rewardId) {
        const rewardRef = db.collection("rewards").doc(cartItem.rewardId);
        const rewardDoc = await rewardRef.get();
        const reward = rewardDoc.data();
        if (reward && cartItem.quantity > 0) {
          updatedCart.push({ ...reward, quantity: cartItem.quantity });
        }
      }
    })
  );
  return updatedCart;
};

//search
export const recipesTagsSearch = async (tags) => {
  const recipes = [];
  const querySnapshot = await db
    .collection("recipes")
    .where("tags", "array-contains-any", tags)
    .get();
  querySnapshot.forEach((doc) => {
    recipes.push(doc.data());
  });
  const recipesAndChef = await Promise.all(
    recipes.map(async (recipe) => {
      recipe.chef = await getChefById(recipe.chefId);
      return recipe;
    })
  );
  return recipesAndChef;
};

export const recipesTextSearch = async (text) => {
  const response = await axios({
    method: "get",
    url: searchRecipesUrl,
    params: {
      text: text,
    },
  });
  return response.data;
};

export const groceriesTagsSearch = async (tags, special) => {
  if (special && tags.length > 0) {
    //get special with tags
    const groceries = [];
    const querySnapshot = await db
      .collection("groceries")
      .where("specialOffers", "==", true)
      .where("tags", "array-contains-any", tags)
      .get();
    querySnapshot.forEach((doc) => {
      groceries.push(doc.data());
    });
    return groceries;
  } else if (special) {
    //get only special because of no tags
    const groceries = [];
    const querySnapshot = await db
      .collection("groceries")
      .where("specialOffers", "==", true)
      .get();
    querySnapshot.forEach((doc) => {
      groceries.push(doc.data());
    });
    return groceries;
  } else {
    //get only tags because special is false
    const groceries = [];
    const querySnapshot = await db
      .collection("groceries")
      .where("tags", "array-contains-any", tags)
      .get();
    querySnapshot.forEach((doc) => {
      groceries.push(doc.data());
    });
    return groceries;
  }
};

export const groceriesTextSearch = async (text) => {
  const response = await axios({
    method: "get",
    url: searchGroceryUrl,
    params: {
      text: text,
    },
  });
  return response.data;
};

//Rewards
export const getAllRewards = async () => {
  const rewards = [];
  const querySnapshot = await db.collection("rewards").get();
  querySnapshot.forEach((doc) => {
    rewards.push(doc.data());
  });
  return rewards;
};

//RewardsCollected & Transactions
export const getAllUserCollectedRewards = async (userId) => {
  const collectedRewards = [];
  const querySnapshot = await db
    .collection("collectedRewards")
    .where("userId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    collectedRewards.push(doc.data());
  });
  return collectedRewards;
};

export const addCollectedReward = async (rewards, userId) => {
  const userRef = db.collection("users").doc(userId);
  const collectedRewardRef = db.collection("collectedRewards").doc();
  const totalPoints = rewards.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.points;
  }, 0);
  const remainingPoints = firebase.firestore.FieldValue.increment(
    totalPoints * -1
  );
  const collectedReward = {
    collectedRewardId: collectedRewardRef.id,
    userId,
    rewards,
    createdOn: new Date().getTime(),
  };
  await userRef.update({ points: remainingPoints });
  await collectedRewardRef.set(collectedReward, { merge: true });
};

export const addShopTransactions = async (
  stripeId,
  userId,
  email,
  address,
  price,
  cart,
  points,
  when,
  freeDelivery
) => {
  const transactionRef = db.collection("transactions").doc();
  const rewards = cart.filter((cartItem) => Boolean(cartItem.rewardId));
  const transaction = {
    transactionId: transactionRef.id,
    stripeId,
    userId,
    email,
    address,
    price,
    cart,
    points,
    type: "shop",
    status: 0,
    createdOn: new Date().getTime(),
    when,
    freeDelivery,
  };
  if (rewards.length > 0) {
    await addCollectedReward(rewards, userId);
    await transactionRef.set(transaction, { merge: true });
  } else {
    await transactionRef.set(transaction, { merge: true });
  }
};

export const addReferrerTransactions = async (
  stripeId,
  referrerId,
  email,
  address,
  price,
  cart,
  name
) => {
  const transactionRef = db.collection("transactions").doc();
  const transaction = {
    transactionId: transactionRef.id,
    stripeId,
    referrerId,
    email,
    address,
    price,
    cart,
    name,
    type: "referrer",
    status: 0,
    createdOn: new Date().getTime(),
  };
  await transactionRef.set(transaction, { merge: true });
};

export const getAllUserTransactions = async (userId) => {
  const userTransactions = [];
  const querySnapshot = await db
    .collection("transactions")
    .where("userId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    userTransactions.push(doc.data());
  });
  return userTransactions;
};

export const getUserTransaction = async (userId, transactionId) => {
  const userTransaction = [];
  const querySnapshot = await db
    .collection("transactions")
    .where("userId", "==", userId)
    .where("transactionId", "==", transactionId)
    .get();
  querySnapshot.forEach((doc) => {
    userTransaction.push(doc.data());
  });
  return userTransaction[0];
};

//PointsCollected
export const getAllUserCollectedPoints = async (userId) => {
  const collectedPoints = [];
  const querySnapshot = await db
    .collection("collectedPoints")
    .where("userId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    collectedPoints.push(doc.data());
  });
  return collectedPoints;
};

export const addCollectedPoints = async (userId, type, details, points) => {
  const userRef = db.collection("users").doc(userId);
  const collectedPointsRef = db.collection("collectedPoints").doc();
  const remainingPoints = firebase.firestore.FieldValue.increment(points);
  const collectedPoints = {
    collectedPointsId: collectedPointsRef.id,
    userId,
    type,
    details,
    points,
    createdOn: new Date().getTime(),
  };
  await userRef.update({ points: remainingPoints });
  await collectedPointsRef.set(collectedPoints, { merge: true });
};

//Chats

export const checkIfChatExist = async (uniqueName) => {
  const chatsRef = db
    .collection("chats")
    .where("uniqueName", "==", uniqueName.trim().toLowerCase());
  const querySnapshot = await chatsRef.get();
  return !querySnapshot.empty;
};

export const createChat = async (payload) => {
  const ref = db.collection("chats").doc();
  // const storageRef = storage.ref(`chats/${ref.id}`);
  // await storageRef.putString(payload.thumbnail, "data_url");
  // let thumbnail = await storageRef.getDownloadURL();
  let thumbnail = await uploadFileToImageKit(payload.thumbnail, "chatImage");

  const chat = {
    author: payload.uid,
    chatId: ref.id,
    thumbnail: thumbnail,
    uniqueName: payload.uniqueName.trim().toLowerCase(),
    membersId: [],
  };
  await ref.set(chat, { merge: true });
};

// export const updateChat = async (payload) => {
//     const ref = db.collection("chats").doc(payload.chatId);
//     let thumbnail = payload.thumbnail;
//     if (payload.thumbnail && payload.thumbnail.startsWith("data:")) {
//         const storageRef = storage.ref(`chats/${ref.id}`);
//         await storageRef.putString(payload.thumbnail, 'data_url');
//         thumbnail = await storageRef.getDownloadURL();
//     }
//     const chat = {
//         author: payload.uid,
//         chatId: ref.id,
//         thumbnail: thumbnail,
//         uniqueName: payload.uniqueName.trim().toLowerCase()
//     }
//     await ref.set(chat, { merge: true });
// };

// export const deleteChat = async (chatId) => {
//   await db.collection("chats").doc(chatId).delete();
//   await storage.ref(`chats/${chatId}`).delete();
//   return chatId;
// };

export const getAllChats = async () => {
  let chatsArray = [];
  let chats = await db.collection("chats").get();
  chats.forEach((chat) => chatsArray.push(chat.data()));
  const chatsArrayData = await Promise.all(
    chatsArray.map(async (chat) => {
      const membersIdData = await Promise.all(
        chat.membersId.map(async (memberId) => await getUserData(memberId))
      );
      chat.membersIdData = membersIdData;
      return chat;
    })
  );
  return chatsArrayData;
};

export const getSingleChat = async (chatId) => {
  const chatRef = db.collection("chats").doc(chatId);
  const chatDoc = await chatRef.get();
  const chat = chatDoc.data();
  return chat;
};

export const addMember = async (chatId, memberId) => {
  const chatRef = db.collection("chats").doc(chatId);
  const chat = {
    membersId: firebase.firestore.FieldValue.arrayUnion(memberId),
  };
  await chatRef.set(chat, { merge: true });
};

export const removeMember = async (chatId, memberId) => {
  const chatRef = db.collection("chats").doc(chatId);
  const chat = {
    membersId: firebase.firestore.FieldValue.arrayRemove(memberId),
  };
  await chatRef.set(chat, { merge: true });
};

// get PromoCodes from firebase
export const getAllPromoCode = async () => {
  const promocode = [];
  const promocodeCopy = await db.collection("promocodes").get();
  promocodeCopy.forEach((doc) => {
    promocode.push(doc.data());
  });
  return promocode;
};

// get All Used PromoCodes from user data

export const getAllUserUsedPromoCode = async (userId) => {
  const userUsedPromoCode = [];
  const querySnapshot = await db
    .collection("userUsedPromoCode")
    .where("userId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    userUsedPromoCode.push(doc.data());
  });
  return userUsedPromoCode;
};

// add Used PromoCodes from user data
export const addUsedPromoCode = async (
  usedUserPromoCodes,
  newUsedPromoCode,
  userId
) => {
  const usedPromoCodeRef = db.collection("userUsedPromoCode").doc(userId);
  const usedPromoCode = {
    usedPromoCodeId: usedPromoCodeRef.id,
    userId,
    usedPromoCodes: [...usedUserPromoCodes, newUsedPromoCode],
    createdOn: new Date().getTime(),
  };
  await usedPromoCodeRef.set(usedPromoCode, { merge: true });
};

// get free Delivery amount
export const getFreeDeliveryAmount = async () => {
  const amountCopyRef = db.collection("freeDeliveryAmount").doc("freeDelivery");
  const amountDoc = await amountCopyRef.get();
  const amount = amountDoc.data();
  return amount;
};

export const addUserEventsFood = async (userId, payload) => {
  const userRef = db.collection("users").doc(userId);
  const currentTime = Date.now();
  localStorage.setItem("lastEventAddedDate", currentTime);
  const userData = {
    uid: userId,
    id: payload.id,
    date: payload.date,
    food: {
      items: payload.items,
      time: payload.time,
      hour: payload.hour,
      minute: payload.minute,
      description: payload.description,
    },
    tag: payload.tag,
    dateCreated: currentTime,
  };
  console.log("userData", userData);
  // await userRef.set(
  //   { events: firebase.firestore.FieldValue.arrayUnion(userData) },
  //   { merge: true }
  // );
  try {
    await userRef.set(
      { events: firebase.firestore.FieldValue.arrayUnion(userData) },
      { merge: true }
    );
    console.log("Update successful");
  } catch (error) {
    console.error("Update failed:", error);
  }
};

export const updateUserEventsFood = async (userId, payload, date) => {
  const userRef = db.collection("users").doc(userId);
  const userDoc = await userRef.get();
  const user = userDoc.data();
  const currentTime = Date.now();
  const events = user?.events;
  const newEvents = events?.map((event) => {
    if (event.dateCreated === date) {
      return {
        ...event,
        date: payload.date,
        dateCreated: currentTime,
        id: payload.id,
        food: {
          time: payload.time,
          hour: payload.hour,
          minute: payload.minute,
          description: payload.description,
          items: payload.items,
        },
      };
    } else {
      return event;
    }
  });
  await userRef.set({ events: newEvents }, { merge: true });
};

export const addUserEventsRecipe = async (userId, payload) => {
  const userRef = db.collection("users").doc(userId);
  const currentTime = Date.now();
  localStorage.setItem("lastEventAddedDate", currentTime);
  const userData = {
    uid: userId,
    id: payload.id,
    recipe: {
      recipeId: payload.recipeId,
      thumbnail: payload.thumbnail,
      title: payload.title,
      time: payload.time,
      hour: payload.hour,
      minute: payload.minute,
    },
    dateCreated: currentTime,
  };
  await userRef.set(
    { events: firebase.firestore.FieldValue.arrayUnion(userData) },
    { merge: true }
  );
};

export const deleteUserEvents = async (userId, eventId) => {
  const userRef = db.collection("users").doc(userId);
  const userDoc = await userRef.get();
  const user = userDoc.data();
  const events = user?.events;
  const newEvents = events?.filter((event) => event.dateCreated !== eventId);
  await userRef.set({ events: newEvents }, { merge: true });
};

export const addUserEventsWater = async (userId, payload) => {
  const userRef = db.collection("users").doc(userId);
  const currentTime = Date.now();
  localStorage.setItem("lastEventAddedDate", currentTime);
  const userData = {
    uid: userId,
    date: payload.date,
    id: payload.id,
    water: {
      quantity: payload.quantity,
      time: payload.time,
      hour: payload.hour,
    },
    dateCreated: currentTime,
  };

  await userRef.set(
    { events: firebase.firestore.FieldValue.arrayUnion(userData) },
    { merge: true }
  );
};

export const addUserEventsWeight = async (userId, payload) => {
  const userRef = db.collection("users").doc(userId);
  const currentTime = Date.now();
  localStorage.setItem("lastEventAddedDate", currentTime);
  const userData = {
    uid: userId,
    date: payload.date,
    weight: {
      size: payload.size,
      hour: payload.hour,
      time: payload.time,
    },
    id: payload.id,
    dateCreated: currentTime,
  };

  await userRef.set(
    { events: firebase.firestore.FieldValue.arrayUnion(userData) },
    { merge: true }
  );
};

export const updateUserEventWeight = async (userId, payload, date) => {
  const userRef = db.collection("users").doc(userId);
  const userDoc = await userRef.get();
  const user = userDoc.data();
  const currentTime = Date.now();
  const events = user?.events;
  const newEvents = events?.map((event) => {
    if (event.dateCreated === date) {
      return {
        ...event,
        date: payload.date,
        dateCreated: currentTime,
        id: payload.id,
        weight: {
          size: payload.size,
          hour: payload.hour,
          time: payload.time,
        },
      };
    } else {
      return event;
    }
  });
  await userRef.set({ events: newEvents }, { merge: true });
};

export const addUserEventsSymptoms = async (userId, payload) => {
  const userRef = db.collection("users").doc(userId);
  const currentTime = Date.now();
  localStorage.setItem("lastEventAddedDate", currentTime);
  const userData = {
    uid: userId,
    date: payload.date,
    time: payload.time,
    symptoms: {
      symptoms: payload.symptoms,
      hour: payload.hour,
      description: payload.description,
    },
    id: payload.id,
    dateCreated: currentTime,
  };
  await userRef.set(
    { events: firebase.firestore.FieldValue.arrayUnion(userData) },
    { merge: true }
  );
};

export const updateUserEventSymptoms = async (userId, payload, date) => {
  const userRef = db.collection("users").doc(userId);
  const userDoc = await userRef.get();
  const user = userDoc.data();
  const currentTime = Date.now();
  const events = user?.events;
  const newEvents = events?.map((event) => {
    if (event.dateCreated === date) {
      return {
        ...event,
        date: payload.date,
        dateCreated: currentTime,
        id: payload.id,
        time: payload.time,
        symptoms: {
          symptoms: payload.symptoms,
          hour: payload.hour,
          description: payload.description,
        },
      };
    } else {
      return event;
    }
  });
  await userRef.set({ events: newEvents }, { merge: true });
};

export const updateFoodDairyStatus = async (payload) => {
  const userRef = db.collection("users").doc(payload.uid);
  await userRef.set({ foodDiaryComplete: payload.status }, { merge: true });
};

export const getUserCompanyInvites = async (email) => {
  const data = [];
  const invites = db.collection("companyInvites");
  const allInvites = await invites.where("email", "==", email).get();
  allInvites.forEach((doc) => {
    if (!doc.data().isAccepted) {
      data.push(doc.data());
    }
  });
  return data;
};

export const updateUserInvite = async (id) => {
  const invites = db.collection("companyInvites").doc(id);
  await invites.set(
    {
      isAccepted: true,
    },
    { merge: true }
  );
};

// Articles
export const getAllArticles = async () => {
  const articles = [];
  const querySnapshot = await db.collection("articles").get();
  querySnapshot.forEach((doc) => {
    articles.push(doc.data());
  });
  return articles;
};

export const getArticleById = async (articleId) => {
  const articleRef = db.collection("articles").doc(articleId);
  const articleDoc = await articleRef.get();
  const article = articleDoc.data();
  return article;
};

export const get10Article = async () => {
  const articles = [];
  const collection = firebase.firestore().collection("articles");

  const lastTenQuery = collection.limit(3);

  await lastTenQuery.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      articles.push(doc.data());
    });
  });
  return articles;
};

export const articleTextSearch = async (text) => {
  const response = await axios({
    method: "get",
    url: searchArticleUrl,
    params: {
      text: text,
    },
  });
  return response.data;
};
//Notification
export const getAdminNotifications = async (userId) => {
  const notifications = [];
  const querySnapshot = await db.collection("adminNotifications").get();
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.chatType === "group") {
      const participants = data.participants;
      const participant = participants.find(
        (participant) => participant.userId === userId
      );
      if (participant) {
        notifications.push(data);
      }
    }

    if (data.chatType === "individual" && data.receiverId === userId) {
      notifications.push(data);
    }

    if (data.sender === "admin") {
      notifications.push(data);
    }
  });
  const sortedNotification = notifications.sort(
    (a, b) => Number(new Date(b.created)) - Number(new Date(a.created))
  );
  return sortedNotification;
};

export const createNotifications = async (payload) => {
  const ref = db.collection("adminNotifications").doc();
  let notifications = {
    notificationId: ref.id,
    receiverId: payload.receiverId,
    title: payload.title,
    body: payload.body,
    sender: payload.sender,
    senderImage: payload.senderImage,
    senderId: payload.senderId,
    thumbnail: payload.thumbnail,
    created: new Date().getTime(),
  };
  if (payload.chatType === "group") {
    notifications = {
      ...notifications,
      chatType: payload.chatType,
      participants: payload.participants,
    };
  } else {
    notifications = {
      ...notifications,
      chatType: payload.chatType,
      messageRead: payload.messageRead,
    };
  }

  await ref.set(notifications, { merge: true });
  return notifications;
};

export const updateReadMessage = async (id) => {
  const ref = db.collection("adminNotifications").doc(id);
  await ref.set({ messageRead: true }, { merge: true });
};

export const updateReadMessageByUserId = async (userId, senderId) => {
  const ref = db.collection("adminNotifications");
  const querySnapshot = await ref
    .where("receiverId", "==", userId)
    .where("senderId", "==", senderId)
    .get();
  querySnapshot.forEach((doc) => {
    doc.ref.set({ messageRead: true }, { merge: true });
  });
};

export const updateReadMessageByGroupId = async (groupId, userId) => {
  const ref = db.collection("adminNotifications");
  const querySnapshot = await ref
    .where("chatType", "==", "group")
    .where("receiverId", "==", groupId)
    .get();
  // for each doc update the messageRead property of the particpant with the userId to true
  querySnapshot.forEach((doc) => {
    const participants = doc.data().participants;
    const newParticipants = participants.map((participant) => {
      if (participant.userId === userId) {
        return {
          ...participant,
          messageRead: true,
        };
      } else {
        return participant;
      }
    });
    doc.ref.set({ participants: newParticipants }, { merge: true });
  });
};
// get number of unread messages
export const getIndividualUnreadMessages = async (userId) => {
  const unreadNotifications = [];
  const ref = db.collection("adminNotifications");
  const querySnapshot = await ref
    .where("receiverId", "==", userId)
    .where("messageRead", "==", false)
    .get();
  querySnapshot.forEach((doc) => {
    unreadNotifications.push(doc.data());
  });
  return unreadNotifications;
};

export const getSingleUserGroupUnreadMsg = async (userId) => {
  const unreadNotifications = [];
  const ref = db.collection("adminNotifications");
  // get all group notification where a user is a participant and message read is false
  const querySnapshot = await ref
    .where("chatType", "==", "group")
    .where("participants", "array-contains", { userId, messageRead: false })
    .get();

  querySnapshot.forEach((doc) => {
    unreadNotifications.push(doc.data());
  });
  return unreadNotifications;
};

export const updatePatientNutrients = async (payload) => {
  const ref = db.collection("patientNutrients").doc(payload.id);
  const nutrients = {
    patientId: payload.id,
    calories: payload.calories,
    carbs: payload.carbs,
    fats: payload.fats,
    proteins: payload.proteins,
  };
  await ref.set(nutrients, { merge: true });
  return nutrients;
};

export const getNutrientsById = async (userId) => {
  const ref = db.collection("patientNutrients").doc(userId);
  const doc = await ref.get();
  const nutrients = doc.data();
  return nutrients;
};

export const toggleShowFeatures = async (payload) => {
  const userRef = db.collection("users").doc(payload.id);
  await userRef.set({ showFeatures: payload?.showFeatures }, { merge: true });
};

//sessions
export const getUserSessions = async (userId) => {
  const sessions = [];
  const querySnapshot = await db
    .collection("sessions")
    .where("userId", "==", userId)
    .get();
  querySnapshot.forEach((doc) => {
    sessions.push(doc.data());
  });
  return sessions;
};

export const getSession = async (sessionId) => {
  const sessionRef = db.collection("sessions").doc(sessionId);
  const sessionDoc = await sessionRef.get();
  return sessionDoc.data();
};

export const addSession = async (payload) => {
  const ref = db.collection("sessions").doc();
  await ref.set(
    {
      sessionId: ref.id,
      userId: payload.userId,
      title: payload.title.trim().toLowerCase(),
      notes: payload.notes,
      steps: payload.steps,
      createdOn: new Date().getTime(),
    },
    { merge: true }
  );
  return ref.id;
};

export const updateSession = async (payload) => {
  const ref = db.collection("sessions").doc(payload.sessionId);
  await ref.set(
    {
      sessionId: ref.id,
      userId: payload.userId,
      title: payload.title.trim().toLowerCase(),
      notes: payload.notes,
      steps: payload.steps,
      updatedOn: new Date().getTime(),
    },
    { merge: true }
  );
  return ref.id;
};

export const deleteSession = async (sessionId) => {
  const sessionRef = db.collection("sessions").doc(sessionId);
  await sessionRef.delete();
};

export const addRecentMealData = async (payload) => {
  let mealData = [];
  const ref = db.collection("recentlyAddedMeals").doc(payload.userId);
  const recentMealDoc = await ref.get();
  const recentMeal = recentMealDoc.data();
  console.log("...payload.data", recentMeal, payload.data);
  if (recentMeal?.mealData?.length > 0) {
    mealData = [...recentMeal.mealData, ...payload.data];
    console.log('firts');
  } else {
    mealData = payload.data;
    console.log('second');
  }
  console.log("mealData", mealData);
  // sort by most recent
  const sortedByMostRecent = mealData.sort(
    (objA, objB) => Number(objB.dateCreated) - Number(objA.dateCreated)
  );
  console.log('sortedByMostRecent', sortedByMostRecent);
  // remove duplicate meals
  const uniqueMeals = sortedByMostRecent.filter((obj, index) => {
    return index === sortedByMostRecent.findIndex((o) => obj.mid === o.mid);
  });
  console.log('uniqueMeals', uniqueMeals);

  const mealDataObj = {
    userId: payload.userId,
    mealData: uniqueMeals,
  };
  console.log('mealDataObj', mealDataObj);
  await ref.set(mealDataObj, { merge: true });
};

export const getRecentMealData = async (userId) => {
  const ref = db.collection("recentlyAddedMeals").doc(userId);
  const recentMealDoc = await ref.get();
  const recentMeal = recentMealDoc.data();
  return recentMeal;
};

export const addExternalLinksToUser = async (userId, payload) => {
  const ref = db.collection("users").doc(userId);
  await ref.set({ additionalResources: payload }, { merge: true });
};

export const updateUserNotificationPreferences = async (userId, payload) => {
  const ref = db.collection("users").doc(userId);
  await ref.set({ notificationPreferences: payload }, { merge: true });
};

export const getDietitianIncome = async (uid) => {
  const checkoutPayments = [];
  const querySnapshot = await db
    .collection("checkoutPayments")
    .where("assignedDietitian", "==", uid)
    .get();
  querySnapshot.forEach((doc) => {
    checkoutPayments.push(doc.data());
  });
  const checkoutPaymentsWithUser = await Promise.all(
    checkoutPayments.map(async (checkoutPayment) => {
      const userData = checkoutPayment.email
        ? await getUserDataByEmail(checkoutPayment.email)
        : null;
      return {
        ...checkoutPayment,
        user: userData,
      };
    })
  );
  return checkoutPaymentsWithUser;
};
