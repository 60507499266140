import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toast } from "../../../../state";
import helpIcon from "./../../../../assets/icons/help.png";

import styles from "./ChangePassword.module.scss";
import { signInWithEmailAndPassword } from "firebase/auth";
import { updateUserWithPassword } from "../../../../services/firebase";
import { useHistory } from "react-router";
import { Avatar, ButtonBase } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { logout } from "../../../../services/firebase";
import AnimateElement from "../../../../components/AnimateElement";

const validationPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Minimum password characters is 8")
    .max(20, "Maximum password characters is 20")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+=\-[\]{}|\\:;'",.<>/?]{8,}$/,
      "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(8, "Minimum password characters is 8")
    .max(20, "Maximum password characters is 20")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+=\-[\]{}|\\:;'",.<>/?]{8,}$/,
      "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
    )
    .required("Confirm is required"),
});

const ResetFinish = (props) => {
  const { userState } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const moveTo = (path) => history.push(path);
  const [logoutDialogIsOpen, setLogoutDialogIsOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  // useEffect(() => {
  //   if (!email || !sessionStorage.getItem("resetToken")) {
  //     setSignInStep("main");
  //   }
  // }, []);

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUpdateUserWithEmailAndPassword = async (
    oldPassword,
    password
  ) => {
    console.log(oldPassword, password);
    const email = userState?.user?.email;
    console.log(email);
    try {
      const data = await updateUserWithPassword(email, oldPassword, password);
      console.log("data", data);
      if (data === "success") {
        dispatch(toast({ message: "Password updated successfully" }));
        moveTo("/home");
      } else {
        dispatch(toast({ message: "unable to update password" }));
      }
    } catch (error) {
      console.log(error);
      dispatch(toast({ message: "unable to update password" }));
    }
  };

  return (
    <AnimateElement duration={"0.5"}>
      <div>
        <div className={styles.headerTop}>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => history.goBack()}
          >
            <CloseOutlinedIcon className={styles.closeIcon} />
          </IconButton>
        </div>
        <Formik
          validationSchema={validationPasswordSchema}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values, FormikBag) => {
            if (values.password !== values.confirmPassword) {
              dispatch(toast({ message: "Password does not match!!!" }));
              return;
            }
            await handleUpdateUserWithEmailAndPassword(
              values.oldPassword,
              values.password
            );
            FormikBag.setSubmitting(false);
          }}
        >
          {(formik) => (
            <div className={styles.emailPageContent}>
              <div className={styles.pass}>Change Password</div>
              <TextField
                fullWidth
                label="Old Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                className={styles.textField}
                inputProps={{ autoComplete: "new-password" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("oldPassword")}
                // error={Boolean(formik.touched.password && formik.errors.password)}
                // helperText={
                //   Boolean(formik.touched.password && formik.errors.password)
                //     ? formik.errors.password
                //     : "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
                // }
                disabled={formik.isSubmitting}
              />

              <TextField
                fullWidth
                label="New Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                className={styles.textField}
                inputProps={{ autoComplete: "new-password" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("password")}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                helperText={
                  Boolean(formik.touched.password && formik.errors.password)
                    ? formik.errors.password
                    : "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
                }
                disabled={formik.isSubmitting}
              />

              <TextField
                fullWidth
                label="Confirm password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                className={styles.textField}
                inputProps={{ autoComplete: "new-password" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("confirmPassword")}
                error={Boolean(
                  formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                )}
                helperText={
                  Boolean(
                    formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                  )
                    ? formik.errors.confirmPassword
                    : "Minimum eight characters, at least one uppercase letter, one lowercase letter one number and one special character"
                }
                disabled={formik.isSubmitting}
              />
              <Button
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                className={styles.button}
                onClick={formik.submitForm}
                disabled={formik.isSubmitting}
              >
                Update
              </Button>
              <div
                className={styles.forgot}
                onClick={() => setLogoutDialogIsOpen(true)}
              >
                forgotten old password
              </div>
            </div>
          )}
        </Formik>
        {logoutDialogIsOpen && (
          <div className={styles.logOutDialog}>
            <h2 className={styles.logOutText}>
              <div>You have to log out to reset password</div>
              Are you sure you want to logout?
            </h2>
            <ButtonBase
              className={styles.cancelLogoutButton}
              onClick={() => setLogoutDialogIsOpen(false)}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              className={styles.confirmLogoutButton}
              onClick={async () => [
                history.push("/account/password?reset=true"),
                logout(),
              ]}
            >
              Confirm
            </ButtonBase>
          </div>
        )}
        <div className="pb-5" />

        <a
          href="https://www.stovehealth.com/#help"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonBase className={styles.helpButton}>
            <Avatar className={styles.helpIcon} src={helpIcon} />
            <span>Help</span>
          </ButtonBase>
        </a>
      </div>
    </AnimateElement>
  );
};

export default ResetFinish;
