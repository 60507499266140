import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThemeDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = (props) => {
    const { open, onCancel, onConfirm, message, isLoading, isLoadingText } = props;
    return (
        <ThemeDialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCancel}
            fullWidth
            maxWidth="sm"
            aria-labelledby="alert-dialog"
            aria-describedby="alert-dialog"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} className="text-capitalize" onClick={onCancel} color="default" variant="contained">
                    Cancel
                 </Button>
                <Button disabled={isLoading} className="text-capitalize" onClick={onConfirm} color="primary" variant="contained">
                    {isLoading ? `${isLoadingText}` : "Confirm"}
                </Button>
            </DialogActions>
        </ThemeDialog>
    );
}

Dialog.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    message: PropTypes.string,
    isLoading: PropTypes.bool,
    isLoadingText: PropTypes.string
};

export default Dialog;
