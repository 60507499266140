import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { useLocation } from "react-router-dom";

import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import AddIcons from "@material-ui/icons/Add";

import searchIcon from "./../../../../assets/icons/search.png";
import backIcon from "./../../../../assets/icons/back.png";
import Private from "./../../../../assets/icons/private.svg";
import MessageBox from "../../../../components/MessageBox/MessageBox";

import { setActiveConversation, toast } from "../../../../state";
import styles from "./Search.module.scss";
import AnimateElement from "../../../../components/AnimateElement";

const Groups = (props) => {
  const [searchText, setSearchText] = useState("");
  const { url, userState, chatState, conversationsReady } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleNavigateToMessages = () => {
    history.push(`${url}/messsages`);
  };

  const navigateToConversation = (chat, isPrivate, type) => {
    console.log(chat);
    if (isPrivate === true) {
      if (
        chat.membersId.includes(userState.user.uid) &&
        chat.membersIdData &&
        chat.membersIdData.length > 0
      ) {
        dispatch(setActiveConversation(chat));
        history.push(`${url}/conversation`);
      } else {
        dispatch(
          toast({ message: "Contact Admin to join any private groups" })
        );
      }
    } else {
      dispatch(setActiveConversation(chat));
      if (type === "auto-join") {
        history.push(`${url}/conversation/auto-join`);
      } else {
        history.push(`${url}/conversation`);
      }
    }
  };

  console.log(chatState);
  const isLoading =
    chatState.puplicConversationIsLoading === true ||
    chatState.profilesIsLoading === true ||
    conversationsReady === false
      ? true
      : false;
  if (!isLoading) {
    if (location?.pathname?.split("/")[3]) {
      const chat = chatState.puplicConversations.filter(function (singleChat) {
        return singleChat.chatId === location.pathname.split("/")[3];
      });
      if (chat.length > 0) {
        navigateToConversation(chat[0], false, "auto-join");
      } else {
        dispatch(
          toast({ message: "Unable to find custom group contact Admin" })
        );
      }
    }
  }
  return (
    <div>
      <div className={styles.header}>
        <IconButton
          size="small"
          className={styles.icon}
          onClick={handleNavigateToMessages}
        >
          <img alt="Back" src={backIcon} className={styles.arrowback} />
        </IconButton>
        <InputBase
          autoFocus
          className={styles.searchInput}
          placeholder="Search topics"
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
      </div>

      {isLoading === true && (
        <div className="px-4 pt-4 text-center">
          <CircularProgress size={15} disableShrink />
        </div>
      )}

      {isLoading === false && (
        <div className={styles.homePage}>
          <div className={styles.listTitle}>Suggested public groups</div>
          <List className={styles.list}>
            {chatState.puplicConversations.filter(
              (chat) =>
                chat.uniqueName &&
                chat.uniqueName.toLowerCase().includes(searchText.toLowerCase())
            ).length === 0 && searchText.length > 0 ? (
              <MessageBox text="No group found" />
            ) : (
              <>
                {chatState.puplicConversations
                  .filter(
                    (chat) =>
                      chat.uniqueName &&
                      chat.uniqueName
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                  )
                  .map((chat) => {
                    return (
                      <ListItem
                        key={chat.chatId}
                        className={styles.listItem}
                        button
                        onClick={() =>
                          navigateToConversation(chat, chat?.isPrivate, "")
                        }
                      >
                        <ListItemAvatar className={styles.listAvatar}>
                          <AnimateElement duration={"0.5"}>
                            <Avatar
                              className={styles.avatar}
                              src={chat.thumbnail}
                            >
                              <PeopleIcon />
                            </Avatar>
                          </AnimateElement>
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography
                          primary={
                            <div className={styles.primaryText}>
                              {chat.uniqueName}
                              {chat.membersId.includes(userState.user.uid) &&
                              chat.membersIdData &&
                              chat.membersIdData.length > 0 ? null : (
                                <span>
                                  {" "}
                                  {chat.isPrivate && (
                                    <img
                                      alt="Back"
                                      src={Private}
                                      style={{ width: "15px" }}
                                    />
                                  )}
                                </span>
                              )}
                            </div>
                          }
                          secondary={
                            <div>
                              <div className={styles.secondaryText}>
                                {chat.membersId.length}{" "}
                                {chat.membersId.length > 1
                                  ? "members"
                                  : "member"}
                              </div>
                              {chat.membersId.includes(userState.user.uid) &&
                                chat.membersIdData &&
                                chat.membersIdData.length > 0 && (
                                  <div className={styles.avatarGroupContainer}>
                                    <AvatarGroup
                                      max={4}
                                      spacing="medium"
                                      classes={{
                                        avatar: styles.avatarGroupIcon,
                                      }}
                                    >
                                      {chat.membersIdData.map((user) => {
                                        return (
                                          <AnimateElement>
                                            <Avatar
                                              key={user?.uid}
                                              src={user?.photoURL}
                                            >
                                              <PeopleIcon />
                                            </Avatar>
                                          </AnimateElement>
                                        );
                                      })}
                                    </AvatarGroup>
                                    <span className={styles.avatarGroupText}>
                                      Joined
                                    </span>
                                  </div>
                                )}
                            </div>
                          }
                        />
                        {chat.membersId.includes(userState.user.uid) && (
                          <span className={styles.joined}>Joined</span>
                        )}
                      </ListItem>
                    );
                  })}
              </>
            )}
          </List>
          <div className={styles.listTitle}>Suggested contacts</div>
          <List className={styles.list}>
            {chatState.profiles.filter(
              (profile) =>
                profile.displayName &&
                profile.displayName
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length === 0 && searchText.length > 0 ? (
              <MessageBox text="No contact found" />
            ) : (
              <>
                {chatState.profiles
                  .filter(
                    (profile) =>
                      profile.displayName &&
                      profile.displayName
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                  )
                  .map((profile) => {
                    return (
                      <ListItem
                        key={profile.uid}
                        className={styles.listItem}
                        button
                        onClick={() => [
                          navigateToConversation(profile, false, ""),
                          console.log("profile", profile),
                        ]}
                      >
                        <ListItemAvatar className={styles.listAvatar}>
                          <AnimateElement duration={"0.5"}>
                            <Avatar
                              className={styles.avatar}
                              src={profile.photoURL}
                            >
                              <PersonIcon />
                            </Avatar>
                          </AnimateElement>
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography
                          primary={
                            <div className={styles.primaryText}>
                              {profile.displayName}
                            </div>
                          }
                        />
                      </ListItem>
                    );
                  })}
              </>
            )}
          </List>
        </div>
      )}
    </div>
  );
};

export default Groups;
