import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";

import styles from "./edit.module.scss";
import AnimateElement from "../../../components/AnimateElement";
import { CircularProgress } from "@material-ui/core";
import addBtn from "../../../assets/add-btn-black.png";
import checkedBtn from "../../../assets/checked-green-btn.png";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useHistory } from "react-router";

const Edit = () => {
  const [meals, setmeals] = useState([
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
    {
      recipe_name: "men",
      quantity: "20",
      size: "38",
      image:
        "https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262",
    },
  ]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleClick = (i, item) => {};
  const onClose = () => {
    history.goBack();
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.close}>
          {" "}
          <CloseOutlinedIcon onClick={onClose} />
        </div>
        <div className={styles.title}> Edit Meals</div>
        <div></div>
      </div>
      <AnimateElement duration={"0.5"}>
        {meals && !loading ? (
          <div className={styles.con}>
            {meals.map((item, i) => (
              <div key={i}>
                {item.recipe_name ? (
                  <div
                    className={`${styles.dataBox} ${
                      selected.includes(item) && styles.add
                    }`}
                  >
                    <div className={styles.dataContent}>
                      <div
                        style={{
                          backgroundImage: `url(${item?.image})`,
                        }}
                        className={styles.listImage}
                      />
                      <div className={styles.dataText}>
                        <h6 className={styles.dataTextHeading}>
                          {item.recipe_name}
                        </h6>
                        <div>
                          <p
                            className={styles.text}
                          >{`${item?.size} ${item?.quantity}`}</p>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          handleClick(i, item);
                        }}
                      >
                        <img
                          src={`${
                            selected.includes(item) ? checkedBtn : addBtn
                          }`}
                          alt="add-button"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        )}
      </AnimateElement>
    </>
  );
};

export default Edit;
