import React from "react";
import styles from "./Fallback.module.scss";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ButtonBase from "@material-ui/core/ButtonBase";
import error404 from "../../assets/error404.svg";
import { useHistory } from "react-router";

const Fallback = () => {
  const history = useHistory();

  return (
    <div className={styles.errorPage}>
      <div onClick={() => history.push("/")}>
        <CloseOutlinedIcon />
      </div>

      <div className={styles.content}>
        <img src={error404} alt="error" />
        <p className={styles.errorTitle}>
          Ops! It looks like something went wrong!
        </p>
        <p className={styles.errorDescription}>
          We’re very sorry. Please, send us an email, and we’ll get back ASAP.
        </p>
        <ButtonBase
          className={styles.btn}
          onClick={() => history.push("/recipes")}
        >
          {"Home"}
        </ButtonBase>
      </div>
    </div>
  );
};

export default Fallback;
