import React, { useState, useEffect } from "react";
import checked from "../../../../assets/checked-black.png";
import din1 from "../../../../assets/dina1.svg";
import din2 from "../../../../assets/dina2.svg";
import din3 from "../../../../assets/dina3.svg";
import back from "../../../../assets/icons/back.png";
import settings from "../../../../assets/icons/gpt_setting.svg";
import styles from "./Header.module.scss";
import { useHistory } from "react-router";

const Header = ({ isVisible, setIsVisible, mode }) => {
   const history = useHistory();

   return (
      <>
         <div className={styles.con}>
            <div className={styles.data}>
               <img
                  onClick={() => {
                     history.goBack();
                     console.log("back");
                  }}
                  className={styles.back}
                  src={back}
                  alt='back'
               />

               <div
                  className={styles.title}
                  onClick={() => {
                     history.goBack();
                  }}
               >
                  Your AI Companion
               </div>
               <div className={styles.settings}>
                  {mode === "recipe" && <img className={styles.back} src={settings} alt='settings' onClick={() => setIsVisible(!isVisible)} />}
               </div>
            </div>

            <div className={styles.beta}>
            <img
                  className={styles.back}
                  src={checked}
                  alt='checked'
               />
               <p className={styles.betaText}><b>Beta</b> - leave feedback <a href="https://www.stovehealth.com/#help" target="_blank" rel="noopener noreferrer">here</a></p></div>
         </div>
      </>
   );
};

export default Header;
