import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import PersonIcon from "@material-ui/icons/Person";

import searchIcon from "./../../../../assets/icons/search.png";
import ferrisImg from "./../../../../assets/icons/user.png";

import Layout from "../../../../layout";
import NotFound from "./../NotFound/NotFound";

import {
  setActiveConversation,
  getAllUnreadIndividualNotifications,
  getUserUnreadGroupMessages,
} from "../../../../state";
import { getUserData } from "../../../../services/firebase/index";

import styles from "./Messages.module.scss";
import AnimateElement from "../../../../components/AnimateElement";

const Messsages = (props) => {
  const {
    url,
    setDialogIsOpen,
    userState,
    chatState,
    conversationsReady,
    privateConversationsIsLoading,
    privateConversations,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [dietitian, setDietitian] = useState(null);
  const [privateConvo, setPrivateConvo] = useState([]);
  const [formattedConvo, setFormattedConvo] = useState([]);
  const [userUnreadMsgCount, setUserUnreadMsgCount] = useState({});
  const { unReadIndividualNotifications, unReadGroupMessages } = useSelector(
    (state) => state.notification,
    shallowEqual
  );

  const handleNavigateToSearch = () => {
    history.push(`${url}/search`);
  };

  const navigateToConversation = (channel) => {
    dispatch(setActiveConversation(channel));
    history.push(`${url}/conversation`);
  };

  const getUser = async (id) => {
    const user = await getUserData(id);
    if (user) {
      setDietitian(user);
    }
  };

  const isLoading =
    conversationsReady === false ||
    privateConversationsIsLoading === true ||
    chatState.puplicConversationIsLoading === true
      ? true
      : false;

  const userAssignedChatGroup = (user) => {
    if (user?.assignedChatGroup) {
      if (user?.assignedChatGroup.chatGroupId) {
        return [user?.assignedChatGroup.chatGroupId];
      } else {
        return user?.assignedChatGroup?.map((group) => group.chatId);
      }
    } else return [];
  };

  const usersWithUnreadMessages = () => {
    if (privateConversations && privateConversations.length > 0) {
      // check if convo has unread messages
      const unreadMessages = privateConversations.filter((convo) => {
        const participant = getParticipant(
          convo.attributes,
          userState.user.uid
        );
        const chat = getPublicChat(
          chatState.puplicConversations,
          convo.uniqueName
        );

        if (participant && Object.keys(userUnreadMsgCount)?.length > 0) {
          return userUnreadMsgCount[participant.identity] > 0;
        }

        if (chat && Object.keys(userUnreadMsgCount)?.length > 0) {
          return userUnreadMsgCount[chat.chatId] > 0;
        }
      });

      return unreadMessages;
    }
  };

  const getUnreadMessages = () => {
    let users = {};

    if (privateConversations && privateConversations?.length > 0) {
      privateConversations.map((conversation) => {
        const participant = getParticipant(
          conversation.attributes,
          userState.user.uid
        );
        const chat = getPublicChat(
          chatState.puplicConversations,
          conversation.uniqueName
        );

        if (participant) {
          // check if participant has an unread message and increase count in users
          unReadIndividualNotifications.length > 0 &&
            unReadIndividualNotifications.map((msg) => {
              if (participant.identity === msg.senderId) {
                return (users[participant.identity] =
                  (users[participant.identity] || 0) + 1);
              }
            });
        }

        if (chat) {
          // check if participant has an unread message and increase count in users
          unReadGroupMessages.length > 0 &&
            unReadGroupMessages.map((msg) => {
              if (chat.chatId === msg.receiverId) {
                return (users[chat.chatId] = (users[chat.chatId] || 0) + 1);
              }
            });
        }
      });
    }
    setUserUnreadMsgCount(users);
    return users;
  };

  useEffect(() => {
    const result = usersWithUnreadMessages();
    result && setFormattedConvo(result);
  }, [
    privateConversations,
    unReadIndividualNotifications,
    unReadGroupMessages,
  ]);


  useEffect(() => {
    if (userState?.user?.assignedDietitian?.uid) {
      getUser(userState?.user?.assignedDietitian?.uid);
    }
  }, []);

  useEffect(() => {
    const result = sortPrivateChat(privateConversations);
    result && setPrivateConvo(result);
  }, [privateConversations]);

  useEffect(() => {
    if (userState.user) {
      dispatch(getAllUnreadIndividualNotifications(userState.user.uid));
      dispatch(getUserUnreadGroupMessages(userState.user.uid));
    }
  }, [dispatch, userState.user]);

  useEffect(() => {
    getUnreadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    privateConversations,
    unReadIndividualNotifications,
    unReadGroupMessages,
  ]);

  return (
    <Layout>
      {isLoading === true && (
        <div className="px-4 pt-4 text-center">
          <CircularProgress size={15} disableShrink />
        </div>
      )}

      {isLoading === false && (
        <div className={styles.homePage}>
          <div className={styles.header}>
            <div className={styles.searchContainer}>
              <IconButton>
                <Avatar
                  alt="search"
                  sizes=""
                  variant="square"
                  src={searchIcon}
                  className={styles.searchIcon}
                />
              </IconButton>
              <InputBase
                className={styles.searchInput}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onFocus={handleNavigateToSearch}
              />
            </div>
            <ButtonBase
              onClick={() => setDialogIsOpen(true)}
              className={styles.addIcon}
            >
              <AddOutlinedIcon />
            </ButtonBase>
          </div>

          {privateConversations.length === 0 && <NotFound url={url} />}

          <List className={styles.list}>
            <>
              {userState.user.assignedDietitian ? (
                <ListItem
                  className={styles.listItem}
                  button
                  onClick={() =>
                    privateConversations.filter(
                      (conversation) =>
                        dietitian?.uid in conversation?.attributes
                    ).length > 0
                      ? navigateToConversation(
                          privateConversations.filter(
                            (conversation) =>
                              dietitian?.uid in conversation?.attributes
                          )[0]
                        )
                      : navigateToConversation(dietitian)
                  }
                >
                  <ListItemAvatar className={styles.listAvatar}>
                    <AnimateElement duration={"0.5"}>
                      <Avatar
                        className={styles.avatar}
                        src={dietitian?.photoURL}
                      >
                        <PersonIcon />
                      </Avatar>
                    </AnimateElement>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div className={styles.containerText}>
                        <div className={styles.primaryTextContainer}>
                          <span className={styles.primaryTextleft}>
                            {userState?.user?.assignedDietitian?.displayName}
                          </span>
                          <span className={styles.primaryTextRight}>
                            dietitian
                          </span>
                          {userUnreadMsgCount[dietitian?.uid] && (
                            <div className={styles.unread}>
                              {userUnreadMsgCount[dietitian?.uid]}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </ListItem>
              ) : null}
              {userState.user.assignedChatGroup ? (
                <>
                  {chatState.puplicConversations
                    // .filter((conversation) => conversation.chatId === userState?.user.assignedChatGroup.chatGroupId)
                    .filter((conversation) =>
                      userAssignedChatGroup(userState?.user).includes(
                        conversation.chatId
                      )
                    )
                    .map((conversation) => {
                      return (
                        <Fragment key={conversation.sid}>
                          <>
                            <ListItem
                              key={conversation.sid}
                              className={styles.listItem}
                              button
                              onClick={() =>
                                navigateToConversation(conversation)
                              }
                            >
                              <ListItemAvatar className={styles.listAvatar}>
                                <AnimateElement duration={"0.5"}>
                                  <Avatar
                                    className={styles.avatar}
                                    src={conversation.thumbnail}
                                  >
                                    <PersonIcon />
                                  </Avatar>
                                </AnimateElement>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <div className={styles.containerText}>
                                    <div
                                      className={styles.primaryTextContainer}
                                    >
                                      <span className={styles.primaryTextleft}>
                                        {conversation.uniqueName}
                                      </span>
                                      <span className={styles.primaryTextRight}>
                                        dietitian group
                                      </span>
                                      {userUnreadMsgCount[
                                        conversation.chatId
                                      ] && (
                                        <div className={styles.unread}>
                                          {
                                            userUnreadMsgCount[
                                              conversation.chatId
                                            ]
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                }
                              />
                            </ListItem>
                          </>
                        </Fragment>
                      );
                    })}
                </>
              ) : null}
              {/* unread messages */}
              {formattedConvo?.length > 0 &&
                formattedConvo.map((conversation) => {
                  const participant = getParticipant(
                    conversation.attributes,
                    userState.user.uid
                  );
                  const chat = getPublicChat(
                    chatState.puplicConversations,
                    conversation.uniqueName
                  );
                  return (
                    <Fragment key={conversation.sid}>
                      {conversation.attributes.isPrivate === true &&
                        !(dietitian?.uid in conversation?.attributes) && (
                          <>
                            {conversation.lastMessage && (
                              <ListItem
                                key={conversation.sid}
                                className={styles.listItem}
                                button
                                onClick={() =>
                                  navigateToConversation(conversation)
                                }
                              >
                                <ListItemAvatar className={styles.listAvatar}>
                                  <AnimateElement duration={"0.5"}>
                                    <Avatar
                                      className={styles.avatar}
                                      src={participant.thumbnail}
                                    >
                                      <PersonIcon />
                                    </Avatar>
                                  </AnimateElement>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <div className={styles.containerText}>
                                      <div
                                        className={styles.primaryTextContainer}
                                      >
                                        <span
                                          className={styles.primaryTextleft}
                                        >
                                          {participant.name}
                                        </span>
                                        <span
                                          className={styles.primaryTextRight}
                                        >
                                          {conversation.lastMessage &&
                                            formatDistanceToNowStrict(
                                              conversation.lastMessage
                                                .dateCreated
                                            )}
                                        </span>
                                        {userUnreadMsgCount[
                                          participant.identity
                                        ] && (
                                          <div className={styles.unread}>
                                            {
                                              userUnreadMsgCount[
                                                participant.identity
                                              ]
                                            }
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  }
                                />
                              </ListItem>
                            )}
                          </>
                        )}

                      {chat &&
                        !userAssignedChatGroup(userState?.user).includes(
                          chat.chatId
                        ) && (
                          <ListItem
                            key={conversation.sid}
                            className={styles.listItem}
                            button
                            onClick={() => navigateToConversation(conversation)}
                          >
                            <ListItemAvatar className={styles.listAvatar}>
                              <AnimateElement duration={"0.5"}>
                                <Avatar
                                  className={styles.avatar}
                                  src={chat.thumbnail}
                                >
                                  <PersonIcon />
                                </Avatar>
                              </AnimateElement>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <div className={styles.containerText}>
                                  <div className={styles.primaryTextContainer}>
                                    <span className={styles.primaryTextleft}>
                                      {chat.uniqueName}
                                    </span>
                                    <span className={styles.primaryTextRight}>
                                      {conversation.lastMessage &&
                                        formatDistanceToNowStrict(
                                          conversation.lastMessage.dateCreated
                                        )}
                                    </span>
                                    {userUnreadMsgCount[chat.chatId] && (
                                      <div className={styles.unread}>
                                        {userUnreadMsgCount[chat.chatId]}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              }
                            />
                          </ListItem>
                        )}
                    </Fragment>
                  );
                })}
              {/* private conversations */}
              {privateConvo.map((conversation) => {
                const participant = getParticipant(
                  conversation.attributes,
                  userState.user.uid
                );
                const chat = getPublicChat(
                  chatState.puplicConversations,
                  conversation.uniqueName
                );
                return (
                  <Fragment key={conversation.sid}>
                    {conversation.attributes.isPrivate === true &&
                      !(dietitian?.uid in conversation?.attributes) &&
                      !Object.keys(userUnreadMsgCount).includes(
                        participant.identity
                      ) && (
                        <>
                          {conversation.lastMessage && (
                            <ListItem
                              key={conversation.sid}
                              className={styles.listItem}
                              button
                              onClick={() =>
                                navigateToConversation(conversation)
                              }
                            >
                              <ListItemAvatar className={styles.listAvatar}>
                                <AnimateElement duration={"0.5"}>
                                  <Avatar
                                    className={styles.avatar}
                                    src={participant.thumbnail}
                                  >
                                    <PersonIcon />
                                  </Avatar>
                                </AnimateElement>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <div className={styles.containerText}>
                                    <div
                                      className={styles.primaryTextContainer}
                                    >
                                      <span className={styles.primaryTextleft}>
                                        {participant.name}
                                      </span>
                                      <span className={styles.primaryTextRight}>
                                        {conversation.lastMessage &&
                                          formatDistanceToNowStrict(
                                            conversation.lastMessage.dateCreated
                                          )}
                                      </span>
                                      {userUnreadMsgCount[
                                        participant.identity
                                      ] && (
                                        <div className={styles.unread}>
                                          {
                                            userUnreadMsgCount[
                                              participant.identity
                                            ]
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                }
                              />
                            </ListItem>
                          )}
                        </>
                      )}

                    {chat &&
                      !userAssignedChatGroup(userState?.user).includes(
                        chat.chatId
                      ) &&
                      !Object.keys(userUnreadMsgCount).includes(
                        chat.chatId
                      ) && (
                        <ListItem
                          key={conversation.sid}
                          className={styles.listItem}
                          button
                          onClick={() => navigateToConversation(conversation)}
                        >
                          <ListItemAvatar className={styles.listAvatar}>
                            <AnimateElement >
                              <Avatar
                                className={styles.avatar}
                                src={chat.thumbnail}
                              >
                                <PersonIcon />
                              </Avatar>
                            </AnimateElement>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <div className={styles.containerText}>
                                <div className={styles.primaryTextContainer}>
                                  <span className={styles.primaryTextleft}>
                                    {chat.uniqueName}
                                  </span>
                                  <span className={styles.primaryTextRight}>
                                    {conversation.lastMessage &&
                                      formatDistanceToNowStrict(
                                        conversation.lastMessage.dateCreated
                                      )}
                                  </span>
                                  {userUnreadMsgCount[chat.chatId] && (
                                    <div className={styles.unread}>
                                      {userUnreadMsgCount[chat.chatId]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            }
                          />
                        </ListItem>
                      )}
                  </Fragment>
                );
              })}
            </>
          </List>
        </div>
      )}
    </Layout>
  );
};

export default Messsages;

export const getParticipant = (attributes, uid) => {
  for (const property in attributes) {
    if (property !== uid && property !== "isPrivate") {
      return attributes[property];
    }
  }
};

export const getPublicChat = (chats, uniqueName) => {
  return chats.find((chat) => chat.chatId === uniqueName);
};

export const sortPrivateChat = (chatConversations) => {
  const filteredConversations = chatConversations.filter(
    (conversation) => conversation.lastMessage
  );
  const chat = filteredConversations.sort((a, b) => {
    return b.lastMessage.dateCreated - a.lastMessage.dateCreated;
  });
  return chat;
};
