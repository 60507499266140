import React from "react";


import { useHistory, useParams } from "react-router-dom";
import { withOrientationChange, isMobileOnly } from 'react-device-detect';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns'



import { toast } from "../../../state";
import { getSessionQuery } from "../../../services/queries";
import styles from "./SessionsDetails.module.scss";





const SessionsDetails = (props) => {

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const history = useHistory();
    const { sessionId, number } = useParams();
    const { isLoading, data: sessionData } = getSessionQuery(sessionId);

    const goBack = () => {
        history.goBack();
    };



    return (
        <>
            <div className={styles.page}>

                <div className={styles.header}>
                    <IconButton size="small" onClick={goBack}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00065 12.3379L20 12.3379" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                    <div className={styles.headerTitle}>Session Detail</div>
                    <IconButton size="small">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5.0135V4.9865" stroke="#130F26" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 12.0135V11.9865" stroke="#130F26" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 19.0135V18.9865" stroke="#130F26" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                </div>


                {isLoading && (
                    <div className="px-4 pt-4 text-center">
                        <CircularProgress size={15} disableShrink />
                    </div>
                )}

                {!isLoading && sessionData && (

                    <div className={styles.container}>


                        <div className={styles.titleContainer}>
                            <div className={styles.titleImage}>{number}</div>
                            <div className={styles.titleTextContainer}>
                                <div className={styles.titleHeadertext}>{sessionData.title}</div>
                                <div className={styles.titleHeaderSubText}>{format(new Date(sessionData.createdOn), "d MMMM yyyy")}</div>
                            </div>
                        </div>

                        <div className={styles.listHeaderContainer}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 2V5" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16 2V5" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M7 13H15" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M7 17H12" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>Notes</span>
                        </div>
                        {sessionData.notes.map((note, index) => {
                            const parts = note.split(urlRegex);
                            return (
                                <div className={styles.listText} key={index}>
                                    <span>→</span>
                                    <span className={styles.listTextValue}>{parts.map((part, index) => {
                                        if (part.match(urlRegex)) {
                                            return <a href={part} target="_blank" rel="noopener noreferrer" key={index}>{part}</a>
                                        }
                                        return part
                                    })}</span>
                                </div>
                            )
                        //     return (
                        //     <div className={styles.listText} key={index}>
                        //         <span>→</span>
                        //         <span className={styles.listTextValue}>{note}</span>
                        //     </div>
                        // )
                    }
                        )}

                        <div className={styles.listHeaderContainer}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.1499 2V22" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.34" d="M5.1499 4H16.3499C19.0499 4 19.6499 5.5 17.7499 7.4L16.5499 8.6C15.7499 9.4 15.7499 10.7 16.5499 11.4L17.7499 12.6C19.6499 14.5 18.9499 16 16.3499 16H5.1499" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>Next steps</span>
                        </div>
                        {sessionData.steps.map((step, index) => (
                            <div className={styles.listText} key={index}>
                                <span>→</span>
                                <span className={styles.listTextValue}>{step}</span>
                            </div>
                        ))}
                        {/* <ButtonBase className={styles.confirmButton} component="a" href="https://www.stovehealth.com/#pricing" rel="noopener" target="_blank">Book Next Session</ButtonBase> */}
                    </div>

                )}

            </div>
        </>
    )
}

export default withOrientationChange(SessionsDetails);