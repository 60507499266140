import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./AcceptModal.module.scss";
import { toast, setUser } from "../../../state";
import modalHandle from "../../../assets/modal-handle.png";
import progress from "../../../assets/icons/progress.svg";
import not from "../../../assets/icons/not.svg";
import completed from "../../../assets/icons/complete.svg";
import {
  addUserEventsWater,
  getUserData,
  updateUserTask,
} from "../../../services/firebase/index";
import dayjs from "dayjs";
import { format } from "date-fns";

const today = dayjs();

export const AcceptModal = ({ open, onClose, data, allowRemove, selectedCard }) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  return (
    <>
      <div
        className={`${styles.modal} ${open ? styles.open : ""}`}
        onClick={onClose}
      >
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <img src={modalHandle} alt="" />
          </div>

          <div className={styles.icns}>
            <div className={styles.it} onClick={() => selectedCard === 4 ? data("singleSnack") : data("new")}>
              {selectedCard === 4 ? "Generate A Snack" : "Generate A Meal"}
            </div>
            <div className={styles.it} onClick={() => data("choose")}>
              Choose From Library
            </div>
            {allowRemove && (
              <div className={styles.it} onClick={() => data("remove")}>
                Remove
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
