import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllRewards } from "../services/firebase";


export const getRewards = createAsyncThunk(
    'Rewards/getAllRewards',
    async () => {
        const response = await getAllRewards();
        return response;
    }
);


const initialState = {
    rewardsIsLoading: true,
    rewardsIsError: false,
    rewards: []
}

export const Rewards = createSlice({
    name: 'Rewards',
    initialState,
    reducers: {
        setRewards: (state, action) => {
            state.rewards = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRewards.pending, (state) => {
                state.rewardsIsLoading = true;
                state.rewardsIsError = false;
            })
            .addCase(getRewards.fulfilled, (state, action) => {
                state.rewardsIsLoading = false;
                state.rewardsIsError = false;
                state.rewards = action.payload;
            })
            .addCase(getRewards.rejected, (state) => {
                state.rewardsIsLoading = false;
                state.rewardsIsError = true;
                state.rewards = [];
            })

    }
});

export const { setRewards } = Rewards.actions;
export default Rewards.reducer;