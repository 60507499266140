import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Highlighter from "react-highlight-words";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import MessageBox from "../../../../components/MessageBox/MessageBox";
import NotFound from "../NotFound/NotFound";
import backImg from "./../../../../assets/icons/back.png";
import searchImg from "./../../../../assets/icons/search.png";


import { getRecipesTextSearch, resetTypingSearchRecipes, getGroceriesTextSearch, resetTypingSearchGroceries, getArticleTextSearch, resetTypingSearchArticles } from "../../../../state";
import styles from "./TextSearch.module.scss";
import AnimateElement from "../../../../components/AnimateElement";


const TextSearch = (props) => {
    const { tabTexts } = props;
    const searchState = useSelector(state => state.search, shallowEqual);
    const params = useParams()
    const dispatch = useDispatch();
    const history = useHistory();
    const [searchText, setSearchText] = useState("");
    const searchBounce = useRef();
    const searchPending = useRef(true);


    useEffect(() => {
        if (searchText.length > 0) {
            searchPending.current = true;
            clearTimeout(searchBounce.current);
            searchBounce.current = setTimeout(() => {
                searchPending.current = false;
                dispatch(getGroceriesTextSearch(searchText));
                dispatch(getRecipesTextSearch(searchText));
                dispatch(getArticleTextSearch(searchText));
            }, 1500);
        } else {
            clearTimeout(searchBounce.current);
            dispatch(resetTypingSearchGroceries());
            dispatch(resetTypingSearchRecipes());
            dispatch(resetTypingSearchArticles());
        }
    }, [searchText, dispatch]);


    const handleGoBack = () => {
        history.goBack();
    };

    const handleTabChange = (event, tab) => {
        history.push(`/search/text/${tab}`)
    };


    const navigateToRecipe = (recideId) => {
        history.push(`/recipe/${recideId}`)
    }

    const navigateToGrocery = (id) => {
        history.push(`/shop/${id}`)
    }
    const navigateToArticle = (id) => {
        history.push(`/article/${id}`)
    }


    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerTop}>
                    <IconButton size="small" className="mr-3" onClick={handleGoBack}>
                        <Avatar alt="back" sizes="" variant="square" src={backImg} className={styles.backIcon} />
                    </IconButton>
                    <div className={styles.searchText}>Search</div>
                </div>
                <div className={styles.headerButtom}>
                    <svg className={styles.searchIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M17.5 18L21.5423 22" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <circle cx="11.5" cy="11.5" r="8.5" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <InputBase
                        autoFocus
                        className={styles.searchInput}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="What are you looking for?"
                        inputProps={{ 'aria-label': 'search' }}
                    />

                    {searchText.length > 0 && (
                        <IconButton size="small" onClick={() => setSearchText("")}>
                            <CloseOutlinedIcon className={styles.closeIcon} />
                        </IconButton>
                    )}
                </div>
            </div>

            <AppBar position="sticky" color="default" className={styles.appBar}>
                <Tabs
                    value={params.tab}
                    onChange={handleTabChange}
                    aria-label="Search tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="off"
                    variant="fullWidth"
                >
                    {tabTexts.map((text, idx) => <Tab key={idx} value={text.toLowerCase()} aria-labelledby={text} className={styles.tab} label={text} />)}
                </Tabs>
            </AppBar>

            <Switch>

                <Route path="/search/text/groceries" exact>
                    <>
                        {searchState.groceriesTextIsLoading === true && searchState.groceriesTextIsError === false && (
                            <div className="px-4 pt-4 text-center">
                                <CircularProgress size={15} disableShrink />
                            </div>
                        )}

                        {searchState.groceriesTextIsLoading === false && searchState.groceriesTextIsError === true && (
                            <MessageBox text="Failed to load your search" />
                        )}

                        {searchState.groceriesTextIsLoading === false && searchState.groceriesTextIsError === false && searchState.groceriesTextResult.length === 0 && searchText.length > 0 && !searchPending.current && (
                            <NotFound activeText="grocery" typing />
                        )}

                        {searchState.groceriesTextIsLoading === false && searchState.groceriesTextIsError === false && (
                            <AnimateElement duration={"0.5"}>
                            <List className={styles.list} disablePadding>
                                {searchState.groceriesTextResult.map((grocery, idx) => (
                                    <ListItem key={idx} className={styles.listItem} button onClick={() => navigateToGrocery(grocery.groceryId)} >
                                        <ListItemAvatar className={styles.listAvatar} >
                                            <Avatar alt="Search" src={searchImg} className={styles.avatarImg} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{ noWrap: true, className: styles.listText }}
                                            primary={
                                                <Highlighter
                                                    highlightTag="span"
                                                    highlightClassName={styles.listTextHighlight}
                                                    searchWords={[searchText.toLowerCase()]}
                                                    autoEscape={true}
                                                    textToHighlight={grocery?.description + " │ " + grocery?.name}
                                                />
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            </AnimateElement>
                        )}
                    </>
                </Route>

                <Route path="/search/text/recipes" exact>
                    <>
                        {searchState.recipesTextIsLoading === true && searchState.recipesTextIsError === false && (
                            <div className="px-4 pt-4 text-center">
                                <CircularProgress size={15} disableShrink />
                            </div>
                        )}

                        {searchState.recipesTextIsLoading === false && searchState.recipesTextIsError === true && (
                            <MessageBox text="Failed to load your search" />
                        )}

                        {searchState.recipesTextIsLoading === false && searchState.recipesTextIsError === false && searchState.recipesTextResult.length === 0 && searchText.length > 0 && !searchPending.current && (
                            <NotFound activeText="recipe" typing />
                        )}

                        {searchState.recipesTextIsLoading === false && searchState.recipesTextIsError === false && (
                            <AnimateElement duration={"0.5"}>
                            <List className={styles.list} disablePadding>
                                {searchState.recipesTextResult.map((recipe, idx) => (
                                    <ListItem key={idx} className={styles.listItem} button onClick={() => navigateToRecipe(recipe.recipeId)} >
                                        <ListItemAvatar className={styles.listAvatar} >
                                            <Avatar alt="Search" src={searchImg} className={styles.avatarImg} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{ noWrap: true, className: styles.listText }}
                                            primary={
                                                <Highlighter
                                                    highlightTag="span"
                                                    autoEscape={true}
                                                    highlightClassName={styles.listTextHighlight}
                                                    searchWords={[searchText.toLowerCase()]}
                                                    textToHighlight={recipe.title}
                                                />
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            </AnimateElement>
                        )}
                    </>
                </Route>

                <Route path="/search/text/articles" exact>
                    <>
                        {searchState.articlesTextIsLoading === true && searchState.articlesTextIsError === false && (
                            <div className="px-4 pt-4 text-center">
                                <CircularProgress size={15} disableShrink />
                            </div>
                        )}

                        {searchState.articlesTextIsLoading === false && searchState.articlesTextIsError === true && (
                            <MessageBox text="Failed to load your search" />
                        )}

                        {searchState.articlesTextIsLoading === false && searchState.articlesTextIsError === false && searchState.articlesTextResult.length === 0 && searchText.length > 0 && !searchPending.current && (
                            <NotFound activeText="Article" typing />
                        )}

                        {searchState.articlesTextIsLoading === false && searchState.articlesTextIsError === false && (
                            <AnimateElement duration={"0.5"}>
                            <List className={styles.list} disablePadding>
                                {searchState.articlesTextResult.map((article, idx) => (
                                    <ListItem key={idx} className={styles.listItem} button onClick={() => navigateToArticle(article.articleId)} >
                                        <ListItemAvatar className={styles.listAvatar} >
                                            <Avatar alt="Search" src={searchImg} className={styles.avatarImg} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{ noWrap: true, className: styles.listText }}
                                            primary={
                                                <Highlighter
                                                    highlightTag="span"
                                                    autoEscape={true}
                                                    highlightClassName={styles.listTextHighlight}
                                                    searchWords={[searchText.toLowerCase()]}
                                                    textToHighlight={article.title}
                                                />
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            </AnimateElement>
                        )}
                    </>
                </Route>

                <Redirect to="/search/text/groceries" />
            </Switch>





        </>
    )
}

export default TextSearch;
