import React, { useState, useMemo } from "react";

import styles from "./Card.module.scss";
import { getAppUserQuery } from "../../../../services/queries";
import { useParams, useHistory } from "react-router-dom";
import { CUISINE_COUNTRIES } from "../../../../constant";
import CircularProgress from "@material-ui/core/CircularProgress";
import MessageBox from "../../../../components/MessageBox/MessageBox";

export const CardFoodPreferences = ({ type}) => {
   const { userId } = useParams();
   const history = useHistory();
   const { data: user, isLoading } = getAppUserQuery(userId);

   const HOUSEHOLD_SIZE = "Household size";
   const WEEKLY_FOOD_BUDGET = "Weekly Food Budget";
   const COOKING_TIME = "Cooking time";
   const HEALTH_CONDITIONS = "Health Conditions";
   const ALLERGIES = "Allergies";
   const DISLIKES = "Dislikes";
   const FOOD_PRACTICE = "Food practice";
   const FAVORITE_CUISINES = "Favorite Cuisines";

   // intake form variables
   const CONDITIONS = "Suggested Conditions"
   const GENDER = "Gender";
   const AGE = "Age";
   const MEDICAL_HISTORY = "Medical History";
   const MEDICATIONS = "Medication & Supplements";
   const HEIGHT_WEIGHT = "Height and Weight";
   const SLEEP = "Sleep";
   const SMOKE = "Smoke";
   const ALCOHOL = "Alcohol";
   const PREVIOUS_DIET = "Previous Diet";
   const HEALTH_GOALS = "Health Goals";
   const ALLERGIES_INTAKE = "Allergies";
   const DISLIKED_FOODS = "Dislike food";
   const ACTIVITY = "Physical activities";
   const TYPICAL_DIET = "Typical diet"

   const FOOD_PREFERENCES = useMemo(() => {
      const foodPreferences = user?.foodPreference || [];
      const foodPreferencesData = [
         {
            title: HOUSEHOLD_SIZE,
            data: [{ Adults: foodPreferences.adults }, { Children: foodPreferences.children }, { Toddlers: foodPreferences.toddlers }],
         },
         { title: WEEKLY_FOOD_BUDGET, data: [foodPreferences.foodBudget] },
         { title: COOKING_TIME, data: [foodPreferences.cookingTime] },
         { title: HEALTH_CONDITIONS, data: foodPreferences.healthCondition },
         { title: ALLERGIES, data: foodPreferences.allergies },
         { title: DISLIKES, data: foodPreferences.dislikes },
         { title: FOOD_PRACTICE, data: [foodPreferences.foodPractice] },
         { title: FAVORITE_CUISINES, data: foodPreferences.cuisine },
      ];
      return foodPreferencesData;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user?.foodPreference]);

   const INTAKE_FORM = useMemo(() => {
      const intakeForm = user?.quizData || [];
      const conditions = intakeForm.conditions ? intakeForm.conditions.filter(condition => condition.checked).map(condition => condition.label) : []
      // const otherConditions = intakeForm.other_conditions ? intakeForm.other_conditions : ""
      console.log(intakeForm)
      const intakeFormData = [
         { title: CONDITIONS, data: [...conditions]},
         { title: GENDER, data: [intakeForm.gender] },
         { title: AGE, data: [intakeForm.age] },
         { title: MEDICAL_HISTORY, data: intakeForm.medical_history },
         { title: MEDICATIONS, data: [...(intakeForm.medications || ""), ...(intakeForm.supplements || "")] },
         { title: HEIGHT_WEIGHT, data: [intakeForm.height, intakeForm.weight] },
         { title: SLEEP, data: [intakeForm.sleep] },
         { title: SMOKE, data: [intakeForm.smoke] },
         { title: ALCOHOL, data: [intakeForm.alcohol] },
         { title: ALLERGIES_INTAKE, data: intakeForm.allergies },
         { title: PREVIOUS_DIET, data: intakeForm.previous_diet },
         { title: DISLIKED_FOODS, data: intakeForm.disliked_foods },
         { title: HEALTH_GOALS, data: intakeForm.health_goals },
         { title: ACTIVITY, data: [intakeForm?.activity] },
         { title: TYPICAL_DIET, data: [intakeForm?.typicalDiet] }
      ];
      return intakeFormData;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user?.quizData]);

   const convertFirstLettertoUpperCase = (text) => {
      if(text && text.length > 0){
         const formattedText = text.charAt(0).toUpperCase() + text.slice(1);
         return formattedText;
      } else return "N/A"   
   };

   const setCuisineData = (text, cuisine_country) => {
      const country = text.toUpperCase();
      const cuisineData = cuisine_country.map((item) => {
         if (country === item.value) {
            return (
               <div className={styles.cuisineContainer} key={item.value}>
                  <img src={item.flag} alt={item.title} />
                  <p className={styles.p}>{item.value}</p>
               </div>
            );
         }
      });
      return cuisineData;
   };

   const setHouseHoldData = (data) => {
      const result = Object.keys(data).map((key, index) => {
         return `${key}: ${data[key]}`;
      });
      return result;
   };

   const formatData = (preference, data) => {
      let mutatedData;
      switch (preference) {
         case HEALTH_CONDITIONS:
         case ALLERGIES:
         case DISLIKES:
         case FOOD_PRACTICE:
         case PREVIOUS_DIET:
         case HEALTH_GOALS:
         case ALLERGIES_INTAKE:
         case MEDICATIONS:
         case MEDICAL_HISTORY:
         case GENDER:
         case DISLIKED_FOODS:
         case ACTIVITY:
         case TYPICAL_DIET:
            mutatedData = convertFirstLettertoUpperCase(data);
            break;
         case WEEKLY_FOOD_BUDGET:
         case COOKING_TIME:
         case AGE:
         case SLEEP:
         case SMOKE:
         case ALCOHOL:
         case HEIGHT_WEIGHT:
         case CONDITIONS:
            mutatedData = data;
            break;
         case HOUSEHOLD_SIZE:
            mutatedData = setHouseHoldData(data);
            break;
         case FAVORITE_CUISINES:
            mutatedData = setCuisineData(data, CUISINE_COUNTRIES);
            break;
         default:
            mutatedData = "N/A";
      }
      return mutatedData;
   };

   const handleEdit = (editing, stage) => {
      type === "foodPreference" && history.push(`/onboarding/${editing}/${stage}`) ;
      type === "intakeForm" && history.push(`/track/${editing}/${stage}`);
   };

   const DATASET =
      type === "foodPreference" && user?.foodPreference !== undefined
         ? FOOD_PREFERENCES
         : type === "intakeForm" && user?.quizData !== undefined
         ? INTAKE_FORM
         : [];

   return (
      <>
         {!isLoading && DATASET.length === 0 && <MessageBox text='No Data added yet' />}
         {isLoading && (
            <div className='px-4 py-4 text-center'>
               <CircularProgress size={15} disableShrink />
            </div>
         )}
         {isLoading === false &&
            DATASET.map((item, index) => {
               return (
                  <div className={styles.card} key={item.title + index.toString()}>
                     <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>
                           <p className={styles.cardNo}>
                              {index + 1} / {DATASET.length}
                           </p>
                           <span className={styles.cardText}>{item.title}</span>
                        </div>
                        <div className={styles.cardEdit}>
                           <span className={styles.cardEditText} onClick={() => handleEdit("isEditing", index + 1)}>
                              Edit
                           </span>
                        </div>
                     </div>
                     <div className={styles.cardBody}>
                        { item.data && item.data.length > 0 ? (
                           item.data.map((data, index) => {
                              return (
                                 <div className={styles.cardTags} key={index.toString()}>
                                    <span className={styles.cardTagText}>{formatData(item.title, data)}</span>
                                 </div>
                              );
                           })
                        ) : (
                           <div className={styles.cardTags}>
                              <span className={styles.cardTagText}>N/A</span>
                           </div>
                        )}
                     </div>
                  </div>
               );
            })}
      </>
   );
};
