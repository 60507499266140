import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import styles from "./Calendar.module.scss";
import droplet from "../../assets/droplet-icon.png";
import scale from "../../assets/scale.svg";
import symptom from "../../assets/symptoms.svg";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { openEvents, setUser, selectedEvent } from "../../state";
import EditDateTimeModal from "./EditDateTimeModal/EditDateTimeModal";
import { useParams } from "react-router";
import { getUserData } from "../../services/firebase";

export const Events = ({ startHour, selectDate }) => {
  const [showEditDateModal, setshowEditDateModal] = useState(false);
  const [selectedFoodEvent, setSelectedFoodEvent] = useState({});
  let lastUpdateRef = useRef();
  const userState = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const currentHour = new Date().getHours();
  const [eventsData, setEventsData] = useState([]);

  const { userId } = useParams();

  const getFoodType = (time) => {
    if (time >= 0 && time <= 11) {
      return "Breakfast";
    } else if (time >= 12 && time <= 17) {
      return "Lunch";
    } else if (time >= 18 && time <= 23) {
      return "Dinner";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      //Scroll active time to top
      lastUpdateRef.current && lastUpdateRef.current.scrollIntoView();
      localStorage.removeItem("lastEventAddedDate");
    }, 1500);
  }, [userState]);

  useEffect(() => {
    if (userId) {
      const getUser = async () => {
        try {
          const getData = await getUserData(userId);
          setEventsData(getData?.events);
        } catch (err) {
          console.log(err);
        }
      };
      getUser();
    } else {
      setEventsData(userState?.user?.events);
    }
  }, [userState.user.events, userId]);

  const openEvent = (eventType) => {
    if (
      eventType === "Breakfast" ||
      eventType === "Lunch" ||
      eventType === "Dinner" ||
      eventType === "Snack"
    ) {
      dispatch(
        openEvents({
          currentEventType: eventType,
          showModal: true,
          isModalOpen: false,
          showWeightModal: false,
          showSymptomsModal: false,
        })
      );
    } else if (eventType === "Weight") {
      dispatch(
        openEvents({
          currentEventType: eventType,
          showModal: false,
          isModalOpen: false,
          showWeightModal: true,
          showSymptomsModal: false,
        })
      );
    } else if (eventType === "Symptoms") {
      dispatch(
        openEvents({
          currentEventType: eventType,
          showModal: false,
          isModalOpen: false,
          showWeightModal: false,
          showSymptomsModal: true,
        })
      );
    }
  };

  const handleEditEvent = (event) => {
    setSelectedFoodEvent(event);
    setshowEditDateModal(true);
  };

  const handleEditWeightEvent = (event) => {
    dispatch(selectedEvent({ ...event }));
    openEvent("Weight");
  };

  const handleEditSymptomsEvent = (event) => {
    dispatch(selectedEvent({ ...event }));
    openEvent("Symptoms");
  };

  const userEvents = useMemo(() => {
    if (eventsData) {
      const events =
        eventsData.filter(
          (event) => event.id === selectDate.format("ddd D MMM")
        ) || [];
      return events;
    }
  }, [eventsData, selectDate]);

  const foodEvents = [];

  if (userEvents) {
    for (let i = 0; i < userEvents.length; i++) {
      if (userEvents[i]?.food) {
        // foodEvents.push(userEvents[i].food.items)
        for (let j = 0; j < userEvents[i].food.items.length; j++) {
          foodEvents.push(userEvents[i].food.items[j].Item.nutrients);
        }
      }
    }
  }

  const isWhatPercentOf = (x, y) => {
    return Math.round((x / y) * 100);
  };

  const reduceFat = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + +object.fat;
    }, 0);
  };
  const reduceProt = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + +object.proteins;
    }, 0);
  };
  const reduceCab = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + +object.carbohydrates;
    }, 0);
  };

  const reduceCal = (data) => {
    return data.reduce((accumulator, object) => {
      return accumulator + object["energy-kcal"];
    }, 0);
  };

  return (
    <>
      <div className={styles.events}>
        {userEvents &&
          userEvents.length > 0 &&
          userEvents.map((event, i) => {
            const ref =
              event.dateCreated == localStorage.getItem("lastEventAddedDate")
                ? lastUpdateRef
                : null;
            return (
              <div key={i} ref={ref}>
                {event.food?.hour == startHour && (
                  <div
                    className={styles.containerEvents}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditEvent(event);
                    }}
                  >
                    <div className={styles.header}>
                      <p className={styles.time}>{event.food.time}</p>
                      <p className={styles.tag}>
                        {event.tag === "Snack"
                          ? event.tag
                          : getFoodType(Number(event.food?.hour))}
                      </p>
                    </div>
                    {event.food.items.map((content, i) => (
                      <p className={styles.content} key={i}>
                        {content.Item.name}
                      </p>
                    ))}
                  </div>
                )}

                {event.recipe?.hour == startHour && (
                  <div className={styles.recipe}>
                    <div className={styles.recipeHeaderContainer}>
                      <span className={styles.recipeHeaderTime}>
                        {event.recipe.time}
                      </span>
                      <span className={styles.recipeHeaderPlantext}>
                        Recipe Planned
                      </span>
                    </div>
                    <div className={styles.recipeContent}>
                      <div
                        className={styles.recipeImage}
                        style={{
                          backgroundImage: `url(${event.recipe.thumbnail})`,
                        }}
                      />
                      <div className={styles.recipeTitle}>
                        <span className={styles.recipeTitleHeader}>Recipe</span>{" "}
                        {event.recipe.title}
                      </div>
                    </div>
                  </div>
                )}

                {event.water?.hour == startHour && (
                  <div className={styles.water}>
                    <img src={droplet} alt="droplet" />
                    <p className={styles.litre}>{event.water.quantity}</p>
                  </div>
                )}

                {event.weight?.hour == startHour && (
                  <div
                    className={styles.weight}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditWeightEvent(event);
                    }}
                  >
                    <img src={scale} alt="scale" />
                    <p className={styles.kg}>{event.weight.size}</p>
                  </div>
                )}

                {event.symptoms?.hour == startHour && (
                  <div
                    className={styles.symptoms}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditSymptomsEvent(event);
                    }}
                  >
                    <img src={symptom} alt="scale" />
                    {event.symptoms?.symptoms?.map((condition) => (
                      <span className={styles.condition}>
                        {condition + ","}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        {userEvents && userEvents.length === 0 && (
          <>
            {!userId && (
              <>
                {startHour == 7 && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      openEvent("Breakfast");
                    }}
                    className={`${styles.containerNoEvents} ${
                      currentHour === 7 ? styles.bgActive : styles.bgInactive
                    }`}
                  >
                    <p className={styles.txt}>Add Breakfast</p>
                  </div>
                )}

                {startHour == 12 && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      openEvent("Lunch");
                    }}
                    className={`${styles.containerNoEvents} ${
                      currentHour === 12 ? styles.bgActive : styles.bgInactive
                    }`}
                  >
                    <p className={styles.txt}>Add Lunch</p>
                  </div>
                )}

                {startHour == 19 && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      openEvent("Dinner");
                    }}
                    className={`${styles.containerNoEvents} ${
                      currentHour === 19 ? styles.bgActive : styles.bgInactive
                    }`}
                  >
                    <p className={styles.txt}>Add Dinner</p>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <EditDateTimeModal
        open={showEditDateModal}
        selectedEvent={selectedFoodEvent}
        onClose={() => setshowEditDateModal(false)}
      />
    </>
  );
};
