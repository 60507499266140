import React, { useState, useMemo, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { format, addDays } from "date-fns";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import DoneIcon from "@material-ui/icons/Done";

import backImg from "./../../../../assets/icons/back.png";
import thumbUpImg from "./../../../../assets/thumb-up.png";
import points from "./../../../../assets/points.svg";
import sendIcon from "./../../../../assets/sendIcon.svg";
import doneIcon from "./../../../../assets/doneIcon.svg";

import { toast, clearCart, updateUserField } from "./../../../../state";
import {
  addShopTransactions,
  addUsedPromoCode,
} from "./../../../../services/firebase";
import { sendSMS, sendMail } from "./../../../../services/twilio";
import {
  ADDRESS,
  CONGRATULATIONS,
  getTotalCartPrice,
  getTotalCartDiscount,
} from "./../../Cart";
import styles from "./Confirm.module.scss";

import { calculatePriceAfterPromoCode } from "../../../../utilities";

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: "rgb(30,32,35, 1)",
      color: "#1e2023",
      fontWeight: "normal",
      fontFamily: "Epilogue, Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "rgb(30,32,35, 0.5)",
      },
      ":focus": {
        fontWeight: 600,
      },
    },
    invalid: {
      iconColor: "#ff0000",
      color: "#ff1919",
    },
  },
};

const Confirm = (props) => {
  const {
    setDisplay,
    cartState,
    userState,
    address,
    whenDate,
    promoCodesState,
  } = props;

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isDiscountCodeValid, setDiscountCodeValid] = useState(true);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountSuccess, setDiscountSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let price = useMemo(() => cartState.cart.reduce(getTotalCartPrice, 0), []);
  const user = userState.user;
  const usedUserPromoCodes =
    userState.userUsedPromoCode?.length !== 0
      ? userState.userUsedPromoCode[0].usedPromoCodes
      : [];
  const validDiscountCodes = promoCodesState.promoCodes;
  const freeDeliveryAmount = cartState?.freeDeliveryAmount;
  const cart = cartState.cart;
  const isDeliveredByStove = cart.every(
    (cartItem) => cartItem.deliveryBy === "Stove"
  );

  const [totalPrice, setTotalPrice] = useState(price);

  const isfreeDelivery =
    freeDeliveryAmount > 0 &&
    Number(price) >= freeDeliveryAmount &&
    isDeliveredByStove
      ? "Yes"
      : "No";

  const goToBack = () => {
    if (processing) {
      dispatch(toast({ message: "Please wait while we process your request" }));
      return;
    }
    setDisplay((display) => ({ ...display, header: false, active: ADDRESS }));
  };

  const goToCongratulations = () => {
    setDisplay((display) => ({
      ...display,
      header: false,
      active: CONGRATULATIONS,
    }));
  };

  const confirmButtonClick = async (price) => {
    if (!address) {
      dispatch(toast({ message: "Enter shipping address" }));
      return;
    }
    setError(null);
    setProcessing(true);
    try {
      await addShopTransactions(
        "",
        user.uid,
        user.email,
        address,
        price,
        cartState.cart,
        0,
        new Date(addDays(new Date(), whenDate)).getTime(),
        isfreeDelivery
      );
      const rewards = cartState.cart.filter((cartItem) =>
        Boolean(cartItem.rewardId)
      );
      if (rewards.length > 0) {
        const totalPoints = rewards.reduce((accumulator, cartItem) => {
          return accumulator + cartItem.points;
        }, 0);
        dispatch(
          updateUserField({
            field: "points",
            data: user.points - totalPoints,
          })
        );
      }
      try {
        sendSMS(
          `(New transaction order) Name: ${user.displayName}, Email: ${user.email}, Price: £${price}`
        );
        sendMail(
          "info@stovecook.com",
          "New transaction order",
          `<div>
                               <p><strong>Name: </strong> ${user.displayName}</p>
                               <p><strong>Email: </strong><a href="mailto:${user.email}">${user.email}</a></p>
                               <p><strong>Price: </strong> £${price}</p>
                          </div>`
        );
      } catch {}
      setProcessing(false);
      dispatch(clearCart());
      goToCongratulations();
    } catch (error) {
      setError(error);
      setProcessing(false);
    }
  };

  const confirmAndPayButtonClick = async (price) => {
    if (!stripe || !elements) {
      return;
    }
    if (!address) {
      dispatch(toast({ message: "Enter shipping address" }));
      return;
    }
    setError(null);
    setProcessing(true);

    try {
      const cardElement = elements.getElement(CardElement);
      const response = await axios.post(
        `https://us-central1-stove-e851c.cloudfunctions.net/stripePayment${
          window.location.hostname.includes("app.stovecook") ? "Prod" : "Dev"
        }`,
        {
          amount: Number(price) * 100,
        }
      );
      let paymentIntent = response.data;
      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          receipt_email: user.email,
          payment_method: {
            card: cardElement,
            billing_details: {
              name: user.displayName,
              email: user.email,
            },
          },
        }
      );
      if (result.error) {
        setError(result.error);
        setProcessing(false);
      } else {
        if (
          result.paymentIntent &&
          result.paymentIntent.status === "succeeded"
        ) {
          await addShopTransactions(
            result.paymentIntent.id,
            user.uid,
            user.email,
            address,
            price,
            cartState.cart,
            0,
            new Date(addDays(new Date(), whenDate)).getTime(),
            isfreeDelivery
          );
          const rewards = cartState.cart.filter((cartItem) =>
            Boolean(cartItem.rewardId)
          );
          if (rewards.length > 0) {
            const totalPoints = rewards.reduce((accumulator, cartItem) => {
              return accumulator + cartItem.points;
            }, 0);
            dispatch(
              updateUserField({
                field: "points",
                data: user.points - totalPoints,
              })
            );
          }
          try {
            sendSMS(
              `(New transaction order) Name: ${user.displayName}, Email: ${user.email}, Price: £${price}`
            );
            sendMail(
              "info@stovecook.com",
              "New Transaction Order",
              ReactDOMServer.renderToString(
                <EmailVerification
                  time={format(new Date(), "do MMMM yyyy 'at' h:mm aaa")}
                  name={user.displayName}
                  email={user.email}
                  address={address}
                  price={price}
                  cart={cartState.cart}
                />
              )
            );
          } catch {}
          isDiscountCodeValid &&
            !checkIfCodeisUsed(promoCode) &&
            (await addUsedPromoCode(usedUserPromoCodes, promoCode, user.uid));
          setProcessing(false);
          dispatch(clearCart());
          goToCongratulations();
        }
      }
    } catch (error) {
      setError(error);
      setProcessing(false);
    }
  };

  const onEnterPromoCode = (e) => {
    const value = e.target.value;
    setPromoCode(value);
  };

  const checkIfCodeisUsed = (newCode) => {
    if (usedUserPromoCodes?.length > 0) {
      return usedUserPromoCodes?.includes(newCode.toUpperCase());
    }
  };

  const applyPromoCode = () => {
    if (checkIfCodeisUsed(promoCode)) {
      dispatch(toast({ message: "discount code already used" }));
    } else {
      setDiscountAmount(
        promoCode === ""
          ? 0
          : calculatePriceAfterPromoCode(promoCode, validDiscountCodes, price)
      );
      setDiscountCodeValid(
        validDiscountCodes.some((e) => e.code === promoCode.toUpperCase())
      );
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (
      isDiscountCodeValid &&
      discountAmount &&
      !checkIfCodeisUsed(promoCode)
    ) {
      setTotalPrice(discountAmount);
      dispatch(toast({ message: "discount code applied successfully" }));
      setDiscountSuccess(true);
    }
  }, [discountAmount, isDiscountCodeValid]);

  useEffect(() => {
    if (!isDiscountCodeValid) {
      setDiscountSuccess(false);
      setTimeout(() => {
        setIsVisible(false);
      }, 4000);
    }
  }, [isVisible, isDiscountCodeValid]);

  return (
    <div className={styles.homePage}>
      <div className={styles.header}>
        <IconButton size="small" onClick={goToBack}>
          <Avatar
            alt="search"
            sizes=""
            variant="square"
            src={backImg}
            className={styles.backIcon}
          />
        </IconButton>
        <span className={styles.headerText}>Confirm order</span>
        <span className={styles.stepText}>
          <span className={styles.stepTextRed}>2</span>/2
        </span>
      </div>

      {user && (
        <div className="px-4 pt-4 pb-2">
          <div className={styles.shippingDetailsContainer}>
            <div className={styles.shippingDetails}>Shipping information</div>
            <div className={styles.nameText}>{user.displayName}</div>
            <div className={styles.addressText}>{address}</div>
            <div className={styles.line} />
            <div className={styles.whentext}>
              {whenDate === 1
                ? `Tomorrow, ${format(
                    addDays(new Date(), whenDate),
                    "do MMMM yyyy"
                  )}`
                : format(addDays(new Date(), whenDate), "EEE, do MMMM yyyy")}
            </div>
          </div>
        </div>
      )}

      {price === 0 && (
        <>
          {error && <div className={styles.errorText}>{error.message}</div>}
          {cartState.cart.length > 0 && (
            <>
              <div className={styles.subtotalContainer}>
                <span className={styles.totalText}>Subtotal</span>
                <span className={styles.totalPriceText}>£0.00</span>
              </div>
              <div className={styles.shippingContainer}>
                <span className={styles.totalText}>Shipping</span>
                <span className={styles.totalPriceText}>free</span>
              </div>
              <div className={styles.totalContainer}>
                <span className={styles.totalText}>Total</span>
                <span className={styles.totalPriceText}>£0.00</span>
              </div>
            </>
          )}
          {cartState.cart.length > 0 && user && user.uid && (
            <ButtonBase
              onClick={() => confirmButtonClick(price)}
              disabled={processing}
              className={styles.confirmButton}
            >
              {processing ? (
                <CircularProgress color="inherit" size="24px" />
              ) : (
                `Confirm`
              )}
            </ButtonBase>
          )}
        </>
      )}

      {price > 0 && (
        <>
          <div className="p-4">
            <div className={styles.paymentContainer}>
              <div className={styles.paymentText}>Payment</div>
              <div>
                <CardElement
                  options={CARD_OPTIONS}
                  onChange={(event) => {
                    setError(event.error);
                    setCardComplete(event.complete);
                  }}
                />
              </div>
            </div>
          </div>
          {error && <div className={styles.errorText}>{error.message}</div>}

          <div className={`${styles.promotion} p-4`}>
            <TextField
              variant="standard"
              placeholder="Apply promo code"
              value={promoCode}
              defaultValue="Normal"
              className={styles.input}
              onChange={onEnterPromoCode}
              margin="none"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment onClick={applyPromoCode} position="end">
                    {discountSuccess ? (
                      <img src={doneIcon} alt="" />
                    ) : (
                      <img src={sendIcon} alt="" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {!isDiscountCodeValid && isVisible && (
            <div className={styles.errorText}>
              The Discount code you applied is invalid. Please try again.
            </div>
          )}

          {discountSuccess && (
            <div className={styles.errorText}>
              Congratulations, a discount of £
              {Number((price - discountAmount).toFixed(2))} has been applied!
            </div>
          )}

          {cartState.cart.length > 0 && (
            <>
              <div className={styles.subtotalContainer}>
                <span className={styles.totalText}>Subtotal</span>
                <span className={styles.totalPriceText}>£{price}</span>
              </div>
              <div className={styles.shippingContainer}>
                <span className={styles.totalText}>Shipping</span>
                {freeDeliveryAmount > 0 &&
                Number(price) >= freeDeliveryAmount &&
                isDeliveredByStove ? (
                  <span className={styles.totalPriceText}>
                    <s>{price}</s>
                  </span>
                ) : (
                  <span className={styles.totalPriceText}>Free</span>
                )}
              </div>
              <div className={styles.totalContainer}>
                <span className={styles.totalText}>Total</span>
                <span className={styles.totalPriceText}>£{totalPrice}</span>
              </div>

              <div className="px-4 pt-4">
                <div className={styles.saveContainer}>
                  <div className="d-flex mb-2">
                    <span className="mr-auto">Money saved</span>
                    <span>
                      -£{cartState.cart.reduce(getTotalCartDiscount, 0)}
                    </span>
                  </div>
                  <div className="d-flex">
                    <span className={styles.saveText}>
                      Through cumulative discounts you saved this amount of
                      money. Keep it up!
                    </span>
                    <Avatar
                      alt="Thumb"
                      src={thumbUpImg}
                      variant="square"
                      className={styles.thumb}
                    />
                  </div>
                </div>
              </div>

              <div className="px-4">
                <div className={`${styles.points} `}>
                  <div className={styles.pointsIcon}>
                    <img src={points} alt="points-icon" />
                    <span className={styles.text}>Pontential points</span>
                  </div>
                  <span className={styles.text}>{user && user.points}</span>
                </div>
              </div>
            </>
          )}
          {cartState.cart.length > 0 && user && user.uid && (
            <ButtonBase
              onClick={() => confirmAndPayButtonClick(totalPrice)}
              disabled={!stripe || !elements || !cardComplete || processing}
              className={styles.confirmButton}
            >
              {processing ? (
                <CircularProgress color="inherit" size="24px" />
              ) : (
                `Confirm & Pay  £${totalPrice}`
              )}
            </ButtonBase>
          )}
        </>
      )}
    </div>
  );
};

export default Confirm;

const EmailVerification = ({ time, name, email, address, price, cart }) => {
  const mainContainerStyle = {
    maxWidth: "100%",
    backgroundColor: "#fcefe3",
    padding: "48px 24px",
  };

  const stoveLogoContainerStyle = {
    textAlign: "center",
  };

  const stoveLogoStyle = {
    width: "50px",
    height: "auto",
    borderRadius: "8px",
  };

  const header = {
    padding: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "15px",
    color: "#130f26",
    textTransform: "capitalize",
    textAlign: "center",
  };

  const title = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "15px",
    color: "#130f26",
    textTransform: "capitalize",
  };

  const description = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "160%",
    color: "#130F26",
    textTransform: "capitalize",
  };

  const tableContainerStyle = {
    padding: "5px 10px",
    width: "100%",
    overflow: "auto",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
  };

  const table = {
    border: "2px solid #130f26",
    borderRadius: "8px",
    minWidth: "100%",
    borderCollapse: "collapse",
  };

  const tableHeaderCell = {
    borderRight: "2px solid #130f26",
    padding: "5px 10px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "15px",
    color: "#130f26",
    textTransform: "capitalize",
    textAlign: "center",
  };

  const tableBodyCell = {
    border: "2px solid #130f26",
    padding: "5px 10px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "160%",
    color: "#130F26",
    textTransform: "capitalize",
  };

  return (
    <div style={mainContainerStyle}>
      <div style={stoveLogoContainerStyle}>
        <img
          style={stoveLogoStyle}
          src="https://app.stovecook.com/stove-512x512.png"
          alt="stove"
        />
      </div>
      <p style={header}>
        <span>New Order</span>
      </p>

      <p>
        <div style={title}>Time of order:</div>
        <div style={{ ...description, textTransform: "none" }}>{time}</div>
      </p>
      <p>
        <div style={title}>Customer name:</div>
        <div style={description}>{name}</div>
      </p>
      <p>
        <div style={title}>Customer email:</div>
        <div style={{ ...description, textTransform: "none" }}>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </p>
      <p>
        <div style={title}>Delivery address:</div>
        <div style={description}>{address}</div>
      </p>
      <p>
        <div style={title}>Total Amount Paid:</div>
        <div style={description}>£{Number(price).toFixed(2)}</div>
      </p>
      <div style={tableContainerStyle}>
        <table layout="auto" style={table}>
          <thead>
            <tr>
              <td style={tableHeaderCell}>Image</td>
              <td style={tableHeaderCell}>Name</td>
              <td style={tableHeaderCell}>Description</td>
              <td style={tableHeaderCell}>Size</td>
              <td style={tableHeaderCell}>Unit Purchased</td>
              <td style={tableHeaderCell}>Unit Price (£)</td>
              <td style={tableHeaderCell}>Product Link</td>
            </tr>
          </thead>
          <tbody>
            {cart.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ ...tableBodyCell, textAlign: "center" }}>
                    <img
                      style={stoveLogoStyle}
                      src={item.thumbnail}
                      alt={item.name}
                    />
                  </td>
                  <td style={tableBodyCell}>{item.name}</td>
                  <td style={tableBodyCell}>{item.description}</td>
                  <td style={tableBodyCell}>{item.size}</td>
                  <td style={{ ...tableBodyCell, textAlign: "right" }}>
                    {item.quantity}
                  </td>
                  <td style={{ ...tableBodyCell, textAlign: "right" }}>
                    £{Number(item.price).toFixed(2)}
                  </td>
                  <td style={{ ...tableBodyCell, textAlign: "center" }}>
                    <a
                      href={`https://app.stovecook.com/shop/${item.groceryId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Item
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
