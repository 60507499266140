import React, { useState } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TextSearch from "./components/TextSearch/TextSearch";
import TagSearch from "./components/TagSearch/TagSearch";



//Tabs
const GROCERIES = "Groceries";
const RECIPES = "Recipes";
const ARTICLES = "Articles";

const tabTexts = [
    GROCERIES,
    RECIPES,
    ARTICLES
]


const Search = () => {
    const { path, url } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${path}/text/:tab`} exact>
                    <TextSearch tabTexts={tabTexts} />
                </Route>
                <Route path={`${path}/tag/:tab/:label?`} exact>
                    <TagSearch tabTexts={tabTexts} />
                </Route>
                <Redirect to={`${path}/text/groceries`} />
            </Switch>

            {/* <Layout header={display.header}>
                {display.active === HOME && (
                    <div className={styles.homePage}>
                        <div className={styles.header}>
                            <div className={styles.searchContainer}>
                                <IconButton onClick={() => setDisplay((display) => ({ ...display, header: false, active: TYPING_RESULT }))}>
                                    <Avatar alt="search" sizes="" variant="square" src={searchIcon} className={styles.searchIcon} />
                                </IconButton>
                                <InputBase
                                    onFocus={() => setDisplay((display) => ({ ...display, header: false, active: TYPING_RESULT }))}
                                    className={styles.searchInput}
                                    placeholder="What are you looking for?"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                        </div>

                        <AppBar position="sticky" color="default" className={styles.appBar}>
                            <Tabs
                                value={display.tab}
                                onChange={handleTabChange}
                                aria-label="Search tabs"
                                indicatorColor="primary"
                                textColor="primary"
                                scrollButtons="off"
                                variant="fullWidth"
                            >
                                {tabTexts.map((text, idx) => <Tab key={idx} value={text} aria-labelledby={text} className={styles.tab} label={text} />)}
                            </Tabs>
                        </AppBar>

                        {display.tab === RECIPES && <RecipesTab />}
                        {display.tab === GROCERIES && <GroceriesTab />}
                        <ButtonBase
                            className={styles.searchButton}
                            disabled={
                                (display.tab === RECIPES && searchState.recipesTag.length === 0) ||
                                (display.tab === GROCERIES && (searchState.groceriesTag.length === 0 && searchState.specialOfferSearch === false))
                            }
                            onClick={() => setDisplay((display) => ({ ...display, header: false, active: TAG_RESULT }))}
                        >Search</ButtonBase>
                    </div>
                )}

                {display.active === TAG_RESULT && (
                    <>
                        {display.tab === RECIPES && <RecipesTagSearchResult setDisplay={setDisplay} />}
                        {display.tab === GROCERIES && <GroceriesTagSearchResult setDisplay={setDisplay} />}
                    </>
                )}
            </Layout> */}
        </>
    )
}

export default Search;