import React, { useState, useEffect } from "react";
import styles from "./ToggleButton.module.scss";

const ToggleButton = ({ toggle, handleToggle }) => {   
   return (
      <div className={toggle ? styles.switchOn : styles.switchOff} onClick={handleToggle}>
         <div className={styles.toggle}>
            <button className={styles.toggleHandle}></button>
         </div>
      </div>
   );
};

export default ToggleButton;
