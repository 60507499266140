import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from "../../../../state";

import styles from "./../SignIn.module.scss";


const validationPasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, "Minimum password characters is 8").max(20, "Maximum password characters is 20").matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+=\-[\]{}|\\:;'",.<>/?]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number").required("Password is required"),
    confirmPassword: Yup.string().min(8, "Minimum password characters is 8").max(20, "Maximum password characters is 20").matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+=\-[\]{}|\\:;'",.<>/?]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number").required("Confirm is required")
});

const ResetFinish = (props) => {
    const { email, setEmail, setSignInStep, handleUpdateUserWithEmailAndPassword } = props;
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!email || !sessionStorage.getItem("resetToken")) {
            setSignInStep("main");
        }
    }, [])




    const handleClickShowPassword = () => {
        setShowPassword(showPassword => !showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onChangeEmail = useCallback(() => {
        setEmail("");
        setSignInStep("main");
        sessionStorage.clear();
    }, [])



    return (
        <Formik
            validationSchema={validationPasswordSchema}
            initialValues={{
                password: "",
                confirmPassword: ""
            }}
            onSubmit={async (values, FormikBag) => {
                if (values.password !== values.confirmPassword) {
                    dispatch(toast({ message: "Password does not match!!!" }));
                    return
                }
                await handleUpdateUserWithEmailAndPassword(email, values.password);
                FormikBag.setSubmitting(false);
            }}

        >
            {formik => (
                <div className={styles.emailPageContent}>

                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        className={styles.textField}
                        inputProps={{ autoComplete: "new-password" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...formik.getFieldProps('password')}
                        error={Boolean(formik.touched.password && formik.errors.password)}
                        helperText={Boolean(formik.touched.password && formik.errors.password) ? formik.errors.password : "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"}
                        disabled={formik.isSubmitting}
                    />


                    <TextField
                        fullWidth
                        label="Confirm password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        className={styles.textField}
                        inputProps={{ autoComplete: "new-password" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...formik.getFieldProps("confirmPassword")}
                        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                        helperText={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword) ? formik.errors.confirmPassword : "Minimum eight characters, at least one uppercase letter, one lowercase letter one number and one special character"}
                        disabled={formik.isSubmitting}
                    />
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={formik.submitForm} disabled={formik.isSubmitting}>Register</Button>
                    <Button disableElevation fullWidth variant="contained" color="primary" className={styles.button} onClick={onChangeEmail} disabled={formik.isSubmitting} >Restart</Button>
                </div>
            )}
        </Formik>
    )
}

export default ResetFinish;
