import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import GroceryWidgetList from "./../../components/GroceryWidgetList/GroceryWidgetList";

import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import styles from "./SuggestedGroceries.module.scss";

import constant from "./../../constant";
import AnimateElement from "../../components/AnimateElement";

const shopCategories = constant.shopCategories.options;
const tabs = ["Popular", ...shopCategories]






const SuggestedGrocery = () => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState("Popular");
    const groceriesState = useSelector(state => state.groceries, shallowEqual);


    const handleTabChange = useCallback((event, value) => {
        setActiveTab(value);
    }, [])

    const groceries = activeTab === "Popular" ? groceriesState.groceries : groceriesState.groceries.filter(grocery => grocery.shopCategory.includes(activeTab.toLowerCase()))

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <IconButton size="small" onClick={() => history.goBack()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.00065 12.3379L20 12.3379" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.3691 19.6759L3.99988 12.3379L11.3691 5" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </IconButton>
                <span className={styles.headerText}>Best Deals Groceries</span>
                <IconButton size="small">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M17.5 18L21.5423 22" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <circle cx="11.5" cy="11.5" r="8.5" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </IconButton>
            </div>

            <AppBar position="sticky" color="default" className={styles.appBar}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="Groceries tabs"
                    classes={{ indicator: styles.indicator }}
                    TabIndicatorProps={{ children: <span /> }}
                >
                    {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === false && tabs.map((category, idx) => <Tab key={idx} aria-labelledby={category} className={styles.tab} classes={{ selected: styles.tabSelected }} label={category} value={category} />)}
                </Tabs>
            </AppBar>

            {groceriesState.groceriesIsLoading === true && groceriesState.groceriesIsError === false && (
                <div className="p-4">
                    <Spinner variant="rect" height={140} />
                    <Spinner variant="text" height={40} />
                    <Spinner variant="text" height={40} />
                </div>
            )}

            {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === true && (
                <MessageBox text="Failed to load shop items" />
            )}

            {groceriesState.groceriesIsLoading === false && groceriesState.groceriesIsError === false && (
                <>
                    {groceries.length === 0 ? <MessageBox text="No shop items matches your selected filter" /> :  <AnimateElement duration={"0.5"}><GroceryWidgetList groceries={groceries} footer="grocery" /> </AnimateElement>}
                </>
            )}

        </div>
    )
}

export default SuggestedGrocery;