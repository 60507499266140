import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles((theme) => ({
   root: {
      "& .MuiFilledInput-root": {
         background: "rgba(19, 15, 38, 0.04)",
         borderRadius: 16,
      },
      "& .MuiFormControl-root": {
         borderRadius: 16,
      },
      "& .MuiFilledInput-multiline": {
         padding: 16,
      },
      "& .MuiInputBase-root": {
         color: "#130F26",
         lineHeight: 1.5,
         fontSize: 14,
         fontWeight: 400,
      },
   },
}))(TextField);

const CustomTextArea = (props) => {
   return (
      <div>
         <CustomTextField
            InputProps={{
               disableUnderline: true,
            }}
            fullWidth
            variant='filled'
            multiline
            rows={4}
            {...props}
         />
      </div>
   );
};

export default CustomTextArea;
