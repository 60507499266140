import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import login from "./../../assets/login.svg"
import styles from "./Header.module.scss";

import { setUnReadMessageCount } from '../../state'

const Header = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const userState = useSelector(state => state.user, shallowEqual);
    const cartState = useSelector(state => state.cart, shallowEqual);
    const notificationState = useSelector(state => state.notification, shallowEqual)
    const { unReadIndividualNotifications, unReadGroupMessages, notifications, unReadMessages } = useSelector((state) => state.notification, shallowEqual);
    const isDietitian = userState?.user?.isDietitian && userState?.accountType === "dietitian";

    const moveTo = (path) => history.push(path);

    useEffect(() => {
        const unread = unReadIndividualNotifications.length + unReadGroupMessages.length
        dispatch(setUnReadMessageCount(unread))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unReadIndividualNotifications, unReadGroupMessages])

    return (
        <div className={styles.header}>
            <div>
                {(userState.user && userState.user.uid) ? (
                    <IconButton className="p-0" onClick={() => moveTo("/account")}>
                        <Avatar className={styles.profileImage} src={userState.user.photoURL}>
                            <PersonIcon />
                        </Avatar>
                    </IconButton>
                ) : (
                    <div className={styles.loginContainer}>
                        <img src={login} alt="login" />
                        <Button onClick={() => moveTo("/login")} variant="contained" className={styles.loginButton}>Login</Button>
                    </div>
                )}
            </div>
            <div className={styles.topIconContainer}>
                {isDietitian ? (
                    <>
                        <IconButton className={styles.iconButton} onClick={() => moveTo("/notifications")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0201 2.90991C8.71009 2.90991 6.02009 5.59991 6.02009 8.90991V11.7999C6.02009 12.4099 5.76009 13.3399 5.45009 13.8599L4.30009 15.7699C3.59009 16.9499 4.08009 18.2599 5.38009 18.6999C9.69009 20.1399 14.3401 20.1399 18.6501 18.6999C19.8601 18.2999 20.3901 16.8699 19.7301 15.7699L18.5801 13.8599C18.2801 13.3399 18.0201 12.4099 18.0201 11.7999V8.90991C18.0201 5.60991 15.3201 2.90991 12.0201 2.90991Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                                <path d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" />
                            </svg>
                            {unReadMessages > 0 && <span className={styles.cartNotify}>{unReadMessages}</span>}
                        </IconButton>
                        <IconButton className={styles.iconButton} onClick={() => moveTo("/chat")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0714 19.0699C16.0152 22.1263 11.4898 22.7867 7.78642 21.074C7.23971 20.8539 6.79148 20.676 6.36537 20.676C5.17849 20.683 3.70117 21.8339 2.93336 21.067C2.16555 20.2991 3.31726 18.8206 3.31726 17.6266C3.31726 17.2004 3.14642 16.7602 2.92632 16.2124C1.21283 12.5096 1.87411 7.98269 4.93026 4.92721C8.8316 1.02443 15.17 1.02443 19.0714 4.9262C22.9797 8.83501 22.9727 15.1681 19.0714 19.0699Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M15.9393 12.4131H15.9483" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M11.9306 12.4131H11.9396" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M7.92128 12.4131H7.93028" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {unReadMessages > 0 && <span className={styles.chatNotify} />}
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton className={styles.iconButton} onClick={() => moveTo("/search")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M17.5 18L21.5423 22" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <circle cx="11.5" cy="11.5" r="8.5" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </IconButton>
                        <IconButton className={styles.iconButton} onClick={() => moveTo("/notifications")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0201 2.90991C8.71009 2.90991 6.02009 5.59991 6.02009 8.90991V11.7999C6.02009 12.4099 5.76009 13.3399 5.45009 13.8599L4.30009 15.7699C3.59009 16.9499 4.08009 18.2599 5.38009 18.6999C9.69009 20.1399 14.3401 20.1399 18.6501 18.6999C19.8601 18.2999 20.3901 16.8699 19.7301 15.7699L18.5801 13.8599C18.2801 13.3399 18.0201 12.4099 18.0201 11.7999V8.90991C18.0201 5.60991 15.3201 2.90991 12.0201 2.90991Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                                <path d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" />
                            </svg>
                            {unReadMessages > 0 && <span className={styles.cartNotify}>{unReadMessages}</span>}
                        </IconButton>
                        <IconButton className={styles.iconButton} onClick={() => moveTo("/chat")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0714 19.0699C16.0152 22.1263 11.4898 22.7867 7.78642 21.074C7.23971 20.8539 6.79148 20.676 6.36537 20.676C5.17849 20.683 3.70117 21.8339 2.93336 21.067C2.16555 20.2991 3.31726 18.8206 3.31726 17.6266C3.31726 17.2004 3.14642 16.7602 2.92632 16.2124C1.21283 12.5096 1.87411 7.98269 4.93026 4.92721C8.8316 1.02443 15.17 1.02443 19.0714 4.9262C22.9797 8.83501 22.9727 15.1681 19.0714 19.0699Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M15.9393 12.4131H15.9483" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M11.9306 12.4131H11.9396" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M7.92128 12.4131H7.93028" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {unReadMessages > 0 && <span className={styles.chatNotify} />}
                        </IconButton>
                        <IconButton className={styles.iconButton} onClick={() => moveTo("/cart")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.4222 19.8203C7.8442 19.8203 8.1872 20.1633 8.1872 20.5853C8.1872 21.0073 7.8442 21.3493 7.4222 21.3493C7.0002 21.3493 6.6582 21.0073 6.6582 20.5853C6.6582 20.1633 7.0002 19.8203 7.4222 19.8203Z" fill="#130F26" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M18.6747 19.8203C19.0967 19.8203 19.4397 20.1633 19.4397 20.5853C19.4397 21.0073 19.0967 21.3493 18.6747 21.3493C18.2527 21.3493 17.9097 21.0073 17.9097 20.5853C17.9097 20.1633 18.2527 19.8203 18.6747 19.8203Z" fill="#130F26" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2.75 3.25L4.83 3.61L5.793 15.083C5.871 16.018 6.652 16.736 7.59 16.736H18.502C19.398 16.736 20.158 16.078 20.287 15.19L21.236 8.632C21.353 7.823 20.726 7.099 19.909 7.099H5.164" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M14.1255 10.7949H16.8985" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className={styles.cartNotify}>{cartState.cart.length}</span>
                        </IconButton>
                    </>
                )}
            </div>
        </div>
    )
}

export default Header;
// {location.pathname.startsWith("/cart") ? <div className={styles.chip}><Avatar alt="cart" variant="square" src={cartLightIcon} className={cx(styles.img)} /><span className={cx(styles.chipLabel, styles.costomLabel)}>Cart</span><span className={styles.badgeCartLight}>{cartState.cart.length}</span></div> : <IconButton className={styles.buttonBase} onClick={() => moveTo("/cart")}><span className="position-relative"><Avatar alt="cart" sizes="small" variant="square" src={cartIcon} className={styles.img} /><span className={styles.badgeCart}>{cartState.cart.length}</span></span></IconButton>}