import React, {useEffect} from 'react';
import { shallowEqual, useSelector} from 'react-redux';
import NavigationHeader from '../../components/NavigationHeader/NavigationHeader'

import styles from './UserNotifications.module.scss'

import logo from '../../assets/stove-logo.png'
import forwardIcon from '../../assets/forwardIcon.svg'

import MoreVertIcon from '@material-ui/icons/MoreVert';

import Notification from './components/Notification/Notification';

const UserNotifications = ({ privateConversations } ) => {
  const notificationState = useSelector(state => state.notification, shallowEqual)
  const userState = useSelector(state => state.user, shallowEqual)

  return (
    <div className={styles.page}>
        <NavigationHeader icon={<MoreVertIcon />} text="Notifications"/>

        <div className={styles.container}>
           <Notification 
           image={logo} 
           text="you just recieved your first notification"
           icon={forwardIcon}
           notificationState={notificationState}
           userState={userState}
           chatConversations={privateConversations}
           />
        </div>
        
    </div>
  )
}

export default UserNotifications