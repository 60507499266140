import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import styles from "./BookingConfirmed.module.scss";

import endIMG from "../../../assets/trackIMGEnd.svg";

const BookingConfirmed = () => {
   const history = useHistory();
   const moveTo = (path) => history.push(path);
   const userState = useSelector((state) => state.user, shallowEqual);
   const user = userState.user;
   return (
      <div className={styles.page}>
         <div className={styles.imgBox}>
            <img
               src={endIMG}
               alt='end'
               onClick={() => {
                  moveTo("/");
               }}
            />
            <div>
               <div className={styles.text}>Booking confirmed!</div>
               <div className={styles.subText}>
                  Yay! Just a little more to go. Your dietitian would love to know a few more details before your first call - it’s vital they have
                  this information before the call.
               </div>
            </div>

            <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
               <button
                  style={{ width: "232px" }}
                  className={styles.nextCenter}
                  onClick={() => {
                    //  user?.quizData ? moveTo("/") : moveTo("/track");
                    moveTo('/track')
                  }}
               >
                  Go to quiz
               </button>
            </div>
         </div>
      </div>
   );
};

export default BookingConfirmed;
