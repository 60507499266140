import React from "react";
import { useHistory } from "react-router-dom";
import { format, addDays } from 'date-fns';
import cx from "classnames"
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';


import CustomInput from "../../../../components/CustomInput/CustomInput";
import backImg from "./../../../../assets/icons/back.png";

import { HOME, CONFIRM } from "./../../Cart";
import styles from "./Address.module.scss";


const Address = (props) => {
    const { setDisplay, userState, address, setAddress, whenDate, setWhenDate } = props;
    const history = useHistory();
    const user = userState.user;


    const goToBack = () => {
        setDisplay((display) => ({ ...display, header: true, active: HOME }));
    }

    const goToConfirmPage = () => {
        setDisplay((display) => ({ ...display, header: false, active: CONFIRM }));
    }

    return (
        <div className={styles.homePage}>
            <div className={styles.header}>
                <IconButton size="small" onClick={goToBack} >
                    <Avatar alt="search" sizes="" variant="square" src={backImg} className={styles.backIcon} />
                </IconButton>
                <span className={styles.headerText}>Pick where and when</span>
                <span className={styles.stepText}><span className={styles.stepTextRed}>1</span>/2</span>
            </div>

            {user && (
                <div className="px-4 pt-4 pb-2">
                    <div className={styles.shippingDetailsContainer}>
                        <div className={styles.shippingDetails}>
                            Ship to
                    </div>
                        <div className={styles.nameText}>
                            {user.displayName}
                        </div>
                        <div>
                            <CustomInput
                                fullWidth
                                multiline
                                rows={2}
                                label="Enter shipping address"
                                placeholder="Enter shipping address"
                                value={address}
                                onChange={event => setAddress(event.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="px-4 pt-4 pb-2">
                <p className={cx("mb-2", styles.whenText)}>When</p>
                <AppBar position="static" color="default" className={styles.appBar}>
                    <Tabs
                        value={false}
                        centered={false}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                        aria-label="Recipes tags"
                        classes={{ indicator: styles.indicator }}
                    >
                        <>
                            {/* <Chip
                                label="Tomorrow"
                                className={whenDate === 1 ? styles.chipSelected : styles.chip}
                                onClick={() => setWhenDate(1)}
                            /> */}
                            {[2, 3, 4, 5, 6, 7].map(value => {
                                return (
                                    <Chip
                                        key={value}
                                        label={format(addDays(new Date(), value), "EEE, do MMM")}
                                        className={whenDate === value ? styles.chipSelected : styles.chip}
                                        onClick={() => setWhenDate(value)}
                                    />
                                )
                            })}
                        </>
                    </Tabs>
                </AppBar>
            </div>



            {user && user.uid && (
                <ButtonBase
                    className={styles.confirmButton}
                    onClick={goToConfirmPage}
                    disabled={!address || !whenDate}
                >Next</ButtonBase>
            )}

        </div>

    )
}

export default Address;