import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "./articleWidget.module.scss";
import ButtonBase from "@material-ui/core/ButtonBase";
import AnimateElement from "../AnimateElement";
import Avatar from "@material-ui/core/Avatar";

export const ArticleWidget = ({ articles, limit }) => {
  const history = useHistory();
  const moveTo = (path) => history.push(path);

  const handleClick = (article) => {
    moveTo(`/article/${article.articleId}`);
  };

  return (
    <>
      {articles &&
        articles.slice(0, limit).map((article) => {
          return (
            <div
              className={styles.articleContainer}
              key={article.articleId}
              onClick={() => handleClick(article)}
            >
              <ButtonBase className={styles.articleContent}>
                <div
                  className={styles.articleImage}
                  style={{
                    backgroundImage: `url(${
                      article.coverImage + "?tr=w-96,h-96"
                    })`,
                  }}
                />
                <div>
                  <div className={styles.articleAuthorContainer}>
                    <AnimateElement>
                      <Avatar
                        alt="Author"
                        src={article.authorImage + "?tr=w-20,h-20"}
                        className={styles.articleAuthorImage}
                      />
                    </AnimateElement>
                    <span className={styles.articleAuthorName}>
                      {article.author}
                    </span>
                  </div>
                  <div className={styles.articleTitle}>{article.title}</div>
                  <div className={styles.articleReadTime}>
                    {article.category}・
                    {Number(article.readTime) < 1
                      ? "less than a minute"
                      : article.readTime + " mins"}
                  </div>
                </div>
              </ButtonBase>
            </div>
          );
        })}
    </>
  );
};
