import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { getAppUserQuery, getAppTaskQuery } from "../../../services/queries";
import styles from "./Task.module.scss";
import icn from "../../../assets/icons/add.svg";
import edit from "../../../assets/icons/edit.png";
import newIcn from "../../../assets/icons/new.svg";
import cancel from "../../../assets/icons/cancelDark.png";
import AnimateElement from "../../../components/AnimateElement";
import { getUserTasks } from "../../../services/firebase";
import { useLocation } from "react-router-dom";
import { isToday, fromUnixTime } from "date-fns";

const Task = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isNew = params.get("new");
  const history = useHistory();
  const { patientId } = useParams();
  const {
    data: patient,
    refetch,
    isLoading: userDataLoading,
  } = getAppUserQuery(patientId);
  const {
    data: allTasks,
    refetch: reTry,
    isLoading: taskLoading,
  } = getAppTaskQuery(patientId);
  const [resourceData, setResourceData] = useState([]);
  const [tasks, setTasks] = useState([]);

  const goBack = () => {
    history.goBack();
  };
  const moveTo = (path) => history.push(path);

  const getTask = async () => {
    const res = await getUserTasks(patientId);
    if (isNew === "yes") {
      let temp = [];
      for (let i = 0; i < res.length; i++) {
        const timestampInSeconds = res[i]?.createdAT?.seconds;
        const dateObject = fromUnixTime(timestampInSeconds);

        const isTodayResult = isToday(dateObject);

        if (isTodayResult) {
          temp.push(res[i]);
        }
      }
      setTasks(temp);
    } else {
      setTasks(res);
    }
  };

  useEffect(() => {
    getTask();
    if (patient && patient?.additionalResources) {
      setResourceData(patient.additionalResources);
    }
  }, [patient, isNew]);
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton className={styles.back} size="small" onClick={goBack}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00065 12.3379L20 12.3379"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
        <Avatar className={styles.avater} src={patient && patient?.photoURL} />
        <div>
          <div className={styles.headerTitle}>Tasks</div>
          <div className={styles.headerSubText}>
            {patient && patient?.displayName}
          </div>
        </div>
        <div className={styles.new}>
          <img
            onClick={() =>
              isNew === "yes"
                ? moveTo(
                    `/dietitian/patient-details/${patientId}/tasks`
                  )
                : moveTo(
                    `/dietitian/patient-details/${patientId}/tasks?new=yes`
                  )
            }
            src={isNew === "yes" ? cancel : newIcn}
            alt="icn"
          />
        </div>
      </div>

      <div className={styles.content}>
        <div
          className={styles.add}
          onClick={() =>
            history.push(`/dietitian/patient-details/${patientId}/new-tasks`)
          }
        >
          <div className={styles.icon}>
            <img src={icn} alt="icn" />
            <div className={styles.text}>
              <div className={styles.name}> Add New Task</div>
            </div>
          </div>

          <div className={styles.arr}>
            <IconButton>
              <AnimateElement duration={"0.5"}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.4892 14L11.5108 9L6.4892 4"
                    stroke="#00866E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </AnimateElement>
            </IconButton>
          </div>
        </div>

        {tasks
          .sort((a, b) => {
            const dateA = a?.createdAT ? a?.createdAT?.seconds : 0; // If createdAt is undefined, use 0 (place it at the end)
            const dateB = b?.createdAT ? b?.createdAT?.seconds : 0;
            return dateB - dateA;
          })
          .map((item, index) => (
            <div className={styles.add} key={index}>
              <div
                className={styles.icon}
                onClick={() => {
                  history.push(
                    `/dietitian/patient-details/${patientId}/task-details/${item.taskId}`
                  );
                }}
              >
                {/* <img src={icn} alt="icn" /> */}
                <div className={styles.index}>{index + 1}</div>
                <div className={styles.text} style={{ margin: "0" }}>
                  <div className={styles.name}> {item?.description}.</div>
                  <div className={styles.sub}> {item?.subDescription}</div>
                  <div className={styles.sub}> {item?.frequency}</div>
                </div>
              </div>

              <div className={styles.arr}>
                <IconButton
                  onClick={() => {
                    history.push(
                      `/dietitian/patient-details/${patientId}/new-tasks?id=${item.taskId}`
                    );
                  }}
                >
                  <AnimateElement duration={"0.5"}>
                    <img src={edit} alt="edit icon" style={{ width: "20px" }} />
                  </AnimateElement>
                </IconButton>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Task;
