import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getUserData } from "../../../services/firebase";
import { toast } from "../../../state";
import { format, addDays } from "date-fns";
import styles from "./HomeChart.module.scss";
import { CChart } from "@coreui/react-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartAnnotationPlugin from "chartjs-plugin-annotation";
import CustomProgessBar from "../../../components/CustomProgressBar/CustomProgessBar";
import water from "../../../assets/icons/water.png";
import weight from "../../../assets/icons/weight.png";
import symptomsImg from "../../../assets/icons/syntoms.png";
import nutrition from "../../../assets/icons/nutrition.png";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import AnimateElement from "../../../components/AnimateElement";

const today = dayjs().format("YYYY-MM-DD");
const date7DaysAgo = dayjs().subtract(7, "day").format("YYYY-MM-DD");

const HomeChart = () => {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [waterData, setWaterData] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [breakFast, setBreakFast] = useState([]);
  const [launch, setLaunch] = useState([]);
  const [dinner, setDinner] = useState([]);
  const [snacks, setSnacks] = useState([]);
  const [symptomsData, setSymptomsData] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [colorList, setColorList] = useState([
    "#EE3D3F",
    "#FFD700",
    "#1D65CE",
    "#32cd32",
    "#8B5A00",
    "#813DD8",
    "#ff7f50",
    "#388E8E",
    "#ffa500",
    "#cd5c5c",
    "#40e0d0",
    "#da70d6",
    "#6495ed",
    "#FF3E96",
    "#6B8E23",
    "#FF00FF",
    "#7D9EC0",
    "#8B1A1A",
  ]);
  const [nutrientsValues, setNutrientsValues] = useState({});
  const [calories, setCalories] = useState([]);
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;
  const history = useHistory();
  const dispatch = useDispatch();
  const params = {
    from: date7DaysAgo,
    to: today,
    userId: user?.uid,
  };

  const getPatientsNutrientsTotals = () => {
    const nutrientsTotals = {
      carbohydrates: 0,
      fats: 0,
      proteins: 0,
    };

    if (user?.events?.length) {
      user.events.forEach((event, i) => {
        if (event.date >= params.from && event.date <= params.to) {
          if (event.hasOwnProperty("food")) {
            event.food.items.forEach((item) => {
              const carbs = item.Item?.nutriments?.carbohydrates
                ? Number(item.Item?.nutriments?.carbohydrates)
                : 0;
              const fats = item.Item?.nutriments?.fat
                ? Number(item.Item?.nutriments?.fat)
                : 0;
              const proteins = item.Item?.nutriments?.proteins
                ? Number(item.Item?.nutriments?.proteins)
                : 0;

              nutrientsTotals.carbohydrates += carbs;
              nutrientsTotals.fats += fats;
              nutrientsTotals.proteins += proteins;
            });
          }
        }
      });
    }
    setNutrientsValues(nutrientsTotals);
    return nutrientsTotals;
  };
  const getAverageNutrientIntake = (
    patientNutrientTotal,
    recommendedNutrientIntake
  ) => {
    if (patientNutrientTotal && recommendedNutrientIntake) {
      const nutrientsAverage =
        (Math.round(patientNutrientTotal) * 100) /
        (7 * recommendedNutrientIntake);
      const result = Math.round(nutrientsAverage);
      return result > 100 ? 100 : result;
    }
    return 0;
  };

  const convertKiloJoulestoKiloCal = (energyValue) => {
    if (energyValue) {
      const result = energyValue / 4.184;
      return result;
    }
    return 0;
  };

  const getDailyCaloriesIntake = () => {
    const weeklyCalories = [];

    labels.forEach((date) => {
      let dailyCalories = 0;
      if (user?.events?.length) {
        user.events.forEach((event, i) => {
          if (event.date >= params.from && event.date <= params.to) {
            if (event.hasOwnProperty("food") && event.id.includes(date)) {
              event.food.items.forEach((item) => {
                if (item.Item?.nutriments) {
                  const calories = item.Item?.nutriments["energy-kcal"]
                    ? Number(item.Item?.nutriments["energy-kcal"])
                    : item.Item?.nutriments["energy_value"]
                    ? convertKiloJoulestoKiloCal(
                        Number(item.Item?.nutriments["energy_value"])
                      )
                    : 0;
                  dailyCalories += calories;
                }
              });
            }
          }
        });
      }
      weeklyCalories.push(Math.round(dailyCalories));
    });
    setCalories(weeklyCalories);
    return weeklyCalories;
  };
  const ProgressBarData = [
    {
      name: "Carbs",
      value: getAverageNutrientIntake(
        nutrientsValues.carbohydrates,
        user?.nutrients?.carbs ? user?.nutrients?.carbs : 500
      ),
      isSet: user?.nutrients?.carbs ? true : false,
    },
    {
      name: "Fats",
      value: getAverageNutrientIntake(
        nutrientsValues.fats,
        user?.nutrients?.fats ? user?.nutrients?.fats : 500
      ),
      isSet: user?.nutrients?.carbs ? true : false,
    },
    {
      name: "Proteins",
      value: getAverageNutrientIntake(
        nutrientsValues.proteins,
        user?.nutrients?.proteins ? user?.nutrients?.fats : 500
      ),
      isSet: user?.nutrients?.carbs ? true : false,
    },
  ];

  const reformData = async () => {
    let reformData = [];
    const fetchData = async () => {
      const newUser = await getUserData(params.userId);
      if (newUser?.events) {
        for (let i = 0; i < newUser.events.length; i++) {
          if (
            newUser.events[i].date >= params.from &&
            newUser.events[i].date <= params.to
          ) {
            reformData.push(newUser.events[i]);
          }
        }
        setData(reformData);
      }

      return newUser;
    };

    if (userState?.user) {
      if (userState?.user?.uid === params.userId) {
        if (userState.user.events) {
          for (let i = 0; i < userState.user.events.length; i++) {
            if (
              userState.user.events[i].date >= params.from &&
              userState.user.events[i].date <= params.to
            ) {
              reformData.push(userState.user.events[i]);
            }
          }
          setData(reformData);
        }
      } else {
        try {
          await fetchData();
        } catch (err) {
          history.goBack();
          dispatch(toast({ message: "Unable to fetch chart data" }));
        }
      }
    }

    let newLabel = [];
    let chartValues = [];
    let water = [];
    let weight = [];
    let symptoms = [];
    let breakFast = [];
    let launch = [];
    let dinner = [];
    let snacks = [];
    let allSymptoms = [];
    let tempSymptoms = {};
    let num = +params.from.split("-")[2];
    let i = 0;
    let finalSymptoms = [];
    while (num <= +params.to.split("-")[2]) {
      if (num === +params.from.split("-")[2]) {
        newLabel.push(format(new Date(params.from), "EEE dd"));
        finalSymptoms.push({ name: format(new Date(params.from), "EEE dd") });

        let found = reformData.filter((data) => {
          return (
            data.date ===
            format(addDays(new Date(params.from), i), "yyyy-MM-dd")
          );
        });
        if (found) {
          chartValues.push(found);
        } else {
          chartValues.push(null);
        }
      } else {
        finalSymptoms.push({
          name: format(addDays(new Date(params.from), i), "EEE dd"),
        });
        newLabel.push(format(addDays(new Date(params.from), i), "EEE dd"));
        let found = reformData.filter((data) => {
          return (
            data.date ===
            format(addDays(new Date(params.from), i), "yyyy-MM-dd")
          );
        });
        if (found) {
          chartValues.push(found);
        } else {
          chartValues.push(null);
        }
      }
      num++;
      i++;
    }
    setLabels(newLabel);
    for (let i = 0; i < chartValues.length; i++) {
      if (chartValues[i].length === 0) {
        water.push({ name: newLabel[i], data: 0 });
        weight.push({ name: newLabel[i], data: 0 });
        symptoms.push(0);
      } else {
        let waterTemp = 0;
        let weightTemp = 0;
        for (let j = 0; j < chartValues[i].length; j++) {
          if (chartValues[i][j].hasOwnProperty("water")) {
            waterTemp = waterTemp + chartValues[i][j].water.quantity;
          } else if (chartValues[i][j].hasOwnProperty("weight")) {
            // weightTemp = weightTemp + +chartValues[i][j].weight.size.replace(/\D/g, "");
            weightTemp =
              weightTemp + +chartValues[i][j].weight.size.slice(0, -2);
          } else if (chartValues[i][j].hasOwnProperty("symptoms")) {
            for (
              let s = 0;
              s < chartValues[i][j].symptoms.symptoms.length;
              s++
            ) {
              if (tempSymptoms[chartValues[i][j].symptoms.symptoms[s]]) {
                tempSymptoms[chartValues[i][j].symptoms.symptoms[s]].push({
                  index: i,
                  time: chartValues[i][j].symptoms.hour,
                });
              } else {
                tempSymptoms[chartValues[i][j].symptoms.symptoms[s]] = [
                  { index: i, time: chartValues[i][j].symptoms.hour },
                ];
              }
            }

            allSymptoms.push(...chartValues[i][j].symptoms.symptoms);
          } else if (chartValues[i][j].tag === "Breakfast") {
            breakFast.push(chartValues[i][j]);
          } else if (chartValues[i][j].tag === "Lunch") {
            launch.push(chartValues[i][j]);
          } else if (chartValues[i][j].tag === "Dinner") {
            dinner.push(chartValues[i][j]);
          } else if (chartValues[i][j].tag === "Snack") {
            snacks.push(chartValues[i][j]);
          }
        }
        water.push({ name: newLabel[i], data: waterTemp });
        weight.push({ name: newLabel[i], data: weightTemp });
        symptoms.push(tempSymptoms);
        let newSymptoms = [];

        for (const key in tempSymptoms) {
          if (tempSymptoms.hasOwnProperty(key)) {
            newSymptoms.push(key);
            for (let i = 0; i < tempSymptoms[key].length; i++) {
              finalSymptoms[tempSymptoms[key][i].index][key] =
                tempSymptoms[key][i].time;
            }
          }
        }
        setSymptomsData(finalSymptoms);
        setSymptoms(newSymptoms);
      }
    }

    setBreakFast(breakFast);
    setDinner(dinner);
    setLaunch(launch);
    setSnacks(snacks);

    setWaterData(water);
    setWeightData(weight);
  };

  useEffect(() => {
    reformData();
  }, []);

  useEffect(() => {
    getPatientsNutrientsTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.events?.length, params.from, params.to]);

  useEffect(() => {
    getDailyCaloriesIntake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.events?.length, labels]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const value = payload[0].value;
      return <div className={styles.tooltip}>{value}</div>;
    }

    return null;
  };

  const CustomLineTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className={styles.tooltip}>
          <p className="label">{`${label}`}</p>
          {payload && payload.length && (
            <>
              {payload.map((entry, index) => (
                <p
                  key={`value-${index}`}
                  className="value"
                >{`${entry.name}: ${entry.value}`}</p>
              ))}
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.sliderTagContainer}>
      <div className={styles.list}>
        <div className={styles.both}>
          <div className={styles.day}>
            <img src={symptomsImg} alt="Community" className={styles.icon} />
            <div className={styles.data}>
              <div className={styles.title}> Symptoms</div>
              {/* <div className={styles.sub}>Overall the week, you had fewer symptoms compared to the average of 3 weeks before. Keep up!</div> */}
            </div>
          </div>
        </div>
        <div style={{ width: "350px", height: "210px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={symptomsData}
              margin={{ top: 20, right: 0, left: -20, bottom: 5 }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" tick={{ fontSize: 10 }} />
              <YAxis tick={{ fontSize: 10 }} />
              <Tooltip content={<CustomLineTooltip />} />
              {symptoms && symptoms.length && (
                <>
                  {symptoms.map((item, index) => (
                    <Line
                      dataKey={item}
                      stroke="none"
                      dot={{
                        r: 6,
                        filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.2))",
                        fill: colorList[index],
                        stroke: "#ffffff",
                        strokeWidth: 2,
                      }}
                    />
                  ))}
                </>
              )}

              <Line
                dataKey="uv"
                stroke="none"
                dot={{
                  r: 6,
                  filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.2))",
                  fill: "#8884d8",
                  stroke: "#ffffff",
                  strokeWidth: 2,
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className={styles.list}>
        <div className={styles.both}>
          <div className={styles.day}>
            <img src={water} alt="Community" className={styles.icon} />
            <div className={styles.data}>
              <div className={styles.title}> Water intake</div>
              {/* <div className={styles.sub}>Hit 5 / 7 times – well done!</div> */}
            </div>
          </div>
        </div>
        <div style={{ width: "350px", height: "210px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={waterData}
              margin={{ top: 20, right: 0, left: -20, bottom: 5 }}
            >
              <XAxis dataKey="name" tick={{ fontSize: 10 }} />
              <YAxis dataKey="data" tick={{ fontSize: 10 }} />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: "transparent" }}
              />
              <Bar dataKey="data" fill="#1D65CE" stroke="none" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        {/* <CChart
               type='bar'
               data={{
                  labels: labels,
                  datasets: [
                     {
                        label: "Water intake",
                        backgroundColor: "#1D65CE",
                        borderColor: "#1D65CE",
                        pointBackgroundColor: "#1D65CE",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#1D65CE",
                        tooltipLabelColor: "#1D65CE",
                        data: waterData,
                     },
                  ],
               }}
               options={{
                  aspectRatio: 1.5,
                  tooltips: {
                     enabled: false,
                  },
               }}
            /> */}
      </div>

      <div className={styles.list}>
        <div className={styles.both}>
          <div className={styles.day}>
            <img src={weight} alt="Community" className={styles.icon} />
            <div className={styles.data}>
              <div className={styles.title}> Weight</div>
              {/* <div className={styles.sub}>You lost 0.4kg – keep going like this!</div> */}
            </div>
          </div>
        </div>

        <div style={{ width: "350px", height: "210px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={weightData}
              margin={{ top: 20, right: 0, left: -20, bottom: 5 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#813DD8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#813DD8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" tick={{ fontSize: 10 }} />
              <YAxis tick={{ fontSize: 10 }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="data"
                stroke="#813DD8"
                strokeWidth={3}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        {/* <CChart
               type='line'
               data={{
                  labels: labels,
                  datasets: [
                     {
                        label: "Weight",
                        backgroundColor: "#813DD8",
                        borderColor: "#813DD8",
                        pointBackgroundColor: "#813DD8",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#813DD8",
                        tooltipLabelColor: "#813DD8",
                        data: weightData,
                     },
                  ],
               }}
               options={{
                  aspectRatio: 1.5,
                  tooltips: {
                     enabled: false,
                  },
               }}
            /> */}
      </div>

      {user && (
        <div className={styles.list}>
          <div className={styles.both}>
            <div className={styles.day}>
              <img src={nutrition} alt="Community" className={styles.icon} />
              <div className={styles.data}>
                <div className={styles.title}> Nutrition</div>
                {/* <div className={styles.sub}>89% of your meals were in line with your program – excellent behave!</div> */}
              </div>
            </div>
          </div>

          <CChart
            type="bar"
            data={{
              labels: labels,
              datasets: [
                {
                  label: "Daily calories intake (kcal)",
                  backgroundColor: "#00866E",
                  borderColor: "#00866E",
                  pointBackgroundColor: "#00866E",
                  borderRadius: "5",
                  pointBorderColor: "#fff",
                  pointHoverBackgroundColor: "#fff",
                  pointHoverBorderColor: "#00866E",
                  tooltipLabelColor: "#00866E",
                  data: calories,
                  datalabels: {
                    display: true,
                    color: "rgba(19, 15, 38, 0.6)",
                    align: "end",
                    anchor: "start",
                    font: {
                      size: "12",
                      weight: 500,
                    },
                    // rotation: 270,
                    clamp: true,
                    offset: 4,
                    formatter: (value) => {
                      return value > 0 ? value : "";
                    },
                  },
                },
              ],
            }}
            options={{
              onHover: null,
              aspectRatio: 2,
              tooltips: {
                enabled: false,
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                y: {
                  max: user?.nutrients?.calories,
                  min: 0,
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                tooltip: {
                  enabled: false,
                  external: null,
                  events: [],
                },
                annotation: {
                  annotations: [
                    {
                      drawTime: "afterDraw", // overrides annotation.drawTime if set
                      type: "line",
                      mode: "horizontal",
                      scaleID: "y-axis-0",
                      value: 500,
                      borderColor: "red",
                      borderWidth: 2,
                    },
                  ],
                },
              },
            }}
            plugins={[ChartDataLabels, ChartAnnotationPlugin]}
          />
          <div className={styles.progressBarCtn}>
            {ProgressBarData.map((data) => {
              return (
                <div className={styles.progress} key={data.name}>
                  <CustomProgessBar isSet={data.isSet} percentage={data.value} />
                  <div className={styles.progressBarTitle}>{data.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeChart;
