import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllRecipes, getSingleRecipe, getOnly10Recipes } from "../services/firebase";
import { removeRecipeFromRedux } from "./User";


export const getRecipes = createAsyncThunk(
    'Recipes/getAllRecipes',
    async () => {
        const response = await getAllRecipes();
        return response;
    }
)

export const getRecipe = createAsyncThunk(
    'Recipes/getSingleRecipe',
    async (recipeId) => {
        const response = await getSingleRecipe(recipeId);
        return response;
    }
)

export const getOnly10Recipe = createAsyncThunk(
    'Recipes/getOnly10Recipe',
    async () => {
        const response = await getOnly10Recipes();
        localStorage.setItem('recipe', JSON.stringify(response))
        return response;
    }
)



const initialState = {
    recipesIsLoading: true,
    recipesIsError: false,
    recipes: [],
    recipeIsLoading: true,
    recipeIsError: false,
    recipe: null,
    onlyRecipe: [],
    recipe10IsLoading: true,
    recipe10IsError: false,
}

export const Recipes = createSlice({
    name: 'Recipes',
    initialState,
    reducers: {
        setRecipes: (state, action) => {
            state.recipes = action.payload;
        },
        likeRecipeAction: (state, action) => {
            const likes = state.recipe.likes;
            likes.push(action.payload);
            state.recipe.likes = likes;
        },
        unlikeRecipeAction: (state, action) => {
            const index = state.recipe.likes.findIndex(userId => userId === action.payload);
            const likes = state.recipe.likes;
            likes.splice(index, 1);
            state.recipe.likes = likes;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(removeRecipeFromRedux, (state, action) => {
                state.recipes = state.recipes.filter(recipe => recipe.recipeId !== action.payload);
            })

        builder
            .addCase(getRecipes.pending, (state, action) => {
                state.recipesIsLoading = true;
                state.recipesIsError = false;
            })
            .addCase(getRecipes.fulfilled, (state, action) => {
                state.recipesIsLoading = false;
                state.recipesIsError = false;
                state.recipes = action.payload.sort(() => Math.random() - 0.5);
            })
            .addCase(getRecipes.rejected, (state, action) => {
                state.recipesIsLoading = false;
                state.recipesIsError = true;
                state.recipes = [];
            })

        builder
            .addCase(getRecipe.pending, (state, action) => {
                state.recipeIsLoading = true;
                state.recipeIsError = false;
            })
            .addCase(getRecipe.fulfilled, (state, action) => {
                state.recipeIsLoading = false;
                state.recipeIsError = false;
                state.recipe = action.payload;
            })
            .addCase(getRecipe.rejected, (state, action) => {
                state.recipeIsLoading = false;
                state.recipeIsError = true;
                state.recipe = null;
            })

        builder
            .addCase(getOnly10Recipe.pending, (state, action) => {
                // let res = JSON.parse(localStorage.getItem('recipe'))
                // state.recipe10IsError = false;
                // if(res){
                //     state.onlyRecipe = res
                //     state.recipe10IsLoading = false;
                // }else {
                //     state.recipe10IsLoading = true;
                // }
                state.recipe10IsError = false;
                state.recipe10IsLoading = true;
                
            })
            .addCase(getOnly10Recipe.fulfilled, (state, action) => {
                state.recipe10IsLoading = false;
                state.recipe10IsError = false;
                state.onlyRecipe = action.payload;
            })
            .addCase(getOnly10Recipe.rejected, (state, action) => {
                state.recipe10IsLoading = false;
                state.recipe10IsError = true;
                state.onlyRecipe = null;
            })
    }
});

export const { setRecipes, likeRecipeAction, unlikeRecipeAction } = Recipes.actions;
export default Recipes.reducer;


