import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminNotifications, getIndividualUnreadMessages, getSingleUserGroupUnreadMsg } from "../services/firebase";
import { notifications } from "../constant";


export const getNotifications = createAsyncThunk(
    'Notification/getNotifications',
    async (userId) => {
        const response = await getAdminNotifications(userId);
        return response;
    }
)

export const getAllUnreadIndividualNotifications = createAsyncThunk('Notification/getAllUnreadNotifications', async (userId) => {
    const response = await getIndividualUnreadMessages(userId);
    return response;
})

export const getUserUnreadGroupMessages = createAsyncThunk('Notification/getGroupUnreadNotifications', async(userId) => {
    const response = await getSingleUserGroupUnreadMsg(userId);
    return response;
})


const initialState = {
    notifications: [],
    notificationsIsLoading: true,
    notificationsIsError: false,
    unReadMessages: 0,
    notificationPreference: notifications,
    unReadIndividualNotifications: [],
    unReadIndividualNotificationsIsLoading: true,
    unReadIndividualNotificationsIsError: false,
    unReadGroupMessages: [],
    unReadGroupMessagesIsLoading: true,
    unReadGroupMessagesIsError: false

}

export const Notification = createSlice({
    name: 'Notification',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.notifications = [...state.notifications, action.payload];
        },
        setUnReadMessageCount : (state, action) => {
            state.unReadMessages = action.payload
        },
        toggleNotification: (state, action) => {
            state.notificationPreference = state.notificationPreference.map((notification) => {
                if (notification.slug === action.payload) {
                    return { ...notification, status: !notification.status }
                }
                return notification;
            })
        },
        setNotificationPreferences: (state, action) => {
            state.notificationPreference = action.payload;
        }

    },
    extraReducers: {
        [getNotifications.pending]: (state) => {
            state.notificationsIsLoading = true;
            state.notificationsIsError = false;
        },
        [getNotifications.fulfilled]: (state, action) => {
            state.notifications = action.payload;
            state.notificationsIsLoading = false;
            state.notificationsIsError = false;
        },
        [getNotifications.rejected]: (state) => {
            state.notificationsIsLoading = false;
            state.notificationsIsError = true;
        },
        [getAllUnreadIndividualNotifications.pending]: (state) => {
            state.unReadIndividualNotificationsIsLoading = true;
            state.unReadIndividualNotificationsIsError = false;
        },
        [getAllUnreadIndividualNotifications.fulfilled]: (state, action) => {
            state.unReadIndividualNotifications = action.payload;
            state.unReadIndividualNotificationsIsLoading = false;
            state.unReadIndividualNotificationsIsError = false;
        },
        [getAllUnreadIndividualNotifications.rejected]: (state) => {
            state.unReadIndividualNotificationsIsLoading = false;
            state.unReadIndividualNotificationsIsError = true;
        },
        [getUserUnreadGroupMessages.pending]: (state) => {
            state.unReadGroupMessagesIsLoading = true;
            state.unReadGroupMessagesIsError = false;
        },
        [getUserUnreadGroupMessages.fulfilled]: (state, action) => {
            state.unReadGroupMessages = action.payload;
            state.unReadGroupMessagesIsLoading = false;
            state.unReadGroupMessagesIsError = false;
        },
        [getUserUnreadGroupMessages.rejected]: (state) => {
            state.unReadGroupMessagesIsLoading = false;
            state.unReadGroupMessagesIsError = true;
        }
    }
})

export const { setNotification, setUnReadMessageCount, toggleNotification, setNotificationPreferences } = Notification.actions;
export default Notification.reducer;