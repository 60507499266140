import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";

import backIcon from "./../../../../assets/icons/back.png";
import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";
import GroceryWidgetList from "../../../../components/GroceryWidgetList/GroceryWidgetList";

import styles from "./GroceriesLiked.module.scss";
import AnimateElement from "../../../../components/AnimateElement";

const GroceriesLiked = (props) => {
  const { userState } = props;
  const history = useHistory();

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton
          size="small"
          className="mr-auto"
          onClick={() => history.goBack()}
        >
          <Avatar
            alt="back"
            sizes="small"
            variant="square"
            src={backIcon}
            className={styles.arrowback}
          />
        </IconButton>
      </div>
      <h2 className={styles.title}>Favourite Products</h2>
      <div className="px-4 pt-4">
        {userState.userLikedGroceriesIsLoading === true &&
          userState.userLikedGroceriesIsError === false && (
            <Spinner variant="rect" height={140} />
          )}
        {userState.userLikedGroceriesIsLoading === false &&
          userState.userLikedGroceriesIsError === true && (
            <MessageBox text="Failed to load your favourite products" />
          )}
        {userState.userLikedGroceriesIsLoading === false &&
          userState.userLikedGroceriesIsError === false &&
          userState.userLikedGroceries.length === 0 && (
            <MessageBox text="No favourite product added" />
          )}
      </div>
      {userState.userLikedGroceriesIsLoading === false &&
        userState.userLikedGroceriesIsError === false && (
          <AnimateElement duration={"0.5"}>
            <GroceryWidgetList
              groceries={userState.userLikedGroceries}
              footer="grocery"
            />
          </AnimateElement>
        )}
    </div>
  );
};

export default GroceriesLiked;
