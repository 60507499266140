import React, { useRef, useState, Fragment, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import cx from "classnames";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import EditIcon from "@material-ui/icons/Edit";

import styles from "./RecipeSteps.module.scss";
import backIcon from "./../../assets/icons/back.png";
import listIcon from "./../../assets/icons/list.png";
import labelIcon from "./../../assets/icons/label.png";
import labelRedIcon from "./../../assets/icons/label-red.png";
import emptyBg from "./../../assets/empty-bg.png";

import { updateRecipe } from "../../services/firebase";
import { getRecipe, toast } from "../../state";

function CustomDots(dots) {
   const customDots = dots.map((dot, index) =>
      React.createElement(
         dot.type,
         {
            ...dot.props,
            key: index,
            className: dot.props.className === "slick-active" ? styles.activeDot : "",
         },
         dot.props.children
      )
   );

   return <ul> {customDots} </ul>;
}

const RecipeSteps = (props) => {
   const { setStepIsOpen, steps, recipe } = props;
   const history = useHistory();
   const dispatch = useDispatch();
   const slider = useRef();
   const userState = useSelector((state) => state.user, shallowEqual);
   const [listIsOpen, setListIsOpen] = useState(false);
   const [activeSlideIndex, setActiveSlideIndex] = useState(0);
   const [loading, setLoading] = useState(false);

   const settings = {
      dots: true,
      arrows: false,
      adaptiveHeight: true,
      infinite: false,
      draggable: false,
      swipeToSlide: false,
      touchMove: false,
      speed: 500,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: styles["dots"],
      appendDots: CustomDots,
      initialSlide: activeSlideIndex,
      beforeChange: (current, next) => setActiveSlideIndex(next),
   };

   const handleUpdateStepPhoto = async (photo) => {
      const newRecipe = { ...recipe };
      setLoading(true);
      try {
         const newSteps = newRecipe.steps.map((step) => {
            if (step.id === activeSlideIndex) {
               return { ...step, thumbnail: photo };
            }
            return step;
         });

         const payload = {
            ...newRecipe,
            steps: newSteps,
         };

         const recipeId = await updateRecipe(payload);
         dispatch(getRecipe(recipeId));
         setLoading(false);
      } catch (error) {
         setLoading(false);
         dispatch(toast({message: "an error occured"}))
         console.log(error, "error");
      }
   };

   return (
      <>
         {listIsOpen ? (
            <div className={styles.page}>
               <div className={styles.header}>
                  <IconButton size='small' className='mr-2' onClick={() => setListIsOpen(false)}>
                     <Avatar alt='back' sizes='small' variant='square' src={backIcon} className={styles.arrowback} />
                  </IconButton>
                  <span className={styles.listHeaderText}>Index directions</span>
               </div>
               <List>
                  {steps.map((step, idx) => (
                     <Fragment key={idx}>
                        <ListItem alignItems='flex-start' className={styles.listItem}>
                           <ListItemAvatar className={styles.avatar}>
                              {idx === activeSlideIndex ? (
                                 <Avatar src={labelRedIcon} className={styles.avatarIcon} />
                              ) : (
                                 <Avatar src={labelIcon} className={styles.avatarIcon} />
                              )}
                           </ListItemAvatar>
                           <ListItemText
                              disableTypography
                              primary={<div className={styles.primarytext}>{`Step ${idx + 1}`}</div>}
                              secondary={<div className={styles.secondaryText}>{step.title}</div>}
                           />
                           <ListItemSecondaryAction>
                              <IconButton
                                 size='small'
                                 edge='end'
                                 aria-label='forward'
                                 onClick={() => {
                                    setActiveSlideIndex(idx);
                                    setListIsOpen(false);
                                 }}
                              >
                                 <ArrowForwardIosOutlinedIcon color='primary' />
                              </IconButton>
                           </ListItemSecondaryAction>
                        </ListItem>
                        <div className={styles.line} />
                     </Fragment>
                  ))}
               </List>
            </div>
         ) : (
            <div className={styles.page}>
               <div className={styles.header}>
                  <IconButton size='small' className='mr-2' onClick={() => setStepIsOpen(false)}>
                     <CloseOutlinedIcon className={styles.closeIcon} />
                  </IconButton>
                  <span className={cx(styles.listHeaderText, "mr-auto")}>Step</span>
                  <IconButton size='small' onClick={() => setListIsOpen(true)}>
                     <Avatar alt='share' variant='square' src={listIcon} className={styles.listIcon} />
                  </IconButton>
               </div>
               <div>
                  <Slider ref={(s) => (slider.current = s)} {...settings}>
                     {steps.map((step, idx) => (
                        <div key={idx} className={styles.stepContainer}>
                           <div
                              className={styles.image}
                              style={{ backgroundImage: `url(${step.thumbnail ? step.thumbnail : emptyBg})` }}
                           >
                              <div className={styles.headerBottom}>
                                 {userState.user.uid === recipe.chefId && (
                                    <div className={styles.iconButton}>
                                       <IconButton
                                          className={styles.icon}
                                          size='small'
                                          onClick={(event) => {
                                             event.stopPropagation();
                                             // dispatch(editRecipe(recipe));
                                             // history.push("/recipeupload/editing/2");
                                          }}
                                       >
                                          {loading ? (
                                             <CircularProgress size={20} color='primary' />
                                          ) : (
                                             <label className={styles.label}>
                                                <EditIcon className={styles.icon} color='primary' />
                                                <input
                                                   hidden
                                                   type='file'
                                                   accept='image/*'
                                                   onChange={(e) => {
                                                      const file = e.target.files[0];
                                                      if (file && file.type.match("image.*")) {
                                                         let reader = new FileReader();
                                                         reader.onload = async function (event) {
                                                            await handleUpdateStepPhoto(event.target.result);
                                                         };
                                                         reader.readAsDataURL(e.target.files[0]);
                                                      }
                                                   }}
                                                />
                                             </label>
                                          )}
                                       </IconButton>
                                    </div>
                                 )}
                              </div>
                           </div>
                           <div className={styles.stepHeaderTop} />
                           <div className={styles.contentContainer}>
                              <div className={styles.headerTextContainer}>
                                 <Avatar src={labelRedIcon} className={styles.headerTextIcon} />
                                 <span className={styles.headerText}>{step.title}</span>
                              </div>
                              <div className={styles.slideLine} />
                              {step.ingredientsUsed && step.ingredientsUsed.length > 0 && (
                                 <>
                                    <div className={styles.prepareText}>Prepare:</div>
                                    <div className={styles.prepareTextContent}>{step.ingredientsUsed.join(", ")}</div>
                                 </>
                              )}
                              {step.description && (
                                 <>
                                    <div className={styles.slideLine} />
                                    <div className={styles.description}>{step.description}</div>
                                 </>
                              )}
                              <div className='py-4'>
                                 {recipe.ingredients &&
                                    recipe.ingredients.length > 0 &&
                                    recipe.ingredients
                                       .filter((ingredient) => {
                                          return step.ingredientsUsed.includes(ingredient.name);
                                       })
                                       .map((ingredient, index) => (
                                          <div key={index}>
                                             {index !== 0 && <div className={styles.lineHalf} />}
                                             <div className={styles.ingredientListBoldText}>{ingredient.name}</div>
                                             <div className={styles.ingredientListListText}>
                                                {Number(ingredient.quantity)} {ingredient.unit}
                                             </div>
                                          </div>
                                       ))}
                                 <div className={styles.lineHalf} />
                              </div>
                           </div>
                        </div>
                     ))}
                  </Slider>
               </div>
               {activeSlideIndex > 0 && (
                  <>
                     <div className={styles.previousButtonBack} />
                     <ButtonBase className={styles.previousButton} onClick={() => slider.current.slickPrev()}>
                        <ArrowBackIosOutlinedIcon color='primary' />
                     </ButtonBase>
                  </>
               )}
               {activeSlideIndex < steps.length - 1 && (
                  <ButtonBase
                     className={styles.nextButton}
                     onClick={() => {
                        slider.current.slickNext();
                     }}
                     disabled={loading}
                  >
                     {"Next >"}
                  </ButtonBase>
               )}
            </div>
         )}
      </>
   );
};

export default RecipeSteps;
