/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-query";
import {
   getAllProfiles,
   getAllAssignedProfiles,
   getProfile,
   getAllRecipesOnly,
   getAllUserRecipes,
   getAllGroceries,
   getAllRecommendRecipes,
   getAllRecommendGroceries,
   getSingleRecipe,
   getNutrientsById,
   getSession,
   getUserSessions,
   getRecentMealData,
   getUserTasks,
   getDietitianIncome
} from "./../firebase";
import { GET_RECENT_MEAL_DATA } from "../queries/queryKeys";
import { getDietitianCalendly } from "./../calendar";

export const getAppUsersQuery = (userId) => {
   return useQuery("list-of-app-users", () => getAllProfiles(userId));
};

export const getAllAssignedProfilesQuery = (userId) => {
   return useQuery("list-of-assigned-user", () => getAllAssignedProfiles(userId));
};

export const getAppUserQuery = (userId) => {
   return useQuery(`list-of-app-users-${userId}`, () => getProfile(userId));
};

export const getAppTaskQuery = (userId) => {
   return useQuery(`list-of-user-tasks-${userId}`, () => getUserTasks(userId));
};
export const createUserTask = (userId, payload) => {
   return useQuery(`create-user-tasks-${userId}`, () => getUserTasks(userId, payload));
};

export const getSingleRecipeQuery = (recipeId) => {
   return useQuery(`getSingleRecipe-${recipeId}`, () => getSingleRecipe(recipeId));
};
export const getAllUserRecipesQuery = (userId) => {
   return useQuery(`getAllUserRecipes-${userId}`, () => getAllUserRecipes(userId));
};

export const getAllRecipesOnlyQuery = () => {
   return useQuery(`getAllRecipesOnly`, () => getAllRecipesOnly());
};

export const getAllGroceriesQuery = () => {
   return useQuery(`getAllGroceries`, () => getAllGroceries());
};

export const getAllRecommendRecipesQuery = (userId) => {
   return useQuery(`getAllRecommendRecipes-${userId}`, () => getAllRecommendRecipes(userId));
};
export const getAllRecommendGroceriesQuery = (userId) => {
   return useQuery(`getAllRecommendGroceries-${userId}`, () => getAllRecommendGroceries(userId));
};
export const getSessionQuery = (sessionId) => {
   return useQuery(`getSession-${sessionId}`, () => getSession(sessionId));
};
export const getUserSessionsQuery = (userId) => {
   return useQuery(`getUserSessions-${userId}`, () => getUserSessions(userId));
};
export const getDietitianCalendlyQuery = (min_start_date, email) => {
   return useQuery(`getDietitianIncomingCalendly`, () => getDietitianCalendly(min_start_date, email));
};
export const getDietitianIncomeQuery = (uid) => {
   return useQuery(`getDietitianIncome-${uid}`, () => getDietitianIncome(uid));
};

export const getUserNutrients = (userId) => {
   const {
      data: userNutrients,
      isLoading,
      refetch,
      isError
   } = useQuery(`getUserNutrients-${userId}`, () => getNutrientsById(userId), {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (error) => {
         const requestErrorMessage = error?.request?.message;
         const errorMessage = error?.response?.data?.message;
         console.log(`${requestErrorMessage || errorMessage}`);
      },
   });

   return { userNutrients, isLoading, refetch, isError };
};

export const useGetRecentMeal = (userId) => {
   const { data: recentMeals, isLoading: recentMealsIsLoading, isFetching: recentMealIsFetching, isError: recentMealIsError } = useQuery(GET_RECENT_MEAL_DATA, () => getRecentMealData(userId), {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (error) => {
         const requestErrorMessage = error?.request?.message;
         const errorMessage = error?.response?.data?.message;
         console.log(`${requestErrorMessage || errorMessage}`);
      },
   });

   return { recentMeals, recentMealsIsLoading, recentMealIsFetching, recentMealIsError };
}
