import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";
import GroceryRewardWidget from "../GroceryRewardWidget/GroceryRewardWidget";
import discountIcon from "./../../../../assets/icons/discount.png";


import recipesIcon from "./../../../../assets/icons/recipes.png";
import groceriesIcon from "./../../../../assets/icons/groceries.png";
import cartIcon from "./../../../../assets/icons/cart.png";
import filterIcon from "./../../../../assets/icons/filter.png";





import styles from "./Points.module.scss";

const REWARDS = "REWARDS";
const HISTORY = "HISTORY";



const tabTexts = [
    REWARDS,
    HISTORY
]



const Points = (props) => {
    const { url, userState, rewardsState, cartState } = props;
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(HISTORY);
    const [userRewardsCollectRewards, setUserRewardsCollectRewards] = useState([]);
    const handleTabChange = (event, tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const userRewardsCollectRewards = [];
        userState.userRewards.forEach((transaction) => {
            userRewardsCollectRewards.push(...transaction.rewards)
        })
        setUserRewardsCollectRewards(userRewardsCollectRewards);
    }, [userState.userRewards])

    return (
        <>

            <div className={styles.headerTop}>
                <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => history.goBack()}
                >
                    <CloseOutlinedIcon className={styles.closeIcon} />
                </IconButton>
                <IconButton
                    size="small"
                    color="inherit"
                >
                    <MoreVertIcon className={styles.MoreVertIcon} />
                </IconButton>
                <svg className={styles.circleSvg} viewBox="0 0 69 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" d="M8.6386 14.9996C8.6386 0.964678 20.2335 -10.468 34.5005 -10.468C48.7665 -10.468 60.3615 0.964668 60.3615 14.9996C60.3615 29.0353 48.7664 40.468 34.5005 40.468C20.2336 40.468 8.6386 29.0353 8.6386 14.9996ZM0.5 14.9996C0.5 33.4799 15.7604 48.5 34.5005 48.5C53.2414 48.5 68.5 33.4799 68.5 14.9996C68.5 -3.47991 53.2414 -18.5 34.5005 -18.5C15.7604 -18.5 0.5 -3.47993 0.5 14.9996Z" fill="white" stroke="white" />
                </svg>
                <svg className={styles.blueWave} viewBox="0 0 33 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.9865 7.98826H25.9749C23.5793 7.98378 22.3223 6.48089 21.1065 5.02695C19.8176 3.4855 18.6003 2.02968 16.0072 2.02968C14.3267 2.02968 13.5202 3.23076 12.5863 4.62144C11.5266 6.19943 10.3254 7.98826 7.62094 7.98826C2.12934 7.98826 1.01756 1.65103 1.00672 1.58706C0.96133 1.31181 1.15065 1.05186 1.43002 1.0068C1.70984 0.96131 1.97267 1.14891 2.0185 1.42431C2.05789 1.65955 3.00803 6.97738 7.62094 6.97738C9.77482 6.97738 10.7254 5.5617 11.7318 4.06299C12.7371 2.56645 13.776 1.0188 16.0072 1.0188C19.0832 1.0188 20.5782 2.80647 21.8972 4.38373C23.0595 5.77355 24.0629 6.97405 25.9771 6.97738H25.9862C30.2314 6.97738 30.9502 1.6698 30.9791 1.44394C31.0138 1.16725 31.2686 0.970994 31.5503 1.0045C31.831 1.03843 32.0306 1.29015 31.9961 1.56699C31.9882 1.63125 31.1393 7.98768 25.9865 7.98826Z" strokeWidth="0.5" />
                </svg>
            </div>

            <div className={styles.headerMiddle}>
                <div className={styles.collectionText}>You’ve collected</div>
                <div className={styles.pointsText}>{userState.user.points} points</div>
                <svg className={styles.blueWave} viewBox="0 0 33 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.9865 7.98826H25.9749C23.5793 7.98378 22.3223 6.48089 21.1065 5.02695C19.8176 3.4855 18.6003 2.02968 16.0072 2.02968C14.3267 2.02968 13.5202 3.23076 12.5863 4.62144C11.5266 6.19943 10.3254 7.98826 7.62094 7.98826C2.12934 7.98826 1.01756 1.65103 1.00672 1.58706C0.96133 1.31181 1.15065 1.05186 1.43002 1.0068C1.70984 0.96131 1.97267 1.14891 2.0185 1.42431C2.05789 1.65955 3.00803 6.97738 7.62094 6.97738C9.77482 6.97738 10.7254 5.5617 11.7318 4.06299C12.7371 2.56645 13.776 1.0188 16.0072 1.0188C19.0832 1.0188 20.5782 2.80647 21.8972 4.38373C23.0595 5.77355 24.0629 6.97405 25.9771 6.97738H25.9862C30.2314 6.97738 30.9502 1.6698 30.9791 1.44394C31.0138 1.16725 31.2686 0.970994 31.5503 1.0045C31.831 1.03843 32.0306 1.29015 31.9961 1.56699C31.9882 1.63125 31.1393 7.98768 25.9865 7.98826Z" strokeWidth="0.5" />
                </svg>
                <svg className={styles.yellowWave} viewBox="0 0 33 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.9865 7.98826H25.9749C23.5793 7.98378 22.3223 6.48089 21.1065 5.02695C19.8176 3.4855 18.6003 2.02968 16.0072 2.02968C14.3267 2.02968 13.5202 3.23076 12.5863 4.62144C11.5266 6.19943 10.3254 7.98826 7.62094 7.98826C2.12934 7.98826 1.01756 1.65103 1.00672 1.58706C0.96133 1.31181 1.15065 1.05186 1.43002 1.0068C1.70984 0.96131 1.97267 1.14891 2.0185 1.42431C2.05789 1.65955 3.00803 6.97738 7.62094 6.97738C9.77482 6.97738 10.7254 5.5617 11.7318 4.06299C12.7371 2.56645 13.776 1.0188 16.0072 1.0188C19.0832 1.0188 20.5782 2.80647 21.8972 4.38373C23.0595 5.77355 24.0629 6.97405 25.9771 6.97738H25.9862C30.2314 6.97738 30.9502 1.6698 30.9791 1.44394C31.0138 1.16725 31.2686 0.970994 31.5503 1.0045C31.831 1.03843 32.0306 1.29015 31.9961 1.56699C31.9882 1.63125 31.1393 7.98768 25.9865 7.98826Z" strokeWidth="0.5" />
                </svg>
                <FiberManualRecordIcon className={styles.cirleDotOne} />
                <FiberManualRecordIcon className={styles.cirleDotTwo} />
                <FiberManualRecordIcon className={styles.cirleDotThree} />
                <FiberManualRecordOutlinedIcon className={styles.cirleDotOutlineOne} />
                <FiberManualRecordOutlinedIcon className={styles.cirleDotOutlineTwo} />
                <FiberManualRecordOutlinedIcon className={styles.cirleDotOutlineThree} />
                <FiberManualRecordIcon className={styles.cirleDotLarge} />
            </div>

            <AppBar position="sticky" color="default" className={styles.appBar}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Search tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="off"
                    variant="fullWidth"
                >
                    {tabTexts.map((text, idx) => <Tab key={idx} value={text} aria-labelledby={text} className={styles.tab} label={text} />)}
                </Tabs>
            </AppBar>


            {activeTab === REWARDS && (
                <>
                    {rewardsState.rewardsIsLoading === true && rewardsState.rewardsIsError === false && (
                        <div className="px-4 mb-3">
                            <Spinner variant="rect" height={88} />
                        </div>
                    )}
                    {rewardsState.rewardsIsLoading === false && rewardsState.rewardsIsError === true && (
                        <MessageBox text="Failed to load rewards" />
                    )}

                    {rewardsState.rewardsIsLoading === false && rewardsState.rewardsIsError === false && (
                        <>
                            <div className={styles.yourRecipe}>
                                <span>Most popular</span>
                                <span className={styles.seeAll}>{"Filter by >"}</span>
                            </div>

                            <div className="px-4 mb-4">
                                <GridList className="mt-4" cols={2} spacing={10} cellHeight="auto" component="div">
                                    {rewardsState.rewards.map(reward => (
                                        <GridListTile key={reward.rewardId}  >
                                            <GroceryRewardWidget reward={reward} userState={userState} cartState={cartState} />
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </div>
                        </>
                    )}
                </>
            )}


            {activeTab === HISTORY && (
                <>

                    {userState.userRewardsIsLoading === true && userState.userRewardsIsError === false && (
                        <div className="px-4 mb-3">
                            <Spinner variant="rect" height={88} />
                        </div>
                    )}
                    {userState.userRewardsIsLoading === false && userState.userRewardsIsError === true && (
                        <MessageBox text="Failed to load collected rewards" />
                    )}
                    {userState.userRewardsIsLoading === false && userState.userRewardsIsError === false && (
                        <>
                            <div className={styles.yourRecipe}>
                                <span>Your last rewards</span>
                                <span className={styles.seeAll}>{"See all >"}</span>
                            </div>

                            <div className={styles.slider}>
                                {userRewardsCollectRewards.map((reward, idx) => (
                                    <Card
                                        key={idx}
                                        component="div"
                                        className={styles.card}
                                    >
                                        <CardMedia
                                            className={styles.cardMedia}
                                            image={reward.thumbnail}
                                            title={reward.title}
                                        />
                                    </Card>
                                ))}
                            </div>
                        </>
                    )}



                    {userState.userCollectedPointsIsLoading === true && userState.userCollectedPointsIsError === false && (
                        <div className="px-4 mb-3">
                            <Spinner variant="rect" height={88} />
                        </div>
                    )}
                    {userState.userCollectedPointsIsLoading === false && userState.userCollectedPointsIsError === true && (
                        <MessageBox text="Failed to load collected points" />
                    )}
                    {userState.userCollectedPointsIsLoading === false && userState.userCollectedPointsIsError === false && (
                        <List className={styles.list}>
                            {
                                userState.userCollectedPoints.map(item => {
                                    return (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar className={styles.listAvatar}>
                                                    <Avatar className={styles.avatar} src={recipesIcon} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    disableTypography
                                                    primary={
                                                        <div className={styles.primaryTextContainer}>
                                                            <span className={styles.primaryText}>New recipe</span>
                                                            <Avatar className={styles.badge} src={discountIcon} />
                                                            <span className={styles.primaryTextRight}>{item.points}</span>
                                                        </div>
                                                    }
                                                    secondary={
                                                        <div className={styles.secondaryTextContainer}>
                                                            <span className={styles.secondaryText}>{item.details}</span>
                                                            <span className={styles.secondaryTextRight}>{format(new Date(item.createdOn), 'dd MMM')}</span>
                                                        </div>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton size="small" edge="end" aria-label="open">
                                                        <ArrowForwardIosOutlinedIcon color="primary" />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider variant="inset" />
                                        </>
                                    )
                                })
                            }
                        </List>
                    )}

                </>
            )}


        </>
    )
}

export default Points;





{/* <ListItem>
<ListItemAvatar className={styles.listAvatar}>
    <Avatar className={styles.avatar} src={cartIcon} />
</ListItemAvatar>
<ListItemText
    disableTypography
    primary={
        <div className={styles.primaryTextContainer}>
            <span className={styles.primaryText}>Shopping</span>
            <Avatar className={styles.badge} src={discountIcon} />
            <span className={styles.primaryTextRight}>16</span>
        </div>
    }
    secondary={
        <div className={styles.secondaryTextContainer}>
            <span className={styles.secondaryText}>£112,78 – 36 products</span>
            <span className={styles.secondaryTextRight}>22 May</span>
        </div>
    }
/>
<ListItemSecondaryAction>
    <IconButton size="small" edge="end" aria-label="open">
        <ArrowForwardIosOutlinedIcon color="primary" />
    </IconButton>
</ListItemSecondaryAction>
</ListItem>
<Divider variant="inset" /> */}