import React from "react";
import axios from "axios";

const ImageDownload = ({ sourceUrl, alt, style }) => {
   const { mediaUrl, media } = sourceUrl;
   const downloadImage = async () => {
      try {
         const response = await axios.get(mediaUrl, {
            responseType: "blob",
         });
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement("a");
         link.href = url;
         link.setAttribute("download", media.filename);
         document.body.appendChild(link);
         link.click();
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div>
         <img src={mediaUrl} alt={alt} onClick={downloadImage} className={style} />
      </div>
   );
};

export default ImageDownload;
