import React, {useState, useEffect} from "react";
import styles from "./CustomMultiLineSelect.module.scss";
import Selected from '../../assets/icons/selected.svg'
import TextField from '@material-ui/core/TextField';


const CustomMultiLineSelect = (props) => {
    const { data, active, select } = props;
	const [selected, setSelected] = useState('')
	const [type, setType] = useState(false)
	const [textValue, setTextValue] = useState(false)

	useEffect(() => {
		if(active) {
			setSelected(active)
		}
		setTextValue(active)
	  }, [active]);

	  const selectOption = (single) => {
		if(single.value === 'Let me type...') {
			setType(true)
		}else {
			setSelected(single.value) 
			select(single.value)
		}
		
	  }

    return (
		<div className={styles.multiselect}>
		{!type && <>{data.map((single, index) => 
			<button key={index} onClick={() => selectOption(single)} className={selected === single.value ? styles.active : styles.nonActive}>
				{single.title}
				{selected === single.value && <img src={Selected} className={styles.img} alt="selected" />}
			</button>

		)} </>}
		{type && <>
			<TextField
				fullWidth
				label="Gender"
				variant="outlined"
				onChange={(e) => [setSelected(e.target.value), select(e.target.value)]}
				value={textValue}
            />
			<div className={styles.other} onClick={() => [setSelected(data[0].value), setType(false)]}>select other options</div>
		</>}
		</div>
    )
}


export default CustomMultiLineSelect;