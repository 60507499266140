import React, { useState, useEffect } from "react";
import styles from "./AdditionalResources.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MessageBox from "../../../components/MessageBox/MessageBox";
import Spinner from "../../../components/Spinner/Spinner";
import Divider from "@material-ui/core/Divider";

import { toast,} from '../../../state'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import TextField from "@material-ui/core/TextField";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";
import { addExternalLinksToUser } from "../../../services/firebase";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAppUserQuery } from "../../../services/queries";

const validationSchema = Yup.object().shape({
   title: Yup.string().required("please add a title"),
   url: Yup.string().required("please add a url"),
   image: Yup.string().nullable(),
});

const initialState = {
   title: "",
   url: "",
   image: "",
};

const AdditionalResources = () => {
   const history = useHistory();
   const dispatch = useDispatch()
   const { userId } = useParams();
   const { data: patient, refetch, isLoading: userDataLoading } = getAppUserQuery(userId);
   const [file, setFile] = useState("");
   const [resourceData, setResourceData] = useState([]);
   const [loading, setLoading] = useState(false);

   const goBack = () => {
      history.goBack();
   };

   const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            resolve(reader.result);
         };
         reader.onerror = (error) => reject(error);
      });
   };

   const handleImageChange = async (e, setFieldValue) => {
      const file = e.target.files[0];
      const base64Img = await convertFileToBase64(file);
      setFieldValue("image", base64Img);
      setFile(file.name);
   };

   const handleAddResource = (values) => {
      const newResource = {
         title: values.title,
         url: values.url,
         image: values.image,
      };
      setResourceData([...resourceData, newResource]);
      setFile("");
   };

   const handleSave = async () => {
      setLoading(true);
         try {
            await addExternalLinksToUser(userId, resourceData);
            setLoading(false);
            refetch()
            dispatch(toast({message:'saved succesfully'}))
         } catch (error) {
            setLoading(false);
            dispatch(toast({message:'an error occured, try again'}))
            console.log(error);
         }
      
   };

   const removeItem = (title) => {
    const newResourceData = resourceData.filter((item) => item.title !== title);
    setResourceData(newResourceData);

}

useEffect(()=>{
    if(patient && patient?.additionalResources){
        setResourceData(patient.additionalResources)
    }
}, [patient])

   return (
      <div className={styles.page}>
         <div className={styles.header}>
            <IconButton className={styles.back} size='small' onClick={goBack}>
               <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4.00065 12.3379L20 12.3379' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                  <path
                     d='M11.3693 19.6759L4.00001 12.338L11.3693 5.00003'
                     stroke='#130F26'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
            </IconButton>
            <Avatar className={styles.avater} src={patient && patient?.photoURL} />
            <div>
               <div className={styles.headerTitle}>Additional Resources</div>
               <div className={styles.headerSubText}>{patient && patient?.displayName}</div>
            </div>
         </div>

         <div className={styles.content}>
            <p className={styles.text}>Add External Resource</p>

            <Formik
               validateOnMount
               enableReinitialize
               validationSchema={validationSchema}
               initialValues={initialState}
               onSubmit={(values, { resetForm }) => {
                  handleAddResource(values);
                  resetForm();
               }}
            >
               {({ handleSubmit, errors, touched, setFieldValue, getFieldProps }) => (
                  <>
                     <TextField
                        fullWidth
                        placeholder='add a title'
                        label='add title'
                        variant='outlined'
                        name='title'
                        {...getFieldProps("title")}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        required
                     />
                     <TextField
                        fullWidth
                        multiline
                        label='add article url'
                        placeholder='add url'
                        variant='outlined'
                        name='url'
                        {...getFieldProps("url")}
                        error={Boolean(touched.url && errors.url)}
                        helperText={touched.url && errors.url}
                        required
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position='start'>
                                 <InsertLinkIcon />
                              </InputAdornment>
                           ),
                        }}
                     />

                     <div>
                        {file && (
                           <div className={styles.file}>
                              <p className={styles.filetext}>{file}</p>
                              <IconButton className={styles.delete} size='small' onClick={() => setFile("")}>
                                 <CloseIcon />
                              </IconButton>
                           </div>
                        )}
                        <div className={styles.upload}>
                           <input
                              accept='image/*'
                              className={styles.input}
                              id='icon-button-file'
                              name='image'
                              type='file'
                              hidden
                              onChange={(e) => handleImageChange(e, setFieldValue)}
                              disabled={file}
                           />
                           <label htmlFor='icon-button-file' className={styles.label}>
                              <IconButton color='primary' aria-label='upload picture' component='span'>
                                 <PhotoCamera />
                                 <span className={styles.text}> add image</span>
                              </IconButton>
                           </label>
                        </div>
                     </div>

                     <Button variant='contained' color='primary' onClick={handleSubmit} disabled={Boolean(errors.title || errors.url)}>
                        {"Add Resource"}
                     </Button>
                  </>
               )}
            </Formik>
         </div>

         {resourceData.map((item, i) => (
            <div className={styles.articleContainer} key={item.title + i.toString()}>
                <IconButton className={styles.remove} size='small' onClick={() => removeItem(item.title)}>
                    <CloseIcon />
                </IconButton>
               <ButtonBase className={styles.articleContent}>
                  <div className={styles.articleImage} style={{ backgroundImage: `url(${item.image})` }} />
                  <div className={styles.resourceText}>
                     <div className={styles.articleTitle}>{item.title}</div>
                     <div className={styles.articleReadTime}>
                        <a href={item.url} target='_blank' rel='noopener noreferrer'>
                           {item.url}
                        </a>
                     </div>
                  </div>
               </ButtonBase>
            </div>
         ))}

         {
               resourceData.length === 0 && (
                  <MessageBox text='No resource added yet' />
               )
         }

         {userDataLoading && (
            <div className='px-4 py-4 text-center'>
               <Spinner variant="rect" height={72} />
               <Spinner variant="rect" height={72} />
               <Spinner variant="rect" height={72} />
            </div>
         )}

      

         <div className={styles.doneBtn}>
            <div className={styles.btnCtn}>
               <button className={`${styles.btn} `} onClick={handleSave} >
                  {loading ? <CircularProgress style={{ color: "white", width: "20px", height: "20px" }} /> : "Save changes"}
               </button>
            </div>
         </div>
      </div>
   );
};

export default AdditionalResources;
