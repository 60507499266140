import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getAllArticles, getArticleById, get10Article } from '../services/firebase';

export const getArticles = createAsyncThunk(
    'Articles/getAllArticles', 
    async () => {
        const response = await getAllArticles();
        return response;
    }
)

export const getArticle = createAsyncThunk(
    'Articles/getSingleArticle',
    async (articleId) => {
        const response = await getArticleById(articleId);
        return response;
    }
)

export const get10Articles = createAsyncThunk(
    'Articles/get10Articles',
    async () => {
        const response = await get10Article();
        localStorage.setItem('articles', JSON.stringify(response))
        return response;
    }
)

const initialState = {
    articles: [],
    article: null,
    articlesIsLoading: true,
    articlesIsError: false,
    articleIsLoading: true,
    articleIsError: false,
    onlyArticle: [],
    articles10IsLoading: true,
    articles10IsError: false,
}

export const Articles = createSlice({
    name: 'Articles',
    initialState,
    reducers: {
        setArticle: (state, action) => {
            state.article = action.payload;
        }
    },
    extraReducers: {
        [getArticles.pending]: (state) => {
            state.articlesIsLoading = true;
            state.articlesIsError = false;
        },
        [getArticles.fulfilled]: (state, action) => {
            state.articles = action.payload;
            state.articlesIsLoading = false;
            state.articlesIsError = false;
        },
        [getArticles.rejected]: (state) => {
            state.articlesIsLoading = false;
            state.articlesIsError = true;
        },
        [getArticle.pending]: (state) => {
            state.articleIsLoading = true;
            state.articleIsError = false;
        },
        [getArticle.fulfilled]: (state, action) => {
            state.article = action.payload;
            state.articleIsLoading = false;
            state.articleIsError = false;
        },
        [getArticle.rejected]: (state) => {
            state.articleIsLoading = false;
            state.articleIsError = true;
        },

        [get10Articles.pending]: (state) => {
            let res = JSON.parse(localStorage.getItem('articles'))

            state.articles10IsError = false;
            if(res) {
                state.onlyArticle = res;
                state.articles10IsLoading = false;
            }else {
                state.articles10IsLoading = true;
            }
        },
        [get10Articles.fulfilled]: (state, action) => {
            state.onlyArticle = action.payload;
            state.articles10IsLoading = false;
            state.articles10IsError = false;
        },
        [get10Articles.rejected]: (state) => {
            state.articles10IsLoading = false;
            state.articles10IsError = true;
        }
        }
    })

export const { setArticle } = Articles.actions;
export default Articles.reducer;