import { baseCalendarAPI } from "../../env";
import { getUserDataByEmail } from "./../firebase";
import axios from "axios";
const calendly = axios.create({
   baseURL: "https://api.calendly.com",
   headers: {
      authorization:
         "Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk1MjE0NDk1LCJqdGkiOiIxODJjMmIxZC04OTU5LTRmMjEtYTk4My1lZjRkZGI3M2RlM2YiLCJ1c2VyX3V1aWQiOiJkMjA3ODBmMS1kNzA3LTQzM2QtYmM3Ni02Y2FkMzdkYWFhNTcifQ.V6YbVjcq60fD5o_v7opUuQ0-zvdsnfOqbjbf3906EfamjCakoMgUwMQ4oJD3Phk5J3ATp3pS_w1bGBRpo-DTKA",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
   },
});

export const getCalendarEvent = async (eventId) => {
   let calendarAPI = await fetch(
      `${baseCalendarAPI}/${eventId}`, // ?key=AIzaSyCjr2Y5A9miL3n7pc4YCppu4dm0YAC9cik  HTTP/1.1
      {
         method: "GET",
         headers: new Headers({
            Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
            Accept: "application/json",
         }),
      }
   );
   let responseData = await calendarAPI.json();
   return responseData;
};

export const getAttendees = async (eventId) => {
   let attendeesAPI = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/events@stovecook.com/events/${eventId}`, // ?key=AIzaSyCjr2Y5A9miL3n7pc4YCppu4dm0YAC9cik  HTTP/1.1
      {
         method: "GET",
         headers: new Headers({
            Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
            Accept: "application/json",
         }),
      }
   );
   let responseData = await attendeesAPI.json();
   return responseData;
};

//
export const addEmail = async (attendees, eventId) => {
   let addEmailAPI = await fetch(
      `${baseCalendarAPI}/${eventId}?sendUpdates=all`, // &key=AIzaSyCjr2Y5A9miL3n7pc4YCppu4dm0YAC9cik HTTP/1.1
      {
         method: "PATCH",
         headers: new Headers({
            Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
         }),
         body: `{
        "attendees": ${JSON.stringify(attendees)}
      }`,
      }
   );
   let responseData = await addEmailAPI.json();
   return responseData;
};

//////////////////////////////////////////////////////////////////////////////////////////////

export const getGoogleEvent = async (eventId) => {
   let event = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/events@stovecook.com/events/${eventId}`, {
      headers: {
         Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
   });
   return event.data;
};

export const updateGoogleEventAttendees = async (attendees, eventId) => {
   let event = await axios.patch(
      `https://www.googleapis.com/calendar/v3/calendars/events@stovecook.com/events/${eventId}?sendUpdates=all`,
      {
         attendees: attendees,
      },
      {
         headers: {
            Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
         },
      }
   );
   return event.data;
};

//////////////////////////////////////Calendly////////////////////////////////////////////////////////
export const getDietitianCalendly = async (min_start_date, dietitianEmail) => {
   const calendlyData = await calendly.get("/scheduled_events", {
      params: {
         count: 100,
         organization: "https://api.calendly.com/organizations/b5c46bd3-57cc-40e6-88d1-6a827dfb974b",
         status: "active",
         //2023-09-02T12:30:00.000000Z
         min_start_time: min_start_date,
      },
   });
   calendlyData.data.collection = await Promise.all(
      calendlyData.data.collection.map(async (event) => {
         const userData = event?.event_guests[0]?.email ? await getUserDataByEmail(event?.event_guests[0]?.email) : null;
         return {
            ...event,
            user: userData,
         };
      })
   );

   const filteredData = await calendlyData.data.collection.filter((event) => {
      return event?.event_guests[0]?.email === dietitianEmail;
   });
   const data = await Promise.all(
      filteredData.map(async (event) => {
         const uuid = event?.uri?.split("/").pop();
         if (uuid) {
            const response = await getEvents(uuid);
            const inviteeUserInfo =  response?.data?.collection[0]?.email ? await getUserDataByEmail(response?.data?.collection[0]?.email) : null;
            return {
               ...event,
               invitees: response?.data?.collection,
               inviteeUserInfo: inviteeUserInfo  
            };
         }
      })
   );
   return data;
};

export const getEvents = async (uuid) => {
   const results = await calendly.get(`/scheduled_events/${uuid}/invitees`, {
      params: {
         count: 100,
         organization: "https://api.calendly.com/organizations/b5c46bd3-57cc-40e6-88d1-6a827dfb974b",
      },
   });
   return results;
};

export const cancelCalendlyEvent = async (uuid) => {
   try {
      const response = await calendly.post(`/scheduled_events/${uuid}/cancellation`);
      return response;
   } catch (error) {
      console.log(error, "error");
   }
};
