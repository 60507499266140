import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./Notifications.module.scss";
import NavigationHeader from "../../../../components/NavigationHeader/NavigationHeader";
import NotificationToggle from "../../../../components/NotificationToggle/NotificationToggle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { setNotificationPreferences } from "../../../../state";

import { notifications } from "../../../../constant";

const Notifications = () => {
   const { notificationPreference } = useSelector((state) => state.notification, shallowEqual);

   return (
      <div className={styles.page}>
         <NavigationHeader icon={<MoreVertIcon />} text='Notifications' />
         <div className={styles.container}>
            <h4 className={styles.heading}>Push notifications</h4>
            {notificationPreference.map((notification) => (
               <NotificationToggle notification={notification} preference={notificationPreference} key={notification.slug} />
            ))}
         </div>
      </div>
   );
};

export default Notifications;
