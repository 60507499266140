import React, { useState, useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "./../../layout";
import { toast, setUser } from "./../../state";
import { getUserData } from "../../services/firebase";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import styles from "./Onboarding.module.scss";
import CustomMultiLineSelect from "../../components/CustomerMultiLineSelect/CustomMultiLineSelect";
import TextField from "@material-ui/core/TextField";

import strokeGood from "../../assets/icons/strokeGood.svg";
import { Avatar, Chip, CircularProgress, IconButton } from "@material-ui/core";
import startIMG from "../../assets/pref.svg";
import endIMG from "../../assets/trackIMGEnd.svg";
import cancel from "../../assets/icons/cancelLight.png";
import cancelDark from "../../assets/icons/cancelDark.png";
import { addFoodPreference } from "../../services/firebase";
import { useHistory } from "react-router";
import italy from "../../assets/icons/italy.png";
import unitedStates from "../../assets/icons/unitedStates.png";
import france from "../../assets/icons/france.png";
import unitedKingdom from "../../assets/icons/unitedKingdom.png";
import brazil from "../../assets/icons/brazil.png";
import japan from "../../assets/icons/japan.png";
import india from "../../assets/icons/india.png";
import sweden from "../../assets/icons/sweden.png";
import colombia from "../../assets/icons/colombia.png";
import china from "../../assets/icons/china.png";
import southAfrica from "../../assets/icons/southAfrica.png";
import bangladesh from "../../assets/icons/bangladesh.png";
import thailand from "../../assets/icons/thailand.png";
import germany from "../../assets/icons/germany.png";
import ireland from "../../assets/icons/ireland.png";
import turkey from "../../assets/icons/turkey.png";
import greece from "../../assets/icons/greece.png";
import cuba from "../../assets/icons/cuba.png";
import slide1 from "../../assets/slide1.png";
import slide2 from "../../assets/slide2.png";
import slide3 from "../../assets/slide3.png";
import slide4 from "../../assets/slide4.png";
import MultiPgae from "../MultiStepPage/MultiStepPage";
import CancelIcon from "@material-ui/icons/Cancel";

const Onboarding = () => {
   const [display, setDisplay] = useState({
      header: false,
   });
   const history = useHistory();
   const { currentStage, editing } = useParams();
   const foodRef = useRef();

   const userState = useSelector((state) => state.user, shallowEqual);
   const [loading, setLoading] = useState(false);
   const [start, setStart] = useState(false);
   const [type, setType] = useState("Conditions");
   const dispatch = useDispatch();
   const [stage, setStage] = useState(0);
   const [adults, setAdults] = useState(0);
   const [children, setChildren] = useState(0);
   const [toddlers, setToddlers] = useState(0);
   const [condition, setCondition] = useState("");
   const [add, setAdd] = useState(false);
   const [values, setValues] = useState([]);
   const [historySelected, setHistorySelected] = useState([]);
   const [allergySelected, setAllergySelected] = useState([]);
   const [dislikeSelected, setDislikeSelected] = useState([]);

   const [cuisineSelected, setCuisineSelected] = useState([]);
   const [foodPracticeList] = useState([
      { title: "I’m omnivore", value: "I’m omnivore" },
      { title: "I’m pesceterian", value: "I’m pesceterian" },
      { title: "I’m vegeterian", value: "I’m vegeterian" },
      { title: "I’m ovo-vegeterian", value: "I’m ovo-vegeterian" },
      { title: "I’m lacto-vegeterian", value: "I’m lacto-vegeterian" },
      { title: "I’m vegan", value: "I’m vegan" },
   ]);

   const [foodBudgetList] = useState([
      { title: "£50 - £100", value: "£50 - £100" },
      { title: "£100 - £200", value: "£100 - £200" },
      { title: "£200 - £300", value: "£200 - £300" },
      { title: "£400 - £500", value: "£400 - £500" },
   ]);
   const [cookingTimeList] = useState([
      { title: "0 - 15 mins", value: "0 - 15 mins" },
      { title: "15 - 30 mins", value: "15 - 30 mins" },
      { title: "30 - 60 mins", value: "30 - 60 mins" },
      { title: "More than 1 hour", value: "More than 1 hour" },
      { title: "2 or more hours", value: "2 or more hours" },
   ]);
   const [cuisineList, setCuisineList] = useState([
      { title: "ITA", value: "ITA", flag: italy },
      { title: "FRA", value: "FRA", flag: france },
      { title: "GBR", value: "GBR", flag: unitedKingdom },
      { title: "USA", value: "USA", flag: unitedStates },
      { title: "BRA", value: "BRA", flag: brazil },
      { title: "JPN", value: "JPN", flag: japan },
      { title: "IND", value: "IND", flag: india },
      { title: "SWE", value: "SWE", flag: sweden },
      { title: "COL", value: "COL", flag: colombia },
      { title: "CHN", value: "CHN", flag: china },
      { title: "ZAF", value: "ZAF", flag: southAfrica },
      { title: "BGD", value: "BGD", flag: bangladesh },
      { title: "THA", value: "THA", flag: thailand },
      { title: "DEU", value: "DEU", flag: germany },
      { title: "IRL", value: "IRL", flag: ireland },
      { title: "TUR", value: "TUR", flag: turkey },
      { title: "GRC", value: "GRC", flag: greece },
      { title: "CUB", value: "CUB", flag: cuba },
   ]);
   const [historyList, setHistoryList] = useState([
      "Diabetes",
      "Hypertension",
      "Heart Disease",
      "Blood Clots",
      "Osteoporosis",
      "Lupus",
      "Cholesterol",
      "Lyme disease",
      "Asthma",
      "Hyperthyroidism",
      "Hypothyroidism",
      "Acid Reflux",
   ]);
   const [allergyList, setAllergyList] = useState([
      "Soybeans",
      "Eggs",
      "Milk",
      "Fish",
      "Crustacean Shellfish",
      "Tree Nuts",
      "Peanuts",
      "Wheat",
      "Gluten",
      "Celary",
      "Mustard",
      "Sesame",
      "Sulfur Dioxide",
      "Lupin",
      "Molluscs",
   ]);
   const [dislikeList, setDislikeList] = useState([
      "Turmeric",
      "Brussels sprout",
      "Cabbage",
      "Beet",
      "Cumin",
      "Turnip",
      "Radishes",
      "Fig",
      "Pepper",
      "Aubergine",
      "Lychee",
      "Kiwi",
      "Garlic",
      "Rocket salad",
      "Lupin",
      "White fish",
   ]);
   const [foodBudget, setFoodBudget] = useState(foodBudgetList[0].value);
   const [cookingTime, setCookingTime] = useState(cookingTimeList[0].value);
   const [foodPractice, setFoodPractice] = useState(foodPracticeList[0].value);

   const moveTo = useCallback((path) => history.push(path));

   const [slideData] = React.useState([
      { img: slide1, title: "Welcome to Stove", sub: "Say hello to a happier, healthier you!" },
      {
         img: slide2,
         title: "Speak to a Registered Dietitian",
         sub: "Our dietitians are here to provide you with personalised nutrition support. Get actionable content and support from a regulated professional in a safe space.",
      },
      {
         img: slide3,
         title: "Cook tasty, easy to prepare recipes",
         sub: "Use our AI to generate recipes or meals plans using any product for any occasion. We also have 100's of recipes, created by chefs.",
      },
      {
         img: slide4,
         title: "Track your food and symptoms seamlessly",
         sub: "Log what you’ve eaten and how you’re feeling with ease. Between sessions your dietitian can review and track how you’re progressing towards your goals.",
      },
   ]);

   const addCondition = () => {
      if (values.length > 0) {
         // let newAdded = condition.toLowerCase().split(",");
         if (stage === 4) {
            setHistoryList([...historyList, ...values]);
            setHistorySelected([...historySelected, ...values]);
         } else if (stage === 5) {
            setAllergyList([...allergyList, ...values]);
            setAllergySelected([...allergySelected, ...values]);
         } else if (stage === 6) {
            setDislikeList([...dislikeList, ...values]);
            setDislikeSelected([...dislikeSelected, ...values]);
         } else if (stage === 8) {
            // let newData = [];
            // for (let i = 0; i < newAdded.length; i++) {
            //    newData.push({ title: newAdded[i], value: newAdded[i], flag: null });
            // }
            setCuisineList([...cuisineList, ...values]);
            setCuisineSelected([...cuisineSelected, ...values]);
         }

         // setCondition("");
         setValues([]);
      }
      setAdd(false);
   };

   const handleSaveCondition = () => {
      if (condition) {
         // Trim the input value and split it by commas
         const newValues = condition.toLowerCase().trim().split(",");
         // Filter out empty strings and add new values to the existing list
         setValues((prevValues) => [...prevValues, ...newValues.map((val) => val.trim()).filter((val) => val !== "")]);
         // Clear the input field
         setCondition("");
      }
   };

   const handleDelete = (selected) => {
      setValues((values) => values.filter((value) => value !== selected));
   };

   const submit = async () => {
      setLoading(true);
      let payload = {
         adults,
         children,
         toddlers,
         foodBudget,
         cookingTime,
         healthCondition: historySelected,
         allergies: allergySelected,
         dislikes: dislikeSelected,
         foodPractice,
         cuisine: cuisineSelected,
      };
      try {
         if (userState?.user) {
            await addFoodPreference(userState?.user?.uid, payload);
            const getData = await getUserData(userState?.user?.uid);
            dispatch(setUser(getData));
            if (!editing && !userState?.user?.isPremuimUser) {
               moveTo('/success');
            } else if (!editing && userState?.user?.isPremuimUser && !userState.user?.quizData){
               moveTo('/track')
            } else if (!editing && userState?.user?.isPremuimUser && userState.user?.quizData){
               setStage(9)
            }
            setLoading(false);
            editing === "isEditing" && history.goBack();
         }
      } catch (error) {
         dispatch(toast({ message: error.message }));
         setLoading(false);
      }
   };

   useEffect(() => {
      if (currentStage) {
         setAdd(false);
         // setStart(true);
         setStage(Number(currentStage));
      }
   }, [currentStage]);

   useEffect(() => {
      const data = userState?.user?.foodPreference;
      if (data && editing === "isEditing") {
         data?.adults && setAdults(data.adults);
         data?.children && setChildren(data.children);
         data?.toddlers && setToddlers(data?.toddlers);
         data?.foodBudget && setFoodBudget(data?.foodBudget);
         data?.cookingTime && setCookingTime(data?.cookingTime);
         data?.foodPractice && setFoodPractice(data?.foodPractice);
         data?.cuisine && setCuisineSelected(data.cuisine);

         if (data?.healthCondition) {
            setHistorySelected(data.healthCondition);
            const combinedArray = [
               ...new Set([...historyList.map((str) => str.toLowerCase()), ...data.healthCondition.map((str) => str.toLowerCase())]),
            ];
            setHistoryList(combinedArray);
         }
         if (data?.allergies) {
            setAllergySelected(data.allergies);
            const combinedArray = [...new Set([...allergyList.map((str) => str.toLowerCase()), ...data.allergies.map((str) => str.toLowerCase())])];
            setAllergyList(combinedArray);
         }
         if (data?.dislikes) {
            setDislikeSelected(data.dislikes);
            const combinedArray = [...new Set([...dislikeList.map((str) => str.toLowerCase()), ...data.dislikes.map((str) => str.toLowerCase())])];
            setDislikeList(combinedArray);
         }
      }
   }, [editing, userState.user?.foodPreference]);

   useEffect(() => {
      // Scroll to the top of the quiz component
      foodRef.current && foodRef.current.scrollTo(0, 0);
   }, [stage]);


   return (
      <Layout header={display.header} spacer={false} footer={userState.user?.foodPreference ? true : false}>
         <div className={styles.page} ref={foodRef}>
                  {!display.header && (
                     <div className={styles.header} style={{ backgroundColor: stage === 0 || stage === 9 ? "white" : "#00866E" }}>
                        <div className={styles.space}></div>
                        <div className={styles.back}>
                           
                            {(stage === 0 || stage === 9 ) ? (
                            userState.user?.foodPreference ? <img src={cancelDark} alt='cancel' onClick={() => moveTo("/")} /> : <div></div>
                           ) : (
                              editing ? <img src={cancel} alt='cancel' onClick={() => history.goBack()} /> : null
                           )}

                           {stage !== 9 && (
                              <div style={{ color: stage === 0 ? "#130F26" : "white" }}>
                                 {stage < 9 ? "Food preferences" : "Food preferences sent!"}
                              </div>
                           )}
                           {stage < 9 && <div>{stage}/8</div>}
                           {stage >= 9 && <div></div>}
                        </div>
                        <div className={styles.down}></div>
                     </div>
                  )}
                  <div className={styles.main}>
                     {add ? (
                        <div className={styles.others}>
                           <div className={styles.title}>Add Other {type}</div>
                           <div className={styles.sub}>Write everything else you want to add, separating each thing by a comma.</div>
                           {values.length > 0 && (
                              <div className={styles.otherConditionsContainer}>
                                 {values.map((textValue) => {
                                    return (
                                       <Chip
                                          // clickable
                                          key={textValue}
                                          label={textValue}
                                          className={styles.chip}
                                          onDelete={() => handleDelete(textValue)}
                                          deleteIcon={<CancelIcon />}
                                       />
                                    );
                                 })}
                              </div>
                           )}
                           <TextField
                              fullWidth
                              multiline
                              label={type === "Dislikes" ? "Write food name" : 'Write your condition'}
                              placeholder={type === "Dislikes" ? "Write food name" : 'Write your condition'}
                              variant="standard"
                              InputProps={{
                                 disableUnderline: true,
                              }}
                              name='url'
                              className={styles.addInput}
                              value={condition}
                              onChange={(e) => setCondition(e.target.value)}
                           />
                           <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                              {/* <button className={styles.prev} onClick={() => setStage((prev) => (prev - 1))}> <img src={strokeGood} alt="prev" /> </button> */}
                              <button
                                 style={{ width: "152px" }}
                                 className={styles.nextCenter}
                                 onClick={condition ? handleSaveCondition : addCondition}
                              >
                                 {condition ? "Save Values" : "Done"}
                              </button>
                           </div>
                        </div>
                     ) : (
                        <>
                           {stage === 0 && (
                              <>
                                 <div className={styles.imgBox}>
                                    <img src={startIMG} alt='woman' />
                                    <div>
                                       <div className={styles.text}>Let's personalise your food experience!</div>
                                       <div className={styles.subText}>
                                          Not sure what to make within your budget that actually tastes good? Struggling to use up leftover
                                          ingredients Answer a few questions and get recipes you can actually cook and want to eat!.
                                       </div>
                                    </div>
                                    <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                                       <button
                                          style={{ width: "296px" }}
                                          className={styles.nextCenter}
                                          onClick={() => [setStage((prev) => prev + 1), setDisplay((display) => ({ ...display, header: false }))]}
                                       >
                                          {" "}
                                          Start questionnaire • 7 steps{" "}
                                       </button>
                                       {userState.user?.foodPreference && (
                                          <div className={styles.more} onClick={() => moveTo("/")}>
                                             Not now, skip it {">"}
                                          </div>
                                       )}
                                    </div>
                                 </div>
                              </>
                           )}

                           {stage === 1 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Household size</div>
                                 <div className={styles.sub}>How many people do you buy food and/or cook for?</div>
                                 <div className={styles.line}></div>

                                 <div className={styles.both}>
                                    <div className={styles.name}>Adults</div>
                                    <div className={styles.calculator}>
                                       <IconButton color='inherit' onClick={() => (adults <= 0 ? null : setAdults((prev) => prev - 1))}>
                                          <RemoveIcon
                                             fontSize='small'
                                             color='primary'
                                             
                                          />
                                       </IconButton>
                                       <span className={styles.number}>{adults}</span>
                                       <IconButton color='primary' onClick={() => setAdults((prev) => prev + 1)}>
                                          <AddIcon fontSize='small' color='inherit'  />
                                       </IconButton>
                                    </div>
                                 </div>

                                 <div className={styles.line}></div>

                                 <div className={styles.both}>
                                    <div className={styles.name}>Children</div>
                                    <div className={styles.calculator}>
                                       <IconButton color='inherit' onClick={() => (children <= 0 ? null : setChildren((prev) => prev - 1))}>
                                          <RemoveIcon
                                             fontSize='small'
                                             color='primary'
                                             
                                          />
                                       </IconButton>
                                       <span className={styles.number}>{children}</span>
                                       <IconButton color='primary' onClick={() => setChildren((prev) => prev + 1)} >
                                          <AddIcon fontSize='small' color='inherit' />
                                       </IconButton>
                                    </div>
                                 </div>

                                 <div className={styles.line}></div>

                                 <div className={styles.both}>
                                    <div className={styles.name}>Toddlers</div>
                                    <div className={styles.calculator}>
                                       <IconButton color='inherit' onClick={() => (toddlers <= 0 ? null : setToddlers((prev) => prev - 1))}>
                                          <RemoveIcon
                                             fontSize='small'
                                             color='primary'
                                             
                                          />
                                       </IconButton>
                                       <span className={styles.number}>{toddlers}</span>
                                       <IconButton color='primary' onClick={() => setToddlers((prev) => prev + 1)}>
                                          <AddIcon fontSize='small' color='inherit'  />
                                       </IconButton>
                                    </div>
                                 </div>

                                 <div className={styles.line}></div>
                              </div>
                           )}

                           {stage === 2 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Weekly Food Budget</div>
                                 <div className={styles.sub}>How much do you spend per person?</div>
                                 <CustomMultiLineSelect
                                    select={(selected) => setFoodBudget(selected)}
                                    active={foodBudget ? foodBudget : foodBudgetList[0].value}
                                    data={foodBudgetList}
                                 />
                              </div>
                           )}

                           {stage === 3 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Cooking time</div>
                                 <div className={styles.sub}>How long do you usually cook for in minutes per meal?</div>
                                 <CustomMultiLineSelect
                                    select={(selected) => setCookingTime(selected)}
                                    active={cookingTime ? cookingTime : cookingTimeList[0].value}
                                    data={cookingTimeList}
                                 />
                              </div>
                           )}

                           {stage === 4 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Health Conditions</div>
                                 <div className={styles.sub}>Do you have particular health conditions?</div>
                                 {historyList.map((value) => {
                                    return (
                                       <Chip
                                          clickable
                                          key={value}
                                          label={value}
                                          className={historySelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                          onClick={() =>
                                             historySelected.includes(value.toLowerCase())
                                                ? setHistorySelected(historySelected.filter((e) => e !== value.toLowerCase()))
                                                : setHistorySelected((prev) => [...prev, value.toLowerCase()])
                                          }
                                       />
                                    );
                                 })}
                                 <Chip
                                    clickable
                                    label='Add Others...'
                                    className={styles.addChip}
                                    onClick={() => [setAdd(true), setType("Conditions")]}
                                 />
                              </div>
                           )}

                           {stage === 5 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Allergies</div>
                                 <div className={styles.sub}>
                                    Do you have any allergies? We’re not going to fill your home page with recipes or products using those
                                    ingredients.
                                 </div>
                                 {allergyList.map((value) => {
                                    return (
                                       <Chip
                                          key={value}
                                          clickable
                                          label={value}
                                          className={allergySelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                          onClick={() =>
                                             allergySelected.includes(value.toLowerCase())
                                                ? setAllergySelected(allergySelected.filter((e) => e !== value.toLowerCase()))
                                                : setAllergySelected((prev) => [...prev, value.toLowerCase()])
                                          }
                                       />
                                    );
                                 })}
                                 <Chip
                                    clickable
                                    label='Add Others...'
                                    className={styles.addChip}
                                    onClick={() => [setAdd(true), setType("Allergies")]}
                                 />
                              </div>
                           )}

                           {stage === 6 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Dislikes</div>
                                 <div className={styles.sub}>What foods do you avoid?</div>
                                 {dislikeList.map((value) => {
                                    return (
                                       <Chip
                                          key={value}
                                          clickable
                                          label={value}
                                          className={dislikeSelected.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                          onClick={() =>
                                             dislikeSelected.includes(value.toLowerCase())
                                                ? setDislikeSelected(dislikeSelected.filter((e) => e !== value.toLowerCase()))
                                                : setDislikeSelected((prev) => [...prev, value.toLowerCase()])
                                          }
                                       />
                                    );
                                 })}
                                 <Chip
                                    clickable
                                    label='Add Others...'
                                    className={styles.addChip}
                                    onClick={() => [setAdd(true), setType("Dislikes")]}
                                 />
                              </div>
                           )}

                           {stage === 7 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Food practice</div>
                                 <div className={styles.sub}>Do you follow any of these diets?</div>
                                 <CustomMultiLineSelect
                                    select={(selected) => setFoodPractice(selected)}
                                    active={foodPractice ? foodPractice : foodPracticeList[0].value}
                                    data={foodPracticeList}
                                 />
                              </div>
                           )}

                           {stage === 8 && (
                              <div className={styles.pad}>
                                 <div className={styles.title}>Favourite Cuisines</div>
                                 <div className={styles.sub}>Which are your favourite cuisines around the globe?</div>
                                 {cuisineList.map((value) => {
                                    return (
                                       <>
                                          {value.flag ? (
                                             <Chip
                                                key={value.value}
                                                avatar={<Avatar alt={value.value} src={value.flag} />}
                                                clickable
                                                label={value.value}
                                                className={cuisineSelected.includes(value.value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                                onClick={() =>
                                                   cuisineSelected.includes(value.value.toLowerCase())
                                                      ? setCuisineSelected(cuisineSelected.filter((e) => e !== value.value.toLowerCase()))
                                                      : setCuisineSelected((prev) => [...prev, value.value.toLowerCase()])
                                                }
                                             />
                                          ) : (
                                             <Chip
                                                key={value.value}
                                                clickable
                                                label={value.value}
                                                className={cuisineSelected.includes(value.value.toLowerCase()) ? styles.chipSelected : styles.chip}
                                                onClick={() =>
                                                   cuisineSelected.includes(value.value.toLowerCase())
                                                      ? setCuisineSelected(cuisineSelected.filter((e) => e !== value.value.toLowerCase()))
                                                      : setCuisineSelected((prev) => [...prev, value.value.toLowerCase()])
                                                }
                                             />
                                          )}
                                       </>
                                    );
                                 })}
                                 {/* <Chip
                                    clickable
                                    label='Add Others...'
                                    className={styles.addChip}
                                    onClick={() => [setAdd(true), setType("Cuisines")]}
                                 /> */}
                              </div>
                           )}

                           {stage === 9 && (
                              <div className={styles.pad}>
                                 <div className={styles.imgBox}>
                                    <img src={endIMG} alt='end' style={{ width: "180px" }} />
                                    <div>
                                       <div className={styles.text}>Yeah, all done!</div>
                                       <div className={styles.subText}>
                                          You can now create personalised recipes with just a click! But just before you jump in, take a moment to
                                          discover how to make the most of your Stove experience.
                                       </div>
                                    </div>

                                    <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                                       <button
                                          style={{ width: "232px" }}
                                          className={styles.nextCenter}
                                          onClick={() => moveTo("/")}
                                       >
                                          Explore
                                       </button>
                                       {/* <div className={styles.more} onClick={() => moveTo("/")}>
                                          
                                          Go to Home{" >"}
                                       </div> */}
                                    </div>
                                 </div>
                              </div>
                           )}

                           {/* {stage === 9 && userState?.user?.isPremuim && (
                              <div className={styles.pad}>
                                 <div className={styles.imgBox}>
                                    <img src={endIMG} alt='end' style={{ width: "180px" }} />
                                    <div>
                                       <div className={styles.text}>Yeah, all done!</div>
                                       <div className={styles.subText}>
                                          You can now create personalised recipes with just a click! But just before you jump in, take a moment to
                                          discover how to make the most of your Stove experience.
                                       </div>
                                    </div>

                                    <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                                       <button
                                          style={{ width: "232px" }}
                                          className={styles.nextCenter}
                                          onClick={() => {
                                             window.location.hostname.includes("app.stovecook")
                                                ? moveTo("/article/MVmQlDM9I2570ZJZxgIY")
                                                : moveTo("/suggested-articles");
                                          }}
                                       >
                                          Explore
                                       </button>
                                       <div className={styles.more} onClick={() => moveTo("/")}>
                                          Skip{" >"}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           )} */}

                           {stage > 0 && stage < 9 && editing !== "isEditing" && (
                              <div className={styles.bottomButton}>
                                 {stage > 1 && (
                                    <button className={styles.prev} onClick={() => setStage((prev) => prev - 1)}>
                                       {" "}
                                       <img src={strokeGood} alt='prev' />{" "}
                                    </button>
                                 )}
                                 {stage === 1 && (
                                    <button className={styles.nextCenter} onClick={() => setStage((prev) => prev + 1)}>
                                       {" "}
                                       {loading ? (
                                          <CircularProgress size={20} disableShrink color='inherit' />
                                       ) : (
                                          <>{stage === 8 ? "Send" : "Next"} </>
                                       )}
                                    </button>
                                 )}
                                 {stage !== 1 && (
                                    <button
                                       className={styles.next}
                                       onClick={() => {
                                          if (stage > 0 && stage < 9) {
                                             if (stage === 8) {
                                                submit();
                                             } else {
                                                setStage((prev) => prev + 1);
                                             }
                                          }
                                       }}
                                    >
                                       {" "}
                                       {loading ? (
                                          <CircularProgress size={20} disableShrink color='inherit' />
                                       ) : (
                                          <>{stage === 8 ? "Send" : "Next"} </>
                                       )}
                                    </button>
                                 )}
                              </div>
                           )}

                           {editing === "isEditing" && stage > 0 && stage < 9 && (
                              <div className={styles.bottomButton}>
                                 <button className={styles.nextCenter} onClick={() => submit()}>
                                    {loading ? <CircularProgress size={20} disableShrink color='inherit' /> : <span>Update</span>}
                                 </button>
                              </div>
                           )}
                        </>
                     )}
                  </div>
                  <div style={{ height: "400px" }}></div>
         </div>
      </Layout>
   );
};

export default Onboarding;
