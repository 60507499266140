import React, { useState, useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import styles from "./EditDateTimeModal.module.scss";
import MultipleNutrientsBar from "../../../components/NutrientsBar/MultipleNutrientsBar";
import { DateTimePicker } from "../../../components/DateTimePicker/DateTimePicker";
import CustomTextArea from "../../../components/CustomTextArea/CustomTextArea";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import Div100vh from "react-div-100vh";
import modalHandle from "../../../assets/modal-handle.png";
import DeleteIcon from "@material-ui/icons/Delete";
import cancel from "../../../assets/cancel-btn.svg";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { CircularProgress } from "@material-ui/core";
import { format } from "date-fns";
import {
  updateUserEventsFood,
  deleteUserEvents,
  getUserData,
} from "../../../services/firebase";
import { toast, setUser } from "../../../state";

dayjs.extend(customParseFormat);
const today = dayjs();

const isEventEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const EditDateTimeModal = ({ selectedEvent, onClose, open }) => {
  const [currentDate, setCurrentDate] = useState(today);
  const [currentTime, setCurrentTime] = useState(today);
  const [description, setDescription] = useState("");
  const [foodItems, setFoodItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const userState = useSelector((state) => state.user, shallowEqual);

  const dispatch = useDispatch();

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteUserEvents(selectedEvent.uid, selectedEvent.dateCreated);
      const userData = await getUserData(userState.user?.uid);
      userData && dispatch(setUser(userData));
      dispatch(toast({ message: "deleted successfully" }));
      setLoading(false);
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      dispatch(toast({ message: "an error occurred deleting event" }));
    }
  };

  const getFoodType = (time) => {
    if (time >= 0 && time <= 11) {
      return "Breakfast";
    } else if (time >= 12 && time <= 17) {
      return "Lunch";
    } else if (time >= 18 && time <= 23) {
      return "Dinner";
    }
  };

  const submit = async () => {
    setLoading(true);
    const payload = {
      date: format(currentDate.$d, "yyyy-MM-dd"),
      time: format(currentTime.$d, "hh:mm a"),
      hour: format(currentTime.$d, "H"),
      minute: format(currentTime.$d, "mm"),
      id: currentDate.format("ddd D MMM"),
      items: foodItems,
      description: description,
    };
    try {
      await updateUserEventsFood(
        selectedEvent.uid,
        payload,
        selectedEvent.dateCreated
      );
      const userData = await getUserData(userState.user?.uid);
      userData && dispatch(setUser(userData));
      dispatch(toast({ message: "Changed successfully" }));
      setLoading(false);
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      setLoading(false);
      dispatch(toast({ message: "An error occurred saving changes" }));
    }
  };

  useEffect(() => {
    if (!isEventEmpty(selectedEvent)) {
      setCurrentDate(formatDate(selectedEvent.date));
      setCurrentTime(formatTime(selectedEvent.food?.time));
      setDescription(selectedEvent.food?.description);
      setFoodItems(selectedEvent.food?.items);
      if (selectedEvent.food?.items) {
        const foodItems = selectedEvent.food.items.map((item) => item.Item);
        setSelectedItems(foodItems);
      }
    }
  }, [selectedEvent]);

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  return (
    <>
      {open && (
        <div
          className={`${styles.modal} ${open ? styles.open : ""}`}
          onClick={onClose}
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalHeader}>
              <img src={modalHandle} alt="" />
            </div>
            <div className={styles.modalHeaderIcon2}>
              <CloseOutlinedIcon onClick={onClose} />
              <DeleteIcon fontSize="small" onClick={handleDelete} />
            </div>

            <div className={styles.modalBody}>
              <p className={styles.text}>
                {selectedEvent.tag === "Snack"
                  ? selectedEvent.tag
                  : getFoodType(Number(selectedEvent.food?.hour))}{" "}
                Details
              </p>
              <DateTimePicker
                selectedTime={currentTime}
                setSelectedTime={setCurrentTime}
                selectedDate={currentDate}
                setSelectedDate={setCurrentDate}
              />
              {/* items */}
              <div className={styles.items}>
                <p>Items</p>
                <div
                  className={`${styles.dataContainer} ${styles.disableScrollbars}`}
                >
                  {foodItems &&
                    foodItems.map((element, i) => (
                      <div
                        className={`${styles.dataBox} ${styles.add}`}
                        key={i}
                      >
                        <div className={styles.dataContent}>
                          <div className={styles.dataText}>
                            <h6 className={styles.dataTextHeading}>
                              {element.Item.name}
                            </h6>
                            <div>
                              <p className={styles.text}>
                                {element.Item.quantity}
                              </p>
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              foodItems.length === 1
                                ? onClose()
                                : setFoodItems(
                                    foodItems.filter((e) => e.id !== element.id)
                                  )
                            }
                          >
                            <img src={cancel} alt="add-button" />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <>
                <MultipleNutrientsBar
                  key={0}
                  selectedMeals={selectedItems}
                  nutrients={
                    userState.user?.nutrients?.show
                      ? userState.user?.nutrients
                      : {
                          show: false,
                          carbs: 0,
                          fat: 0,
                          proteins: 0,
                          calories: 0,
                        }
                  }
                />
              </>
              {/* Notes */}
              <div className={styles.notesCtn}>
                <p>Notes</p>
                <CustomTextArea
                  maxRows={8}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="You can add notes for a more detailed description of your meal."
                />
              </div>

              {/* button */}
              <div className={styles.doneBtn}>
                <div className={styles.btnCtn}>
                  <button className={`${styles.btn} `} onClick={submit}>
                    {loading ? (
                      <CircularProgress
                        style={{
                          color: "white",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditDateTimeModal;

const formatTime = (value) => {
  const time = dayjs(value, "hh:mm a");
  return time;
};

const formatDate = (value) => {
  const date = dayjs(value, "YYYY-MM-DD");
  return date;
};
