import React, { useEffect, useState } from "react";
import { useDispatch, shallowEqual, useSelector, } from "react-redux";
import { openEvents } from "../../../state";

import styles from "./Modal.module.scss";
import modalHandle from "../../../assets/modal-handle.png";
import breakfast from "../../../assets/breakfast.svg";
import lunch from "../../../assets/lunch.svg";
import dinner from "../../../assets/dinner.svg";
import snack from "../../../assets/snack.svg";
import weight from "../../../assets/weight.svg";
import symptoms from "../../../assets/symptomsIcon.svg";
import Box from "@material-ui/core/Box";
import advertImage from "../../../assets/ad-dietitian.png";
import { useHistory } from "react-router";

export const Modal = ({ open, onClose }) => {
   const [type, setType] = useState("AI");
   const dispatch = useDispatch();
   const history = useHistory();
   const { user } = useSelector((state) => state.user, shallowEqual);
   const moveTo = (path) => history.push(path);

   const events = [
      {
         name: "Meals",
         image: lunch,
      },
      {
         name: "Weight",
         image: weight,
      },
      {
         name: "Symptoms",
         image: symptoms,
      },
   ];

   const handleTypeChange = () => {
      setType(type === "AI" ? "Manual" : "AI");
   };

   const openAIEvent = (eventType) => {
      const event = eventType === 'Meals' ? 'food' : eventType
      moveTo(`/gpt/${event.toLowerCase()}`)
   }

   // const getMealTime = () => {
   //    const currentHour = dayjs().hour();
    
   //    if (currentHour >= 7 && currentHour < 12) {
   //      return 'Breakfast';
   //    } else if (currentHour >= 12 && currentHour < 18) {
   //      return 'Lunch';
   //    } else {
   //      return 'Dinner';
   //    }
   //  }

   const openEvent = (eventType) => {
      if (eventType === "Meals") {
         dispatch(
            openEvents({
               currentEventType: eventType,
               showModal: true,
               isModalOpen: false,
               showWeightModal: false,
               showSymptomsModal: false,
            })
         );
      } else if (eventType === "Weight") {
         dispatch(
            openEvents({
               currentEventType: eventType,
               showModal: false,
               isModalOpen: false,
               showWeightModal: true,
               showSymptomsModal: false,
            })
         );
      } else if (eventType === "Symptoms") {
         dispatch(
            openEvents({
               currentEventType: eventType,
               showModal: false,
               isModalOpen: false,
               showWeightModal: false,
               showSymptomsModal: true,
            })
         );
      }
   };

   useEffect(() => {
      open ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
   }, [open]);

   return (
      <div className={`${styles.modal} ${open ? styles.open : ""}`} onClick={onClose}>
         <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            {type === "AI" && (
               <>
                  <div className={styles.modalHeader}>
                     <img src={modalHandle} alt='' />
                     <p className={styles.text}>Add</p>
                  </div>
                  <div className={styles.modalBody}>
                     {events.map((event, i) => (
                        <div className={styles.imgCtn} key={i}>
                           <div className={styles.img} onClick={() => openAIEvent(event.name)}>
                              <img src={event.image} alt={""} />
                              <p className={styles.text}>{event.name}</p>
                           </div>
                        </div>
                     ))}
                  </div>
                  <div className={styles.addManual} onClick={handleTypeChange}>
                     <span className={styles.text}> or add Manually</span>
                  </div>
               </>
            )}

            {type === "Manual" && (
               <>
                  <div className={styles.modalHeader}>
                     <img src={modalHandle} alt='' />
                     <p className={styles.text}>Add Manually</p>
                  </div>
                  <div className={styles.modalBody}>
                     {events.map((event, i) => (
                        <div className={styles.imgCtn} key={i}>
                           <div className={styles.img} onClick={() => openEvent(event.name)}>
                              <img src={event.image} alt={""} />
                              <p className={styles.text}>{event.name}</p>
                           </div>
                        </div>
                     ))}
                  </div>
                  <div className={styles.addManual} onClick={handleTypeChange}>
                     <span className={styles.text}> or add with AI</span>
                  </div>
               </>
            )}

            {!user?.isPremuimUser && <div className={styles.advert} onClick={() => history.push("/dietitian")}>
               <Box className={styles.content}>
                  <div className={styles.textContent}>
                     <p className={styles.title}>Book a dietitian</p>
                     <p className={styles.subTitle}>Speak to a nutrition expert for only £25 p/m</p>
                  </div>
                  <img src={advertImage} alt='Book a dietitian' className={styles.advertImage} />
               </Box>
            </div>}
         </div>
      </div>
   );
};
