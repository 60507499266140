import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Dialog from "@material-ui/core/Dialog";
import CustomDialog from "../../../../components/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import formatRelative from "date-fns/formatRelative";
import { useLocation } from "react-router-dom";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import ChatIcon from "@material-ui/icons/Chat";
import Checkbox from "@material-ui/core/Checkbox";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import BlockIcon from "@material-ui/icons/Block";

import backImg from "./../../../../assets/icons/back.png";
import smileImg from "./../../../../assets/icons/smile.png";
// import paperClipImg from "./../../../../assets/icons/paper-clip.png";
import wallPaperImg from "./../../../../assets/icons/wall-paper.png";
import sendImg from "./../../../../assets/send.png";

import ImageDownload from "../../../../components/ImageDownload/ImageDownload";

import { limitTextLength } from "../../../../utilities";

import {
   getSingleChat,
   addMember,
   removeMember,
   createNotifications,
   updateReadMessageByUserId,
   getUserData,
   updateReadMessageByGroupId,
} from "./../../../../services/firebase";
import {
   toast,
   setActiveConversation,
   getAllPuplicChat,
   getNotifications,
   getUserUnreadGroupMessages,
   getAllUnreadIndividualNotifications,
} from "./../../../../state";
import {
   getPublicConversation,
   joinPuplicConversation,
   createPrivateConversation,
   createPrivateConversationWithProfile,
   getConversationsMessage,
   checkProfile,
   sendBroadCastMessage,
   updateConfigurationService,
} from "./../../../../services/twilio";
import styles from "./Conversation.module.scss";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import AnimateElement from "../../../../components/AnimateElement";

const Conversation = (props) => {
   const { url, chatState, userState, twilioClient } = props;
   const dispatch = useDispatch();
   const history = useHistory();
   const location = useLocation();

   const scrollContentRef = useRef(null);
   const thumbnailRef = useRef(null);
   const formRef = useRef(null);
   const inputRef = useRef(null);

   const [chatHeader, setChatheader] = useState(null);
   const [chatConversationHeaderIsOpen, setChatConversationHeaderIsOpen] = useState(false);
   const [text, setText] = useState("");
   const [thumbnail, setThumbnail] = useState(null);
   const [emoji, setEmoji] = useState("")
   const [conversation, setConversation] = useState(null);
   const [conversationMessages, setConversationMessages] = useState([]);
   const [messageSending, setMessageSending] = useState(false);
   const [forbidden, setForbidden] = useState(false);
   const [isJoining, setIsJoining] = useState(false);
   const [selectedMessage, setSelectedMessage] = useState(null);

   const [participantsDialogIsOpen, setParticipantsDialogIsOpen] = useState(false);
   const [participants, setParticipants] = useState([]);
   const [participantsIsLoading, setParticipantsIsLoading] = useState(false);
   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
   const [showEmojiReaction, setShowEmojiReaction] = useState(false)
   const [reply, setReply] = useState(null);
   const [replyType, setReplyType] = useState(null);
   const [anchorEl, setAnchorEl] = useState(null);
   const [anchorEl2, setAnchorEl2] = useState(null);
   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

   const [checked, setChecked] = useState(true);

   const sendNotification = async (payload) => {
      const recipient = chatHeader?.uid || chatHeader?.identity;
      try {
         if (userState.user && recipient) {
            const notification = {
               body: payload.body,
               created: new Date().getTime(),
               receiverId: recipient,
               title: "",
               sender: userState.user.displayName,
               senderImage: userState.user.photoURL,
               senderId: userState.user.uid,
               messageRead: false,
               chatType: "individual",
               thumbnail: payload.thumbnail,
            };
            await createNotifications(notification);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const sendGroupNotifications = async (payload) => {
      try {
         if (userState.user && chatHeader?.chatId) {
            const groupParticipants = chatHeader.membersId.map((member) => {
               if (member !== userState.user.uid)
                  return {
                     userId: member,
                     messageRead: false,
                  };
            });
            const filteredArray = groupParticipants.filter((value) => value !== undefined);
            const notification = {
               body: payload.body,
               created: new Date().getTime(),
               receiverId: chatHeader?.chatId, //groupchatId
               title: "",
               sender: userState.user.displayName,
               senderImage: userState.user.photoURL,
               senderId: userState.user.uid,
               chatType: "group",
               participants: filteredArray,
               thumbnail: payload.thumbnail,
            };
            await createNotifications(notification);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const getUserNotificationPreference = async () => {
      const recipient = chatHeader?.uid || chatHeader?.identity;
      if (recipient) {
         const userData = await getUserData(recipient);
         if (userData?.notificationPreferences) {
            return userData.notificationPreferences;
         }
      }
   };

   const getUpdatedConversationsMessage = async () => {
      try {
         if (conversation) {
            const [, enhancedMessages] = await getConversationsMessage(conversation);
            setConversationMessages(enhancedMessages);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const handleDeleteMessage = async () => {
      if (selectedMessage) {
         try {
            if (selectedMessage.type === "media") {
               await selectedMessage.updateAttributes({
                  ...selectedMessage.attributes,
                  deleted: true,
                  body: "you deleted this message",
               });
            } else if (selectedMessage.type === "text") {
               await selectedMessage.updateBody("This message was deleted");
               await selectedMessage.updateAttributes({
                  ...selectedMessage.attributes,
                  deleted: true,
               });
            }
         } catch (error) {
            console.log(error);
            dispatch(toast({ message: "an arror occured" }));
         }
         setSelectedMessage(null);
      }
   };

   const handleAddEmojiToConversations = async (event) => {
      if (selectedMessage) {
        console.log(event.native, 'eve', selectedMessage)
         try {
            await selectedMessage.updateAttributes({
               ...selectedMessage.attributes,
               emoji: event.native,
            });
         } catch (error) {
            console.log(error);
            dispatch(toast({ message: "an arror occured adding emoji" }));
         }
         setSelectedMessage(null);
      }
   }

   useEffect(() => {
      if (selectedMessage) {
         selectedMessage.removeListener("updated", getUpdatedConversationsMessage);
         selectedMessage.on("updated", getUpdatedConversationsMessage);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedMessage]);

   const handleClick = (event, msg) => {
      setAnchorEl(event.currentTarget);
      setSelectedMessage(msg);
   };

   useEffect(() => {
      (async () => {
         if (chatState.activeConversation && twilioClient) {
            if (location?.pathname?.split("/")[3]) {
               console.log("location", location?.pathname?.split("/")[3]);
               handleJoinConversation();
            }
            try {
               let chatHeader;
               let activeConversation;
               if (chatState.activeConversation.chatId) {
                  chatHeader = chatState.activeConversation;
                  activeConversation = await getPublicConversation(twilioClient, chatState.activeConversation, userState.user.uid);
               } else if (chatState.activeConversation.uid) {
                  await checkProfile(twilioClient, chatState.activeConversation);
                  chatHeader = chatState.activeConversation;
                  activeConversation = await createPrivateConversationWithProfile(twilioClient, userState.user, chatState.activeConversation);
               } else {
                  activeConversation = chatState.activeConversation;
                  if (activeConversation?.attributes?.isPrivate === false) {
                     chatHeader = await getSingleChat(activeConversation.uniqueName);
                  }
                  if (activeConversation?.attributes?.isPrivate === true) {
                     chatHeader = getParticipant(activeConversation?.attributes, userState.user.uid);
                  }
               }
               setChatheader(chatHeader);
               if (activeConversation === "Forbidden") {
                  setForbidden(true);
               } else {
                  const [conversation, enhancedMessages] = await getConversationsMessage(activeConversation);
                  setConversation(conversation);
                  setConversationMessages(enhancedMessages);
                  setTimeout(() => {
                     scrollContentRef.current && scrollContentRef.current.scrollIntoView();
                  }, 0);
               }
            } catch (error) {
               dispatch(toast({ message: error.message }));
            }
         } else {
            history.push(`${url}/messsages`);
         }
      })();
   }, [chatState.activeConversation, dispatch, history, twilioClient, url, userState.user]);

   const broadCastMessage = async (title, body, conversation) => {
      try {
         const recipient = chatHeader?.uid || chatHeader?.identity;
         const recipientPreferences = await getUserNotificationPreference();
         if (recipientPreferences) {
            if (recipientPreferences[0].status) {
               await sendBroadCastMessage(title, body, [recipient]);
            }
         }
      } catch (error) {
         console.log("broadCastMessage Not sent:", error.message);
      }
   };

   const broadCastGroupMessage = async (memberInfo, title, body) => {
      try {
         const membersId = [];
         const participants = memberInfo?.membersIdData;

         participants &&
            participants.forEach((participant) => {
               if (participant.uid !== userState.user.uid) {
                  if (participant.notificationPreferences) {
                     if (participant.notificationPreferences[1].status) {
                        membersId.push(participant.uid);
                     }
                  }
               }
            });
         if (membersId.length > 0) {
            await sendBroadCastMessage(title, body, membersId);
         }
      } catch (error) {
         console.log("broadCastGroupMessage Not sent:", error.message);
      }
   };

   useEffect(() => {
      if (conversation) {
         const messageAdded = async function (message) {
            try {
               if (Boolean(chatHeader?.uid || chatHeader?.identity)) {
                  const messageBody = message.type === "media" ? message.attributes.body : message.body;
                  broadCastMessage(userState.user.displayName, messageBody, conversation);
               }

               if (chatHeader?.chatId) {
                  const title = chatHeader.uniqueName;
                  const messageBody = message.type === "media" ? message.attributes.body : message.body;
                  const body = `${userState.user.displayName}: ${messageBody}`;
                  broadCastGroupMessage(chatHeader, title, body);
               }

               if (message.type === "media") {
                  message["mediaUrl"] = await message.media.getContentTemporaryUrl();
               }

               setConversationMessages((prevState) => prevState.concat(message));
               setTimeout(() => {
                  scrollContentRef.current && scrollContentRef.current.scrollIntoView();
               }, 0);
            } catch (error) {
               dispatch(toast({ message: "Failed to update messages" }));
            }
         };

         conversation.removeListener("messageAdded", messageAdded);
         conversation.on("messageAdded", messageAdded);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userState.user.displayName, conversation, dispatch]);

   useEffect(() => {
      const participantId = chatHeader?.uid || chatHeader?.identity;
      const updateNotification = async () => {
         if (participantId && userState.user?.uid) {
            try {
               await updateReadMessageByUserId(userState.user.uid, participantId);
               dispatch(getAllUnreadIndividualNotifications(userState.user.uid));
               dispatch(getNotifications(userState.user.uid));
            } catch (error) {
               console.log(error);
            }
         }

         if (chatHeader?.chatId && userState.user?.uid) {
            try {
               await updateReadMessageByGroupId(chatHeader.chatId, userState.user.uid);
               dispatch(getUserUnreadGroupMessages(userState.user.uid));
               dispatch(getNotifications(userState.user.uid));
            } catch (error) {
               console.log(error);
            }
         }
      };
      updateNotification();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userState.user, chatHeader]);

   const goBack = () => {
      history.goBack();
   };

   const selectImage = (e) => {
      if (e.target.files && e.target.files[0]) {
         let reader = new FileReader();
         reader.onload = function (event) {
            setThumbnail(event.target.result);
         };
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const sendMessage = async (conversation, text) => {
      try {
         setMessageSending(true);
         if (thumbnail) {
            const formData = new FormData();
            formData.append("file", thumbnailRef.current.files[0]);
            await conversation.sendMessage(formData, {
               body: text,
               uid: userState.user.uid,
               creator: userState.user.displayName,
               replyTo: reply,
            });
         } else if (text) {
            await conversation.sendMessage(text, {
               uid: userState.user.uid,
               creator: userState.user.displayName,
               replyTo: reply,
            });
         }
         setThumbnail(null);
         setText("");
         setReply(null);
         setReplyType(null);
         setMessageSending(false);
         setShowEmojiPicker(false);
         formRef.current.reset();
      } catch (error) {
         setMessageSending(false);
         dispatch(toast({ message: error.message }));
      }
   };

   const messageParticipant = async (participant) => {
      try {
         setChatheader(null);
         setConversation(null);
         setConversationMessages([]);
         setParticipants([]);
         setThumbnail(null);
         setText("");
         setMessageSending(false);
         setParticipantsDialogIsOpen(false);
         const conversation = await createPrivateConversation(twilioClient, userState.user, participant);
         dispatch(setActiveConversation(conversation));
      } catch (error) {
         dispatch(toast({ message: "Failed to load message" }));
      }
   };

   const addEmoji = (event) => {
      setText(text + event.native);
   };

   const addEmojiReaction = (event) => {
      handleAddEmojiToConversations(event)
      setShowEmojiReaction(false)
   }

   const scrollToBottom = () => {
      const timer = setTimeout(() => {
         window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: "smooth",
         });
      }, 0);
      return () => clearTimeout(timer);
   };

   const handleJoinConversation = async () => {
      if (chatState.activeConversation && chatState.activeConversation.chatId && twilioClient) {
         try {
            setIsJoining(true);
            const activeConversation = await joinPuplicConversation(twilioClient, chatState.activeConversation, userState.user.uid);
            const [conversation, enhancedMessages] = await getConversationsMessage(activeConversation);
            await addMember(chatState.activeConversation.chatId, userState.user.uid);
            const chatHeader = await getSingleChat(chatState.activeConversation.chatId);
            dispatch(getAllPuplicChat());
            setChatheader(chatHeader);
            setConversation(conversation);
            setConversationMessages(enhancedMessages);
            setForbidden(false);
            setIsJoining(false);
            setTimeout(() => {
               scrollContentRef.current && scrollContentRef.current.scrollIntoView();
            }, 0);
         } catch (error) {
            setForbidden(true);
            setIsJoining(false);
            dispatch(toast({ message: error.message }));
         }
      }
   };

   const showParticipants = async () => {
      setChatConversationHeaderIsOpen(null);
      if (conversation) {
         setParticipantsIsLoading(true);
         setParticipantsDialogIsOpen(true);
         try {
            const participants = await conversation.getParticipants();
            const participantsDetails = await Promise.all(participants.map((participant) => participant.getUser()));
            setParticipants(participantsDetails);
            setParticipantsIsLoading(false);
         } catch (error) {
            dispatch(toast({ message: "Failed to load members" }));
            setParticipants([]);
            setParticipantsIsLoading(false);
         }
      }
   };

   const handleLeaveConversation = async () => {
      setChatConversationHeaderIsOpen(null);
      if (chatHeader.chatId && twilioClient && conversation) {
         try {
            await conversation.leave();
            setConversation(null);
            setConversationMessages([]);
            setParticipants([]);
            await removeMember(chatHeader.chatId, userState.user.uid);
            dispatch(getAllPuplicChat());
            dispatch(setActiveConversation(chatHeader));
            setTimeout(() => {
               scrollContentRef.current && scrollContentRef.current.scrollIntoView();
            }, 0);
         } catch (error) {
            dispatch(toast({ message: error.message }));
         }
      }
   };

   const checkHttpUrl = (string) => {
      let givenURL;
      try {
         givenURL = new URL(string);
      } catch (error) {
         return false;
      }
      return givenURL.protocol === "http:" || givenURL.protocol === "https:";
   };

   const formatChatText = (text) => {
      const words = text.split(" ");
      return (
         <>
            {words.map((word, index) => {
               return checkHttpUrl(word) ? (
                  <a href={word} target='_blank' rel='noopener noreferrer' key={word + index.toString()}>
                     {word}
                  </a>
               ) : (
                  word + " "
               );
            })}
         </>
      );
   };

   const handleOnOffGroupNotification = (event) => {
      setChecked(event.target.checked);
   };

   return (
      <Div100vh>
         <div className={styles.page}>
            <div className={styles.headerContainer}>
               <IconButton size='small' className={styles.icon} onClick={goBack}>
                  <img alt='Back' src={backImg} className={styles.backIcon} />
               </IconButton>
               {!chatHeader ? (
                  <ListItem className={styles.listItem}>
                     <ListItemAvatar className={styles.listAvatar}>
                        <AnimateElement duration={"0.5"}>
                           <Avatar className={styles.avatar}>
                              <PersonIcon />
                           </Avatar>
                        </AnimateElement>
                     </ListItemAvatar>
                     <ListItemText disableTypography primary={<div className={styles.primaryText}>Loading...</div>} />
                  </ListItem>
               ) : (
                  <>
                     {chatHeader.chatId && (
                        <>
                           <ListItem className={styles.listItem}>
                              <ListItemAvatar className={styles.listAvatar}>
                                 <AnimateElement duration={"0.5"}>
                                    <Avatar className={styles.avatar} src={chatHeader.thumbnail}>
                                       <PersonIcon />
                                    </Avatar>
                                 </AnimateElement>
                              </ListItemAvatar>
                              <ListItemText
                                 disableTypography
                                 primary={<div className={styles.primaryText}>{chatHeader.uniqueName}</div>}
                                 secondary={
                                    <div className={styles.secondaryText}>
                                       {chatHeader.membersId?.length || 0} {chatHeader.membersId?.length > 1 ? "members" : "member"}
                                    </div>
                                 }
                              />
                           </ListItem>
                           {conversation && (
                              <>
                                 <IconButton
                                    size='small'
                                    className={styles.icon}
                                    onClick={(event) => setChatConversationHeaderIsOpen(event.currentTarget)}
                                 >
                                    <MoreVertIcon />
                                 </IconButton>
                                 <Menu
                                    anchorEl={chatConversationHeaderIsOpen}
                                    anchorOrigin={{
                                       vertical: "bottom",
                                       horizontal: "left",
                                    }}
                                    transformOrigin={{
                                       vertical: "top",
                                       horizontal: "right",
                                    }}
                                    open={Boolean(chatConversationHeaderIsOpen)}
                                    onClose={() => setChatConversationHeaderIsOpen(null)}
                                 >
                                    <MenuItem onClick={showParticipants}>Members</MenuItem>
                                    <MenuItem onClick={handleLeaveConversation}>Leave</MenuItem>
                                    <MenuItem>
                                       Mute notifications
                                       <Checkbox
                                          defaultChecked
                                          color='primary'
                                          inputProps={{ "aria-label": "secondary checkbox" }}
                                          checked={checked}
                                          onChange={handleOnOffGroupNotification}
                                          size='small'
                                       />
                                    </MenuItem>
                                 </Menu>
                              </>
                           )}
                        </>
                     )}
                     {(chatHeader.uid || chatHeader.identity) && (
                        <>
                           <ListItem className={styles.listItem}>
                              <ListItemAvatar className={styles.listAvatar}>
                                 <AnimateElement duration={"0.5"}>
                                    <Avatar className={styles.avatar} src={chatHeader.thumbnail}>
                                       <PersonIcon />
                                    </Avatar>
                                 </AnimateElement>
                              </ListItemAvatar>
                              <ListItemText
                                 disableTypography
                                 primary={<div className={styles.primaryText}>{chatHeader.displayName || chatHeader.name}</div>}
                                 secondary={<div className={styles.secondaryText}></div>}
                              />
                           </ListItem>
                        </>
                     )}
                  </>
               )}
            </div>

            <div className={styles.chatContent}>
               {!conversation && forbidden === false ? (
                  <div className='px-4 pt-4 text-center'>
                     <CircularProgress size={15} disableShrink />
                  </div>
               ) : (
                  <>
                     {conversationMessages.map((message, index) => {
                        if (message.attributes.uid === userState.user.uid) {
                           if (message.type === "media") {
                              return (
                                 <React.Fragment key={message.sid}>
                                    <span className={styles.timeStamp}>{formatRelative(new Date(message.dateCreated), new Date())}</span>
                                    <div key={index} className={styles.userChatContainer}>
                                       {message.attributes?.deleted ? (
                                          <>
                                             <div className={styles.deleted}>
                                                <BlockIcon style={{ color: "#623f3f" }} /> {formatChatText(message.attributes.body)}
                                             </div>
                                          </>
                                       ) : (
                                          <>
                                             <div className={styles.dropdown} onClick={(event) => handleClick(event, message)}>
                                                <IconButton id={message.sid} aria-label='delete' style={{ color: "#623f3f" }}>
                                                   <AddSharpIcon />
                                                </IconButton>
                                             </div>
                                             <Menu
                                                id={message.sid}
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={() => setAnchorEl(null)}
                                                elevation={0}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{
                                                   vertical: "bottom",
                                                   horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                   vertical: "top",
                                                   horizontal: "center",
                                                }}
                                             >
                                                <MenuItem onClick={() => [setOpenDeleteDialog(true), setAnchorEl(null)]}>Delete</MenuItem>
                                             </Menu>
                                             <CustomDialog
                                                open={Boolean(openDeleteDialog)}
                                                message={`Confirm you want to delete message?`}
                                                onCancel={() => [setOpenDeleteDialog(false)]}
                                                onConfirm={() => [handleDeleteMessage(message), setOpenDeleteDialog(false)]}
                                                isLoading={false}
                                                isLoadingText='No Loading'
                                             />
                                             {message.attributes.replyTo && (
                                                <>
                                                   {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "text" && (
                                                      <div className={styles.replyMessage}>
                                                         {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.body}
                                                      </div>
                                                   )}
                                                   {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "media" && (
                                                      <div className={styles.imgShow}>
                                                         <img
                                                            alt='Thumbnail'
                                                            src={conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.mediaUrl}
                                                            className={styles.smallImg}
                                                         />

                                                         <div className={styles.textSide}>
                                                            {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.attributes?.body}
                                                         </div>
                                                      </div>
                                                   )}
                                                </>
                                             )}
                                             <img alt='Thumbnail' src={message.mediaUrl} className={styles.userChatThumbnail} />
                                             {message.attributes.body && <div className={styles.userChatText}>{message.attributes.body}</div>}
                                             <div onClick={() => [setReply(message.sid), setReplyType(message.type)]} className={styles.reply}>
                                                reply
                                             </div>
                                          </>
                                       )}
                                    </div>
                                 </React.Fragment>
                              );
                           }
                           return (
                              <React.Fragment key={message.sid}>
                                 <span className={styles.timeStamp}>{formatRelative(new Date(message.dateCreated), new Date())}</span>
                                 <div key={index} className={styles.userChatContainer}>
                                    {message.attributes?.deleted ? (
                                       <>
                                          <div className={styles.deleted}>
                                             <BlockIcon style={{ color: "#623f3f" }} /> {formatChatText(message.body)}
                                          </div>
                                       </>
                                    ) : (
                                       <>
                                          <div className={styles.dropdown} onClick={(event) => handleClick(event, message)}>
                                             <IconButton id={message.sid} aria-label='delete' style={{ color: "#623f3f" }}>
                                                <AddSharpIcon />
                                             </IconButton>
                                          </div>
                                          <Menu
                                             id={message.sid}
                                             anchorEl={anchorEl}
                                             open={Boolean(anchorEl)}
                                             onClose={() => setAnchorEl(null)}
                                             getContentAnchorEl={null}
                                             elevation={0}
                                             anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                             }}
                                             transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                             }}
                                          >
                                             <MenuItem onClick={() => [setOpenDeleteDialog(true), setAnchorEl(null)]}>Delete</MenuItem>
                                             {/* <MenuItem onClick={() => [setShowEmojiReaction(!showEmojiReaction), setShowEmojiPicker(false), setAnchorEl(null)]}>React</MenuItem> */}
                                          </Menu>
                                          <CustomDialog
                                             open={Boolean(openDeleteDialog)}
                                             message={`Confirm you want to delete message?`}
                                             onCancel={() => [setOpenDeleteDialog(false)]}
                                             onConfirm={() => [handleDeleteMessage(message), setOpenDeleteDialog(false)]}
                                             isLoading={false}
                                             isLoadingText='No Loading'
                                          />
                                          {message.attributes.replyTo && (
                                             <>
                                                {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "text" && (
                                                   <div className={styles.replyMessage}>
                                                      {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.body}
                                                   </div>
                                                )}
                                                {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "media" && (
                                                   <div className={styles.imgShow}>
                                                      <img
                                                         alt='Thumbnail'
                                                         src={conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.mediaUrl}
                                                         className={styles.smallImg}
                                                      />{" "}
                                                      <div className={styles.textSide}>
                                                         {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.attributes?.body}
                                                      </div>
                                                   </div>
                                                )}
                                             </>
                                          )}
                                          <div className={styles.userChatText}>
                                            {message.attributes?.emoji ? <span className={styles.emojiText}>{message.attributes?.emoji}</span> : null}
                                            {formatChatText(message.body)}
                                            </div>
                                          <div onClick={() => [setReply(message.sid), setReplyType(message.type)]} className={styles.reply}>
                                             reply
                                          </div>{" "}
                                       </>
                                    )}
                                 </div>
                              </React.Fragment>
                           );
                        } else {
                           if (message.type === "media") {
                              return (
                                 <React.Fragment key={message.sid}>
                                    <span className={styles.timeStamp}>{formatRelative(new Date(message.dateCreated), new Date())}</span>
                                    <div key={index} className={styles.notUserChatContainer}>
                                       {message.attributes?.deleted ? (
                                          <>
                                             <div className={styles.deleted}>
                                                <BlockIcon style={{ color: "#623f3f" }} /> {formatChatText(message.attributes.body)}
                                             </div>
                                          </>
                                       ) : (
                                          <>
                                             {message.attributes.replyTo && (
                                                <>
                                                   {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "text" && (
                                                      <div className={styles.replyMessage}>
                                                         {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.body}
                                                      </div>
                                                   )}
                                                   {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "media" && (
                                                      <div className={styles.imgShow}>
                                                         <img
                                                            alt='Thumbnail'
                                                            src={conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.mediaUrl}
                                                            className={styles.smallImg}
                                                         />{" "}
                                                         <div className={styles.textSide}>
                                                            {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.attributes?.body}
                                                         </div>
                                                      </div>
                                                   )}
                                                </>
                                             )}
                                             <div className={styles.notUserChatName}>{message.attributes.creator}</div>
                                             <ImageDownload sourceUrl={message} alt='Thumbnail' style={styles.notUserChatThumbnail} />
                                             {/* <img alt='Thumbnail' src={message.mediaUrl} className={styles.notUserChatThumbnail} onClick={() => handleImageDownload(message)} /> */}
                                             {message.attributes.body && <div className={styles.notUserChatText}>{message.attributes.body}</div>}
                                             <div onClick={() => [setReply(message.sid), setReplyType(message.type)]} className={styles.reply}>
                                                reply
                                             </div>
                                          </>
                                       )}
                                    </div>
                                 </React.Fragment>
                              );
                           }
                           return (
                              <React.Fragment key={message.sid}>
                                 <span className={styles.timeStamp}>{formatRelative(new Date(message.dateCreated), new Date())}</span>
                                 <div key={index} className={styles.notUserChatContainer}>
                                    {message.attributes?.deleted ? (
                                       <>
                                          <div className={styles.deleted}>
                                             <BlockIcon style={{ color: "#623f3f" }} /> {formatChatText(message.body)}
                                          </div>
                                       </>
                                    ) : (
                                       <>
                                          {/* <div className={styles.dropdown2} onClick={(event) => {setAnchorEl2(event.currentTarget); setSelectedMessage(message)}}> */}
                                          <div className={styles.dropdown2} onClick={(event) => {setAnchorEl2(event.currentTarget); setSelectedMessage(message); setShowEmojiReaction(!showEmojiReaction); setShowEmojiPicker(false)}}>
                                             <IconButton id={message.sid} aria-label='React' style={{ color: "#623f3f" }}>
                                                <AddSharpIcon />
                                             </IconButton>
                                          </div>
                                          {/* <Menu
                                             id={message.sid}
                                             anchorEl={anchorEl2}
                                             open={Boolean(anchorEl2)}
                                             onClose={() => setAnchorEl2(null)}
                                             getContentAnchorEl={null}
                                             elevation={0}
                                             anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                             }}
                                             transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                             }}
                                          >
                                             <MenuItem onClick={() => [setShowEmojiReaction(!showEmojiReaction), setShowEmojiPicker(false), setAnchorEl2(null)]}>React</MenuItem>
                                          </Menu> */}
                    
                                         
                                          {message.attributes.replyTo && (
                                             <>
                                                {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "text" && (
                                                   <div className={styles.replyMessage}>
                                                      {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.body}
                                                   </div>
                                                )}
                                                {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.type === "media" && (
                                                   <div className={styles.imgShow}>
                                                      <img
                                                         alt='Thumbnail'
                                                         src={conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.mediaUrl}
                                                         className={styles.smallImg}
                                                      />{" "}
                                                      <div className={styles.textSide}>
                                                         {conversationMessages.find((x) => x.sid === message.attributes.replyTo)?.attributes?.body}
                                                      </div>
                                                   </div>
                                                )}
                                             </>
                                          )}
                                          <div className={styles.notUserChatName}>{message.attributes.creator}</div>
                                          <div className={styles.notUserChatText}>
                                             {message.attributes?.emoji ? <span className={styles.emojiText}>{message.attributes?.emoji}</span> : null}
                                            {formatChatText(message.body)}</div>
                                          <div onClick={() => [setReply(message.sid), setReplyType(message.type)]} className={styles.reply}>
                                             reply
                                          </div>
                                       </>
                                    )}
                                 </div>
                              </React.Fragment>
                           );
                        }
                     })}
                  </>
               )}
               <div ref={scrollContentRef} />
            </div>

            <div className={styles.footer}>
               {forbidden ? (
                  <ButtonBase className={styles.joinConversionButton} disabled={isJoining} onClick={handleJoinConversation}>
                     {isJoining ? "Joining..." : "Join this group"}
                  </ButtonBase>
               ) : (
                  <>
                     {reply && (
                        <div className={styles.replyMessageDown}>
                           <IconButton size='small' onClick={() => setReply(null)} className={styles.close}>
                              <CloseOutlinedIcon className={styles.icon} fontSize={"inherit"} />
                           </IconButton>
                           {replyType === "text" && (
                              <div className={styles.textReply}>{limitTextLength(conversationMessages.find((x) => x.sid === reply)?.body, 50)}</div>
                           )}
                           {replyType === "media" && (
                              <>
                                 <div className={styles.imgShow}>
                                    <img
                                       alt='Thumbnail'
                                       src={conversationMessages.find((x) => x.sid === reply)?.mediaUrl}
                                       className={styles.smallImg}
                                    />
                                    <div className={styles.textSide}>
                                       {limitTextLength(conversationMessages.find((x) => x.sid === reply)?.attributes?.body, 50)}
                                    </div>
                                 </div>
                              </>
                           )}
                        </div>
                     )}
                     <div className={styles.actionCtn}>
                        <IconButton
                           size='small'
                           className={styles.actionIcon}
                           onClick={() => [setShowEmojiPicker(!showEmojiPicker), scrollToBottom()]}
                        >
                           <Avatar alt='Emoji' variant='square' src={smileImg} className={styles.icon} />
                        </IconButton>
                        <form
                           className={styles.form}
                           ref={formRef}
                           onSubmit={(event) => {
                              event.preventDefault();
                           }}
                        >
                           <InputBase
                              multiline={true}
                              maxRows={5}
                              fullWidth
                              placeholder='Write a message'
                              value={text}
                              onFocus={() => setShowEmojiPicker(false)}
                              ref={inputRef}
                              onChange={(event) => setText(event.target.value)}
                              className={styles.input}
                           />
                        </form>
                        <IconButton size='small' className={styles.actionIcon}>
                           <label className={`m-0 ${styles.selectBoth}`}>
                              {thumbnail ? (
                                 <Avatar alt='Thumbnail' variant='square' src={thumbnail} className={styles.icon} />
                              ) : (
                                 <Avatar alt='Thumbnail' variant='square' src={wallPaperImg} className={`${styles.icon} ${styles.iconImg}`} />
                              )}
                              <input ref={thumbnailRef} onChange={selectImage} accept='image/*' type='file' className={styles.fileSelect} />
                           </label>
                        </IconButton>

                        {(text.trim().length > 0 || thumbnail) && (
                           <ButtonBase
                              disabled={!conversation || messageSending}
                              onClick={() => {
                                 sendMessage(conversation, text);
                                 const payload = {
                                    body: text ? text : "",
                                    thumbnail: thumbnail ? thumbnail : "",
                                 };
                                 if (chatHeader?.chatId) {
                                    sendGroupNotifications(payload);
                                 } else {
                                    sendNotification(payload);
                                 }
                              }}
                              className={styles.send}
                           >
                              {messageSending ? (
                                 <CircularProgress size={15} disableShrink color='inherit' />
                              ) : (
                                 <Avatar alt='Send' variant='square' src={sendImg} className={styles.iconSend} />
                              )}
                           </ButtonBase>
                        )}
                        
                     </div>
                     {showEmojiPicker && (
                           <div className={styles.emojiReact}>
                              <Picker
                                 searchPosition='none'
                                 onClickOutside={() => setShowEmojiPicker(false)}
                                 data={data}
                                 onEmojiSelect={(event) => addEmoji(event)}
                                 autoFocus={false}
                                 title=''
                              />{" "}
                           </div>
                        )}
                     {showEmojiReaction && (
                           <div className={styles.emojiReact}>
                              <Picker
                                 searchPosition='none'
                                 onClickOutside={() => setShowEmojiReaction(false)}
                                 data={data}
                                 onEmojiSelect={(event) => addEmojiReaction(event)}
                                 autoFocus={false}
                                 title=''
                                 dynamicWidth={true}
                                // perLine={6}
                              />{" "}
                           </div>
                     )}
                  </>
               )}
            </div>
         </div>
         {
            <Dialog open={participantsDialogIsOpen} onClose={() => setParticipantsDialogIsOpen(false)} fullWidth maxWidth='xs'>
               <DialogTitle>Members</DialogTitle>
               {participantsIsLoading ? (
                  <div className='p-4 text-center'>
                     <CircularProgress size={15} disableShrink />
                  </div>
               ) : (
                  <List className={styles.list} disablePadding>
                     {participants.map((participant) => {
                        return (
                           <ListItem key={participant.identity} className={styles.listItem} divider>
                              <ListItemAvatar className={styles.listAvatar}>
                                 <Avatar className={styles.avatar} src={participant.attributes.thumbnail}>
                                    <PersonIcon />
                                 </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                 primary={
                                    <div className={styles.primaryTextContainer}>
                                       <span className={styles.primaryTextleft}>{participant.friendlyName}</span>
                                    </div>
                                 }
                              />
                              {participant.identity !== userState.user.uid && (
                                 <ListItemSecondaryAction>
                                    <IconButton onClick={() => messageParticipant(participant)} edge='end' aria-label='Message'>
                                       <ChatIcon color='primary' />
                                    </IconButton>
                                 </ListItemSecondaryAction>
                              )}
                           </ListItem>
                        );
                     })}
                  </List>
               )}
            </Dialog>
         }
      </Div100vh>
   );
};

export default Conversation;

const getParticipant = (attributes, uid) => {
   for (const property in attributes) {
      if (property !== uid && property !== "isPrivate") {
         return attributes[property];
      }
   }
};
