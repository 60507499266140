import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch
} from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

import CustomInput from "../../components/CustomInput/CustomInput";
import Messages from "./components/Messages/Messages";
import Search from "./components/Search/Search";
import Conversation from "./components/Conversation/Conversation";

import { toast, getAllPuplicChat } from "./../../state";
import { createChat, checkIfChatExist } from "./../../services/firebase";

import styles from "./Chat.module.scss";


const validationSchema = Yup.object().shape({
    thumbnail: Yup.string().required("Picture is required").nullable(),
    uniqueName: Yup.string().required('Group name is required'),
});


const Chat = (props) => {
    const { twilioClient, conversationsReady, privateConversationsIsLoading, privateConversations } = props;
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const userState = useSelector(state => state.user, shallowEqual);
    const chatState = useSelector(state => state.chat, shallowEqual);

    return (
        <>
            <Switch>
                <Route path={`${path}/messsages`} exact>
                    <Messages
                        url={url}
                        setDialogIsOpen={setDialogIsOpen}
                        userState={userState}
                        chatState={chatState}
                        conversationsReady={conversationsReady}
                        privateConversationsIsLoading={privateConversationsIsLoading}
                        privateConversations={privateConversations}

                    />
                </Route>
                <Route path={`${path}/search`} exact>
                    <Search
                        url={url}
                        userState={userState}
                        chatState={chatState}
                        conversationsReady={conversationsReady}
                    />
                </Route>
                <Route path={`${path}/search/:groupName`} exact>
                    <Search
                        url={url}
                        userState={userState}
                        chatState={chatState}
                        conversationsReady={conversationsReady}
                    />
                </Route>
                <Route path={`${path}/conversation`} exact >
                    <Conversation
                        url={url}
                        twilioClient={twilioClient}
                        chatState={chatState}
                        userState={userState}
                    />
                </Route>

                <Route path={`${path}/conversation/auto-join`} exact >
                    <Conversation
                        url={url}
                        twilioClient={twilioClient}
                        chatState={chatState}
                        userState={userState}
                    />
                </Route>
                <Redirect to={`${path}/messsages`} />
            </Switch>
            {dialogIsOpen && (
                <Dialog className={styles.dialog} disableBackdropClick disableEscapeKeyDown open={dialogIsOpen} fullWidth fullScreen maxWidth="sm">
                    <Formik
                        validateOnMount
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={{
                            thumbnail: null,
                            uniqueName: "",

                        }}
                        onSubmit={async (values, FormikBag) => {
                            try {
                                const chatExist = await checkIfChatExist(values.uniqueName);
                                if (chatExist) {
                                    dispatch(toast({ message: "Group name already exists" }));
                                    FormikBag.setSubmitting(false);
                                    return
                                }
                                await createChat({
                                    ...values,
                                    uid: userState.user.uid
                                })
                                dispatch(getAllPuplicChat());
                                dispatch(toast({ message: "Group created successfully" }));
                                FormikBag.setSubmitting(false);
                                setDialogIsOpen(false);
                            } catch (error) {
                                FormikBag.setSubmitting(false);
                                dispatch(toast({ message: error.message }));
                            }
                        }}
                    >
                        {(formik) => (
                            <>
                                <div className={styles.header}>
                                    <CloseIcon onClick={() => setDialogIsOpen(false)} />
                                    <DialogTitle>New Public Group</DialogTitle>
                                </div>

                                <DialogContent>
                                    <Grid container spacing={4}>
                                        <Grid xs={12} item>
                                            <div>
                                                {formik.values.thumbnail ? (
                                                    <div className={styles.thumbnail} style={{ backgroundImage: `url(${formik.values.thumbnail})` }}>
                                                        <label>
                                                            <PhotoCameraOutlinedIcon color="primary" className={styles.editIcon} />
                                                            <input
                                                                hidden
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    let reader = new FileReader();
                                                                    reader.onload = function (event) {
                                                                        formik.setFieldValue("thumbnail", event.target.result)
                                                                    };
                                                                    reader.readAsDataURL(e.target.files[0]);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <div className={styles.thumbnail} style={{ backgroundImage: `url(${formik.values.thumbnail})` }}>
                                                        <label>
                                                            <PhotoCameraOutlinedIcon color="primary" className={styles.editIcon} />
                                                            <input
                                                                hidden
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    let reader = new FileReader();
                                                                    reader.onload = function (event) {
                                                                        formik.setFieldValue("thumbnail", event.target.result)
                                                                    };
                                                                    reader.readAsDataURL(e.target.files[0]);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <CustomInput
                                                placeholder="Enter group name"
                                                {...formik.getFieldProps('uniqueName')}
                                                error={Boolean(formik.touched.uniqueName && formik.errors.uniqueName)}
                                                helperText={formik.touched.uniqueName && formik.errors.uniqueName}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className={styles.dialogAction} >
                                    {/* <Button disabled={formik.isSubmitting} onClick={() => setDialogIsOpen(false)}>
                                        Cancel
                                     </Button> */}
                                    <Button disabled={formik.isSubmitting} onClick={formik.handleSubmit} variant="contained" color="primary" className={styles.button}>
                                        {formik.isSubmitting ? <CircularProgress size={20} disableShrink color="inherit" /> : <DoneIcon fontSize="small" />}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Formik>
                </Dialog>
            )}
        </>
    )
}

export default Chat;