import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';


import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import discountIcon from "./../../../../assets/icons/discount.png";

import { getSingleGrocery } from "../../../../services/firebase";

import styles from "./GroceryWidgetUpload.module.scss";


const colors = [
    {
        textColor: "#656462",
        backgroundColor: "rgba(192, 187, 184, 0.2)",
    },
    {
        textColor: "#005DB5",
        backgroundColor: "rgba(1, 114, 190, 0.08",
    },
    {
        textColor: "#656714",
        backgroundColor: "rgba(215, 198, 84, 0.25)",
    },
    {
        textColor: "#656714",
        backgroundColor: "rgba(255, 217, 102, 0.4)",
    },
    {
        textColor: "#CD091D",
        backgroundColor: "rgba(178, 11, 27, 0.1)",
    },
    {
        textColor: "#D5AC7F",
        backgroundColor: "rgba(227, 198, 124, 0.15)",
    },
    {
        textColor: "#DF6701",
        backgroundColor: "rgba(243, 155, 69, 0.15)",
    }
]



const GroceryWidget = (props) => {
    const { grocery, onAdd, formik } = props;
    const color = useMemo(() => colors[Math.floor(Math.random() * colors.length)], [])

    return (
        <Card
            component="div"
            className={styles.productWidgetContainer}
            style={{ backgroundColor: color.backgroundColor }}
        >
            <ButtonBase
                disabled={formik.values.groceriesId.includes(grocery.groceryId)}
                className={formik.values.groceriesId.includes(grocery.groceryId) ? styles.selected : styles.add}
                onClick={(e) => {
                    e.stopPropagation();
                    onAdd();
                }}>
                {formik.values.groceriesId.includes(grocery.groceryId) ? <CheckIcon fontSize="small" /> : <AddIcon fontSize="small" />}
            </ButtonBase>
            <div className={styles.img} style={{ backgroundImage: `url(${grocery.thumbnail})` }} />
            <Typography classes={{ body1: styles.desc }} style={{ color: color.textColor }} variant="body1" display="block" noWrap>{grocery.description}</Typography>
            <Typography classes={{ body1: styles.name }} variant="body1" display="block" noWrap>{grocery.name}</Typography>
            <Typography classes={{ body1: styles.size }} variant="body1" display="block" noWrap>{grocery.size}</Typography>
            <div className={styles.pointContainer}>
                <Avatar alt="discount" src={discountIcon} className={styles.avatar} />
                <span className={styles.leftText}>{grocery.points} Points</span>
            </div>
        </Card>
    )
}


GroceryWidget.propTypes = {
    grocery: PropTypes.object,
    formik: PropTypes.object,
    onAdd: PropTypes.func
};


const GroceryWidgetAsyc = (props) => {
    const { groceryId, onRemove, groceriesState } = props;
    const color = useMemo(() => colors[Math.floor(Math.random() * colors.length)], []);
    const [grocery, setGrocery] = useState({});

    useEffect(() => {
        if (groceriesState.groceries.find(grocery => grocery.groceryId === groceryId)) {
            const grocery = groceriesState.groceries.find(grocery => grocery.groceryId === groceryId);
            setGrocery(grocery);
        } else {
            (async () => {
                try {
                    const grocery = await getSingleGrocery(groceryId);
                    setGrocery(grocery);
                } catch (error) {
                    //To Do: handle error properly
                    console.log(error)
                }
            })();
        }
    }, []);



    return (
        <Card
            component="div"
            className={styles.productWidgetContainerAsyc}
            style={{ backgroundColor: color.backgroundColor }}
        >
            <IconButton
                size="small"
                className={styles.remove}
                onClick={(e) => {
                    e.stopPropagation();
                    onRemove();
                }}>
                <CloseOutlinedIcon className={styles.removeIcon} fontSize="small" />
            </IconButton>
            {grocery.thumbnail && <div className={styles.img} style={{ backgroundImage: `url(${grocery.thumbnail})` }} />}
        </Card>
    )
}


GroceryWidgetAsyc.propTypes = {
    groceryId: PropTypes.string,
    onRemove: PropTypes.func,
    groceriesState: PropTypes.object
};


const GroceryWidgetPreviewAsyc = (props) => {
    const { groceryId, groceriesState } = props;
    const color = useMemo(() => colors[Math.floor(Math.random() * colors.length)], []);
    const [grocery, setGrocery] = useState({});

    useEffect(() => {
        if (groceriesState.groceries.find(grocery => grocery.groceryId === groceryId)) {
            const grocery = groceriesState.groceries.find(grocery => grocery.groceryId === groceryId);
            setGrocery(grocery);
        } else {
            (async () => {
                try {
                    const grocery = await getSingleGrocery(groceryId);
                    setGrocery(grocery);
                } catch (error) {
                    //To Do: handle error properly
                    console.log(error)
                }
            })();
        }
    }, []);

    return (
        <Card
            component="div"
            className={styles.productWidgetContainer}
            style={{ backgroundColor: color.backgroundColor }}
        >
            <div className={styles.img} style={{ backgroundImage: `url(${grocery.thumbnail})` }} />
            <Typography classes={{ body1: styles.desc }} style={{ color: color.textColor }} variant="body1" display="block" noWrap>{grocery.description}</Typography>
            <Typography classes={{ body1: styles.name }} variant="body1" display="block" noWrap>{grocery.name}</Typography>
            <Typography classes={{ body1: styles.size }} variant="body1" display="block" noWrap>{grocery.size}</Typography>
            <div className={styles.pointContainer}>
                <span>£{grocery.price && grocery.price.toFixed(2)}</span>
                <span className={styles.packText}>1x pack</span>
            </div>
        </Card>
    )
}


GroceryWidgetPreviewAsyc.propTypes = {
    groceryId: PropTypes.string,
    groceriesState: PropTypes.object,
};



export { GroceryWidget, GroceryWidgetAsyc, GroceryWidgetPreviewAsyc };