import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import complete from "../../../assets/icons/complete.svg";
import notStart from "../../../assets/icons/not.svg";
import progress from "../../../assets/icons/progress.svg";
import { getUserTasks } from "../../../services/firebase";

import dayjs from "dayjs";
import * as isToday from "dayjs/plugin/isToday";

import styles from "./Task.module.scss";
import AnimateElement from "../../../components/AnimateElement";

import { TaskModal } from "./TaskModal/TaskModal";
import { useHistory, useParams } from "react-router";

dayjs.extend(isToday);

const TaksList = () => {
  const history = useHistory();
  const { patientId } = useParams();

  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [dailyTasks, setDailyTasks] = useState([]);
  const [weeklyTasks, setWeeklyTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState({});

  const userState = useSelector((state) => state.user, shallowEqual);

  const { user } = userState;

  const getTask = async () => {
    const res = await getUserTasks(user?.uid);
    if(res){
      const dailyTask = res.filter(task => task.frequency.toLowerCase() === "daily" && task.status !== "completed")
      const weeklyTask = res.filter(task => task.frequency.toLowerCase() === "weekly" && task.status !== "completed")
      setDailyTasks(dailyTask)
      setWeeklyTasks(weeklyTask)
    }
  };

  const updateTaskStatus = (id, frequency, status) => {
    if(frequency.toLowerCase() === 'daily') {
      setDailyTasks((prevData) =>
      prevData.map((item) =>
        item.taskId === id? { ...item, status: status } : item
      )
    );
    }
    if(frequency.toLowerCase() === 'weekly'){
      setWeeklyTasks((prevData) =>
      prevData.map((item) =>
        item.taskId === id? { ...item, status: status } : item
      )
    );
    }
  }

  useEffect(() => {
    getTask();
  }, [open]);

  return (
    <div>
      {(dailyTasks.length > 0 || weeklyTasks.length > 0) && ( <div className={styles.page}>
          <div className={styles.tasks}>
            <div className={styles.top}>
              <div className={styles.todo}>To Do</div>
              <div className={styles.status} onClick={() => history.push('/tasks')}>See All</div>
            </div>

            <div className={styles.bottom}>
            {dailyTasks.length > 0 && (
              <div className={styles.task}>
                <span className={styles.taskHeader}>Daily</span>
                {dailyTasks.map((item, index) => (
                  <div className={styles.add} key={index}>
                  <div
                    className={styles.icon}
                    onClick={() => {
                      history.push(
                        `/dietitian/patient-details/${user.uid}/task-details/${item.taskId}`
                      );
                    }}
                  >
                    <div className={styles.text} style={{ margin: "0" }}>
                      <div className={styles.name}> {item.description}</div>
                      <div className={styles.sub}> {item.subDescription}</div>
                    </div>
                  </div>

                  <div
                    className={styles.arr}
                    onClick={() => {
                      setSelectedTask(item);
                      setOpen(true);
                    }}
                  >
                    <IconButton>
                      <AnimateElement duration={"0.5"}>
                        <img
                          src={
                            item.status === "completed"
                              ? complete
                              : item.status === "in progress"
                              ? progress
                              : notStart
                          }
                          alt="tag"
                        />
                      </AnimateElement>
                    </IconButton>
                  </div>
                </div>
                ))}
                </div>)}

            {weeklyTasks.length > 0 &&  (<div className={styles.task}>
                <span className={styles.taskHeader}>Weekly</span>
                {weeklyTasks.map((item, index) => (
                  <div className={styles.add} key={index}>
                  <div
                    className={styles.icon}
                    onClick={() => {
                      history.push(
                        `/dietitian/patient-details/${user.uid}/task-details/${item.taskId}`
                      );
                    }}
                  >
                    <div className={styles.text} style={{ margin: "0" }}>
                      <div className={styles.name}> {item.description}</div>
                      <div className={styles.sub}> {item.subDescription}</div>
                    </div>
                  </div>

                  <div
                    className={styles.arr}
                    onClick={() => {
                      setSelectedTask(item);
                      setOpen(true);
                    }}
                  >
                    <IconButton>
                      <AnimateElement duration={"0.5"}>
                        <img
                          src={
                            item.status === "completed"
                              ? complete
                              : item.status === "in progress"
                              ? progress
                              : notStart
                          }
                          alt="tag"
                        />
                      </AnimateElement>
                    </IconButton>
                  </div>
                  </div>
                ))}
              </div>)}
            </div>
          </div>
        </div>)}
      {
        <TaskModal
          open={open}
          onClose={(id, status, frequency) => {
            setOpen(false);
            updateTaskStatus(id, frequency, status)
          }}
          selectedTask={selectedTask}
        />
      }
    </div>
  );
};

export default TaksList;
