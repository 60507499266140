import React from "react";
import OtpInput from 'react-otp-input';
import * as css from "./PinInput.module.scss";
import cx from "classnames";



const PinInput = (props) => {
    const { className, value, onChange } = props;
    return (
        <div className={cx(css.pinInputContainer, className)}>
            <OtpInput
                hasErrored={props.error || props.hasErrored}
                value={value}
                onChange={onChange}
                isInputSecure={false}
                isInputNum={true}
                numInputs={6}
                separator=""
                containerStyle={css.containerStyle}
                inputStyle={css.inputStyle}
                focusStyle={css.focusStyle}
                errorStyle={css.errorStyle}
                shouldAutoFocus
            />
        </div>
    )
}

export default PinInput;