import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import CustomInput from "../../../../components/CustomInput/CustomInput";
import editIcon from "./../../../../assets/icons/edit.png";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import backIcon from "./../../../../assets/icons/back.png";


import { updateUserProfile, logout } from "../../../../services/firebase";
import { toast, setUser } from "../../../../state";

import styles from "./Settings.module.scss";
import AnimateElement from "../../../../components/AnimateElement";


const validationSchema = Yup.object().shape({
    thumbnail: Yup.string().required("Thumbnail  is required").nullable(),
    firstName: Yup.string().required("Name  is required"),
    lastName: Yup.string().required("Name  is required"),
});



const Settings = (props) => {
    const { userState } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [logoutDialogIsOpen, setLogoutDialogIsOpen] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    let [firstName, lastName] = userState.user.displayName ? userState.user.displayName.split(" ") : ["", ""]

    return (
        <>
            <div className={styles.page}>
                <div className={styles.header}>
                    <IconButton size="small" className="mr-auto" onClick={() => history.goBack()} >
                        <Avatar alt="back" sizes="small" variant="square" src={backIcon} className={styles.arrowback} />
                    </IconButton>
                    <IconButton size="small" color="inherit" onClick={() => setDialogIsOpen(true)}>
                        <Avatar alt="search" sizes="" variant="square" src={editIcon} className={styles.editIcon} />
                    </IconButton>
                </div>

                <h2 className={styles.title}>Manage Account</h2>
                <AnimateElement duration={"0.5"}>
                <div className={styles.accountContainer}>
                    <Avatar className={styles.profileImage} src={userState.user.photoURL} />
                    <Typography className={styles.username} variant="body1" display="block" align="center" noWrap>{userState.user.displayName}</Typography>
                    <Typography className={styles.email} variant="body1" display="block" align="center" noWrap>{userState.user.email}</Typography>
                </div>
                </AnimateElement>

            </div>

            <ButtonBase className={styles.logoutButton} onClick={() => setLogoutDialogIsOpen(true)}>
                <PowerSettingsNewIcon className={styles.logoutIcon} />
                <span >LOGOUT</span>
            </ButtonBase>

            {logoutDialogIsOpen && (
                <div className={styles.logOutDialog}>
                    <h2 className={styles.logOutText}>Are you sure you want to logout?</h2>
                    <ButtonBase
                        className={styles.cancelLogoutButton}
                        onClick={() => setLogoutDialogIsOpen(false)}
                    >
                        Cancel
                    </ButtonBase>
                    <ButtonBase
                        className={styles.confirmLogoutButton}
                        onClick={async () => [history.push("/"), logout()]}
                    >
                        Confirm
                    </ButtonBase>
                </div>
            )}

            {dialogIsOpen && (
                <Dialog className={styles.dialog} disableBackdropClick disableEscapeKeyDown open={dialogIsOpen} fullWidth maxWidth="sm">
                    <Formik
                        validateOnMount
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={{
                            thumbnail: userState.user.photoURL,
                            firstName: firstName,
                            lastName: lastName
                        }}
                        onSubmit={async (values, FormikBag) => {
                            try {
                                const userData = await updateUserProfile({ thumbnail: values.thumbnail, firstName: values.firstName, lastName: values.lastName })
                                if (userData) {
                                    dispatch(setUser(userData));
                                }
                                FormikBag.setSubmitting(false);
                                dispatch(toast({ message: "Profile updated successfully" }));
                                setDialogIsOpen(false);
                            } catch (error) {
                                FormikBag.setSubmitting(false);
                                dispatch(toast({ message: error.message }));
                            }
                        }}
                    >
                        {(formik) => (
                            <>
                                <DialogTitle>Update Profile</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={4}>
                                        <Grid xs={12} item>
                                            <div>
                                                {formik.values.thumbnail ? (
                                                    <div className={styles.thumbnail} style={{ backgroundImage: `url(${formik.values.thumbnail})` }}>
                                                        <label>
                                                            <PhotoCameraOutlinedIcon color="primary" className={styles.editIcon} />
                                                            <input
                                                                hidden
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0]
                                                                    if (file && file.type.match('image.*')) {
                                                                        let reader = new FileReader();
                                                                        reader.onload = function (event) {
                                                                            formik.setFieldValue("thumbnail", event.target.result)
                                                                        };
                                                                        reader.readAsDataURL(e.target.files[0]);
                                                                    }
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <div className={styles.thumbnail} style={{ backgroundImage: `url(${formik.values.thumbnail})` }}>
                                                        <label>
                                                            <PhotoCameraOutlinedIcon color="primary" className={styles.editIcon} />
                                                            <input
                                                                hidden
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0]
                                                                    if (file && file.type.match('image.*')) {
                                                                        let reader = new FileReader();
                                                                        reader.onload = function (event) {
                                                                            formik.setFieldValue("thumbnail", event.target.result)
                                                                        };
                                                                        reader.readAsDataURL(e.target.files[0]);
                                                                    }
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <CustomInput
                                                placeholder="First name"
                                                {...formik.getFieldProps('firstName')}
                                                error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <CustomInput
                                                placeholder="Last name"
                                                {...formik.getFieldProps('lastName')}
                                                error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                                helperText={formik.touched.lastName && formik.errors.lastName}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className={styles.dialogAction}>
                                    <Button disabled={formik.isSubmitting} onClick={() => setDialogIsOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button disabled={formik.isSubmitting} onClick={formik.handleSubmit} variant="contained" color="primary">
                                        {formik.isSubmitting ? <CircularProgress size={20} disableShrink color="inherit" /> : "Update"}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Formik>
                </Dialog>
            )}

        </>
    )
}

export default Settings;