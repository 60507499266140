import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isEditing: false,
    recipe: null,

};

export const Upload = createSlice({
    name: 'Upload',
    initialState,
    reducers: {
        addRecipe: (state) => {
            state.isEditing = false;
            state.recipe = null;
        },
        editRecipe: (state, action) => {
            state.isEditing = true;
            state.recipe = action.payload;
        },
    }
});

export const { addRecipe, editRecipe } = Upload.actions;
export default Upload.reducer;