import React from "react";
import styles from "./BookingModal.module.scss";
import bookingImage from "../../../assets/booking.png";
import check from "../../../assets/checkBooking.png";

const BookingModal = ({ open, onClose }) => {
   return (
      <div className={open ? styles.page : styles.hide} onClick={onClose}>
         <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modalHeader}>
               <div className={styles.img}>
                  <img src={bookingImage} alt='booking' />
               </div>
               <div className={styles.modalTitle}>Great: now book a 30-min call with a Stove Dietitian!</div>
               <div className={styles.description}>You’ve completed the preparation tasks to book an initial call with ours Dietitians.</div>
            </div>
            <div className={styles.modalBody}>
               <div className={styles.benefitsTitle}>Benefits</div>
               <div className={styles.heading}>
                  <img src={check} alt='check' />
                  <span className={styles.text}>Tailored to your health information, food preferences and cooking skills</span>
               </div>
               <div className={styles.heading}>
                  <img src={check} alt='check' />
                  <span className={styles.text}>Get a personalised meal plan</span>
               </div>
               <div className={styles.heading}>
                  <img src={check} alt='check' />
                  <span className={styles.text}>Get a personalised meal plan</span>
               </div>
            </div>
            <div className={styles.button}>
               <a href='https://www.stovehealth.com/' target='_blank' rel='noopener noreferrer'>
                  <button className={styles.btn}>Pay £60 and book a call</button>
               </a>
            </div>
         </div>
      </div>
   );
};

export default BookingModal;
