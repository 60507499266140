import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";

import backIcon from "./../../assets/icons/back.png";
import StoveLogo from "./../../assets/stove-green-logo.svg";
import LoginImage from "./../../assets/login-image.png";
import GoogleIcon from "./../../assets/icons/google.svg";
import AppleIcon from "./../../assets/icons/apple.svg";
import FacebookIcon from "./../../assets/icons/facebook.svg";
// import SplashVideo from "./../../assets/stove-logo-transperent.mp4";
import SplashLogo from "./../../assets/stove-logo-transperent.gif";

import SignIn from "./SignIn";
import Register from "./Register";

import styles from "./Login.module.scss";

import {
  signInWithGoogleCredential,
  signInWithRedirectGoogle,
  signInWithRedirectApple,
  signInWithRedirectFacebook,
  createUserWithEmailAndPassword,
  updateUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getRedirectResult,
} from "../../services/firebase";
import { toast } from "../../state";

const Login = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('reset');

  const history = useHistory();
  const dispatch = useDispatch();
  const [splashIsOpen, setSplashIsOpen] = useState(true);
  const [activePage, setActivePage] = useState(paramValue ? "email" : "social");
  const [activeTab, setActiveTab] = useState("sign_in");
  const moveTo = useCallback((path) => history.push(path), [history]);


  window.GoogleSignUpHandler = useCallback(
    async ({ idToken, accessToken }) => {
      try {
        setSplashIsOpen(true);
        await signInWithGoogleCredential(idToken);
      } catch (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
          setTimeout(() => {
            setSplashIsOpen(false);
            dispatch(
              toast({
                message:
                  "Hey, looks like you're using this email address through another log in option. Please change your log in option. Thanks!",
              })
            );
          }, [3000]);
        } else {
          setTimeout(() => {
            setSplashIsOpen(false);
            dispatch(
              toast({
                message:
                  "Looks like there's an issue logging in! Please try again or reach out to our customer service team. Alternatively, send an email to info@stovecook.com. Thanks!",
              })
            );
          }, [3000]);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      try {
        const user = await getRedirectResult();
        console.log('user', user);
        if (user) {
          setSplashIsOpen(true);
        } else {
          setTimeout(() => setSplashIsOpen(false), 3000);
        }
      } catch (error) {
        console.log('error', error);
        if (error.code === "auth/email-already-exists") {
          setTimeout(() => {
            setSplashIsOpen(false);
            dispatch(
              toast({
                message:
                  "Hey, looks like you're using this email address through another login option. Please change your log in option. Thanks!",
              })
            );
          }, 3000);
        } else {
          setTimeout(() => {
            setSplashIsOpen(false);
            dispatch(
              toast({
                message:
                  "Looks like there's an issue logging in! Please try again or reach out to our customer service team. Alternatively, send an email to info@stovecook.com. Thanks!",
              })
            );
          }, 3000);
        }
      }
    })();
  }, [dispatch]);

  const removeQueryParam = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('reset');
    const updatedSearch = searchParams.toString();
    history.push({
      search: updatedSearch,
    });
  };

  const handleLoginGoogle = () => {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.presentSignInFlow &&
      window.webkit.messageHandlers.presentSignInFlow.postMessage
    ) {
      //IOS
      window.webkit.messageHandlers.presentSignInFlow.postMessage({
        platform: "Google",
      });
    } else if (
      window.AndroidSignInFlow &&
      window.AndroidSignInFlow.presentGoogleSignIn
    ) {
      //Android
      window.AndroidSignInFlow.presentGoogleSignIn();
    } else {
      //Web
      setSplashIsOpen(true);
      signInWithRedirectGoogle();
    }
  };

  const handleLoginApple = async () => {
    // setSplashIsOpen(true);
    await signInWithRedirectApple();
  };

  const handleLoginFacebook = () => {
    setSplashIsOpen(true);
    signInWithRedirectFacebook();
  };

  const handleCreateUserWithEmailAndPassword = async (
    email,
    password,
    firstName,
    lastName
  ) => {
    try {
      setSplashIsOpen(true);
      const user =  await createUserWithEmailAndPassword(
        email,
        password,
        firstName,
        lastName
      );
      !user && setSplashIsOpen(false)
    } catch {
      setSplashIsOpen(false);
      dispatch(
        toast({
          message:
            "Looks like there's an issue logging in! Please try again or reach out to our customer service team. Alternatively, send an email to info@stovecook.com. Thanks!",
        })
      );
    }
  };
  const handleUpdateUserWithEmailAndPassword = async (email, password) => {
    try {
      setSplashIsOpen(true);
      await updateUserWithEmailAndPassword(email, password);
    } catch {
      setSplashIsOpen(false);
      dispatch(
        toast({
          message:
            "Looks like there's an issue  resetting your password! Please try again or reach out to our customer service team. Alternatively, send an email to info@stovecook.com. Thanks!",
        })
      );
    }
  };
  const handleSignInWithEmailAndPassword = async (email, password) => {
    console.log(email, password);
    try {
      setSplashIsOpen(true);
      await signInWithEmailAndPassword(email, password);
    } catch (error) {
      setSplashIsOpen(false);
      if (error.code === "auth/wrong-password") {
        dispatch(toast({ message: "Invalid  email or password" }));
      } else {
        dispatch(
          toast({
            message:
              "Looks like there's an issue logging in! Please try again or reach out to our customer service team. Alternatively, send an email to info@stovecook.com. Thanks!",
          })
        );
      }
    }
  };

  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
  };

  const togglePageChange = () => {
    setActivePage((page) => (page !== "social" ? "social" : "email"));
  };

  return (
    <div className={styles.page}>
      {activePage === "social" && (
        <div className={styles.contentContainer}>
          <div className={styles.headerContainer}>
            <img
              alt="Stove Logo"
              src={StoveLogo}
              className={styles.logo}
              onClick={() => moveTo("/")}
            />
          </div>
          <img alt="Stove Login" src={LoginImage} className={styles.img} />
          <AppBar position="static" color="default" className={styles.appBar}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Auth tabs"
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="off"
              variant="fullWidth"
            >
              <Tab
                value="sign_in"
                aria-labelledby="Sign in"
                className={styles.tab}
                label="Sign in"
                classes={{ selected: styles.selected }}
              />
              <Tab
                value="register"
                aria-labelledby="Register"
                className={styles.tab}
                label="Register"
                classes={{ selected: styles.selected }}
              />
            </Tabs>
          </AppBar>
          {activeTab === "sign_in" && (
            <>
              <p className={styles.headerText}>Hello again!</p>
              <p className={styles.subText}>
                Welcome back, you’ve been missed.
              </p>
              <div className={styles.buttonContainer}>
                <ButtonBase
                  className={styles.googleLoginButton}
                  onClick={handleLoginGoogle}
                >
                  <img
                    alt="Google"
                    src={GoogleIcon}
                    className={styles.googleLoginButtonIcon}
                  />
                </ButtonBase>

                {/* <ButtonBase
                  className={styles.appleLoginButton}
                  onClick={handleLoginApple}
                >
                  <img
                    alt="Apple"
                    src={AppleIcon}
                    className={styles.appleLoginButtonIcon}
                  />
                </ButtonBase> */}

                <ButtonBase
                  className={styles.facebookLoginButton}
                  onClick={handleLoginFacebook}
                >
                  <img
                    alt="facebook"
                    src={FacebookIcon}
                    className={styles.facebookLoginButtonIcon}
                  />
                </ButtonBase>
              </div>
              <div className={styles.withEmailContainer}>
                <div className={styles.line}>
                  <div className={styles.lineText}>Or continue with email</div>
                </div>
                <ButtonBase
                  className={styles.withEmailButton}
                  onClick={togglePageChange}
                >
                  Your email address
                </ButtonBase>
              </div>
            </>
          )}
          {activeTab === "register" && (
            <>
              <p className={styles.headerText}>Welcome to Stove!</p>
              <p className={styles.subText}>
                Buy, cook and learn in a growing community.
              </p>
              <div className={styles.buttonContainer}>
                <ButtonBase
                  className={styles.googleLoginButton}
                  onClick={handleLoginGoogle}
                >
                  <img
                    alt="Google"
                    src={GoogleIcon}
                    className={styles.googleLoginButtonIcon}
                  />
                </ButtonBase>

                {/* <ButtonBase
                  className={styles.appleLoginButton}
                  onClick={handleLoginApple}
                >
                  <img
                    alt="Apple"
                    src={AppleIcon}
                    className={styles.appleLoginButtonIcon}
                  />
                </ButtonBase> */}

                <ButtonBase
                  className={styles.facebookLoginButton}
                  onClick={handleLoginFacebook}
                >
                  <img
                    alt="facebook"
                    src={FacebookIcon}
                    className={styles.facebookLoginButtonIcon}
                  />
                </ButtonBase>
              </div>
              <div className={styles.withEmailContainer}>
                <div className={styles.line}>
                  <div className={styles.lineText}>Or register with email</div>
                </div>
                <ButtonBase
                  className={styles.withEmailButton}
                  onClick={togglePageChange}
                >
                  Your email address
                </ButtonBase>
              </div>
            </>
          )}
        </div>
      )}

      {activePage === "email" && (
        <div className={styles.contentContainer}>
          <div className={styles.headerContainer}>
            <IconButton
              size="small"
              className="mr-auto"
              onClick={() => { togglePageChange(); removeQueryParam() }}
            >
              <Avatar
                alt="back"
                variant="square"
                src={backIcon}
                className={styles.arrowback}
              />
            </IconButton>
          </div>
          <AppBar position="static" color="default" className={styles.appBar}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Auth tabs"
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="off"
              variant="fullWidth"
            >
              <Tab
                value="sign_in"
                aria-labelledby="Sign in"
                className={styles.tab}
                label="Sign in"
                classes={{ selected: styles.selected }}
              />
              <Tab
                value="register"
                aria-labelledby="Register"
                className={styles.tab}
                label="Register"
                classes={{ selected: styles.selected }}
              />
            </Tabs>
          </AppBar>
          {activeTab === "sign_in" && (
            <>
              <p className={styles.headerText}>Hello again!</p>
              <p className={styles.subText}>
                Welcome back, you’ve been missed.
              </p>
              <SignIn
                handleSignInWithEmailAndPassword={
                  handleSignInWithEmailAndPassword
                }
                handleUpdateUserWithEmailAndPassword={
                  handleUpdateUserWithEmailAndPassword
                }
              />
            </>
          )}
          {activeTab === "register" && (
            <>
              <p className={styles.headerText}>Welcome to Stove!</p>
              <p className={styles.subText}>
                Buy, cook and learn in a growing community.
              </p>
              <Register
                handleCreateUserWithEmailAndPassword={
                  handleCreateUserWithEmailAndPassword
                }
              />
            </>
          )}
        </div>
      )}

      {splashIsOpen && (
        <div className={styles.splashScreen}>
          {/* <video className={styles.video} playsInline autoPlay loop muted>
                        <source src={SplashVideo} type="video/mp4" />
                    </video> */}
          <img
            src={SplashLogo}
            alt="logo"
            style={{ width: "350px", height: "inherit" }}
          />
        </div>
      )}
    </div>
  );
};

export default Login;
