import React, { useEffect, useState, useRef } from "react";
import { Events } from "./Events";
import styles from "./Calendar.module.scss";
import minuteHand from "../../assets/minute-hand.png";
import { getHours } from "../../utilities";
import dayjs from "dayjs";

const timeStyles = {
   position: "absolute",
   left: "33px",
};

export const Time = ({ selectDate, setSelectedTime, selectedTime }) => {
   const [timerStyle, setTimerStyle] = useState(timeStyles);
   const [activeDiv, setActiveDiv] = useState("");
   const [hourRow, setHourRow] = useState(getHours());
   const [height, setHeight] = useState("");
   const [distance, setDistance] = useState(0);
   const [position, setPosition] = useState(height);

   let timerRef = useRef([]);
   const fixedDivRef = useRef(null);
   const minuteRef = useRef(null)
   const date = new Date();
   const minutes = date.getMinutes();
   const hours = date.getHours();

   // This function gets the height of the div with current time
   const getHeight = () => {
      if (activeDiv) {
         const y = activeDiv.offsetHeight;
         setHeight(y);
      }
   };
   // get the  distance timer would move based on divs height
   const getDistance = () => {
      if (height) {
         const displacement = Number(height) / 60;
         setDistance(displacement);
      }
   };
   // get the current location on the div where the timer should stay
   const getPosition = () => {
      const getPosition = distance * minutes;
      setPosition(getPosition);
   };

   const setTimeOnScroll = (time) => {
      const newTime = dayjs(selectedTime).hour(Number(time));
      setSelectedTime(newTime);
   };

   useEffect(() => {
      getHeight();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [hours, activeDiv, hourRow]);

   useEffect(() => {
      getDistance();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [height]);

   useEffect(() => {
      getPosition();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [distance, minutes]);

   useEffect(() => {
      setTimerStyle((timerStyle) => ({
         ...timerStyle,
         top: `${position}px`,
      }));
   }, [minutes, position]);

   //these uses the intesectionObserver API to detect when the timecontainer intersects with the middlepoint of the viewport, which is where the metricline is positioned.
   useEffect(() => {
      const options = {
         rootMargin: "-50% 0px -50% 0px", //sets the viewport to the middle
         threshold: 0,
      };
      const observer = new IntersectionObserver((entries) => {
         entries.forEach((entry) => {
            if (entry.isIntersecting) {
               const currentValue = entry.target.dataset.value;
               setTimeOnScroll(currentValue);
            }
         });
      }, options);
      if (timerRef.current) {
         timerRef.current.forEach((div) => {
            observer.observe(div);
         });
      }
      return () => {
         observer.disconnect();
      };
   }, []);

   useEffect(() => {
      setActiveDiv(timerRef.current[hours]);
   }, [hours]);


    useEffect(() => {
      if (minuteRef.current) {
         const headerHeight = 160;
              const topOffset = minuteRef.current.getBoundingClientRect().top;
              const adjustedScrollPosition = topOffset - headerHeight;
        
              window.scrollTo({
                top: window.scrollY + adjustedScrollPosition,
                behavior: 'smooth',
              });
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minuteRef?.current]);

   //  useEffect(() => {
   //    if (activeImageRef.current) {
   //      const headerHeight = 80; // Replace with your actual header height
   //      const topOffset = activeImageRef.current.getBoundingClientRect().top;
   //      const adjustedScrollPosition = topOffset - headerHeight;
  
   //      window.scrollTo({
   //        top: window.scrollY + adjustedScrollPosition,
   //        behavior: 'smooth',
   //      });
   //    }
   //  }, []);


   return (
      <div className={styles.sectionCtn}>
         <div className={styles.container}>
            <div className={styles.metricline} ref={fixedDivRef}></div>
            {hourRow.map((timeStamp, i) => {
               return (
                  <div
                     className={styles.timeContainer}
                     key={i}
                     ref={(el) => (timerRef.current[timeStamp.startHour] = el)}
                     data-value={timeStamp.startHour}
                  >
                     <p className={styles.text}>
                        <span>{timeStamp.time}</span>
                     </p>
                     <Events startHour={timeStamp.startHour} selectDate={selectDate} />
                     {timeStamp.isActive && <img src={minuteHand} alt='minute' style={timerStyle} ref={ minuteRef} />}
                  </div>
               );
            })}
         </div>
      </div>
   );
};
