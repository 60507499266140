import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import cx from "classnames";
import emptyBg from "./../../assets/empty-bg.png";


import styles from "./RecipeWidgetList.module.scss";

const RecipeWidgetList = (props) => {
    const history = useHistory();
    const { recipes, padding } = props;

    const navigateToRecipe = (recideId) => {
        history.push(`/recipe/${recideId}`)
    }

    return <div className={cx(styles.recipeWidgetContainer, { [styles.recipeWidgetContainerPadding]: padding })}>
        {recipes.map((recipe) => (
            <Card key={recipe.recipeId} component="div" className={styles.card} onClick={() => navigateToRecipe(recipe.recipeId)}>
                <div style={{ backgroundImage: `url(${recipe.thumbnail ? recipe.thumbnail : 'https://ik.imagekit.io/stovehealth/empty-bg.png?updatedAt=1687381132262'})` }} className={styles.img} />
                <div className={styles.titleContainer}>
                    <Avatar src={recipe.chef && recipe.chef.pic} className={styles.chefImg} variant="circle" />
                    <Typography classes={{ body1: styles.chefName }} variant="body1" display="inline" noWrap>{recipe.chef && recipe.chef.firstName} {recipe.chef && recipe.chef.lastName}</Typography>
                </div>
                <Typography classes={{ body1: styles.title }} variant="body1" >{recipe.title}</Typography>
            </Card>
        ))}
    </div>
}

RecipeWidgetList.propTypes = {
    recipes: PropTypes.array.isRequired,
    padding: PropTypes.bool
};

export default RecipeWidgetList
