import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { withOrientationChange, isMobileOnly } from "react-device-detect";
import Avatar from "@material-ui/core/Avatar";
import { format, sub } from "date-fns";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import {
  getAppUserQuery,
  getUserSessionsQuery,
} from "../../../services/queries";
import styles from "./PatientSessions.module.scss";

const PatientSessions = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { patientId } = useParams();
  const { data } = getAppUserQuery(patientId);
  const { isLoading: isLoadingSessions, data: sessionsData } =
    getUserSessionsQuery(patientId);
  const [sortedSessions, setSortedSessions] = useState([]);

  const goBack = () => {
    history.goBack();
  };

  const handleNavigateToPath = (path) => {
    history.push(`${url}/${path}`);
  };

  useEffect(() => {
    if (sessionsData) {
      setSortedSessions(
        sessionsData
          .slice()
          .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
      );
    }
  }, [sessionsData]);

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <IconButton className={styles.back} size="small" onClick={goBack}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.00065 12.3379L20 12.3379"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <Avatar className={styles.avater} src={data && data.photoURL} />
          <div>
            <div className={styles.headerTitle}>Sessions</div>
            <div className={styles.headerSubText}>
              {data && data.displayName}
            </div>
          </div>
        </div>

        <div className={styles.container}>
          {isLoadingSessions && (
            <div className="px-4 pt-4 text-center">
              <CircularProgress size={15} disableShrink />
            </div>
          )}

          {!isLoadingSessions && sessionsData && (
            <>
              {sortedSessions.map((session, index) => (
                <div
                  className={styles.titleContainer}
                  key={session.sessionId}
                  onClick={() => handleNavigateToPath(session.sessionId)}
                >
                  <div className={styles.titleImage}>{index + 1}</div>
                  <div className={styles.titleTextContainer}>
                    <div
                      className={styles.titleHeaderSubText}
                      style={{ paddingBottom: "5px" }}
                    >
                      Session {index + 1}
                    </div>
                    <div className={styles.titleHeadertext}>
                      {session.title}
                    </div>
                    <div className={styles.titleHeaderSubText}> 
                      {format(new Date(session.createdOn), "d MMMM yyyy")} - {sortedSessions[index + 1] ?  format(sub(new Date(sortedSessions[index + 1].createdOn), { days: 1 }), "d MMMM yyyy") : format(new Date(), "d MMMM yyyy")}
                      
                    </div>
                  </div>
                  <IconButton>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.48926 11L6.51087 6L1.48926 1"
                        stroke="#00866E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </IconButton>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <Fab className={styles.fab} onClick={() => handleNavigateToPath("add")}>
        <AddIcon />
      </Fab>
    </>
  );
};

export default withOrientationChange(PatientSessions);
