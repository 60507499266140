import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   activeLists: [],
   isAdded: {},
   eventStates: {
      showModal: false,
      currentEventType: "",
      isModalOpen: false,
      showWeightModal: false,
      showSymptomsModal: false,
   },
   selectedEvent: {}
};

export const Calendar = createSlice({
   name: "Calendar",
   initialState,
   reducers: {
      addto: (state, { payload }) => {
         state.activeLists = [...state.activeLists, payload];
      },
      remove: (state, { payload }) => {
         state.activeLists = payload;
      },
      toggle: (state, { payload }) => {
         state.isAdded = { ...state.isAdded, [payload.id]: !state.isAdded[payload.id] };
      },
      clear: (state) => {
         state.activeLists = [];
         state.isAdded = {};
      },
      openEvents: (state, { payload }) => {
         state.eventStates = {
            ...state.eventStates,
            showModal: payload.showModal,
            currentEventType: payload.currentEventType,
            isModalOpen: payload.isModalOpen,
            showWeightModal: payload.showWeightModal,
            showSymptomsModal: payload.showSymptomsModal,
         };
      },
      closeEvents: (state) => {
         state.eventStates = {
            ...state.eventStates,
            showModal: false,
            currentEventType: "",
            isModalOpen: false,
            showWeightModal: false,
            showSymptomsModal: false,
         };
         state.selectedEvent = {};
      },
      selectedEvent: (state, {payload}) => {
         state.selectedEvent = payload;
      }
   },
});

// Action creators are generated for each case reducer function
export const { addto, remove, toggle, clear, openEvents, closeEvents, selectedEvent } = Calendar.actions;

export default Calendar.reducer;
