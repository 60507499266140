import React, { useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";


import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';

import discountIcon from "./../../../../assets/icons/discount-red.png";
import thumbUpImg from "./../../../../assets/thumb-up.png";

import { updateCart, toast } from "./../../../../state";
import { ADDRESS, getDiscountPrice, getPrice, getTotalPrice, getTotalCartPrice, getTotalCartDiscount } from "./../../Cart";


import styles from "./Home.module.scss";

const colors = [
    {
        textColor: "#656462",
        backgroundColor: "rgba(192, 187, 184, 0.2)",
    },
    {
        textColor: "#005DB5",
        backgroundColor: "rgba(1, 114, 190, 0.08",
    },
    {
        textColor: "#656714",
        backgroundColor: "rgba(215, 198, 84, 0.25)",
    },
    {
        textColor: "#656714",
        backgroundColor: "rgba(255, 217, 102, 0.4)",
    },
    {
        textColor: "#CD091D",
        backgroundColor: "rgba(178, 11, 27, 0.1)",
    },
    {
        textColor: "#D5AC7F",
        backgroundColor: "rgba(227, 198, 124, 0.15)",
    },
    {
        textColor: "#DF6701",
        backgroundColor: "rgba(243, 155, 69, 0.15)",
    }
]



const Item = (props) => {
    const { cartItem, user } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const color = useMemo(() => colors[Math.floor(Math.random() * colors.length)], []);

    const updateCartItem = (cartItem, quantity) => {
        dispatch(updateCart({
            ...cartItem,
            quantity
        }))
    }

    const decrease = (cartItem) => {
        let newNumber = cartItem.quantity - 1;
        if (newNumber >= 0) {
            updateCartItem(cartItem, newNumber);
        }
    }

    const increase = (cartItem) => {
        let newNumber = cartItem.quantity + 1
        if(cartItem.stockQuantity && (newNumber <= cartItem.stockQuantity)){
            updateCartItem(cartItem, newNumber);
          } else{
              dispatch(toast({ message: "You can’t order more than what’s available in stock"}))
          } 
    }

    const removeFromCart = (cartItem) => {
        updateCartItem(cartItem, 0);
    }

    const copyResellLink = (groceryItem) => {
        if (!user) {
            history.push("/login");
        } else {
            if (navigator.share) {
                navigator.share({
                    title: "Share resell link",
                    url: `${window.location.origin}/redeem/${user.uid}/${groceryItem.groceryId}/${groceryItem.quantity}`,
                }).then(() => {
                    console.log("success")
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                const dummyInput = document.createElement("input");
                document.body.appendChild(dummyInput);
                dummyInput.value = `${window.location.origin}/redeem/${user.uid}/${groceryItem.groceryId}/${groceryItem.quantity}`;
                dummyInput.select();
                document.execCommand("copy");
                document.body.removeChild(dummyInput);
                dispatch(toast({ message: "Share link copied to clipboard" }));
            }
        }

    }

    return (
        <div className={styles.listItem}>
            {cartItem.groceryId && (
                <>
                    <div className={styles.image} style={{ backgroundImage: `url(${cartItem.thumbnail})`, backgroundColor: color.backgroundColor }} />


                    <div className={styles.rightPan}>

                        <div className={styles.topContainer}>
                            <div className={styles.left}>
                                <span className={styles.discription} style={{ color: color.textColor }}>{cartItem.description}</span>
                                <span className={styles.name}>{cartItem.name}</span>
                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>£{getPrice(cartItem)}</span>
                                    {cartItem.quantity >= cartItem.discountQuantity && (
                                        <span className={styles.discount}>{getDiscountPrice(cartItem)}/pack</span>
                                    )}
                                </div>
                                {/* <ButtonBase onClick={() => copyResellLink(cartItem)} className={styles.resellButton}>Share</ButtonBase> */}
                            </div>

                            <div className={styles.right}>
                                <div className={styles.calculator}>
                                    <IconButton size="small" color="inherit" onClick={() => decrease(cartItem)}><RemoveIcon fontSize="small" color="primary" /></IconButton>
                                    <span className={styles.number}>{cartItem.quantity}</span>
                                    <IconButton size="small" color="primary" onClick={() => increase(cartItem)} disabled={cartItem.specialOffers && cartItem.quantity >= 1}><AddIcon fontSize="small" color="inherit"  /></IconButton>
                                </div>
                                <span className={styles.totalPrice}>£{getTotalPrice(cartItem)}</span>
                            </div>
                        </div>


                        <div className={styles.deliveryContainer}>
                            <div className={styles.tag}>
                                <span className={styles.leftTag}>Delivered in:</span>
                                <span className={styles.rightTag}>{cartItem.delivery} days</span>
                            </div>
                            <div className={styles.tag}>
                                <span className={styles.leftTag}>Delivery by:</span>
                                <span className={styles.rightTag}>{cartItem.deliveryBy}</span>
                            </div>
                        </div>
                    </div>

                </>

            )}

            {cartItem.rewardId && (
                <>
                    <div className={styles.image} style={{ backgroundImage: `url(${cartItem.thumbnail})`, backgroundColor: color.backgroundColor }} />


                    <div className={styles.rightPan}>
                        <div className={styles.left}>
                            <span className={styles.discription} style={{ color: color.textColor }}>{cartItem.description}</span>
                            <span className={styles.name}>{cartItem.name}</span>
                        </div>

                        <div className={styles.right}>
                            <IconButton size="small" color="inherit" onClick={() => removeFromCart(cartItem)}>
                                <DeleteIcon fontSize="small" color="primary" />
                            </IconButton>
                        </div>
                    </div>
                </>

            )}
        </div>
    )

}




const Home = (props) => {
    const { setDisplay, cartState, userState } = props;
    const history = useHistory();

    const proceedToAddress = () => {
        if (!userState.user) {
            history.push("/login");
        } else {
            setDisplay((display) => ({ ...display, header: false, active: ADDRESS }));
        }
    }

    return (
        <div className={styles.homePage}>

            <div className={styles.list}>
                {cartState.cart.map((cartItem, index) => (
                    <Item key={index} cartItem={cartItem} user={userState.user} />
                ))}
            </div>

            {cartState.cart.length > 0 && (
                <>
                    <div className={styles.totalContainer}>
                        <span className={styles.totalText}>Total</span>
                        <span className={styles.totalPriceText}>£{cartState.cart.reduce(getTotalCartPrice, 0)}</span>
                    </div>

                    <div className="px-4 pt-4">
                        {cartState.cart.reduce(getTotalCartDiscount, 0) > 0 && (
                            <div className={styles.saveContainer}>
                                <div className="d-flex mb-2">
                                    <span className="mr-auto">Money saved</span>
                                    <span>-£{cartState.cart.reduce(getTotalCartDiscount, 0)}</span>
                                </div>
                                <div className="d-flex">
                                    <span className={styles.saveText}>Through cumulative discounts you saved this amount of money. Keep it up!</span>
                                    <Avatar alt="Thumb" src={thumbUpImg} variant="square" className={styles.thumb} />
                                </div>
                            </div>
                        )}

                        {/* <div className={styles.pointEarnedContainer}>
                            <Avatar alt="discount" src={discountIcon} className={styles.discountIcon} />
                            <span className="mr-auto">Potential points</span>
                            <span>0</span>
                        </div> */}
                    </div>
                </>
            )}

            <ButtonBase
                className={styles.ProceedButton}
                onClick={proceedToAddress}
            >Proceed to Checkout</ButtonBase>
        </div>

    )
}

export default Home;