import dayjs from 'dayjs'
//shuffle and Array
export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export const calculatePriceAfterPromoCode = (
  promoCode,
  promoCodes,
  totalReturnedPrice
) => {
  let reducedPrice;
  if (promoCodes.length > 0 && totalReturnedPrice > 0) {
    promoCodes.forEach((element) => {
      const { active, code, percent_off } = element;
      if (code === promoCode.toUpperCase() && active) {
        reducedPrice = Number(totalReturnedPrice * (percent_off / 100));
        totalReturnedPrice = totalReturnedPrice - reducedPrice;
      }
    });
    return Number(totalReturnedPrice).toFixed(2);
  }
  return 0;
};

// 
export const getHours = () => {
  let currentHourCount = new Date().getHours()
  const hourRow = new Array(24).fill([]).map((hr, i) => {
    let hourCount = i + 1
    const newDate = dayjs().hour(12);
    let isActive = false;
    let startHour = i;
    if (currentHourCount === startHour) {
      isActive = true;
    }
    const AMPM = hourCount >= 12 && hourCount !== 24 ? 'PM' : 'AM'
    const hour = hourCount % 12 || 12;
    const time = hour + ' ' + AMPM
    return {
      time,
      startHour,
      hourCount,
      newDate,
      isActive,
    };


  })


  return hourRow;

}

// calculate discount
export const calculateDiscount = (original_price, discounted_price) => {
  const discount = 100 * ((original_price - discounted_price) / original_price);
  return discount > 0 ? Number(discount).toFixed(0) : 0;
}

// convert k-joules to k-cal
export const convertKiloJoulestoKiloCal = (energyValue) => {
  if(energyValue){
     const result = energyValue / 4.184;
     return result;
  }
  return 0;
}


export const limitTextLength = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  console.log(text, 'opp')
  return text.slice(0, maxLength) + "...";
  
}
