import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import timeIcon from "./../../assets/icons/time.svg";
import servingsIcon from "./../../assets/icons/servings.png";
import { toast } from "./../../state";

import styles from "./MealPlan.module.scss";
import AnimateElement from "../../components/AnimateElement";
import { useHistory } from "react-router";
import {
  getRecipesByName,
  getSingleRecipe,
  uploadRecipe,
} from "../../services/firebase";
import { SSE } from "sse";
import { jsonrepair } from "jsonrepair";
import EmptyCard from "./emptyCard";
import addBtn from "./../../assets/icons/pencil.svg";

const MealCard = ({
  plan,
  refresh,
  showFooter = true,
  background = "#ffd772",
  cardStyle = styles.card,
  title = styles.title,
  loadingStyles,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user, shallowEqual);
  const resultRef = useRef();
  const jsonRef = useRef();
  const [loading, setLoading] = useState(false);
  // recipe/ulLxVcblKefjYq95zXmH
  const moveTo = (path) => history.push(path);
  const apiKey = "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";

  const recipeFormat = `{
    "thumbnail": "" should be empty,
    "chefId": should be "${userState.user.uid}",
    "groceriesId": [] should be empty array,
    "title": should not be empty should be name of recipe or name of the food,
    "servings": should not be empty should be number of recipe servings eg "2", 
    "readyIn": should not be empty should be in this format "5 mins" and it should be in fives,
    "difficulty": should be either "Low", "Medium", "High" or "Pro"
    "cost": should be either "£", "££", "£££" or "££££"
    "type": should be either "Starter", "Dessert", "Side dish", "Sauce", "Main", "Salad", "Bread", "Soup", "Roast", "Antipasti", "Sandwich", "Stew", "Cake", "Pie" or "Drink",
    "meal": should be string of either "Breakfast", "Launch", "Dinner" or Snack
    "dietary": should be a either "Vegeterian", "Vegan", "Pescetarian", "No pork", "No beef", "No nuts", "Glueten free", "Dairy free" or "N/A"
    "cuisine": should be full name of country it was made in
    "cookingMethod": should be either "baked", "boiled", "braised", "broiled", "grilled", "poached", "roasted", "sautéed", "simmered", "steamed", "stir-fry" or "stewed",
    "ingredients" : should be array of object eg [{"name": name of ingredient, "quantity": as decimal numbers instead of fractions. For example, use 1.5 instead of 1/2, and 2.3 instead of 2/3, "unit": unit of mesurement,}]
    "nutriments": {"proteins": amount of protein, "carbohydrates": amount of carbohydrate, "fat": amount of fat, "energy-kcal": amount of calories} should contain carbohydrates, protein , fat in grams and calories in kcal the should all be in decimal numbers instead of fractions. For example, use 1.5 instead of 1/2, and 2.3 instead of 2/3
    "steps": [{"ingredientsUsed": array of ingredient used in step array eg ["salt" "pepper" "oil"], "title": title of step eg "Step 1", "description": description of what will be done in the step}] should be array of object containing ingredientsUsed, title, description
}`;

  const send = async () => {
    setLoading(true);
    resultRef.current = "";
    jsonRef.current = "";
    let url = "https://api.openai.com/v1/chat/completions";
    let data = {
      model: "gpt-4o",
      // response_format: { type: "json_object" },
      messages: [
        {
          role: "user",
          content: `i need a ${plan?.recipe_name} recipe formatted in this json format ${recipeFormat}`,
        },
      ],
      temperature: 0.8,
      top_p: 1,
      max_tokens: 1500,
      stream: true,
      n: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    let source = new SSE(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
        "X-Content-Type-Options": "nosniff",
      },
      method: "POST",
      payload: JSON.stringify(data),
    });

    source.addEventListener("message", (e) => {
      if (e.data === "[DONE]") {
        resultRef.current = convertToJSON(jsonRef.current);

        if (resultRef.current) {
          upload();
        } else {
          dispatch(toast({ message: "Error processing recipe" }));
          setLoading(false);
        }
      } else {
        let payload = JSON.parse(e.data);
        let text = payload.choices[0].delta.content;
        let data = JSON.parse(e.data).choices[0];
        // let text = JSON.parse(jsonrepair(e.data)).choices[0].text;
        if (data.finish_reason === "stop") {
          source.close();
        } else {
          jsonRef.current = jsonRef.current + text;
          // console.log("text", text);
        }
      }
    });

    source.addEventListener("readystatechange", (e) => {
      if (e.readyState >= 2) {
      }
    });
    source.stream();
    // } else {
    //   dispatch(toast({ message: `Please write a message` }));
    // }
  };

  const convertToJSON = (string) => {
    // console.log("string", string);
    const jsonStartIndex = string.indexOf("{");
    const jsonEndIndex = string.lastIndexOf("}");

    const jsonString = string.substring(jsonStartIndex, jsonEndIndex + 1);

    let mainJson = null;
    if (jsonString.length > 2) {
      // console.log("jsonString", string, jsonStartIndex, jsonString, jsonEndIndex);

      try {
        mainJson = JSON.parse(jsonrepair(jsonString));
      } catch (err) {
        dispatch(toast({ message: "Error processing recipe" }));
        setLoading(false);
        console.log("eer", err);
      }
    } else {
      dispatch(toast({ message: "Error processing recipe" }));
      setLoading(false);
    }
    return mainJson;
  };

  const view = async () => {
    setLoading(true);
    const gotRecipe = await getRecipesByName(plan?.recipe_name);
    if (gotRecipe.length > 0) {
      setLoading(false);
      moveTo(`/recipe/${gotRecipe[0].recipeId}`);
    } else {
      await send();
    }
  };

  const upload = async () => {
    const res = await uploadRecipe(resultRef.current);
    setLoading(false);
    moveTo(`/recipe/${res}`);
  };

  return (
    <>
      {!loading && (
        <Card
          component="div"
          className={cardStyle}
          style={{ backgroundColor: background }}
          // onClick={view}
        >
          {/* <Typography
            classes={{ body1: title }}
            variant="body1"
            onClick={() =>
              plan?.recipe_id ? moveTo(`/recipe/${plan.recipe_id}`) : view()
            }
          > */}
          <div
            className={styles.title}
            onClick={() =>
              plan?.recipe_id ? moveTo(`/recipe/${plan.recipe_id}`) : view()
            }
          >
            {plan?.recipe_name ? plan?.recipe_name : ""}
          </div>
          {/* </Typography> */}
          {showFooter && (
            <div className={styles.footer}>
              <img
                onClick={() => refresh()}
                className={styles.img1}
                src={addBtn}
                alt="add btn"
              />
            </div>
          )}
        </Card>
      )}
      {loading && (
        <EmptyCard
          loading={loading}
          addMeal={() => {}}
          loadingStyles={loadingStyles}
        />
      )}
    </>
  );
};

export default MealCard;
