import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./Notification.module.scss";

import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { setActiveConversation, getNotifications } from "../../../../state";

import {
  updateReadMessage,
  updateReadMessageByGroupId,
  updateReadMessageByUserId,
} from "../../../../services/firebase";
import AnimateElement from "../../../../components/AnimateElement";

dayjs.extend(relativeTime);

const Notification = ({
  icon,
  image,
  text,
  notificationState,
  userState,
  chatConversations,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = userState.user;
  const { notifications, notificationsIsLoading, notificationsIsError } =
    notificationState;
  const chatState = useSelector((state) => state.chat, shallowEqual);

  useEffect(() => {
    if (userState.user) {
      dispatch(getNotifications(userState.user.uid));
    }
  }, [dispatch, userState.user]);

  const navigateToConversation = (channel) => {
    dispatch(setActiveConversation(channel));
    history.push("chat/conversation");
  };

  const handleClick = async (senderId) => {
    const conversation = chatConversations.find(
      (conversation) =>
        senderId in conversation?.attributes &&
        user.uid in conversation?.attributes
    );
    if (conversation) {
      navigateToConversation(conversation);
      try {
        dispatch(getNotifications(user.uid));
      } catch (error) {
        console.log(error);
      }
    } else {
      history.push("chat/");
    }
  };

  const handleSelectGroup = async (chatId) => {
    const conversation = chatConversations.find(
      (conversation) => conversation.uniqueName === chatId
    );
    if (conversation) {
      navigateToConversation(conversation);
    } else {
      history.push("chat/");
    }
  };

  const getChat = (chats, chatId) => {
    const chat = chats.find((chat) => chat.chatId === chatId);
    return chat;
  };

  return (
    <>
      {notificationsIsLoading === true && notificationsIsError === false && (
        <div className="p-4">
          <Spinner variant="rect" height={140} />
          <Spinner variant="text" height={40} />
          <Spinner variant="text" height={40} />
        </div>
      )}

      {notificationsIsLoading === false && notificationsIsError === true && (
        <MessageBox text="Failed to load notifications" />
      )}

      {notificationsIsLoading === false &&
        notificationsIsError === false &&
        notifications &&
        notifications.length === 0 && <MessageBox text="No notifications" />}

      {notificationsIsLoading === false &&
        notificationsIsError === false &&
        notifications &&
        notifications.length > 0 && (
          <>
            {notifications.map((notification, index) => {
              const groupChat =
                notification.chatType === "group" &&
                getChat(chatState.puplicConversations, notification.receiverId);
              return (
                <>
                  {notification.chatType === "individual" && (
                    <div
                      className={styles.container}
                      key={index.toString()}
                      onClick={() => handleClick(notification?.senderId)}
                    >
                      <List className={styles.list}>
                        <ListItem
                          alignItems="flex-start"
                          className={styles.listItem}
                        >
                          <ListItemAvatar className={styles.imageContainer}>
                            {notification.sender === "admin" && (
                              <Avatar
                                src={image}
                                alt="img"
                                className={styles.img}
                              />
                            )}
                            {notification.sender !== "admin" &&
                              notification.senderImage && (
                                <AnimateElement duration={"0.5"}>
                                  <Avatar
                                    src={notification.senderImage || image}
                                    alt="img"
                                    className={styles.img}
                                  />
                                </AnimateElement>
                              )}
                            {notification.sender !== "admin" &&
                              !notification.senderImage && (
                                <AnimateElement duration={"0.5"}>
                                  {" "}
                                  <Avatar
                                    src={image}
                                    alt="img"
                                    className={styles.img}
                                  />
                                </AnimateElement>
                              )}
                          </ListItemAvatar>
                          <ListItemText
                            primary=""
                            secondary={
                              <React.Fragment>
                                <span
                                  className={styles.user}
                                >{`${notification.sender}`}</span>
                                <br />
                                <span className={styles.body}>
                                  {notification.body?.length > 20
                                    ? notification.body.substring(0, 20) + "..."
                                    : notification.body.substring(0, 20)}
                                </span>
                                <br />
                                <span className={styles.date}>
                                  {" "}
                                  {formatDistanceToNowStrict(
                                    new Date(notification.created)
                                  )}{" "}
                                  ago
                                </span>
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="forward">
                              <ArrowForwardIosIcon
                                color="primary"
                                fontSize="small"
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </List>
                    </div>
                  )}
                  {groupChat && (
                    <div
                      className={styles.container}
                      key={index.toString()}
                      onClick={() =>
                        handleSelectGroup(notification?.receiverId)
                      }
                    >
                      <List className={styles.list}>
                        <ListItem
                          alignItems="flex-start"
                          className={styles.listItem}
                        >
                          <ListItemAvatar className={styles.imageContainer}>
                          <AnimateElement duration={"0.5"}> <Avatar
                              src={
                                groupChat.thumbnail
                                  ? groupChat.thumbnail
                                  : image
                              }
                              alt="img"
                              className={styles.img}
                            />
                            </AnimateElement>
                          </ListItemAvatar>
                          <ListItemText
                            primary=""
                            secondary={
                              <React.Fragment>
                                <span
                                  className={styles.user}
                                >{`${groupChat.uniqueName}`}</span>
                                <br />
                                <span className={styles.body}>
                                  <span className={styles.sender}>
                                    {notification.sender}
                                  </span>
                                  :{" "}
                                  {notification.body?.length > 20
                                    ? notification.body.substring(0, 20) + "..."
                                    : notification.body.substring(0, 20)}
                                </span>
                                <br />
                                <span className={styles.date}>
                                  {" "}
                                  {formatDistanceToNowStrict(
                                    new Date(notification.created)
                                  )}{" "}
                                  ago
                                </span>
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="forward">
                              <ArrowForwardIosIcon
                                color="primary"
                                fontSize="small"
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </List>
                    </div>
                  )}

                  {notification.sender === "admin" && (
                    <div className={styles.container} key={index.toString()}>
                      <List className={styles.list}>
                        <ListItem
                          alignItems="flex-start"
                          className={styles.listItem}
                        >
                          <ListItemAvatar className={styles.imageContainer}>
                          <AnimateElement duration={"0.5"}>  <Avatar
                              src={image}
                              alt="img"
                              className={styles.img}
                            />
                            </AnimateElement>
                          </ListItemAvatar>
                          <ListItemText
                            primary=""
                            secondary={
                              <React.Fragment>
                                <span
                                  className={styles.user}
                                >{`${notification.sender}`}</span>
                                <br />
                                <span className={styles.body}>
                                  {notification.body?.length > 20
                                    ? notification.body.substring(0, 20) + "..."
                                    : notification.body.substring(0, 20)}
                                </span>
                                <br />
                                <span className={styles.date}>
                                  {" "}
                                  {formatDistanceToNowStrict(
                                    new Date(notification.created)
                                  )}{" "}
                                  ago
                                </span>
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="forward">
                              <ArrowForwardIosIcon
                                color="primary"
                                fontSize="small"
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </List>
                    </div>
                  )}
                </>
              );
            })}
          </>
        )}
    </>
  );
};

export default Notification;
