import React from "react";
import styles from "./NutrientsBar.module.scss";
import { convertKiloJoulestoKiloCal } from "../../utilities";

const MultipleNutrientsBar = ({ selectedMeals, nutrients }) => {
  console.log("nutrients", nutrients);
  const isWhatPercentOf = (x, y) => {
    if (x && y) {
      return Math.round((x / y) * 100);
    } else {
      return 0;
    }
  };

  const percentOfNumber = (number, percentage) => {
    return Math.trunc((percentage / 100) * number);
  };

  const reduceFat = (data) => {
    return data.reduce((accumulator, object) => {
      if (object.quantity?.match(/\d+/g)) {
        const quantity = +object.quantity?.match(/\d+/g).map(Number)[0];
        const calc = isWhatPercentOf(+object.served, quantity);
        return (
          accumulator +
          isWhatPercentOf(
            percentOfNumber(+calc, +object.nutriments.fat),
            nutrients.fats
          )
        );
      } else {
        return (
          accumulator + isWhatPercentOf(+object.nutriments.fat, nutrients.fats)
        );
      }
    }, 0);
  };
  const reduceProt = (data) => {
    return data.reduce((accumulator, object) => {
      if (object.quantity?.match(/\d+/g)) {
        const quantity = +object.quantity?.match(/\d+/g).map(Number)[0];
        const calc = isWhatPercentOf(+object.served, quantity);
        return (
          accumulator +
          isWhatPercentOf(
            percentOfNumber(+calc, +object.nutriments.proteins),
            nutrients.proteins
          )
        );
      } else {
        return (
          accumulator +
          isWhatPercentOf(+object.nutriments.proteins, nutrients.proteins)
        );
      }
    }, 0);
  };
  const reduceCab = (data) => {
    return data.reduce((accumulator, object) => {
      if (object.quantity?.match(/\d+/g)) {
        const quantity = +object.quantity?.match(/\d+/g).map(Number)[0];
        const calc = isWhatPercentOf(+object.served, quantity);
        return (
          accumulator +
          isWhatPercentOf(
            percentOfNumber(+calc, +object.nutriments.carbohydrates),
            nutrients.carbs
          )
        );
      } else {
        return (
          accumulator +
          isWhatPercentOf(+object.nutriments.carbohydrates, nutrients.carbs)
        );
      }
      // return accumulator + +object.nutriments.carbohydrates;
    }, 0);
  };

  const reduceCal = (data) => {
    return data.reduce((accumulator, object) => {
      if (object.quantity?.match(/\d+/g)) {
        const energyValue = object.nutriments["energy-kcal"]
          ? Number(object.nutriments["energy-kcal"])
          : convertKiloJoulestoKiloCal(
              Number(object.nutriments["energy-value"])
            );
        const quantity = object.quantity?.match(/\d+/g)
          ? +object.quantity?.match(/\d+/g).map(Number)[0]
          : null;
        const calc = isWhatPercentOf(+object.served, quantity);
        return accumulator + percentOfNumber(calc, energyValue);
      } else {
        const energyValue = object.nutriments["energy-kcal"]
          ? Number(object.nutriments["energy-kcal"])
          : convertKiloJoulestoKiloCal(
              Number(object.nutriments["energy-value"])
            );
        return accumulator + energyValue;
      }
    }, 0);
  };

  const sum = (data, type) => {
    if (type === "fat") {
      return data.reduce((acc, item) => acc + item.nutriments.fat, 0);
    }
    if (type === "cal") {
      return data.reduce((acc, item) => acc + item.nutriments["energy-kcal"], 0);
    }
    if (type === "cab") {
      return data.reduce((acc, item) => acc + item.nutriments.carbohydrates, 0);
    }
    if (type === "pro") {
      return data.reduce((acc, item) => acc + item.nutriments.proteins, 0);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.fles}>
        <div className={`${styles.sin} ${styles.bod}`}>
          <div>Kcal/day</div>
          <div>
            <progress value={reduceCal(selectedMeals)} max={nutrients.calories}>
              {nutrients.show ? reduceCal(selectedMeals) + "%" : sum(selectedMeals, 'cal')}
            </progress>
          </div>
          <div>
            <b>{nutrients.show ? reduceCal(selectedMeals) + "%" : sum(selectedMeals, 'cal') + 'kcal'}</b>
          </div>
        </div>

        <div className={styles.sin}>
          <div>Carbs</div>
          <div>
            <progress value={nutrients.show ? reduceCab(selectedMeals) : sum(selectedMeals, 'cab')} max="200">
              {nutrients.show ? reduceCab(selectedMeals) + "%" : sum(selectedMeals, 'cab') + 'g'}
            </progress>
          </div>
          <div>
            <b>{nutrients.show ? reduceCab(selectedMeals) + "%" : sum(selectedMeals, 'cab') + 'g'}</b>
          </div>
        </div>
        <div className={styles.sin}>
          <div>Protein</div>
          <div>
            <progress value={nutrients.show ? reduceProt(selectedMeals) : sum(selectedMeals, 'pro')} max="200">
              {nutrients.show ? reduceProt(selectedMeals) + "%" : sum(selectedMeals, 'pro') + "g"}
            </progress>
          </div>
          <div>
            <b>{nutrients.show ? reduceProt(selectedMeals) + "%" : sum(selectedMeals, 'pro') + "g"}</b>
          </div>
        </div>

        <div className={styles.sin}>
          <div>Fat</div>
          <div>
            <progress
              onClick={() => sum(selectedMeals, "fat")}
              value={nutrients.show  ? reduceFat(selectedMeals) : sum(selectedMeals, 'fat')}
              max="200"
            >
              {nutrients.show  ? reduceFat(selectedMeals) + "%" : sum(selectedMeals, 'fat') + "g"}
            </progress>
          </div>
          <div>
            <b>{nutrients.show  ? reduceFat(selectedMeals) + "%" : sum(selectedMeals, 'fat') + "g"}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleNutrientsBar;
