import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import backIcon from "./../../../../assets/icons/back.png";
import discountIcon from "./../../../../assets/icons/discount.png";
import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";

import { transactionState } from "../../../../constant";


import styles from "./Transactions.module.scss";
import AnimateElement from "../../../../components/AnimateElement";


const Transactions = (props) => {
    const { userState, url } = props;
    const history = useHistory();
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(index);
    };

    const goBack = () => {
        history.goBack()
    }

    const navigateToTransaction = (id) => {
        history.push(`${url}/transaction/${id}`);
    }

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <IconButton size="small" className="mr-auto" onClick={goBack} >
                    <Avatar alt="back" sizes="small" variant="square" src={backIcon} className={styles.arrowback} />
                </IconButton>
            </div>
            <h2 className={styles.title}>Order History</h2>

            <div className="px-4 pt-4">
                {userState.userTransactionsIsLoading === true && userState.userTransactionsIsError === false && (
                    <Spinner variant="rect" height={140} />
                )}
                {userState.userTransactionsIsLoading === false && userState.userTransactionsIsError === true && (
                    <MessageBox text="Failed to load your transactions" />
                )}
                {userState.userTransactionsIsLoading === false && userState.userTransactionsIsError === false && userState.userTransactions.length === 0 && (
                    <MessageBox text="No transaction yet" />
                )}
            </div>

            {userState.userTransactionsIsLoading === false && userState.userTransactionsIsError === false && userState.userTransactions.length > 0 && (
                <List className={styles.list}>
                    {userState.userTransactions.map((tranction, index) => {
                        return (
                            <AnimateElement duration={"0.5"}>
                            <Fragment key={tranction.transactionId}>
                                <ListItem divider button onClick={() => openIndex === index ? handleClick(null) : handleClick(index)} >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <div className={styles.primaryTextContainer}>
                                                <span className={styles.primaryText}>{transactionState[tranction.status]}</span>
                                                <Avatar className={styles.badge} src={discountIcon} />
                                                <span className={styles.primaryTextRight}>0</span>
                                            </div>
                                        }
                                        secondary={
                                            <div className={styles.secondaryTextContainer}>
                                                <span className={styles.secondaryText}>{`£${tranction.price} - ${tranction.cart.length} ${tranction.cart.length > 1 ? "products" : "products"} `}</span>
                                                <span className={styles.secondaryTextRight}>{format(new Date(tranction.createdOn), 'dd MMM yyy')}</span>
                                            </div>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton size="small" onClick={() => navigateToTransaction(tranction.transactionId)} >
                                            <ArrowForwardIosIcon color="primary" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {openIndex === index && (
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {tranction.cart.map((cartItem, cartItemIndex) => (
                                                <ListItem className={styles.cartListItem} key={cartItemIndex}>
                                                    <ListItemAvatar className={styles.listAvatar}>
                                                        <Avatar className={styles.avatar} src={cartItem.thumbnail} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className="text-capitalize"
                                                        primary={cartItem.name}
                                                        secondary={`${cartItem.rewardId ? `Points: ${cartItem.points}` : `£${cartItem.price}`} - ${cartItem.quantity}`}

                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </Fragment>
                            </AnimateElement>
                        )
                    })}
                </List>
            )}
        </div>
    )
}

export default Transactions;