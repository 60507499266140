import React from "react";
import { withOrientationChange, isMobileOnly } from 'react-device-detect';
import styles from "./App.module.scss";
import Pages from "./pages";
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
// import { KJUR } from "jsrsasign";

const App = (props) => {
  const { isPortrait } = props;
  return (
    <div className={(isMobileOnly && isPortrait) ? styles.container : styles.makeMobile}>
      <ErrorBoundary>
        <Pages />
      </ErrorBoundary>
    </div>
  )
}

export default withOrientationChange(App);




// export function getToken() {
//   var pHeader = { alg: "RS256", typ: "JWT" };
//   var pClaim = {};
//   pClaim.aud = "https://www.googleapis.com/oauth2/v3/token";
//   pClaim.scope = "https://www.googleapis.com/auth/calendar";
//   pClaim.iss = "calendar-api@stove-272418.iam.gserviceaccount.com";
//   pClaim.exp = KJUR.jws.IntDate.get("now + 1hour");
//   pClaim.iat = KJUR.jws.IntDate.get("now");
//   pClaim.sub = "info@stovecook.com";
//   var sClaim = JSON.stringify(pClaim);
//   var key = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC1S0b1Yi/qies7\nNXe+llHxUSwjIhrS6liHkyq15odGqd4IME8b+nCQmhQ2KBrrXA7RxCFYvOQW1kek\nv3qUQk1zOBhIcwhivYXbX392wcrUrq2bofqQvoXHpLIMANDg7jstdU8UCEkCAG7/\nzF0O7CVT9Dph4VcGR0Km5QEhbybqdYx/95tmFFa6+1SG/svKv/7LEwrJ0IjL7yph\ng8h51QTtMyujJwr6PYJ9DR9jfbXH2fnTCs9Y+iarKRAO0gAf6utk0RFVQqGXcoCx\nlBeTIriS0t0iakqiF5KulQRDZVb+VLsqDArqevV31BpwCoxPibHuDYkZoP2eXxJq\nZY9C3QzdAgMBAAECggEAAf6w6lbkiTpv2w7u/KsIfhUA3Qs/mXTT1I6mk/L43qrC\nIPvLrDH/kfMyXuGdhaTn9yAUy5G/Yw6oj7r0zTOyJDFIroyMWe5eO9hNvZ1dbyUc\n6sqjNg2ryOV15vLEfjUg4ZKaBnHVd0FQ0ssuCK0lHAGr+aV2165FoOmmeGRIuDKV\nCxNW58KaHQ13FeK9/xOhCgePKsuODYxXZfuGjm8rSHMLPj0aTx/YWXJ1u9VgrFkp\nE3CeNzzWEYUhdCUxouui+AEUrxFmPDvYIC0yN1BvVSxf17/iYBHgoTqVXkHtUYhT\nsYMxX/mhepHbWDXSiqrDRvcvMDKsGXHMaJ5liiNXAQKBgQDii4D2NHPvcdJp69Oz\nORdCw5c25XAVpyKH5pVNQJvgaQTOxV8JKpO4LiZoSr7ISGvRw8mvlNZnNBp31kYF\n/nFhG3+RPxtwqHPRVY21FwkToRZgaBXOTo5DNsPHYUGpugrBThAq6sOU7ersJ4ep\n5BLxHydqHrpBWUyUUGLxenakRQKBgQDM3ZusNSYG2b2O8b8Ecw9WN4zZJFLCrRPn\n15yKWaz4bg435cXIupz+l1YrVBdk+U1lt20p9cT1MisnyxStAZSpC25DlWnfamcG\nyBTzv7kpPej0+beI62IdRmXReodtj15vRD1iun2oExJ94QxAxnzB0UVOQtywqm7x\nrlBaptHruQKBgQCjTN4MG2lKu6hqE8VJVqjuxmCVALRX119ryQnsYts521nM1rcX\nu2o6BIJJAUAezR1zWodimo4mXIeLxJg7L9t4nULKZ0wXXEYIKnx4gf9X9/FSUxUM\n3tFrn+lmKjQT7XhdOTEsTofnLcz+p8wB6dbBmqxlsXqfKoiqDHwWBynIZQKBgEc9\nkRLzcIPVWCvLOUfM0zqCGsKv9O+pzFBFFDSwNow1iVA64OqKqmmGKvrqRTBh2V5T\nyOxcgN9bUNKHtmrrL80RIojx/l0o3SbokRCqTTNgShBWflJouDfB8UpFN68LuiUr\nL8WTYHsAFuAbUG1+GIg1w8oRSjlNK0N0AJFljX5RAoGAJ1R+8fp0BgnbZ15C7WPU\nXjDu8fck8lL63or7x6h8jqHTclxrQEoZR1E9e3K7qqm62Tn1pTVNDBFSdNRez9r2\ndZBqbkzQRD3Ui+gDvh0ggYGRBafs/BnXz/VcwT0f2Rs9jSJ61Kxe5sEHe4k0EHYp\nv6QebklKr+/xtanJP9waY54=\n-----END PRIVATE KEY-----\n";
//   var sJWS = KJUR.jws.JWS.sign(null, pHeader, sClaim, key);
//   var XHR = new XMLHttpRequest();
//   var urlEncodedData = "";
//   var urlEncodedDataPairs = [];
//   urlEncodedDataPairs.push(
//     encodeURIComponent("grant_type") +
//     "=" +
//     encodeURIComponent("urn:ietf:params:oauth:grant-type:jwt-bearer")
//   );
//   urlEncodedDataPairs.push(
//     encodeURIComponent("assertion") + "=" + encodeURIComponent(sJWS)
//   );
//   urlEncodedData = urlEncodedDataPairs.join("&").replace(/%20/g, "+");

//   // We define what will happen if the data are successfully sent
//   XHR.addEventListener("load", function (event) {
//     var response = JSON.parse(XHR.responseText);
//     let token = response["access_token"];
//     window.localStorage.setItem("access_token", token);
//   });

//   // We define what will happen in case of error
//   XHR.addEventListener("error", function (event) {
//     console.log("Oops! Something went wrong.");
//   });

//   XHR.open("POST", "https://www.googleapis.com/oauth2/v3/token");
//   XHR.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
//   XHR.send(urlEncodedData);
// }
