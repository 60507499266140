import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

import backIcon from "./../../../../assets/icons/back.png";
import discountIcon from "./../../../../assets/icons/discount.png";
import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";
import OrderImg from "./../../../../assets/order.png";

import { transactionState } from "../../../../constant";
import { getUserTransaction } from "../../../../services/firebase";

import styles from "./Transaction.module.scss";
import AnimateElement from "../../../../components/AnimateElement";

const colors = [
  {
    textColor: "#656462",
    backgroundColor: "rgba(192, 187, 184, 0.2)",
  },
  {
    textColor: "#005DB5",
    backgroundColor: "rgba(1, 114, 190, 0.08",
  },
  {
    textColor: "#656714",
    backgroundColor: "rgba(215, 198, 84, 0.25)",
  },
  {
    textColor: "#656714",
    backgroundColor: "rgba(255, 217, 102, 0.4)",
  },
  {
    textColor: "#CD091D",
    backgroundColor: "rgba(178, 11, 27, 0.1)",
  },
  {
    textColor: "#D5AC7F",
    backgroundColor: "rgba(227, 198, 124, 0.15)",
  },
  {
    textColor: "#DF6701",
    backgroundColor: "rgba(243, 155, 69, 0.15)",
  },
];

export const getDiscountPrice = (cartItem) => {
  const discountAmount = cartItem.discountPrice - cartItem.price;
  return Math.sign(discountAmount) === -1
    ? `-£${Math.abs(discountAmount).toFixed(2).replace(".", ",")}`
    : `£${Math.abs(discountAmount).toFixed(2).replace(".", ",")}`;
};

export const getPrice = (cartItem) => {
  if (cartItem.quantity >= cartItem.discountQuantity) {
    return cartItem.discountPrice.toFixed(2);
  } else {
    return cartItem.price.toFixed(2);
  }
};

export const getTotalPrice = (cartItem) => {
  let totalPrice = 0;
  if (cartItem.quantity >= cartItem.discountQuantity) {
    totalPrice = cartItem.discountPrice * cartItem.quantity;
  } else {
    totalPrice = cartItem.price * cartItem.quantity;
  }
  return totalPrice.toFixed(2);
};

export const getTotalCartPrice = (accumulator, cartItem) => {
  if (cartItem.groceryId) {
    let price = Number(accumulator) + Number(getTotalPrice(cartItem));
    return price.toFixed(2);
  } else {
    return Number(accumulator);
  }
};

export const getTotalCartDiscount = (accumulator, cartItem) => {
  let discount = 0;
  if (cartItem.groceryId) {
    if (cartItem.quantity >= cartItem.discountQuantity) {
      discount =
        Number(accumulator) +
        (cartItem.price - cartItem.discountPrice) * cartItem.quantity;
    } else {
      discount = Number(accumulator);
    }
  } else {
    discount = Number(accumulator);
  }
  return discount.toFixed(2);
};

const Item = (props) => {
  const { cartItem } = props;
  const color = useMemo(
    () => colors[Math.floor(Math.random() * colors.length)],
    []
  );

  return (
    <div className={styles.listItem}>
      {cartItem.groceryId && (
        <>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${cartItem.thumbnail})`,
              backgroundColor: color.backgroundColor,
            }}
          />
          <div className={styles.rightPan}>
            <div className={styles.topContainer}>
              <div className={styles.left}>
                <span
                  className={styles.discription}
                  style={{ color: color.textColor }}
                >
                  {cartItem.description}
                </span>
                <span className={styles.name}>{cartItem.name}</span>

                <div className={styles.priceContainer}>
                  <span className={styles.price}>£{getPrice(cartItem)}</span>
                  {cartItem.quantity >= cartItem.discountQuantity && (
                    <span className={styles.discount}>
                      {getDiscountPrice(cartItem)}/pack
                    </span>
                  )}
                </div>
              </div>

              <div className={styles.right}>
                <span className={styles.totalPrice}>
                  £{getTotalPrice(cartItem)}
                </span>
                <span className={styles.totalQuantity}>
                  x
                  {cartItem.quantity > 9
                    ? cartItem.quantity
                    : `0${cartItem.quantity}`}
                </span>
              </div>
            </div>

            <div className={styles.deliveryContainer}>
              <div className={styles.tag}>
                <span className={styles.leftTag}>Delivered in:</span>
                <span className={styles.rightTag}>
                  {cartItem.delivery} days
                </span>
              </div>
              <div className={styles.tag}>
                <span className={styles.leftTag}>Delivery by:</span>
                <span className={styles.rightTag}>{cartItem.deliveryBy}</span>
              </div>
            </div>
          </div>
        </>
      )}

      {cartItem.rewardId && (
        <>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${cartItem.thumbnail})`,
              backgroundColor: color.backgroundColor,
            }}
          />
          <div className={styles.rightPan}>
            <div className={styles.left}>
              <span
                className={styles.discription}
                style={{ color: color.textColor }}
              >
                {cartItem.description}
              </span>
              <span className={styles.name}>{cartItem.name}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Transaction = (props) => {
  const { userState } = props;
  const history = useHistory();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        const transaction = await getUserTransaction(
          userState.user.uid,
          params.transactionId
        );
        setTransaction(transaction);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        setIsError(true);
      }
    })();
  }, [params.transactionId]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton size="small" onClick={() => history.goBack()}>
          <Avatar
            alt="back"
            sizes="small"
            variant="square"
            src={backIcon}
            className={styles.arrowback}
          />
        </IconButton>
        <span className={styles.orderText}>Order {params.transactionId}</span>
      </div>

      {isLoading === true && isError === false && (
        <div className="px-4 pt-4">
          <Spinner variant="rect" height={140} />
        </div>
      )}
      {isLoading === false && isError === true && (
        <div className="px-4 pt-4">
          <MessageBox text="Failed to load your transaction" />
        </div>
      )}
      {isLoading === false && isError === false && !transaction && (
        <div className="px-4 pt-4">
          <MessageBox text="Transaction not found" />
        </div>
      )}

      {isLoading === false && isError === false && transaction && (
        <AnimateElement duration={"0.5"}>
          <>
            <div className={styles.trackContainer}>
              <img alt="Order" src={OrderImg} className={styles.orderImage} />

              {transaction.status === 0 && (
                <>
                  <p className={styles.orderStatusText}>Order placed!</p>
                  <p className={styles.orderStatusSubText}>
                    We’re preparing your order for{" "}
                    {format(
                      new Date(transaction.when ? transaction.when : 0),
                      "do MMMM yyyy"
                    )}
                  </p>
                </>
              )}
              {transaction.status === 1 && (
                <>
                  <p className={styles.orderStatusText}>
                    Your order is coming!
                  </p>
                  <p className={styles.orderStatusSubText}>
                    Be ready at home: it will arrive in the next hour – so, bon
                    appetit!
                  </p>
                </>
              )}
              {transaction.status === 2 && (
                <>
                  <p className={styles.orderStatusText}>
                    Your Order has been delivered
                  </p>
                  <p className={styles.orderStatusSubText}>
                    Enjoy! Have an issue with your order? If so, just email us
                    at{" "}
                    <a href="mailto:info@stovecook.com">info@stovecook.com</a>{" "}
                    and our team will be happy to help!
                  </p>
                </>
              )}

              {transaction.status === 0 && (
                <div className={styles.statusLineContainer}>
                  <div className="text-center">
                    <div className={styles.statusLineDone}></div>
                    <span>
                      <svg
                        className="mr-1"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.66235 8C3.40981 8 3.15727 7.89533 2.96449 7.68475L0.289177 4.76256C-0.0963923 4.34141 -0.0963923 3.6592 0.289177 3.23928C0.674746 2.81813 1.2982 2.8169 1.68377 3.23805L3.66235 5.39922L8.31623 0.315862C8.7018 -0.105287 9.32525 -0.105287 9.71082 0.315862C10.0964 0.737012 10.0964 1.41923 9.71082 1.84038L4.3602 7.68475C4.16742 7.89533 3.91488 8 3.66235 8Z"
                          fill="black"
                        />
                      </svg>
                      <span className={styles.statusTextDone}>Getting</span>
                    </span>
                  </div>
                  <div className="text-center">
                    <div className={styles.statusLineCurrent}></div>
                    <span className={styles.statusTextCurrent}>Collect</span>
                  </div>
                  <div className="text-center">
                    <div className={styles.statusLineUndone}></div>
                    <span className={styles.statusTextUndone}>Delivery</span>
                  </div>
                </div>
              )}
              {transaction.status === 1 && (
                <div className={styles.statusLineContainer}>
                  <div className="text-center">
                    <div className={styles.statusLineDone}></div>
                    <span>
                      <svg
                        className="mr-1"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.66235 8C3.40981 8 3.15727 7.89533 2.96449 7.68475L0.289177 4.76256C-0.0963923 4.34141 -0.0963923 3.6592 0.289177 3.23928C0.674746 2.81813 1.2982 2.8169 1.68377 3.23805L3.66235 5.39922L8.31623 0.315862C8.7018 -0.105287 9.32525 -0.105287 9.71082 0.315862C10.0964 0.737012 10.0964 1.41923 9.71082 1.84038L4.3602 7.68475C4.16742 7.89533 3.91488 8 3.66235 8Z"
                          fill="black"
                        />
                      </svg>
                      <span className={styles.statusTextDone}>Getting</span>
                    </span>
                  </div>
                  <div className="text-center">
                    <div className={styles.statusLineDone}></div>
                    <span>
                      <svg
                        className="mr-1"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.66235 8C3.40981 8 3.15727 7.89533 2.96449 7.68475L0.289177 4.76256C-0.0963923 4.34141 -0.0963923 3.6592 0.289177 3.23928C0.674746 2.81813 1.2982 2.8169 1.68377 3.23805L3.66235 5.39922L8.31623 0.315862C8.7018 -0.105287 9.32525 -0.105287 9.71082 0.315862C10.0964 0.737012 10.0964 1.41923 9.71082 1.84038L4.3602 7.68475C4.16742 7.89533 3.91488 8 3.66235 8Z"
                          fill="black"
                        />
                      </svg>
                      <span className={styles.statusTextDone}>Collect</span>
                    </span>
                  </div>
                  <div className="text-center">
                    <div className={styles.statusLineCurrent}></div>
                    <span className={styles.statusTextCurrent}>Delivery</span>
                  </div>
                </div>
              )}
              {transaction.status === 2 && (
                <div className={styles.statusLineContainer}>
                  <div className="text-center">
                    <div className={styles.statusLineDone}></div>
                    <span>
                      <svg
                        className="mr-1"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.66235 8C3.40981 8 3.15727 7.89533 2.96449 7.68475L0.289177 4.76256C-0.0963923 4.34141 -0.0963923 3.6592 0.289177 3.23928C0.674746 2.81813 1.2982 2.8169 1.68377 3.23805L3.66235 5.39922L8.31623 0.315862C8.7018 -0.105287 9.32525 -0.105287 9.71082 0.315862C10.0964 0.737012 10.0964 1.41923 9.71082 1.84038L4.3602 7.68475C4.16742 7.89533 3.91488 8 3.66235 8Z"
                          fill="black"
                        />
                      </svg>
                      <span className={styles.statusTextDone}>Getting</span>
                    </span>
                  </div>
                  <div className="text-center">
                    <div className={styles.statusLineDone}></div>
                    <span>
                      <svg
                        className="mr-1"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.66235 8C3.40981 8 3.15727 7.89533 2.96449 7.68475L0.289177 4.76256C-0.0963923 4.34141 -0.0963923 3.6592 0.289177 3.23928C0.674746 2.81813 1.2982 2.8169 1.68377 3.23805L3.66235 5.39922L8.31623 0.315862C8.7018 -0.105287 9.32525 -0.105287 9.71082 0.315862C10.0964 0.737012 10.0964 1.41923 9.71082 1.84038L4.3602 7.68475C4.16742 7.89533 3.91488 8 3.66235 8Z"
                          fill="black"
                        />
                      </svg>
                      <span className={styles.statusTextDone}>Collect</span>
                    </span>
                  </div>
                  <div className="text-center">
                    <div className={styles.statusLineDone}></div>
                    <span>
                      <svg
                        className="mr-1"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.66235 8C3.40981 8 3.15727 7.89533 2.96449 7.68475L0.289177 4.76256C-0.0963923 4.34141 -0.0963923 3.6592 0.289177 3.23928C0.674746 2.81813 1.2982 2.8169 1.68377 3.23805L3.66235 5.39922L8.31623 0.315862C8.7018 -0.105287 9.32525 -0.105287 9.71082 0.315862C10.0964 0.737012 10.0964 1.41923 9.71082 1.84038L4.3602 7.68475C4.16742 7.89533 3.91488 8 3.66235 8Z"
                          fill="black"
                        />
                      </svg>
                      <span className={styles.statusTextDone}> Delivery</span>
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.orderListContainer}>
              <p className={styles.orderOverviewText}>Order overview</p>

              <div className={styles.list}>
                {transaction.cart.map((cartItem, index) => (
                  <Item key={index} cartItem={cartItem} />
                ))}
              </div>

              <div className={styles.totalContainer}>
                <span className={styles.totalText}>Total</span>
                <span className={styles.totalPriceText}>
                  £{transaction.cart.reduce(getTotalCartPrice, 0)}
                </span>
              </div>

              <div className={styles.shippingDetailsContainer}>
                <div className={styles.shippingDetails}>
                  Shipping information
                </div>

                <div className={styles.line} />

                <div className={styles.nameText}>
                  {userState.user.displayName}
                </div>
                <div className={styles.addressText}>{transaction.address}</div>
              </div>
            </div>
          </>
        </AnimateElement>
      )}
    </div>
  );
};

export default Transaction;
