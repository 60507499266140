import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "./Calories.module.scss";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Slider from "@material-ui/core/Slider";
import _debounce from "lodash/debounce";

import { getAppUserQuery, getUserNutrients } from "../../../services/queries";
import {
  useUpdatePatientNutrients,
  useToggleShowFeatures,
} from "../../../services/mutations";

import Spinner from "./../../../components/Spinner/Spinner";
import ToggleButton from "./../../../components/ToggleButton/ToggleButton";
import CustomSlider from "../../../components/CustomSlider/Slider";
import MessageBox from "../../../components/MessageBox/MessageBox";
import { getUserData, updateNutrient } from "../../../services/firebase";
import { shallowEqual, useSelector } from "react-redux";
import AnimateElement from "../../../components/AnimateElement";

const PatientCalories = () => {
  const [toggle, setToggle] = useState(false);
  const [values, setValues] = useState();

  const history = useHistory();
  const { patientId } = useParams();
  const {
    data,
    refetch,
    isLoading: userDataLoading,
  } = getAppUserQuery(patientId);
  const { userNutrients, isLoading, isError } = getUserNutrients(patientId);
  const { updatePatientNutrientData } = useUpdatePatientNutrients(patientId);
  const { toggleShowFeature } = useToggleShowFeatures();
  const [nutrients, setNutrients] = useState({
    calories: 0,
    carbs: 0,
    fats: 0,
    proteins: 0,
    show: false,
  });

  const userState = useSelector((state) => state.user, shallowEqual);

  const goBack = () => {
    history.goBack();
  };

  const handleShowFeatures = () => {
    setToggle(Boolean(!toggle));
    setNutrients({ ...nutrients, show: Boolean(!nutrients.show) });
    console.log(nutrients);
  };

  const updateShowFeatures = async () => {
    const data = {
      id: patientId,
      showFeatures: toggle,
    };
    await toggleShowFeature(data);
  };

  const debouncedSave = useMemo(
    () =>
      _debounce(async () => {
        // const payload = {
        //    id: patientId,
        //    calories: values.calories,
        //    carbs: values.carbs,
        //    fats: values.fats,
        //    proteins: values.proteins,
        // };
        console.log("debounce", nutrients);
        await updateNutrient(patientId, nutrients);
      }, 5000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nutrients]
  );

  const getUser = async () => {
    try {
      const user = await getUserData(patientId);
      console.log("main user", user);
      if (user.nutrients) {
        setNutrients(user.nutrients);
        console.log("this");
      } else {
        setNutrients({
          show: false,
          calories: 0,
          fats: 0,
          proteins: 0,
          carbs: 0,
        });
        console.log("that");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    debouncedSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nutrients]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateShowFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <IconButton className={styles.back} size="small" onClick={goBack}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00065 12.3379L20 12.3379"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
        <AnimateElement duration={"0.5"}>
          <Avatar className={styles.avater} src={data && data.photoURL} />
        </AnimateElement>
        <div>
          <div className={styles.headerTitle}>Calories & Macronutrients</div>
          <div className={styles.headerSubText}>{data && data.displayName}</div>
        </div>
      </div>

      {(isLoading || userDataLoading) && (
        <div className="p-4">
          <Spinner variant="rect" height={140} />
          <Spinner variant="text" height={40} />
          <Spinner variant="text" height={40} />
          <Spinner variant="rect" height={140} />
        </div>
      )}

      {isError && <MessageBox text="An error Occured" />}

      {!isLoading && !userDataLoading && !isError && (
        <div className={styles.content}>
          <div className={styles.showFeatures}>
            <div className={styles.featureHeading}>
              <div className={styles.featureTitle}>Show feature</div>
              <ToggleButton
                toggle={nutrients.show}
                handleToggle={handleShowFeatures}
              />
            </div>

            <div className={styles.featureDescription}>
              <span className={styles.descriptionText}>
                Overall the experience, your patient will see percentage about
                daily kcal and macronutrients (carbs, fat and protein) in
                Tracking section, and in other pages like food detail, weekly
                report, etc.
              </span>
            </div>
          </div>

          <div
            className={styles.calories}
            style={{ opacity: nutrients.show ? "" : "0.4" }}
          >
            <div className={styles.caloriesHeading}>
              <div className={styles.caloriesTitle}>Calories</div>
              <div className={styles.caloriesSubText}>
                Set up the calories intake specifically for this patient.
              </div>
            </div>

            <div className={styles.caloriesSlider}>
              <CustomSlider
                name="calories"
                defaultValue={nutrients.calories}
                value={nutrients.calories}
                min={1000}
                max={3000}
                orientation="vertical"
                disabled={!nutrients.show}
                onChange={(e, newValue) => {
                  setNutrients({ ...nutrients, calories: newValue });
                }}
              />
              <div className={styles.caloriesSliderTextCtn}>
                <div className={styles.caloriesSliderText}>1000</div>
                <div className={styles.caloriesSliderText}>3000</div>
              </div>
            </div>
          </div>

          <div
            className={styles.macroNutrients}
            style={{ opacity: nutrients.show ? "" : "0.4" }}
          >
            <div className={styles.macroNutrientsHeading}>
              <div className={styles.macroNutrientsTitle}>Macronutrients</div>
              <div className={styles.macroNutrientsSubText}>
                Set up the proper macronutrients intake for this patient.
              </div>
            </div>

            <div className={styles.macroNutrientsSlider}>
              <div className={styles.macroNutrientsSliderCtn}>
                <div className={styles.macroNutrientsSliderTitle}>Fats</div>
                <div className={styles.sliderCtn}>
                  <CustomSlider
                    name="fats"
                    defaultValue={nutrients.fats}
                    value={nutrients.fats}
                    min={0}
                    max={200}
                    orientation="horizontal"
                    unit="g"
                    disabled={!nutrients.show}
                    onChange={(e, newValue) => {
                      setNutrients({ ...nutrients, fats: newValue });
                    }}
                  />
                </div>
              </div>
              <div className={styles.macroNutrientsSliderCtn}>
                <div className={styles.macroNutrientsSliderTitle}>Carbs</div>
                <div className={styles.sliderCtn}>
                  <CustomSlider
                    name="carbs"
                    defaultValue={nutrients.carbs}
                    value={nutrients.carbs}
                    min={0}
                    max={200}
                    orientation="horizontal"
                    unit="g"
                    disabled={!nutrients.show}
                    onChange={(e, newValue) => {
                      setNutrients({ ...nutrients, carbs: newValue });
                    }}
                  />
                </div>
              </div>
              <div className={styles.macroNutrientsSliderCtn}>
                <div className={styles.macroNutrientsSliderTitle}>Proteins</div>
                <div className={styles.sliderCtn}>
                  <CustomSlider
                    name="proteins"
                    defaultValue={nutrients.proteins}
                    value={nutrients.proteins}
                    min={0}
                    max={200}
                    orientation="horizontal"
                    unit="g"
                    disabled={!nutrients.show}
                    onChange={(e, newValue) => {
                      setNutrients({ ...nutrients, proteins: newValue });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientCalories;
