import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import Introduction from "./components/Introduction/Introduction";
import Ingredients from "./components/Ingredients/Ingredients";
import Brands from "./components/Brands/Brands";
import Directions from "./components/Directions/Directions";
import Preview from "./components/Preview/Preview";
import Congratulations from "./components/Congratulations/Congratulations";

import { toast } from "../../state";
import {
  createChef,
  getChefById,
  uploadRecipe,
  updateRecipe,
  addCollectedPoints,
} from "../../services/firebase";

export const getTotalPoints = (brands) => {
  return brands.reduce((accumulator, grocery) => {
    return accumulator + grocery.points;
  }, 0);
};

const validationSchema = Yup.object().shape({
  groceriesId: Yup.array().of(Yup.string()),
  thumbnail: Yup.string().nullable(),

  title: Yup.string().required("Title is required"),
  difficulty: Yup.string().required("Difficulty is required"),
  readyIn: Yup.string().required("Ready in is required"),
  cost: Yup.string().required("Cost is required"),
  servings: Yup.string().required("Servings is required"),
  type: Yup.string().required("Type is required"),
  meal: Yup.array().of(Yup.string()).min(1).required("Meal is required"),
  dietary: Yup.string().required("Dietary is required"),
  cuisine: Yup.string().required("Made in is required"),
  cookingMethod: Yup.string().required("Cooking method is required"),
  "energy-kcal": Yup.string().required("value is required"),
  carbohydrates: Yup.string().required("value is required"),
  proteins: Yup.string().required("value is required"),
  fat: Yup.string().required("value is required"),

  ingredients: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      quantity: Yup.string(),
      unit: Yup.string(),
    })
  ),

  steps: Yup.array().of(
    Yup.object().shape({
      thumbnail: Yup.string().nullable(),
      title: Yup.string().required("Title is required"),
      ingredientsUsed: Yup.array(),
      description: Yup.string(),
    })
  ),
});

const RecipeUpload = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { stage } = useParams();
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [initialValues, setInitialValues] = useState({
    groceriesId: [],
    thumbnail: null,
    title: "",
    difficulty: "",
    readyIn: "",
    cost: "",
    servings: "",
    type: "",
    meal: [],
    dietary: "",
    cuisine: "",
    cookingMethod: "",
    ingredients: [],
    steps: [],
    "energy-kcal": "",
    carbohydrates: "",
    proteins: "",
    fat: "",
  });
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [activeRecipeId, setActiveRecipeId] = useState("");
  const uploadState = useSelector((state) => state.upload, shallowEqual);
  const groceriesState = useSelector((state) => state.groceries, shallowEqual);
  const searchState = useSelector((state) => state.search, shallowEqual);
  const userState = useSelector((state) => state.user, shallowEqual);
  const user = userState.user;

  useEffect(() => {
    if (uploadState.isEditing && uploadState.recipe) {
     const meal = typeof uploadState.recipe?.meal === "string" ? [uploadState.recipe?.meal] : uploadState.recipe?.meal
      let initialValue = {
        ...uploadState.recipe,
        ...uploadState.recipe?.nutriments,
        meal: meal,
      };
      setInitialValues(initialValue);
    }
  }, []);

  useEffect(() => {
    if (stage && uploadState.isEditing && uploadState.recipe) {
      setActiveIndex(Number(stage));
    }
  }, [stage]);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values, FormikBag) => {
        if (uploadState.isEditing) {
          try {
            setIsSubmiting(true);
            await updateRecipe({
              chefId: user.uid,
              ...JSON.parse(JSON.stringify(values)),
              cookingMethod: values.cookingMethod,
              nutriments: {
                "energy-kcal": Number(values["energy-kcal"]),
                carbohydrates: Number(values.carbohydrates),
                proteins: Number(values.proteins),
                fat: Number(values.fat),
              },
            });
            dispatch(toast({ message: "Recipe updated successfully" }));
            history.replace("/account/recipes");
          } catch (e) {
            setIsSubmiting(false);
            dispatch(toast({ message: "Failed to update recipe" }));
            FormikBag.setSubmitting(false);
          }
        } else {
          // const gainedPoints = getTotalPoints(selectedBrands);
          try {
            setIsSubmiting(true);
            const chef = await getChefById(user.uid);
            if (chef && chef.chefId) {
              const recipeId = await uploadRecipe({
                chefId: user.uid,
                ...values,
                nutriments: {
                  "energy-kcal": Number(values["energy-kcal"]),
                  carbohydrates: Number(values.carbohydrates),
                  proteins: Number(values.proteins),
                  fat: Number(values.fat),
                },
              });
              setActiveRecipeId(recipeId);
            } else {
              let [firstName, lastName] = user.displayName
                ? user.displayName.split(" ")
                : ["", ""];

              await createChef({
                chefId: user.uid,
                firstName: firstName,
                lastName: lastName,
                email: user.email,
                bio: "",
                pic: user.photoURL,
              });
              const recipeId = await uploadRecipe({
                chefId: user.uid,
                ...values,
                nutriments: {
                  "energy-kcal": Number(values["energy-kcal"]),
                  carbohydrates: Number(values.carbohydrates),
                  proteins: Number(values.proteins),
                  fat: Number(values.fat),
                },
              });
              setActiveRecipeId(recipeId);
            }
            // await addCollectedPoints(user.uid, "recipe", values.title, gainedPoints);
            // dispatch(updateUserField({
            //     field: "points",
            //     data: user.points + gainedPoints
            // }));
            console.log({
              chefId: user.uid,
              ...values,
              nutriments: {
                "energy-kcal": Number(values["energy-kcal"]),
                carbohydrates: Number(values.carbohydrates),
                proteins: Number(values.proteins),
                fat: Number(values.fat),
              },
            });
            setActiveIndex(5);
            setIsSubmiting(false);
            dispatch(toast({ message: "Recipe uploaded successfully" }));
            FormikBag.setSubmitting(false);
          } catch {
            setIsSubmiting(false);
            dispatch(toast({ message: "Failed to upload recipe" }));
            FormikBag.setSubmitting(false);
          }
        }
      }}
    >
      {(formik) => (
        <>
          {activeIndex === 1 && (
            <Ingredients setActiveIndex={setActiveIndex} formik={formik} />
          )}
          {activeIndex === 2 && (
            <Directions
              setActiveIndex={setActiveIndex}
              selectedBrands={selectedBrands}
              formik={formik}
              stage={stage}
            />
          )}
          {activeIndex === 3 && (
            <Introduction setActiveIndex={setActiveIndex} formik={formik} />
          )}
          {/* {
                        activeIndex === 4 && <Brands setActiveIndex={setActiveIndex} setSelectedBrands={setSelectedBrands} formik={formik} groceriesState={groceriesState} searchState={searchState} />
                    } */}
          {activeIndex === 4 && (
            <Preview
              setActiveIndex={setActiveIndex}
              formik={formik}
              isSubmiting={isSubmiting}
            />
          )}
          {activeIndex === 5 && (
            <Congratulations
              activeRecipeId={activeRecipeId}
              points={getTotalPoints(selectedBrands)}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default RecipeUpload;
