import React, { useState } from "react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Chip from '@material-ui/core/Chip';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';

import styles from "./RecipesTab.module.scss";


import constant from "../../../../constant";
const cost = constant.cost.options;
const difficulty = constant.difficulty.options;
const readyIn = constant.readyIn.options;
const servings = constant.servings.options;
const type = constant.type.options;
const dietary = constant.dietary.options;
const cuisine = constant.cuisine.options;




const RecipesTab = (props) => {
    const { localCuisines, setLocalCuisines, recipesTagSearch, toggleRecipesTagSearch } = props;

    const [selectedCuisine, setSelectedCuisine] = useState(cuisine[0].toLowerCase());



    const addToLocalCuisines = (value) => {
        const newLocalCuisines = [...localCuisines, value];
        setLocalCuisines([...new Set(newLocalCuisines)]);
    }


    return (
        <div className={styles.page}>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Type of course</span>
                {type.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
            </div>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Made in</span>
                {localCuisines.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
                <div className={styles.selectAndAddContainer}>
                    <Select
                        className={styles.selectInput}
                        classes={{ select: styles.select, icon: styles.dropDownIcon }}
                        labelId="List of cousine"
                        input={<InputBase />}
                        IconComponent={ArrowBackIosOutlinedIcon}
                        value={selectedCuisine}
                        onChange={(event) => setSelectedCuisine(event.target.value)}
                    >
                        {cuisine.map((value) => (
                            <MenuItem key={value} disabled={localCuisines.includes(value.toLowerCase())} value={value.toLowerCase()}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    <ButtonBase
                        disabled={localCuisines.includes(selectedCuisine)}
                        onClick={() => addToLocalCuisines(selectedCuisine)}
                        className={styles.addIcon}
                    >
                        <AddOutlinedIcon />
                    </ButtonBase>
                </div>
            </div>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Diet</span>
                {dietary.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
            </div>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Difficulty</span>
                {difficulty.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
            </div>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Cost</span>
                {cost.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
            </div>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Ready In</span>
                {readyIn.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
            </div>
            <div className={styles.filterTagContainer}>
                <span className={styles.tapHeaderText}>Servings</span>
                {servings.map((value) => {
                    return (
                        <Chip
                            key={value}
                            clickable
                            label={value}
                            className={recipesTagSearch.includes(value.toLowerCase()) ? styles.chipSelected : styles.chip}
                            onClick={() => toggleRecipesTagSearch(value.toLowerCase())}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default RecipesTab;