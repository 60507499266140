import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: "",
    open: false,
    message: "",
};

export const Notify = createSlice({
    name: 'Notify ',
    initialState,
    reducers: {
        toast: (state, action) => {
            return { ...state, open: true, ...action.payload };
        },
        closeToast: () => {
            return initialState;
        },
    }
});

export const { toast, closeToast } = Notify.actions;
export default Notify.reducer;