import React, { useState } from "react";
import Main from "./step/Main";
import ResetEmail from "./step/ResetEmail";
import ResetVerifyEmail from "./step/ResetVerifyEmail";
import ResetFinish from "./step/ResetFinish";
import { useHistory, useLocation } from 'react-router-dom';

const SignIn = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();

  // To read a specific query parameter
  const paramValue = queryParams.get('reset');

  const {
    handleSignInWithEmailAndPassword,
    handleUpdateUserWithEmailAndPassword,
  } = props;
  const [email, setEmail] = useState("");
  const [signInStep, setSignInStep] = useState(paramValue ? 'reset_email' : "main");

 

  return (
    <>
      {signInStep === "main" && (
        <Main
          setSignInStep={setSignInStep}
          handleSignInWithEmailAndPassword={handleSignInWithEmailAndPassword}
        />
      )}
      {signInStep === "reset_email" && (
        <ResetEmail
          SendResetEmail={SendResetEmail}
          email={email}
          setEmail={setEmail}
          setSignInStep={setSignInStep}
        />
      )}
      {signInStep === "reset_verify_email" && (
        <ResetVerifyEmail
          SendResetEmail={SendResetEmail}
          email={email}
          setEmail={setEmail}
          setSignInStep={setSignInStep}
        />
      )}
      {signInStep === "finish" && (
        <ResetFinish
          email={email}
          setEmail={setEmail}
          setSignInStep={setSignInStep}
          handleUpdateUserWithEmailAndPassword={
            handleUpdateUserWithEmailAndPassword
          }
        />
      )}
    </>
  );
};

export default SignIn;

const SendResetEmail = () => {
  const mainContainerStyle = {
    width: "100%",
    minHeight: "420px",
    background: "#fcefe3",
    padding: "36px 12px",
  };

  const stoveLogoContainerStyle = {
    textAlign: "center",
  };

  const stoveLogoStyle = {
    width: "50px",
    height: "auto",
    borderRadius: "8px",
  };

  const header = {
    padding: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "15px",
    color: "#130f26",
    textTransform: "capitalize",
    textAlign: "center",
  };

  const innerContainerStyle = {
    margin: "auto",
    background: "#fff",
    width: "360px",
    maxWidth: "80%",
    borderRadius: "12px",
    padding: "24px",
    marginTop: "24px",
    fontSize: "16px",
    textAlign: "center",
  };

  return (
    <div style={mainContainerStyle}>
      <div style={stoveLogoContainerStyle}>
        <img
          style={stoveLogoStyle}
          src="https://app.stovecook.com/stove-512x512.png"
          alt="stove"
        />
      </div>
      <p style={header}>
        <span>Password Reset</span>
      </p>
      <div style={innerContainerStyle}>
        <div>Please use the following Pin to reset your password:</div>
        <br />
        <strong>{"{{pin}}"}</strong>
      </div>
    </div>
  );
};
