import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import styles from "./MealPlan.module.scss";
import AnimateElement from "../../components/AnimateElement";
import plus from "../../assets/icons/plus.svg";
import Loader from "../GptChat/Components/Loader/Loader";

const EmptyCard = ({ addMeal, loading = false, loadingStyles }) => {
  const userState = useSelector((state) => state.user, shallowEqual);
  return (
    <AnimateElement duration={"0.5"}>
      <div className={`${styles.emp} ${loadingStyles ? loadingStyles : ""}`}>
        {!loading && (
          <img
            className={styles.cd}
            src={plus}
            alt="add icon"
            onClick={() => addMeal()}
          />
        )}
        {loading && (
          <div style={{marginTop: '20px'}}>
            <Loader text="" />
          </div>
        )}
      </div>
    </AnimateElement>
  );
};

export default EmptyCard;
