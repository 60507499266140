import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { get10Groceries, getAllGroceries, getSingleGrocery } from "../services/firebase";

export const getGroceries = createAsyncThunk(
    'Groceries/getAllGroceries',
    async () => {
        const response = await getAllGroceries();
        return response;
    }
)

export const getGrocery = createAsyncThunk(
    'Groceries/getSingleGrocery',
    async (groceryId) => {
        const response = await getSingleGrocery(groceryId);
        return response;
    }
)

export const get10Grocery = createAsyncThunk(
    'Groceries/get10Grocery',
    async () => {
        const response = await get10Groceries();
        localStorage.setItem('groceries', JSON.stringify(response))
        return response;
    }
)


const initialState = {
    activeGroceriesTab: "All",
    currentPage: 1,
    tabIndex: 0,
    groceriesIsLoading: true,
    groceriesIsError: false,
    groceries: [],
    groceryIsLoading: true,
    groceryIsError: false,
    grocery: null,
    onlyGrocery: [],
    grocery10IsLoading: true,
    grocery10IsError: false,
}

export const Groceries = createSlice({
    name: 'Groceries',
    initialState,
    reducers: {
        setActiveGroceriesTab: (state, action) => {
            state.activeGroceriesTab = action.payload;
        },
        setGroceries: (state, action) => {
            state.groceries = action.payload;
        },
        likeGroceryAction: (state, action) => {
            const likes = state.grocery.likes;
            likes.push(action.payload);
            state.grocery.likes = likes;
        },
        unlikeGroceryAction: (state, action) => {
            const index = state.grocery.likes.findIndex(userId => userId === action.payload);
            const likes = state.grocery.likes;
            likes.splice(index, 1);
            state.grocery.likes = likes;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroceries.pending, (state) => {
                state.groceriesIsLoading = true;
                state.groceriesIsError = false;
            })
            .addCase(getGroceries.fulfilled, (state, action) => {
                state.groceriesIsLoading = false;
                state.groceriesIsError = false;
                state.groceries = action.payload.sort(() => Math.random() - 0.5);
            })
            .addCase(getGroceries.rejected, (state) => {
                state.groceriesIsLoading = false;
                state.groceriesIsError = true;
                state.groceries = [];
            })

        builder
            .addCase(getGrocery.pending, (state,) => {
                state.groceryIsLoading = true;
                state.groceryIsError = false;
            })
            .addCase(getGrocery.fulfilled, (state, action) => {
                state.groceryIsLoading = false;
                state.groceryIsError = false;
                state.grocery = action.payload;
            })
            .addCase(getGrocery.rejected, (state, action) => {
                state.groceryIsLoading = false;
                state.groceryIsError = true;
                state.grocery = null;
            })

        builder
            .addCase(get10Grocery.pending, (state,) => {
                let res = JSON.parse(localStorage.getItem('groceries'))
                state.grocery10IsError = false;
                if(res) {
                    state.onlyGrocery = res;
                    state.grocery10IsLoading = false;
                }else {
                    state.grocery10IsLoading = true;
                }
            })
            .addCase(get10Grocery.fulfilled, (state, action) => {
                state.grocery10IsLoading = false;
                state.grocery10IsError = false;
                state.onlyGrocery = action.payload;
            })
            .addCase(get10Grocery.rejected, (state, action) => {
                state.grocery10IsLoading = false;
                state.grocery10IsError = true;
                state.onlyGrocery = [];
            })
    }
});

export const { setActiveGroceriesTab, setGroceries, likeGroceryAction, unlikeGroceryAction, setCurrentPage, setTabIndex } = Groceries.actions;
export default Groceries.reducer;