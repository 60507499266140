import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { recipesTagsSearch, recipesTextSearch, groceriesTagsSearch, groceriesTextSearch, articleTextSearch } from "../services/firebase";



export const getRecipesTagsSearch = createAsyncThunk(
    'Search/recipesTagsSearch',
    async (tags) => {
        const response = await recipesTagsSearch(tags);
        return response;
    }
)

export const getRecipesTextSearch = createAsyncThunk(
    'Search/recipesTextSearch',
    async (text) => {
        const response = await recipesTextSearch(text);
        return response;
    }
)

export const getGroceriesTagsSearch = createAsyncThunk(
    'Search/groceriesTagsSearch',
    async (options) => {
        const response = await groceriesTagsSearch(options.tags, options.specialOfferSearch);
        return response;
    }
)

export const getGroceriesTextSearch = createAsyncThunk(
    'Search/groceriesTextSearch',
    async (text) => {
        const response = await groceriesTextSearch(text);
        return response;
    }
)

export const getArticleTextSearch = createAsyncThunk(
    'Search/articleTextSearch',
    async (text) => {
        const response = await articleTextSearch(text);
        return response;
    }
)

const initialState = {
    recipesTag: [],

    recipesTagIsLoading: true,
    recipesTagIsError: false,
    recipesTagResult: [],
    recipesTextIsLoading: true,
    recipesTextIsError: false,
    recipesTextResult: [],

    groceriesTag: [],
    specialOfferSearch: false,

    groceriesTagIsLoading: true,
    groceriesTagIsError: false,
    groceriesTagResult: [],
    groceriesTextIsLoading: false,
    groceriesTextIsError: false,
    groceriesTextResult: [],

    articlesTextIsLoading: false,
    articlesTextIsError: false,
    articlesTextResult: [],
};

export const Search = createSlice({
    name: 'Search',
    initialState,
    reducers: {
        toggleRecipesTagSearch: (state, action) => {
            const recipesTag = [...state.recipesTag];
            const recipesTagSet = new Set(recipesTag);
            if (recipesTagSet.has(action.payload)) {
                recipesTagSet.delete(action.payload);
            } else {
                recipesTagSet.add(action.payload);
            }
            state.recipesTag = [...recipesTagSet]

        },
        toggleGroceriesTagSearch: (state, action) => {
            const groceriesTag = [...state.groceriesTag];
            const groceriesTagSet = new Set(groceriesTag);
            if (groceriesTagSet.has(action.payload)) {
                groceriesTagSet.delete(action.payload);
            } else {
                groceriesTagSet.add(action.payload);
            }
            state.groceriesTag = [...groceriesTagSet]

        },
        toggleSpecialOfferSearch: (state, action) => {
            state.specialOfferSearch = action.payload;
        },
        resetTypingSearchRecipes: (state) => {
            state.recipesTextIsLoading = false;
            state.recipesTextIsError = false;
            state.recipesTextResult = [];
        },
        resetTypingSearchGroceries: (state) => {
            state.groceriesTextIsLoading = false;
            state.groceriesTextIsError = false;
            state.groceriesTextResult = [];
        },
        resetTypingSearchArticles: (state) => {
            state.articlesTextIsLoading = false;
            state.articlesTextIsError = false;
            state.articlesTextResult = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRecipesTagsSearch.pending, (state) => {
                state.recipesTagIsLoading = true;
                state.recipesTagIsError = false;
            })
            .addCase(getRecipesTagsSearch.fulfilled, (state, action) => {
                state.recipesTagIsLoading = false;
                state.recipesTagIsError = false;
                state.recipesTagResult = action.payload;
            })
            .addCase(getRecipesTagsSearch.rejected, (state) => {
                state.recipesTagIsLoading = false;
                state.recipesTagIsError = true;
                state.recipesTagResult = [];
            })

        builder
            .addCase(getRecipesTextSearch.pending, (state) => {
                state.recipesTextIsLoading = true;
                state.recipesTextIsError = false;
            })
            .addCase(getRecipesTextSearch.fulfilled, (state, action) => {
                state.recipesTextIsLoading = false;
                state.recipesTextIsError = false;
                state.recipesTextResult = action.payload;
            })
            .addCase(getRecipesTextSearch.rejected, (state) => {
                state.recipesTextIsLoading = false;
                state.recipesTextIsError = true;
                state.recipesTextResult = [];
            })

        builder
            .addCase(getGroceriesTagsSearch.pending, (state) => {
                state.groceriesTagIsLoading = true;
                state.groceriesIsError = false;
            })
            .addCase(getGroceriesTagsSearch.fulfilled, (state, action) => {
                state.groceriesTagIsLoading = false;
                state.groceriesTagIsError = false;
                state.groceriesTagResult = action.payload;
            })
            .addCase(getGroceriesTagsSearch.rejected, (state) => {
                state.groceriesTagIsLoading = false;
                state.groceriesTagIsError = true;
                state.groceriesTagResult = [];
            })

        builder
            .addCase(getGroceriesTextSearch.pending, (state) => {
                state.groceriesTextIsLoading = true;
                state.groceriesTextIsError = false;
            })
            .addCase(getGroceriesTextSearch.fulfilled, (state, action) => {
                state.groceriesTextIsLoading = false;
                state.groceriesTextIsError = false;
                state.groceriesTextResult = action.payload;
            })
            .addCase(getGroceriesTextSearch.rejected, (state) => {
                state.groceriesTextIsLoading = false;
                state.groceriesTextIsError = true;
                state.groceriesTextResult = [];
            })
        builder
            .addCase(getArticleTextSearch.pending, (state) => {
                state.articlesTextIsLoading = true;
                state.articlesTextIsError = false;
            })
            .addCase(getArticleTextSearch.fulfilled, (state, action) => {
                state.articlesTextIsLoading = false;
                state.articlesTextIsError = false;
                state.articlesTextResult = action.payload;
            })
            .addCase(getArticleTextSearch.rejected, (state) => {
                state.articlesTextIsLoading = false;
                state.articlesTextIsError = true;
                state.articlesTextResult = [];
            })
    }
});

export const { toggleRecipesTagSearch, toggleGroceriesTagSearch, toggleSpecialOfferSearch, resetTypingSearchRecipes, resetTypingSearchGroceries, resetTypingSearchArticles } = Search.actions;
export default Search.reducer;






