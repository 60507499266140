import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withOrientationChange, isMobileOnly } from "react-device-detect";
import cx from "classnames";

import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import Spinner from "../../../../components/Spinner/Spinner";
import MessageBox from "../../../../components/MessageBox/MessageBox";

import notes from "./../../../../assets/icons/notes.png";
import helpIcon from "./../../../../assets/icons/help.png";
import key from "./../../../../assets/icons/key.png";
import LoyaltyIcon from "@material-ui/icons/Loyalty";

import recipesIcon from "./../../../../assets/icons/recipes.png";
import heartIcon from "./../../../../assets/icons/heart.png";
import groceriesIcon from "./../../../../assets/icons/groceries.png";
import cartIcon from "./../../../../assets/icons/cart.png";
import filterIcon from "./../../../../assets/icons/filter.png";
import notificationIcon from "./../../../../assets/icons/notification.png";
import SwitchAccountIcon from "./../../../../assets/icons/arrow-swap-horizontal.png";

import styles from "./Home.module.scss";
import AnimateElement from "../../../../components/AnimateElement";
import { setUserAccountType } from "./../../../../state";

const Home = (props) => {
   const { userState, url, isPortrait } = props;
   const isDietitian = userState?.user?.isDietitian;
   const history = useHistory();
   const dispatch = useDispatch();
   const [switchAccountDialogIsOpen, setSwitchAccountDialogIsOpen] = useState(false);

   const navigateToHealthProfile = () => {
      history.push(`dietitian/health-profile/${userState?.user?.uid}`);
   };

   const switchAccountHandler = useCallback((value) => {
      localStorage.setItem("accountType", value);
      dispatch(setUserAccountType(value));
      setTimeout(() => {
         setSwitchAccountDialogIsOpen(false);
         history.push("/");
      }, [400]);
   }, []);

   return (
      <>
         <div className={styles.header}>
            <div className={styles.headerTop}>
               <IconButton size='small' color='inherit' onClick={() => history.goBack()}>
                  <CloseOutlinedIcon className={styles.closeIcon} />
               </IconButton>
            </div>
            <div className={styles.headerMiddle}>
               <AnimateElement duration={"0.5"}>
                  <Avatar className={styles.profileImage} src={userState.user.photoURL} />
               </AnimateElement>
               <Typography className={styles.username} variant='body1' display='block' noWrap>
                  {userState.user.displayName}
               </Typography>
            </div>
            <div className={styles.headerBottom}>
               <div className={styles.item}>
                  <span className={styles.title}>Recipes</span>
                  <span className={styles.text}>{userState.userRecipes.length}</span>
               </div>
               <div className={styles.item}>
                  <span className={styles.title}>Followers</span>
                  <span className={styles.text}>0</span>
               </div>
               <div className={styles.item}>
                  <span className={styles.title}>Follows</span>
                  <span className={styles.text}>0</span>
               </div>
            </div>
         </div>

         {userState.userRecipesIsLoading === true && userState.userRecipesIsError === false && (
            <div className='px-3 mb-3'>
               <Spinner variant='rect' height={88} />
            </div>
         )}

         {userState.userRecipesIsLoading === false && userState.userRecipesIsError === true && <MessageBox text='Failed to load your recipes' />}

         <div className={styles.yourRecipe}>
            <span>Your recipes</span>
            <span onClick={() => history.push(`${url}/recipes`)} className={styles.seeAll}>
               {"See all >"}
            </span>
         </div>
         {userState.userRecipesIsLoading === false && userState.userRecipesIsError === false && userState.userRecipes.length > 0 && (
            <>
               <div className={styles.slider}>
                  {userState.userRecipes.map((recipe, idx) => (
                     <AnimateElement duration={"0.5"}>
                        <Card onClick={() => history.push(`/recipe/${recipe.recipeId}`)} key={idx} component='div' className={styles.card}>
                           <CardMedia className={styles.cardMedia} image={recipe.thumbnail} title={recipe.title} />
                        </Card>
                     </AnimateElement>
                  ))}
               </div>
            </>
         )}

         <AnimateElement duration={"0.5"}>
            <List className={styles.list}>
               <Divider />
               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/recipesliked`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={recipesIcon} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Favourite recipes'
                     secondary={`${userState.userLikedRecipes.length}  ${userState.userLikedRecipes.length > 1 ? " products" : " product"}`}
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Favourite recipes'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/password`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={key} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Change Password'
                     secondary='Update your password'
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Change Password'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/groceriesliked`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={groceriesIcon} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Favourite products'
                     secondary={`${userState.userLikedGroceries.length} ${userState.userLikedGroceries.length > 1 ? " products" : " product"}`}
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Favourite products'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push('/tasks')}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={notes} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Tasks'
                     secondary='See current and previous tasks'
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Favourite products'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/transactions`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={cartIcon} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Order history'
                     secondary={`${userState.userTransactions.length}  ${userState.userTransactions.length > 1 ? " transactions" : " transaction"}`}
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Order history'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/settings`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={filterIcon} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Settings'
                     secondary='Account, Notifications, Payments'
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Settings'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/notification`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar} src={notificationIcon} />
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Notifications'
                     secondary='Manage all notifications'
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Notifications'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider className={styles.cus} onClick={() => history.push(`${url}/points`)}>
                  <ListItemAvatar className={styles.listAvatar}>
                     <Avatar className={styles.avatar}>
                        <LoyaltyIcon color={"primary"} />
                     </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                     classes={{
                        primary: styles.primaryText,
                        secondary: styles.secondaryText,
                     }}
                     primary='Points Rewards'
                     secondary='See all Points Gathered'
                  />
                  <ListItemSecondaryAction>
                     <IconButton size='small' edge='end' aria-label='Points'>
                        <ArrowForwardIosOutlinedIcon color='primary' />
                     </IconButton>
                  </ListItemSecondaryAction>
               </ListItem>

               {userState?.accountType === "consumer" && userState?.user?.quizData && (
                  <ListItem divider className={styles.cus} onClick={navigateToHealthProfile}>
                     <ListItemAvatar className={styles.listAvatar}>
                        <Avatar className={styles.avatar} src={heartIcon} />
                     </ListItemAvatar>
                     <ListItemText
                        classes={{
                           primary: styles.primaryText,
                           secondary: styles.secondaryText,
                        }}
                        primary='Food Preferences & Quiz'
                        secondary='Edit Food Preferences & Quiz'
                     />
                     <ListItemSecondaryAction>
                        <IconButton size='small' edge='end' aria-label='quiz'>
                           <ArrowForwardIosOutlinedIcon color='primary' />
                        </IconButton>
                     </ListItemSecondaryAction>
                  </ListItem>
               )}

               {isDietitian && (
                  <ListItem divider className={styles.cus} onClick={() => setSwitchAccountDialogIsOpen(true)}>
                     <ListItemAvatar className={styles.listAvatar}>
                        <Avatar className={styles.avatar} src={SwitchAccountIcon} />
                     </ListItemAvatar>
                     <ListItemText
                        classes={{
                           primary: styles.primaryText,
                           secondary: styles.secondaryText,
                        }}
                        primary='Account type'
                        secondary='Switch between accounts'
                     />
                     <ListItemSecondaryAction>
                        <IconButton size='small' edge='end' aria-label='Points'>
                           <ArrowForwardIosOutlinedIcon color='primary' />
                        </IconButton>
                     </ListItemSecondaryAction>
                  </ListItem>
               )}
            </List>
         </AnimateElement>

         <div className='pb-5' />

         <a href='https://www.stovehealth.com/#help' target='_blank' rel='noopener noreferrer'>
            <ButtonBase className={styles.helpButton}>
               <Avatar className={styles.helpIcon} src={helpIcon} />
               <span>Help</span>
            </ButtonBase>
         </a>

         {switchAccountDialogIsOpen && (
            <div
               className={isMobileOnly && isPortrait ? styles.overlayContainer : styles.makeMobileOverlayContainer}
               onClick={() => setSwitchAccountDialogIsOpen(false)}
            >
               <div className={styles.overlayContent} onClick={(e) => e.stopPropagation()}>
                  <div className={styles.overlayDot} />
                  <div className={styles.overlayContentHeader}>Your Accounts</div>

                  <div
                     className={cx(styles.overlayContentCard, { [styles.active]: userState?.accountType === "consumer" })}
                     onClick={() => switchAccountHandler("consumer")}
                  >
                     <svg className={styles.tick} xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
                        <circle cx='16' cy='16' r='16' fill='#00866E' />
                        <path
                           d='M14.4012 21.6004C14.015 21.6004 13.6289 21.4538 13.3341 21.159L9.24299 17.068C8.65338 16.4784 8.65338 15.5233 9.24299 14.9354C9.8326 14.3458 10.786 14.3441 11.3756 14.9337L14.4012 17.9593L21.5179 10.8426C22.1075 10.253 23.0609 10.253 23.6505 10.8426C24.2401 11.4322 24.2401 12.3873 23.6505 12.9769L15.4684 21.159C15.1736 21.4538 14.7874 21.6004 14.4012 21.6004Z'
                           fill='white'
                        />
                        <path
                           d='M14.4012 21.6004C14.015 21.6004 13.6289 21.4539 13.3341 21.159L9.24299 17.068C8.65338 16.4784 8.65338 15.5233 9.24299 14.9354C9.8326 14.3458 10.786 14.3441 11.3756 14.9337L14.4012 17.9593L21.5179 10.8426C22.1075 10.253 23.0609 10.253 23.6505 10.8426C24.2401 11.4322 24.2401 12.3873 23.6505 12.9769L15.4684 21.159C15.1736 21.4539 14.7874 21.6004 14.4012 21.6004'
                           stroke='#00866E'
                           strokeWidth='0.5'
                        />
                     </svg>
                     <Avatar className={styles.overlayContentImage} src={userState?.user?.photoURL} />
                     <div className={styles.overlayContentNameText}>{userState?.user?.displayName}</div>
                     <div className={styles.overlayContentEmailText}>{userState?.user?.email}</div>
                     <div className={styles.overlayContentCons}>CONSUMER</div>
                  </div>

                  <div
                     className={cx(styles.overlayContentCard, { [styles.active]: userState?.accountType === "dietitian" })}
                     onClick={() => switchAccountHandler("dietitian")}
                  >
                     <svg className={styles.tick} xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
                        <circle cx='16' cy='16' r='16' fill='#00866E' />
                        <path
                           d='M14.4012 21.6004C14.015 21.6004 13.6289 21.4538 13.3341 21.159L9.24299 17.068C8.65338 16.4784 8.65338 15.5233 9.24299 14.9354C9.8326 14.3458 10.786 14.3441 11.3756 14.9337L14.4012 17.9593L21.5179 10.8426C22.1075 10.253 23.0609 10.253 23.6505 10.8426C24.2401 11.4322 24.2401 12.3873 23.6505 12.9769L15.4684 21.159C15.1736 21.4538 14.7874 21.6004 14.4012 21.6004Z'
                           fill='white'
                        />
                        <path
                           d='M14.4012 21.6004C14.015 21.6004 13.6289 21.4539 13.3341 21.159L9.24299 17.068C8.65338 16.4784 8.65338 15.5233 9.24299 14.9354C9.8326 14.3458 10.786 14.3441 11.3756 14.9337L14.4012 17.9593L21.5179 10.8426C22.1075 10.253 23.0609 10.253 23.6505 10.8426C24.2401 11.4322 24.2401 12.3873 23.6505 12.9769L15.4684 21.159C15.1736 21.4539 14.7874 21.6004 14.4012 21.6004'
                           stroke='#00866E'
                           strokeWidth='0.5'
                        />
                     </svg>
                     <Avatar className={styles.overlayContentImage} src={userState?.user?.photoURL} />
                     <div className={styles.overlayContentNameText}>{userState?.user?.displayName}</div>
                     <div className={styles.overlayContentEmailText}>{userState?.user?.email}</div>
                     <div className={styles.overlayContentBuss}>BUSINESS</div>
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default withOrientationChange(Home);
