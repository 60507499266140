import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import GridList from "@material-ui/core/GridList";
import ButtonBase from "@material-ui/core/ButtonBase";
import GridListTile from "@material-ui/core/GridListTile";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateCart, toast, setCurrentPage } from "./../../state";
import cx from "classnames";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";

import { getSingleGrocery } from "./../../services/firebase";

import styles from "./GroceryWidgetList.module.scss";
import Pagination from "../Pagination/Pagination";

import { calculateDiscount } from '../../utilities'

const colors = [
  {
    textColor: "#656462",
    soldOutBg: "rgb(192, 187, 184)",
    backgroundColor: "rgba(192, 187, 184, 0.2)",
  },
  {
    textColor: "#005DB5",
    soldOutBg: "rgb(1, 114, 190)",
    backgroundColor: "rgba(1, 114, 190, 0.08)",
  },
  {
    textColor: "#656714",
    soldOutBg: "rgb(215, 198, 84)",
    backgroundColor: "rgba(215, 198, 84, 0.25)",
  },
  {
    textColor: "#656714",
    soldOutBg: "rgb(255, 217, 102)",
    backgroundColor: "rgba(255, 217, 102, 0.4)",
  },
  {
    textColor: "#CD091D",
    soldOutBg: "rgb(178, 11, 27)",
    backgroundColor: "rgba(178, 11, 27, 0.1)",
  },
  {
    textColor: "#D5AC7F",
    soldOutBg: "rgb(227, 198, 124)",
    backgroundColor: "rgba(227, 198, 124, 0.15)",
  },
  {
    textColor: "#DF6701",
    soldOutBg: "rgb(243, 155, 69)",
    backgroundColor: "rgba(243, 155, 69, 0.15)",
  },
];

let PageSize = 10;

const GroceryWidget = (props) => {
  const { grocery, onClick, footer } = props;
  const dispatch = useDispatch();
  const color = useMemo(
    () => colors[Math.floor(Math.random() * colors.length)],
    []
  );
  const [activeCard, setActiveCard] = useState("product");
  const cartState = useSelector((state) => state.cart, shallowEqual);
  let cartQuantity = 0;
  if (cartState.cart.find((item) => item.groceryId === grocery.groceryId)) {
    const cartItem = cartState.cart.find(
      (item) => item.groceryId === grocery.groceryId
    );
    cartQuantity = cartItem.quantity;
  }
  const stockQuantity = grocery?.stockQuantity;

  const updateCartItem = (grocery, quantity) => {
    dispatch(
      updateCart({
        ...grocery,
        quantity,
      })
    );
  };

  const decrease = () => {
    let newNumber = cartQuantity - 1;
    if (newNumber >= 0) {
      updateCartItem(grocery, newNumber);
    }
  };

  const increase = () => {
    let newNumber = cartQuantity + 1;
    if (stockQuantity && (newNumber <= stockQuantity)) {
      updateCartItem(grocery, newNumber);
    } else {
      dispatch(toast({ message: "You can’t order more than what’s available in stock" }))
    }
  }


  return (
    <>
      {activeCard === "product" && (
        <Card
          component="div"
          className={styles.productWidgetContainer}
          style={{ backgroundColor: color.backgroundColor, 
            height: "auto"
          }}
          onClick={onClick}
        >
          {!(grocery.stockQuantity > 0) && (
            <div className={styles.soldOutContainer}>
              <div className={styles.soldOut} style={{ backgroundColor: color.soldOutBg }}>
                SOLD OUT
              </div>
            </div>
          )}
          {grocery.stockQuantity > 0 && (
            <ButtonBase
              className={styles.add}
              onClick={(e) => {
                e.stopPropagation();
                setActiveCard("add");
              }}
            >
              {cartQuantity === 0 ? (
                <span>
                  <AddIcon fontSize="small" />
                </span>
              ) : (
                <span>{cartQuantity}</span>
              )}
            </ButtonBase>
          )}
          <div className={styles.ImgCtn}>
            <img
              className={styles.img}
              src={grocery.thumbnail + '?tr=w-100,h-100'}
              alt={'product_image'} />
          </div>
          <Typography
            classes={{ body1: styles.desc }}
            style={{ color: color.textColor }}
            variant="body1"
            display="block"
            noWrap
          >
            {grocery.description}
          </Typography>
          <Typography
            classes={{ body1: styles.name }}
            variant="body1"
            display="block"
            noWrap
          >
            {grocery.name}
          </Typography>

          <div className={styles.sizeCtn}>
          <Typography
            classes={{ body1: styles.size }}
            variant="body1"
            display="block"
            noWrap
          >
            {grocery.size}
          </Typography>
          {
            grocery.specialOffers && (
              <Typography
              classes={{ body1: styles.specialOfferText }}
              variant="body1"
              display="block"
              // noWrap
              >
                Promo
              </Typography>
            )
          }
          </div>


          {footer === "recipe" && (
            <div className={styles.priceContainer} 
            style={{ position: "absolute" }}
            >
              {grocery.price && (
                <span>£{Number(grocery.price).toFixed(2)}</span>
              )}
              <span className={styles.leftText}>1x pack</span>
            </div>
          )}

          {footer === "grocery" && (
            <>
            {
              grocery.specialOffers ? (<div className={styles.specialOfferContainer}>
                  <div className={styles.specialOffer} >
                    <span><s>£{Number(grocery.price).toFixed(2)}</s></span>
                    <span>-{calculateDiscount(grocery.price, grocery.discountPrice)}%</span>
                  </div>
                    <span>£{Number(grocery.discountPrice).toFixed(2)}</span>
                </div> ) 
           :  
           (<div className={styles.priceContainer}>
              {grocery.price && (
                <span>£{Number(grocery.price).toFixed(2)}</span>
              )}
              <span className={styles.divider}></span>
              <span className={styles.discountContainer}>
                <span className={styles.number}>
                  {grocery.discountQuantity}+
                </span>
                {grocery.discountPrice && (
                  <span>£{Number(grocery.discountPrice).toFixed(2)}</span>
                )}
              </span>
            </div>)

          }
            </>
           
          )}
        </Card>
      )}
      {activeCard === "add" && (
        <Card component="div" className={styles.addWidgetContainer}>
          <div className={styles.headerText}>
            How many <br /> items?
          </div>
          <div className={styles.calculator}>
            <IconButton size="small" color="inherit" onClick={decrease}>
              <RemoveIcon fontSize="small" color="inherit" />
            </IconButton>
            <span className={styles.number}>{cartQuantity}</span>
            <IconButton size="small" color="inherit" onClick={increase} disabled={grocery.specialOffers && cartQuantity > 0}>
                <AddIcon fontSize="small" color="inherit" />
            </IconButton>
          </div>
          <div className={styles.priceContainer}>
            {/* {grocery.price && <span>£{Number(grocery.price).toFixed(2)}</span>} */}
            <span
              className={cx(styles.discountContainer, {
                [styles.activePrice]: cartQuantity < grocery.discountQuantity,
              })}
            >
              {grocery.price && (
                <span>£{Number(grocery.price).toFixed(2)}</span>
              )}
            </span>
            <span
              className={cx(styles.discountContainer, {
                [styles.activePrice]: cartQuantity >= grocery.discountQuantity,
              })}
            >
              <span className={styles.number}>{grocery.discountQuantity}+</span>
              {grocery.discountPrice && (
                <span>£{Number(grocery.discountPrice).toFixed(2)}</span>
              )}
            </span>
          </div>
          <div className={styles.doneContainer}>
            <IconButton onClick={() => setActiveCard("product")}>
              <CheckIcon className={styles.icon} fontSize="small" />
            </IconButton>
          </div>
          <div className={styles.footer} />
        </Card>
      )}
    </>
  );
};

GroceryWidget.propTypes = {
  grocery: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  footer: PropTypes.oneOf(["recipe", "grocery"]).isRequired,
};

const GroceryWidgetList = (props) => {
  const { groceries, footer } = props;
  const groceriesState = useSelector(state => state.groceries, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const navigateToGrocery = (groceryId) => {
    history.push(`/shop/${groceryId}`);
  };
  const currentPage = groceriesState.currentPage

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return groceries.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, groceries]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentPage])

  return (
    <div className={styles.GroceryWidgetContainer}>
      <GridList cols={2} spacing={10} cellHeight="auto" component="div">
        {currentTableData.map((grocery) => (
          <GridListTile key={grocery.groceryId} classes={{ tile: styles.tile }}>
            <GroceryWidget
              grocery={grocery}
              onClick={() => navigateToGrocery(grocery.groceryId)}
              footer={footer}
            />
          </GridListTile>
        ))}
      </GridList>
      <Pagination
        currentPage={currentPage}
        totalCount={groceries.length}
        pageSize={PageSize}
        onPageChange={(page) => dispatch(setCurrentPage(page))}
      />
    </div>
  );
};

GroceryWidgetList.propTypes = {
  groceries: PropTypes.array.isRequired,
  footer: PropTypes.oneOf(["recipe", "grocery"]).isRequired,
};

const GroceryWidgetAsyc = (props) => {
  const { groceryId, onClick, footer } = props;
  const dispatch = useDispatch();
  const color = useMemo(
    () => colors[Math.floor(Math.random() * colors.length)],
    []
  );
  const [activeCard, setActiveCard] = useState("product");
  const [grocery, setGrocery] = useState(null);
  const cartState = useSelector((state) => state.cart, shallowEqual);
  const groceriesState = useSelector((state) => state.groceries, shallowEqual);

  let cartQuantity = 0;
  if (cartState.cart.find((item) => item.groceryId === groceryId)) {
    const cartItem = cartState.cart.find(
      (item) => item.groceryId === groceryId
    );
    cartQuantity = cartItem.quantity;
  }

  useEffect(() => {
    if (
      groceriesState.groceries.find(
        (grocery) => grocery.groceryId === groceryId
      )
    ) {
      const grocery = groceriesState.groceries.find(
        (grocery) => grocery.groceryId === groceryId
      );
      setGrocery(grocery);
    } else {
      (async () => {
        try {
          const grocery = await getSingleGrocery(groceryId);
          setGrocery(grocery);
        } catch (error) {
          //To Do: handle error properly
          //   console.log(error);
        }
      })();
    }
  }, [groceriesState.groceries, groceryId]);

  const updateCartItem = (grocery, quantity) => {
    dispatch(
      updateCart({
        ...grocery,
        quantity,
      })
    );
  };

  const decrease = () => {
    let newNumber = cartQuantity - 1;
    if (newNumber >= 0) {
      updateCartItem(grocery, newNumber);
    }
  };

  const increase = () => {
    let newNumber = cartQuantity + 1;
    updateCartItem(grocery, newNumber);
  };

  return (
    <>
      {!grocery && (
        <Card
          component="div"
          className={styles.productWidgetContainerAsyc}
          style={{ backgroundColor: color.backgroundColor }}
        >
          <CircularProgress color="primary" size="24px" />
        </Card>
      )}
      {grocery && activeCard === "product" && (
        <Card
          component="div"
          className={styles.productWidgetContainer}
          style={{ backgroundColor: color.backgroundColor }}
          onClick={onClick}
        >
          {!(grocery.stockQuantity > 0) && (
            <div className={styles.soldOutContainer}>
              <div className={styles.soldOut} style={{ backgroundColor: color.soldOutBg }}>
                SOLD OUT
              </div>
            </div>
          )}
          {grocery.stockQuantity > 0 && (
            <ButtonBase
              className={styles.add}
              onClick={(e) => {
                e.stopPropagation();
                setActiveCard("add");
              }}
            >
              {cartQuantity === 0 ? (
                <span>
                  <AddIcon fontSize="small" />
                </span>
              ) : (
                <span>{cartQuantity}</span>
              )}
            </ButtonBase>
          )}
          <div
            className={styles.img}
            style={{ backgroundImage: `url(${grocery.thumbnail})` }}
          />
          <Typography
            classes={{ body1: styles.desc }}
            style={{ color: color.textColor }}
            variant="body1"
            display="block"
            noWrap
          >
            {grocery.description}
          </Typography>
          <Typography
            classes={{ body1: styles.name }}
            variant="body1"
            display="block"
            noWrap
          >
            {grocery.name}
          </Typography>

          <div className={styles.sizeCtn}>
          <Typography
            classes={{ body1: styles.size }}
            variant="body1"
            display="block"
            noWrap
          >
            {grocery.size}
          </Typography>
          {
            grocery.specialOffers && (
              <Typography
              classes={{ body1: styles.specialOfferText }}
              variant="body1"
              display="block"
              // noWrap
              >
                Promo
              </Typography>
            )
          }
          </div>

          {footer === "recipe" && (
            <div className={styles.priceContainer}
            style={{position: "absolute"}}
            >
              {grocery.price && (
                <span>£{Number(grocery.price).toFixed(2)}</span>
              )}
              <span className={styles.leftText}>1x pack</span>
            </div>
          )}

          {footer === "grocery" && (
            <div className={styles.priceContainer}>
              {grocery.price && (
                <span>£{Number(grocery.price).toFixed(2)}</span>
              )}
              <span className={styles.divider}></span>
              <span className={styles.discountContainer}>
                <span className={styles.number}>
                  {grocery.discountQuantity}+
                </span>
                {grocery.discountPrice && (
                  <span>£{Number(grocery.discountPrice).toFixed(2)}</span>
                )}
              </span>
            </div>
          )}
        </Card>
      )}
      {grocery && activeCard === "add" && (
        <Card component="div" className={styles.addWidgetContainer}>
          <div className={styles.headerText}>
            How many <br /> items?
          </div>
          <div className={styles.calculator}>
            <IconButton size="small" color="inherit" onClick={decrease}>
              <RemoveIcon fontSize="small" color="inherit" />
            </IconButton>
            <span className={styles.number}>{cartQuantity}</span>
            <IconButton size="small" color="inherit" onClick={increase}>
              <AddIcon fontSize="small" color="inherit" />
            </IconButton>
          </div>
          <div className={styles.priceContainer}>
            {grocery.price && <span>£{Number(grocery.price).toFixed(2)}</span>}
            <span className={styles.discountContainer}>
              <span className={styles.number}>{grocery.discountQuantity}+</span>
              {grocery.discountPrice && (
                <span>£{Number(grocery.discountPrice).toFixed(2)}</span>
              )}
            </span>
          </div>
          <div className={styles.doneContainer}>
            <IconButton onClick={() => setActiveCard("product")}>
              <CheckIcon className={styles.icon} fontSize="small" />
            </IconButton>
          </div>
          <div className={styles.footer} />
        </Card>
      )}
    </>
  );
};

GroceryWidgetAsyc.propTypes = {
  groceryId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  footer: PropTypes.oneOf(["recipe", "grocery"]).isRequired,
};

const GroceryWidgetListAsyc = (props) => {
  const { groceriesId, footer } = props;
  const history = useHistory();
  const navigateToGrocery = (groceryId) => {
    history.push(`/shop/${groceryId}`);
  };

  return (
    <div className={styles.GroceryWidgetContainer}>
      <GridList cols={2} spacing={10} cellHeight="auto" component="div">
        {groceriesId.map((groceryId) => (
          <GridListTile key={groceryId} classes={{ tile: styles.tile }}>
            <GroceryWidgetAsyc
              groceryId={groceryId}
              onClick={() => navigateToGrocery(groceryId)}
              footer={footer}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

GroceryWidgetListAsyc.propTypes = {
  groceriesId: PropTypes.array.isRequired,
  footer: PropTypes.oneOf(["recipe", "grocery"]).isRequired,
};

export { GroceryWidget, GroceryWidgetAsyc, GroceryWidgetListAsyc };
export default GroceryWidgetList;
