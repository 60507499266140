import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import { useHistory, useParams } from "react-router-dom";
import { withOrientationChange, isMobileOnly } from 'react-device-detect';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from "classnames";


import Pagination from "../../../components/Pagination/Pagination";
import GroceriesTab from "./GroceriesTab/GroceriesTab";

import { toast } from "../../../state";
import inputOption from "../../../constant";
import { getAppUserQuery, getAllGroceriesQuery } from "../../../services/queries";
import { recommendGroceriesTo, unrecommendGroceriesTo } from "../../../services/firebase";
import Footer from "../../../layout/Footer/Footer";
import styles from "./PatientGroceries.module.scss";

let PageSize = 10;
const PatientGroceries = (props) => {
    const { isPortrait } = props;
    const [groceriesData, setGroceriesData] = useState([]);
    const [activeShopCategories, setActiveShopCategories] = useState("");

    const [sortIsOpen, setSortIsOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState("Alphabetical order A > Z");

    const [groceriesTagSearchIsOpen, setGroceriesagSearchIsOpen] = useState(false);
    const [groceriesTagSearch, setGroceriesTagSearch] = useState([]);

    const [localCuisines, setLocalCuisines] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [added, setAdded] = useState([]);
    const [deleted, setDeleted] = useState([]);


    const [isSubmiting, setIsSubmiting] = useState(false);


    const dispatch = useDispatch();
    const history = useHistory();
    const { patientId } = useParams();
    const { data } = getAppUserQuery(patientId);
    const { isLoading: isLoadingGroceries, data: groceries, refetch } = getAllGroceriesQuery();



    useEffect(() => {
        if (groceries) {
            handleFilterSort(sortOrder, groceries)
        }
    }, [groceries])

    const goBack = () => {
        history.goBack();
    };

    const recommendHandler = async (recommendedUser = [], groceryId) => {
        if (recommendedUser.includes(data.uid)) {
            const deletedUniqueIds = new Set(deleted);
            deletedUniqueIds.delete(groceryId);
            setDeleted([...deletedUniqueIds]);
        } else {
            const addedUniqueIds = new Set(added);
            addedUniqueIds.add(groceryId);
            setAdded([...addedUniqueIds]);
            const deletedUniqueIds = new Set(deleted);
            deletedUniqueIds.delete(groceryId);
            setDeleted([...deletedUniqueIds]);
        }
    }

    const unrecommendHandler = async (recommendedUser = [], groceryId) => {
        if (!recommendedUser.includes(data.uid)) {
            const addedUniqueIds = new Set(added);
            addedUniqueIds.delete(groceryId);
            setAdded([...addedUniqueIds]);

        } else {
            const addedUniqueIds = new Set(added);
            addedUniqueIds.delete(groceryId);
            setAdded([...addedUniqueIds]);
            const deletedUniqueIds = new Set(deleted);
            deletedUniqueIds.add(groceryId);
            setDeleted([...deletedUniqueIds]);
        }
    }

    const handleSave = async () => {
        try {
            if (data) {
                setIsSubmiting(true);
                await Promise.all(added.map(groceryId => recommendGroceriesTo(groceryId, data.uid)))
                await Promise.all(deleted.map(groceryId => unrecommendGroceriesTo(groceryId, data.uid)))
                await refetch();
                setAdded([]);
                setDeleted([]);
                dispatch(toast({ message: "Changes saved successfully" }))
                setIsSubmiting(false);
            }
        } catch {
            dispatch(toast({ message: "Try again" }))
            setIsSubmiting(false);
        }
    }


    const handleFilterSort = (sort, groceriesData) => {
        setSortOrder(sort);
        setSortIsOpen(false);
        if (groceriesData) {
            switch (sort) {
                case "Alphabetical order A > Z":
                    const groceriesDataDesc = groceriesData.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        // names must be equal
                        return 0;
                    });
                    setGroceriesData(groceriesDataDesc);
                    break;
                case "Alphabetical order Z > A":
                    const groceriesDataAsc = groceriesData.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return 1;
                        }
                        if (nameA > nameB) {
                            return -1;
                        }
                        // names must be equal
                        return 0;
                    });
                    setGroceriesData(groceriesDataAsc);
                    break;
                case "Most Popular":
                    const groceriesDataPopular = groceriesData.sort(function (a, b) {
                        const nameA = a.likes?.length || 0; // ignore upper and lowercase
                        const nameB = b.likes?.length || 0; // ignore upper and lowercase
                        return nameB - nameA;
                    });
                    setGroceriesData(groceriesDataPopular);
                    break;
                case "Latest Added":
                    const groceriesDataLatest = groceriesData.sort(function (a, b) {
                        const nameA = a.createdOn; // ignore upper and lowercase
                        const nameB = b.createdOn; // ignore upper and lowercase
                        return nameB - nameA;
                    });
                    setGroceriesData(groceriesDataLatest);
                    break;
                default:
                    break
            }
        }
    }

    const toggleGroceriesTagSearch = (value) => {
        const groceriesTagSet = new Set(groceriesTagSearch);
        if (groceriesTagSet.has(value)) {
            groceriesTagSet.delete(value);
        } else {
            groceriesTagSet.add(value);
        }
        setGroceriesTagSearch([...groceriesTagSet])
    }


    const handleFilter = (categories) => {
        setGroceriesagSearchIsOpen(false);
        let inUseCategories = "";
        let inUseGroceries = [];
        if (categories === activeShopCategories) {
            inUseCategories = ""
            inUseGroceries = groceries;
        } else {
            inUseCategories = categories ? categories : activeShopCategories;
            inUseGroceries = groceries?.filter(grocery => {
                if (grocery?.shopCategory?.some) {
                    return grocery?.shopCategory?.some(category => category.toLowerCase().includes(inUseCategories.toLowerCase()))
                } else {
                    return grocery?.shopCategory?.toLowerCase().includes(inUseCategories.toLowerCase())
                }
            })
        }
        setActiveShopCategories(inUseCategories);
        if (groceriesTagSearch.length === 0) {
            setGroceriesData(inUseGroceries);
        } else {
            const filteredGroceriesTag = inUseGroceries.filter(grocery => {
                return grocery.tags.some(tag => {
                    if (tag?.toLowerCase) {
                        return groceriesTagSearch.some(tagSearch => tagSearch.toLowerCase() === tag?.toLowerCase())
                    } else {
                        return false;
                    }
                })
            })
            setGroceriesData(filteredGroceriesTag);
        }
    }


    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentTableData = groceriesData.slice(firstPageIndex, lastPageIndex);


    return (
        <>
            <div className={styles.page}>

                <div className={styles.header}>
                    <IconButton className={styles.back} size="small" onClick={goBack}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00065 12.3379L20 12.3379" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                    <Avatar className={styles.avater} src={data && data.photoURL} />
                    <div>
                        <div className={styles.headerTitle}>Recommended Products</div>
                        <div className={styles.headerSubText}>{data && data.displayName}</div>
                    </div>

                </div>

                <div className={styles.sliderTagContainer}>
                    {inputOption.shopCategories.options.map((value) => {
                        return <div key={value} component="div" className={cx(styles.sliderTag, { [styles.selected]: value.toLowerCase() === activeShopCategories.toLowerCase() })} onClick={() => handleFilter(value)}>
                            <span>{value}</span>
                            <span className={styles.sliderTagDot}>{groceries?.filter(grocery => {
                                if (grocery?.shopCategory?.some) {
                                    return grocery?.shopCategory?.some(category => category.toLowerCase().includes(value.toLowerCase()))
                                } else {
                                    return grocery?.shopCategory?.toLowerCase().includes(value.toLowerCase())
                                }
                            }).length}</span>
                        </div>
                    })}
                </div>


                <div className={styles.container}>


                    <div className={styles.filterContainer}>
                        <ButtonBase onClick={() => setSortIsOpen(true)}>
                            <span className={styles.orderText}>{sortOrder}</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 4.66669H14H2Z" fill="#130F26" />
                                <path d="M2 4.66669H14" stroke="#130F26" strokeLinecap="round" />
                                <path d="M4 8H12" stroke="#292D32" strokeLinecap="round" />
                                <path d="M6.66667 11.3333H9.33334" stroke="#292D32" strokeLinecap="round" />
                            </svg>
                        </ButtonBase>
                        <ButtonBase onClick={() => setGroceriesagSearchIsOpen(true)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.56517 3C3.70108 3 3 3.71286 3 4.5904V5.52644C3 6.17647 3.24719 6.80158 3.68936 7.27177L8.5351 12.4243L8.53723 12.4211C9.47271 13.3788 9.99905 14.6734 9.99905 16.0233V20.5952C9.99905 20.9007 10.3187 21.0957 10.584 20.9516L13.3436 19.4479C13.7602 19.2204 14.0201 18.7784 14.0201 18.2984V16.0114C14.0201 14.6691 14.539 13.3799 15.466 12.4243L20.3117 7.27177C20.7528 6.80158 21 6.17647 21 5.52644V4.5904C21 3.71286 20.3 3 19.4359 3H4.56517Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.4" d="M10 7H20.5" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className={styles.filterText}>{groceriesTagSearch.length}</span>
                        </ButtonBase>
                    </div>


                    {isLoadingGroceries && (
                        <div className="px-4 pt-4 text-center">
                            <CircularProgress size={15} disableShrink />
                        </div>
                    )}


                    {!isLoadingGroceries && groceriesData && (
                        <>
                            {currentTableData.map((grocery) => {
                                return (
                                    <div className={cx(styles.listContent, { [styles.selected]: grocery.recommendedUser && grocery.recommendedUser.includes(data.uid) })} key={grocery.groceryId} onClick={() => history.push(`/shop/${grocery.groceryId}`)}>
                                        <div className={styles.img} style={{ backgroundImage: `url(${grocery.thumbnail})` }} />
                                        <div className={styles.textContainer}>
                                            <div className={styles.nameText}>{grocery.name}</div>
                                            <div className={styles.descText}>{grocery.size}</div>
                                        </div>
                                        {((grocery.recommendedUser && grocery.recommendedUser.includes(data.uid)) || added.includes(grocery.groceryId)) && !deleted.includes(grocery.groceryId) ? (
                                            <IconButton onClick={(event) => [event.stopPropagation(), unrecommendHandler(grocery.recommendedUser, grocery.groceryId)]}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="12" fill="#00866E" />
                                                    <path d="M10.6623 15.8241C10.4098 15.8241 10.1573 15.7283 9.96449 15.5355L7.28918 12.8602C6.90361 12.4746 6.90361 11.8501 7.28918 11.4656C7.67475 11.08 8.2982 11.0789 8.68377 11.4645L10.6623 13.4431L15.3162 8.78918C15.7018 8.40361 16.3253 8.40361 16.7108 8.78918C17.0964 9.17475 17.0964 9.79932 16.7108 10.1849L11.3602 15.5355C11.1674 15.7283 10.9149 15.8241 10.6623 15.8241Z" fill="white" />
                                                </svg>
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={(event) => [event.stopPropagation(), recommendHandler(grocery.recommendedUser, grocery.groceryId)]}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="12" fill="#130F26" />
                                                    <path d="M16.2855 11.9985H7.71411" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.9995 16.2859V7.71448" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </IconButton>
                                        )}
                                    </div>
                                )

                            })}
                            <Pagination
                                currentPage={currentPage}
                                totalCount={groceriesData.length}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </>
                    )}
                </div>

            </div>

            {[...added, ...deleted].length > 0 && (
                <ButtonBase
                    disabled={isSubmiting}
                    className={styles.confirmButton}
                    onClick={handleSave}
                >{isSubmiting ? <CircularProgress color="inherit" size="24px" /> : "Save"}</ButtonBase>
            )}

            <Footer />

            {sortIsOpen && (
                <div className={cx(styles.sortContainer, (isPortrait && isMobileOnly) ? "" : styles.makeMobile)} onClick={() => setSortIsOpen(false)}>
                    <div onClick={(event) => event.stopPropagation()}>
                        <div className={styles.contentContainer}>
                            <div className={styles.sortHeader}>Order for</div>

                            <ButtonBase className={cx(styles.sortList, { [styles.selected]: sortOrder === "Alphabetical order A > Z" })} onClick={() => handleFilterSort("Alphabetical order A > Z", groceriesData)}>
                                <span>{"Alphabetical order A > Z"}</span>
                                {sortOrder === "Alphabetical order A > Z" && (
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593Z" fill="#00866E" />
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593" stroke="white" />
                                    </svg>
                                )}
                            </ButtonBase>

                            <ButtonBase className={cx(styles.sortList, { [styles.selected]: sortOrder === "Alphabetical order Z > A" })} onClick={() => handleFilterSort("Alphabetical order Z > A", groceriesData)}>
                                <span>{"Alphabetical order Z > A"}</span>
                                {sortOrder === "Alphabetical order Z > A" && (
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593Z" fill="#00866E" />
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593" stroke="white" />
                                    </svg>
                                )}
                            </ButtonBase>

                            <ButtonBase className={cx(styles.sortList, { [styles.selected]: sortOrder === "Most Popular" })} onClick={() => handleFilterSort("Most Popular", groceriesData)}>
                                <span>{"Most Popular"}</span>
                                {sortOrder === "Most Popular" && (
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593Z" fill="#00866E" />
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593" stroke="white" />
                                    </svg>
                                )}
                            </ButtonBase>

                            <ButtonBase className={cx(styles.sortList, { [styles.selected]: sortOrder === "Latest Added" })} onClick={() => handleFilterSort("Latest Added", groceriesData)}>
                                <span>{"Latest Added"}</span>
                                {sortOrder === "Latest Added" && (
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593Z" fill="#00866E" />
                                        <path d="M6.85975 12.8593C6.45569 12.8593 6.05163 12.706 5.74318 12.3976L1.46268 8.11707C0.845772 7.50016 0.845772 6.50084 1.46268 5.88573C2.07959 5.26882 3.07711 5.26701 3.69402 5.88393L6.85975 9.04965L14.306 1.60343C14.9229 0.986519 15.9204 0.986519 16.5373 1.60343C17.1542 2.22034 17.1542 3.21966 16.5373 3.83658L7.97632 12.3976C7.66787 12.706 7.26381 12.8593 6.85975 12.8593" stroke="white" />
                                    </svg>
                                )}
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            )}
            {groceriesTagSearchIsOpen && (
                <div className={cx(styles.tagContainer, (isPortrait && isMobileOnly) ? "" : styles.makeMobile)} onClick={() => setGroceriesagSearchIsOpen(false)}>
                    <div onClick={(event) => event.stopPropagation()}>
                        <div className={styles.contentContainer}>
                            <div className={styles.tagHeader}>
                                <span className={styles.tagHeaderText}>Filters</span>
                                <span className={styles.tagHeaderCount}>{groceriesTagSearch.length}</span>
                            </div>
                            <GroceriesTab
                                localCuisines={localCuisines}
                                setLocalCuisines={setLocalCuisines}
                                groceriesTagSearch={groceriesTagSearch}
                                toggleGroceriesTagSearch={toggleGroceriesTagSearch}

                            />
                        </div>
                        <ButtonBase className={styles.tagSearch} onClick={() => handleFilter(null)}>
                            Search
                        </ButtonBase>
                    </div>
                </div>
            )}
        </>
    )
}

export default withOrientationChange(PatientGroceries);