import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styles from "./SuccessPage.module.scss";

import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { getUserData, updateUserData } from "../../../services/firebase/index";

import { toast } from "../../../state";

import Divider from "@material-ui/core/Divider";
import endIMG from "../../../assets/trackIMGEnd.svg";
import booksession from "../../../assets/booksession.svg";
import tick from "../../../assets/checkgreen.svg";

const SuccessPage = () => {
   const [calendlyIsOpen, setCalendlyIsOpen] = useState(false);
   const [dietitian, setDietitian] = useState(null);
   const userState = useSelector((state) => state.user, shallowEqual);
   const user = userState.user;
   const history = useHistory();
   const dispatch = useDispatch();
   const moveTo = (path) => history.push(path);

   const getUser = async (id) => {
      const user = await getUserData(id);
      if (user) {
         setDietitian(user);
      }
   };

   // useCalendlyEventListener({
   //    onEventScheduled: async (event) => {
   //       dispatch(toast({ message: "Session booked successfully" }));
   //       setCalendlyIsOpen(false);
   //       localStorage.removeItem("foodPreferences");
   //       localStorage.setItem("visited", true);
   //       await updateUserSetting();
   //       user?.quizData ? moveTo("/") : moveTo("/bookingConfirmed");
   //    },
   // });

   useEffect(() => {
      if (userState?.user?.assignedDietitian?.uid) {
         getUser(userState?.user?.assignedDietitian?.uid);
      }
   }, []); 

   return (
      <>
         <div className={styles.page}>
               <div className={styles.imgBox}>
                  <img
                     src={endIMG}
                     alt='end'
                     onClick={() => {
                        moveTo("/");
                     }}
                  />
                  <div>
                     <div className={styles.text}>Amazing - all done!</div>
                     <div className={styles.subText}>
                        You can now create personalised recipes with just a click! But just before you jump in, take a moment to discover how to make
                        the most of your Stove experience.
                     </div>
                  </div>

                  <div className={styles.bottomButton} style={{ flexDirection: "column" }}>
                     <button
                        style={{ width: "232px" }}
                        className={styles.nextCenter}
                        onClick={async () => {
                           moveTo("/getStarted");
                        }}
                     >
                        Explore
                     </button>
                     <div
                        className={styles.more}
                        onClick={() => {
                           moveTo("/");
                        }}
                     >
                        Skip{" >"}
                     </div>
                  </div>
               </div>
         </div>

         {/* <PopupModal
            rootElement={document.body}
            url={
               dietitian?.calendlyUrl
                  ? dietitian?.calendlyUrl
                  : "https://calendly.com/stove/stove-monthly-call-emma?hide_event_type_details=1&hide_gdpr_banner=1"
            }
            open={calendlyIsOpen}
            onModalClose={() => setCalendlyIsOpen(false)}
            prefill={{
               email: userState?.user?.email,
               name: userState?.user?.displayName,
               guests: dietitian?.email ? [dietitian?.email] : [],
            }}
         /> */}
      </>
   );
};

export default SuccessPage;
