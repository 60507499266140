import React, { Fragment, useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import Layout from "../../layout";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";
import homeTagBgDemo from "./../../assets/home-tag-bg-demo.png";
import emptyBg from "./../../assets/empty-bg.png";

import aichef from "./../../assets/ai_chef.png";
import community from "./../../assets/community.png";
import get_started from "./../../assets/get_started.png";
import getStarted from "./../../assets/home_started.png";
import addCommunityImg from "./../../assets/home_community.png";
import chef from "./../../assets/home_chef.png";
import arrow from "./../../assets/homeArrow.svg";
import recipe from "../../assets/icons/recipeHome.svg";
import food from "../../assets/icons/foodHome.svg";
import water from "../../assets/icons/water.png";
import symptom from "../../assets/icons/symptomsHome.svg";
import complete from "../../assets/icons/complete.svg";
import notStart from "../../assets/icons/not.svg";
import progress from "../../assets/icons/progress.svg";
import weight from "../../assets/icons/weightHome.svg";
import gallery from "../../assets/icons/gallery.svg";
import searchIcon from "../../assets/icons/search.png";
import barcode from "../../assets/icons/barcode.svg";
import {
  getUserCompanyInvites,
  updateUserInvite,
  addFoodPreference,
  getUserData,
  getUserTasks,
  getUserMeal,
} from "../../services/firebase";
import { format, isMonday, startOfWeek } from "date-fns";
import dayjs from "dayjs";
import * as isToday from "dayjs/plugin/isToday";

import {
  sortPrivateChat,
  getParticipant,
  getPublicChat,
} from "../Chat/components/Messages/Messages";
import {
  setActiveConversation,
  setLoading,
  setUser,
  setTodaysMeal,
} from "../../state";

import styles from "./Home.module.scss";
import HomeChart from "./Chart/HomeChart";
import Div100vh from "react-div-100vh";
import Dialog from "../../components/Dialog/Dialog";
import { ArticleWidget } from "../../components/articleWidget/articleWidget";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import { macroTags, trackEvents } from "./../../constant";
import { openEvents } from "../../state";
import Calendar from "../Calendar/Calendar";
import AnimateElement from "../../components/AnimateElement";
import GetStartedArticle from "../Onboarding/GetStatedArticle/GetStartedArticle";
import TaskList from "../Dietitian/Task/TaskList";
import { TaskModal } from "./TaskModal/TaskModal";
import MealCard from "../MealPlan/card";

dayjs.extend(isToday);
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Home = ({ conversation, privateConversationsIsLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [addedEvents, setAddedEvents] = useState([]);
  const [privateConvo, setPrivateConvo] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedTask, setSelectedTask] = useState({});

  const [invite, setInvite] = useState([]);
  const [tags, setTags] = useState([]);
  const [foodTags, setFoodTags] = useState([]);
  const [healthGoals, setHealthGoals] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [advertIndex, setAdvertIndex] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const recipeState = useSelector((state) => state.recipes, shallowEqual);
  const groceriesState = useSelector((state) => state.groceries, shallowEqual);
  const userState = useSelector((state) => state.user, shallowEqual);
  const articleState = useSelector((state) => state.articles, shallowEqual);
  const calendarState = useSelector((state) => state.calendar, shallowEqual);
  const chatState = useSelector((state) => state.chat, shallowEqual);
  const { user, todaysMeal } = userState;
  const [email] = useState(userState?.user?.email);

  const moveTo = (path) => history.push(path);

  const navigateToConversation = (channel) => {
    dispatch(setActiveConversation(channel));
    history.push(`chat/conversation`);
  };

  const checkInvite = async () => {
    try {
      if (email) {
        const data = await getUserCompanyInvites(email);
        if (data.length > 0) {
          setIsOpen(true);
          setInvite(data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMoveToRecipe = (recipeItem) => {
    if (user) {
      moveTo(`/recipe/${recipeItem.recipeId}`);
    } else {
      moveTo("/login");
    }
  };

  useEffect(() => {
    checkInvite(email);
  }, []);

  useEffect(() => {
    if (conversation && conversation?.length > 0) {
      const result = sortPrivateChat(conversation);
      setPrivateConvo(result);
    }
  }, [conversation]);

  useEffect(() => {
    if (user) {
      if (user?.foodPreference) {
        const {
          allergies,
          cookingTime,
          dislikes,
          foodBudget,
          healthCondition,
        } = user?.foodPreference;
        const food_tags = [
          ...allergies,
          ...dislikes,
          ...healthCondition,
          cookingTime,
          foodBudget,
        ];
        setFoodTags(food_tags);
      }
      if (user?.quizData?.health_goals) {
        const health_goals = user?.quizData?.health_goals
          ? user?.quizData?.health_goals
          : [];
        setHealthGoals(health_goals);
      }
      if (user?.quizData?.conditions) {
        const checkedConditions = user?.quizData?.conditions
          ? user?.quizData?.conditions
              .filter((condition) => condition?.checked === true)
              .map((condition) => condition?.label)
          : [];
        setConditions(checkedConditions);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const tags = [...foodTags, ...healthGoals, ...conditions];
      setTags(tags);
    }
  }, [conditions, foodTags, healthGoals, user]);

  const fetchMealPlans = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const currentDay = format(new Date(), "EEEE");
    let firstModayOfWeek = format(
      startOfWeek(new Date(today), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    );

    try {
      const data = await getUserMeal(userState.user.uid, firstModayOfWeek);
      if (data) {
        const { mealPlan } = data;
        const mealPlanData = mealPlan.filter(
          (item) => item?.day.toLowerCase() === currentDay.toLowerCase()
        );
        if (mealPlanData.length > 0) {
          dispatch(setTodaysMeal(mealPlanData));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && !todaysMeal) {
      fetchMealPlans();
    }
  }, [user, todaysMeal]);

  if (user && !user.foodPreference) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <Layout>
      <Dialog
        open={Boolean(isOpenDelete)}
        message={`Are you sure you want to delete the invite?`}
        onCancel={() => [setIsOpen(true), setIsOpenDelete(false)]}
        onConfirm={() => [
          updateUserInvite(invite[0].id),
          setIsOpen(false),
          setIsOpenDelete(false),
        ]}
        isLoading={false}
        isLoadingText="No Loading"
      />
      {invite.length > 0 && (
        <Dialog
          open={Boolean(isOpen)}
          message={`You have an invite from ${invite[0].companyName} to join ${invite[0].chatName} group`}
          onCancel={() => [setIsOpenDelete(true), setIsOpen(false)]}
          onConfirm={() => [
            moveTo(invite[0].path),
            updateUserInvite(invite[0].id),
          ]}
          isLoading={false}
          isLoadingText="No Loading"
        />
      )}
      <div className={styles.page}>
        <AnimateElement duration={"1"}>
          <AutoPlaySwipeableViews
            index={advertIndex}
            onChangeIndex={setAdvertIndex}
            interval={5000}
          >
            <div className={styles.getStarted}>
              <Box
                className={styles.community}
                onClick={() => {
                  window.location.hostname.includes("app.stovecook")
                    ? moveTo("/article/MVmQlDM9I2570ZJZxgIY")
                    : moveTo("/suggested-articles");
                }}
              >
                <div className={styles.title}>Get Started</div>
                <div className={styles.subTitle}>
                  Find out how to navigate Stove like a pro
                </div>
                <div className={styles.communityImage}>
                  <img
                    src={getStarted}
                    alt="Community"
                    className={styles.img}
                  />
                </div>
                <img src={arrow} alt="chef" className={styles.arrow} />
              </Box>
            </div>
            <div className={styles.chefContainer}>
              <Box
                className={styles.community}
                onClick={() => moveTo("/gpt/recipe")}
              >
                <div className={styles.title}>
                  Become a chef with AI-powered recipes and more!
                </div>
                <img src={chef} alt="chef" className={styles.communityImage} />
                <img src={arrow} alt="chef" className={styles.arrow} />
              </Box>
            </div>

            <div className={styles.communityContainer}>
              <Box
                className={styles.community}
                onClick={() => moveTo("/chat/messsages")}
              >
                <div className={styles.title}>
                  Discover Stove’s Community Groups
                </div>
                <div className={styles.subTitle}>
                  Dive deep into healthy culinary topics, follow tips and find
                  new friends!
                </div>
                <div className={styles.communityImage}>
                  <img
                    src={addCommunityImg}
                    alt="Community"
                    className={styles.img}
                  />
                </div>
                <img src={arrow} alt="chef" className={styles.arrow} />
              </Box>
            </div>
          </AutoPlaySwipeableViews>
        </AnimateElement>

        <div className={styles.titleContainer}>
          <span className={styles.titleText}>My Preferences</span>
        </div>

        {tags && tags.length > 0 && (
          <div className={styles.sliderTagContainer}>
            {tags.map((tag, index) => {
              return (
                <AnimateElement duration={"0.8"} key={tag}>
                  <Card
                    component="div"
                    key={tag}
                    className={styles.sliderTag}
                    onClick={() => moveTo(`/search/tag/groceries/${tag}`)}
                  >
                    <span className={styles.sliderTagDot} />
                    <span className={styles.tagtext}>{tag}</span>
                  </Card>
                </AnimateElement>
              );
            })}
          </div>
        )}

        <div className={styles.titleContainer}>
          <span className={styles.titleText}>Diary</span>
          <ButtonBase
            className={styles.seeAllButton}
            onClick={() => moveTo("/gpt/:type")}
          >
            <span className={styles.seeAllText}>See all</span>
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6.5L4 4L1 1.5"
                stroke="#00866E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ButtonBase>
        </div>

        <div className={styles.gptPromptContainer}>
          <Box
            className={styles.gptPrompt}
            onClick={() => moveTo("/gpt/recipe")}
          >
            <AnimateElement>
              <img src={recipe} alt="icon" className={styles.img} />
              <p className={styles.gptPromptText}>Create a new recipe</p>
            </AnimateElement>
          </Box>

          <Box className={styles.gptPrompt} onClick={() => moveTo("/gpt/food")}>
            <AnimateElement>
              <img src={food} alt="icon" className={styles.img} />
              <p className={styles.gptPromptText}>Log food and drink</p>
            </AnimateElement>
          </Box>

          <Box
            className={styles.gptPrompt}
            onClick={() => moveTo("/gpt/water")}
          >
            <AnimateElement>
              <img src={water} alt="icon" className={styles.img} />
              <p className={styles.gptPromptText}>
                Log <br /> water
              </p>
            </AnimateElement>
          </Box>

          <Box
            className={styles.gptPrompt}
            onClick={() => moveTo("/gpt/symptoms")}
          >
            {" "}
            <AnimateElement>
              <img src={symptom} alt="icon" className={styles.img} />
              <p className={styles.gptPromptText}>
                Log <br /> symptoms
              </p>
            </AnimateElement>
          </Box>

          <Box
            className={styles.gptPrompt}
            onClick={() => moveTo("/gpt/weight")}
          >
            {" "}
            <AnimateElement>
              <img src={weight} alt="icon" className={styles.img} />
              <p className={styles.gptPromptText}>
                Log <br /> weight
              </p>
            </AnimateElement>
          </Box>
        </div>

        <div className={styles.titleContainer}>
          <span className={styles.titleText}>Todays meals</span>
          {/* <ButtonBase className={styles.seeAllButton} onClick={() => moveTo("/recipes")}>
                  <span className={styles.seeAllText}>See all</span>
                  <svg className={styles.todayCheck} width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <path d='M1 6.5L4 4L1 1.5' stroke='#00866E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                  </svg>
               </ButtonBase> */}
        </div>

        {/* {recipeState.recipe10IsLoading === true && recipeState.recipe10IsError === false && (
               <div className='px-3'>
                  <Spinner variant='rect' height={64} />
               </div>
            )}

            {recipeState.recipe10IsLoading === false && recipeState.recipe10IsError === true && (
               <MessageBox text='Failed to load suggested recipes' />
            )} */}

        {!todaysMeal && (
          <div
            className={styles.noTodaysMeals}
            onClick={() => {
              moveTo(`/mealplan/`);
            }}
          >
            <ButtonBase className={styles.add}>
              <AddIcon className={styles.addIcon} />
              <div className={styles.addText}>Add</div>
            </ButtonBase>
          </div>
        )}

        {todaysMeal && todaysMeal.length > 0 && (
          <div
            className={styles.todaysMeals}
            onClick={() => {
              //  moveTo(`/mealplan/`);
            }}
          >
            <div className={styles.card}>
              <div className={styles.text}>
                {todaysMeal[0]?.breakfast?.recipe_name}
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.text}>
                {todaysMeal[0]?.lunch?.recipe_name}
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.text}>
                {todaysMeal[0]?.dinner?.recipe_name}
              </div>
            </div>
          </div>
        )}

        {user?.uid && user?.isPremuimUser && <TaskList />}

        {/* speak to a dietitian */}
        {!user?.isPremuimUser && (
          <AnimateElement>
            <div className={styles.speakContainer}>
              <Box
                className={styles.community}
                onClick={() => moveTo("/dietitian")}
              >
                <div className={styles.title}>Speak to a Dietitian</div>
                <div className={styles.subTitle}>
                  Get personalised dietary support and 24/7 care
                </div>
                <img src={arrow} alt="chef" className={styles.arrow} />
              </Box>
            </div>
          </AnimateElement>
        )}

        {/* {user && (
          <>
            <div className={styles.titleContainer}>
              <span className={styles.titleText}>Tracking</span>
              <ButtonBase
                className={styles.seeAllButton}
                onClick={() => moveTo("/track")}
              >
                <span className={styles.seeAllText}>See all</span>
                <svg
                  width="5"
                  height="8"
                  viewBox="0 0 5 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6.5L4 4L1 1.5"
                    stroke="#00866E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBase>
            </div>

            <HomeChart />
          </>
        )} */}

        {/* Recen Chat */}
        {/* {user && (
          <>
            <div className={styles.titleContainer}>
              <span className={styles.titleText}>Community</span>
              <ButtonBase
                className={styles.seeAllButton}
                onClick={() => moveTo("/chat")}
              >
                <span className={styles.seeAllText}>See all</span>
                <svg
                  className={styles.todayCheck}
                  width="5"
                  height="8"
                  viewBox="0 0 5 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6.5L4 4L1 1.5"
                    stroke="#00866E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBase>
            </div>

            {privateConversationsIsLoading === true && (
              <div className="px-3">
                <Spinner variant="rect" height={64} />
              </div>
            )}

            {privateConversationsIsLoading === false &&
              privateConvo.length === 0 && (
                <MessageBox text="No Recent Chats" height={64} />
              )}

            <div className={styles.sliderChatContainer}>
              {user &&
                privateConversationsIsLoading === false &&
                privateConvo.length > 0 &&
                privateConvo.map((convo) => {
                  const participant = getParticipant(
                    convo.attributes,
                    user?.uid
                  );
                  const groupChat = getPublicChat(
                    chatState.puplicConversations,
                    convo.uniqueName
                  );
                  return (
                    <Fragment key={convo.sid}>
                      {groupChat ? (
                        <>
                          <div
                            key={convo.sid}
                            className={styles.sliderChatContent}
                            onClick={() => navigateToConversation(convo)}
                          >
                            <ButtonBase
                              className={styles.sliderChatContentImage}
                              style={{
                                backgroundImage: `url(${
                                  groupChat.thumbnail
                                    ? groupChat.thumbnail + "?tr=w-48,h-48"
                                    : emptyBg
                                })`,
                              }}
                            />
                            <span className={styles.sliderChatContentText}>
                              {groupChat.uniqueName}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {convo.attributes.isPrivate === true && (
                            <>
                              {convo.lastMessage && (
                                <div
                                  key={convo.sid}
                                  className={styles.sliderChatContent}
                                  onClick={() => navigateToConversation(convo)}
                                >
                                  <ButtonBase
                                    className={styles.sliderChatContentImage}
                                    style={{
                                      backgroundImage: `url(${
                                        participant.thumbnail
                                          ? participant.thumbnail +
                                            "?tr=w-48,h-48"
                                          : emptyBg
                                      })`,
                                    }}
                                  />
                                  <span
                                    className={styles.sliderChatContentText}
                                  >
                                    {participant.name}
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </>
        )} */}

        {/* <div className={styles.titleContainer}>
               <span className={styles.titleText}>Shop</span>
               <ButtonBase className={styles.seeAllButton} onClick={() => moveTo("/best-deals")}>
                  <span className={styles.seeAllText}>See all</span>
                  <svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <path d='M1 6.5L4 4L1 1.5' stroke='#00866E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                  </svg>
               </ButtonBase>
            </div>

            {groceriesState.grocery10IsLoading === true && groceriesState.grocery10IsError === false && (
               <div className='px-3'>
                  <Spinner variant='rect' height={156} />
               </div>
            )}

            {groceriesState.grocery10IsLoading === false && groceriesState.grocery10IsError === true && (
               <MessageBox text='Failed to load best offers for you' />
            )}

            <div className={styles.sliderGroceryContainer}>
               {groceriesState.onlyGrocery.map((grocery) => {
                  return (
                     <div
                        key={grocery.groceryId}
                        className={styles.sliderGroceryContent}
                        style={{
                           backgroundImage: `url(${grocery.thumbnail ? grocery.thumbnail + "?tr=w-156,h-156" : emptyBg})`,
                        }}
                        onClick={() => moveTo(`/shop/${grocery.groceryId}`)}
                     >
                        <ButtonBase className={styles.sliderGroceryContentAdd}>
                           <AddIcon fontSize='small' />
                        </ButtonBase>
                        <div className={styles.sliderGroceryContentDetails}>
                           <div className={styles.sliderGroceryContentName}>{grocery.name}</div>
                           <div className={styles.sliderGroceryContentSize}>{grocery.size}</div>
                           <div className={styles.sliderGroceryContentPriceContainer}>
                              <span className={styles.sliderGroceryContentDiscountPrice}>£{Number(grocery.discountPrice).toFixed(2)}</span>
                              <span className={styles.sliderGroceryContentPrice}>£{Number(grocery.price).toFixed(2)}</span>
                              <div className={styles.sliderGroceryContentDiscountPercent}>
                                 <span>-{calculateDiscount(grocery.price, grocery.discountPrice)}%</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div> */}

        <div className={styles.titleContainer}>
          <span className={styles.titleText}>Suggestions</span>
          <ButtonBase
            className={styles.seeAllButton}
            onClick={() => moveTo("/suggested-articles")}
          >
            <span className={styles.seeAllText}>See all</span>
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6.5L4 4L1 1.5"
                stroke="#00866E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ButtonBase>
        </div>

        {articleState.articles10IsLoading === true &&
          articleState.articles10IsError === false && (
            <div className="px-3">
              <Spinner variant="rect" height={64} />
            </div>
          )}
        {articleState.articles10IsLoading === false &&
          articleState.articles10IsError === true && (
            <MessageBox text="Failed to load learn & discover" />
          )}

        {articleState.articles10IsLoading === false &&
          articleState.articles10IsError === false &&
          articleState.onlyArticle.length == 0 && (
            <MessageBox text="No articles found" />
          )}

        {articleState.articles10IsLoading === false &&
          articleState.articles10IsError === false &&
          articleState.onlyArticle.length > 0 && (
            <ArticleWidget articles={articleState.onlyArticle} limit={3} />
          )}
      </div>
      {
        <TaskModal
          open={open}
          onClose={(id, status) => {
            setOpen(false);
            setTasks((prevData) =>
              prevData.map((item) =>
                item.taskId === id ? { ...item, status } : item
              )
            );
          }}
          selectedTask={selectedTask}
        />
      }
    </Layout>
  );
};

export default Home;

const calculateDiscount = (original_price, discounted_price) => {
  const discount = 100 * ((original_price - discounted_price) / original_price);
  return discount > 0 ? Number(discount).toFixed(0) : 0;
};
