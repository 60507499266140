import {
   configureStore,
   combineReducers
} from '@reduxjs/toolkit';
import User from './User';
import Rewards from "./Rewards";
import Recipes from "./Recipes";
import Groceries from "./Groceries";
import Search from "./Search";
import Chat from "./Chat";
import Cart from "./Cart";
import Notify from "./Notify";
import Upload from "./Upload";
import PromoCode from "./PromoCodes"
import Calendar from "./Calendar";
import Articles from "./Articles";
import Notification from "./Notification";
// import getOnly10Recipe from 

const combinedReducer = combineReducers({
   user: User,
   upload: Upload,
   rewards: Rewards,
   recipes: Recipes,
   groceries: Groceries,
   search: Search,
   chat: Chat,
   cart: Cart,
   notify: Notify,
   promoCodes: PromoCode,
   calendar: Calendar,
   articles: Articles,
   notification: Notification,
});

//Do this to clear all reducer
// const rootReducer = (state, action) => {
//     if (action.type === 'User/logoutUser') {
//         state = undefined;
//     }
//     return combinedReducer(state, action);
// };

const store = configureStore({
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: {
            // Ignore these action types
            ignoredActions: [
               "Chat/setActiveConversation",
               "User/setUser",
               "Chat/getAllProfiles/fulfilled",
               "Articles/getAllArticles/fulfilled",
               "Articles/get10Articles/fulfilled",
               "Groceries/get10Grocery/fulfilled",
               "Chat/getAllProfiles/pending",
               "Chat/getAllChats/pending",
               "Recipes/getAllRecipes/fulfilled",
               "Chat/getAllChats/fulfilled"
            ],
            ignoredPaths: ["chat", "user", "articles"],
         },
      }),
   reducer: combinedReducer,
});

export { setUser, setLoading, setUserAccountType, setTodaysMeal, logoutUser, updateUserField, removeRecipeFromRedux, getUserUsedPromoCode, getUserRecipes, getUserTransactions, getUserCollectedRewards, getUserLikedRecipes, getUserLikedGroceries, getUserCollectedPoints } from "./User";
export { getRewards, setRewards } from "./Rewards";
export { addRecipe, editRecipe } from "./Upload";
export { getRecipes, getRecipe, getOnly10Recipe, setRecipes, likeRecipeAction, unlikeRecipeAction } from "./Recipes";
export { setActiveGroceriesTab, getGroceries, getGrocery, setGroceries, likeGroceryAction, unlikeGroceryAction, setCurrentPage, setTabIndex, get10Grocery } from "./Groceries";
export { toggleRecipesTagSearch, getRecipesTagsSearch, getRecipesTextSearch, getArticleTextSearch, toggleGroceriesTagSearch, toggleSpecialOfferSearch, getGroceriesTagsSearch, getGroceriesTextSearch, resetTypingSearchRecipes, resetTypingSearchGroceries, resetTypingSearchArticles } from "./Search";
export { getProfiles, getAllPuplicChat, setActiveConversation } from "./Chat";
export { revalidateCart, updateCart, clearCart, getDeliveryAmount } from "./Cart";
export { toast, closeToast } from "./Notify";
export { getPromoCodes, setPromoCode } from "./PromoCodes";
export { addto, remove, toggle, clear, closeEvents, openEvents, selectedEvent } from "./Calendar"
export { getArticles, getArticle, setArticle, get10Articles } from "./Articles"
export { setNotification, getNotifications, setUnReadMessageCount, toggleNotification, setNotificationPreferences, getAllUnreadIndividualNotifications, getUserUnreadGroupMessages } from "./Notification";

export default store;