import React from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from "react-router-dom";
import { withOrientationChange } from 'react-device-detect';
import { format } from 'date-fns'
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from "../../../layout/Footer/Footer";



import { getUserSessionsQuery } from "../../../services/queries";
import styles from "./Sessions.module.scss";


const Sessions = (props) => {

    const history = useHistory();
    const { url } = useRouteMatch();
    const userState = useSelector((state) => state.user, shallowEqual);
    const { isLoading: isLoadingSessions, data: sessionsData } = getUserSessionsQuery(userState.user.uid);



    const goBack = () => {
        history.goBack();
    };

    const handleNavigateToPath = (path) => {
        history.push(`${url}/${path}`);
    }

    return (
        <>
            <div className={styles.page}>

                {/* <div className={styles.header}>
                    <IconButton size="small" onClick={goBack}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00065 12.3379L20 12.3379" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.3693 19.6759L4.00001 12.338L11.3693 5.00003" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                    <div className={styles.headerTitle}>Sessions</div>
                    <IconButton size="small">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 2V5" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16 2V5" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M3.5 9.08984H20.5" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C15.7909 15 14 16.7909 14 19C14 21.2091 15.7909 23 18 23Z" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19.4898 19.0498H16.5098" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18 17.5898V20.5798" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M11.9955 13.7002H12.0045" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M8.29431 13.7002H8.30329" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M8.29443 16.7002H8.30342" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                </div> */}


                <div className={styles.container}>

                    {isLoadingSessions && (
                        <div className="px-4 pt-4 text-center">
                            <CircularProgress size={15} disableShrink />
                        </div>
                    )}

                    {!isLoadingSessions && sessionsData && (
                        <>
                            {sessionsData.map((session, index) => (
                                <div className={styles.titleContainer} key={session.sessionId} onClick={() => handleNavigateToPath(`${session.sessionId}/${sessionsData.length - index}`)}>
                                    <div className={styles.titleImage}>{sessionsData.length - index}</div>
                                    <div className={styles.titleTextContainer}>
                                        <div className={styles.titleHeadertext}>{session.title}</div>
                                        <div className={styles.titleHeaderSubText}>{format(new Date(session.createdOn), "d MMMM yyyy")}</div>
                                    </div>
                                    <IconButton>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.48926 11L6.51087 6L1.48926 1" stroke="#00866E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </IconButton>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <Footer />
            </div>
        </>
    )
}

export default withOrientationChange(Sessions);