import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { getUserTasks } from "../../../../../services/firebase";
import { parseISO, isWithinInterval } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import back from "../../../../../assets/icons/back.png";
import complete from "../../../../../assets/icons/complete.svg";
import notStart from "../../../../../assets/icons/not.svg";
import progress from "../../../../../assets/icons/progress.svg";
import AnimateElement from "../../../../../components/AnimateElement";

import styles from "./TaskDetail.module.scss";
import { TaskModal } from "../../TaskModal/TaskModal";

const TaskDetail = () => {
   const [tasks, setTasks] = useState([]);
   const [open, setOpen] = useState(false);
   const [selectedTask, setSelectedTask] = useState({});
   const { user } = useSelector((state) => state.user, shallowEqual);
   const { startDate, endDate } = useParams();
   const history = useHistory();

   const startOfWeek = startDate ? new Date(startDate) : false;
   const endOfWeek = endDate ? new Date(endDate): false;

   const getTaskByDateRange = (tasks) => {
      const res = tasks.filter((task) => {
         if (task?.date && startOfWeek && endOfWeek) {
            const itemDate = parseISO(task.date);
            return isWithinInterval(itemDate, { start: startOfWeek, end: endOfWeek });
         }
      });
      setTasks(res);
   };

   const updateTaskStatus = (id, frequency, status) => {
      setTasks((prevData) => prevData.map((item) => (item.taskId === id ? { ...item, status: status } : item)));
   };

   useEffect(() => {
      const getTask = async () => {
        if(user){
            const res = await getUserTasks(user.uid);
            getTaskByDateRange(res);
        }
        
      };
      getTask();
   }, [user]);

   return (
      <div className={styles.page}>
         <div className={styles.header}>
            <img src={back} alt='Community' className={styles.back} onClick={() => history.goBack()} />
            <div className={styles.title}>Week Tasks</div>
         </div>
         <div className={styles.container}>
            {tasks.length > 0 &&
               tasks.map((item, index) => (
                  <div className={styles.add} key={index}>
                     <div
                        className={styles.icon}
                        onClick={() => {
                           history.push(`/dietitian/patient-details/${user.uid}/task-details/${item.taskId}`);
                        }}
                     >
                        <div className={styles.text} style={{ margin: "0" }}>
                           <div className={styles.name}> {item.description}</div>
                           <div className={styles.sub}> {item.subDescription}</div>
                           <div className={styles.status}>Status: {item.status}</div>
                        </div>
                     </div>

                     <div
                        className={styles.arr}
                        onClick={() => {
                           setSelectedTask(item);
                           setOpen(true);
                        }}
                     >
                        <IconButton>
                           <AnimateElement duration={"0.5"}>
                              <img src={item.status === "completed" ? complete : item.status === "in progress" ? progress : notStart} alt='tag' />
                           </AnimateElement>
                        </IconButton>
                     </div>
                  </div>
               ))}
            {tasks.length === 0 && <div className={styles.noTask}>No task available</div>}
         </div>
         {
            <TaskModal
               open={open}
               onClose={(id, status, frequency) => {
                  setOpen(false);
                  updateTaskStatus(id, status);
               }}
               selectedTask={selectedTask}
            />
         }
      </div>
   );
};

export default TaskDetail;
