import React, { Fragment, useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import PhonedIcon from "@material-ui/icons/Phone";



import Footer from "../../layout/Footer/Footer";
import Spinner from "../../components/Spinner/Spinner";
import MessageBox from "../../components/MessageBox/MessageBox";





import { getDietitianCalendlyQuery } from "../../services/queries";



import styles from "./SessionDietitian.module.scss";


const SessionDietitian = () => {
   const history = useHistory();
   const dispatch = useDispatch();


   const userState = useSelector((state) => state.user, shallowEqual);
   const moveTo = (path) => history.push(path);



   return (

      <div className={styles.page}>


         <div className={styles.header}>
            <div className={styles.headertext}>Session</div>
            <IconButton size="small">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path opacity="0.4" d="M20.75 17.6H3.25" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
            </IconButton>
         </div>






         <Footer />
      </div>

   )
};

export default SessionDietitian;



