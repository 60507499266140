import React, { useState } from "react";


import Email from "./step/Email";
import VerifyEmail from "./step/VerifyEmail";
import Finish from "./step/Finish";

const Register = (props) => {
    const { handleCreateUserWithEmailAndPassword } = props;
    const [email, setEmail] = useState("");
    const [registerStage, setRegisterStage] = useState("email");
    return (
        <>
            {registerStage === "email" && <Email SendRegisterEmail={SendRegisterEmail} setEmail={setEmail} setRegisterStage={setRegisterStage} />}
            {registerStage === "verify" && <VerifyEmail SendRegisterEmail={SendRegisterEmail} email={email} setEmail={setEmail} setRegisterStage={setRegisterStage} />}
            {registerStage === "finish" && <Finish email={email} setEmail={setEmail} setRegisterStage={setRegisterStage} handleCreateUserWithEmailAndPassword={handleCreateUserWithEmailAndPassword} />}
        </>
    )
}

export default Register;




const SendRegisterEmail = () => {
    const mainContainerStyle = {
        width: "100%",
        minHeight: '420px',
        background: "#fcefe3",
        padding: "36px 12px"
    }

    const stoveLogoContainerStyle = {
        textAlign: "center"
    };

    const stoveLogoStyle = {
        width: "50px",
        height: "auto",
        borderRadius: "8px"
    };

    const header = {
        padding: "24px",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "15px",
        color: "#130f26",
        textTransform: "capitalize",
        textAlign: "center"
    };

    const innerContainerStyle = {
        margin: 'auto',
        background: "#fff",
        width: '360px',
        maxWidth: "80%",
        borderRadius: '12px',
        padding: '24px',
        marginTop: '24px',
        fontSize: '16px',
        textAlign: "center"
    }


    return <div style={mainContainerStyle}>
        <div style={stoveLogoContainerStyle}>
            <img
                style={stoveLogoStyle}
                src="https://app.stovecook.com/stove-512x512.png"
                alt="stove"
            />
        </div>
        <p style={header}>
            <span>Register</span>
        </p>
        <div style={innerContainerStyle} >
            <div>Please use the following Pin to register:</div>
            <br />
            <strong>{"{{pin}}"}</strong>
        </div>
    </div>
}