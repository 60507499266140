import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { getAppUserQuery } from "../../../../services/queries";
import styles from "./New.module.scss";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { DateTimePicker } from "../../../../components/DateTimePicker/DateTimePicker";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import {
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import DayJsUtils from "@date-io/dayjs";
import CALENDER_ICON from "./../../../../assets/icons/calender-icon.svg";
import TIME_ICON from "./../../../../assets/time.svg";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import CustomTextArea from "../../../../components/CustomTextArea/CustomTextArea";
import {
  createUserTask,
  getSingleTask,
  updateUserTask,
} from "../../../../services/firebase";
import CancelIcon from "@material-ui/icons/Cancel";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";

const NewTask = () => {
  const history = useHistory();
  const { patientId } = useParams();
  const {
    data: patient,
    refetch,
    isLoading: userDataLoading,
  } = getAppUserQuery(patientId);
  const [resourceData, setResourceData] = useState([]);
  const [add, setAdd] = useState(false);
  const [values, setValues] = useState([]);
  const [condition, setCondition] = useState("");
  const [isTimeSelectable, setIsTimeSelectable] = useState(false);
  const [isDateSelectable, setIsDateSelectable] = useState(false);
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
  const id = urlParams.get("id");

  const [item, setItem] = useState([
    "Daily",
    "Weekly",
    "Monthly",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    "complete food diary",
    "plan your week's meals",
    "create a shopping list",
    "keep a symptom diary",
    "log thoughts and feelings when eating",
    "ask GP to conduct the suggested tests",
    "ask GP for a general health check-up",
    "notify family regarding the new plan",
  ]);
  const [selected, setSelected] = useState([]);
  const [desc, setDesc] = useState("");
  const [useTime, setUseTime] = useState(false);
  const [useDate, setUseDate] = useState(false);
  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [frequency, setFrequency] = useState(item[0]);
  const [task, setTask] = useState({});

  const goBack = () => {
    history.goBack();
  };

  const getTask = async (id) => {
    const res = await getSingleTask(id);
    setTask(res);
    console.log("data", res);
    setFrequency(res.frequency);
    if (res.date) {
      setDate(res.date ? new Date(res.date) : null);
      setIsDateSelectable(true);
    }
    if (res.time) {
      const currentDate = new Date();
      const [hours, minutes] = res.time
        .split(":")
        .map((value) => parseInt(value, 10));
      const isPM = res.time.includes("PM");
      if (isPM && hours !== 12) {
        hours += 12;
      } else if (!isPM && hours === 12) {
        hours = 0;
      }
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
      setIsTimeSelectable(true);
      setTime(res.time ? new Date(currentDate) : null);
    }
    setDesc(res.subDescription);
    if (res.description) {
      if (!data.includes(res.description)) {
        setData((prev) => [...prev, res.description]);
      }
      setSelected((prev) => [...prev, res.description.toLowerCase()]);
    }
  };

  useEffect(() => {
    if (patient && patient?.additionalResources) {
      setResourceData(patient.additionalResources);
    }
  }, [patient]);

  useEffect(() => {
    if (id) {
      getTask(id);
    }
  }, [id]);

  const addData = async () => {
    try {
      setLoading(true);

      for (let i = 0; i < selected.length; i++) {
        const payload = {
          subDescription: desc,
          time: isTimeSelectable ? format(time, "h:mm a") : null,
          frequency: frequency,
          date: isDateSelectable ? format(date, "yyyy-MM-dd") : null,
          status: "not started",
          description: selected[i],
        };
        if (id) {
          payload.taskId = id;
          const b = await updateUserTask(payload);
          console.log(b);
        } else {
          const b = await createUserTask(patientId, payload);
          console.log(b);
        }
      }

      setLoading(false);

      goBack();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = (selected) => {
    setValues((values) => values.filter((value) => value !== selected));
  };

  const handleSaveCondition = () => {
    if (condition) {
      // Trim the input value and split it by commas
      const newValues = condition.toLowerCase().trim().split(",");
      // Filter out empty strings and add new values to the existing list
      setValues((prevValues) => [
        ...prevValues,
        ...newValues.map((val) => val.trim()).filter((val) => val !== ""),
      ]);
      // Clear the input field
      setCondition("");
    }
  };

  const addCondition = () => {
    if (values.length > 0) {
      setData([...data, ...values]);
      setSelected([...selected, ...values]);
      // setCondition("");
      setValues([]);
    }
    setAdd(false);
  };

  return (
    <div className={styles.page}>
      <div className={styles.top}>
        <CloseOutlinedIcon
          onClick={() => {
            goBack();
          }}
        />
        <div className={styles.head}>Add Task</div>
        <div></div>
      </div>

      <div className={styles.dateTimeContainer}>
        <div className={styles.td}>
          <div className={styles.d}>
            <div>Use Date</div>
            <ToggleButton
              toggle={isDateSelectable}
              handleToggle={() => {
                setIsDateSelectable((prev) => !prev);
              }}
            />
          </div>
          <div className={styles.t}>
            <div>Use Time</div>
            <ToggleButton
              toggle={isTimeSelectable}
              handleToggle={() => {
                setIsTimeSelectable((prev) => !prev);
              }}
            />
          </div>
        </div>
        <DateTimePicker
          dateLabel="Complete by/on"
          selectedTime={time}
          setSelectedTime={(e) => {
            setTime(new Date(e.$d));
            console.log(e.$d);
          }}
          selectedDate={date}
          setSelectedDate={(e) => {
            setDate(new Date(e.$d));
          }}
          isTimeSelectable={isTimeSelectable}
          isDateSelectable={isDateSelectable}
          changeTimeSelectable={() => {
            setIsTimeSelectable((prev) => !prev);
          }}
          changeDateSelectable={() => {
            setIsDateSelectable((prev) => !prev);
          }}
        />

        <Grid xs={5} item>
          <CustomSelect
            select
            placeholder="Frequency"
            name={"unit"}
            value={frequency}
            onChange={(e) => {
              setFrequency(e.target.value);
            }}
          >
            {item.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </CustomSelect>
        </Grid>
      </div>

      <div>
        <div className={styles.allSymptoms}>
          {data.map((value) => {
            return (
              <Chip
                clickable
                key={value}
                label={value}
                className={
                  selected.includes(value.toLowerCase())
                    ? styles.chipSelected
                    : styles.chip
                }
                onClick={() => {
                  if (selected.includes(value.toLowerCase())) {
                    setSelected(
                      selected.filter((e) => e !== value.toLowerCase())
                    );
                    // setDesc((prev) =>
                    //   prev.includes(", " + value)
                    //     ? desc.replace(", " + value, "")
                    //     : desc.replace(value, "")
                    // );
                  } else {
                    setSelected((prev) => [...prev, value.toLowerCase()]);
                    // setDesc((prev) =>
                    //   desc.length === 0 ? value : prev + ", " + value
                    // );
                  }
                }}
              />
            );
          })}
          <Chip
            clickable
            label="Add Others..."
            className={styles.addChip}
            onClick={() => setAdd(true)}
          />
        </div>
      </div>
      <div className={styles.notes}>
        <div className={styles.notesCtn}>
          <p>Note</p>
          <CustomTextArea
            maxrows={8}
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
            placeholder="Add additional information to the task you’re setting."
          />
        </div>
      </div>

      <button
        className={`${styles.btn} `}
        onClick={addData}
        //  disabled={!checkIfQuantityIsAdded(selected)}
      >
        {loading ? (
          <CircularProgress
            style={{
              color: "white",
              width: "20px",
              height: "20px",
            }}
          />
        ) : (
          "Done"
        )}
      </button>
      {add && (
        <div className={styles.main}>
          <div className={styles.others}>
            <div className={styles.title}>Add Other Values</div>
            <div className={styles.sub}>
              Write everything else you want to add, separating each thing by a
              comma.
            </div>
            {values.length > 0 && (
              <div className={styles.otherConditionsContainer}>
                {values.map((textValue) => {
                  return (
                    <Chip
                      // clickable
                      key={textValue}
                      label={textValue}
                      className={styles.chip}
                      onDelete={() => handleDelete(textValue)}
                      deleteIcon={<CancelIcon />}
                    />
                  );
                })}
              </div>
            )}
            <TextField
              fullWidth
              multiline
              label="Write your condition"
              placeholder="Write your condition"
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              name="url"
              className={styles.addInput}
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
            />
            <div
              className={styles.bottomButton}
              style={{ flexDirection: "column" }}
            >
              {/* <button className={styles.prev} onClick={() => setStage((prev) => (prev - 1))}> <img src={strokeGood} alt="prev" /> </button> */}
              <button
                style={{ width: "152px" }}
                className={styles.nextCenter}
                onClick={condition ? handleSaveCondition : addCondition}
              >
                {condition ? "Save Values" : "Done"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewTask;
